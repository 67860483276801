import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {ExportConfigDBEntry, MaterialMapsExportQueryResult} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UpperCasePipe} from "@angular/common"
import {Component, inject, Input, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MaterialMapsExporter} from "@cm/lib/materials/material-maps-exporter"
import {MaterialMapsExportQueryResult as MapsExportQueryResult} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-material-exports-list-detail",
    templateUrl: "./material-exports-list-detail.component.html",
    styleUrls: ["./material-exports-list-detail.component.scss"],
    standalone: true,
    imports: [MemoizePipe, MatTooltipModule, UpperCasePipe],
})
export class MaterialExportsListDetailComponent implements OnInit {
    @Input() info!: MaterialMapsExportQueryResult
    @Input() deleteMapsExport: ((configDBEntry: ExportConfigDBEntry) => void) | undefined = undefined
    @Input() renameMapsExport: ((configDBEntry: ExportConfigDBEntry) => void) | undefined = undefined
    @Input() sourceInfoRequestToString!: (sourceInfoRequest: MaterialMapsExporter.SourceInfoRequest) => string

    expanded = false

    permission = inject(PermissionsService)
    sdk = inject(SdkService)
    $can = this.permission.$to

    constructor(public authService: AuthService) {}

    ngOnInit(): void {}

    async downloadDataObject(dataObjectLegacyId: number) {
        const {dataObject} = await this.sdk.gql.materialExportsGetDataObject({
            dataObjectLegacyId,
        })

        const anchor = document.createElement("a")
        anchor.href = dataObject.downloadUrl
        anchor.click()
    }

    mapWorkflows(workflows: MapsExportQueryResult["summary"]["workflow"]) {
        return workflows.map((x) => MaterialMapsExporter.workflowToString(x))
    }

    mapEngines(engines: MapsExportQueryResult["summary"]["engine"]) {
        return engines.map((x) => MaterialMapsExporter.engineToString(x))
    }

    mapNormalYs(normalY: MapsExportQueryResult["summary"]["normalY"]) {
        return normalY.map((x) => MaterialMapsExporter.normalYToString(x))
    }

    mapResolutions(resolutions: MapsExportQueryResult["summary"]["resolution"]) {
        return resolutions.map((x) => MaterialMapsExporter.resolutionToString(x))
    }
}
