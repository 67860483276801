import {z} from "zod"
import {
    DeclareNodeGraphTS,
    GetParameters,
    NodeGraph,
    NodeGraphMeta,
    NodeParamEvaluator,
    NodeParamSyncEvaluator,
    NodeParameters,
    Version,
    getParametersSchema,
    nodeParameters,
} from "@src/graph-system/node-graph"

export type GetNodeParameters<Context, ParamTypes extends NodeParameters, PrimitiveParamTypes extends NodeParameters> = GetParameters<Context, ParamTypes> &
    PrimitiveParamTypes

export type NodeMeta<Context, ParamTypes extends NodeParameters, PrimitiveParamTypes extends NodeParameters> = NodeGraphMeta<
    GetNodeParameters<Context, ParamTypes, PrimitiveParamTypes>
>

export function DeclareNode<
    ZodReturnType extends z.ZodType,
    ZodContextType extends z.ZodType,
    ZodParamTypes extends z.ZodType<NodeParameters>,
    ZodPrimitiveParamTypes extends z.ZodType<NodeParameters>,
>(
    definition: {
        returns: ZodReturnType
        context: ZodContextType
        parameters: ZodParamTypes
        primitiveParameters: ZodPrimitiveParamTypes
    },
    implementation: {
        run?: (data: {
            get: NodeParamEvaluator
            context: z.infer<typeof definition.context>
            parameters: GetNodeParameters<
                z.infer<typeof definition.context>,
                z.infer<typeof definition.parameters>,
                z.infer<typeof definition.primitiveParameters>
            >
        }) => Promise<z.infer<typeof definition.returns>>
        runSync?: (data: {
            get: NodeParamSyncEvaluator
            context: z.infer<typeof definition.context>
            parameters: GetNodeParameters<
                z.infer<typeof definition.context>,
                z.infer<typeof definition.parameters>,
                z.infer<typeof definition.primitiveParameters>
            >
        }) => z.infer<typeof definition.returns>
    },
    meta?: NodeMeta<z.infer<typeof definition.context>, z.infer<typeof definition.parameters>, z.infer<typeof definition.primitiveParameters>>,
) {
    const {returns: returnSchema, context: contextSchema, parameters: paramsSchema, primitiveParameters: primitiveParamsSchema} = definition
    type ReturnType = z.infer<typeof returnSchema>
    type Context = z.infer<typeof contextSchema>
    type ParamTypes = z.infer<typeof paramsSchema>
    type PrimitiveParamTypes = z.infer<typeof primitiveParamsSchema>

    return DeclareNodeTS<ReturnType, Context, ParamTypes, PrimitiveParamTypes>(
        {...implementation, validation: {returnSchema, contextSchema, paramsSchema, primitiveParamsSchema}},
        meta,
    )
}

export function DeclareNodeTS<ReturnType, Context, ParamTypes extends NodeParameters, PrimitiveParamTypes extends NodeParameters>(
    implementation: {
        run?: (data: {
            get: NodeParamEvaluator
            context: Context
            parameters: GetNodeParameters<Context, ParamTypes, PrimitiveParamTypes>
        }) => Promise<ReturnType>
        runSync?: (data: {get: NodeParamSyncEvaluator; context: Context; parameters: GetNodeParameters<Context, ParamTypes, PrimitiveParamTypes>}) => ReturnType
        validation?: {
            returnSchema?: z.ZodType
            contextSchema?: z.ZodType
            paramsSchema?: z.ZodType<NodeParameters>
            primitiveParamsSchema?: z.ZodType<NodeParameters>
        }
    },
    meta?: NodeMeta<Context, ParamTypes, PrimitiveParamTypes>,
) {
    const {validation} = implementation
    const paramsSchema = validation?.paramsSchema ?? nodeParameters
    const primitiveParamsSchema = validation?.primitiveParamsSchema ?? nodeParameters

    return DeclareNodeGraphTS<ReturnType, Context, GetNodeParameters<Context, ParamTypes, PrimitiveParamTypes>>(
        {
            ...implementation,
            validation: {
                returnSchema: validation?.returnSchema,
                contextSchema: validation?.contextSchema,
                paramsSchema: getParametersSchema(paramsSchema).and(primitiveParamsSchema),
            },
        },
        meta,
    )
}

export type Node<ReturnType, Context, ParamTypes extends NodeParameters, PrimitiveParamTypes extends NodeParameters> = NodeGraph<
    ReturnType,
    Context,
    GetNodeParameters<Context, ParamTypes, PrimitiveParamTypes>
>

export type NodeVersion<
    Context,
    ParamTypesFrom extends NodeParameters,
    PrimitiveParamTypesFrom extends NodeParameters,
    ParamTypesTo extends NodeParameters,
    PrimitiveParamTypesTo extends NodeParameters,
> = Version<GetNodeParameters<Context, ParamTypesFrom, PrimitiveParamTypesFrom>, GetNodeParameters<Context, ParamTypesTo, PrimitiveParamTypesTo>>
