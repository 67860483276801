import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {MeshData} from "@src/geometry-processing/mesh-data"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {IDataObjectNew} from "@src/templates/interfaces/data-object"
import {Subscription, from, map} from "rxjs"

const TD = TypeDescriptors

const loadMeshNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    data: TD.inlet(TD.Identity<Uint8Array>()),
    plyDataObjectId: TD.inlet(TD.Number),
    displaySubdivisionLevel: TD.inlet(TD.Number),
    renderSubdivisionLevel: TD.inlet(TD.Number),
    meshData: TD.outlet(TD.MeshData),
}

export class LoadMeshNew implements NodeClassImpl<typeof loadMeshNewDescriptor, typeof LoadMeshNew> {
    static descriptor = loadMeshNewDescriptor
    static uniqueName = "LoadMeshNew"
    sceneManager!: Inlet<ISceneManagerNew>
    data!: Inlet<Uint8Array>
    plyDataObjectId!: Inlet<number>
    displaySubdivisionLevel!: Inlet<number>
    renderSubdivisionLevel!: Inlet<number>
    meshData!: Outlet<MeshData>

    private pending: Subscription | null = null

    run() {
        this.meshData.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (
            this.sceneManager === NotReady ||
            this.data === NotReady ||
            this.plyDataObjectId === NotReady ||
            this.displaySubdivisionLevel === NotReady ||
            this.renderSubdivisionLevel === NotReady
        ) {
            return
        }

        const {displaySubdivisionLevel, renderSubdivisionLevel, sceneManager, data, plyDataObjectId} = this

        this.pending = this.sceneManager.addTaskNew(
            `loadMeshData(${plyDataObjectId})`,
            from(
                this.sceneManager.loadMeshDataNew(data, plyDataObjectId, {
                    displaySubdivisionLevel: 0,
                    renderSubdivisionLevel,
                }),
            ).pipe(
                map((meshDataLow) => {
                    this.pending = null
                    this.meshData.emitIfChanged(meshDataLow)

                    if (displaySubdivisionLevel > 0) {
                        this.pending = sceneManager.addTaskNew(
                            `loadMeshDataHigh(${plyDataObjectId})`,
                            from(
                                sceneManager.loadMeshDataNew(data, plyDataObjectId, {
                                    displaySubdivisionLevel,
                                    renderSubdivisionLevel,
                                }),
                            ).pipe(
                                map((meshDataHigh) => {
                                    this.pending = null
                                    this.meshData.emitIfChanged(meshDataHigh)
                                }),
                            ),
                            false,
                        )
                    }
                }),
            ),
            true,
        )
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
