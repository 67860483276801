import {ImageColorSpace} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {ConfiguratorParameterType} from "@cm/lib/templates/configurator-parameters"
import {ConfigInfo, InterfaceDescriptor, MaterialInfo} from "@cm/lib/templates/interface-descriptors"
import {DataObjectReference} from "@cm/lib/templates/nodes/data-object-reference"
import {FindMaterial} from "@cm/lib/templates/nodes/find-material"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {Parameters, TemplateParameterValue} from "@cm/lib/templates/nodes/parameters"
import {TransientDataObject, TransientDataObjectParameters} from "@cm/lib/templates/nodes/transient-data-object"
import {z} from "zod"

export type ConfiguratorUrlParameters = {
    templateId: string | undefined
    sceneId: number | undefined
    parameters: Parameters
}

type DecodedUrlParameter = {
    id: string
    value: string
    type: ConfiguratorParameterType
}

type ParsedParameter = {
    id: string
    value: TemplateParameterValue
}

const PARAM_REGEX = /param\(([^)]*)\)/
const VALUE_REGEX = /([^(]+)\(([^)]*)\)/

const decodeUrlParameter = (key: string, value: string): DecodedUrlParameter | undefined => {
    const paramMatch = key.match(PARAM_REGEX)
    if (!paramMatch || !paramMatch[1]) return undefined

    const id = paramMatch[1]
    const valueMatch = value.match(VALUE_REGEX)
    if (!valueMatch || !valueMatch[1] || !valueMatch[2]) {
        console.error("Invalid parameter value:", value)
        return undefined
    }

    const type = valueMatch[1] as ConfiguratorParameterType

    return {id, value: valueMatch[2], type}
}

/*key has the format "param(id)", value has the format "type(value)".*/
const initializeParameterFromUrlFormat = async (key: string, val: string, sdk: SdkService): Promise<ParsedParameter | undefined> => {
    const decodedParameter = decodeUrlParameter(key, val)
    if (!decodedParameter) return undefined

    const {id, value, type} = decodedParameter
    const parsedValue = await initializeTemplateParameterValue(type, value, sdk)

    return {id, value: parsedValue}
}

const intSchema = z.preprocess((val) => {
    if (typeof val === "string" && /^-?\d+$/.test(val)) return parseInt(val, 10)
    return val
}, z.number().int())

const booleanSchema = z.preprocess(
    (val) => (val === true || val === "true" || val === "1" ? true : val === false || val === "false" || val === "0" ? false : val),
    z.boolean(),
)

const imageDataSchema = z.object({
    data: z.instanceof(Uint8Array),
    contentType: z.string(),
})

/*Convert string based parameter representation from e.g. configurator url or outer website to an instance that can be handled by the
template system. This was previously done in SceneViewer.prepareParameter.*/
export const initializeTemplateParameterValue = async (
    type: ConfiguratorParameterType,
    value: string | TransientDataObjectParameters,
    sdk: SdkService,
): Promise<TemplateParameterValue> => {
    let parsedValue: TemplateParameterValue
    switch (type) {
        case "int":
            parsedValue = intSchema.parse(value)
            break
        case "material":
            parsedValue = await createMaterialReferenceFromId(z.string().uuid().parse(value), sdk)
            break
        case "material-article-id":
            parsedValue = new FindMaterial({articleId: z.string().parse(value)})
            break
        case "number":
        case "float":
            parsedValue = z.number().parse(value)
            break
        case "boolean":
            parsedValue = booleanSchema.parse(value)
            break
        case "image":
            if (typeof value === "string" || typeof value === "number") {
                parsedValue = new DataObjectReference({
                    dataObjectId: intSchema.parse(value),
                    name: "Data Object Reference",
                })
            } else if (imageDataSchema.safeParse(value).success) {
                parsedValue = new TransientDataObject({contentType: value.contentType, imageColorSpace: ImageColorSpace.Srgb, data: value.data})
            } else {
                throw new Error(`Invalid image value: ${value}`)
            }
            break
        default:
            parsedValue = z.string().parse(value)
            break
    }

    return parsedValue
}

//The tempate system still operates on legacy ids, only use this function internally and do not expose the legacy ids outside of the configurator
export const createMaterialReferenceFromLegacyId = async (materialLegacyId: number, sdk: SdkService): Promise<MaterialReference> => {
    const revisionLegacyId = (await sdk.throwable.latestRevisionFromMaterialLegacyIdForConfigurator({materialLegacyId})).material.latestCyclesRevision?.legacyId
    if (!revisionLegacyId) throw new Error(`Failed to get latest revision for material ${materialLegacyId}`)
    return new MaterialReference({name: "(Material Ref)", materialRevisionId: revisionLegacyId})
}

//For everything outside of the configurator, use uuids
const createMaterialReferenceFromId = async (materialId: string, sdk: SdkService): Promise<MaterialReference> => {
    const revisionLegacyId = (await sdk.throwable.latestRevisionFromMaterialIdForConfigurator({materialId})).material.latestCyclesRevision?.legacyId
    if (!revisionLegacyId) throw new Error(`Failed to get latest revision for material ${materialId}`)
    return new MaterialReference({name: "(Material Ref)", materialRevisionId: revisionLegacyId})
}

export const decodeConfiguratorUrlParameters = async (parameterString: string, sdk: SdkService): Promise<ConfiguratorUrlParameters> => {
    const {templateId, sceneId, ...restParameters} = Object.fromEntries(new URLSearchParams(parameterString))

    const parameterPromises = Object.entries(restParameters).map(([key, value]) => initializeParameterFromUrlFormat(key, value, sdk))
    const parsedParameters = (await Promise.all(parameterPromises)).filter((param): param is NonNullable<typeof param> => param != null)
    const parameters = new Parameters(Object.fromEntries(parsedParameters.map((param) => [param.id, param.value])))

    return {
        templateId: templateId,
        sceneId: sceneId ? parseInt(sceneId, 10) : undefined,
        parameters,
    }
}

export const encodeConfiguratorURLParams = async (interfaceDescriptors: InterfaceDescriptor<unknown, object>[], sdk: SdkService): Promise<string> => {
    let result = ""
    for (const descriptor of interfaceDescriptors) {
        if (descriptor instanceof ConfigInfo && descriptor.props.type === "input" && descriptor.props.value) {
            result += `&param(${descriptor.props.id})=config(${descriptor.props.value.id})`
        }
        if (
            descriptor instanceof MaterialInfo &&
            descriptor.props.type === "input" &&
            descriptor.props.value &&
            descriptor.props.origin instanceof MaterialReference
        ) {
            const materialId = (await sdk.throwable.materialIdFromLegacyId({materialLegacyId: descriptor.props.value.materialId})).material.id
            result += `&param(${descriptor.props.id})=material(${materialId})`
        }
    }
    return result
}
