import {Component, computed, inject, input, output} from "@angular/core"
import {SceneManagerService, TransformMode} from "@app/template-editor/services/scene-manager.service"
import {ObjectInspectorComponent} from "@template-editor/components/inspectors/object-inspector/object-inspector.component"
import {TemplateNode} from "@cm/lib/templates/types"
import {GenericInspectorComponent} from "../inspectors/generic-inspector/generic-inspector.component"
import {Export, Input, Mesh, Value, isExport, isInput, isMesh, isObject, isValue} from "@cm/lib/templates/node-types"
import {Camera} from "@cm/lib/templates/nodes/camera"
import {CameraInspectorComponent} from "../inspectors/camera-inspector/camera-inspector.component"
import {ThreeTemplateSceneViewerComponent} from "../three-template-scene-viewer/three-template-scene-viewer.component"
import {PostProcessRender} from "@cm/lib/templates/nodes/post-process-render"
import {PostProcessRenderInspectorComponent} from "../inspectors/post-process-render-inspector/post-process-render-inspector.component"
import {SceneProperties} from "@cm/lib/templates/nodes/scene-properties"
import {ScenePropertiesInspectorComponent} from "../inspectors/scene-properties-inspector/scene-properties-inspector.component"
import {Annotation} from "@cm/lib/templates/nodes/annotation"
import {AnnotationInspectorComponent} from "../inspectors/annotation-inspector/annotation-inspector.component"
import {ConfigGroup} from "@cm/lib/templates/nodes/config-group"
import {ConfigGroupInspectorComponent} from "../inspectors/config-group-inspector/config-group-inspector.component"
import {ConfigVariant} from "@cm/lib/templates/nodes/config-variant"
import {ConfigVariantInspectorComponent} from "../inspectors/config-variant-inspector/config-variant-inspector.component"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {TemplateInstanceInspectorComponent} from "../inspectors/template-instance-inspector/template-instance-inspector.component"
import {RigidRelation} from "@cm/lib/templates/nodes/rigid-relation"
import {RigidRelationInspectorComponent} from "../inspectors/rigid-relation-inspector/rigid-relation-inspector.component"
import {MeshDecal} from "@cm/lib/templates/nodes/mesh-decal"
import {MeshDecalInspectorComponent} from "../inspectors/mesh-decal-inspector/mesh-decal-inspector.component"
import {Group} from "@cm/lib/templates/nodes/group"
import {GroupInspectorComponent} from "../inspectors/group-inspector/group-inspector.component"
import {PlaneGuide} from "@cm/lib/templates/nodes/plane-guide"
import {PlaneGuideInspectorComponent} from "../inspectors/plane-guide-inspector/plane-guide-inspector.component"
import {PointGuideInspectorComponent} from "../inspectors/point-guide-inspector/point-guide-inspector.component"
import {PointGuide} from "@cm/lib/templates/nodes/point-guide"
import {HDRILight} from "@cm/lib/templates/nodes/hdri-light"
import {HDRILightInspectorComponent} from "../inspectors/hdri-light-inspector/hdri-light-inspector.component"
import {AreaLight} from "@cm/lib/templates/nodes/area-light"
import {AreaLightInspectorComponent} from "../inspectors/area-light-inspector/area-light-inspector.component"
import {LightPortal} from "@cm/lib/templates/nodes/light-portal"
import {LightPortalInspectorComponent} from "../inspectors/light-portal-inspector/light-portal-inspector.component"
import {MeshInspectorComponent} from "../inspectors/mesh-inspector/mesh-inspector.component"
import {RenderInspectorComponent} from "../inspectors/render-inspector/render-inspector.component"
import {Render} from "@cm/lib/templates/nodes/render"
import {InputInspectorComponent} from "../inspectors/input-inspector/input-inspector.component"
import {ExportInspectorComponent} from "../inspectors/export-inspector/export-inspector.component"
import {ValueInspectorComponent} from "../inspectors/value-inspector/value-inspector.component"
import {OverlayMaterialColor} from "@cm/lib/templates/nodes/overlay-material-color"
import {OverlayMaterialColorInspectorComponent} from "../inspectors/overlay-material-color-inspector/overlay-material-color-inspector.component"
import {MaterialReferenceInspectorComponent} from "../inspectors/material-reference-inspector/material-reference-inspector.component"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {LodType} from "@cm/lib/templates/nodes/lod-type"
import {LodTypeInspectorComponent} from "../inspectors/lod-type-inspector/lod-type-inspector.component"
import {DataObjectReferenceInspectorComponent} from "../inspectors/data-object-reference-inspector/data-object-reference-inspector.component"
import {DataObjectReference} from "@cm/lib/templates/nodes/data-object-reference"
import {MeshCurve} from "@cm/lib/templates/nodes/mesh-curve"
import {MeshCurveInspectorComponent} from "../inspectors/mesh-curve-inspector/mesh-curve-inspector.component"

@Component({
    selector: "cm-template-inspector",
    standalone: true,
    templateUrl: "./template-inspector.component.html",
    styleUrl: "./template-inspector.component.scss",
    imports: [
        ObjectInspectorComponent,
        GenericInspectorComponent,
        CameraInspectorComponent,
        ThreeTemplateSceneViewerComponent,
        PostProcessRenderInspectorComponent,
        ScenePropertiesInspectorComponent,
        AnnotationInspectorComponent,
        ConfigGroupInspectorComponent,
        ConfigVariantInspectorComponent,
        TemplateInstanceInspectorComponent,
        RigidRelationInspectorComponent,
        MeshDecalInspectorComponent,
        GroupInspectorComponent,
        PlaneGuideInspectorComponent,
        PointGuideInspectorComponent,
        HDRILightInspectorComponent,
        AreaLightInspectorComponent,
        LightPortalInspectorComponent,
        MeshInspectorComponent,
        RenderInspectorComponent,
        InputInspectorComponent,
        ExportInspectorComponent,
        ValueInspectorComponent,
        OverlayMaterialColorInspectorComponent,
        MaterialReferenceInspectorComponent,
        LodTypeInspectorComponent,
        DataObjectReferenceInspectorComponent,
        MeshCurveInspectorComponent,
    ],
})
export class TemplateInspectorComponent {
    private sceneManagerService = inject(SceneManagerService)

    transformMode = input.required<TransformMode>()
    transformModeChanged = output<TransformMode>()

    selectedNodes = computed(() => new Set(this.sceneManagerService.$selectedNodeParts().map((node) => node.templateNode)))
    isAnnotation = (node: TemplateNode): node is Annotation => node instanceof Annotation
    isCamera = (node: TemplateNode): node is Camera => node instanceof Camera
    isConfigGroup = (node: TemplateNode): node is ConfigGroup => node instanceof ConfigGroup
    isConfigVariant = (node: TemplateNode): node is ConfigVariant => node instanceof ConfigVariant
    isMesh = (node: TemplateNode): node is Mesh => isMesh(node)
    isObject = isObject
    isPostProcessRender = (node: TemplateNode): node is PostProcessRender => node instanceof PostProcessRender
    isSceneProperties = (node: TemplateNode): node is SceneProperties => node instanceof SceneProperties
    isTemplateInstance = (node: TemplateNode): node is TemplateInstance => node instanceof TemplateInstance
    isRigidRelation = (node: TemplateNode): node is RigidRelation => node instanceof RigidRelation
    isMeshDecal = (node: TemplateNode): node is MeshDecal => node instanceof MeshDecal
    isMeshCurve = (node: TemplateNode): node is MeshCurve => node instanceof MeshCurve
    isGroup = (node: TemplateNode): node is Group => node instanceof Group
    isPlaneGuide = (node: TemplateNode): node is PlaneGuide => node instanceof PlaneGuide
    isPointGuide = (node: TemplateNode): node is PointGuide => node instanceof PointGuide
    isHDRILight = (node: TemplateNode): node is HDRILight => node instanceof HDRILight
    isAreaLight = (node: TemplateNode): node is AreaLight => node instanceof AreaLight
    isLightPortal = (node: TemplateNode): node is LightPortal => node instanceof LightPortal
    isRender = (node: TemplateNode): node is Render => node instanceof Render
    isInput = (node: TemplateNode): node is Input => isInput(node)
    isExport = (node: TemplateNode): node is Export => isExport(node)
    isValue = (node: TemplateNode): node is Value => isValue(node)
    isOverlayMaterialColor = (node: TemplateNode): node is OverlayMaterialColor => node instanceof OverlayMaterialColor
    isMaterialReference = (node: TemplateNode): node is MaterialReference => node instanceof MaterialReference
    isLodType = (node: TemplateNode): node is LodType => node instanceof LodType
    isDataObjectReference = (node: TemplateNode): node is DataObjectReference => node instanceof DataObjectReference
}
