import {ChangeDetectionStrategy, Component, inject, input} from "@angular/core"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {DialogService} from "@app/common/services/dialog/dialog.service"
import {MaterialInfo} from "@cm/lib/templates/interface-descriptors"
import {SelectMaterialComponent} from "@app/platform/components/materials/select-material/select-material.component"

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "cm-select-material-button",
    templateUrl: "./select-material.component.html",
    styleUrls: ["./select-material.component.scss"],
    imports: [ButtonComponent],
})
export class SelectMaterialButton {
    $materialInfo = input.required<MaterialInfo>()

    private dialog = inject(DialogService)
    private configMenuService = inject(ConfigMenuService)

    chooseMaterialForInput() {
        this.dialog.selectInDialog(SelectMaterialComponent, {
            onSelect: ({legacyId}) => {
                this.configMenuService.emitMaterialSelected({input: this.$materialInfo(), legacyId})
            },
        })
    }
}
