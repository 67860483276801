@if ($data()?.items?.length === 0) {
    <cm-full-page-feedback text="No tasks to show"></cm-full-page-feedback>
} @else {
    <div class="cm-job-tasks-table">
        <cm-table-cell class="cm-first-column" [header]="true"> ID </cm-table-cell>
        <cm-table-cell [centered]="true" [header]="true"> State </cm-table-cell>
        <cm-table-cell [header]="true"> Name </cm-table-cell>
        <cm-table-cell [header]="true"> Message </cm-table-cell>
        <cm-table-cell [header]="true"> Claimed by </cm-table-cell>
        @for (row of $data()?.items; track row?.data?.id || $index) {
            <div class="cm-ruler"></div>
            <div class="cm-table-row" (click)="clickItem.emit(row?.data)">
                <cm-table-cell [item]="row">
                    <span class="cm-id cm-first-column">{{ row.data?.legacyId }}</span>
                </cm-table-cell>
                <cm-table-cell [item]="row" [centered]="true">
                    <cm-job-task-thumbnail [jobTask]="row.data"></cm-job-task-thumbnail>
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-overflowable-text [class.cm-name-fallback]="!row.data?.name">
                        {{ row.data?.name ?? row.data?.taskType ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-overflowable-text>
                        {{ row.data?.message }}
                    </cm-overflowable-text>
                </cm-table-cell>
                <cm-table-cell [item]="row">
                    <cm-overflowable-text>
                        {{ row.data?.claimedBy }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </div>
        }
    </div>
}
