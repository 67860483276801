@if (node) {
    <div class="cm-section-label">Template Output</div>
    <cm-string-input label="ID" [(value)]="node.id" (valueChange)="updateNode(node)" [validate]="isValidId"></cm-string-input>
    <cm-string-input label="Name" [(value)]="node.name" (valueChange)="updateNode(node)"></cm-string-input>
    <div class="cm-section-label">Value</div>
    <cm-node-reference-slot [(node)]="node.node" (nodeChange)="updateNode(node)"></cm-node-reference-slot>
    <div class="cm-section-label">Tags</div>
    <cm-search [value]="searchText" (focus)="focusSearch = true" (blur)="focusSearch = false" (valueChange)="searchText = $event"> </cm-search>
    <cm-dropdown [isOpen]="focusSearch && searchText && searchTags?.length > 0">
        <ng-container cm-dropdown>
            <div class="cm-dropdown-list">
                @for (tag of searchTags | slice: 0 : 5; track tag) {
                    <div (mousedown)="$event.preventDefault()" (click)="searchText = null; addTag(node, tag)" class="cm-list-item">
                        <div [style.background]="Labels.TagType.get(tag.type).background" class="cm-tag-label">
                            {{ Labels.TagType.get(tag.type).label }}
                        </div>
                        <div class="cm-tag-name">
                            {{ tag.name }}
                        </div>
                    </div>
                }
            </div>
        </ng-container>
    </cm-dropdown>
    <div class="cm-selected-tag-list">
        @for (tag of nodeTags; track tag) {
            <div (click)="removeTag(node, tag)" class="cm-selected-tag">
                <i class="fa-solid fa-xmark"></i>
                <div class="cm-selected-tag-name" matTooltip="{{ Labels.TagType.get(tag.type).label }}: {{ tag.name }}">
                    {{ tag.name }}
                </div>
            </div>
        }
    </div>
}
