import {MeshData} from "@src/geometry-processing/mesh-data"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Subscription, from, map} from "rxjs"

const TD = TypeDescriptors

const meshDecalNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    inputMeshData: TD.inlet(TD.MeshData),
    offset: TD.inlet(TD.Tuple<[number, number]>()),
    rotation: TD.inlet(TD.Number),
    size: TD.inlet(TD.Tuple<[number, number]>()),
    distance: TD.inlet(TD.Number),
    meshData: TD.outlet(TD.MeshData),
}

export class MeshDecalNew implements NodeClassImpl<typeof meshDecalNewDescriptor, typeof MeshDecalNew> {
    static descriptor = meshDecalNewDescriptor
    static uniqueName = "MeshDecalNew"
    sceneManager!: Inlet<ISceneManagerNew>
    inputMeshData!: Inlet<MeshData>
    offset!: Inlet<[number, number]>
    rotation!: Inlet<number>
    size!: Inlet<[number, number]>
    distance!: Inlet<number>
    meshData!: Outlet<MeshData>

    private pending: Subscription | null = null

    run() {
        this.meshData.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (
            this.sceneManager === NotReady ||
            this.inputMeshData === NotReady ||
            this.offset === NotReady ||
            this.rotation === NotReady ||
            this.size === NotReady ||
            this.distance === NotReady
        ) {
            return
        }

        this.pending = this.sceneManager.addTaskNew(
            `clipAndOffsetMeshForDecal(${this.inputMeshData.id})`,
            from(this.sceneManager.clipAndOffsetMeshForDecalNew(this.inputMeshData, this.offset, this.rotation, this.size, this.distance)).pipe(
                map((meshData) => {
                    this.pending = null
                    this.meshData.emitIfChanged(meshData)
                }),
            ),
            true,
        )
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
