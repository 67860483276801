import {Component, computed, inject, input, output, viewChild} from "@angular/core"
import {ImageLike} from "@cm/lib/templates/node-types"
import {BaseInspectorComponent} from "../base-inspector/base-inspector.component"
import {CardComponent} from "@common/components/cards/card/card.component"
import {DataObjectReference} from "@cm/lib/templates/nodes/data-object-reference"
import {from, of, switchMap} from "rxjs"
import {AsyncPipe} from "@angular/common"
import {TemplateNodeComponent} from "../../template-node/template-node.component"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ImageInput} from "@cm/lib/templates/nodes/input"
import {getNodeIconSeconaryClass} from "@app/template-editor/helpers/template-icons"
import {InspectorSectionComponent} from "../inspector-section/inspector-section.component"
import {Settings} from "@common/models/settings/settings"
import {DataObjectThumbnailComponent} from "../../../../common/components/data-object-thumbnail/data-object-thumbnail.component"
import {toObservable, toSignal} from "@angular/core/rxjs-interop"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {ThumbnailComponent} from "../../../../common/components/thumbnail/thumbnail.component"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {selectFile, uploadImage} from "@app/template-editor/helpers/upload"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {MatDialog} from "@angular/material/dialog"

@Component({
    selector: "cm-image-inspector",
    standalone: true,
    templateUrl: "./image-inspector.component.html",
    styleUrls: ["./image-inspector.component.scss", "./../../../helpers/template-icons.scss"],
    imports: [
        CardComponent,
        AsyncPipe,
        TemplateNodeComponent,
        MatMenuModule,
        MatTooltipModule,
        InspectorSectionComponent,
        DataObjectThumbnailComponent,
        ThumbnailComponent,
    ],
})
export class ImageInspectorComponent extends BaseInspectorComponent<ImageLike | null> {
    label = input.required<string>()
    highlighted = input(false)
    requestUpdateImage = output<ImageLike | null>()
    private dragImage = viewChild.required<TemplateNodeComponent>("dragImage")

    private sdk = inject(SdkService)
    private uploadService = inject(UploadGqlService)
    private dialog = inject(MatDialog)
    drag = inject(TemplateNodeDragService)

    imageIcon = computed(() => getNodeIconSeconaryClass(ImageInput.getNodeClass()))

    dataObjectReference = computed(() => {
        const node = this.node()
        if (node instanceof DataObjectReference) return node
        return undefined
    })

    private thumbnailData = toSignal(
        toObservable(this.node).pipe(
            switchMap((node) => {
                if (node instanceof DataObjectReference) return from(this.sdk.gql.getDataObjectForImageInspector({legacyId: node.parameters.dataObjectId}))
                else return of(Settings.SWITCH_MATERIAL_URL)
            }),
        ),
        {
            initialValue: Settings.IMAGE_NOT_AVAILABLE_SMALL_URL,
        },
    )

    thumbnailUrl = computed(() => {
        const thumbnailData = this.thumbnailData()
        if (typeof thumbnailData === "string") return thumbnailData
        else return undefined
    })
    thumbnailObject = computed(() => {
        const thumbnailData = this.thumbnailData()
        if (typeof thumbnailData === "string") return undefined
        return thumbnailData.dataObject
    })

    isReference = computed(() => {
        const node = this.node()
        if (!node) return false
        return !(node.parents.size === 1)
    })

    async onClick() {
        const file = await selectFile("Select an image file for upload", "image/jpeg, image/png, image/webp")
        if (file) {
            const image = await uploadImage(this.sdk, this.uploadService, this.sceneManagerService, this.dialog, file)
            if (!image) return
            this.requestUpdateImage.emit(image)
        }
    }

    gotoReference() {
        if (!this.isReference()) return

        const node = this.node()
        if (!node) return

        this.sceneManagerService.selectNode({templateNode: node, part: "root"})
    }

    removeAssignment() {
        this.requestUpdateImage.emit(null)
    }

    dragStart(event: DragEvent) {
        const node = this.node()
        if (!node) {
            event.preventDefault()
            return
        }

        event.dataTransfer?.setDragImage(this.dragImage().getDragImage(), 0, 0)

        this.drag.dragStart(event, node)
    }
}
