import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    OnInit,
    Output,
    signal,
    ViewChild,
    computed,
} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {
    ContentTypeModel,
    DataObjectAssignmentType,
    DataObjectState,
    DataObjectType,
    DownloadResolution,
    FeedbackCanvasDataObjectFragment,
    FeedbackCanvasItemFragment,
    FeedbackCanvasTaskFragment,
    TaskState,
} from "@api"
import {IsNonNull, IsNotUndefined} from "@cm/lib/utils/filter"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {FileThumbnailComponent} from "@platform/components/files/file-thumbnail/file-thumbnail.component"
import {VisualPin} from "@platform/models/pictures/visual-pin/visual-pin"
import {DrawingService} from "@platform/services/pictures/drawing.service"
import {TasksService} from "@platform/services/tasks/tasks.service"
import * as paper from "paper"
import {auditTime, BehaviorSubject, debounceTime, filter, fromEvent, Subject, switchMap, tap} from "rxjs"
import {IsDefined} from "@cm/lib/utils/filter"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {environment} from "@environment"
import {DrawingHitOptions, NavigationHitOptions} from "@platform/models/pictures/canvas/options"
import {CanvasMode} from "@platform/models/pictures/canvas/canvas"

@Component({
    selector: "cm-feedback-canvas",
    templateUrl: "feedback-canvas.component.html",
    styleUrls: ["feedback-canvas.component.scss"],
    standalone: true,
    imports: [MatProgressSpinnerModule, FullPageFeedbackComponent, FileThumbnailComponent, DataObjectThumbnailComponent],
})
export class FeedbackCanvasComponent implements OnInit, AfterViewInit {
    destroyRef = inject(DestroyRef)
    drawing = inject(DrawingService)
    notifications = inject(NotificationsService)
    organizations = inject(OrganizationsService)
    permission = inject(PermissionsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    tasksService = inject(TasksService)
    private uploadService = inject(UploadGqlService)
    $can = this.permission.$to

    @ViewChild("canvasElement", {static: true}) canvasElementRef!: ElementRef<HTMLCanvasElement>
    @ViewChild("canvasContainer", {static: true}) canvasContainer!: ElementRef<HTMLDivElement>
    @Output("imageClick") imageClick = new EventEmitter<void>()
    @Input({required: true}) organizationId!: string

    pictureRevisionId$ = new BehaviorSubject<string | null | undefined>(undefined)

    protected DownloadResolution = DownloadResolution

    @Input()
    set pictureRevisionId(pictureRevisionId: string | null | undefined) {
        this.pictureRevisionId$.next(pictureRevisionId)
    }

    private pictureRevision$ = this.pictureRevisionId$.pipe(
        filter(IsNotUndefined),
        tap(() => {
            this.$isInitialized.set(true)
            this.$isLoadingRevision.set(true)
            this.project?.activeLayer?.removeChildren()
        }),
        switchMap((pictureRevisionId) =>
            this.refresh.keepFetched$(pictureRevisionId, ContentTypeModel.PictureRevision, async ({id}) =>
                this.sdk.gql.feedbackCanvasItem({pictureRevisionId: id}),
            ),
        ),
        tap((revision) => {
            this.pictureRevision = revision
            if (revision) this.$isLoadingThumbnail.set(true)
            this.$isLoadingRevision.set(false)
        }),
        filter(IsDefined),
        takeUntilDestroyed(this.destroyRef),
    )
    pictureRevision?: FeedbackCanvasItemFragment | null

    private pinLayer!: paper.Layer
    private drawingLayer!: paper.Layer
    private scope!: paper.PaperScope
    private project!: paper.Project
    private tool!: paper.Tool
    private drawingSVG?: FeedbackCanvasDataObjectFragment

    @HostListener("window:resize", ["$event"])
    onResize(_event: Event) {
        this.resize$.next()
    }

    drawingPath?: paper.Path
    selectedItem: paper.Item | null = null
    selectedSegment: paper.Segment | null = null

    _canvasMode: CanvasMode = "navigating"

    resize$ = new Subject<void>()

    $isInitialized = signal<boolean>(false)
    $isLoadingRevision = signal<boolean>(false)
    $isLoadingThumbnail = signal<boolean>(false)
    $isLoadingDrawing = signal<boolean>(false)
    $isLoading = computed<boolean>(() => !this.$isInitialized() || this.$isLoadingRevision() || this.$isLoadingThumbnail() || this.$isLoadingDrawing())
    $isResizing = signal<boolean>(false)
    $showDrawing = computed<boolean>(() => !this.$isLoading() && !this.$isResizing())

    logicalSize = new paper.Size(320, 320)

    private visualPins = new Map<string, VisualPin>()
    private saveDrawingSubject: Subject<void> = new Subject<void>()

    ngOnInit() {
        this.scope = new paper.PaperScope()
        this.project = new this.scope.Project(this.canvasElementRef.nativeElement)
        this.tool = new this.scope.Tool()
        this.drawingLayer = new this.scope.Layer()
        this.pinLayer = new this.scope.Layer()

        this.pictureRevision$
            .pipe(
                switchMap(({picture}) => this.tasksService.tasks$(picture.id, ContentTypeModel.Picture)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(async (tasks) => {
                const pins = tasks?.filter(IsNonNull)?.filter((task) => task.state !== TaskState.Archived) ?? []
                this.removePins()
                this.resizeDrawing()
                await this.initPins(pins)
            })

        this.tasksService.selectedTaskId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((taskId) => {
            this.highlightPin(taskId)
        })

        this.saveDrawingSubject
            .pipe(auditTime(500))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.saveDrawing())

        this.pictureRevision$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((_value) => {
            this.$isLoadingDrawing.set(true)
            const drawingData = this.pictureRevision?.drawingData?.[0]?.dataObject
            if (drawingData) {
                this.loadDrawing(drawingData?.thumbnail?.downloadUrl ?? drawingData?.downloadUrl ?? null)
            } else {
                this.loadDrawing(undefined)
            }
            this.$isLoadingDrawing.set(false)
        })

        this.drawing.canvasMode$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mode) => {
            this.canvasMode = mode
        })

        this.drawing.clearDrawing$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            void this.deleteDrawing()
        })

        this.resize$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => this.$isResizing.set(true)),
                debounceTime(500),
            )
            .subscribe(() => {
                this.$isResizing.set(false)
                setTimeout(() => {
                    this.resizeDrawing()
                    this.updatePins()
                })
            })
    }

    ngAfterViewInit() {
        this.initMouseEvents()
    }

    protected onThumbnailLoaded() {
        this.$isLoadingThumbnail.set(false)
    }

    protected resizeDrawing() {
        const dimensions = this.canvasContainer.nativeElement.getBoundingClientRect()
        this.project.view.viewSize = new paper.Size(dimensions.width, dimensions.height)
        const scaleWidth = dimensions.width / this.logicalSize.width
        const scaleHeight = dimensions.height / this.logicalSize.height
        this.drawingLayer.view.scaling = new paper.Point(scaleWidth, scaleHeight)
        this.drawingLayer.view.center = new paper.Point(this.logicalSize.width / 2, this.logicalSize.height / 2)
    }

    get canvasMode(): CanvasMode {
        return this._canvasMode
    }

    set canvasMode(value: CanvasMode) {
        if (this.drawingLayer) {
            this.drawingLayer.selected = false
        }
        this.selectedItem = null
        this.selectedSegment = null
        switch (value) {
            case "drawing": {
                this.pinLayer.visible = false
                this.drawingLayer.activate()
                break
            }
            case "navigating": {
                if (this._canvasMode !== "navigating") {
                    this.saveDrawingSubject.next()
                }
                if (this.pinLayer) {
                    this.pinLayer.visible = true
                    this.pinLayer.activate()
                }
                break
            }
        }
        this._canvasMode = value
    }

    initMouseEvents(): void {
        fromEvent<paper.ToolEvent>(this.tool, "mousedown")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: paper.ToolEvent) => {
                if (this.canvasMode === "drawing") {
                    this.drawingPath = new paper.Path()
                    this.drawingPath.strokeColor = new paper.Color(this.drawing.$color())
                    this.drawingPath.strokeWidth = 1.5
                }

                if (this.canvasMode === "editing") {
                    this.selectedItem = null
                    this.selectedSegment = null
                    this.drawingLayer.selected = false
                    const hitResult: paper.HitResult = this.drawingLayer.hitTest(event.point, DrawingHitOptions)
                    if (!hitResult) return
                    this.selectedItem = hitResult.item
                    if (hitResult.type === "segment") {
                        this.selectedSegment = hitResult.segment
                    }
                    this.selectedItem.selected = true
                }

                if (this.canvasMode === "navigating") {
                    const hitResult: paper.HitResult = this.pinLayer.hitTest(event.point, NavigationHitOptions)
                    if (!hitResult) this.imageClick.emit()
                }
            })

        fromEvent<paper.ToolEvent>(this.tool, "mousedrag")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: paper.ToolEvent) => {
                if (this.canvasMode === "drawing") {
                    this.drawingPath?.add(event.point)
                }

                if (this.canvasMode === "editing") {
                    if (this.selectedSegment) {
                        const padding = 5
                        const maxX: number = this.project.view.viewSize.width - padding
                        const maxY: number = this.project.view.viewSize.height - padding
                        let newX: number = this.selectedSegment.point.x + event.delta.x
                        let newY: number = this.selectedSegment.point.y + event.delta.y
                        if (newX > maxX) newX = maxX
                        if (newX < padding) newX = padding
                        if (newY > maxY) newY = maxY
                        if (newY < padding) newY = padding
                        this.selectedSegment.point.x = newX
                        this.selectedSegment.point.y = newY
                    } else if (this.selectedItem) {
                        this.selectedItem.position.x += event.delta.x
                        this.selectedItem.position.y += event.delta.y
                    }
                }
            })

        fromEvent<paper.ToolEvent>(this.tool, "mousemove")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: paper.ToolEvent) => {
                this.canvasElementRef.nativeElement.style.cursor = "default"
                if (this.canvasMode === "editing") {
                    this.drawingLayer.selected = false
                    if (this.selectedItem) this.selectedItem.selected = true
                    const hitResult: paper.HitResult = this.drawingLayer.hitTest(event.point, DrawingHitOptions)
                    if (!hitResult) return
                    hitResult.item.selected = true
                }
                if (this.canvasMode === "navigating") {
                    const hitResult: paper.HitResult = this.pinLayer.hitTest(event.point, NavigationHitOptions)
                    if (hitResult) {
                        this.canvasElementRef.nativeElement.style.cursor = "grab"
                    } else {
                        this.canvasElementRef.nativeElement.style.cursor = "zoom-in"
                    }
                }
            })

        fromEvent<paper.ToolEvent>(this.tool, "mouseup")
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: paper.ToolEvent) => {
                switch (this.canvasMode) {
                    case "editing": {
                        // save curve edits
                        this.saveDrawingSubject.next()
                        break
                    }
                    case "drawing": {
                        this.saveDrawingSubject.next()
                        this.drawingPath?.add(event.point)
                        this.drawingPath?.smooth()
                        this.drawingPath?.simplify(10)
                    }
                }
            })

        this.tool.onKeyDown = (event: paper.KeyEvent) => {
            if (event.key === "delete" && this.selectedItem) {
                this.selectedItem.remove()
                this.saveDrawingSubject.next()
            }
        }
    }

    private loadDrawing(downloadUrl: string | null | undefined): void {
        this.drawingLayer.removeChildren()
        if (downloadUrl) {
            this.drawingLayer.importSVG(downloadUrl, {
                applyMatrix: true,
                onLoad: () => {
                    if (this.drawingSVG?.width && this.drawingSVG.height) {
                        this.drawingLayer.scale(
                            this.logicalSize.width / this.drawingSVG.width,
                            this.logicalSize.height / this.drawingSVG.height,
                            new paper.Point(0, 0),
                        )
                    }
                    this.resizeDrawing()
                    this.updatePins()
                },
            })
        }
    }

    private async saveDrawing() {
        const mimeType = "image/svg+xml"
        const drawingData: string = this.drawingLayer.exportSVG({asString: true}) as string
        const blob: Blob = new Blob([drawingData], {type: mimeType})
        const fileName = `picture-revision-${this.pictureRevision?.number ?? 0}-drawing.svg`
        const file: File = new File([blob], fileName, {type: mimeType})

        // In case there already is a drawing, overwrite it if possible
        const drawingDataItem: FeedbackCanvasDataObjectFragment | undefined = this.pictureRevision?.drawingData?.[0]?.dataObject
        // Note that the data object might have been created on prod, so we won't necessarily be able to overwrite it
        // Only try to get an upload URL if the data object is in a bucket matching the current environment
        const signedUploadUrl = drawingDataItem?.bucketName?.startsWith(environment.storage.bucketsPrefix)
            ? await this.sdk.gql
                  .feedbackCanvasItemUploadLink({
                      dataObjectId: drawingDataItem.id,
                  })
                  .then(({dataObject: {signedUploadUrl}}) => signedUploadUrl)
                  .catch((error) => {
                      console.error(`Cannot get signed upload url for existing drawing data object: ${error}`)
                      return undefined
                  })
            : undefined
        if (drawingDataItem && signedUploadUrl) {
            await this.notifications.withUserFeedback(
                async () => {
                    const {updateDataObject: updatedDrawingData} = await this.sdk.throwable.feedbackCanvasUpdateDrawingData({
                        input: {
                            id: drawingDataItem.id,
                            width: this.logicalSize.width,
                            height: this.logicalSize.height,
                        },
                    })
                    await this.uploadService.uploadFileToUrl(file, signedUploadUrl, false, mimeType)
                    const existingDrawingData = this.pictureRevision?.drawingData?.[0]
                    if (existingDrawingData && this.pictureRevision) {
                        this.pictureRevision.drawingData = [{...existingDrawingData, dataObject: updatedDrawingData}]
                    }
                },
                {
                    error: "Cannot save drawing",
                },
            )
        } else {
            await this.notifications.withUserFeedback(
                async () => {
                    const pictureRevisionId = this.pictureRevision?.id
                    if (pictureRevisionId) {
                        const dataObject = await this.uploadService.createAndUploadDataObject(
                            file,
                            {
                                organizationId: this.organizationId,
                                state: DataObjectState.Completed,
                                type: DataObjectType.Image,
                                width: this.logicalSize.width,
                                height: this.logicalSize.height,
                            },
                            {showUploadToolbar: false},
                        )
                        const {createDataObjectAssignment: drawingDataAssignment} = await this.sdk.gql.feedbackCanvasCreateDataObjectAssignment({
                            input: {
                                contentTypeModel: ContentTypeModel.PictureRevision,
                                dataObjectId: dataObject.id,
                                objectId: pictureRevisionId,
                                type: DataObjectAssignmentType.DrawingData,
                            },
                        })
                        if (this.pictureRevision) {
                            this.pictureRevision.drawingData = [drawingDataAssignment]
                        }
                    }
                },
                {
                    error: "Cannot save drawing",
                },
            )
        }
    }

    private async deleteDrawing() {
        if (!this.pictureRevision) return
        const drawingDataItemAssignment = this.pictureRevision?.drawingData?.[0]
        if (!drawingDataItemAssignment) return

        await this.sdk.gql.feedbackCanvasDeleteDataObjectAssignment(drawingDataItemAssignment)
        this.$isLoadingDrawing.set(true)
        this.loadDrawing(undefined)
        this.$isLoadingDrawing.set(false)
    }

    createPin = (task: FeedbackCanvasTaskFragment) => {
        return this.notifications.withUserFeedback(
            async () => {
                const {createTaskPin: pin} = await this.sdk.gql.feedbackCanvasCreateTaskPin({
                    input: {
                        taskId: task.id,
                        x: 0.5,
                        y: 0.5,
                    },
                })
                return pin
            },
            {
                error: "Cannot create comment marker",
            },
        )
    }

    addPinToCanvas(task: FeedbackCanvasTaskFragment): VisualPin | undefined {
        if (task.pins.length === 0) {
            throw new Error("Task does not have any pins")
        }
        const visualPin: VisualPin = new VisualPin(
            this.project,
            this.$can().update.task({organization: {id: this.organizationId}}, "pins"),
            async (position: {x: number; y: number}) =>
                this.notifications.withUserFeedback(
                    async () => {
                        await this.sdk.gql.feedbackCanvasUpdateTaskPinPosition({
                            input: {
                                id: task.pins[0].id,
                                ...position,
                            },
                        })
                    },
                    {
                        success: "Pin's position updated",
                        error: "Could not update pin's position",
                    },
                ),
        )
        visualPin.taskPin = task.pins[0]
        visualPin.task = task
        visualPin.draw()
        this.visualPins.set(task.id, visualPin)
        visualPin.click.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.tasksService.selectTask(task)
        })
        return visualPin
    }

    async initPins(tasks: Array<FeedbackCanvasTaskFragment>) {
        for (const task of tasks) {
            if (task.pins.length == 0) {
                const newPin = await this.createPin(task)
                if (newPin) {
                    task.pins = [newPin]
                    this.addPinToCanvas(task)
                }
            } else {
                this.addPinToCanvas(task)
            }
        }
    }

    updatePins(): void {
        for (const visualPin of this.visualPins.values()) {
            visualPin.update()
        }
    }

    removePins(): void {
        for (const visualPin of this.visualPins.values()) {
            visualPin.remove()
        }
        this.visualPins.clear()
    }

    highlightPin(taskId: string | null): void {
        // Remove highlight from all pins
        for (const visualPin of this.visualPins.values()) {
            visualPin.unhighlight()
        }

        if (taskId) {
            this.visualPins.get(taskId)?.highlight()
        }
    }

    get thumbnailDataObject() {
        // TODO: show thumbnail of previous revision if current revision is not yet available
        return this.pictureRevision?.pictureData?.[0]?.dataObject
    }

    ngOnDestroy() {
        this.removePins()
        this.project?.remove()
    }
}
