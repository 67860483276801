import {HttpErrorResponse} from "@angular/common/http"
import {computed, inject, Injectable, Signal} from "@angular/core"
import {toSignal} from "@angular/core/rxjs-interop"
import {MatSnackBar} from "@angular/material/snack-bar"
import {AuthenticatedUserFragment, ContentTypeModel, MutationLoginInput, SystemRole} from "@api"
import {IsNotUndefined} from "@cm/lib/utils/filter"
import {decodeToken, localStorageToken} from "@common/helpers/auth/token"
import {TokenService} from "@common/services/auth/token.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Apollo} from "apollo-angular"
import {BehaviorSubject, filter, firstValueFrom, map, Observable, throwError as observableThrowError} from "rxjs"
import {ActivatedRoute, Router} from "@angular/router"
import {ErrorCode} from "@common/models/errors"
import {extractErrorInfo} from "@common/helpers/api/errors"

// TODO: make this configurable
const COLORMASS_ORGANIZATION_ID = "023aa8d4-9540-4be8-b9bd-2cc047bb6923"

@Injectable()
export class AuthService {
    // null if the user is not logged in
    // undefined during initial app load and during login until the user has been loaded
    // otherwise a user fragment
    public user$ = new BehaviorSubject<AuthenticatedUserFragment | null | undefined>(undefined)
    // undefined during initial app load and during login until the user has been loaded
    // null if the user is not logged in
    // otherwise a user fragment
    public $user: Signal<AuthenticatedUserFragment | null | undefined>

    userId$ = new BehaviorSubject<string | null | undefined>(undefined)
    isStaff$: Observable<boolean>

    $actingAsCustomer = computed(() => {
        const activeMembership = this.sdk.$silo()
        return activeMembership?.organization?.id !== COLORMASS_ORGANIZATION_ID
    })

    public initialLoadCompleted$ = new BehaviorSubject<boolean>(false)

    apollo = inject(Apollo)
    notifications = inject(NotificationsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    snackBar = inject(MatSnackBar)
    router = inject(Router)
    token = inject(TokenService)

    constructor() {
        this.$user = toSignal(this.user$, {initialValue: undefined})

        this.isStaff$ = this.user$.pipe(
            filter((user) => user !== undefined),
            map((user) => !!user?.isStaff),
        )

        // allow the refresh service to trigger a reload of the current user
        // e.g. when the user's details change
        this.refresh
            .keepFetched$<AuthenticatedUserFragment>(this.userId$, ContentTypeModel.User, (id) =>
                this.sdk.gql.authenticatedUser(id).catch((error) => {
                    console.error(error)
                    this.logOut()
                    this.notifications.showInfo("You have been logged out automatically.", 10000)
                    return null
                }),
            )
            .subscribe((reloadedUser) => {
                this.user$.next(reloadedUser)
            })

        this.loadUserIdFromStorage().then((userId) => {
            this.userId$.next(userId)
            this.initialLoadCompleted$.next(true)
        })
    }

    get user(): Promise<AuthenticatedUserFragment | null> {
        return firstValueFrom(this.user$.pipe(filter(IsNotUndefined)))
    }

    async loadUserIdFromStorage(): Promise<string | null> {
        const token = localStorageToken()
        if (!token) {
            return null
        }

        try {
            const decodedToken = decodeToken(token)
            return decodedToken?.id ?? null
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async logIn(credentials: MutationLoginInput): Promise<boolean> {
        this.user$.next(undefined)
        try {
            const {
                login: {user: userIds, token},
            } = await this.sdk.gql.performLogin({input: credentials})
            this.storeToken(token)
            await this.sdk.loadSiloFromLocalStorage()
            // set an appropriate auth silo, unless it's already set
            if (!this.sdk.$silo()?.organization) {
                const {user} = await this.sdk.gqlWithoutSilo.authSiloData({id: userIds.id})
                if (user.role === SystemRole.Superadmin) {
                    await this.sdk.activateSystemRole(SystemRole.Superadmin)
                } else if (user.role === SystemRole.Staff) {
                    await this.sdk.activateSystemRole(SystemRole.Staff)
                } else if (user.memberships && user.memberships.length > 0) {
                    this.sdk.activateMembership(user.memberships[0])
                }
            }
            this.userId$.next(userIds.id)
            await this.apollo.client.resetStore()
            return true
        } catch (error) {
            if (`${error}`.startsWith("TypeError: Network request failed")) {
                console.error(error)
                this.snackBar.open("Login failed. Server unavailable.", "", {duration: 3000})
                this.handleError(error as HttpErrorResponse)
                this.userId$.next(null)
                return false
            } else {
                console.error(error)
                this.snackBar.open("Login failed. Wrong email or password.", "", {duration: 3000})
                this.handleError(error as HttpErrorResponse)
                this.userId$.next(null)
                return false
            }
        }
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        console.error(error)
        let errorMessage: string = error.message || "An error occurred."
        if (error.error) {
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred.
                errorMessage = error.error.message
            } else if (error.error.detail !== undefined) {
                // The backend returned an unsuccessful response code.
                errorMessage = error.error.detail
            }
        }
        return observableThrowError(errorMessage)
    }

    public logOut(): void {
        try {
            localStorage.removeItem("token")
            // removing these causes the silo selection to be lost when logging out and back in
            // however, it also leads to authentication errors if the user logs in with a different account (!)
            localStorage.removeItem("cm-auth-silo-system-role")
            localStorage.removeItem("cm-auth-silo-organization")
        } catch {
            // probably don't have access to localStorage, should be safe to ignore
        }
        this.sdk.$silo.set(null)
        this.userId$.next(null)
        void this.apollo.client.resetStore()
    }

    public storeToken(token: string) {
        this.token.store(token)
        void this.apollo.client.resetStore()
    }

    isLoggedIn() {
        return !!this.userId$.value
    }

    isStaff() {
        if (!this.$user()) {
            return false
        }
        return this.$user()?.isStaff ?? false
    }

    isSuperuser() {
        if (!this.$user()) {
            return false
        }
        return this.$user()?.isSuperuser ?? false
    }

    /**
     * Handle an authorization errors for queries that are essential for the app to function. If such a query fails,
     * the page should not be shown at all.
     */
    handleAuthorizationError = (route: ActivatedRoute) => async (error: unknown) => {
        const errorInfo = extractErrorInfo(error)
        const state = route.snapshot
        switch (errorInfo.code) {
            case ErrorCode.Forbidden:
                await this.router.navigate(["/unauthorized"])
                return
            case ErrorCode.Unauthenticated:
                await this.router.navigate(["/login"], {queryParams: {returnUrl: state.url}})
                return
        }
        throw error
    }
}
