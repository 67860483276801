<div class="cm-title">
    {{ labelText() }}
</div>
<cm-card
    [defaultThumbnail]="false"
    (click)="openSelectMaterialDialog()"
    class="cm-accept-nodes"
    [class.cm-drop]="highlighted()"
    [draggable]="true"
    (dragstart)="dragStart($event)"
    (dragend)="drag.dragEnd($event)"
>
    <ng-container cm-thumbnail>
        @if (thumbnailObject(); as thumbnailData) {
            <cm-data-object-thumbnail [dataObjectId]="thumbnailData.id"></cm-data-object-thumbnail>
        } @else {
            @if (thumbnailUrl(); as thumbnailData) {
                <cm-thumbnail [url]="thumbnailData"></cm-thumbnail>
            }
        }
    </ng-container>
    <ng-container cm-title>
        @if (material(); as material) {
            <cm-template-node [node]="material" [noDrag]="true" #dragImage></cm-template-node>
        } @else {
            <i class="fa fa-sign-in"></i>
            <span class="cm-material-title-none"> (No Material) </span>
        }
    </ng-container>
    <ng-container cm-top-left>
        @if (node(); as node) {
            <div matTooltip="Remove assignment" (click)="$event.stopPropagation(); removeAssignment()" class="cm-icon">
                <i class="far fa-trash"></i>
            </div>
        }
    </ng-container>
    <ng-container cm-top-right>
        @if (node(); as node) {
            <div matTooltip="Move texture" (click)="$event.stopPropagation()" [matMenuTriggerFor]="meshUVMenu" class="cm-icon">
                <i class="far fa-arrows"></i>
                <!-- hasBackdrop needs to be false in order for node drag and drop to work! -->
                <mat-menu [hasBackdrop]="false" #meshUVMenu>
                    <div (click)="$event.stopPropagation()" class="cm-overlay-settings">
                        <cm-inspector-section>
                            <ng-container cm-title> Texture Settings </ng-container>
                            <ng-container cm-content>
                                <cm-value-slot [node]="node" [key]="'horizontalOffset'" [label]="'Horizontal'"></cm-value-slot>
                                <cm-value-slot [node]="node" [key]="'verticalOffset'" [label]="'Vertical'"></cm-value-slot>
                                <cm-value-slot [node]="node" [key]="'rotation'" [label]="'Rotation'"></cm-value-slot>
                            </ng-container>
                        </cm-inspector-section>
                    </div>
                </mat-menu>
            </div>
        }
    </ng-container>
    <ng-container cm-bottom-left>
        @if (isReference()) {
            <div matTooltip="Node is a reference" (click)="$event.stopPropagation(); gotoReference()" class="cm-icon">
                <i class="far fa-external-link"></i>
            </div>
        }
    </ng-container>
    <ng-container cm-bottom-right>
        @if (node(); as node) {
            <div matTooltip="Side: {{ sideLabel() }}" (click)="$event.stopPropagation()" [matMenuTriggerFor]="meshSideMenu" class="cm-icon">
                <mat-menu #meshSideMenu>
                    <div (click)="$event.stopPropagation()" class="cm-overlay-settings">
                        <cm-inspector-section>
                            <ng-container cm-title> Texture Settings </ng-container>
                            <ng-container cm-content>
                                <cm-value-slot [node]="node" [key]="'side'" [label]="'Side'" [selectionPossibilities]="sidePossibilities"></cm-value-slot>
                            </ng-container>
                        </cm-inspector-section>
                    </div>
                </mat-menu>
                <i class="far fa-bring-forward"></i>
            </div>
        }
    </ng-container>
</cm-card>
