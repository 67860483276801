import {NgTemplateOutlet, UpperCasePipe} from "@angular/common"
import {Component, inject, Input, OnInit, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule} from "@angular/material/core"
import {MatDialog} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelect} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {ContentTypeModel, DataObjectAssignmentType, MaterialOutputsDataFragment, MaterialOutputTileableImageFragment} from "@api"
import {MaterialMapsExporter} from "@cm/lib/materials/material-maps-exporter"
import {IsDefined} from "@cm/lib/utils/filter"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {HintComponent} from "@common/components/hint/hint.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {ImageViewerComponent} from "@common/components/viewers"
import {
    defaultExportEngine,
    defaultExportFormat,
    defaultExportNormalY,
    defaultExportResolution,
    defaultExportWorkflow,
    getDefaultExportConfigs,
} from "@common/helpers/material-maps-exporter"
import {MaterialAssetsRenderingService} from "@common/services/material-assets-rendering/material-assets-rendering.service"
import {
    MaterialMapsExporterService,
    MaterialMapsExportQueryResult as MapsExportQueryResult,
} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {FlatOption, FlatThumbnailOptionLabels} from "@labels"
import {MaterialExportsListComponent} from "@platform/components/materials/material-exports-list/material-exports-list.component"
import {FlatMaterialOutput} from "@platform/models/material-outputs/flat-option"

@Component({
    selector: "cm-material-outputs",
    standalone: true,
    imports: [
        MatTooltipModule,
        MatMenuModule,
        RouterModule,
        MatButtonModule,
        ButtonComponent,
        NgTemplateOutlet,
        ImageViewerComponent,
        MaterialExportsListComponent,
        MatInputModule,
        MatOptionModule,
        MatSelect,
        FormsModule,
        UpperCasePipe,
        HintComponent,
        PlaceholderComponent,
    ],
    templateUrl: "./material-outputs.component.html",
    styleUrl: "./material-outputs.component.scss",
})
export class MaterialOutputsComponent implements OnInit {
    @Input({required: true}) materialId!: string
    @ViewChild("imageViewer", {static: true}) imageViewer!: ImageViewerComponent

    material?: MaterialOutputsDataFragment

    showFlatOptions = false
    showMapsExports = false
    showCustomMapsExports = false

    exportSourceInfoRequest?: MaterialMapsExporter.SourceInfoRequest
    selectedExportWorkflow = defaultExportWorkflow
    selectedExportEngine = defaultExportEngine
    selectedExportNormalY = defaultExportNormalY
    selectedExportFormat = defaultExportFormat
    selectedExportResolution = defaultExportResolution
    exportWorkflows = MaterialMapsExporter.workflows
    exportEngines = MaterialMapsExporter.engines
    exportNormalsY = MaterialMapsExporter.normalsY
    exportFormats = MaterialMapsExporter.formats
    exportResolutions = MaterialMapsExporter.resolutions

    flatOptions?: FlatMaterialOutput[]
    flatImageDataObjectIds: string[] = []
    numberOfFlatImages = 0
    tileableImage?: MaterialOutputTileableImageFragment

    allExports: MapsExportQueryResult[] = []
    customExports: MapsExportQueryResult[] = []
    defaultExports: (MaterialMapsExporter.Request & {displayName: string; items: MapsExportQueryResult[]})[] = []

    permission = inject(PermissionsService)
    matDialog = inject(MatDialog)
    materialAssetsRenderingService = inject(MaterialAssetsRenderingService)
    materialMapsExporterService = inject(MaterialMapsExporterService)
    notifications = inject(NotificationsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    $can = this.permission.$to

    // TODO: Tileable images were downloaded as only zip files in the past.
    //  For those contents, no download image menu should appear and they should be downloaded directly.
    //  This checking is done with this method. When such old contents are replaced this method will be removed.
    get tileableImageIsZipFile(): boolean {
        return this.tileableImage?.mediaType === "application/zip"
    }

    ngOnInit() {
        void this.loadFlatOptions().then((material) => {
            this.refresh.observeItem$(material).subscribe(() => {
                void this.loadFlatOptions()
            })

            this.flatOptions = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => {
                return {
                    dataObject: material?.dataObjectAssignments?.find(
                        (dataObjectAssignment) => dataObjectAssignment.assignmentType === flatOption.state.dataObjectAssignmentType,
                    )?.dataObject,
                    flatOption: flatOption.state,
                    dataObjectAssignmentType: flatOption.state.dataObjectAssignmentType,
                    label: flatOption.label,
                }
            })
            this.flatImageDataObjectIds = Object.values(this.flatOptions)
                .map((value) => value.dataObject?.id)
                .filter(IsDefined)
        })
    }

    async loadFlatOptions() {
        const {material} = await this.sdk.gql.materialOutputsData({id: this.materialId})
        this.material = material
        this.tileableImage = material.dataObjectAssignments.find(
            (dataObjectAssignment) => dataObjectAssignment?.assignmentType === DataObjectAssignmentType.MaterialTileableRender,
        )?.dataObject
        this.flatOptions = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => {
            return {
                dataObject: material.dataObjectAssignments?.find(
                    (dataObjectAssignment) => dataObjectAssignment.assignmentType === flatOption.state.dataObjectAssignmentType,
                )?.dataObject,
                flatOption: flatOption.state,
                dataObjectAssignmentType: flatOption.state.dataObjectAssignmentType,
                label: flatOption.label,
            }
        })
        this.flatImageDataObjectIds = Object.values(this.flatOptions)
            .map((value) => value.dataObject?.id)
            .filter(IsDefined)
        this.numberOfFlatImages = this.flatImageDataObjectIds.length

        this.exportSourceInfoRequest = material.latestCyclesRevision ? {source: "materialRevision"} : undefined
        await this.loadMapsExports()

        return material
    }

    async loadMapsExports() {
        const materialLegacyId = this.material?.legacyId
        if (!materialLegacyId) {
            return
        }
        this.allExports = await this.materialMapsExporterService.queryMapsExportsForMaterial({legacyId: materialLegacyId}).catch((err) => {
            this.notifications.showError(`Failed to to load maps exports info: ${err}`, 3000)
            return []
        })
        this.defaultExports = getDefaultExportConfigs().map((x) => {
            return {...x, items: this.allExports.filter((y) => y.config.root.name === x.root.name)}
        })
        this.customExports = this.allExports.filter((x) => this.defaultExports.every((y) => y.root.name !== x.config.root.name))
    }

    openImageViewer(dataObjectId: string): void {
        void this.imageViewer.openViewer(dataObjectId, this.flatImageDataObjectIds)
    }

    deleteDataObject(dataObjectId: string): void {
        const dialogRef = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Delete item",
                message: "Are you sure you want to remove the file?",
                confirmLabel: "Delete item",
                cancelLabel: "Cancel",
            },
        })

        dialogRef.afterClosed().subscribe(async (confirmed) => {
            if (!confirmed) {
                return
            }
            await this.sdk.gql
                .materialOutputsDeleteDataObject({id: dataObjectId})
                .then(() => this.refresh.item(this.material))
                .catch(() => {
                    this.notifications.showInfo("Cannot delete file.", 3000)
                })
        })
    }

    generateCustomMapsExport() {
        const exportName = this.material?.name ?? ""
        const conversionRequest = MaterialMapsExporter.conversionRequest(
            this.selectedExportWorkflow,
            this.selectedExportEngine,
            this.selectedExportNormalY,
            this.selectedExportFormat,
            this.selectedExportResolution,
        )
        if (this.exportSourceInfoRequest) {
            const exportRequest = MaterialMapsExporter.exportRequest(
                MaterialMapsExporter.exportFolder(
                    [conversionRequest, MaterialMapsExporter.conversionInfoRequest(conversionRequest, "info", "text")],
                    exportName,
                ),
                this.exportSourceInfoRequest,
            )

            return this.generateExport(exportRequest)
        }
    }

    generateExport = (exportRequest: MaterialMapsExporter.Request) => {
        const materialId = this.material?.id
        if (materialId) {
            this.materialMapsExporterService
                .generateMapsExport(exportRequest, {id: materialId})
                .then((jobId) => {
                    this.notifications.showInfo(`Maps Export generation: Job submitted (${jobId})`)
                })
                .catch((err) => {
                    this.notifications.showInfo(`Maps Export generation: Failed (${err})`)
                })
                .finally(() => {
                    this.refresh.item({id: materialId, __typename: ContentTypeModel.Material})
                })
        }
    }

    generateShaderBallThumbnailForMaterial() {
        const resolution = 2000
        const materialId = this.material?.id
        if (materialId) {
            this.materialAssetsRenderingService.generateShaderBallThumbnail(materialId, resolution).then(() => {
                this.notifications.showInfo("Shader ball generation: Job submitted", undefined, "Dismiss")
            })
        }
    }

    generateThumbnailForMaterial(flatOption: FlatOption) {
        const materialId = this.material?.id
        if (materialId && this.materialAssetsRenderingService.checkThumbnailSizeValid(flatOption.resolution, flatOption.size)) {
            this.materialAssetsRenderingService.generateThumbnail(materialId, flatOption).then(() => {
                this.notifications.showInfo("Flat thumbnail generation: Job submitted", undefined, "Dismiss")
            })
        } else {
            this.notifications.showInfo("Requested resolution too high", 3000)
        }
    }

    generateTileForMaterial() {
        const materialId = this.material?.id
        if (materialId) {
            this.materialAssetsRenderingService.generateTile(materialId).then(() => {
                this.notifications.showInfo("Tileable image generation: Job submitted", undefined, "Dismiss")
            })
        }
    }

    sourceInfoRequestToString = (sourceInfoRequest: MaterialMapsExporter.SourceInfoRequest) => {
        switch (sourceInfoRequest.source) {
            case "materialRevision": {
                if (sourceInfoRequest.sourceId === undefined) {
                    return `Latest material revision`
                }
                const revision = this.material?.revisions?.find((revision) => revision.legacyId === sourceInfoRequest.sourceId)
                if (revision) {
                    return `Material revision ${revision.number}`
                } else {
                    return `Material revision id ${sourceInfoRequest.sourceId}`
                }
            }
            case "textureSet":
                if (sourceInfoRequest.sourceId === undefined) {
                    throw new Error("sourceId is undefined for textureSet")
                }
                return `Texture set ${sourceInfoRequest.sourceId}`
        }
    }

    protected readonly MaterialMapsExporter = MaterialMapsExporter
}
