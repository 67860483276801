import {registerNode} from "@src/graph-system/register-node"
import {objectLike} from "@src/templates/node-types"
import {z} from "zod"
import {DeclareTransformNode, TemplateTransformNode} from "@src/templates/declare-transform-node"
import {skipped, visitNone} from "@src/graph-system/declare-visitor-node"
import {SolverRelationData} from "@src/templates/runtime-graph/nodes/solver/relation-data"
import {ThisStructID} from "@src/templates/runtime-graph/types"

const rigidRelationParameters = z.object({
    targetA: objectLike.nullable(),
    targetB: objectLike.nullable(),
})
export type RigidRelationParameters = z.infer<typeof rigidRelationParameters>

@registerNode
export class RigidRelation extends DeclareTransformNode(
    {parameters: rigidRelationParameters},
    {
        onVisited: {
            onFilterActive: ({parameters}) => {
                if (parameters.targetA === null || parameters.targetB === null) return skipped
                return visitNone(parameters)
            },
            onCompile: function (this: RigidRelation, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {solverData} = currentTemplate
                const {translation, rotation} = parameters
                const {solverRelations} = solverData

                const scope = evaluator.getScope(this)

                const [targetA, targetAInvalid] = scope.branch(evaluator.evaluateObject(scope, parameters.targetA))
                const [targetB, targetBInvalid] = scope.branch(evaluator.evaluateObject(scope, parameters.targetB))

                const relation = scope.struct<SolverRelationData>("SolverRelationData", {
                    id: ThisStructID,
                    translation: this.setupTranslation(scope, context, translation),
                    rotation: this.setupRotation(scope, context, rotation),
                    objA: scope.get(targetA, "solverObject"),
                    objB: scope.get(targetB, "solverObject"),
                })
                solverRelations.push(scope.phi(relation, targetAInvalid, targetBInvalid))

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "RigidRelation"},
) {}

export type RigidRelationFwd = TemplateTransformNode<RigidRelationParameters>
