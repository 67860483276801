// @ts-strict-ignore

import {computeRgbCurveLUT} from "@src/materials/utils"
import {UnwrappedMaterialGraphNode} from "@src/materials/material-node-graph"

function normalizeName(text: string): string {
    return text.toLowerCase().replace(/ /g, "_")
}

/**
 * maps blender and colormass json node names to cycles internal node names
 */
const nodeTypeMap: Map<string, string> = new Map([
    ["UVMap", "uvmap"],
    ["Mapping", "mapping"],
    ["ShaderNodeRGBCurve", "rgb_curves"],
    ["ShaderNodeNormalMap", "normal_map"],
    ["OutputMaterial", "material_output"],
    ["ShaderNodeMath", "math"],
    ["BsdfPrincipled", "principled_bsdf"],
    ["ShaderNodeBsdfVelvet", "velvet_bsdf"],
    ["ShaderNodeFresnel", "fresnel"],
    ["ShaderNodeRGB", "color"],
    ["ShaderNodeValue", "value"],
    ["ShaderNodeBsdfGlass", "glass_bsdf"],
    ["ShaderNodeGamma", "gamma"],
    ["ShaderNodeBrightContrast", "brightness_contrast"],
    ["ShaderNodeHueSaturation", "hsv"],
    ["ShaderNodeInvert", "invert"],
    ["ShaderNodeDisplacement", "displacement"],
    ["ShaderNodeVectorDisplacement", "vector_displacement"],
    ["ShaderNodeCombineXYZ", "combine_xyz"],
    ["ShaderNodeCombineHSV", "combine_hsv"],
    ["ShaderNodeCombineRGB", "combine_rgb"],
    ["ShaderNodeSeparateXYZ", "separate_xyz"],
    ["ShaderNodeSeparateHSV", "separate_hsv"],
    ["ShaderNodeSeparateRGB", "separate_rgb"],
    ["ShaderNodeVectorMath", "vector_math"],
    ["ShaderNodeBlackbody", "blackbody"],
    ["ShaderNodeWavelength", "wavelength"],
    ["ShaderNodeTexChecker", "checker_texture"],
    ["ShaderNodeBump", "bump"],
    ["ShaderNodeNormal", "normal"],
    ["ShaderNodeRGBToBW", "rgb_to_bw"],
    ["ShaderNodeMixRGB", "mix"],
    ["ShaderNodeMixShader", "mix_closure"],
    ["ShaderNodeAddShader", "add_closure"],
    ["ShaderNodeValToRGB", "rgb_ramp"],
    ["ShaderNodeLightFalloff", "light_falloff"],
    ["ShaderNodeTexVoronoi", "voronoi_texture"],
    ["ShaderNodeBsdfDiffuse", "diffuse_bsdf"],
    ["ShaderNodeEmission", "emission"],
    ["ShaderNodeBsdfTranslucent", "translucent_bsdf"],
    ["ShaderNodeBsdfTransparent", "transparent_bsdf"],
    ["ShaderNodeNewGeometry", "geometry"],
    ["TexImage", "image_texture"],
    ["ShaderNodeLightPath", "light_path"],
    ["ShaderNodeTexCoord", "texture_coordinate"],
    ["ShaderNodeTexGradient", "gradient_texture"],
    ["ShaderNodeTexMagic", "magic_texture"],
    ["ShaderNodeTexMusgrave", "musgrave_texture"],
    ["ShaderNodeTexWave", "wave_texture"],
    ["ShaderNodeTexNoise", "noise_texture"],
    ["ShaderNodeSubsurfaceScattering", "subsurface_scattering"],
    ["ShaderNodeAmbientOcclusion", "ambient_occlusion"],
    ["ShaderNodeTangent", "tangent"],
])

const parameterSetConversionMap = new Map<string, (parameters: {[name: string]: any}) => {[name: string]: any}>([["ShaderNodeRGBCurve", generateRgbCurveLUT]])

// some input/sockets names from blender/colormass json are named differently in cycles
// WARNING: also see input/parameter whitelist in MaterialNodeGraph!
const nodeSocketMap: Map<string, string> = new Map([
    ["input math value", "value1"],
    ["input math value_001", "value2"],
    ["input math value_002", "value3"],
    ["input math internal.operation", "math_type"],
    ["input math internal.use_clamp", "use_clamp"],
    ["input principled_bsdf internal.subsurface_method", "subsurface_method"],
    ["input principled_bsdf internal.distribution", "distribution"],
    ["input principled_bsdf specular", "specular_ior_level"],
    ["input principled_bsdf subsurface", "subsurface_weight"],
    ["input principled_bsdf sheen", "sheen_weight"],
    ["input principled_bsdf clearcoat", "coat_weight"],
    ["input principled_bsdf clearcoat_roughness", "coat_roughness"],
    ["input principled_bsdf clearcoat_normal", "coat_normal"],
    ["input principled_bsdf transmission", "transmission_weight"],
    ["input glass_bsdf internal.distribution", "distribution"],
    ["input glass_bsdf ior", "IOR"],
    ["input image_texture internal.image.colorspace_settings.name", "colorspace"],
    ["input image_texture internal.interpolation", "interpolation"],
    ["input image_texture internal.extension", "extension"],
    ["input image_texture internal.projection", "projection"],
    ["input mapping internal.rotation", "rotation"],
    ["input mapping internal.scale", "scale"],
    ["input mapping internal.translation", "location"],
    ["input mapping internal.vector_type", "mapping_type"],
    ["input uvmap internal.from_instancer", "from_dupli"],
    ["input uvmap internal.uv_map_index", "attribute"],
    ["input normal_map internal.space", "space"],
    ["input normal_map internal.uv_map_index", "attribute"],
    ["output rgb_to_bw value", "val"],
    ["input displacement internal.space", "space"],
    ["input vector_displacement internal.space", "space"],
    ["input bump internal.invert", "invert"],
    ["input mix internal.blend_type", "mix_type"],
    ["input mix internal.use_clamp", "use_clamp"],
    ["input mix_closure shader", "closure1"],
    ["input mix_closure shader_001", "closure2"],
    ["output mix_closure shader", "closure"],
    ["input add_closure shader", "closure1"],
    ["input add_closure shader_001", "closure2"],
    ["output add_closure shader", "closure"],
    ["input fresnel ior", "IOR"],
    ["input rgb_curves color", "value"],
    ["output rgb_curves color", "value"],
    ["input vector_math internal.operation", "math_type"],
    ["input vector_math vector", "vector1"],
    ["input vector_math vector_001", "vector2"],
    ["input vector_math vector_002", "vector3"],
    ["input separate_rgb image", "color"],
    ["input separate_hsv image", "color"],
    ["input voronoi_texture internal.coloring", "coloring"],
    ["input voronoi_texture internal.distance", "metric"],
    ["input voronoi_texture internal.feature", "feature"],
    ["input rgb_ramp internal.color_ramp.cycles_ramp_color_table", "ramp"],
    ["input rgb_ramp internal.color_ramp.cycles_ramp_alpha_table", "ramp_alpha"],
    ["input color color", "value"],
    ["input tangent internal.direction_type", "direction_type"],
    ["input tangent internal.axis", "axis"],
])

export function mapColormassToCyclesNodeType(nodeType: string): string | undefined {
    return nodeTypeMap.get(nodeType)
}

export function mapColormassToCyclesNodeParameterSet(node: UnwrappedMaterialGraphNode): {[name: string]: any} {
    if (!node.parameters) return {}
    const parameterSetConversionFn = parameterSetConversionMap.get(node.nodeType)
    if (parameterSetConversionFn) {
        return parameterSetConversionFn(node.parameters)
    } else {
        return node.parameters
    }
}

export function mapColormassToCyclesInputName(nodeType: string, inputName: string): string {
    nodeType = normalizeName(nodeType)
    inputName = normalizeName(inputName)
    return nodeSocketMap.get(`input ${nodeType} ${inputName}`) ?? inputName
}

export function mapColormassToCyclesOutputName(nodeType: string, outputName: string): string {
    nodeType = normalizeName(nodeType)
    outputName = normalizeName(outputName)
    return nodeSocketMap.get(`output ${nodeType} ${outputName}`) ?? outputName
}

/**
 * some input parameters such as enums must be converted to what cycles expects as input values ¯\(°_o)/¯
 */

export namespace CyclesNodePropertyConversions {
    function mapEnum(map: any, defaultValue?: any) {
        return (value: any) => {
            if (value in map) {
                return map[value]
            } else if (defaultValue !== undefined) {
                console.warn(`WARNING: Unknown enum value '${value}', using default '${defaultValue}'`)
                return defaultValue
            } else {
                console.warn(`WARNING: Unknown enum value '${value}'`)
                return value
            }
        }
    }

    function mapEnumWithThru(map: any) {
        return (value: any) => {
            if (value in map) {
                return map[value]
            } else {
                return value
            }
        }
    }

    function ident(x: any) {
        return x
    }
    function vec4_vec3(x: any) {
        return [x[0], x[1], x[2]]
    }
    function float_to_vec3(x: number) {
        return [x, x, x]
    }

    // function m_cm(x: any) { return x * 0.01; }
    const subsurfaceMethod = ident
    const colorspace = mapEnum({linear: "__builtin_raw", "non-color": "__builtin_raw", srgb: "__builtin_srgb"}, "__builtin_srgb")
    const voronoiFeature = ident
    const extension = mapEnum({repeat: "periodic", extend: "clamp", clip: "black"})
    const interpolation = ident
    export function uvMapAttrName(x: any) {
        return `UVMap${x}`
    }

    type ConversionFn = (x: any) => any

    // undefined: no conversion
    // null: exclude parameter
    const parameterConversionMap: Map<string, ConversionFn | null> = new Map([
        ["input rgb_curves value", vec4_vec3],
        ["input normal_map color", vec4_vec3],
        ["input normal_map normal", null],
        ["input normal_map attribute", uvMapAttrName],
        ["input normal dot", null],
        ["input fresnel fac", null],
        ["input rgb_to_bw color", vec4_vec3],
        ["input rgb_to_bw val", null],
        ["input color value", vec4_vec3],
        ["input emission color", vec4_vec3],
        ["input velvet_bsdf color", vec4_vec3],
        ["input diffuse_bsdf color", vec4_vec3],
        ["input translucent_bsdf color", vec4_vec3],
        ["input transparent_bsdf color", vec4_vec3],
        ["input principled_bsdf base_color", vec4_vec3],
        ["input principled_bsdf alpha", null],
        ["input principled_bsdf emission", null],
        ["input principled_bsdf subsurface_method", subsurfaceMethod],
        ["input principled_bsdf sheen_tint", float_to_vec3],
        ["input principled_bsdf transmission_roughness", null],
        ["input glass_bsdf color", vec4_vec3],
        ["input uvmap uv", null],
        ["input uvmap attribute", uvMapAttrName],
        ["input mix color", null],
        ["input mix color1", vec4_vec3],
        ["input mix color2", vec4_vec3],
        ["input invert color", vec4_vec3],
        ["input hsv color", vec4_vec3],
        ["input brightness_contrast color", vec4_vec3],
        ["input gamma color", vec4_vec3],
        ["input rgb_ramp alpha", null],
        ["input rgb_ramp color", null],
        ["input light_falloff quadratic", null],
        ["input light_falloff linear", null],
        ["input light_falloff constant", null],
        ["input displacement displacement", null],
        ["input displacement scale", ident], // m_cm
        ["input vector_displacement displacement", null],
        ["input vector_displacement scale", ident], // m_cm
        ["input vector_displacement vector", vec4_vec3],
        ["input combine_xyz vector", null],
        ["input combine_hsv color", null],
        ["input combine_rgb image", null],
        ["input separate_xyz x", null],
        ["input separate_xyz y", null],
        ["input separate_xyz z", null],
        ["input separate_hsv color", vec4_vec3],
        ["input separate_hsv h", null],
        ["input separate_hsv s", null],
        ["input separate_hsv v", null],
        ["input separate_rgb color", vec4_vec3],
        ["input separate_rgb r", null],
        ["input separate_rgb g", null],
        ["input separate_rgb b", null],
        ["input vector_math value", null],
        ["input blackbody color", null],
        ["input wavelength color", null],
        ["input checker_texture color1", vec4_vec3],
        ["input checker_texture color2", vec4_vec3],
        ["input checker_texture color", null],
        ["input checker_texture fac", null],
        ["input voronoi_texture color", null],
        ["input voronoi_texture position", null],
        ["input voronoi_texture distance", null],
        ["input voronoi_texture radius", null],
        ["input voronoi_texture fac", null],
        ["input voronoi_texture feature", voronoiFeature],
        ["input image_texture alpha", null],
        ["input image_texture color", null],
        ["input image_texture colorspace", colorspace],
        ["input image_texture extension", extension],
        ["input image_texture interpolation", interpolation],
        ["input geometry position", null],
        ["input geometry normal", null],
        ["input geometry tangent", null],
        ["input geometry true_normal", null],
        ["input geometry incoming", null],
        ["input geometry parametric", null],
        ["input geometry backfacing", null],
        ["input geometry pointiness", null],
        ["input light_path is_camera_ray", null],
        ["input light_path is_shadow_ray", null],
        ["input light_path is_diffuse_ray", null],
        ["input light_path is_glossy_ray", null],
        ["input light_path is_singular_ray", null],
        ["input light_path is_reflection_ray", null],
        ["input light_path is_transmission_ray", null],
        ["input light_path is_volume_scatter_ray", null],
        ["input light_path ray_length", null],
        ["input light_path ray_depth", null],
        ["input light_path diffuse_depth", null],
        ["input light_path glossy_depth", null],
        ["input light_path transparent_depth", null],
        ["input light_path transmission_depth", null],
        ["input texture_coordinate camera", null],
        ["input texture_coordinate reflection", null],
        ["input texture_coordinate window", null],
        ["input texture_coordinate object", null],
        ["input texture_coordinate uv", null],
        ["input texture_coordinate normal", null],
        ["input texture_coordinate generated", null],
        ["input musgrave_texture color", null],
        ["input musgrave_texture fac", null],
        ["input magic_texture color", null],
        ["input magic_texture fac", null],
        ["input gradient_texture color", null],
        ["input gradient_texture fac", null],
        ["input wave_texture color", null],
        ["input wave_texture fac", null],
        ["input noise_texture color", null],
        ["input noise_texture fac", null],
        ["input subsurface_scattering color", vec4_vec3],
        ["input ambient_occlusion color", vec4_vec3],
        ["input ambient_occlusion distance", ident], // m_cm
        ["input ambient_occlusion ao", null], //TODO: Hack for "ao" output being included in list of parameters. Why does the blender plugin set this??
        ["input mapping internal.max", null],
        ["input mapping internal.min", null],
        ["input mapping internal.use_max", null],
        ["input bump height_dx", null], //FIXME A workaround for height_dx/height_dy outputs. Should be removed once this fixed on the blender plugin side
        ["input bump height_dy", null],
        ["input tangent tangent", null],
        ["input tangent attribute", uvMapAttrName],
    ])

    export function getParameterConversion(nodeType: string, parameterName: string): ConversionFn | null | undefined {
        return parameterConversionMap.get(`input ${nodeType} ${parameterName}`)
    }
}

export function mapColormassToCyclesProperty(nodeType: string, parameterName: string, value: any): [string, any] | null {
    parameterName = mapColormassToCyclesInputName(nodeType, parameterName)

    if (parameterName.includes("internal.")) {
        return null
    }

    if (typeof value === "string") {
        value = normalizeName(value)
    }

    const conversionFn = CyclesNodePropertyConversions.getParameterConversion(nodeType, parameterName)
    if (conversionFn === undefined) {
        // no conversion
    } else if (conversionFn === null) {
        return null // exclude this parameter
    } else {
        value = conversionFn(value)
    }

    return [parameterName, value]
}

// function split3(vector: MaterialGraphNode) {
//     const node: MaterialGraphNode = {
//         nodeType: 'separate_xyz',
//         parameters: {},
//         inputs: { vector }
//     };
//     return [
//         wrapNodeOutput(node, 'x'),
//         wrapNodeOutput(node, 'y'),
//         wrapNodeOutput(node, 'z')
//     ] as const;
// }

// function join(x: MaterialGraphNode, y: MaterialGraphNode, z: MaterialGraphNode) {
//     const node: MaterialGraphNode = {
//         nodeType: 'combine_xyz',
//         parameters: {},
//         inputs: { x, y, z }
//     };
//     return wrapNodeOutput(node, 'vector');
// }

// function setNodeInputOrParam(node: MaterialGraphNode, key: string, value: MaterialGraphNode | number | boolean | string): void {
//     if (value == null) {
//         return;
//     } else if (typeof value === 'object') {
//         node.inputs[key] = value;
//     } else {
//         node.parameters[key] = value;
//     }
// }

// function mathOp(name: string, a: MaterialGraphNode | number, b: MaterialGraphNode | number) {
//     const node: MaterialGraphNode = {
//         nodeType: 'math',
//         parameters: { math_type: name },
//         inputs: {}
//     };
//     setNodeInputOrParam(node, 'value1', a);
//     setNodeInputOrParam(node, 'value2', b);
//     return wrapNodeOutput(node, 'value');
// }

// function add(a: MaterialGraphNode | number, b: MaterialGraphNode | number) { return mathOp('add', a, b); }
// function sub(a: MaterialGraphNode | number, b: MaterialGraphNode | number) { return mathOp('subtract', a, b); }
// function mul(a: MaterialGraphNode | number, b: MaterialGraphNode | number) { return mathOp('multiply', a, b); }
// function div(a: MaterialGraphNode | number, b: MaterialGraphNode | number) { return mathOp('divide', a, b); }

function generateRgbCurveLUT(parameters: {[name: string]: any}): {fac: number; curves: number[][]} {
    const fac = "fac" in parameters ? parameters["fac"] : 1
    const lutSize = 256
    const lut = computeRgbCurveLUT(lutSize, (paramName) => parameters[paramName])
    return {
        fac: fac,
        curves: lut,
    }
}
