<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [$title]="title"
                    [$isLoaded]="$item() !== undefined"
                    [$canEdit]="$can().update.material($item())"
                    (onChanged)="updateItemSubject.next({name: $event})"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>

            <ng-container cm-buttons>
                @if ($item(); as material) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menuOptions="matMenu">
                        <button mat-menu-item (click)="removeGalleryImage()" [disabled]="!canRemoveGalleryImage">Remove gallery image</button>
                        @if (material.latestRevision) {
                            <button
                                mat-menu-item
                                class="cm-revision-link"
                                matTooltip="Revision created by
                      {{ material.latestRevision.createdBy.name }}
                      {{ material.latestRevision.createdAt | cmTimeAgo }}"
                                matTooltipPosition="right"
                                (click)="showRevisionHistoryDialog()"
                                [disabled]="!$can().read.material(material, 'revisions')"
                                [matTooltipDisabled]="!$can().read.material(material, 'revisions')"
                            >
                                Show version history
                            </button>
                        }
                        <button
                            mat-menu-item
                            [disabled]="!material.textureGroup || !permission.$to().create.materialRevision({material})"
                            [matMenuTriggerFor]="material.textureGroup ? textureSetMenu : undefined"
                        >
                            New material revision
                        </button>
                        <mat-menu #textureSetMenu>
                            @for (set of material.textureGroup?.textureSets; track set) {
                                <button mat-menu-item [routerLink]="['setup', set.id]" [queryParamsHandling]="'preserve'" (click)="nodeGraphMenu = false">
                                    Texture set {{ set.legacyId }}
                                    <i class="fa-regular fa-play"></i>
                                </button>
                            }
                        </mat-menu>
                        <button mat-menu-item (click)="requestDeleteConfirmation()" [disabled]="!material || !$can().delete.item(material)">
                            Delete material
                        </button>
                    </mat-menu>
                }
            </ng-container>

            <ng-container cm-sidebar>
                @if ($item(); as material) {
                    @if (material.latestCyclesRevision; as latestCyclesRevision) {
                        @if (material.latestRevision; as latestRevision) {
                            @if ($can().update.materialRevision({material})) {
                                <a [routerLink]="['edit', latestCyclesRevision.id]" [queryParamsHandling]="'preserve'" class="cm-new-node-graph-container">
                                    <cm-button
                                        matTooltip="Revision created by
                        {{ latestRevision.createdBy.firstName }}
                        {{ latestRevision.createdBy.lastName }}
                        {{ latestRevision.createdAt | cmTimeAgo }}"
                                        class="cm-node-graph-button"
                                    >
                                        <ng-container cm-left-icon>
                                            <i class="far fa-diagram-project"></i>
                                        </ng-container>
                                        <ng-container cm-text>
                                            Edit material
                                            <span> (v{{ latestRevision.number }}) </span>
                                        </ng-container>
                                    </cm-button>
                                </a>
                            }
                        }
                    } @else {
                        @if (material.textureGroup && material.textureGroup.textureSets.length > 0) {
                            @if ($can().create.materialRevision({material})) {
                                <div class="cm-new-node-graph-container">
                                    <cm-button (click)="resolveNewTexture(material.textureGroup)" class="cm-node-graph-button">
                                        <ng-container cm-left-icon>
                                            <i class="far fa-diagram-project"></i>
                                        </ng-container>
                                        <ng-container cm-text> New material</ng-container>
                                    </cm-button>
                                    @if (material.textureGroup && material.textureGroup.textureSets.length > 1 && nodeGraphMenu) {
                                        <div class="cm-texture-sets-options">
                                            <cm-hint>
                                                <ng-container cm-text>
                                                    There are multiple scans assigned to this material, please select which to use.
                                                </ng-container>
                                            </cm-hint>
                                            @for (set of material.textureGroup.textureSets; track set) {
                                                <div
                                                    class="cm-texture-set-option"
                                                    [routerLink]="['setup', set.id]"
                                                    [queryParamsHandling]="'preserve'"
                                                    (click)="nodeGraphMenu = false"
                                                >
                                                    Texture set {{ set.legacyId }}
                                                    <i class="fa-regular fa-play"></i>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        }
                    }
                    <cm-material-outputs [materialId]="material.id"></cm-material-outputs>

                    <div class="cm-section-title">Material info</div>
                    @if ($can().read.material(material, "assets")) {
                        <cm-show-assets-button [$queryParamsKey]="'materialId'" [$entityId]="material.id"></cm-show-assets-button>
                    }

                    <!--Article ID-->
                    <cm-article-id-section
                        [$item]="material"
                        [$canEdit]="$can().update.material(material, 'articleId')"
                        ($onChanged)="updateItemSubject.next($event)"
                    ></cm-article-id-section>
                    <!--Description-->
                    <cm-sidebar-description-section
                        [$item]="material"
                        [$canEdit]="$can().update.material(material, 'description')"
                        ($onChanged)="updateItemSubject.next($event)"
                    ></cm-sidebar-description-section>
                    <!--Tags-->
                    @if ($can().update.tag(null, "production")) {
                        <cm-tag-select
                            [assignedToId]="material.id"
                            [organizationId]="material.organization?.id"
                            [includeTagsWithNoOrganization]="true"
                            [contentTypeModel]="Enums.ContentTypeModel.Material"
                            [type]="Enums.TagType.Production"
                            [canEdit]="$can().update.material(material, 'info')"
                        ></cm-tag-select>
                    }
                    <!--Range-->
                    <cm-tag-select
                        label="Range"
                        [assignedToId]="material.id"
                        [organizationId]="material.organization?.id"
                        [contentTypeModel]="Enums.ContentTypeModel.Material"
                        [type]="Enums.TagType.MaterialRange"
                        [canEdit]="$can().update.material(material, 'info')"
                    ></cm-tag-select>
                    <!--Customer-->
                    <cm-organization-select
                        [updateOrganization]="updateItem.bind(this)"
                        [$organizationId]="material?.organization.id ?? null"
                        [$disabled]="!$can().update.material(material, 'organization')"
                    ></cm-organization-select>
                    <!--Visibility-->
                    @if ($can().update.material(material, "public")) {
                        <cm-boolean-label-select
                            [$name]="'Visibility'"
                            [$options]="Labels.Public"
                            [$value]="material.public"
                            ($onChanged)="updateItem({public: $event})"
                        >
                        </cm-boolean-label-select>
                    }
                    @if ($can().update.material(material, "usage")) {
                        <!--Usage-->
                        <cm-boolean-label-select
                            [$name]="'Usage'"
                            [$options]="Labels.MeshSpecific"
                            [$value]="material.meshSpecific"
                            ($onChanged)="updateItem({meshSpecific: $event})"
                        >
                        </cm-boolean-label-select>
                    }
                    <!--Sample arrived-->
                    @if ($can().read.material(material, "sampleArrival")) {
                        <mat-form-field class="cm-infos-field cm-sample-arrival cm-datepicker">
                            <mat-label>Sample arrived</mat-label>
                            <input
                                matInput
                                [disabled]="!$can().update.material(material, 'sampleArrival')"
                                [matDatepicker]="sampleArrivalDatePicker"
                                [ngModel]="material.sampleArrival"
                                (ngModelChange)="updateItem({sampleArrival: $event})"
                                [max]="sampleArrivalMaxDate"
                            />
                            <mat-datepicker-toggle matSuffix [for]="sampleArrivalDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #sampleArrivalDatePicker></mat-datepicker>
                        </mat-form-field>
                    }
                    <!--Icon-->
                    @if ($can().read.material(material, "iconAssignments")) {
                        <div class="cm-icon-container">
                            @if (!(materialIconAssignments.length > 0)) {
                                <cm-drop-files [showDropzone]="true" [maxFiles]="1" (filesDropped)="uploadIcon($event)" subtitle="to add as icon">
                                </cm-drop-files>
                            } @else {
                                @if (materialIconAssignments; as assignments) {
                                    <cm-data-object-thumbnail [dataObjectId]="assignments[0].dataObject.id"></cm-data-object-thumbnail>
                                }
                                <div class="cm-delete-button" mat-icon-button matTooltip="Delete icon" matTooltipPosition="below" (click)="deleteIcon()">
                                    <i class="far fa-trash"></i>
                                </div>
                            }
                        </div>
                    }
                }
            </ng-container>
            <ng-container cm-main>
                @if ($item(); as material) {
                    <cm-section-content>
                        <cm-corners-layout cm-content>
                            <cm-gallery-image [item]="material"></cm-gallery-image>
                            <div cm-bottom-left class="cm-state-container">
                                @if ($can().read.material(material, "paymentState")) {
                                    <cm-payment-state-label
                                        [item]="material"
                                        [editable]="true"
                                        (stateChange)="updateItem({paymentState: $event})"
                                    ></cm-payment-state-label>
                                }
                                @if ($can().read.material(material, "state")) {
                                    <cm-state-label
                                        [stateLabels]="Labels.MaterialState"
                                        [state]="material.state"
                                        [editable]="$can().update.material(material, 'state')"
                                        (stateChange)="updateItem({state: $event})"
                                    ></cm-state-label>
                                }
                            </div>
                            <cm-copy-legacy-id-button cm-bottom-right [legacyId]="material.legacyId"></cm-copy-legacy-id-button>
                        </cm-corners-layout>
                    </cm-section-content>
                    @if ($can().read.material(material, "textures")) {
                        @if (material.textureGroup) {
                            <cm-texture-sets [$textureGroupId]="material.textureGroup.id" [$showAssociatedJobs]="false"></cm-texture-sets>
                        } @else {
                            <cm-section [indentContent]="false">
                                <ng-container cm-icon><i class="far fa-game-board-alt"></i></ng-container>
                                <ng-container cm-title>
                                    <div class="cm-texture-sets-header">Texture sets</div>
                                </ng-container>
                                <ng-container cm-content>
                                    <div class="cm-texture-sets">
                                        <div (click)="createTextureGroup()" class="cm-texture-group-add"><i class="far fa-plus"></i> Add a texture group</div>
                                    </div>
                                </ng-container>
                            </cm-section>
                        }
                    }

                    @if ($can().read.material(material, "tasks")) {
                        <cm-project-management-tasks-section [$item]="material"></cm-project-management-tasks-section>
                    }

                    @if ($can().read.material(material, "templates")) {
                        <cm-templates-section [$item]="material"></cm-templates-section>
                    }

                    @if ($can().read.material(material, "comment")) {
                        <cm-comment-section
                            [$item]="material"
                            [$canEdit]="$can().update.material(material, 'comment')"
                            ($onChanged)="updateItemSubject.next($event)"
                        ></cm-comment-section>
                    }

                    @if ($can().read.material(material, "files")) {
                        <cm-files-section [$item]="material"></cm-files-section>
                    }

                    @if ($can().read.material(material, "activity")) {
                        <cm-activity-section [$item]="material"></cm-activity-section>
                    }
                }
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>

<ng-template #materialRevisionHistoryDialog>
    <cm-material-revisions-history-dialog></cm-material-revisions-history-dialog>
</ng-template>

<router-outlet></router-outlet>
