@if (node) {
    <ng-content select="[cm-content]"> </ng-content>
    <cm-inspector-section>
        <ng-container cm-title> Resolution </ng-container>
        <ng-container cm-content>
            <div class="cm-resolution">
                <cm-input-container>
                    <ng-container cm-inline-label> X: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.resolutionX" (valueChange)="nodeChanged(node)" [decimalPlaces]="0"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <div class="cm-multiplier">×</div>
                <cm-input-container>
                    <ng-container cm-inline-label> Y: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.resolutionY" (valueChange)="nodeChanged(node)" [decimalPlaces]="0"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Camera Settings </ng-container>
        <ng-container cm-content>
            <div class="cm-camera-settings">
                <cm-input-container>
                    <ng-container cm-inline-label>
                        <span matTooltip="Focal length"> Focal l.: </span>
                    </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.focalLength" (valueChange)="nodeChanged(node)" [decimalPlaces]="0"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Sensor: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.filmGauge" (valueChange)="nodeChanged(node)" [decimalPlaces]="0"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label>
                        <span matTooltip="Focal distance"> Focal d.: </span>
                    </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.focalDistance" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> F-stop: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.fStop" (valueChange)="nodeChanged(node)" [decimalPlaces]="1"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Zoom: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.zoomFactor" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="2">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Advanced Camera Settings </ng-container>
        <ng-container cm-content>
            <div class="cm-camera-settings">
                <cm-input-container>
                    <ng-container cm-inline-label> Shift X: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.shiftX" (valueChange)="nodeChanged(node)" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Shift Y: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.shiftY" (valueChange)="nodeChanged(node)" [decimalPlaces]="2"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Near clip: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.nearClip" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="1"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Far clip: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.farClip" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="0"> </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-toggle class="cm-span2" [(toggle)]="node.automaticVerticalTilt" (toggleChange)="nodeChanged(node)">
                    <ng-container cm-text>Auto vertical tilt</ng-container>
                </cm-toggle>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Configurator Settings </ng-container>
        <ng-container cm-content>
            <div class="cm-camera-settings">
                <cm-input-container>
                    <ng-container cm-inline-label> Min dist.: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.minDistance" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="1">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Max dist.: </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.maxDistance" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="1">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Min angle (v): </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.minPolarAngle" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="1">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Min angle (h): </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.minAzimuthAngle" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="1">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Max angle (v): </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.maxPolarAngle" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="1">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container>
                    <ng-container cm-inline-label> Max angle (h): </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.maxAzimuthAngle" (valueChange)="nodeChanged(node)" [optional]="true" [decimalPlaces]="1">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <cm-toggle class="cm-span2" [(toggle)]="node.enablePanning" (toggleChange)="nodeChanged(node)">
                    <ng-container cm-text>Enable panning</ng-container>
                </cm-toggle>
                <cm-toggle class="cm-span2" [(toggle)]="node.screenSpacePanning" (toggleChange)="nodeChanged(node)">
                    <ng-container cm-text>Screen space panning</ng-container>
                </cm-toggle>
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Configurator Settings - Tone Mapping </ng-container>
        <ng-container cm-content>
            <div class="cm-camera-settings">
                <cm-input-container>
                    <ng-container cm-inline-label> Exposure </ng-container>
                    <ng-container cm-input>
                        <cm-numeric-input [(value)]="node.exposure" (valueChange)="nodeChanged(node)" [convert]="convertExposure" [decimalPlaces]="2">
                        </cm-numeric-input>
                    </ng-container>
                </cm-input-container>
                <!-- TODO: unify this with picture editor -->
                <mat-form-field class="cm-tonemapping-mode">
                    <mat-label>Mode</mat-label>
                    <mat-select
                        [value]="node.toneMapping?.mode ?? 'linear'"
                        (selectionChange)="node.toneMapping = defaultsForToneMapping($event.value); nodeChanged(node)"
                    >
                        <mat-option [value]="'pbr-neutral'">PBR Neutral</mat-option>
                        <mat-option [value]="'filmic'">Filmic</mat-option>
                        <mat-option [value]="'filmic-advanced'">Filmic (Advanced)</mat-option>
                        <mat-option [value]="'contrast'">Contrast</mat-option>
                        <mat-option [value]="'corona'">Corona</mat-option>
                        <mat-option [value]="'linear'">None (Linear)</mat-option>
                    </mat-select>
                </mat-form-field>
                @if (node.toneMapping; as toneMapping) {
                    @if (toneMapping.mode == "corona" && hasHighlightCompression(node.toneMapping)) {
                        <cm-input-container>
                            <ng-container cm-inline-label>
                                <span matTooltip="Highlight Compression">Highlight </span>
                            </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="node.toneMapping.highlightCompression" (valueChange)="nodeChanged(node)" [decimalPlaces]="2">
                                </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    }
                    @if (
                        (toneMapping.mode == "contrast" || toneMapping.mode == "filmic-advanced" || toneMapping.mode == "corona") &&
                        hasContrast(node.toneMapping)
                    ) {
                        <cm-input-container>
                            <ng-container cm-inline-label> Contrast </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="node.toneMapping.contrast" (valueChange)="nodeChanged(node)" [decimalPlaces]="2">
                                </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    }
                    @if (toneMapping.mode == "corona" && hasSaturation(node.toneMapping)) {
                        <cm-input-container>
                            <ng-container cm-inline-label> Saturation </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="node.toneMapping.saturation" (valueChange)="nodeChanged(node)" [decimalPlaces]="2">
                                </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    }
                    @if ((toneMapping.mode == "contrast" || toneMapping.mode == "filmic-advanced") && hasBalance(node.toneMapping)) {
                        <cm-input-container>
                            <ng-container cm-inline-label> Balance </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="node.toneMapping.balance" (valueChange)="nodeChanged(node)" [decimalPlaces]="2">
                                </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    }
                    @if ((toneMapping.mode == "contrast" || toneMapping.mode == "filmic-advanced") && hasColorBalance(node.toneMapping)) {
                        <cm-input-container>
                            <ng-container cm-inline-label>
                                <span matTooltip="Color Balance">Color</span>
                            </ng-container>
                            <ng-container cm-input>
                                <cm-numeric-input [(value)]="node.toneMapping.colorBalance" (valueChange)="nodeChanged(node)" [decimalPlaces]="2">
                                </cm-numeric-input>
                            </ng-container>
                        </cm-input-container>
                    }
                }
            </div>
        </ng-container>
    </cm-inspector-section>
    <cm-inspector-section>
        <ng-container cm-title> Automatic Target </ng-container>
        <ng-container cm-content>
            <cm-node-reference-slot
                [filter]="NodeUtils.resolvesToObject"
                [(node)]="$any(node).automaticTarget"
                (nodeChange)="nodeChanged(node)"
            ></cm-node-reference-slot>
        </ng-container>
    </cm-inspector-section>
}
