<div class="cm-container">
    <mat-table [dataSource]="$data().items" #matTable="matTable">
        <ng-container matColumnDef="taskType">
            <mat-header-cell *matHeaderCellDef>Task Type</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <cm-table-cell [item]="item">
                    <cm-overflowable-text>
                        {{ item.data?.taskType ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="queueGroup">
            <mat-header-cell *matHeaderCellDef>Queue Group</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <cm-table-cell [item]="item">
                    <cm-overflowable-text>
                        {{ item.data?.queueGroup ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="defaultTimeout">
            <mat-header-cell *matHeaderCellDef>Default Timeout</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <cm-table-cell [item]="item">
                    <cm-overflowable-text>
                        {{ item.data?.defaultTimeout ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="maxTimeout">
            <mat-header-cell *matHeaderCellDef>Max Timeout</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <cm-table-cell [item]="item">
                    <cm-overflowable-text>
                        {{ item.data?.maxTimeout ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cancelTimeout">
            <mat-header-cell *matHeaderCellDef>Cancel Timeout</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <cm-table-cell [item]="item">
                    <cm-overflowable-text>
                        {{ item.data?.cancelTimeout ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endpoint">
            <mat-header-cell *matHeaderCellDef>Endpoint</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <cm-table-cell [item]="item">
                    <cm-overflowable-text>
                        {{ item.data?.endpoint ?? "-" }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="invocationMethod">
            <mat-header-cell *matHeaderCellDef>Method</mat-header-cell>
            <mat-cell *matCellDef="let item">
                <cm-table-cell [item]="item">
                    <cm-overflowable-text>
                        {{ formatInvocationTypeForDisplay(item.data?.invocationMethod) }}
                    </cm-overflowable-text>
                </cm-table-cell>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="$columns()"></mat-header-row>
        <mat-row
            class="row-template"
            [class.cm-disabled]="!row.data"
            *matRowDef="let row; columns: $columns()"
            (click)="$event.stopPropagation(); rowClick(row)"
        ></mat-row>
    </mat-table>

    <cm-paginator></cm-paginator>
    <cm-entity-add (click)="createItem()"></cm-entity-add>
</div>

<router-outlet></router-outlet>
