// @ts-strict-ignore
import {
    AfterViewInit,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {
    BasicTagInfoFragment,
    ContentTypeModel,
    DataObjectAssignmentType,
    ImageColorSpace,
    JobTaskState,
    SceneViewerJobTaskStateWithOutputGQL,
    SceneViewerMaterialFragment,
    SceneViewerMaterialRevisionFragment,
    SceneViewerTemplateFragment,
    SceneViewerTemplateRevisionFragment,
} from "@api"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {isAndroid, isIoS} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {exitFullscreen, fullscreenActive, inIframe, isFullscreenApiAvailable, openFullscreen} from "@app/common/helpers/fullscreen/fullscreen"
import {triggerDOMLink} from "@app/common/helpers/routes"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {HdriService} from "@app/editor/services/hdri.service"
import {isNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {DataObjectBatchApiCallService} from "@app/templates/template-system/data-object-batch-api-call.service"
import {MeshDataBatchApiCallService} from "@app/templates/template-system/mesh-data-batch-api-call.service"
import {SceneManager} from "@app/templates/template-system/scene-manager"
import {TemplateRevisionBatchApiCallService} from "@app/templates/template-system/template-revision-batch-api-call.service"
import {IMaterialGraph, isMaterialGraph} from "@cm/lib/materials/material-node-graph"
import {ConfiguratorParameter, ConfiguratorParameterType} from "@cm/lib/templates/configurator-parameters"
import {IDisplaySceneEvent, ObjectId, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {ActionMenuLegacyComponent} from "@app/common/components/menu/action-menu/action-menu/action-menu-legacy.component"
import {ConfigMenulegacyComponent} from "@common/components/menu/config-menu/config-menu.component"
import {LoadingSpinnerComponent} from "@common/components/progress"
import {Matrix4} from "@cm/lib/math"
import {FilesService} from "@common/services/files/files.service"
import {ItemUtils} from "@common/services/item-utils/item-utils.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PdfGenerationService} from "@common/services/pdf-generation/pdf-generation.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {StatisticsService} from "@common/services/statistics/statistics.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {InteractiveSceneViewComponent} from "@editor/components/scene-view/scene-view.component"
import {SelectionEvent} from "@editor/helpers/scene/selection"
import {DisplayScene, SceneViewManager} from "@editor/models/editor"
import {EditorService} from "@editor/services/editor.service"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {forkJoinZeroOrMore} from "@legacy/helpers/utils"
import {APIService} from "@legacy/services/api/api.service"
import {PricingService} from "@pricing/services/pricing.service"
import {
    catchError,
    finalize,
    firstValueFrom,
    from,
    map,
    Observable,
    of,
    of as observableOf,
    ReplaySubject,
    Subject,
    Subscription,
    switchMap,
    takeUntil,
    filter,
    take,
} from "rxjs"
import {increaseOnboardingCounter, isOnboardingRequired} from "@app/common/components/viewers/configurator/helpers/onboarding"
import {uploadAndAttachGLTFToEntity} from "@app/legacy/helpers/ar-viewer-export"
import {EndpointUrls} from "@app/common/models/constants/urls"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {arGltfGenerationLegacyTask, arUsdzGenerationTask} from "@cm/lib/job-task/ar-legacy"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {Settings} from "@app/common/models/settings/settings"

type ArModelType = {
    downloadUrl: string
    objectName: string
    mediaType?: string
}

@Component({
    standalone: true,
    selector: "cm-scene-viewer",
    templateUrl: "./scene-viewer.component.html",
    providers: [EditorService],
    styleUrls: ["./scene-viewer.component.scss"],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [LoadingSpinnerComponent, MatButtonModule, InteractiveSceneViewComponent, ConfigMenulegacyComponent, ActionMenuLegacyComponent],
})
export class SceneViewerComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
    @ViewChild("sceneView", {static: true}) sceneView: InteractiveSceneViewComponent
    @Input() showUi = true

    @Input() set useExternalMenu(val: string) {
        this._externalMenu = val === "true" //called from web component as use-external-menu. If not set from outside, the internal menu might flash up for a short time.
        this.setShowMenu()
    }

    @Input() progressiveLoading = true //NOTE: must be set at init time!
    @Input() headless = false //NOTE: must be set at init time!
    @Input() defaultCameraPosition: [number, number, number] = [-300, 150, 300]
    @Input() defaultCameraTarget: [number, number, number] = [0, 50, 0]
    @Input() defaultHdriId = 15
    @Input() embedded: boolean
    @Input() templateMode = false

    @Input() set templateId(id: number) {
        this.entityId = id
        this.loadSubject.next()
    }

    @Input() initParams?: {[id: string]: string}

    @Input() set templateUuid(id: string) {
        this.loadTemplateWithUuid(id)
    }
    @Input() useCaptionsInMenu = false

    @Output() loadingCompleted: EventEmitter<void> = new EventEmitter<void>()
    @Output() configurationLoaded: EventEmitter<void> = new EventEmitter<void>()
    @Output() sceneEvent: EventEmitter<IDisplaySceneEvent> = new EventEmitter<IDisplaySceneEvent>()
    @Output() changeCompleted: EventEmitter<{id: string; value: string; type: string}> = new EventEmitter<{
        id: string
        value: string
        type: string
    }>() //for web component api

    private unsubscribe: Subject<void> = new Subject<void>()
    viewManager: SceneViewManager
    private sceneManager: SceneManager
    private animTimer = new AnimFrameTimer((delta) => this.animate(delta))
    private entityId: number
    private entityRevision?: SceneViewerTemplateRevisionFragment
    private entityLoading = true
    private displayScene: DisplayScene
    private loadSubject = new Subject<void>()
    private load$ = this.loadSubject.asObservable()

    public customerId?: number

    readonly rootNode: Nodes.TemplateInstance = {
        name: "Root",
        id: "root",
        type: "templateInstance",
        template: undefined,
        lockedTransform: Matrix4.identity().toArray(),
    }

    onboardingHintVisible = false
    arEnabled = false
    pdfGenerationEnabled = false
    gltfDownloadEnabled = false
    stlDownloadEnabled = false
    salesEnquiryEnabled = false
    snapshotEnabled = false
    fullscreenEnabled = false
    qrCodeDialogOpen = false
    showActionMenu = false
    _externalMenu = false
    _showMenu = true
    isCreatingStl = false

    sceneProperties?: Nodes.SceneProperties
    studioNode?: Nodes.Object

    private hdriService = inject(HdriService)
    private materialGraphService = inject(MaterialGraphService)
    private refreshService = inject(RefreshService)
    private sdk = inject(SdkService)

    constructor(
        private workerService: WebAssemblyWorkerService,
        private zone: NgZone,
        private statisticsService: StatisticsService,
        private notificationService: NotificationsService,
        private pdfService: PdfGenerationService,
        private itemUtils: ItemUtils,
        private configMenuService: ConfigMenuLegacyService,
        private jobStateWithOutput: SceneViewerJobTaskStateWithOutputGQL,
        private elementRef: ElementRef,
        private pricingService: PricingService,
        private meshDataBatchApiCallService: MeshDataBatchApiCallService,
        private dataObjectBatchApiCallService: DataObjectBatchApiCallService,
        private templateRevisionBatchApiCallService: TemplateRevisionBatchApiCallService,
        private legacyApi: APIService,
    ) {}

    getSceneManager() {
        return this.sceneManager
    }

    setShowMenu() {
        this._showMenu = !this._externalMenu || fullscreenActive()
    }

    @HostListener("document:fullscreenchange", ["$event"])
    onFullscreenChange(_event: Event) {
        this.setShowMenu()
    }

    zoomIn(amount: number): void {
        this.sceneView.zoomIn(amount)
    }

    zoomOut(amount: number): void {
        this.sceneView.zoomOut(amount)
    }

    resetCamera(): void {
        const nodes = this.sceneManager.getAllSceneNodes()
        const cameraNode: SceneNodes.Camera | undefined = nodes.find(SceneNodes.Camera.is)
        if (cameraNode) {
            this.sceneView.sceneView.setCameraId(undefined)
            this.sceneView.sceneView.setCameraId(cameraNode.id)
        }
    }

    hideOnboardingIcons(): void {
        this.onboardingHintVisible = false
        increaseOnboardingCounter()
    }

    ngOnInit(): void {
        this.displayScene = new DisplayScene(this.workerService, this.materialGraphService, this.hdriService, {
            showGrid: false,
            editMode: false,
            ambientLight: this.templateMode,
            progressiveLoading: this.progressiveLoading,
        })

        this.viewManager = new SceneViewManager(this.displayScene)

        this.workerService.startInitialWorkers()

        if (this.entityId) this.load()
        this.zone.runOutsideAngular(() => this.animTimer.start())

        this.displayScene.sceneEvent$.pipe(takeUntil(this.unsubscribe)).subscribe((x) => this.sceneEvent.emit(x))
        this.configMenuService.materialSelected$.pipe(takeUntil(this.unsubscribe)).subscribe((event) => this.setMaterialInput(event.input.id, event.material))

        this.configMenuService.configSelected$.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            this.setConfigInput(event.config.id, event.variant)
        })

        this.configMenuService.parameterChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((event) => this.setParameter(event.input.id, event.value))

        this.configMenuService.pdfDownloadRequested$.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.downloadPdf())

        this.load$.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.load())
    }

    ngAfterViewInit() {
        this.sceneView.config = {}

        if (this.embedded) {
            this.sceneView.navigation.focused = false
            this.sceneView.config = {
                ...this.sceneView.config,
                navigationFocus: "trackHostFocusBlurEvents",
            }
        }

        //functions exposed on the web component
        this.elementRef.nativeElement.getParameterList = this.getParameterList.bind(this)
        this.elementRef.nativeElement.loadConfigurator = this.loadTemplateWithUuid.bind(this)
        this.elementRef.nativeElement.setParameter = this.prepareAndSetParameter.bind(this)
        this.elementRef.nativeElement.saveSnapshot = this.saveSnapshot.bind(this)
        this.elementRef.nativeElement.captureSnapshotInMemory = this.captureSnapshotInMemory.bind(this)
        this.elementRef.nativeElement.zoomIn = this.zoomIn.bind(this)
        this.elementRef.nativeElement.zoomOut = this.zoomOut.bind(this)
        this.elementRef.nativeElement.resetCamera = this.resetCamera.bind(this)
        this.elementRef.nativeElement.loadTemplateWithLegacySceneId = this.loadTemplateWithLegacySceneId.bind(this) //only for internal use - do not add this to cm-configurator.d.ts
        this.elementRef.nativeElement.getPricesAsList = this.pricingService.getPricesAsList.bind(this.pricingService)
    }

    ngDoCheck(): void {
        /*TODO: This hacky way of updating the interface was also used in the html template before by binding the value of getInterface to
        the interface array of the menu. The interface is set very often this way.
        It is better to explicitly set this whenever the interface changes, but it is hard to figure out when this happens. Two possible positions are:
        this.load() before this.loadingCompleted.emit(), and this.setParameter(). setParameter however seems to perform an asynchronous operation, currently
        without a communication mechanism. Since ngDoCheck does the same as the previous html template hack, I will shift fixing this for now.
        Previous comment was: <!--FIXME: The child components selected configvariants are refreshed from here-->
        Same: TemplatePublisher.ngDoCheck()

        Compare local version to live version: https://configurator.colormass.com/?sceneId=1163 */
        this.configMenuService.setInterfaceLegacy(this.getInterface())
    }

    destroyed = false

    ngOnDestroy(): void {
        this.destroyed = true
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.animTimer.stop()
        this.viewManager.destroy()
        this.viewManager = null

        this.sceneManager?.destroy()
        this.sceneManager = null

        this.displayScene?.destroy()
        this.displayScene = null

        this.workerService.terminateJobsAndWorkers()
    }

    get loading() {
        return this.entityLoading || this.arLoading
    }

    private load(): Observable<void> {
        this.entityLoading = true
        const complete$ = new ReplaySubject<void>()

        const displayScene = this.displayScene
        let beginTime: number
        from(this.sdk.gql.sceneViewerTemplate({legacyId: this.entityId}))
            .pipe(
                switchMap(({template: entity}) => {
                    this.customerId = entity.organizationLegacyId
                    const entityRevision = (this.entityRevision = entity.revisions.find((x) => !isNewTemplateSystem(x.graph)))
                    console.log(`Loading ${entity.__typename} ${entity.id}`)

                    this.sceneManager?.destroy()
                    this.sceneManager = null

                    beginTime = performance.now()

                    const sceneManager = (this.sceneManager = new SceneManager(
                        this.sdk,
                        this.refreshService,
                        this.workerService,
                        this.materialGraphService,
                        true,
                        this.meshDataBatchApiCallService,
                        this.dataObjectBatchApiCallService,
                        this.templateRevisionBatchApiCallService,
                        this.legacyApi,
                    ))

                    const graphJson = entityRevision.graph

                    if (!graphJson) {
                        throw new Error("Entity has no template graph!")
                    }
                    const entityGraph = sceneManager.importGraph(graphJson)
                    const rootNode = this.rootNode
                    const nodeSorter = new NodeUtils.NodeSorter(entityGraph)

                    // const camera = nodeSorter.getCamera()
                    let sceneProperties = nodeSorter.getSceneProperties()
                    // const hdriLight = nodeSorter.getHDRILight()
                    // let cameraAutoTarget: Nodes.Object

                    const pending: Observable<void>[] = []

                    const rootGraph: Nodes.TemplateGraph = entityGraph
                    rootNode.template = entityGraph

                    // This does not work for customers, because they have no access to the HDRIs right now.
                    // However, not sure if this is needed at all? Should it be the SceneViewer's responsibility to automatically add a standard lighting?
                    //
                    // if (!hdriLight && isTemplateMode) {
                    //     pending.push(
                    //         Hdri.getAll().pipe(
                    //             map((hdris) => {
                    //                 const hdri = hdris.find((x) => x.id === this.defaultHdriId) ?? hdris[0]
                    //                 hdriLight = Nodes.create<Nodes.HDRILight>({
                    //                     type: "hdriLight",
                    //                     name: "Environment HDRI",
                    //                     hdri: Nodes.create<Nodes.HDRIReference>({
                    //                         type: "hdriReference",
                    //                         hdriId: hdri.id,
                    //                         name: hdri.name,
                    //                     }),
                    //                     intensity: 1,
                    //                     rotation: [0, 0, 0],
                    //                 })
                    //                 rootGraph.nodes.push(hdriLight)
                    //             })
                    //         )
                    //     )
                    // }

                    if (!sceneProperties) {
                        sceneProperties = Nodes.create<Nodes.SceneProperties>({
                            type: "sceneProperties",
                            maxSubdivisionLevel: 1,
                            backgroundColor: [0.9, 0.9, 0.9],
                            uiColor: [0, 0, 0],
                            uiStyle: "default",
                            enableAr: !this.templateMode,
                            showAnnotations: true,
                            environmentMapMode: "full",
                        })
                        rootGraph.nodes.push(sceneProperties)
                    }

                    if (this.initParams != null) {
                        Nodes.Meta.setAllParameters(rootNode, this.initParams)
                    }

                    displayScene.renderSuspended = true

                    this.updateTemplateParamsForViewport()

                    this.studioNode = nodeSorter.getStudio()
                    this.sceneProperties = sceneProperties

                    return forkJoinZeroOrMore(pending)
                }),
                switchMap(() => {
                    this.sceneManager.defaultCustomerId = this.customerId
                    this.sceneManager.updateRoot(this.rootNode)
                    return this.sceneManager.sync(true)
                }),
                switchMap(() => displayScene.syncTasks()),
                switchMap(() => {
                    if (this.headless) {
                        displayScene.flushDeferredUpdates()
                        return observableOf(undefined)
                    } else if (!this.animTimer.framesRunning) {
                        displayScene.renderSuspended = false
                        displayScene.forceRender()
                        return observableOf(undefined)
                    } else {
                        displayScene.renderSuspended = false
                        return displayScene.syncRender()
                    }
                }),
                map(() => {
                    if (this.sceneProperties?.focusMode === "click") {
                        this.sceneView.config = {
                            ...this.sceneView.config,
                            focusMode: "click",
                        }
                    }

                    const sceneProperties = this.sceneProperties
                    this.onboardingHintVisible = (sceneProperties?.enableOnboardingHint && isOnboardingRequired()) ?? false

                    this.arEnabled = sceneProperties?.enableAr ?? false
                    this.showActionMenu ||= this.arEnabled

                    this.pdfGenerationEnabled = sceneProperties?.enablePdfGeneration ?? false
                    this.showActionMenu ||= this.pdfGenerationEnabled

                    this.gltfDownloadEnabled = sceneProperties?.enableGltfDownload ?? false
                    this.showActionMenu ||= this.gltfDownloadEnabled

                    this.stlDownloadEnabled = sceneProperties?.enableStlDownload ?? false
                    this.showActionMenu ||= this.stlDownloadEnabled

                    this.snapshotEnabled = sceneProperties?.enableSnapshot ?? true
                    this.showActionMenu ||= this.snapshotEnabled

                    this.fullscreenEnabled = isFullscreenApiAvailable() && (sceneProperties?.enableFullscreen ?? true)
                    this.showActionMenu ||= this.fullscreenEnabled

                    this.salesEnquiryEnabled = sceneProperties?.enableSalesEnquiry ?? false
                    this.showActionMenu ||= this.salesEnquiryEnabled

                    this.entityLoading = false
                    const endTime = performance.now()
                    console.log("Scene loading took " + Math.round(endTime - beginTime) + " milliseconds.")

                    if (this.sceneProperties && this.sceneProperties.uiStyle !== undefined) this.configMenuService.setUiStyle(this.sceneProperties.uiStyle)
                    if (this.sceneProperties && this.sceneProperties.iconSize !== undefined)
                        this.configMenuService.setIconSize(
                            typeof this.sceneProperties.iconSize === "string" ? Number(this.sceneProperties.iconSize) : this.sceneProperties.iconSize,
                        )

                    this.loadingCompleted.emit()
                }),
                takeUntil(this.unsubscribe),
                map(() => {
                    complete$.next()
                    complete$.complete()
                }),
            )
            .subscribe(null, (err) => {
                console.error(err)
                this.notificationService.showError(err)
            })

        return complete$
    }

    loadTemplateWithUuid(id: string, initParams?: {[id: string]: string}) {
        void this.pricingService.load(id)
        this.sdk.gql.sceneViewerTemplateLegacyIdFromUuid({id: id}).then(({template}) => {
            this.loadTemplateWithLegacyId(template.legacyId, initParams)
        })
    }

    loadTemplateWithLegacyId(legacyId: number, initParams?: {[id: string]: string}) {
        this.entityId = legacyId
        this.initParams = initParams
        this.load()
    }

    loadTemplateWithLegacySceneId(legacySceneId: number, _initParams?: {[id: string]: unknown}) {
        this.sdk.gql.sceneViewerTemplateUuidFromLegacyId({legacyId: legacySceneId}).then(({scene}) => {
            console.log("Id: " + legacySceneId + " UUID: " + scene.picture.template?.id)
            this.loadTemplateWithUuid(scene.picture.template?.id, this.initParams)
        })
    }

    getInterface(): Nodes.Meta.InterfaceDescriptor[] {
        return this.sceneManager?.getInterfaceForNode(this.rootNode) ?? []
    }

    getParameterList(): ConfiguratorParameter[] {
        const retList: ConfiguratorParameter[] = []
        for (const desc of this.getInterface()) {
            if (desc.interfaceType === "input") {
                let value: string | number | null | undefined
                if (desc.inputType === "material") value = desc.value?.materialId
                else if (desc.inputType === "config") value = desc.value
                retList.push({
                    id: desc.id,
                    type: desc.inputType,
                    name: desc.name,
                    values:
                        desc.inputType === "config"
                            ? desc.variants.map((x) => ({
                                  id: x.id,
                                  name: x.name,
                              }))
                            : undefined,
                    value,
                })
            }
        }
        return retList
    }

    /*TODO: This function previously was part of configurator.component, which wraps scene-viewer.component. It seems like it does some caching, which also
    happens in the config-menu.component. This should be unified. */
    public prepareParameter(
        id: string,
        type: ConfiguratorParameterType,
        value: string | number | Nodes.Node,
    ): Observable<{
        id: string
        type: ConfiguratorParameterType
        value: string | number | Nodes.Node
    }> {
        if (type === "material") {
            return from(
                this.sdk.gql.sceneViewerMaterial({legacyId: value as number}).then(({material}) => ({
                    id,
                    type,
                    value: this.prepareMaterialInput(material),
                })),
            )
        } else if (type === "material-article-id") {
            return observableOf({id, type, value: this.prepareMaterialInput({articleId: value as string})})
        } else if (type === "template") {
            return from(
                this.sdk.gql.sceneViewerTemplate({legacyId: value as number}).then(({template}) => ({
                    id,
                    type,
                    value: this.prepareTemplateInput(template),
                })),
            )
        } else if (type === "image") {
            if (typeof value === "string" || typeof value === "number") {
                return from(
                    this.sdk.gql
                        .sceneViewerDataObject({legacyId: Number(value)})
                        .then(({dataObject}) => ({id, type, value: this.prepareImageInput(dataObject)})),
                )
            } else if (value && typeof value === "object" && "data" in value && "contentType" in value && value.data && value.contentType) {
                const data = value.data as Uint8Array
                const contentType = value.contentType as string
                return observableOf({
                    id,
                    type,
                    value: this.prepareImageInputWithData({contentType, imageColorSpace: ImageColorSpace.Srgb, data}),
                })
            } else {
                return observableOf({id, type, value: null})
            }
        } else {
            return observableOf({id, type, value})
        }
    }

    /*This is the equivalent of configurator.component's setParameter. It is only required, when the web component is controlled externally without our own menu.
    I think what happens inside this.prepareParameter (which previously also was in configurator.component) is to some extend redundant to what is happening in our
    "real menu" and should be unified somehow.  */
    public prepareAndSetParameter(id: string, type: ConfiguratorParameterType, value: string): void {
        if (id == undefined) return

        this.prepareParameter(id, type, value)
            .pipe(
                switchMap((prepared) => {
                    this.setParameter(prepared.id, prepared.value)
                    return this.syncChanges()
                }),
            )
            .subscribe({
                next: () => this.changeCompleted.emit({id, type, value}),
                error: (error) => console.error(error),
            })
    }

    setParameter(id: string, value: unknown): void {
        Nodes.Meta.setParameter(this.rootNode, id, value)
        this.sceneManager?.markNodeChanged(this.rootNode)
    }

    getParameterDescriptor(id: string): Nodes.Meta.InterfaceDescriptor {
        const iface = this.sceneManager?.getInterfaceForNode(this.rootNode)
        if (!iface) return undefined
        for (const desc of iface) {
            if (desc.id === id) {
                return desc
            }
        }
        return undefined
    }

    getParameter<T>(id: string): T {
        return this.getParameterDescriptor(id)?.value as T
    }

    setConfigInput(id: string, variant: Nodes.Meta.VariantInfo) {
        this.setParameter(id, this.prepareConfigInput(variant))
        this.syncChanges().subscribe({
            next: () => {
                this.configurationLoaded.emit()
            },
            error: (error) => {
                console.error(error)
            },
        })
    }

    setMaterialInput(id: string, value: SceneViewerMaterialFragment | IMaterialGraph) {
        return this.setParameter(id, this.prepareMaterialInput(value))
    }

    prepareConfigInput(variant: Nodes.Meta.VariantInfo) {
        return variant.id
    }

    prepareMaterialInput(
        value:
            | SceneViewerMaterialFragment
            | SceneViewerMaterialRevisionFragment
            | {
                  articleId: string
              }
            | IMaterialGraph,
    ) {
        if (isMaterialGraph(value)) {
            return {
                type: "materialGraphReference",
                graph: value,
            } as Nodes.MaterialGraphReference
        } else if ("__typename" in value) {
            if (value["__typename"] === "MaterialRevision") {
                return {
                    type: "materialReference",
                    name: "(Material Ref)",
                    materialRevisionId: value.legacyId,
                } as Nodes.MaterialReference
            } else if (value["__typename"] === "Material") {
                if (value.revisions.length === 0) {
                    throw Error("Tried to set material input with incomplete material entity")
                }
                const materialRevisionId = value.latestCyclesRevision?.legacyId
                if (!materialRevisionId) {
                    return undefined
                }
                return {
                    type: "materialReference",
                    name: value.name,
                    materialRevisionId,
                } as Nodes.MaterialReference
            }
        } else if ("articleId" in value) {
            return {
                type: "findMaterial",
                // customerId
                articleId: value.articleId,
            } as Nodes.FindMaterial
        }
        throw new Error("Invalid material type")
    }

    prepareTemplateInput(template: SceneViewerTemplateFragment | SceneViewerTemplateRevisionFragment) {
        if (template.__typename === "Template" && template.revisions.length === 0) {
            throw Error("Tried to set template input with incomplete template entity")
        }
        const templateRevisionId = template.__typename === "TemplateRevision" ? template.legacyId : template?.latestRevision?.legacyId
        if (!templateRevisionId) {
            return undefined
        }
        return {
            type: "templateReference",
            templateRevisionId,
        } as Nodes.TemplateReference
    }

    prepareImageInput(value: {legacyId: number}) {
        return {
            type: "dataObjectReference",
            dataObjectId: value.legacyId,
        } as Nodes.DataObjectReference
    }

    prepareImageInputWithData(value: {contentType: string; imageColorSpace: ImageColorSpace; data: Uint8Array}) {
        return {
            type: "transientDataObject",
            data: value.data,
            contentType: value.contentType,
            imageColorSpace: value.imageColorSpace,
        } as Nodes.TransientDataObject
    }

    getParameterAsMaterialId(id: string): number | undefined {
        const desc = this.getParameterDescriptor(id)
        if (Nodes.Meta.isMaterial(desc)) return desc.value?.materialId
        else return undefined
    }

    //TODO: use this for PDF generation
    gatherMaterialsForInterfacesWithTag(
        tag: BasicTagInfoFragment | null,
    ): Observable<Record<Nodes.Meta.InterfaceDescriptor["id"], SceneViewerMaterialFragment>> {
        const pending: Observable<[string, SceneViewerMaterialFragment]>[] = []
        for (const desc of this.getInterface()) {
            if (Nodes.Meta.isMaterial(desc)) {
                if (tag && !desc.tags?.find((x) => x.tagId === tag.legacyId)) continue
                const materialLegacyId = desc.value?.materialId
                if (materialLegacyId != null) {
                    pending.push(
                        forkJoinZeroOrMore([
                            observableOf(desc.id),
                            from(this.sdk.gql.sceneViewerMaterial({legacyId: materialLegacyId}).then(({material}) => material)),
                        ]),
                    )
                }
            }
        }
        return forkJoinZeroOrMore(pending).pipe(map(Object.fromEntries))
    }

    // Full screen does not work if the Scene Viewer is within an overlay
    // https://github.com/angular/components/issues/10679#issuecomment-379022568
    toggleFullscreen(): void {
        if (fullscreenActive()) {
            exitFullscreen()
        } else {
            const fullscreenElement = inIframe() ? document.documentElement : this.elementRef.nativeElement
            openFullscreen(fullscreenElement)
        }
    }

    // API for iFrame

    arLoading = false
    desktopArUrl: string
    private arSubscription?: Subscription

    setConfigurationString(value: string): Observable<void> {
        Nodes.Meta.setAllParameters(this.rootNode, JSON.parse(value))
        this.sceneManager?.markNodeChanged(this.rootNode)
        return this.syncChanges()
    }

    async downloadPdf() {
        try {
            const snapshot = await firstValueFrom(this.sceneView.renderCanvasToDataURL("image/jpeg", 1.0))
            const pdf = await this.pdfService.generatePdfOld(snapshot, this.customerId)
            FilesService.downloadFile("Configuration.pdf", pdf)
            this.configMenuService.emitPdfDownloadFinished()
        } catch (error) {
            this.notificationService.showError(`Cannot generate PDF: ${error}`)
        }
    }

    private viewArModel(arModel: ArModelType, configString: string) {
        if (!arModel) {
            return
        }
        if (isAndroid) {
            this.statisticsService.recordArView(this.entityRevision.id, configString).subscribe()
            this.viewInArAndroid(arModel)
        } else if (isIoS) {
            this.statisticsService.recordArView(this.entityRevision.id, configString).subscribe()
            this.viewInArIos(arModel)
        } else {
            // The statistics for this case are recorded on the back end.
            this.viewInArDesktop(arModel)
        }
    }

    deferredArViewAction: (() => void) | null = null

    downloadGltf(): void {
        const studioNodeId = this.studioNode ? this.sceneManager.getObjectIdForNode(this.studioNode) : undefined

        this.displayScene.exportScene([studioNodeId]).subscribe((gltfData) => {
            FilesService.downloadFile("export.glb", new Blob([gltfData], {type: "application/octet-stream"}))
        })
    }

    delay(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    async viewInAr() {
        const configString = this.sceneManager.getConfigurationString(true)
        // console.log("configString", configString); return;
        this.arSubscription?.unsubscribe()
        this.arSubscription = undefined
        this.arLoading = true
        this.deferredArViewAction = null
        await this.delay(2000)
        this.arSubscription = this.getOrCreateArDataObject(isIoS ? "model/vnd.usdz+zip" : "model/gltf-binary")
            .pipe(
                map(([dataObject, didWait]) => {
                    this.arLoading = false
                    if (!dataObject) return
                    if (isAndroid && didWait) {
                        // Trying to programmatically navigate on Android will fail :(
                        // The navigation needs to be the direct result of user action.
                        this.deferredArViewAction = () => {
                            this.deferredArViewAction = null
                            this.viewArModel(dataObject, configString)
                        }
                    } else {
                        this.viewArModel(dataObject, configString)
                    }
                }),
            )
            .subscribe()
    }

    private viewInArDesktop(gltfDataObject: {objectName: string}) {
        this.desktopArUrl = `${EndpointUrls.AR_REDIRECT_URL}/${gltfDataObject.objectName}`
        this.qrCodeDialogOpen = true
        // Alternatively, the glTF could be generated and uploaded by the client when on desktop. However, for this everybody would have to have permissions to upload files.
        // if (!this.gltfDataObject) this.exportScene(configString).subscribe(() => this.startArPolling(configString).subscribe());
        // if (!this.usdzDataObject) this.startArPolling(configString).subscribe();
    }

    private viewInArAndroid(gltfDataObject: {downloadUrl: string}) {
        const dataObjectURL = gltfDataObject.downloadUrl
        triggerDOMLink({
            href:
                `intent://arvr.google.com/scene-viewer/1.0?` +
                `mode=ar_only&` +
                `file=${dataObjectURL}#Intent;` +
                `scheme=https;package=com.google.android.googlequicksearchbox;` +
                `action=android.intent.action.VIEW;` +
                `S.browser_fallback_url=https://developers.google.com/ar;end;`,
        })
    }

    private viewInArIos(usdzDataObject: {downloadUrl: string}) {
        const dataObjectURL = usdzDataObject.downloadUrl
        // need to create a link with an <img> tag inside, as noted here: https://cwervo.com/writing/quicklook-web
        const a = document.createElement("a")
        a.href = dataObjectURL
        a.rel = "ar"
        const img = document.createElement("img")
        a.appendChild(img)
        document.body.appendChild(a)
        a.style.display = "none"
        a.click()
        a.remove()
    }

    closeArDialog(): void {
        this.qrCodeDialogOpen = false
        this.arSubscription?.unsubscribe()
        this.arSubscription = undefined
    }

    creatingStl(isCreating: boolean) {
        this.isCreatingStl = isCreating
    }

    syncChanges(): Observable<void> {
        const sceneManager = this.sceneManager
        if (!sceneManager) return observableOf(void 0)
        const displayScene = this.displayScene
        return sceneManager.sync(true).pipe(
            map(() => {
                displayScene.updateAll(sceneManager.getAllSceneNodes())
                displayScene.flushDeferredUpdates()
            }),
            switchMap(() => displayScene.syncTasks()),
            switchMap(() => (displayScene.renderSuspended ? observableOf(undefined) : displayScene.syncRender())),
            finalize(() => {
                this.configMenuService.setInterfaceLegacy(this.getInterface())
            }),
        )
    }

    private animate(delta: number) {
        if (!this.viewManager || this.destroyed) {
            this.animTimer.stop()
            return
        }
        let didUpdate = false
        const isOffscreen = delta > 250
        if (isOffscreen) {
            while (this.sceneManager?.updateAllMeshPositions()) {
                didUpdate = true
            }
        } else {
            didUpdate = this.sceneManager?.updateAllMeshPositions()
        }
        if (didUpdate) {
            const nodes = this.sceneManager.getAllSceneNodes()
            this.viewManager?.displayScene?.updateAll(nodes)
            this.sceneView.sceneView.setCameraId(nodes.find(SceneNodes.Camera.is)?.id) // need to call this directly, instead of using angular binding (one frame of latency)
        }
        if (isOffscreen) {
            this.viewManager?.displayScene?.forceRender()
        }
    }

    private setSelection(_objId: ObjectId | null, _materialSlot: number | null) {
        // const node = this.sceneManager.getNodeForObjectId(objId);
        // if (node && NodeUtils.isTemplateOrInstance(node)) {
        //     this.selectedTemplate = node;
        //     this.selectionOutlines = [[objId, undefined]];
        // } else {
        //     this.selectedTemplate = null;
        //     this.selectionOutlines = null;
        // }
    }

    saveSnapshot(): void {
        this.sceneView.renderCanvasToDataURL("image/jpeg", 1.0).subscribe((dataURL) => {
            FilesService.downloadFile("snapshot.jpg", dataURL.replace("image/jpeg", "image/octet-stream"))
        })
    }

    captureSnapshotInMemory(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.sceneView.renderCanvasToDataURL("image/jpeg", 1.0).subscribe({
                next: (dataURL: string) => resolve(dataURL),
                error: (error: Error) => reject(error),
                complete: () => {},
            })
        })
    }

    generateAndUploadGLTF(uploadService: UploadGqlService) {
        const excludeStudio = true
        const configString = this.sceneManager.getConfigurationString(true)
        const excludedObjectIDs: string[] = []
        if (excludeStudio) {
            //TODO: Better filtering for studio/ground plane node...
            if (this.studioNode) {
                excludedObjectIDs.push(this.sceneManager.getObjectIdForNode(this.studioNode))
            }
        }
        return this.displayScene.exportScene(excludedObjectIDs).pipe(
            switchMap((glbData) => {
                console.log("Buffer size:", glbData.byteLength)
                return uploadAndAttachGLTFToEntity(this.entityRevision, glbData, configString, uploadService, this.sdk)
            }),
        )
    }

    private getExistingGltfDataObjectId(configString: string): Observable<{id: string} | null> {
        return from(
            this.sdk.gql
                .sceneViewerDataObjectAssignments({
                    filter: {
                        contentTypeModel: ContentTypeModel.TemplateRevision,
                        objectId: this.entityRevision.id,
                        assignmentKey: {equals: configString},
                        assignmentType: [DataObjectAssignmentType.CachedTemplateGltf],
                    },
                })
                .then(({dataObjectAssignments}) => dataObjectAssignments?.[0]?.dataObject),
        )
    }

    private getGltfOrUsdz(
        gltfDataObject: ArModelType & {
            related: ArModelType[]
        },
        contentType: "model/vnd.usdz+zip" | "model/gltf-binary",
    ): ArModelType | null {
        if (!gltfDataObject) {
            return null
        }
        switch (contentType) {
            case "model/gltf-binary":
                return gltfDataObject
            case "model/vnd.usdz+zip":
                return gltfDataObject.related.find((x) => x.mediaType === "model/vnd.usdz+zip")
            default:
                throw Error(`Unknown type: ${contentType}.`)
        }
    }

    private getExistingArModel(configString: string, contentType: "model/vnd.usdz+zip" | "model/gltf-binary"): Observable<ArModelType | null> {
        return this.getExistingGltfDataObjectId(configString).pipe(
            switchMap((existingDataObjectId) => {
                if (existingDataObjectId) {
                    return from(
                        this.sdk.gql.sceneViewerDataObject({
                            id: existingDataObjectId.id,
                        }),
                    ).pipe(map(({dataObject}) => this.getGltfOrUsdz(dataObject, contentType)))
                }
                return of(null)
            }),
        )
    }

    private generateNewArModel(configString: string, contentType: "model/vnd.usdz+zip" | "model/gltf-binary"): Observable<ArModelType | null> {
        //The ar generation task uses the new template system, the code below will not work anymore: The arGltfGenerationTask now ueses the template revision id
        //instead of the template id and the serialized parameters from the new system instead of the configString.
        //throw new Error("Not implemented")
        const configStringB64 = btoa(configString)
        const arGenerationArgs = {
            organizationLegacyId: this.customerId,
            templateLegacyId: this.entityId,
            configString: configStringB64,
        }
        const gltfGenerationTask = JobNodes.task(arGltfGenerationLegacyTask, {
            input: JobNodes.struct({
                templateId: JobNodes.value(this.entityId),
                configString: JobNodes.value(configStringB64),
            }),
        })
        const usdzGenerationTask = JobNodes.task(arUsdzGenerationTask, {
            input: gltfGenerationTask,
        })
        const graph = JobNodes.jobGraph(JobNodes.list([gltfGenerationTask, usdzGenerationTask]), {
            platformVersion: Settings.APP_VERSION,
        })
        console.log("Starting AR generation job", arGenerationArgs)

        return from(
            this.sdk.gql.sceneViewerCreateJob({
                input: {
                    graph: graphToJson(graph),
                    name: "AR generation",
                    organizationLegacyId: this.customerId,
                },
            }),
        ).pipe(
            switchMap(({createJob: arGenerationJob}) =>
                this.jobStateWithOutput
                    .watch(
                        {
                            id: arGenerationJob.id,
                        },
                        {pollInterval: 2000},
                    )
                    .valueChanges.pipe(
                        // wait until the job is complete
                        filter(({data: {jobTask}}) => {
                            switch (jobTask.state) {
                                case JobTaskState.Runnable:
                                case JobTaskState.Running:
                                case JobTaskState.Init:
                                    return false
                                default:
                                    return true
                            }
                        }),
                        // we can let the subscription go once the job is complete
                        take(1),
                        // try to fetch the AR model again
                        switchMap(({data: {jobTask}}) => {
                            switch (jobTask.state) {
                                case JobTaskState.Complete: {
                                    return this.getExistingArModel(configString, contentType)
                                }
                                default:
                                    return null
                            }
                        }),
                    ),
            ),
        )
    }

    private getOrCreateArDataObject(contentType: "model/vnd.usdz+zip" | "model/gltf-binary"): Observable<[ArModelType, boolean] | null> {
        const configString = this.sceneManager.getConfigurationString(true)

        return this.getExistingArModel(configString, contentType).pipe(
            switchMap((existingArModel) => {
                if (existingArModel) {
                    return of([existingArModel, false] as [ArModelType, boolean])
                }

                return this.generateNewArModel(configString, contentType).pipe(
                    switchMap((result) => {
                        if (result) {
                            return of([result, true] as [ArModelType, boolean])
                        }
                        return null
                    }),
                )
            }),
            catchError((error) => {
                console.error(error)
                return of(null)
            }),
        )
    }

    private updateTemplateParamsForViewport() {
        const viewportSize = this.sceneView.getCurSize()
        if (viewportSize && viewportSize[0] && viewportSize[1] && !this.headless) {
            const rootNode = this.rootNode
            Nodes.Meta.setParameter(rootNode, "$viewportSize", [...viewportSize])
            if (!this.entityLoading) {
                this.sceneManager?.markNodeChanged(rootNode)
            }
        }
    }

    viewSizeChanged(_width: number, _height: number) {
        this.updateTemplateParamsForViewport()
    }

    onSceneViewSelectionEvent(event: SelectionEvent): void {
        this.setSelection(event.objectId, event.materialSlot)
    }
}

class AnimFrameTimer {
    private animFrameId: number | null = null
    private timerId: ReturnType<typeof setInterval> | null = null
    private lastTime: number = Date.now()
    private frameTimeout = 500
    private curInterval = 0
    private fastInterval = 50
    private slowInterval = 250
    framesRunning = false

    constructor(private callback: (delta: number) => void) {}

    start() {
        this.changeInterval(this.fastInterval)
        if (this.animFrameId != null) cancelAnimationFrame(this.animFrameId)
        this.animFrameId = requestAnimationFrame(this.onFrame.bind(this))
    }

    protected changeInterval(interval: number) {
        if (interval == this.curInterval) return
        this.curInterval = interval
        if (this.timerId != null) clearInterval(this.timerId)
        this.timerId = setInterval(this.onTimer.bind(this), interval)
    }

    protected onFrame() {
        const now = Date.now()
        this.framesRunning = true
        this.changeInterval(this.slowInterval) // check less often, as frames are now running
        this.animFrameId = requestAnimationFrame(this.onFrame.bind(this))
        this.callback(now - this.lastTime)
        this.lastTime = now
    }

    protected onTimer() {
        const now = Date.now()
        const delta = now - this.lastTime
        if (delta >= this.frameTimeout) {
            this.framesRunning = false
            this.changeInterval(this.fastInterval) // check more often, as frames are not running
            this.callback(delta)
            this.lastTime = now
        }
    }

    stop() {
        if (this.animFrameId != null) {
            cancelAnimationFrame(this.animFrameId)
            this.animFrameId = null
        }
        if (this.timerId != null) {
            clearInterval(this.timerId)
            this.timerId = null
            this.curInterval = 0
        }
    }
}
