<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [$title]="$item()?.name"
                    [$iconClass]="'far fa-user'"
                    [$isLoaded]="$item() !== undefined"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>
            <ng-container cm-buttons>
                <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <mat-menu #menuOptions="matMenu">
                    <button mat-menu-item (click)="removeGalleryImage()" [disabled]="!canRemoveGalleryImage">Remove gallery image</button>
                </mat-menu>
            </ng-container>

            <ng-container cm-sidebar>
                @if ($item(); as user) {
                    <!--Password-->
                    <button class="cm-simple-inert-button cm-change-password-button" mat-flat-button (click)="openPasswordChangeDialog()">
                        Change password
                    </button>
                    <!--First name-->
                    <mat-form-field class="cm-infos-field">
                        <mat-label>First name</mat-label>
                        <input matInput id="firstName" [ngModel]="user.firstName" (ngModelChange)="updateItem({firstName: $event})" />
                    </mat-form-field>
                    <!--Last name-->
                    <mat-form-field class="cm-infos-field">
                        <mat-label>Last name</mat-label>
                        <input matInput id="lastName" [ngModel]="user.lastName" (ngModelChange)="updateItem({lastName: $event})" />
                    </mat-form-field>
                    <!--Email-->
                    <mat-form-field class="cm-infos-field">
                        <mat-label>Email</mat-label>
                        <input matInput id="email" [ngModel]="user.email" (ngModelChange)="updateItem({email: $event})" />
                    </mat-form-field>
                    <!--Join date-->
                    <mat-form-field class="cm-infos-field" matTooltip="{{ user.dateJoined | date: 'y-MM-dd H:mm:ss' }}">
                        <mat-label>Joined</mat-label>
                        <input [disabled]="true" matInput id="joined" [ngModel]="user.dateJoined | cmTimeAgo" />
                    </mat-form-field>
                    <!--Customer-->
                    <mat-form-field class="cm-infos-field">
                        <mat-label>Customer</mat-label>
                        <mat-select
                            [ngModel]="$memberOrganizationIds()"
                            placeholder="Customer"
                            name="organizationId"
                            [multiple]="true"
                            (ngModelChange)="toggleOrganizationMembership($event)"
                            [disabled]="organizationSelectorDisabled && organizations.$editOptions().length <= 1"
                        >
                            @for (option of organizations.$editOptions(); track option) {
                                <mat-option [value]="option.state">{{ option.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    <!--Filter visibility-->
                    <mat-checkbox
                        [ngModel]="user.visibleInFilters"
                        (ngModelChange)="updateItem({visibleInFilters: $event})"
                        class="cm-infos-field cm-mat-checkbox"
                    >
                        Visible in filters
                    </mat-checkbox>
                    <!--Email notifications-->
                    <mat-checkbox
                        [ngModel]="user.emailNotifications"
                        (ngModelChange)="updateItem({emailNotifications: $event})"
                        class="cm-infos-field cm-mat-checkbox"
                    >
                        Email notifications
                    </mat-checkbox>
                    <!--Account-->
                    <mat-checkbox
                        [ngModel]="user.isActive"
                        [disabled]="user.id === auth.$user()?.id"
                        (ngModelChange)="updateItem({isActive: $event})"
                        class="cm-infos-field cm-mat-checkbox"
                    >
                        Active
                    </mat-checkbox>
                    <!--System Role-->
                    <mat-form-field class="cm-infos-field">
                        <mat-label>System role</mat-label>
                        <mat-select
                            [ngModel]="user.systemRole"
                            placeholder="None"
                            name="systemRole"
                            (ngModelChange)="updateUserRole($event)"
                            [disabled]="!$can().update.user(user, 'systemRole')"
                        >
                            @for (option of Labels.SystemRole.values(); track option) {
                                <mat-option [value]="option.state">{{ option.label }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }
            </ng-container>

            <ng-container cm-main>
                <cm-gallery-image [item]="$item()"></cm-gallery-image>
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
