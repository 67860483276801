<cm-routed-dialog [dialogSize]="DialogSize.Medium">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section [$title]="title" [$isLoaded]="$item() !== undefined" (onClosed)="closeModal()"></cm-title-section>
            </ng-container>

            <ng-container cm-buttons>
                @if ($item(); as job) {
                    <button class="cm-simple-window-header-button cm-item-menu" [matMenuTriggerFor]="menuOptions">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menuOptions="matMenu">
                        <div mat-menu-item [style.cursor]="'pointer'" [matMenuTriggerFor]="copyItemsMenu">
                            <mat-icon>file_copy</mat-icon>
                            Copy
                        </div>
                        <cm-copy-value-to-clipboard-menu #copyItemsMenu [$copyItems]="$copyItems()"></cm-copy-value-to-clipboard-menu>
                    </mat-menu>
                }
            </ng-container>
            <ng-container cm-main>
                <cm-section>
                    <ng-container cm-icon><i class="far fa-project-diagram"></i></ng-container>
                    <ng-container cm-title>Execution plan</ng-container>
                    <ng-container cm-content>
                        <cm-execution-plan [jobId$]="heartbeat$"></cm-execution-plan>
                    </ng-container>
                </cm-section>
                <cm-section>
                    <ng-container cm-icon><i class="far fa-list-check"></i></ng-container>
                    <ng-container cm-title>Tasks</ng-container>
                    <ng-container cm-content>
                        <cm-job-tasks-table [jobId$]="heartbeat$" (clickItem)="taskClick($event)"></cm-job-tasks-table>
                    </ng-container>
                </cm-section>
            </ng-container>
            <cm-files-section
                cm-main
                [$item]="$item()"
                [assignmentTypes]="[DataObjectAssignmentType.Attachment, DataObjectAssignmentType.JobTaskData]"
            ></cm-files-section>
            <cm-organization-select
                cm-sidebar
                [cmIsLoading]="!$item()"
                [updateOrganization]="updateItem.bind(this)"
                [$organizationId]="$item()?.organization.id ?? null"
                [$disabled]="true"
            ></cm-organization-select>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()">
                <mat-label>Created by</mat-label>
                <input matInput id="createdBy" [ngModel]="$item()?.createdBy?.name" disabled />
            </mat-form-field>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()">
                <mat-label>State</mat-label>
                <input matInput id="state" [ngModel]="$item()?.state" disabled />
            </mat-form-field>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()" matTooltip="{{ $item()?.started | date: 'y-MM-dd H:mm:ss' }}">
                <mat-label>Started</mat-label>
                <input matInput id="started" [ngModel]="$item()?.started | cmTimeAgo" disabled />
            </mat-form-field>
            <mat-form-field cm-sidebar [cmIsLoading]="!$item()" matTooltip="{{ $item()?.finished | date: 'y-MM-dd H:mm:ss' }}">
                <mat-label>Finished</mat-label>
                <input matInput id="finished" [ngModel]="$item()?.finished | cmTimeAgo" disabled />
            </mat-form-field>
            <ng-container cm-sidebar>
                @if ($item()?.cancelled) {
                    <mat-form-field [cmIsLoading]="!$item()" matTooltip="{{ $item()?.cancelled | date: 'y-MM-dd H:mm:ss' }}">
                        <mat-label>Cancelled</mat-label>
                        <input matInput id="cancelled" [ngModel]="$item()?.cancelled | cmTimeAgo" disabled />
                    </mat-form-field>
                }
                <mat-checkbox
                    [ngModel]="!!$item()?.priority"
                    (ngModelChange)="togglePriority($event)"
                    [disabled]="!$can().update.job($item(), 'priority')"
                    class="cm-mat-checkbox"
                >
                    High priority
                </mat-checkbox>
                @switch (this.$item()?.state) {
                    @case (JobState.Failed) {
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="restartJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-play"></i>
                            Restart
                        </button>
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="rerunAllTasksInJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-backward-fast"></i>
                            Re-run all
                        </button>
                    }
                    @case (JobState.Cancelled) {
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="restartJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-play"></i>
                            Restart
                        </button>
                    }
                    @case (JobState.Init) {
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="restartJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-play"></i>
                            Restart
                        </button>
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="rerunAllTasksInJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-backward-step"></i>
                            Re-run all
                        </button>
                    }
                    @case (JobState.Running) {
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="nudgeJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-forward-step"></i>
                            Nudge
                        </button>
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="cancelJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-stop"></i>
                            Cancel
                        </button>
                    }
                    @case (JobState.Runnable) {
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="nudgeJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-forward-step"></i>
                            Nudge
                        </button>
                        <button color="primary" [cmIsLoading]="!$item()" mat-button (click)="cancelJob()" [disabled]="!$can().update.job()">
                            <i class="fa-solid fa-stop"></i>
                            Cancel
                        </button>
                    }
                }
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
<router-outlet></router-outlet>
