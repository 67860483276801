import {DestroyRef} from "@angular/core"
import {SdkService} from "@common/services/sdk/sdk.service"
import * as Apollo from "apollo-angular"
import {Variables} from "graphql-request"
import {deepEqual} from "@cm/lib/utils/utils"

/**
 * Manages a request that can be cancelled when the user navigates away
 */
export class CancellableRequest<ResultType, VariablesType extends Variables = Variables> {
    private fetchAbortController = new AbortController()
    // we cancel previous fetches that used different variables, but skip and take should be ignored,
    // so that multiple batches of the same data can be fetched in parallel
    private groupInfo: Omit<Variables, "skip" | "take"> | null = null

    constructor(
        private query: Apollo.Query<ResultType, VariablesType>,
        private sdk: SdkService,
        private destroyRef: DestroyRef,
    ) {
        this.destroyRef.onDestroy(() => this.cancel())
    }

    async fetch(variables: VariablesType): Promise<ResultType> {
        const groupInfo = {...variables}
        delete groupInfo.skip
        delete groupInfo.take
        if (!deepEqual(this.groupInfo, groupInfo)) {
            this.groupInfo = groupInfo
            this.fetchAbortController.abort()
            this.fetchAbortController = new AbortController()
        }
        return this.sdk.client.request<ResultType>({
            document: this.query.document,
            requestHeaders: this.sdk.requestHeaders(),
            signal: this.fetchAbortController.signal,
            variables,
        })
    }

    cancel() {
        this.fetchAbortController.abort()
    }
}
