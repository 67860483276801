<div class="cm-table-container mat-elevation-z8">
    <table #revisionTable mat-table [dataSource]="material.revisions">
        <!-- Revision column -->
        <ng-container matColumnDef="revision">
            <th mat-header-cell *matHeaderCellDef>Revision</th>
            <td mat-cell *matCellDef="let revision">
                <div class="cm-revision-number-container">
                    <ng-container>{{ revision.number }}</ng-container>
                    <a class="cm-revision-delete" (click)="deleteRevision(revision)" matTooltip="Delete" matTooltipPosition="above">
                        <i class="far fa-trash"></i>
                    </a>
                </div>
            </td>
        </ng-container>
        <!-- Author column -->
        <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef>Author</th>
            <td mat-cell *matCellDef="let revision">{{ revision.createdBy.firstName }} {{ revision.createdBy.lastName }}</td>
        </ng-container>
        <!-- Date column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let revision">
                <span matTooltip="{{ revision.createdAt | cmFormatDate }}" matTooltipPosition="above">{{ revision.createdAt | cmTimeAgo }}</span>
            </td>
        </ng-container>
        <!-- Corona file -->
        <ng-container matColumnDef="corona">
            <th mat-header-cell *matHeaderCellDef>Corona</th>
            <td mat-cell *matCellDef="let revision">
                @if (revision.matFileDataObject) {
                    <a [href]="getMatFileUrl(revision)" class="far fa-arrow-to-bottom"></a>
                }
                @if (!revision.matFileDataObject) {
                    <i class="far fa-minus"></i>
                }
            </td>
        </ng-container>
        <!-- Cycles file -->
        <ng-container matColumnDef="cycles">
            <th mat-header-cell *matHeaderCellDef>Cycles</th>
            <td mat-cell *matCellDef="let revision">
                @if (revision.hasCyclesMaterial) {
                    <i class="far fa-check"></i>
                }
                @if (!revision.hasCyclesMaterial) {
                    <i class="far fa-minus"></i>
                }
            </td>
        </ng-container>
        <!-- Graph schema -->
        <ng-container matColumnDef="graph-schema">
            <th mat-header-cell *matHeaderCellDef>Graph schema</th>
            <td mat-cell *matCellDef="let revision">{{ revision.graphSchema }}</td>
        </ng-container>
        <!-- Comment Column -->
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>Comment</th>
            <td mat-cell *matCellDef="let revision">{{ revision.comment }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
