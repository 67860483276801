import {AnyJSONValue, TemplateData, TemplateNode, isAnyJSONValue} from "@src/templates/types"
import {getInterfaceIdPrefix} from "@src/templates/interface-descriptors"
import {isTemplateNode} from "@src/templates/types"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {Matrix4} from "@src/math"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {
    MaterialLike,
    ImageLike,
    TemplateLike,
    ObjectLike,
    StringLike,
    NumberLike,
    BooleanLike,
    isBooleanLike,
    isImageLike,
    isMaterialLike,
    isNumberLike,
    isObjectLike,
    isStringLike,
    isTemplateLike,
    JSONLike,
    isJSONLike,
    Mesh,
} from "@src/templates/node-types"
import {TemplateInstance} from "@src/templates/nodes/template-instance"
import {BuilderInlet, GraphBuilder, isBuilderOutlet} from "@src/templates/runtime-graph/graph-builder"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {EvaluatedTemplateInput, EvaluatedTemplateInputs, EvaluatedTemplateValueType, TemplateContext} from "@src/templates/types"
import {CompactUIDTable} from "@src/utils/utils"
import {FetchDataObjectNew} from "@src/templates/runtime-graph/nodes/fetch-data-object-new"
import {DataObjectReference} from "@src/templates/nodes/data-object-reference"
import {GraphScheduler} from "./runtime-graph/graph-scheduler"
import {MeshCurve} from "./nodes/mesh-curve"
import {SampleCurveData} from "./runtime-graph/nodes/sample-curve"
import {TemplateParameterValue} from "./nodes/parameters"
import {MeshData} from "@src/geometry-processing/mesh-data"
import {SceneNodes} from "./interfaces/scene-object"
import {ResolveAlias} from "./runtime-graph/types"
import {NotReady} from "./runtime-graph/slots"

function cached(target: NodeEvaluator, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value

    descriptor.value = function (...args: any[]) {
        const resultCache = (this as any).resultCache
        const node = args[1]
        const cacheValue = resultCache.get(node)
        if (cacheValue !== undefined) return cacheValue

        const result = originalMethod.apply(this, args)
        resultCache.set(node, result)

        return result
    }

    return descriptor
}

type MeshObjectData = ObjectData & {meshData: MeshData}

export class NodeEvaluator {
    protected resultCache = new Map<TemplateNode, BuilderInlet<unknown>>()

    constructor(
        readonly idMap: CompactUIDTable<string>,
        readonly templateScope: GraphBuilderScope,
        readonly templateContext: TemplateContext,
        readonly ambientInputs: EvaluatedTemplateInputs,
        readonly activeNodeSet: Set<TemplateNode> | undefined,
    ) {}

    getLocalId(node: TemplateNode) {
        return this.idMap.intern(node.instanceId)
    }

    getScope(node: TemplateNode) {
        return this.templateScope.scope(`${node.getNodeLabel()}@${this.getLocalId(node)}`)
    }

    fetchDataObject(scope: GraphBuilderScope, dataObject: DataObjectReference) {
        return scope.node(FetchDataObjectNew, {
            sceneManager: this.templateContext.sceneManager,
            dataObjectId: dataObject.parameters.dataObjectId,
        }).dataObject
    }

    @cached
    evaluateMaterial(scope: GraphBuilderScope, node: MaterialLike | null): BuilderInlet<IMaterialGraph | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    @cached
    evaluateTemplate(scope: GraphBuilderScope, node: TemplateLike | null): BuilderInlet<TemplateData | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    @cached
    evaluateObject(scope: GraphBuilderScope, node: ObjectLike | null): BuilderInlet<ObjectData | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    @cached
    evaluateMesh(scope: GraphBuilderScope, node: Mesh): BuilderInlet<MeshObjectData> {
        return scope.lambda(
            node.evaluate(scope, this),
            (objectData) => {
                const {displayList} = objectData
                if (displayList.length !== 1) throw new Error("Mesh object must have exactly one display list entry")
                const sceneNode = displayList[0]
                if (!SceneNodes.Mesh.is(sceneNode)) throw new Error("Display list entry must be a mesh")
                const {meshData} = sceneNode
                return {...objectData, meshData}
            },
            "meshData",
        )
    }

    @cached
    evaluateMeshCurve(scope: GraphBuilderScope, node: MeshCurve): BuilderInlet<ObjectData & SampleCurveData> {
        return node.evaluate(scope, this)
    }

    @cached
    evaluateImage(scope: GraphBuilderScope, node: ImageLike | null): BuilderInlet<TemplateImageDataNew | null> {
        if (node === null) return null
        return node.evaluate(scope, this)
    }

    evaluateString(scope: GraphBuilderScope, node: StringLike | null): BuilderInlet<string | null> {
        if (node === null) return null
        if (typeof node === "string") return node
        return node.evaluate(scope, this)
    }

    evaluateNumber(scope: GraphBuilderScope, node: NumberLike | null): BuilderInlet<number | null> {
        if (node === null) return null
        if (typeof node === "number") return node
        return node.evaluate(scope, this)
    }

    evaluateBoolean(scope: GraphBuilderScope, node: BooleanLike | null): BuilderInlet<boolean | null> {
        if (node === null) return null
        if (typeof node === "boolean") return node
        return node.evaluate(scope, this)
    }

    evaluateActive(active: BooleanLike) {
        if (typeof active !== "boolean") {
            const scheduler = new GraphScheduler()
            const builder = new GraphBuilder("root", scheduler)
            const scope = new GraphBuilderScope(builder, "")

            const evaled = this.evaluateBoolean(scope, active)
            if (typeof evaled !== "boolean") throw new Error("Active flag must be an instantly evaluable boolean")

            return evaled
        } else return active
    }

    evaluateJSON(scope: GraphBuilderScope, node: JSONLike | null): BuilderInlet<AnyJSONValue | null> {
        if (node === null) return null
        if (isAnyJSONValue(node)) return node
        return node.evaluate(scope, this)
    }

    evaluateTemplateInputs(scope: GraphBuilderScope, node: TemplateInstance): [BuilderInlet<EvaluatedTemplateInputs>, string[]] {
        function setTemplateInput(
            scope: GraphBuilderScope,
            inputs: {[inputId: string]: BuilderInlet<EvaluatedTemplateInput> | typeof NotReady},
            type: EvaluatedTemplateValueType,
            id: string,
            value: BuilderInlet<EvaluatedTemplateInput["value"]>,
            origin?: TemplateParameterValue,
        ) {
            if (isBuilderOutlet(value) || value instanceof ResolveAlias) {
                inputs[id] = scope.struct<EvaluatedTemplateInput>("EvaluatedTemplateInput", {type, value, origin})
            } else {
                inputs[id] = {type, value, origin}
            }
        }

        const evaledInputs: {[inputId: string]: BuilderInlet<EvaluatedTemplateInput> | typeof NotReady} = {}
        const claimedInputIds: string[] = []

        for (const [inputId, value] of Object.entries(node.parameters.parameters.parameters)) {
            if (value === undefined || value === null) continue

            claimedInputIds.push(inputId)
            if (isTemplateNode(value)) {
                if (isMaterialLike(value)) {
                    setTemplateInput(scope, evaledInputs, "material", inputId, this.evaluateMaterial(scope, value), value)
                } else if (isTemplateLike(value)) {
                    setTemplateInput(scope, evaledInputs, "template", inputId, this.evaluateTemplate(scope, value), value)
                } else if (isObjectLike(value)) {
                    setTemplateInput(scope, evaledInputs, "object", inputId, this.evaluateObject(scope, value), value)
                } else if (isImageLike(value)) {
                    setTemplateInput(scope, evaledInputs, "image", inputId, this.evaluateImage(scope, value), value)
                } else if (isStringLike(value)) {
                    setTemplateInput(scope, evaledInputs, "string", inputId, this.evaluateString(scope, value), value)
                } else if (isNumberLike(value)) {
                    setTemplateInput(scope, evaledInputs, "number", inputId, this.evaluateNumber(scope, value), value)
                } else if (isBooleanLike(value)) {
                    setTemplateInput(scope, evaledInputs, "boolean", inputId, this.evaluateBoolean(scope, value), value)
                } else if (isJSONLike(value)) {
                    setTemplateInput(scope, evaledInputs, "json", inputId, this.evaluateJSON(scope, value), value)
                } else {
                    throw Error(`Cannot use node ${value.getNodeClass()} as input for a template`)
                }
            } else if (typeof value === "string") {
                setTemplateInput(scope, evaledInputs, "string", inputId, value, value)
            } else if (typeof value === "number") {
                setTemplateInput(scope, evaledInputs, "number", inputId, value, value)
            } else if (typeof value === "boolean") {
                setTemplateInput(scope, evaledInputs, "boolean", inputId, value, value)
            } else if (isAnyJSONValue(value)) {
                setTemplateInput(scope, evaledInputs, "json", inputId, value, value)
            } else {
                setTemplateInput(scope, evaledInputs, "unknown", inputId, value, value)
            }
        }

        for (const [inputId, entry] of Object.entries(this.ambientInputs)) {
            const [prefix, unwrappedId] = getInterfaceIdPrefix(inputId)
            if (prefix === node.parameters.id) {
                evaledInputs[unwrappedId] = entry
            }
        }

        return [scope.group("evaledInput", evaledInputs), claimedInputIds] as const
    }
}
