import {inject, Injectable} from "@angular/core"
import {MaterialAssetsRenderingMaterialFragment} from "@api"
import {CreateJobGraphData} from "@cm/lib/job-task/job-nodes"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {
    CM_TO_MM,
    MAX_PIXEL_PER_MM,
    renderGraphForTemplateGraph,
    setupTemplateGraphForPlaneRender,
} from "@common/helpers/rendering/material/material-assets-rendering/common"
import {jobGraphFn_thumbnail} from "@common/helpers/rendering/material/material-assets-rendering/material-thumbnail"
import {jobGraphFn_shaderBall, setupTemplateGraphForShaderBallRender} from "@common/helpers/rendering/material/material-assets-rendering/shader-ball"
import {jobGraphFn_tile, tileGenerationParamsForMaterial} from "@common/helpers/rendering/material/material-assets-rendering/tileable-material"
import {JobGraphMaterial} from "@common/models/material-assets-rendering/job-graph-material"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {NameAssetFromSchemaService} from "@common/services/name-asset-from-schema/name-asset-from-schema.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {environment} from "@environment"
import {FlatOption} from "@labels"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {MeshDataBatchApiCallService} from "@app/templates/template-system/mesh-data-batch-api-call.service"
import {APIService} from "@legacy/services/api/api.service"
import {DataObjectBatchApiCallService} from "@app/templates/template-system/data-object-batch-api-call.service"
import {TemplateRevisionBatchApiCallService} from "@app/templates/template-system/template-revision-batch-api-call.service"

const FOV = 0.005 //Smaller FOV moves the camera further away from the plane when generating thumbnails & tileable images. At distances > ~100km, we get rendering artifacts.
const SENSOR_SIZE = 36
const SAMPLES = environment.rendering.materialAssets.samples
const SAMPLES_FOR_SHADER_BALL_RENDER = environment.rendering.shaderBall.samples
const SENSOR_SIZE_FOR_SHADER_BALL_RENDER = 26

@Injectable({
    providedIn: "root",
})
export class MaterialAssetsRenderingService {
    private webAssemblyWorkerService = inject(WebAssemblyWorkerService)
    private nameAssetFromSchemaService = inject(NameAssetFromSchemaService)
    private uploadGqlService = inject(UploadGqlService)
    private sdkService = inject(SdkService)
    private refreshService = inject(RefreshService)
    private materialGraphService = inject(MaterialGraphService)
    private meshDataBatchApiCallService = inject(MeshDataBatchApiCallService)
    private dataObjectBatchApiCallService = inject(DataObjectBatchApiCallService)
    private templateRevisionBatchApiCallService = inject(TemplateRevisionBatchApiCallService)

    private legacyApi = inject(APIService)

    checkThumbnailSizeValid(resolution: number, size_in_cm: number): boolean {
        return Math.floor(resolution / size_in_cm / CM_TO_MM) < MAX_PIXEL_PER_MM
    }

    async fetchAndVerifyMaterialDetails(materialId: string): Promise<
        MaterialAssetsRenderingMaterialFragment &
            JobGraphMaterial & {
                latestCyclesRevision: {legacyId: number}
            }
    > {
        const detail = (await this.sdkService.gql.materialAssetsRenderingMaterial({materialId})).material
        if (!detail.name) throw new Error(`Missing name field for material with id: ${materialId}`)
        if (!detail.latestCyclesRevision) throw new Error(`Missing latest cycles revision field for material with id: ${materialId}`)
        if (!detail.organization) throw new Error(`Missing organization field for material with id: ${materialId}`)
        return {
            ...detail,
            latestCyclesRevision: detail.latestCyclesRevision,
            name: detail.name,
            organization: detail.organization,
        }
    }

    async generateShaderBallThumbnail(materialId: string, resolution: number) {
        const materialDetails = await this.fetchAndVerifyMaterialDetails(materialId)
        const jobName = `Shader ball generation for material ${materialDetails.legacyId}`

        const templateGraphFn = () =>
            setupTemplateGraphForShaderBallRender(
                materialDetails.latestCyclesRevision.legacyId,
                resolution,
                resolution,
                SAMPLES_FOR_SHADER_BALL_RENDER,
                SENSOR_SIZE_FOR_SHADER_BALL_RENDER,
                this.sdkService,
            )

        const jobGraphFn = async (renderGraphId: number, materialDetails: JobGraphMaterial, useGpu: boolean, useCloud: boolean) =>
            jobGraphFn_shaderBall(renderGraphId, materialDetails, useGpu, useCloud)
        return this.prepareAndSubmitJob(jobName, materialDetails, templateGraphFn, jobGraphFn)
    }

    async generateThumbnail(materialId: string, flatOption: FlatOption) {
        const materialDetails = await this.fetchAndVerifyMaterialDetails(materialId)
        const jobName = `Thumbnail generation for material ${materialDetails.legacyId}`

        const defaultNameBase = `Thumbnail - ${materialDetails.name} - ${flatOption.size.toFixed(2).replace(/\./gi, ",")}cm x ${flatOption.size
            .toFixed(2)
            .replace(/\./gi, ",")}cm`
        const filenames = await this.nameAssetFromSchemaService.getMaterialThumbnailNameSet(
            materialDetails.id,
            flatOption.dataObjectAssignmentType,
            defaultNameBase,
        )

        const templateGraphFn = async () =>
            setupTemplateGraphForPlaneRender(
                materialDetails.latestCyclesRevision.legacyId,
                flatOption.resolution,
                flatOption.resolution,
                SAMPLES,
                flatOption.size,
                flatOption.size,
                FOV,
                SENSOR_SIZE,
            )

        const jobGraphFn = async (renderGraphId: number, materialDetails: JobGraphMaterial, useGpu: boolean, useCloud: boolean) =>
            jobGraphFn_thumbnail(renderGraphId, materialDetails, flatOption.resolution, flatOption.size, filenames, useGpu, useCloud)
        return this.prepareAndSubmitJob(jobName, materialDetails, templateGraphFn, jobGraphFn)
    }

    async generateTile(materialId: string): Promise<void> {
        const materialDetails = await this.fetchAndVerifyMaterialDetails(materialId)
        const jobName = `Tileable image generation for material ${materialDetails.legacyId}`

        const {
            repeatWidth,
            repeatHeight,
            repeatWidthCm,
            repeatHeightCm,
            paddingWidth,
            paddingHeight,
            paddedWidth,
            paddedHeight,
            paddedWidthCm,
            paddedHeightCm,
            offsetX,
            offsetY,
        } = await tileGenerationParamsForMaterial(materialDetails, this.materialGraphService)

        const defaultNameBase = `Tileable image - ${materialDetails.name} - ${repeatWidthCm.toFixed(2).replace(/\./gi, ",")}cm x ${repeatHeightCm
            .toFixed(2)
            .replace(/\./gi, ",")}cm`
        const filenames = await this.nameAssetFromSchemaService.getMaterialTileableRenderNameSet(materialDetails.id, defaultNameBase)

        const templateGraphFn = async () =>
            setupTemplateGraphForPlaneRender(
                materialDetails.latestCyclesRevision.legacyId,
                paddedWidth,
                paddedHeight,
                SAMPLES,
                paddedWidthCm,
                paddedHeightCm,
                FOV,
                SENSOR_SIZE,
                offsetX,
                offsetY,
            )

        const jobGraphFn = async (renderGraphId: number, materialDetails: JobGraphMaterial, useGpu: boolean, useCloud: boolean) =>
            jobGraphFn_tile(
                renderGraphId,
                materialDetails,
                repeatWidthCm,
                repeatHeightCm,
                repeatWidth,
                repeatHeight,
                paddingWidth,
                paddingHeight,
                filenames,
                useGpu,
                useCloud,
            )
        return this.prepareAndSubmitJob(jobName, materialDetails, templateGraphFn, jobGraphFn)
    }

    async prepareAndSubmitJob(
        jobName: string,
        materialDetails: {legacyId: number; name: string; organization: {id: string; legacyId: number}},
        templateGraphFn: () => Promise<Nodes.TemplateGraph>,
        jobGraphFn: (
            renderGraphId: number,
            material: {legacyId: number; name: string; organization: {id: string; legacyId: number}},
            useGpu: boolean,
            useCloud: boolean,
        ) => Promise<CreateJobGraphData>,
    ): Promise<void> {
        const useGpu = true
        const useCloud = true

        const templateGraph = await templateGraphFn()
        const renderGraph = await renderGraphForTemplateGraph(
            templateGraph,
            this.webAssemblyWorkerService,
            this.sdkService,
            this.refreshService,
            this.materialGraphService,
            this.meshDataBatchApiCallService,
            this.dataObjectBatchApiCallService,
            this.templateRevisionBatchApiCallService,
            this.legacyApi,
            useGpu,
            useCloud,
        )
        const uploadResult = await this.uploadGqlService.createAndUploadDataObject(
            new File([JSON.stringify(graphToJson(renderGraph))], "render.json", {type: "application/json"}),
            {
                organizationId: materialDetails.organization.id,
            },
            {showUploadToolbar: false, processUpload: true},
        )
        const jobGraph = await jobGraphFn(uploadResult.legacyId, materialDetails, useGpu, useCloud)

        await this.sdkService.gql.materialAssetsRenderingCreateJobTask({
            input: {
                name: jobName,
                organizationId: materialDetails.organization.id,
                graph: graphToJson(jobGraph),
            },
        })
    }
}
