import {DeclareMaterialNode, cyclesNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {threeRGBColorNode} from "@src/materials/three-utils"

export class ScannedTransmission extends DeclareMaterialNode(
    {
        returns: z.object({
            bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode).optional(),
        }),
        inputs: z.object({
            bsdf: z.instanceof(THREENodes.MeshPhysicalNodeMaterial).or(cyclesNode).optional(),
            alpha: materialSlots.optional(),
            normal: materialSlots.optional(),
            transmission: materialSlots.optional(),
            normalStrength: materialSlots.optional(),
            minTransmission: materialSlots.optional(),
            amount: materialSlots.optional(),
        }),
        parameters: z.object({
            normalStrength: z.number().optional(),
            minTransmission: z.number().optional(),
            amount: z.number().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            // just bypass this in ThreeJS mode for now
            const input_bsdf = await get(inputs.bsdf)
            return {bsdf: input_bsdf}
        },
    },
) {}
