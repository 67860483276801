import {NdArray} from "ndarray" //TODO: move these into this module?
import {Matrix4} from "@src/math"

// utility functions for working with ndarrays:

export type Matrix = NdArray
export type Vector = NdArray
export type Tensor = NdArray

/** A set of parameters which should be optimized. Note that the parameter values are never accessed directly by the solver! Only the deltas are used to update them,
    and the deltas may be in a different space than the stored parameters! This allows optimization over the local tangent space of a manifold. This requires the Jacobians
    calculated by a residual blocks to be defined with respect to this "local" space of parameter deltas, rather than the parameters themselves.
*/
export interface IParameterBlock {
    readonly size: number /** the number of scalar parameters in this block */
    updateWithDelta(delta: Vector): void
    save(): number[]
    restore(data: number[]): void
}

/** A combination of a vector of residuals and one or more related parameter blocks. Each residual vector + parameter block combination represents a non-zero block of the system Jacobian */
export interface IResidualBlock {
    readonly type: "linear" | "linearConstrained" | "quadratic" | "quadraticConstrained" | "generalNonlinear"
    readonly size: number
    active: boolean /** true if the residuals should be updated, false if they should be set to zero. */
    readonly relatedParameters: IParameterBlock[]
    evaluate(residuals: Vector, jacobians: Matrix[]): boolean // should SUM into jacobian array
}

export interface ILinearOperatorParameterBlock extends IParameterBlock {
    readonly matrix: Matrix
    readonly matrixInv: Matrix
    readonly jacobian: Tensor
    readonly jacobianInv: Tensor
}

/** An optimizable entity as a collection of related residual blocks.
    These blocks will be added to and removed from the solver together as one unit.
    An entity is a single 'thing' which is being optimized, but is not necessarily a single object.
    An example would be a surface-surface constraint, or a force being applied to an object.
    (The objects themselves may be represented as _parameters_ in the residual blocks, which may be shared.)
    */
export interface IOptimizationEntity {
    /** The set of associated residual blocks (these should not be shared across entities! A given residual block should appear in only one location.) */
    readonly residualBlocks: IResidualBlock[]
    /** If this function is defined, it will be called before every evaluation step. It may be used to update internal state that is relevant to the residual/jacobian evaluation. */
    preEval?(): void
    /** If this function is defined, it will be called after convergence. Return value indicates if more steps are needed. */
    postConverge?(): boolean
}

export enum RotationalFreedomMode {
    None = 0,
    Full = 1,
    X_Only = 2,
    Y_Only = 3,
    Z_Only = 4,
}

/** Represents 3D position and orientation as a 3-vector and a quaternion, but with all updates to the quaternion taking place in the local tangent space. (Lie algebra of SE(3))
    See "A tutorial on SE(3) transformation parameterizations and on-manifold optimization"
        http://ingmec.ual.es/~jlblanco/papers/jlblanco2010geometry3D_techrep.pdf
  */
export interface ITransformParameters3D extends ILinearOperatorParameterBlock {
    tx: number
    ty: number
    tz: number
    qx: number
    qy: number
    qz: number
    qw: number

    tx_free: boolean
    ty_free: boolean
    tz_free: boolean
    r_free: RotationalFreedomMode

    hasFreeParams(): boolean
}

export interface IRelation extends IOptimizationEntity {
    readonly objectA: IRigidBody
    readonly objectB: IRigidBody
    transform: ITransformParameters3D
    update(): void

    transformLimits: {
        tx: number[]
        ty: number[]
        tz: number[]
    }
}

/** A rigid body with a position and orientation in world space */
export interface IRigidBody {
    readonly transform: ILinearOperatorParameterBlock
}

export type ObjectId = string
export type VariableId = string
export type VariableType = "boundedReal" | "1-sphere" | "3-sphere"

export interface IVariable_R1 {
    type: "boundedReal"
    relation: IRelation
    property: "tx" | "ty" | "tz"
    range: [number, number]
    default: number
    value: number
    holdDefault: boolean
}

export interface IVariable_S1 {
    type: "1-sphere"
    relation: IRelation
    property: "rx" | "ry" | "rz"
    default: [number, number]
    value: [number, number]
    holdDefault: boolean
}

export interface IVariable_S3 {
    type: "3-sphere"
    relation: IRelation
    property: "r"
    default: [number, number, number, number]
    value: [number, number, number, number]
    holdDefault: boolean
}

export type IVariable = IVariable_R1 | IVariable_S1 | IVariable_S3

export type FixedRotationalJoint = {
    type: "fixed"
    rx: number
    ry: number
    rz: number
}

export type HingeRotationalJoint = {
    type: "hinge"
    axis: "x" | "y" | "z"
    angleVariable: IVariable_S1
}

export type BallRotationalJoint = {
    type: "ball"
    angleVariable: IVariable_S3
}

export type TranslationalJoint = {
    tx: number | IVariable_R1
    ty: number | IVariable_R1
    tz: number | IVariable_R1
}

export type RotationalJoint = FixedRotationalJoint | HingeRotationalJoint | BallRotationalJoint

export interface IConnectionSolver {
    removeRelation(relation: IRelation): void
    removeObject(objId: ObjectId): void
    addObject(objId: ObjectId): IRigidBody
    addObjectRelation(objIdA: ObjectId, objIdB: ObjectId): IRelation | undefined
    addVariable(varId: VariableId, type: VariableType): IVariable
    removeVariable(variable: IVariable): void
    setVariableDefault(
        variable: IVariable,
        defaultValue: number | readonly [number, number] | readonly [number, number, number] | readonly [number, number, number, number],
        holdUntilConvergence: boolean,
        triggerUpdate?: boolean,
    ): void
    setVariableConstraints(variable: IVariable, range: [number, number], triggerUpdate?: boolean): void
    setRelationConstraints(relation: IRelation, trans: TranslationalJoint, rot: RotationalJoint, triggerUpdate?: boolean): void
    setObjectMatrix(objId: ObjectId, matrix: Matrix4): void
    setObjectActive(objId: ObjectId, translationActive: boolean, rotationActive?: boolean, force?: boolean): void
    propagateFixedRelations(rootIds: ObjectId[]): void
    getObjectMatrix(objId: ObjectId): Matrix4 | null
}
