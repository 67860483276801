import {EventEmitter} from "@angular/core"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import * as paper from "paper"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {TilingAreaToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-toolbox-item"
import {LabelToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/label-item"

export class TilingAreaBoundaryCurveToolboxItem extends CanvasBaseToolboxItemBase<TilingAreaToolboxItem> {
    readonly clicked = new EventEmitter<{position: Vector2; t: number}>()

    constructor(
        parent: TilingAreaToolboxItem,
        private flipLabelSide: boolean,
    ) {
        super(parent)

        this._label = new LabelToolboxItem(this)
        this._label.visible = false

        this.viewChange.subscribe(() => this.updateZoomDependent())

        this.sendToBack()
    }

    override remove() {
        super.remove()
        this._curve?.remove()
    }

    override hitTest(point: Vector2Like): boolean {
        if (this._curve?.hitTest(point, {stroke: true, tolerance: (5 * window.devicePixelRatio) / this.zoomLevel})) {
            this.cursor = "crosshair"
            return true
        }
        return false
    }

    override onMouseDown(event: paper.ToolEvent): boolean {
        if (this._curve) {
            const t = this.computeClosestT(event.downPoint)
            this.clicked.emit({position: Vector2.fromVector2Like(event.downPoint), t})
            return true
        }
        return false
    }

    setCurvePoints(positions: Vector2[], tValues: number[]) {
        this._positions = positions
        this._tValues = tValues

        this.beginPaperCreation()

        this._curve?.remove()
        this._curve = new paper.Path(this._positions)
        this._curve.strokeColor = new paper.Color("red")
        this._curve.strokeColor.alpha = 0.7

        this.updateCurveLabel()
        this.updateZoomDependent()
    }

    private computeClosestT(point: Vector2Like) {
        if (!this._curve) {
            throw new Error("Curve not found")
        }
        const curvePoint = this._curve.getNearestPoint(point)
        const location = this._curve.getLocationOf(curvePoint)
        const p0 = this._positions[location.index]
        const p1 = this._positions[location.index + 1]
        const t = curvePoint.getDistance(p0) / Vector2.distance(p0, p1) // compute t value between p0 and p1 based on the distance between curvePoint and p0 (as the location.time value is non-linear)
        const t0 = this._tValues[location.index]
        const t1 = this._tValues[location.index + 1]
        return t0 + (t1 - t0) * t
    }

    private updateZoomDependent() {
        if (this._curve) {
            this._curve.strokeWidth = window.devicePixelRatio / this.zoomLevel
        }
    }

    private updateCurveLabel() {
        const firstPosition = this._positions[0]
        const lastPosition = this._positions[this._positions.length - 1]
        const direction = lastPosition.sub(firstPosition).normalized()
        const directionPerp = direction.perp()
        if (this.flipLabelSide) {
            directionPerp.negateInPlace()
        }
        let angle = (Math.atan2(direction.y, direction.x) / Math.PI) * 180
        if (90 < angle && angle < 180) {
            angle -= 180
        } else if (-180 < angle && angle < -90) {
            angle += 180
        }
        const center = firstPosition.add(lastPosition).div(2)
        const minCurveDeviationAlongDirectionPerp = this._positions.reduce((minDeviation, position) => {
            const deviation = position.sub(center).dot(directionPerp)
            return Math.min(minDeviation, deviation)
        }, 0)
        const labelPosition = center.add(directionPerp.mul(minCurveDeviationAlongDirectionPerp))
        const getCentimetersLabel = (pixels: number) => {
            if (!this.physicalInfo) {
                return "??? cm"
            }
            const cm: number = Math.round((pixels / this.physicalInfo.pixelsPerCm) * 100) / 100
            return `${cm.toFixed(2)} cm`
        }
        const curveLength = this._curve?.length ?? 0
        const text = `${getCentimetersLabel(curveLength)} (${Math.round(curveLength)} px)`
        this._label.text$.next(text)
        this._label.angle$.next(angle)
        this._label.position$.next(labelPosition)
        this._label.screenSpaceOffset$.next(directionPerp.mul(-(this._label.baseFontSize / 2 + 10)))
        this._label.visible = true
    }

    private _positions: Vector2[] = []
    private _tValues: number[] = []
    private _curve: paper.Path | undefined
    private _label: LabelToolboxItem
}
