// @ts-strict-ignore
import {Navigation} from "@editor/helpers/scene/navigation"
import {SelectionEvent} from "@editor/helpers/scene/selection"
import {ThreeCamera} from "@editor/helpers/scene/three-proxies/camera"
import {ThreeScene, ThreeSceneConfig} from "@editor/helpers/scene/three-proxies/scene"
import {ObjectId, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {SceneManager} from "app/templates/template-system/scene-manager"
import {Subject, Subscription, takeUntil} from "rxjs"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {Selection} from "@editor/helpers/scene/selection"
import {Matrix4} from "@cm/lib/math"
import {RenderView} from "@editor/helpers/scene/three-proxies/render-view"

export interface IEditor {
    sceneManager: SceneManager
    applyWorldTransformToNodes(nodes: Nodes.Node[], worldTransform: Matrix4, lockTransform: boolean): void

    //TODO: move UI state elsewhere?
    highlightOutlines: [ObjectId, number | undefined][] | null

    // used by template tree:
    //   addTask
    //   applyWorldTransformToNodes
    //   captureRelationParams
    //   centroidForNodes
    //   deleteNodes
    //   getAccessibleNodes
    //   getNodeErrors
    //   resetNodeTransform
    //   worldTransformForNode
}

// ---------------------------------------------------------------------------------------------------------------------

export type DisplayScene = ThreeScene
export const DisplayScene = ThreeScene
export type DisplayView = RenderView
export type SceneConfiguration = ThreeSceneConfig
export type SceneViewConfiguration = {
    editMode?: boolean
    showOutlines?: boolean
    focusMode?: Nodes.FocusMode
    navigationFocus?: "trackHostFocusBlurEvents"
}

export class SceneView {
    config: SceneViewConfiguration = {}
    renderView: DisplayView

    _cameraId?: string
    _defaultCamera: ThreeCamera

    constructor(protected manager: SceneViewManager) {
        this.renderView = manager.displayScene.createRenderView()
        this._defaultCamera = this.renderView.camera
        manager.addView(this)
        this.manager = manager
    }

    updateDefaultCamera(node: SceneNodes.Camera) {
        this._defaultCamera.update(node)
    }

    setConfig(newConfig: SceneViewConfiguration) {
        this.config = {
            ...newConfig,
        }
    }

    setCameraId(cameraId: string) {
        this._cameraId = cameraId
        this.manager?.updateCameraForView(this)
    }

    setOutlines(outlines: IEditor["highlightOutlines"]) {
        const objects = this.manager.displayScene.getObjectsForOutlines(outlines)
        this.renderView.setOutlines(objects)
    }

    initViewport(hostElem: HTMLElement): void {
        if (hostElem) {
            const viewElem = this.renderView.getDOMElement()
            viewElem.remove()
            viewElem.style.width = "100%"
            viewElem.style.height = "100%"
            viewElem.style.maxWidth = "100%"
            hostElem.appendChild(viewElem)
        }
    }

    updateSize(width: number, height: number): boolean {
        return this.renderView.updateSize(width, height)
    }

    getSize() {
        return this.renderView.getSize()
    }

    destroy(): void {
        this.manager.removeView(this)
        this.renderView.dispose()
    }
}

export class InteractiveSceneView extends SceneView {
    navigation: Navigation
    selectionMgr: Selection

    private navigationChangeSubscription: Subscription = null
    private selectionEventSubscription: Subscription = null

    onNavigationChange?: () => void = null
    onSelectionEvent?: (event: SelectionEvent) => void = null

    constructor(manager: SceneViewManager) {
        super(manager)
        this.initSelection()
        this.initNavigation()
    }

    override setConfig(newConfig: SceneViewConfiguration) {
        super.setConfig(newConfig)
        const config = this.config
        const isEditMode = this.config.editMode === true
        this.renderView.setEditMode(isEditMode)
        this.renderView.setTAAFadeIn(!isEditMode) // disable TAA fade-in in edit mode
    }

    private initNavigation(): void {
        this.navigation = new Navigation(this.renderView, this.renderView.getDOMElement())
        this.navigation.orbitControls.rotateSpeed = 0.9
        this.navigationChangeSubscription = this.navigation.change.subscribe(() => {
            this.manager?.displayScene.update()
            this.onNavigationChange?.()
        })
    }

    private destroyNavigation(): void {
        if (this.navigationChangeSubscription) {
            this.navigationChangeSubscription.unsubscribe()
            this.navigationChangeSubscription = null
        }
        if (this.navigation) this.navigation.destroy()
    }

    private initSelection(): void {
        this.selectionMgr = new Selection(this.renderView)
        this.selectionEventSubscription = this.selectionMgr.selectionEvent.subscribe((event) => {
            this.onSelectionEvent?.(event)
            if (this.config && this.config.focusMode && this.config.focusMode === "click") {
                this.renderView.overrideFocusDistance = this.renderView.getFocusDepthForPoint(event.surfacePointInfo)
            }
        })
    }

    private destroySelection(): void {
        if (this.selectionEventSubscription) {
            this.selectionEventSubscription.unsubscribe()
            this.selectionEventSubscription = null
        }
        if (this.selectionMgr) this.selectionMgr.destroy()
    }

    override destroy(): void {
        super.destroy()
        this.destroyNavigation()
        this.destroySelection()
    }
}

export class SceneViewManager {
    private views: SceneView[] = []
    private unsubscribe = new Subject<void>()

    constructor(public displayScene: DisplayScene) {
        this.displayScene.cameraAdded$.pipe(takeUntil(this.unsubscribe)).subscribe((cameraId) => {
            this.views.forEach((view) => this.updateCameraForView(view))
        })

        this.displayScene.cameraRemoved$.pipe(takeUntil(this.unsubscribe)).subscribe((cameraId) => {
            this.views.forEach((view) => this.updateCameraForView(view))
        })
    }

    viewsNavigationAccessor() {
        return this.views.filter((sv) => sv instanceof InteractiveSceneView && sv.navigation).map((sv) => (sv as InteractiveSceneView).navigation)
    }

    viewAdded$ = new Subject<SceneView | InteractiveSceneView>()
    viewRemoved$ = new Subject<SceneView | InteractiveSceneView>()
    viewCameraChanged$ = new Subject<SceneView | InteractiveSceneView>()

    addView(view: SceneView) {
        const idx = this.views.indexOf(view)
        if (idx !== -1) return
        this.views.push(view)
        this.viewAdded$.next(view)
    }

    removeView(view: SceneView) {
        const idx = this.views.indexOf(view)
        if (idx === -1) return
        this.views.splice(idx, 1)
        this.viewRemoved$.next(view)
    }

    updateCameraForView(view: SceneView | InteractiveSceneView): void {
        const resolvedCamera = view._cameraId && (this.displayScene.getObjectForId(view._cameraId) as ThreeCamera)
        const curCamera = view.renderView.camera === view._defaultCamera ? undefined : view.renderView.camera
        if (resolvedCamera !== curCamera) {
            view.renderView.camera = resolvedCamera ?? view._defaultCamera
            this.viewCameraChanged$.next(view)
        }
    }

    destroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.viewAdded$.complete()
        this.viewRemoved$.complete()
        this.viewCameraChanged$.complete()
        this.views = null
    }
}
