import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {color} from "@src/materials/types"
import {threeRGBColorNode, threeConvert, threeValueNode} from "@src/materials/three-utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class BrightnessContrast extends DeclareMaterialNode(
    {
        returns: z.object({color: materialSlots}),
        inputs: z.object({color: materialSlots.optional(), bright: materialSlots.optional(), contrast: materialSlots.optional()}),
        parameters: z.object({color: color.optional(), bright: z.number().optional(), contrast: z.number().optional()}),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const colorValue = (await get(inputs.color)) ?? threeConvert(parameters.color, threeRGBColorNode) ?? threeRGBColorNode({r: 0, g: 0, b: 0})
            const brightnessValue = (await get(inputs.bright)) ?? threeConvert(parameters.bright, threeValueNode) ?? threeValueNode(0)
            const contrastValue = (await get(inputs.contrast)) ?? threeConvert(parameters.contrast, threeValueNode) ?? threeValueNode(0)

            const aNodeF = THREENodes.add(threeValueNode(1.0), contrastValue)
            const halfContrast = THREENodes.mul(threeValueNode(0.5), contrastValue)
            const bNodeF = THREENodes.sub(brightnessValue, halfContrast)
            const bNodeC = THREENodes.mul(threeValueNode(1), bNodeF)
            const mulNodeC = THREENodes.mul(aNodeF, colorValue)

            return {color: THREENodes.add(mulNodeC, bNodeC)}
        },
    },
) {}
