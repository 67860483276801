<cm-action-panel #infoPanel arrowRight="13px" minWidth="250px" maxWidth="250px">
    @if (fileName(); as fileName) {
        <cm-action-panel-row cmIconClass="far fa-image-polaroid">
            <span class="cm-file-name" [matTooltip]="fileName" matTooltipPosition="above" matTooltipClass="cm-untruncated-tooltip">
                {{ fileName }}
            </span>
        </cm-action-panel-row>
    }
    <cm-action-panel-row cmIconClass="far fa-up-right-and-down-left-from-center">
        {{ width() }} px × {{ height() }} px
        @if (size(); as size) {
            ({{ size | filesize: {base: 2} }})
        }
    </cm-action-panel-row>
    @if (imageColorProfile(); as imageColorProfile) {
        <cm-action-panel-row cmIconClass="far fa-palette">
            {{ imageColorProfile == "none" ? "No color profile" : imageColorProfile }}
        </cm-action-panel-row>
    }
    <cm-action-panel-row cmIconClass="far fa-magnifying-glass">{{ getZoomLevel() }}%</cm-action-panel-row>
</cm-action-panel>
<cm-action-bar>
    <ng-container cm-action-items>
        <ng-content select="[cm-action-items-pos1]"></ng-content>
        <cm-action-item (click)="navigation()?.zoomTo(1)" tooltip="Zoom to 100%" cmIconClass="far fa-expand"></cm-action-item>
        <cm-action-item (click)="navigation()?.zoomToFitImage()" tooltip="Zoom to fit" cmIconClass="far fa-compress"></cm-action-item>
        <cm-action-item tooltip="Download" cmIconClass="far fa-arrow-to-bottom" [matMenuTriggerFor]="downloadPictureMenu">
            <mat-menu #downloadPictureMenu="matMenu">
                @if (dataObject()) {
                    <button mat-menu-item (click)="downloadOriginal()">Original ({{ getFileTypeLabel() }})</button>
                    <button mat-menu-item (click)="downloadJpeg()">JPEG</button>
                }
                @if (imageData()) {
                    <button mat-menu-item (click)="downloadFromImageData('png')">PNG</button>
                    <button mat-menu-item (click)="downloadFromImageData('jpeg')">JPEG</button>
                }
            </mat-menu></cm-action-item
        >
        <ng-content select="[cm-action-items-pos2]"></ng-content>
        <cm-action-item [showPanelByDefault]="!isMobileDevice" tooltip="Info" [panel]="infoPanel" cmIconClass="far fa-info-circle"></cm-action-item>
    </ng-container>
</cm-action-bar>
