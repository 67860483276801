import {Component, computed, input, output} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {PricingNode} from "@cm/lib/pricing/declare-pricing-node"
import {PricingContext} from "@cm/lib/pricing/nodes/core"
import {PricedItemNode} from "@cm/lib/pricing/nodes/priced-item-node"
import {VariantConditionNodeComponent} from "@pricing/components/node-visualizers/variant-condition-node/variant-condition-node.component"

type ValidityStatus = "valid" | "warning" | "error"

@Component({
    standalone: true,
    selector: "cm-priced-item",
    templateUrl: "./priced-item-node.component.html",
    styleUrls: ["./priced-item-node.component.scss", "../node-styles.scss"],
    imports: [VariantConditionNodeComponent, MatTooltipModule],
})
export class PricedItemNodeComponent {
    $node = input.required<PricingNode>()

    $pricedItemNode = computed<PricedItemNode | undefined>(() => {
        const node = this.$node()
        return node instanceof PricedItemNode ? node : undefined
    })

    $pricingContext = input.required<PricingContext>()

    $priceResult = computed<{price?: number; error?: string}>(() => {
        const node = this.$pricedItemNode()
        if (node) {
            try {
                const price = node.getPrice(this.$pricingContext())
                return {price}
            } catch (e) {
                return {error: String(e)}
            }
        } else {
            return {error: "Node is undefined"}
        }
    })

    $validityStatus = computed<ValidityStatus>(() => {
        const priceResult = this.$priceResult()
        const node = this.$pricedItemNode()

        const hasPrice = priceResult.price !== undefined

        const condition = node?.parameters.condition?.parameters.condition
        const hasCondition = condition ? condition.variantIds.length > 0 : false

        if (hasPrice) {
            return "valid"
        } else if (!hasPrice && !hasCondition) {
            return "warning"
        } else {
            return "error"
        }
    })

    $description = computed<string>(() => {
        const node = this.$pricedItemNode()
        const result = this.$priceResult()
        if (node) {
            return result.price !== undefined ? `${node.getDescription()}: ${result.price}` : `${node.getDescription()}: Could not get price`
        } else {
            return ""
        }
    })

    $conditionRemoved = output<void>()

    constructor(public configMenuService: ConfigMenuLegacyService) {}
}
