import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {assertBatchSizeOne} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

export type ParameterType = {
    sourceImage: ImageRef
    kernel: {
        width: number
        height: number
        values: number[] // row-major
    }
    borderMode: "wrap" | "clamp" | "wrap-mirrored" | "zero" // TODO switch to AddressMode
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export const imageOpConvolve: ImageOpType<ParameterType, ReturnType> = {
    name: "Convolve",

    WebGL2: ({cmdQueue, parameters: {sourceImage, kernel, borderMode, resultImageOrDataType}}) => {
        assertBatchSizeOne(sourceImage.descriptor)
        if (kernel.values.length != kernel.width * kernel.height) {
            throw new Error("Kernel values array length doesn't match the kernel size")
        }
        const painter = cmdQueue.createPainter(
            "compositor",
            "convolve",
            `
            uniform float u_kernel[${kernel.width} * ${kernel.height}];

            vec4 computeColor(ivec2 targetPixel) {
                vec4 color = vec4(0);
                ivec2 kernelOfs = (ivec2(${kernel.width}, ${kernel.height}) - 1) / 2;
                for (int ky = 0; ky < ${kernel.height}; ky++) {
                    for (int kx = 0; kx < ${kernel.width}; kx++) {
                        float k = u_kernel[kx + ky * ${kernel.width}];
                        ivec2 sourcePixelIndex = targetPixel + ivec2(kx, ky) - kernelOfs;
                        vec4 i = texelFetch0(sourcePixelIndex, ${borderMode === "wrap" ? "ADDRESS_MODE_REPEAT" : borderMode === "clamp" ? "ADDRESS_MODE_CLAMP_TO_EDGE" : borderMode === "wrap-mirrored" ? "ADDRESS_MODE_MIRRORED_REPEAT" : "ADDRESS_MODE_BORDER"});
                        color += i * k;
                    }
                }
                return color;
            }
        `,
        )
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, sourceImage.descriptor)
        cmdQueue.paint(painter, {
            parameters: {
                u_kernel: {type: "float[]", value: kernel.values},
            },
            sourceImages: sourceImage,
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, kernel, borderMode, resultImageOrDataType}}) => {
        assertBatchSizeOne(sourceImage.descriptor)
        if (kernel.values.length != kernel.width * kernel.height) {
            throw new Error("Kernel values array length doesn't match the kernel size")
        }
        const resultNode = cmdQueue.createImage(sourceImage.descriptor, {
            type: "convolve",
            input: sourceImage,
            kernel: {type: "constKernel", width: kernel.width, height: kernel.height, data: kernel.values},
            borderMode,
        })
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function convolve(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpConvolve, parameters)
}
