export class NotVisibleError extends Error {
    constructor() {
        super("Not visible")
    }
}

export enum ErrorCode {
    Generic = 400,
    Unauthenticated = 401,
    Forbidden = 403,
    Conflict = 409,
    Server = 500,
}

export const ErrorCodeLookup = {
    400: ErrorCode.Generic,
    401: ErrorCode.Unauthenticated,
    403: ErrorCode.Forbidden,
    409: ErrorCode.Conflict,
    500: ErrorCode.Server,
}

export type ErrorInfo = {message: string; code: ErrorCode}
