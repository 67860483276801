export type BatchUpdateProperty = "addTagId" | "assignUserId" | "nextActor" | "paymentState" | "removeTagId" | "removeUserAssignment" | "state"
export type BatchUpdateType = (property: BatchUpdateProperty, value: string | boolean) => Promise<number>
export type BatchMenuItem<EntityType extends {id: string; legacyId: number}> = {
    groupLabel: string
    items: {
        label: string
        operation: (item: EntityType) => Promise<boolean>
        text?: string
        tooltip?: string
    }[]
}

export type BatchDownloadData<EntityType extends {id: string; legacyId: number}> = {
    pathInMenu: string
    getDataObjectsForDownload: (item: EntityType) => Promise<{path: string; dataObjectLegacyId: number}[]>
}

export type AdvancedAction = {
    label: string
    operation: () => Promise<void> | void
    tooltip?: string
}

export const emptyBatchEditInfo = {
    running: false,
    total: 0,
    numSucceeded: 0,
    numSkipped: 0,
    numErrored: 0,
}
