import {NodeGraphClass, NodeParameters, nodeParameters} from "@src/graph-system/node-graph"
import {TemplateNodeTSImplementation, TemplateNodeImplementation, TemplateNodeMeta} from "@src/templates/declare-template-node"
import {z} from "zod"
import {DeclareObjectNodeTS, ObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {MeshData} from "@src/geometry-processing/mesh-data"
import {BuilderOutlet} from "@src/templates/runtime-graph/graph-builder"
import {dataObjectNewToImageRessource, isIDataObjectNew} from "@src/templates/interfaces/data-object"
import {SceneNodes, MeshRenderSettings} from "@src/templates/interfaces/scene-object"
import {OnCompileContext} from "@src/templates/types"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {MaterialAssignments} from "@src/templates/nodes/material-assignment"
import {imageLike} from "@src/templates/node-types"
import {nodeInstance} from "@src/graph-system/instance"

const meshNode = z.object({
    subdivisionRenderIterations: z.number().optional(),
    displacementTexture: imageLike.optional(),
    displacementUvChannel: z.number().optional(),
    displacementMin: z.number().optional(),
    displacementMax: z.number().optional(),
    displacementNormalStrength: z.number().optional(),
    displacementNormalSmoothness: z.number().optional(),
    displacementNormalOriginalResolution: z.boolean().optional(),
    materialAssignments: nodeInstance(MaterialAssignments),
    materialSlotNames: z.record(z.string()),
    visibleDirectly: z.boolean(),
    visibleInReflections: z.boolean(),
    visibleInRefractions: z.boolean(),
    receiveRealtimeShadows: z.boolean(),
    castRealtimeShadows: z.boolean(),
})
export type MeshNode = z.infer<typeof meshNode>

export function DeclareMeshNode<ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        parameters: ZodParamTypes
    },
    implementation: TemplateNodeImplementation<z.infer<typeof definition.parameters> & MeshNode & ObjectNode>,
    meta: TemplateNodeMeta<z.infer<typeof definition.parameters> & MeshNode & ObjectNode>,
) {
    const {parameters: paramsSchema} = definition
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareMeshNodeTS<ParamTypes>({...implementation, validation: {paramsSchema}}, meta)
}

export function DeclareMeshNodeTS<ParamTypes extends NodeParameters>(
    implementation: TemplateNodeTSImplementation<ParamTypes & MeshNode & ObjectNode>,
    meta: TemplateNodeMeta<ParamTypes & MeshNode & ObjectNode>,
): NodeGraphClass<TemplateMeshNode<ParamTypes>> {
    const retClass = class extends DeclareObjectNodeTS<ParamTypes & MeshNode & ObjectNode>(
        {...implementation, validation: {paramsSchema: meshNode.and(implementation.validation?.paramsSchema ?? nodeParameters)}},
        meta,
    ) {
        setupMesh(scope: GraphBuilderScope, context: OnCompileContext, meshData: BuilderOutlet<MeshData>, isProcedural: boolean) {
            const {evaluator, topLevelObjectId} = context

            const {
                displacementTexture,
                displacementUvChannel,
                displacementMin,
                displacementMax,
                displacementNormalStrength,
                displacementNormalSmoothness,
                displacementNormalOriginalResolution,
                visibleDirectly,
                visibleInReflections,
                visibleInRefractions,
                materialAssignments,
                receiveRealtimeShadows,
                castRealtimeShadows,
            } = this.parameters

            const materialMap = materialAssignments.setupMaterialAssignments(scope, context)

            const displacementImageResource = scope.lambda(
                evaluator.evaluateImage(scope, displacementTexture ?? null),
                (displacementImage) => {
                    const dataObject = displacementImage?.dataObject
                    if (!dataObject) return undefined
                    if (isIDataObjectNew(dataObject)) return dataObjectNewToImageRessource(dataObject)
                    else {
                        console.warn("Transient data object passed to displacement texture. This is not supported.")
                        return undefined
                    }
                },
                "displacementDataObject",
            )

            const meshRenderSettings = scope.struct<MeshRenderSettings>("MeshRenderSettings", {
                displacementUvChannel,
                displacementMin,
                displacementMax,
                displacementNormalStrength,
                displacementNormalSmoothness,
                displacementNormalOriginalResolution,
                displacementImageResource,
                // cryptoMatteObjectName: topLevelObjectId ?? objProps.id,
                cryptoMatteAssetName: topLevelObjectId ?? undefined,
            })

            this.setupObject(scope, context, "Mesh", meshData, undefined, (objectProps) => {
                return scope.struct<SceneNodes.Mesh>("Mesh", {
                    type: "Mesh",
                    ...objectProps,
                    meshData,
                    materialMap,
                    meshRenderSettings,
                    visibleDirectly,
                    visibleInReflections,
                    visibleInRefractions,
                    receiveRealtimeShadows,
                    castRealtimeShadows,
                    isDecal: false,
                    isProcedural,
                })
            })
        }
    }
    return retClass
}

export type TemplateMeshNode<ParamTypes extends NodeParameters = {}> = TemplateObjectNode<ParamTypes & MeshNode> & {
    setupMesh(scope: GraphBuilderScope, context: OnCompileContext, meshData: BuilderOutlet<MeshData>, isProcedural: boolean): void
}
