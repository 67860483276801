import {Component, input} from "@angular/core"
import {PricedItem} from "@cm/lib/pricing/nodes/core"
import {CurrencyPipe} from "@angular/common"
import {TippyDirective} from "@ngneat/helipopper"

@Component({
    standalone: true,
    selector: "cm-price-table",
    templateUrl: "./price-table.component.html",
    styleUrls: ["./price-table.component.scss"],
    imports: [CurrencyPipe, TippyDirective],
})
export class PriceTableComponent {
    $items = input.required<PricedItem[]>()
    $error = input.required<string>()
}
