import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {TypeDescriptors} from "../type-descriptors"

const TD = TypeDescriptors

const mergeDescriptor = {
    input: TD.inlet(TD.Array(TD.Array(TD.Identity<any>()))),
    output: TD.outlet(TD.Identity<any>()),
}

export class Merge<T> implements NodeClassImpl<typeof mergeDescriptor, typeof Merge> {
    static descriptor = mergeDescriptor
    static uniqueName = "Merge"
    input!: Inlet<T[][]>
    output!: Outlet<T[]>

    run() {
        if (this.input === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const tmp: T[] = []
        for (const xs of this.input) {
            for (const x of xs) {
                tmp.push(x)
            }
        }
        this.output.emit(tmp)
    }
}
