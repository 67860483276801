import {ImageBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-base"
import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {HalImageChannelLayout, HalImageDataType, HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {isHalImageDescriptor} from "@common/helpers/hal"
import {Size2Like} from "@cm/lib/math"
import {MakeOptional} from "@cm/lib/utils/type"

export type ChannelLayout = HalImageChannelLayout
export type DataType = HalImageDataType
export type ImageDescriptor = HalImageDescriptor & {
    batchSize: Size2Like // number of images in a batch
}
export type ImageDescriptorWithOptionals = MakeOptional<ImageDescriptor, "batchSize"> // default: {width: 1, height: 1}

export function isImageDescriptor(obj?: unknown): obj is ImageDescriptor {
    return isHalImageDescriptor(obj) && "batchSize" in obj
}

export function isImageDescriptorWithOptionals(obj?: unknown): obj is ImageDescriptorWithOptionals {
    return isHalImageDescriptor(obj)
}

export type ImageRefId = number | string
export type AddressSpace = "temporary" | "drawable" | "data-object"

export type ImageRef = {
    readonly isImageRef: true
    readonly addressSpace: AddressSpace
    readonly id: ImageRefId
    readonly descriptor: ImageDescriptor
    readonly debugInfo?: string
}

export function makeImageRef(addressSpace: AddressSpace, id: ImageRefId, descriptor: ImageDescriptor, debugInfo?: string): ImageRef {
    return {
        isImageRef: true,
        addressSpace,
        id,
        descriptor,
        debugInfo,
    }
}

export function isImageRef(obj: unknown): obj is ImageRef {
    return typeof obj === "object" && obj != null && "isImageRef" in obj && obj.isImageRef === true
}

export class RefCountedImageRef extends ImageBase implements ImageRef {
    readonly isImageRef = true

    constructor(addressSpace: AddressSpace, id: ImageRefId, descriptor: ImageDescriptor, disposeFn?: (image: ImageBase) => void, debugInfo?: string) {
        super(descriptor, addressSpace, id, disposeFn, debugInfo)
    }
}

export class ManagedImageRef extends SmartPtr<RefCountedImageRef> {}
