import {Component} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent, SearchFilterComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {Labels} from "@labels"
import {FilesGridComponent} from "@platform/components/files/files-grid/files-grid.component"
import {HdrisGridComponent} from "@platform/components/hdris/hdris-grid/hdris-grid.component"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"
import {BasePageComponent} from "@pages/base/base-page.component"

@Component({
    selector: "cm-files-page",
    standalone: true,
    imports: [
        SidebarLayoutComponent,
        CheckboxesFilterComponent,
        HdrisGridComponent,
        ItemFiltersComponent,
        RouterOutlet,
        SearchFilterComponent,
        TagSearchFilterComponent,
        FilesGridComponent,
    ],
    templateUrl: "./files-page.component.html",
    styleUrl: "./files-page.component.scss",
})
export class FilesPageComponent extends BasePageComponent {
    readonly Labels = Labels
}
