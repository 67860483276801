import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {Subscription, from, map} from "rxjs"

const TD = TypeDescriptors

const loadGraphNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    templateRevisionId: TD.inlet(TD.Number),
    graph: TD.outlet(TD.Identity<TemplateGraph>()),
}

export class LoadGraphNew implements NodeClassImpl<typeof loadGraphNewDescriptor, typeof LoadGraphNew> {
    static descriptor = loadGraphNewDescriptor
    static uniqueName = "LoadGraphNew"
    sceneManager!: Inlet<ISceneManagerNew>
    templateRevisionId!: Inlet<number>
    graph!: Outlet<TemplateGraph>

    private pending: Subscription | null = null

    run() {
        this.graph.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.templateRevisionId === NotReady) {
            return
        }

        this.pending = this.sceneManager.addTaskNew(
            `loadTemplateGraph(${this.templateRevisionId})`,
            from(this.sceneManager.loadTemplateGraphNew(this.templateRevisionId)).pipe(
                map((graph) => {
                    this.pending = null
                    this.graph.emitIfChanged(graph)
                }),
            ),
            true,
        )
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
