import {Component, inject, OnInit} from "@angular/core"
import {MatPaginatorModule} from "@angular/material/paginator"
import {RouterOutlet} from "@angular/router"
import {PaginatorComponent} from "@common/components/data"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {Labels, StateLabel} from "@labels"
import {JobsGridComponent} from "@platform/components/jobs/jobs-grid/jobs-grid.component"
import {JobsTableComponent} from "@platform/components/jobs/jobs-table/jobs-table.component"
import {ResponsiveTableLayoutComponent} from "@platform/components/layouts/responsive-table-layout/responsive-table-layout.component"
import {TagsTableComponent} from "@platform/components/tags/tags-table/tags-table.component"
import {TableContentDirective} from "@platform/directives/content/table-content.directive"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsDefined} from "@cm/lib/utils/filter"
import {BasePageComponent} from "@pages/base/base-page.component"

@Component({
    selector: "cm-jobs-page",
    standalone: true,
    imports: [
        CheckboxesFilterComponent,
        EntityResponsiveSidebarComponent,
        ItemFiltersComponent,
        MatPaginatorModule,
        RouterOutlet,
        TagSearchFilterComponent,
        ResponsiveTableLayoutComponent,
        TagsTableComponent,
        JobsTableComponent,
        JobsGridComponent,
        PaginatorComponent,
        TableContentDirective,
    ],
    templateUrl: "./jobs-page.component.html",
    styleUrl: "./jobs-page.component.scss",
})
export class JobsPageComponent extends BasePageComponent implements OnInit {
    organizations = inject(OrganizationsService)

    sdk = inject(SdkService)

    userLabels: StateLabel<string>[] | null = null

    override ngOnInit() {
        super.ngOnInit()
        this.sdk.gql.materialsPageVisibleUsers().then(({users}) => {
            this.userLabels = users.filter(IsDefined).map((user) => ({
                label: user.name,
                state: user.id,
            }))
        })
    }

    public readonly Labels = Labels
}
