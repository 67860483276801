<ng-content></ng-content>

@if (proceduralMesh(); as proceduralMesh) {
    <cm-inspector-section>
        <ng-container cm-title> Setup </ng-container>
        <ng-container cm-content>
            <cm-value-slot
                [node]="proceduralMesh"
                [key]="'geometryGraph'"
                [label]="'Geometry'"
                [topLabel]="true"
                [selectionPossibilities]="geometryPossibilities"
            ></cm-value-slot>
            <cm-value-slot [node]="proceduralMesh" [key]="'parameters'" [label]="'Parameters'" [topLabel]="true"></cm-value-slot>
        </ng-container>
    </cm-inspector-section>
} @else {
    <cm-inspector-section>
        <ng-container cm-title> Subdivision </ng-container>
        <ng-container cm-content>
            <cm-value-slot [node]="node()" [key]="'subdivisionRenderIterations'" [label]="'Iterations'" [decimalPlaces]="0"></cm-value-slot>
        </ng-container>
    </cm-inspector-section>
}

<cm-inspector-section>
    <ng-container cm-title>
        Materials
        @if (proceduralMesh(); as proceduralMesh) {
            <i [matMenuTriggerFor]="materialMenu" class="far fa-cog cm-menu-settings">
                <mat-menu #materialMenu>
                    <div class="cm-overlay-settings">
                        <div class="cm-material-slot-buttons">
                            <cm-button (click)="addMaterialSlot()">
                                <ng-container cm-text>Add slot</ng-container>
                            </cm-button>
                            <cm-button (click)="removeEmptyMaterialSlots()">
                                <ng-container cm-text>Remove empty</ng-container>
                            </cm-button>
                        </div>
                    </div>
                </mat-menu>
            </i>
        }
    </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'materialAssignments'" [label]="'Parameters'" [topLabel]="true"></cm-value-slot
    ></ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title>
        Displacement
        <i [matMenuTriggerFor]="displacementMenu" class="far fa-cog cm-menu-settings">
            <mat-menu #displacementMenu>
                <div (click)="$event.stopPropagation()" class="cm-overlay-settings">
                    <cm-inspector-section>
                        <ng-container cm-title> Displacement Settings </ng-container>
                        <ng-container cm-content>
                            <cm-value-slot [node]="node()" [key]="'displacementUvChannel'" [label]="'UV Channel'" [decimalPlaces]="0"></cm-value-slot>
                            <cm-value-slot [node]="node()" [key]="'displacementMin'" [label]="'Min (cm)'"></cm-value-slot>
                            <cm-value-slot [node]="node()" [key]="'displacementMax'" [label]="'Max (cm)'"></cm-value-slot>
                        </ng-container>
                    </cm-inspector-section>
                    <cm-inspector-section>
                        <ng-container cm-title> Configurator Settings </ng-container>
                        <ng-container cm-content>
                            <cm-value-slot [node]="node()" [key]="'displacementNormalStrength'" [label]="'Strength'"></cm-value-slot>
                            <cm-value-slot [node]="node()" [key]="'displacementNormalSmoothness'" [label]="'Smoothness'"></cm-value-slot>
                            <cm-value-slot [node]="node()" [key]="'displacementNormalOriginalResolution'" [label]="'Original Resolution'"></cm-value-slot>
                        </ng-container>
                    </cm-inspector-section>
                </div>
            </mat-menu>
        </i>
    </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'displacementTexture'" [label]="'Displacement'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Visibility </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'visibleDirectly'" [label]="'Visible to Camera'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'visibleInReflections'" [label]="'Visible in Reflections'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'visibleInRefractions'" [label]="'Visible in Refractions'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title> Configurator Shadows </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'receiveRealtimeShadows'" [label]="'Receive Shadows'"></cm-value-slot>
        <cm-value-slot [node]="node()" [key]="'castRealtimeShadows'" [label]="'Cast Shadows'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>
