import {Component, computed, effect, inject, input, output, signal, viewChild} from "@angular/core"
import {MatTooltip} from "@angular/material/tooltip"
import {ArConfiguratorActionsComponent} from "@app/template-editor/components/ar-configurator-actions/ar-configurator-actions.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {ObjectId, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {TemplateAllVariationsComponent} from "@template-editor/components/template-all-variations/template-all-variations.component"
import {TemplateInspectorComponent} from "@template-editor/components/template-inspector/template-inspector.component"
import {TemplateSceneViewerOptionsComponent} from "@template-editor/components/template-scene-viewer-options/template-scene-viewer-options.component"
import {TemplateSelectVariationComponent} from "@template-editor/components/template-select-variation/template-select-variation.component"
import {
    TemplateTreeViewType,
    TemplateTreeViewTypeSelectorComponent,
} from "@template-editor/components/template-tree-view-type-selector/template-tree-view-type-selector.component"
import {TemplateTreeComponent} from "@template-editor/components/template-tree/template-tree.component"
import {
    TemplateViewType,
    TemplateViewTypeSelectorComponent,
} from "@template-editor/components/template-view-type-selector/template-view-type-selector.component"
import {ThreeTemplateSceneProviderComponent} from "@template-editor/components/three-template-scene-provider/three-template-scene-provider.component"
import {SceneCamera, ThreeTemplateSceneViewerComponent} from "@template-editor/components/three-template-scene-viewer/three-template-scene-viewer.component"
import {TemplateImageViewerComponent} from "../template-image-viewer/template-image-viewer.component"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {TemplateAddComponent} from "../template-add/template-add.component"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatMenuModule} from "@angular/material/menu"
import {asapScheduler} from "rxjs"
import {LocalPreviewRenderingService} from "@common/services/rendering/local-preview-rendering.service"

@Component({
    selector: "cm-template-edit",
    standalone: true,
    templateUrl: "./template-edit.component.html",
    styleUrl: "./template-edit.component.scss",
    providers: [SceneManagerService, TemplateNodeClipboardService, TemplateNodeDragService],
    imports: [
        TemplateViewTypeSelectorComponent,
        TemplateTreeViewTypeSelectorComponent,
        TemplateTreeComponent,
        TemplateAllVariationsComponent,
        TemplateSelectVariationComponent,
        ThreeTemplateSceneViewerComponent,
        TemplateSceneViewerOptionsComponent,
        ThreeTemplateSceneProviderComponent,
        TemplateInspectorComponent,
        MatTooltip,
        TemplateImageViewerComponent,
        ArConfiguratorActionsComponent,
        ButtonComponent,
        TemplateAddComponent,
        MatProgressBarModule,
        MatMenuModule,
    ],
})
export class TemplateEditComponent {
    title = input<string | undefined>(undefined)
    templateGraph = input.required<TemplateGraph>()
    defaultCustomerId = input.required<number>()
    templateId = input.required<string>()
    templateRevisionId = input.required<string>()

    requestSaveInLibrary = output<TemplateGraph>()
    requestSave = output<() => void>()
    requestOldEditor = output<void>()

    threeViewer = viewChild<ThreeTemplateSceneViewerComponent>("threeViewer")

    templateViewType = signal<TemplateViewType>("edit")
    templateTreeViewType = signal<TemplateTreeViewType>("tree")
    selectedCameraId = signal<ObjectId | undefined>(undefined)

    localSceneManagerService = inject(SceneManagerService)
    localPreviewRenderingService = inject(LocalPreviewRenderingService)

    selectedCameraNode = computed(() => {
        const selectedCameraId = this.selectedCameraId()

        if (selectedCameraId) {
            const cameraNode = this.localSceneManagerService.$cameras().find((x) => x.id === selectedCameraId)
            if (cameraNode) return cameraNode
        }
        return undefined
    })

    camera = computed<SceneCamera | undefined>(() => {
        const selectedCameraNode = this.selectedCameraNode()
        return selectedCameraNode ? {parameters: selectedCameraNode, transform: selectedCameraNode.transform} : undefined
    })

    previewImageData = computed(() => {
        return this.localPreviewRenderingService.$previewImageData()
    })

    pendingTasks = computed(() => {
        return this.localSceneManagerService.$pendingTasks() + this.localPreviewRenderingService.$pendingTasks()
    })

    templateGraphModified = output<boolean>()

    constructor() {
        effect(() => {
            this.templateGraphModified.emit(this.localSceneManagerService.$templateGraphModified())
        })

        effect(() => {
            const templateGraph = this.templateGraph()
            asapScheduler.schedule(() => this.localSceneManagerService.$templateGraph.set(templateGraph))
        })

        effect(() => {
            const defaultCustomerId = this.defaultCustomerId()
            asapScheduler.schedule(() => this.localSceneManagerService.$defaultCustomerId.set(defaultCustomerId))
        })

        effect(() => {
            const templateRevisionId = this.templateRevisionId()
            asapScheduler.schedule(() => this.localSceneManagerService.$templateRevisionId.set(templateRevisionId))
        })

        effect(() => {
            const nodes = this.localSceneManagerService.$scene()
            asapScheduler.schedule(() => {
                this.localPreviewRenderingService.$sceneNodes.set(nodes)
            })
        })

        effect(() => {
            const viewer = this.threeViewer()
            if (!viewer) return
            const {width, height, pixelRatio} = viewer.$renderDimensions()
            const resolutionScale = pixelRatio * 1
            asapScheduler.schedule(() => {
                this.localPreviewRenderingService.$size.set([width * resolutionScale, height * resolutionScale])
            })
        })

        effect(() => {
            const viewer = this.threeViewer()
            if (!viewer) return
            const viewCamera = viewer.$viewCamera()
            asapScheduler.schedule(() => {
                this.localPreviewRenderingService.$cameraOverride.set(viewCamera)
            })
        })
    }

    save() {
        this.requestSave.emit(() => {
            this.localSceneManagerService.synchronizeSerializedTemplateGraph()
        })
    }

    openInOldEditor() {
        this.requestOldEditor.emit()
    }
}
