// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import PackageJson from "../../package.json"

/**
 * Configuration for preview deployments (i.e. all deployments to non-prod environments).
 */
export const environment = {
    production: true,
    name: "qa",
    version: PackageJson.version,
    firebase: {
        apiKey: "AIzaSyB-Pje907dzyDn4P5kCrrUDEaABeqDrDds",
        authDomain: "cm-platform-qa-b6f44.firebaseapp.com",
        projectId: "cm-platform-qa",
        storageBucket: "cm-platform-qa.appspot.com",
        messagingSenderId: "1072563382583",
        appId: "1:1072563382583:web:92ea47e349e23f590b52b2",
    },
    firebaseConfig: {
        collectionEnabled: true,
    },

    rendering: {
        materialAssets: {
            samples: 4,
        },
        shaderBall: {
            samples: 4,
        },
        defaultQueueDomain: "colormass",
    },

    storage: {
        bucketsPrefix: "cm-platform-qa-",
        usePostToUpload: false,
    },

    style: {
        navigationBar: {
            background: "#072571",
            label: "qa",
        },
    },

    debug: {
        enableCallStack: false,
    },

    restApiEndpoint: "https://api-qa.colormass.com/v01/",
    gqlApiEndpoint: "https://gql-qa.colormass.com",
    localTilingEndpoint: "ws://localhost:5678/",
    webComponentConfiguratorUrl: "https://cm-configurator-wc-qa-b6f44.web.app/",
    useWebComponentConfigurator: false,
}
