<cm-routed-dialog [dialogSize]="DialogSize.Small">
    <ng-container cm-overlay-content>
        <cm-details-dialog-layout [loadError]="$loadError()" [isLoaded]="!!$item()">
            <ng-container cm-title>
                <cm-title-section
                    [$title]="title"
                    [$iconClass]="'far fa-tag'"
                    [$isLoaded]="$item() !== undefined"
                    [$canEdit]="$can().update.tag($item(), 'name')"
                    (onChanged)="updateItem({name: $event})"
                    (onClosed)="closeModal()"
                ></cm-title-section>
            </ng-container>
            <ng-container cm-main>
                <cm-tag-thumbnail [tag]="$item()"></cm-tag-thumbnail>
                <cm-section>
                    <ng-container cm-icon><i class="far fa-paperclip"></i></ng-container>
                    <ng-container cm-title> Assignments</ng-container>
                    <ng-container cm-content>
                        <cm-tag-assignments [tagId]="$item()?.id"></cm-tag-assignments>
                    </ng-container>
                </cm-section>
            </ng-container>
            <ng-container cm-sidebar>
                <cm-input-container [cmIsLoading]="!$item()">
                    <ng-container cm-inline-label> Name:</ng-container>
                    <ng-container cm-input>
                        <cm-string-input [value]="$item()?.name" (valueChange)="updateItem({name: $event})"></cm-string-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container [cmIsLoading]="!$item()">
                    <ng-container cm-inline-label> Color:</ng-container>
                    <ng-container cm-input>
                        <cm-color-input [hexValue]="$item()?.color" (hexValueChange)="updateItem({color: $event})"></cm-color-input>
                    </ng-container>
                </cm-input-container>
                <cm-input-container [cmIsLoading]="!$item()">
                    <ng-container cm-top-label> Description:</ng-container>
                    <ng-container cm-input>
                        @if ($item()) {
                            <cm-native-input-text-area [value]="$item()?.description" [minRows]="4" (valueChange)="updateItem({description: $event})">
                            </cm-native-input-text-area>
                        }
                    </ng-container>
                </cm-input-container>
                <cm-organization-select
                    [label]="'Customer'"
                    [updateOrganization]="updateItem.bind(this)"
                    [$organizationId]="$item()?.organization?.id ?? null"
                    [$showIfViewedByOwner]="false"
                ></cm-organization-select>
                <mat-form-field [cmIsLoading]="!$item()">
                    <mat-select
                        [ngModel]="$item()?.type"
                        (valueChange)="updateItem({type: $event})"
                        placeholder="Type"
                        name="mTagType"
                        [disabled]="!$can().update.tag()"
                    >
                        @for (tagType of Labels.TagTypeOptions; track tagType) {
                            <mat-option [value]="tagType.state">{{ tagType.label }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <cm-input-container [cmIsLoading]="!$item()">
                    <ng-container cm-inline-label> Created by:</ng-container>
                    <ng-container cm-input>
                        <cm-string-input [value]="$item()?.createdBy?.name" [disabled]="true"></cm-string-input>
                    </ng-container>
                </cm-input-container>
            </ng-container>
        </cm-details-dialog-layout>
    </ng-container>
</cm-routed-dialog>
