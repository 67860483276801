@if (showSection) {
    <cm-section>
        <ng-container cm-icon><i class="far fa-cube"></i></ng-container>
        <ng-container cm-title>{{ $title() ?? "Templates" }}</ng-container>
        <ng-container cm-content>
            <div class="cm-templates-section">
                @if (showPlaceholders) {
                    <div class="cm-placeholder-container">
                        <cm-placeholder [dark]="true"></cm-placeholder>
                        <cm-placeholder [dark]="true"></cm-placeholder>
                        <cm-placeholder [dark]="true"></cm-placeholder>
                        <cm-placeholder [dark]="true"></cm-placeholder>
                    </div>
                } @else {
                    @for (template of templates; track template.id) {
                        <cm-entity-card
                            [$entity]="template"
                            [entityLink]="['/templates', template.id]"
                            [style.pointer-events]="$can().open.template(template) ? 'auto' : 'none'"
                        >
                        </cm-entity-card>
                    }
                }
            </div>
        </ng-container>
    </cm-section>
}
