import {Component, inject, Input, input, computed} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule, MatOptionSelectionChange} from "@angular/material/core"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {IsLoadingDirective} from "@app/common/directives"
import {UtilsService} from "@legacy/helpers/utils"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {AuthService} from "@common/services/auth/auth.service"
import {BasicOrganizationInfoFragment} from "@api"
import {sortOrganizationsStateLabelListAlphabetically} from "@common/helpers/utils/organization"
import {PermissionsService} from "@common/services/permissions/permissions.service"

type OrganizationInfo = Pick<BasicOrganizationInfoFragment, "id" | "name">

@Component({
    selector: "cm-organization-select",
    standalone: true,
    imports: [MatInputModule, MatSelectModule, MatFormFieldModule, MatOptionModule, FormsModule, IsLoadingDirective, MatButtonModule],
    templateUrl: "./organization-select.component.html",
    styleUrl: "./organization-select.component.scss",
})
export class OrganizationSelectComponent {
    $organizationId = input<string | null>(null)
    $showIfViewedByOwner = input<boolean>(true)
    $showNotifications = input<boolean>(false)
    $showLabel = input<boolean>(true)
    $usedInAddEntityContext = input<boolean>(false)
    $disabled = input<boolean>(false)
    @Input({required: true}) updateOrganization!: (data: {organizationId: string}) => Promise<unknown>
    @Input() label: string = "Organization"

    auth = inject(AuthService)
    permission = inject(PermissionsService)
    notifications = inject(NotificationsService)
    organizations = inject(OrganizationsService)
    utils = inject(UtilsService)
    $can = this.permission.$to

    $selected = computed<OrganizationInfo | null | undefined>(() => {
        const organizationId = this.$organizationId()
        if (!organizationId) {
            return null
        }
        const allOrganizations = this.organizations.$all()
        if (!allOrganizations) {
            return undefined
        }
        const organization = allOrganizations.find((organization) => organization.id === organizationId)
        if (organization) {
            return {
                id: organization.id,
                name: organization.name ?? "",
            }
        } else {
            return undefined
        }
    })

    $show = computed<boolean>(() => {
        if (this.$can().read.organization() || this.$showIfViewedByOwner()) return true
        const organizationId = this.$organizationId()
        return organizationId ? !this.organizations.$own()?.some((organization) => organization.id === organizationId) : false
    })

    $options = computed<OrganizationInfo[]>(() => {
        const selected = this.$selected()
        if (this.$disabled()) return selected ? [selected] : []
        const options = sortOrganizationsStateLabelListAlphabetically(this.organizations.$editOptions()).map((organization) => ({
            id: organization.state,
            name: organization.label ?? "",
        }))
        if (this.$can().read.organization()) return options
        if (!selected) return []
        // FIXME: this is implicitly a permission check - if the entity is already owned by one of the
        // organizations the user is a member of, the user is allowed to change the organization to another organization of which he is
        // a member.
        // Relegate the permission check to the parent component and the can edit flag to this one via input
        if (options.some((option) => option.id === selected.id)) return options
        return [selected]
    })

    async toggleOrganization(toggleEvent: MatOptionSelectionChange<OrganizationInfo>) {
        if (toggleEvent.isUserInput) {
            const organizationId = toggleEvent.source.value.id
            if (this.$showNotifications()) {
                await this.notifications.withUserFeedback(
                    async () => {
                        await this.updateOrganization({organizationId})
                    },
                    {
                        success: "Organization updated",
                        error: "Failed to update organization",
                    },
                )
            } else {
                await this.updateOrganization({organizationId})
            }
        }
    }

    compareAssignments(option?: OrganizationInfo, selection?: OrganizationInfo): boolean {
        return !!option?.id && option?.id === selection?.id
    }
}
