import {Component, Input} from "@angular/core"

@Component({
    selector: "cm-line",
    standalone: true,
    imports: [],
    templateUrl: "./line.component.html",
    styleUrl: "./line.component.scss",
})
export class LineComponent {
    @Input() color: string = "#f1f1f1"
}
