import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {IDataObjectNew} from "@src/templates/interfaces/data-object"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {Subscription, from, map} from "rxjs"

const TD = TypeDescriptors

const fetchDataObjectNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    dataObjectId: TD.inlet(TD.Number),
    dataObject: TD.outlet(TD.DataObjectNew),
}

export class FetchDataObjectNew implements NodeClassImpl<typeof fetchDataObjectNewDescriptor, typeof FetchDataObjectNew> {
    static descriptor = fetchDataObjectNewDescriptor
    static uniqueName = "FetchDataObjectNew"
    sceneManager!: Inlet<ISceneManagerNew>
    dataObjectId!: Inlet<number>
    dataObject!: Outlet<IDataObjectNew>

    private pending: Subscription | null = null

    run() {
        this.dataObject.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.dataObjectId === NotReady) {
            return
        }

        this.pending = this.sceneManager.addTaskNew(
            `loadDataObject(${this.dataObjectId})`,
            from(this.sceneManager.loadDataObjectNew(this.dataObjectId)).pipe(
                map((dataObject) => {
                    this.pending = null
                    this.dataObject.emitIfChanged(dataObject)
                }),
            ),
            true,
        )
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
