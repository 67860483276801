// @ts-strict-ignore
import {ImageColorSpace} from "@api"
import {
    DataObject,
    getDataObjectFromImageResourceForFormatAndResolution,
    isResolvedResourceWithTransientDataObject,
    isShadowCatcherMaterial,
    ITransientDataObject,
    MaterialGraphNode,
    ResolvedResource,
    unwrapNodeOutput,
    UnwrappedMaterialGraphNode,
    WrappedMaterialGraphNode,
} from "@cm/lib/materials/material-node-graph"
import {computeRgbCurveLUT} from "@cm/lib/materials/utils"
import {IDataTextureManager} from "@editor/helpers/scene/three-proxies/material-manager"
import {NormalMapBlendNode} from "@editor/helpers/scene/three-proxies/normal-map-blend-node"
import {ShadowCatcherMaterial} from "@editor/helpers/scene/three-proxies/shadow-catcher-material"
import {ITextureManager, LoadTextureDescriptor, Vec3TransformNode} from "@editor/helpers/scene/three-proxies/utils"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {DerivativeSamplerNode, HeightDerivativeToNormalMapNode, UVDisplacementNode} from "@editor/helpers/scene/three-proxies/displacement-map-nodes"
import {IMaterialData} from "@cm/lib/templates/interfaces/material-data"
import {NoiseNode} from "@editor/helpers/scene/three-proxies/noise-node"
import {ApplyLUTNode, lutSize} from "@editor/helpers/scene/three-proxies/apply-lut-node"
import {hsv2rgb, HSVNode, rgb2hsv} from "@editor/helpers/scene/three-proxies/hsv-node"
import {colorBurn, colorDodge, overlay} from "@editor/helpers/scene/three-proxies/color-functions"
import {TextureResolution} from "@cm/lib/templates/nodes/scene-properties"
import {IDataObject} from "@cm/lib/templates/interfaces/data-object"
import {createImageTextureSetNodes, createSetImageTextureNode, ImageTextureSetNodes} from "@cm/lib/materials/material-converter-utils"
import {MediaType} from "@cm/lib/api-gql/data-object"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {threeHsvToRgbNode, threeRgbToHsvNode} from "@cm/lib/materials/three-utils"

const DEBUG = false

function hookOnBeforeCompile(material: THREE.Material) {
    const fn = material.onBeforeCompile.bind(material)
    material.onBeforeCompile = (...args) => {
        return fn(...args)
    }
}

function constColorNode(...value: [number, number, number]) {
    return THREENodes.color(new THREE.Color(...value))
}

function arrayToColorNode(value: [number, number, number]) {
    return value ? constColorNode(...value) : null
}

function arrayToColorOrZeroAsNull(value: [number, number, number]) {
    if (!value) return null
    else if (value[0] == 0 && value[1] == 0 && value[2] == 0) return null
    else return constColorNode(...value)
}

export function constFloatNode(value: number) {
    return THREENodes.float(value)
}

function constVectorNode(...value: [number, number] | [number, number, number]) {
    if (value.length === 2) {
        return THREENodes.vec2(new THREE.Vector2(...value))
    } else if (value.length === 3) {
        return THREENodes.vec3(new THREE.Vector3(...value))
    } else throw new Error("invalid vector length")
}

function constFloatOrZeroAsNullNode(value: any) {
    if (value > 0) {
        return constFloatNode(value)
    } else {
        return null
    }
}

function createFresnelNode(ior: number) {
    const n1 = 1.0
    const r0_sqrt = (n1 - ior) / (n1 + ior)
    const r0 = r0_sqrt * r0_sqrt
    const NoV = new THREENodes.SplitNode(THREENodes.normalView, "z")
    const oneMinusNoV = THREENodes.sub(constFloatNode(1.0), NoV)
    const oneMinusNoV_2 = THREENodes.mul(oneMinusNoV, oneMinusNoV)
    const oneMinusNoV_3 = THREENodes.mul(oneMinusNoV_2, oneMinusNoV)
    const oneMinusNoV_5 = THREENodes.mul(oneMinusNoV_2, oneMinusNoV_3)
    const tmp1 = THREENodes.mul(constFloatNode(1 - r0), oneMinusNoV_5)
    return THREENodes.add(constFloatNode(r0), tmp1)
}

function scaleNodeBy(node: THREENodes.Node, scale: number): THREENodes.Node {
    return THREENodes.mul(node, constFloatNode(scale))
}

function blendDisplacementWithNormalMap(normalMap: THREENodes.Node, uv: THREENodes.Node, tex: THREE.Texture, scale: number): THREENodes.Node {
    //TODO: this doesn't account for primary and secondary UVs having different tangent spaces
    const deriv = new DerivativeSamplerNode(THREENodes.texture(tex, uv), uv)
    return new NormalMapBlendNode(new HeightDerivativeToNormalMapNode(scaleNodeBy(deriv, scale), uv), normalMap)
}

export type MaterialConverterOptions = {
    textureResolution?: TextureResolution
    textureFiltering?: boolean
    progressiveLoading?: boolean
    shadowCatcherFalloff?: {sizeX: number; sizeZ: number; smoothness: number; opacity: number}
}

export type MaterialConverterArgs = {
    materialData: IMaterialData
    meshDisplacementDataObject?: IDataObject
    meshDisplacementImageResource?: ResolvedResource
    meshDisplacementUVChannel?: number
    meshDisplacementScale?: number
    textureManager: ITextureManager
    dataTextureManager: IDataTextureManager
    options: MaterialConverterOptions
    materialModifier?: (material: THREE.Material) => void
}

export class MaterialConverter {
    private materialData: IMaterialData
    private textureManager: ITextureManager
    private dataTextureManager: IDataTextureManager
    private options: MaterialConverterOptions
    private material: THREENodes.MeshPhysicalNodeMaterial = null
    private nodeIdMap = new Map<MaterialGraphNode, number>()
    private nodeCache = new Map<string, THREENodes.Node>()
    private texNodeCache = new Map<MaterialGraphNode, THREENodes.TextureNode>()
    private imageTextureSetNodesCache = new Map<MaterialGraphNode, ImageTextureSetNodes>()
    private setImageTextureNodeCache = new Map<MaterialGraphNode, WrappedMaterialGraphNode>()

    private numPendingTextures = 0
    private meshDisplacementTexture: THREE.Texture
    private meshDisplacementUVChannel = 1
    private meshDisplacementScale = 1
    private displacementTextureInfo?: ResolvedResource
    private materialModifier?: (material: THREE.Material) => void

    private constructor(
        args: MaterialConverterArgs,
        private onFinishedLoading: (material: THREE.Material) => void,
    ) {
        this.materialData = args.materialData
        this.textureManager = args.textureManager
        this.dataTextureManager = args.dataTextureManager
        this.options = args.options
        this.meshDisplacementUVChannel = args.meshDisplacementUVChannel
        this.meshDisplacementScale = args.meshDisplacementScale
        this.materialModifier = args.materialModifier
        //TODO: get displacementTextureInfo by traversing graph, inspecting displacement related nodes, tracing back to image texture node
    }

    private error(msg: string, ...extra: any[]) {
        console.error(msg, ...extra)
    }

    private warn(msg: string, ...extra: any[]) {
        console.warn(msg, ...extra)
    }

    static evaluateNodeGraph(args: MaterialConverterArgs, onFinishedLoading: (material: THREE.Material) => void): void {
        const rootNode = args.materialData.materialGraph?.rootNode

        if (!rootNode) {
            onFinishedLoading(undefined)
        } else if (isShadowCatcherMaterial(args.materialData.materialGraph)) {
            const shadowOpts = args.options.shadowCatcherFalloff
            const material = new ShadowCatcherMaterial({
                opacity: shadowOpts?.opacity,
                bias: shadowOpts ? 0.0 : 0.2,
                falloffX: shadowOpts?.sizeX,
                falloffZ: shadowOpts?.sizeZ,
                smoothness: shadowOpts?.smoothness,
            })
            if (args.materialModifier) args.materialModifier(material)
            onFinishedLoading(material)
        } else {
            const converter = new MaterialConverter(args, onFinishedLoading)
            ++converter.numPendingTextures // suspend completion callback

            if (args.meshDisplacementDataObject) {
                const desc = converter.getTextureDescriptorForImageResource(args.meshDisplacementImageResource)
                const textureNode = converter.getTextureNode(desc, (texture) => {
                    texture.colorSpace = desc.colorSpace ?? THREE.LinearSRGBColorSpace
                    texture.format = THREE.LuminanceFormat
                    texture.minFilter = converter.options.textureFiltering ? THREE.LinearMipMapLinearFilter : THREE.LinearFilter
                    texture.magFilter = THREE.LinearFilter
                    texture.wrapS = THREE.RepeatWrapping
                    texture.wrapT = THREE.RepeatWrapping
                    texture.anisotropy = 1

                    converter.meshDisplacementTexture = texture
                })

                converter.meshDisplacementTexture = textureNode.value as unknown as THREE.Texture
            }

            converter.evalNode(rootNode)

            --converter.numPendingTextures
            converter.checkFinishedLoading()
        }
    }

    private createMaterialInstance() {
        const material = new THREENodes.MeshPhysicalNodeMaterial({})
        material.name = `nodeMaterial`
        material.userData.materialData = this.materialData

        const originalOnBeforeCompile = material.onBeforeCompile
        material.onBeforeCompile = (shader, renderer) => {
            originalOnBeforeCompile(shader, renderer)
            shader.vertexUv1s = true
            shader.vertexUv2s = true
            shader.vertexUv3s = true
        }

        return material
    }

    private checkFinishedLoading() {
        if (this.numPendingTextures === 0) {
            const cb = this.onFinishedLoading
            if (cb) {
                if (!this.material) {
                    console.warn("No valid BSDF node was found, creating invisible material!")
                    this.material = this.createMaterialInstance()
                    this.material.opacityNode = constFloatNode(0) // can't use visible=false, because this will prevent object outlines from rendering
                }
                cb(this.material)
            } else {
                throw new Error()
            }
            this.onFinishedLoading = undefined
        }
    }

    private getThreeJSColorSpaceForDataObject(dataObject: DataObject | ITransientDataObject): THREE.ColorSpace | undefined {
        if (dataObject.imageColorSpace === ImageColorSpace.Linear) {
            return THREE.LinearSRGBColorSpace
        } else if (dataObject.imageColorSpace === ImageColorSpace.Srgb) {
            return THREE.SRGBColorSpace
        } else {
            this.error(`DataObject ${(dataObject as any).legacyId ?? "(transient)"} has no colorspace information!`)
            return undefined
        }
    }

    private selectTextureFormatAndResolutionFromOptionsAndMediaType(
        options: MaterialConverterOptions,
        mediaType: MediaType,
    ): {primary: {format: "png" | "jpg"; resolution: TextureResolution}; lowRes?: {format: "jpg"; resolution: TextureResolution}} {
        const DEFAULT_TEXTURE_RESOLUTION: TextureResolution = "2000px"

        if (mediaType === "image/png") {
            return {
                primary: {
                    format: "png",
                    resolution: "original",
                },
            }
        }

        // isMobileDevice check as temporary (?) workaround for out-of-memory issues on mobile devices
        return {
            primary: {
                format: "jpg",
                resolution: isMobileDevice ? "500px" : options.textureResolution ?? DEFAULT_TEXTURE_RESOLUTION,
            },
            lowRes: options.progressiveLoading ? {format: "jpg", resolution: "500px"} : undefined,
        }
    }

    private getTextureDescriptorForImageResource(resolvedResource: ResolvedResource): LoadTextureDescriptor {
        if (isResolvedResourceWithTransientDataObject(resolvedResource)) {
            return {
                primaryURL: resolvedResource.transientDataObject.toObjectURL(),
                freeObjectURL: true,
                colorSpace: this.getThreeJSColorSpaceForDataObject(resolvedResource.transientDataObject),
            }
        } else {
            const {primary, lowRes} = this.selectTextureFormatAndResolutionFromOptionsAndMediaType(this.options, resolvedResource.mainDataObject.mediaType)
            const primaryDataObject = getDataObjectFromImageResourceForFormatAndResolution(resolvedResource, primary.format, primary.resolution)
            const lowResDataObject = lowRes
                ? getDataObjectFromImageResourceForFormatAndResolution(resolvedResource, lowRes.format, lowRes.resolution)
                : undefined

            if (!primaryDataObject)
                throw Error(`No primary data object found for image resource (data object legacyId: ${resolvedResource.mainDataObject.legacyId})`)

            return {
                lowResURL: lowResDataObject?.downloadUrl,
                primaryURL: primaryDataObject.downloadUrl,
                colorSpace: this.getThreeJSColorSpaceForDataObject(primaryDataObject),
            }
        }
    }

    private nodeIdCounter = 0

    private evalNode(node: MaterialGraphNode): THREENodes.Node {
        if (!node) return undefined
        const [unwrappedNode, outputName] = unwrapNodeOutput(node)
        if (!unwrappedNode) return undefined

        let id = this.nodeIdMap.get(node)
        if (id == undefined) {
            id = this.nodeIdCounter++
            this.nodeIdMap.set(node, id)
        }
        const cacheKey = outputName ? `${id}-${outputName}` : `${id}`
        let evaledNode = this.nodeCache.get(cacheKey)
        if (evaledNode) return evaledNode
        evaledNode = this._evalNode(unwrappedNode, outputName)
        this.nodeCache.set(cacheKey, evaledNode)
        return evaledNode
    }

    private getTextureNode(textureDesc: LoadTextureDescriptor, setupTexture: (texture: THREE.Texture) => void, uvNode?: THREENodes.Node) {
        const textureNode = THREENodes.texture(new THREE.Texture(), uvNode)

        ++this.numPendingTextures
        this.textureManager.loadTexture(
            textureDesc,
            (texture) => {
                setupTexture(texture)
                textureNode.value = texture

                --this.numPendingTextures
                this.checkFinishedLoading()
            },
            (highResTex) => {
                setupTexture(highResTex)
                textureNode.value = highResTex
            },
        )

        return textureNode
    }

    private _evalNode(node: UnwrappedMaterialGraphNode, outputName: string | undefined): THREENodes.Node {
        const evalInput = (name: string) => {
            const input = node.inputs && node.inputs[name]
            return input ? this.evalNode(input) : undefined
        }

        const getParam = (name: string) => {
            return node.parameters ? node.parameters[name] : undefined
        }

        const evalParam = (name: string, valueToNode?: (x: any) => THREENodes.Node) => {
            const value = node.parameters && node.parameters[name]
            if (value == null) return undefined
            else if (valueToNode) return valueToNode(value) ?? undefined
            else return undefined
        }

        const evalInputOrParam = (name: string, valueToNode?: (x: any) => THREENodes.Node) => {
            return evalInput(name) ?? evalParam(name, valueToNode)
        }

        const nodeType = node.nodeType

        if ("OutputMaterial" === nodeType) {
            return evalInput("Surface")
        } else if ("ShaderNodeMixShader" == nodeType) {
            return evalInput("Shader") ?? evalInput("Shader_001")
        } else if ("ShaderNodeAddShader" == nodeType) {
            return evalInput("Shader") ?? evalInput("Shader_001")
        } else if ("ShaderNodeBsdfDiffuse" === nodeType) {
            if (!this.material) this.material = this.createMaterialInstance()
            this.material.colorNode = evalInputOrParam("Color", arrayToColorNode) ?? evalInputOrParam("Base Color", arrayToColorNode) ?? this.material.colorNode
            //@ts-ignore
            if (this.material.colorNode.type === "Color") {
                // the idea here is that if the input is already set with a texture or someother type of node we don't want to override it
                // this can only happen if there are multiple BSDF nodes in the source node graph
                this.material.colorNode = evalParam("Color", arrayToColorNode) ?? evalParam("Base Color", arrayToColorNode) ?? this.material.colorNode
            }
            return null
        } else if ("BsdfPrincipled" === nodeType) {
            if (!this.material) this.material = this.createMaterialInstance()
            const material = this.material

            material.colorNode = evalInputOrParam("Base Color", arrayToColorNode) ?? evalInputOrParam("Color", arrayToColorNode) ?? material.colorNode
            const specular = evalInputOrParam("Specular", constFloatNode)
            if (specular) {
                //ior = (2.0 / (1.0 - (0.08 * specular.clamp(min=1e-9)).sqrt())) - 1.0
                material.iorNode = THREENodes.sub(
                    THREENodes.div(
                        constFloatNode(2.0),
                        THREENodes.sub(
                            constFloatNode(1.0),
                            THREENodes.sqrt(THREENodes.mul(constFloatNode(0.08), THREENodes.clamp(specular, constFloatNode(1e-9), constFloatNode(1.0)))),
                        ),
                    ),
                    constFloatNode(1.0),
                )
            }

            material.clearcoatNode = evalInputOrParam("Clearcoat", constFloatOrZeroAsNullNode) ?? null
            material.clearcoatRoughnessNode = material.clearcoatNode ? evalInputOrParam("Clearcoat Roughness", constFloatNode) : null
            material.clearcoatNormalNode = material.clearcoatNode ? evalInputOrParam("Clearcoat Normal", null) : null
            material.emissiveNode = evalInputOrParam("Emission", arrayToColorOrZeroAsNull) ?? material.emissiveNode
            material.sheenNode = evalInputOrParam("Sheen", constFloatOrZeroAsNullNode) ?? material.sheenNode
            material.roughnessNode = evalInputOrParam("Roughness", constFloatNode) ?? material.roughnessNode
            material.metalnessNode = evalInputOrParam("Metallic", constFloatNode) ?? material.metalnessNode
            material.normalNode = evalInputOrParam("Normal", null) ?? material.normalNode //TODO: apply displacement even if original material does not have a normal map
            const alphaNode = evalInputOrParam("Alpha", (value) => (value < 0.95 ? constFloatNode(value) : undefined))
            const transmissionNode = evalInputOrParam("Transmission", (value) => (value > 0.05 ? constFloatNode(value) : undefined))

            if (material.sheenNode) {
                // assume sheen tint = 1
                material.sheenNode = THREENodes.mul(material.colorNode, material.sheenNode)
            }

            if (alphaNode && transmissionNode) {
                this.warn("Material uses both alpha and transmission! Preferring alpha.")
                material.transparent = true
                material.opacityNode = alphaNode
            } else if (alphaNode) {
                if (DEBUG) console.log("Material is transparent (using alpha)")
                material.transparent = true
                material.opacityNode = alphaNode
            } else if (transmissionNode) {
                if (DEBUG) console.log("Material is transparent (using transmission)")
                material.transparent = true
                material.transmissionNode = transmissionNode
            }

            material.alphaTest = this.materialData.alphaMaskThreshold ?? 0

            if (this.materialModifier) this.materialModifier(material)

            return null
        } else if ("ShaderNodeTextureSet" === nodeType) {
            let imageTextureSetNodes = this.imageTextureSetNodesCache.get(node)
            if (!imageTextureSetNodes) {
                imageTextureSetNodes = createImageTextureSetNodes(node)
                this.imageTextureSetNodesCache.set(node, imageTextureSetNodes)
            }
            switch (outputName) {
                case "BaseColor":
                    return this.evalNode(imageTextureSetNodes.baseColor)
                case "Metallic":
                    return this.evalNode(imageTextureSetNodes.metallic)
                case "Specular":
                    return this.evalNode(imageTextureSetNodes.specular)
                case "Roughness":
                    return this.evalNode(imageTextureSetNodes.roughness)
                case "Anisotropic":
                    return this.evalNode(imageTextureSetNodes.anisotropic)
                case "AnisotropicRotation":
                    return this.evalNode(imageTextureSetNodes.anisotropicRotation)
                case "Alpha":
                    return this.evalNode(imageTextureSetNodes.alpha)
                case "Normal":
                    return this.evalNode(imageTextureSetNodes.normal)
                case "Displacement":
                    return this.evalNode(imageTextureSetNodes.displacement)
                case "Transmission":
                    return this.evalNode(imageTextureSetNodes.transmission)
                default:
                    throw new Error("ShaderNodeTextureSet: Unknown output name")
            }
        } else if ("ShaderNodeSetTexture" === nodeType) {
            let setImageTextureNode = this.setImageTextureNodeCache.get(node)
            if (!setImageTextureNode) {
                setImageTextureNode = createSetImageTextureNode(node)
                this.setImageTextureNodeCache.set(node, setImageTextureNode)
            }
            return this.evalNode(setImageTextureNode)
        } else if ("UVMap" === nodeType) {
            const index = getParam("internal.uv_map_index") ?? 0
            const uv = THREENodes.uv(index)

            if (this.displacementTextureInfo) {
                const displacementTexDesc = this.getTextureDescriptorForImageResource(this.displacementTextureInfo)
                const textureNode = this.getTextureNode(displacementTexDesc, (texture) => {
                    texture.colorSpace = THREE.LinearSRGBColorSpace
                    texture.minFilter = this.options.textureFiltering ? THREE.LinearMipMapLinearFilter : THREE.LinearFilter
                    texture.magFilter = THREE.LinearFilter
                    texture.wrapS = THREE.RepeatWrapping
                    texture.wrapT = THREE.RepeatWrapping
                    texture.anisotropy = 1
                })

                const depthScale = 0.5
                return new UVDisplacementNode(
                    textureNode,
                    uv,
                    this.displacementTextureInfo.metadata?.widthCm,
                    this.displacementTextureInfo.metadata?.heightCm,
                    depthScale,
                )
            }

            return uv
        } else if ("ShaderNodeTangent" === nodeType) {
            return THREENodes.attribute("tangent", "vec3")
            //TODO: use THREE.BufferGeometryUtils.computeTangents when loading mesh?
        } else if ("ShaderNodeTexCoord" === nodeType) {
            this.warn("WARNING: ShaderNodeTexCoord has multiple outputs, assuming UV output is used.")
            return THREENodes.uv(0)
        } else if ("Mapping" === nodeType) {
            const scale = getParam("Scale") ?? getParam("internal.scale") ?? [1, 1, 1]
            const location = getParam("Location") ?? getParam("internal.translation") ?? [0, 0, 0]
            const rotation = getParam("Rotation") ?? getParam("internal.rotation") ?? [0, 0, 0]
            const mode = getParam("internal.vector_type") ?? "POINT"
            const locationX = location[0] ?? 0
            const locationY = location[1] ?? 0
            const locationZ = location[2] ?? 0
            const rotationX = rotation[0] ?? 0
            const rotationY = rotation[1] ?? 0
            const rotationZ = rotation[2] ?? 0
            const scaleX = scale[0] ?? 1
            const scaleY = scale[1] ?? 1
            const scaleZ = scale[2] ?? 1

            let inputVector = evalInput("Vector")
            if (!inputVector) {
                this.warn("Input to Mapping node not connected, defaulting to UV!")
                inputVector = THREENodes.uv(0)
            }

            const matrixNode = THREENodes.uniform(new THREE.Matrix4().identity())
            const matrix = matrixNode.value as unknown as THREE.Matrix4
            let outputVector: THREENodes.Node = new Vec3TransformNode(inputVector, matrixNode)

            if (mode === "TEXTURE") {
                // inverse of POINT
                matrix.identity()
                matrix.multiply(new THREE.Matrix4().makeScale(1 / scaleX, 1 / scaleY, 1 / scaleZ))
                matrix.multiply(new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(-rotationX, -rotationY, -rotationZ, "ZYX")))
                matrix.multiply(new THREE.Matrix4().makeTranslation(-locationX, -locationY, -locationZ))
            } else if (mode === "POINT") {
                // Operations on vector: Scale, then rotate, then translate
                // effective expression: ((I * Translation) * Rotation) * Scale) <- (vector)
                matrix.identity()
                matrix.multiply(new THREE.Matrix4().makeTranslation(locationX, locationY, locationZ))
                matrix.multiply(new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(rotationX, rotationY, rotationZ, "XYZ")))
                matrix.multiply(new THREE.Matrix4().makeScale(scaleX, scaleY, scaleZ))
            } else if (mode === "VECTOR") {
                // same as POINT, but with no translation
                matrix.identity()
                matrix.multiply(new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(rotationX, rotationY, rotationZ, "XYZ")))
                matrix.multiply(new THREE.Matrix4().makeScale(scaleX, scaleY, scaleZ))
            } else if (mode === "NORMAL") {
                // divide by scale, then rotate, then normalize? (see Cycles implementation... not sure why it is supposed to work this way.)

                matrix.identity()
                matrix.multiply(new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(rotationX, rotationY, rotationZ, "XYZ")))
                matrix.multiply(new THREE.Matrix4().makeScale(1 / scaleX, 1 / scaleY, 1 / scaleZ))

                outputVector = THREENodes.normalize(outputVector)
            } else {
                this.error(`Unhandled mapping type: ${mode}`)
            }

            return THREENodes.vec2(outputVector)
        } else if ("TexImage" === nodeType) {
            let textureNode = this.texNodeCache.get(node)

            if (!textureNode) {
                let textureDesc: LoadTextureDescriptor
                if (node.resolvedResources) {
                    if (node.resolvedResources.length !== 1) {
                        this.error("ERROR: TexImage node has multiple image resources!")
                        return constFloatNode(0.5)
                    }
                    textureDesc = this.getTextureDescriptorForImageResource(node.resolvedResources[0])
                    if (DEBUG) console.log("Texture descriptor:", textureDesc)
                }

                if (!textureDesc) {
                    this.error(
                        `ERROR: no valid descriptor for texture! Likely cause: thumbnail cloud function failed. Resolved resources: ${node.resolvedResources}`,
                    )
                    return constFloatNode(0.5)
                }

                const colorSpace = getParam("internal.image.colorspace_settings.name") ?? "Linear"
                const interpolation = getParam("internal.interpolation") ?? "Closest"
                const extension = getParam("internal.extension") ?? "REPEAT"

                const setupTexture = (texture: THREE.Texture) => {
                    // WARNING: this is a shared object, so the assumption is that these values are valid for all uses!
                    texture.minFilter = this.options.textureFiltering ? THREE.LinearMipMapLinearFilter : THREE.NearestFilter
                    texture.magFilter = this.options.textureFiltering ? THREE.LinearFilter : THREE.NearestFilter
                    texture.wrapS = THREE.RepeatWrapping
                    texture.wrapT = THREE.RepeatWrapping
                    texture.anisotropy = this.options.textureFiltering ? 1 : 1

                    if (colorSpace === "Linear" || colorSpace === "Non-Color") {
                        texture.colorSpace = THREE.LinearSRGBColorSpace
                    } else if (colorSpace === "sRGB") {
                        texture.colorSpace = THREE.SRGBColorSpace
                    } else {
                        this.warn(`Warning: Unknown color space ${colorSpace} - assuming sRGB.`)
                        texture.colorSpace = THREE.SRGBColorSpace
                    }

                    if (extension === "REPEAT") {
                        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
                    } else if (extension === "EXTEND") {
                        texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping
                    } else if (extension === "CLIP") {
                        this.warn(`Warning: Unsupported extension mode: ${extension}`)
                        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
                    }

                    if (!this.options.textureFiltering) {
                        if (interpolation === "Closest") {
                            texture.minFilter = texture.magFilter = THREE.NearestFilter
                        } else if (interpolation === "Linear") {
                            texture.minFilter = texture.magFilter = THREE.LinearFilter
                        } else {
                            this.warn(`Warning: Unknown interpolation type ${interpolation} - assuming Linear.`)
                            texture.minFilter = texture.magFilter = THREE.LinearFilter
                        }
                    }

                    if (textureDesc?.colorSpace !== undefined) {
                        texture.colorSpace = textureDesc.colorSpace
                    }
                }

                const uvNode = (evalInput("Vector") as THREENodes.UVNode) ?? textureNode.uvNode
                textureNode = this.getTextureNode(textureDesc, setupTexture, uvNode)
                this.texNodeCache.set(node, textureNode)
            }

            if (outputName === "Alpha") {
                return new THREENodes.SplitNode(textureNode, "a")
            } else {
                return textureNode
            }
        } else if ("ShaderNodeNormalMap" === nodeType) {
            let normalMap = evalInputOrParam("Color", arrayToColorNode)
            const strength = evalInputOrParam("Strength", constFloatNode)

            if (normalMap) {
                //TODO: apply displacement even if original material does not have a normal map
                if (this.meshDisplacementTexture) {
                    normalMap = blendDisplacementWithNormalMap(
                        normalMap,
                        THREENodes.uv(this.meshDisplacementUVChannel),
                        this.meshDisplacementTexture,
                        this.meshDisplacementScale,
                    )
                }

                return THREENodes.normalMap(normalMap, strength)
            } else {
                this.error("ERROR: could not get an input node for normal map node")
                return undefined
            }
        } else if ("ShaderNodeRGBCurve" === nodeType) {
            //remark: the lut values can be < 0 or > 1 according to the OutOfBoundsMode
            const lut = computeRgbCurveLUT(lutSize, getParam)
            const lut_rgba = lut.map((x) => [...x, 1])

            const texture = this.dataTextureManager.createDataTexture(new Float32Array(lut_rgba.flat()), lut_rgba.length, 1, THREE.RGBAFormat, THREE.FloatType)
            texture.minFilter = THREE.NearestFilter
            texture.magFilter = THREE.NearestFilter
            texture.wrapS = THREE.ClampToEdgeWrapping
            texture.wrapT = THREE.ClampToEdgeWrapping
            texture.anisotropy = 1
            texture.colorSpace = THREE.LinearSRGBColorSpace
            texture.needsUpdate = true

            const rgbInput = evalInputOrParam("Color", arrayToColorNode)
            const fac = evalInputOrParam("Fac", constFloatNode)

            return new ApplyLUTNode(rgbInput, texture, fac)
        } else if ("ShaderNodeMath" === nodeType) {
            const inputA = evalInputOrParam("Value", constFloatNode)
            const inputB = evalInputOrParam("Value_001", constFloatNode)
            const operation: string = getParam("internal.operation")
            const useClamp: boolean = getParam("internal.use_clamp") ? true : false

            const getOperation = () => {
                if (operation === "ADD") {
                    return THREENodes.add(inputA, inputB)
                } else if (operation === "SUBTRACT") {
                    return THREENodes.sub(inputA, inputB)
                } else if (operation === "MULTIPLY") {
                    return THREENodes.mul(inputA, inputB)
                } else if (operation === "DIVIDE") {
                    return THREENodes.div(inputA, inputB)
                } else {
                    return undefined
                }
            }

            let out: THREENodes.Node = getOperation()
            if (out === undefined) {
                this.error(`ShaderNodeMath unknown operator: ${operation}`)
                if (inputA || inputB) {
                    this.warn("ShaderNodeMath is bypassing unimplemented mode.")
                    out = inputA ?? inputB
                }
            }
            if (useClamp) {
                out = THREENodes.clamp(out, constFloatNode(0), constFloatNode(1))
            }
            return out
        } else if ("ShaderNodeMixRGB" === nodeType) {
            const inputA = evalInputOrParam("Color1", arrayToColorNode)
            const inputB = evalInputOrParam("Color2", arrayToColorNode)
            const facNode = evalInputOrParam("Fac", constFloatNode)
            const operation: string = getParam("internal.blend_type")

            if (operation === "MIX") {
                return THREENodes.mix(inputA, inputB, facNode)
            } else if (operation === "LINEAR_LIGHT") {
                const mulBNode = THREENodes.mul(inputB, constFloatNode(2.0))
                const subBNode = THREENodes.sub(mulBNode, constFloatNode(1))
                const facBNode = THREENodes.mul(facNode, subBNode)
                return THREENodes.add(inputA, facBNode)
            } else if (operation === "SOFT_LIGHT") {
                const invANode = THREENodes.sub(constFloatNode(1), inputA) // (1-A)
                const invBNode = THREENodes.sub(constFloatNode(1), inputB) // (1-B)
                const mulInvAInvBNode = THREENodes.mul(invANode, invBNode) // (1-A)(1-B)
                const screenNode = THREENodes.sub(constFloatNode(1), mulInvAInvBNode) // 1-(1-A)(1-B)
                const mulInvABNode = THREENodes.mul(invANode, inputB) // (1-A)B
                const mulInvABANode = THREENodes.mul(mulInvABNode, inputA) // (1-A)BA
                const mulAScreenNode = THREENodes.mul(inputA, screenNode) // A*scr
                const additionNode = THREENodes.add(mulInvABANode, mulAScreenNode) // (1-A)BA+A*scr
                return THREENodes.mix(inputA, additionNode, facNode) // (1-f)A+f[(1-A)BA+A*scr]
            } else if (operation === "MULTIPLY") {
                const mulNode = THREENodes.mul(inputA, inputB)
                return THREENodes.mix(inputA, mulNode, facNode)
            } else if (operation === "ADD") {
                const mulNode = THREENodes.add(inputA, inputB)
                return THREENodes.mix(inputA, mulNode, facNode)
            } else if (operation === "SUBTRACT") {
                const mulNode = THREENodes.sub(inputA, inputB)
                return THREENodes.mix(inputA, mulNode, facNode)
            } else if (operation === "DIFFERENCE") {
                const subNode = THREENodes.sub(inputA, inputB) // A-B
                const diffNode = THREENodes.abs(subNode) // |A-B|
                return THREENodes.mix(inputA, diffNode, facNode) // (1-f)A+f|A-B|
            } else if (operation === "LIGHTEN") {
                const mulNode = THREENodes.mul(facNode, inputB)
                return THREENodes.max(inputA, mulNode)
            } else if (operation === "DARKEN") {
                const minNode = THREENodes.min(inputA, inputB) // min(A, B)
                return THREENodes.mix(inputA, minNode, facNode) // (1-f)A+f*min(A,B)
            } else if (operation === "DIVIDE") {
                const rNodeInpA = new THREENodes.SplitNode(inputA, "r")
                const gNodeInpA = new THREENodes.SplitNode(inputA, "g")
                const bNodeInpA = new THREENodes.SplitNode(inputA, "b")
                const rNodeInpB = new THREENodes.SplitNode(inputB, "r")
                const gNodeInpB = new THREENodes.SplitNode(inputB, "g")
                const bNodeInpB = new THREENodes.SplitNode(inputB, "b")
                const divNodeR = THREENodes.div(rNodeInpA, rNodeInpB)
                const divNodeG = THREENodes.div(gNodeInpA, gNodeInpB)
                const divNodeB = THREENodes.div(bNodeInpA, bNodeInpB)
                const divResultR = THREENodes.cond(THREENodes.greaterThan(rNodeInpB, constFloatNode(0)), divNodeR, rNodeInpA)
                const divResultG = THREENodes.cond(THREENodes.greaterThan(gNodeInpB, constFloatNode(0)), divNodeG, gNodeInpA)
                const divResultB = THREENodes.cond(THREENodes.greaterThan(bNodeInpB, constFloatNode(0)), divNodeB, bNodeInpA)
                const divNode = new THREENodes.JoinNode([divResultR, divResultG, divResultB])

                return THREENodes.mix(inputA, divNode, facNode)
            } else if (operation === "SCREEN") {
                const invANode = THREENodes.sub(constFloatNode(1), inputA) // (1-A)
                const invBNode = THREENodes.sub(constFloatNode(1), inputB) // (1-B)
                const invFacNode = THREENodes.sub(constFloatNode(1), facNode) // (1-f)
                const mulInvFacInvANode = THREENodes.mul(invFacNode, invANode) // (1-f)(1-A)
                const mulFacInvANode = THREENodes.mul(facNode, invANode) // f(1-A)
                const mulInvAInvBNode = THREENodes.mul(mulFacInvANode, invBNode) // f(1-A)(1-B)
                const addNode = THREENodes.add(mulInvFacInvANode, mulInvAInvBNode) // (1-f)(1-A)+f(1-A)(1-B)
                return THREENodes.sub(constFloatNode(1), addNode) // 1-[(1-f)(1-A)+f(1-A)(1-B)]
            } else if (operation === "COLOR") {
                const hsvNodeInpB = rgb2hsv(inputB)
                const sNodeHsvInpB = new THREENodes.SplitNode(hsvNodeInpB, "y")

                // If part
                const hsvNodeInpA = rgb2hsv(inputA)
                const hNodeHsvInpB = new THREENodes.SplitNode(hsvNodeInpB, "x")
                const vNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "z")
                const newHsvNode = new THREENodes.JoinNode([hNodeHsvInpB, sNodeHsvInpB, vNodeHsvInpA])
                const newRgbNode = hsv2rgb(newHsvNode)
                const ifNode = THREENodes.mix(inputA, newRgbNode, facNode)

                return THREENodes.cond(THREENodes.greaterThan(sNodeHsvInpB, constFloatNode(0)), ifNode, inputA)
            } else if (operation === "HUE") {
                const hsvNodeInpB = rgb2hsv(inputB)
                const sNodeHsvInpB = new THREENodes.SplitNode(hsvNodeInpB, "y")

                // If part
                const hsvNodeInpA = rgb2hsv(inputA)
                const hNodeHsvInpB = new THREENodes.SplitNode(hsvNodeInpB, "x")
                const sNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "y")
                const vNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "z")
                const newHsvNode = new THREENodes.JoinNode([hNodeHsvInpB, sNodeHsvInpA, vNodeHsvInpA])
                const newNodeRgb = hsv2rgb(newHsvNode)
                const ifNode = THREENodes.mix(inputA, newNodeRgb, facNode)

                return THREENodes.cond(THREENodes.greaterThan(sNodeHsvInpB, constFloatNode(0)), ifNode, inputA)
            } else if (operation === "SATURATION") {
                const hsvNodeInpA = rgb2hsv(inputA)
                const sNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "y")

                // If part
                const hsvNodeInpB = rgb2hsv(inputB)
                const hNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "x")
                const vNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "z")
                const sNodeHsvInpB = new THREENodes.SplitNode(hsvNodeInpB, "y")
                const mixNode = THREENodes.mix(sNodeHsvInpA, sNodeHsvInpB, facNode)
                const ifNodeHsv = new THREENodes.JoinNode([hNodeHsvInpA, mixNode, vNodeHsvInpA])
                const ifNodeRgb = hsv2rgb(ifNodeHsv)

                return THREENodes.cond(THREENodes.greaterThan(sNodeHsvInpA, constFloatNode(0)), ifNodeRgb, inputA)
            } else if (operation === "VALUE") {
                const hsvNodeInpA = rgb2hsv(inputA)
                const hsvNodeInpB = rgb2hsv(inputB)
                const hNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "x")
                const sNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "y")
                const vNodeHsvInpA = new THREENodes.SplitNode(hsvNodeInpA, "z")
                const vNodeHsvInpB = new THREENodes.SplitNode(hsvNodeInpB, "z")
                const mixNode = THREENodes.mix(vNodeHsvInpA, vNodeHsvInpB, facNode)
                const newHsvNode = new THREENodes.JoinNode([hNodeHsvInpA, sNodeHsvInpA, mixNode])

                return hsv2rgb(newHsvNode)
            } else if (operation === "COLOR_BURN") {
                return colorBurn(inputA, inputB, facNode)
            } else if (operation === "COLOR_DODGE") {
                return colorDodge(inputA, inputB, facNode)
            } else if (operation === "OVERLAY") {
                return overlay(inputA, inputB, facNode)
            } else {
                if (inputA || inputB) {
                    this.warn(`WARNING: ShaderNodeMixRGB unknown operation mode: ${operation}. Bypassing...`)
                    return inputA ?? inputB
                } else {
                    this.warn(`WARNING: ShaderNodeMixRGB unknown operation mode: ${operation}. No input to bypass!`)
                }
            }
            return undefined
        } else if ("ShaderNodeValue" === nodeType) {
            return evalInputOrParam("Value", constFloatNode)
        } else if ("ShaderNodeRGB" === nodeType) {
            return evalInputOrParam("Color", arrayToColorNode)
        } else if ("ShaderNodeSeparateRGB" === nodeType) {
            const input = evalInputOrParam("Image", arrayToColorNode)
            if (!input) {
                return undefined
            } else if (outputName === "R") {
                return new THREENodes.SplitNode(input, "r")
            } else if (outputName === "G") {
                return new THREENodes.SplitNode(input, "g")
            } else if (outputName === "B") {
                return new THREENodes.SplitNode(input, "b")
            } else {
                this.error(`ShaderNodeSeparateRGB unknown output: ${outputName}`)
                return undefined
            }
        } else if ("ShaderNodeSeparateHSV" === nodeType) {
            const input = evalInputOrParam("Image", arrayToColorNode)
            if (!input) {
                return undefined
            }
            const hsv = threeRgbToHsvNode(input)
            if (outputName === "H") {
                return new THREENodes.SplitNode(hsv, "r")
            } else if (outputName === "S") {
                return new THREENodes.SplitNode(hsv, "g")
            } else if (outputName === "V") {
                return new THREENodes.SplitNode(hsv, "b")
            } else {
                this.error(`ShaderNodeSeparateRGB unknown output: ${outputName}`)
                return undefined
            }
        } else if ("ShaderNodeSeparateXYZ" === nodeType) {
            const input = evalInputOrParam("Vector", arrayToColorNode)
            if (!input) {
                return undefined
            } else if (outputName === "X") {
                return new THREENodes.SplitNode(input, "x")
            } else if (outputName === "Y") {
                return new THREENodes.SplitNode(input, "y")
            } else if (outputName === "Z") {
                return new THREENodes.SplitNode(input, "z")
            } else {
                this.error(`ShaderNodeSeparateRGB unknown output: ${outputName}`)
                return undefined
            }
        } else if ("ShaderNodeCombineRGB" === nodeType) {
            const inputR = evalInputOrParam("R", constFloatNode)
            const inputG = evalInputOrParam("G", constFloatNode)
            const inputB = evalInputOrParam("B", constFloatNode)
            return new THREENodes.JoinNode([inputR, inputG, inputB])
        } else if ("ShaderNodeCombineHSV" === nodeType) {
            const inputH = evalInputOrParam("H", constFloatNode)
            const inputS = evalInputOrParam("S", constFloatNode)
            const inputV = evalInputOrParam("V", constFloatNode)
            return threeHsvToRgbNode(new THREENodes.JoinNode([inputH, inputS, inputV]))
        } else if ("ShaderNodeCombineXYZ" === nodeType) {
            const inputX = evalInputOrParam("X", constFloatNode)
            const inputY = evalInputOrParam("Y", constFloatNode)
            const inputZ = evalInputOrParam("Z", constFloatNode)
            return new THREENodes.JoinNode([inputX, inputY, inputZ])
        } else if ("ShaderNodeBump" === nodeType) {
            const textureNode = evalInput("Height")
            const strength = getParam("Strength") ?? 1

            if (textureNode) {
                if (!(textureNode instanceof THREENodes.TextureNode)) {
                    this.error("ShaderNodeBump cannot use non-texture node as input")
                    return undefined
                }

                //@ts-ignore
                return THREENodes.bumpMap(textureNode, constFloatNode(strength * 0.1))
            } else {
                this.error("ERROR: could not get an input node for bump map node")
                return undefined
            }
        } else if ("ShaderNodeTexNoise" === nodeType) {
            const noiseNode = new NoiseNode()
            noiseNode.uv = (evalInput("Vector") as THREENodes.UVNode) ?? noiseNode.uv
            this.warn("WARNING: ShaderNodeTexNoise not fully implemented!")
            return noiseNode
        } else if ("ShaderNodeHueSaturation" === nodeType) {
            const rgbInput = evalInputOrParam("Color", arrayToColorNode)
            const hue = evalInputOrParam("Hue", constFloatNode)
            const value = evalInputOrParam("Value", constFloatNode)
            const saturation = evalInputOrParam("Saturation", constFloatNode)
            const fac = evalInputOrParam("Fac", constFloatNode)

            return new HSVNode(rgbInput, hue, saturation, value, fac)
        } else if ("ShaderNodeValToRGB" === nodeType) {
            this.warn("WARNING: ShaderNodeValToRGB only implemeted as an approximation!")
            // this tries to linearly approximate a ramp node by just looking at the first two points that define the ramp
            const c00 = getParam("internal.color_ramp.elements[0].color[0]")
            const c01 = getParam("internal.color_ramp.elements[0].color[1]")
            const c02 = getParam("internal.color_ramp.elements[0].color[2]")
            const x0 = getParam("internal.color_ramp.elements[0].position")
            const c10 = getParam("internal.color_ramp.elements[1].color[0]")
            const c11 = getParam("internal.color_ramp.elements[1].color[1]")
            const c12 = getParam("internal.color_ramp.elements[1].color[2]")
            const x1 = getParam("internal.color_ramp.elements[1].position")
            const nodeX = evalInputOrParam("Fac", constFloatNode)

            const nodeX0 = constFloatNode(x0)
            const nodeVecC0 = constVectorNode(c00, c01, c02)
            const nodeVecM = constVectorNode((c10 - c00) / (x1 - x0), (c11 - c01) / (x1 - x0), (c12 - c02) / (x1 - x0))
            const nodeOffsetX = THREENodes.sub(nodeX, nodeX0)
            const nodeLinear = THREENodes.mul(nodeOffsetX, nodeVecM)
            return THREENodes.add(nodeLinear, nodeVecC0)
        } else if ("ShaderNodeInvert" === nodeType) {
            const color = evalInputOrParam("Color", arrayToColorNode)
            const fac = evalInputOrParam("Fac", constFloatNode)
            const inverted = THREENodes.sub(constFloatNode(1), color)
            return THREENodes.mix(color, inverted, fac)
        } else if ("ShaderNodeGamma" === nodeType) {
            const color = evalInputOrParam("Color", arrayToColorNode)
            const gamma = evalInputOrParam("Gamma", constFloatNode)
            return THREENodes.pow(color, gamma)
        } else if ("ShaderNodeBrightContrast" === nodeType) {
            const input = evalInputOrParam("Color", arrayToColorNode)
            const bright = evalInputOrParam("Bright", constFloatNode)
            const contrast = evalInputOrParam("Contrast", constFloatNode)
            const aNodeF = THREENodes.add(constFloatNode(1.0), contrast)
            const halfContrast = THREENodes.mul(constFloatNode(0.5), contrast)
            const bNodeF = THREENodes.sub(bright, halfContrast)
            const bNodeC = THREENodes.mul(constFloatNode(1), bNodeF)
            const mulNodeC = THREENodes.mul(aNodeF, input)
            return THREENodes.add(mulNodeC, bNodeC)
        } else if ("ShaderNodeRGBToBW" === nodeType) {
            const input = evalInputOrParam("Color", arrayToColorNode)
            return THREENodes.luminance(THREENodes.vec3(input), THREENodes.lumaCoeffs)
        } else if ("ShaderNodeAmbientOcclusion" === nodeType) {
            this.error("ERROR: ShaderNodeAmbientOcclusion not supported!")
            return constFloatNode(1) // 0 = occluded, 1 = unoccluded
        } else if ("ShaderNodeTexGradient" === nodeType) {
            this.error("ERROR: ShaderNodeTexGradient not implemented")
            return constColorNode(0.5, 0.5, 0.5)
        } else if ("ShaderNodeFresnel" === nodeType) {
            const ior: number = (node.parameters && node.parameters["IOR"]) ?? 1.5
            if (node.inputs && "IOR" in node.inputs) {
                this.error("ERROR: IOR input connected to node")
            }
            return createFresnelNode(ior)
        } else if ("ShaderNodeEmission" === nodeType) {
            if (!this.material) this.material = this.createMaterialInstance()
            else console.warn("Existing material will be overwritten")

            this.material.colorNode = arrayToColorNode([0, 0, 0])

            const emissionValue = evalInputOrParam("Color", arrayToColorNode) ?? evalParam("Color", arrayToColorNode) ?? this.material.emissiveNode

            if (emissionValue) {
                const emissionStrengthValue = evalInputOrParam("Strength", constFloatNode)

                if (emissionStrengthValue) {
                    this.material.emissiveNode = THREENodes.mul(emissionValue, emissionStrengthValue)
                } else {
                    this.material.emissiveNode = emissionValue
                }
            }

            return null
        } else if ("ShaderNodeScannedTransmission" === nodeType) {
            this.warn("WARNING: ShaderNodeScannedTransmission not implemented")
            return evalInput("BSDF")
        } else {
            this.error(`ERROR: node type ${nodeType} implementation missing!`)
            const bypass = evalInput("Color") ?? evalInput("Color1")
            if (bypass) {
                this.warn("WARNING: bypassing node!")
                return bypass
            } else {
                this.warn("WARNING: no bypass found!")
                return undefined
            }
        }
    }
}
