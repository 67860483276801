import {Component, inject, input, signal} from "@angular/core"
import {ContentTypeModel, TextureGroupTextureSetIdsFragment} from "@api"
import {ScanJobComponent} from "@app/textures/texture-group-details/scan-job/scan-job.component"
import {TextureSetComponent} from "@app/textures/texture-set/texture-set.component"
import {AssociatedJobsTableComponent} from "@app/platform/components/jobs/associated-jobs-table/associated-jobs-table.component"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {filter, map, merge} from "rxjs"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {toObservable} from "@angular/core/rxjs-interop"
import {SectionComponent} from "@common/components/item"
import {MatTooltipModule} from "@angular/material/tooltip"

@Component({
    imports: [ScanJobComponent, TextureSetComponent, AssociatedJobsTableComponent, SectionComponent, MatTooltipModule],
    standalone: true,
    selector: "cm-texture-sets",
    templateUrl: "./texture-sets.component.html",
    styleUrl: "./texture-sets.component.scss",
})
export class TextureSetsComponent {
    $textureGroupId = input<string | undefined>()
    $showAssociatedJobs = input(false)

    protected permission = inject(PermissionsService)
    $can = this.permission.$to

    protected ContentTypeModel = ContentTypeModel
    protected $textureGroup = signal<TextureGroupTextureSetIdsFragment | undefined>(undefined)

    constructor(
        private sdk: SdkService,
        private notifications: NotificationsService,
        private refreshService: RefreshService,
    ) {
        merge(
            toObservable(this.$textureGroupId),
            this.refreshService.itemSubject.pipe(
                filter(({id}) => id === this.$textureGroupId()),
                map(({id}) => id),
            ),
        ).subscribe((id) => this.loadTextureGroup(id))
    }

    private async loadTextureGroup(textureGroupId: string | undefined) {
        if (!textureGroupId) {
            this.$textureGroup.set(undefined)
        } else {
            await this.sdk.gql
                .queryTextureGroupTextureSetIds({id: textureGroupId})
                .then((result) => {
                    this.$textureGroup.set(result.textureGroup)
                })
                .catch((error) => {
                    this.notifications.showError(error)
                    this.$textureGroup.set(undefined)
                })
        }
    }

    protected async addTextureSet() {
        const textureGroupId = this.$textureGroupId()
        if (textureGroupId) {
            await this.notifications.withUserFeedback(
                async () => {
                    await this.sdk.gql.textureGroupDetailsCreateTextureSet({
                        input: {
                            textureGroupId,
                        },
                    })
                    this.refreshService.item({id: textureGroupId, __typename: ContentTypeModel.TextureGroup})
                },
                {
                    error: "Cannot create new texture set",
                },
            )
        }
    }
}
