import {EventEmitter} from "@angular/core"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {HalImage} from "@common/models/hal/hal-image"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {TypedImageData} from "@cm/lib/image-processing/image-processing-actions"
import {ImageImgProc} from "app/textures/texture-editor/operator-stack/image-op-system/image-imgproc"
import {ImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {ImageColorSpace, UploadServiceDataObjectFragment} from "generated/graphql"
import {firstValueFrom, switchMap} from "rxjs"
import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {isHalDescriptorCompatible} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {TexturesApiService} from "@app/textures/service/textures-api.service"
import {ImageProcessingService} from "@app/common/services/rendering/image-processing.service"
import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {ImageCacheWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-cache-webgl2"

const RESTORED_MASK_FORMAT = TextureEditorSettings.PreviewProcessingImageFormat

export function isDrawableImageRef(value: unknown): value is DrawableImageRef {
    return value instanceof DrawableImageRef
}

export class DrawableImageCache {
    readonly imageCreated = new EventEmitter<{ref: DrawableImageRef; isEmpty: boolean}>()
    readonly imageRemoved = new EventEmitter<DrawableImageRef>()

    constructor(
        private imageCacheWebGL2: ImageCacheWebGL2,
        private imageProcessingService: ImageProcessingService,
        private uploadGqlService: UploadGqlService,
        private texturesApi: TexturesApiService,
    ) {
        this.halBlitter = new HalPainterImageBlit(imageCacheWebGL2.halContext)
    }

    dispose(): void {
        const ids = this.webGlImageByRef.keys()
        for (;;) {
            const {value} = ids.next()
            if (!value) {
                break
            }
            this.removeDrawableImage(value)
        }
        this.halBlitter.dispose()
    }

    hasDrawableImage(ref: DrawableImageRef): boolean {
        return this.webGlImageByRef.has(ref) || this.hasDataObjectId(ref)
    }

    removeDrawableImage(ref: DrawableImageRef, disposeHalImage = true): void {
        const halImage = this.webGlImageByRef.get(ref)
        if (halImage) {
            if (disposeHalImage) {
                this.imageCacheWebGL2.releaseImage(halImage)
            }
            this.webGlImageByRef.delete(ref)
            this.imageRemoved.emit(ref)
        }
        this.resetDataObjectId(ref)
    }

    async cloneImage(ref: DrawableImageRef, targetRef: DrawableImageRef): Promise<void> {
        const halImage = this.webGlImageByRef.get(ref)
        if (!halImage) {
            throw Error("WebGlImage for drawable image does not exist")
        }
        if (this.hasDrawableImage(targetRef)) {
            this.removeDrawableImage(targetRef)
        }
        await this.createDrawableImageFromHalImage(targetRef, halImage)
        const dataObjectId = this.getDataObjectId(ref)
        if (dataObjectId !== undefined) {
            this.setDataObjectId(targetRef, dataObjectId)
        }
    }

    // creates a new webgl image from the given mask reference or downloads it from the data-object if available
    async createDrawableImageFromDataObjectOrDescriptor(ref: DrawableImageRef, imageDesc: HalImageDescriptor): Promise<HalPaintableImage> {
        if (this.webGlImageByRef.has(ref)) {
            throw Error("WebGlImage for drawable image already exists")
        }
        let halImage: HalPaintableImage
        if (this.hasDataObjectId(ref)) {
            halImage = await this.createDrawableImageFromDataObject(ref)
            // make sure the image has the correct format
            //if (!deepEqual(halImage.descriptor, imageDesc) || !deepEqual(halImage.options, options)) {  // commented out as it can happen that the device does not support some requested format and some fallback is used instead
            if (!isHalDescriptorCompatible(halImage.descriptor, imageDesc)) {
                throw Error("Downloaded drawable image has wrong format")
            }
        } else {
            halImage = await this.createDrawableImageFromDescriptor(ref, imageDesc)
        }
        return halImage
    }

    // creates a new webgl image for the given drawable image reference from the given hal image
    private async createDrawableImageFromHalImage(ref: DrawableImageRef, image: HalPaintableImage): Promise<HalPaintableImage> {
        if (this.webGlImageByRef.has(ref)) {
            throw Error("WebGlImage for drawable image already exists")
        }
        // const halImage = createHalPaintableImage(this.halContext)
        // await halImage.create(image.descriptor)
        const halImage = await this.imageCacheWebGL2.getImage(image.descriptor)
        this.webGlImageByRef.set(ref, halImage)
        this.markMaskReferenceDirty(ref)
        await this.halBlitter.paint(halImage, image)
        this.imageCreated.emit({ref, isEmpty: false})
        return halImage
    }

    // creates a new webgl image from the given mask reference
    async createDrawableImageFromDescriptor(ref: DrawableImageRef, imageDesc: HalImageDescriptor): Promise<HalPaintableImage> {
        if (this.webGlImageByRef.has(ref)) {
            throw Error("WebGlImage for drawable image already exists")
        }
        // const halImage = createHalPaintableImage(this.halContext)
        // await halImage.create(imageDesc)
        const halImage = await this.imageCacheWebGL2.getImage(imageDesc)
        this.webGlImageByRef.set(ref, halImage)
        await halImage.clear()
        this.markMaskReferenceDirty(ref)
        this.imageCreated.emit({ref, isEmpty: true})
        return halImage
    }

    // creates a new webgl image from the downloaded data-object by the given mask reference
    async createDrawableImageFromDataObject(ref: DrawableImageRef): Promise<HalPaintableImage> {
        if (this.webGlImageByRef.has(ref)) {
            throw Error("WebGlImage for drawable image already exists")
        }
        if (!this.hasDataObjectId(ref)) {
            throw Error("Mask reference has no data object")
        }
        return await this.downloadDrawableImage(ref)
    }

    async getHalPaintableImageByRef(ref: DrawableImageRef): Promise<HalPaintableImage> {
        return await this.downloadDrawableImage(ref)
    }

    async getWebGl2ImageByRef(ref: DrawableImageRef): Promise<ImageWebGL2> {
        const halImage = await this.getHalPaintableImageByRef(ref)
        return new ImageWebGL2("drawable", -1, undefined, halImage, {...halImage.descriptor, batchSize: {width: 1, height: 1}}, "DrawableImageCache")
    }

    async getImgProcImageByRef(ref: DrawableImageRef): Promise<ImageImgProc> {
        const dataObjectId = await this.uploadDrawableImage(ref)
        const dataObjectLegacyId = await this.texturesApi.getDataObjectLegacyId(dataObjectId)
        const dataObjectReference = JobNodes.dataObjectReference(dataObjectLegacyId)
        return Nodes.decode(Nodes.externalData(dataObjectReference, "encodedData"))
    }

    markMaskReferenceDirty(ref: DrawableImageRef): void {
        if (!this.webGlImageByRef.has(ref)) {
            throw Error("WebGlImage for drawable image not registered")
        }
        this.resetDataObjectId(ref) // mark for re-upload
    }

    isMaskReferenceDirty(ref: DrawableImageRef): boolean {
        return !this.hasDataObjectId(ref)
    }

    hasDataObjectId(ref: DrawableImageRef): boolean {
        return ref.maskReference.dataObjectId !== ""
    }

    getDataObjectId(ref: DrawableImageRef): string | undefined {
        return this.hasDataObjectId(ref) ? ref.maskReference.dataObjectId : undefined
    }

    setDataObjectId(ref: DrawableImageRef, dataObjectId: string): void {
        ref.maskReference.dataObjectId = dataObjectId
    }

    private resetDataObjectId(ref: DrawableImageRef): void {
        ref.maskReference.dataObjectId = ""
    }

    // creates a new webgl image from the downloaded data-object by the given id
    private async downloadDrawableImage(ref: DrawableImageRef): Promise<HalPaintableImage> {
        const dataObjectId = this.getDataObjectId(ref)
        if (!this.webGlImageByRef.has(ref) && dataObjectId) {
            if (this.pendingDownloadsByRef.has(ref)) {
                await this.pendingDownloadsByRef.get(ref)
            } else {
                const pendingDownload = this.downloadImage(dataObjectId)
                this.pendingDownloadsByRef.set(ref, pendingDownload)
                const halImage = await pendingDownload
                this.webGlImageByRef.set(ref, halImage)
                this.pendingDownloadsByRef.delete(ref)
                this.imageCreated.emit({ref, isEmpty: false})
            }
        }
        const halImage = this.webGlImageByRef.get(ref)
        if (!halImage) {
            throw Error("WebGlImage for drawable image could not be downloaded")
        }
        return halImage
    }

    // uploads (if needed) the webgl image to a new data-object and updates the id
    private async uploadDrawableImage(ref: DrawableImageRef): Promise<string> {
        if (!this.hasDataObjectId(ref)) {
            if (this.pendingUploadsByRef.has(ref)) {
                await this.pendingUploadsByRef.get(ref)
            } else {
                const halImage = this.webGlImageByRef.get(ref)
                if (!halImage) {
                    throw Error("WebGlImage for drawable image not registered")
                }
                const pendingUpload = this.uploadImage(ref.customerLegacyId, halImage)
                this.pendingUploadsByRef.set(ref, pendingUpload)
                const dataObject = await pendingUpload
                this.setDataObjectId(ref, dataObject.id)
                this.pendingUploadsByRef.delete(ref)
            }
        }
        const dataObjectId = this.getDataObjectId(ref)
        if (dataObjectId === undefined) {
            throw Error("Drawable image could not been uploaded to data object")
        }
        return dataObjectId
    }

    private async downloadImage(dataObjectId: string): Promise<HalPaintableImage> {
        return this.texturesApi.createHalImageFromDataObject(this.imageCacheWebGL2, dataObjectId, {
            downloadFormat: "exr",
            preferredOutputFormat: RESTORED_MASK_FORMAT,
            allowIncomplete: true,
        })
    }

    private async uploadImage(customerId: number, webGlImage: HalImage): Promise<UploadServiceDataObjectFragment> {
        const maskImageData = await this.imageDataFromHalImage(webGlImage)
        const convertedMaskAlpha = Nodes.convert(Nodes.input(maskImageData), "float32", "L", false)
        const encodedMaskAlpha = Nodes.encode(convertedMaskAlpha, "image/x-exr")
        return firstValueFrom(
            this.imageProcessingService.evalGraph(encodedMaskAlpha).pipe(
                switchMap((evaledEncodedData) => {
                    const dataFile = new File([evaledEncodedData.data], "mask.exr")
                    return this.uploadGqlService.createAndUploadDataObject(
                        dataFile,
                        {
                            organizationLegacyId: customerId,
                            mediaType: "image/x-exr",
                            imageColorSpace: evaledEncodedData.colorSpace === "linear" ? ImageColorSpace.Linear : ImageColorSpace.Srgb,
                            width: evaledEncodedData.width,
                            height: evaledEncodedData.height,
                        },
                        {
                            showUploadToolbar: false,
                            processUpload: false,
                        },
                    )
                }),
            ),
        )
    }

    private async imageDataFromHalImage(halImage: HalImage): Promise<TypedImageData> {
        if (halImage.descriptor.channelLayout !== "R") {
            throw Error("webGlImage layout must be L")
        }
        return {
            data: await halImage.readRawImageData("float32"),
            width: halImage.descriptor.width,
            height: halImage.descriptor.height,
            channelLayout: "L",
            dataType: "float32",
            colorSpace: halImage.descriptor.options?.useSRgbFormat ? "sRGB" : "linear",
            dpi: undefined,
        }
    }

    private webGlImageByRef = new Map<DrawableImageRef, HalPaintableImage>()
    private pendingUploadsByRef = new Map<DrawableImageRef, Promise<UploadServiceDataObjectFragment>>()
    private pendingDownloadsByRef = new Map<DrawableImageRef, Promise<HalPaintableImage>>()
    private halBlitter: HalPainterImageBlit
}
