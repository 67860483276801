import {jobTaskIcon, jobTaskNodeClass} from "@common/helpers/jobs/icons"
import {ExecutionPlanNodeType} from "@platform/components/jobs/execution-plan/graph/types"
import type {Selection} from "d3"
import type {MutGraphNode} from "d3-dag"

const NODE_SIZE = 64

export const drawGraph = (
    data: ExecutionPlanNodeType[],
    tooltips: {
        create: (node: SVGGElement, data: ExecutionPlanNodeType) => void
        update: (node: SVGGElement, data: ExecutionPlanNodeType) => void
        remove: (node: SVGGElement) => void
    },
    d3: typeof import("d3"),
    d3Dag: typeof import("d3-dag"),
) => {
    const stratify = d3Dag.graphStratify()
    const dag = stratify(data)

    const shape = d3Dag.tweakShape([NODE_SIZE, NODE_SIZE], d3Dag.shapeEllipse)
    const layout = d3Dag.sugiyama().nodeSize([NODE_SIZE, NODE_SIZE]).gap([NODE_SIZE, NODE_SIZE]).tweaks([shape])

    const {width, height} = layout(dag)

    const svg = d3.select<SVGSVGElement, MutGraphNode<ExecutionPlanNodeType, undefined>>("figure#flowchart svg#execution_plan")

    // note that we are swapping x and y to make the graph horizontal, so we also need to swap width and height
    const actualWidth = height + 20
    const padding = (Math.max(actualWidth, 900) - actualWidth) / 2
    svg.attr("viewBox", `${-10 - padding},-10,${actualWidth + 2 * padding},${width + 20}`)
        .attr("width", "100%")
        .attr("height", "400")

    // svg.transition().duration(750)

    // Nodes
    d3.select<SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>>("figure#flowchart svg#execution_plan g.nodes")
        .selectAll<SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>>("g.node")
        .data(dag.nodes(), (d) => d.data.id)
        .join(
            (enteredSelection) => {
                const addedNode = enteredSelection.append("g")

                addedNode
                    .classed("node", true)
                    .attr("height", NODE_SIZE)
                    .attr("width", NODE_SIZE)
                    .attr("data-tooltip-id", (d) => d.data.id)

                addedNode
                    .append("rect")
                    .classed("node-background", true)
                    .attr("x", (d) => actualWidth - d.y)
                    .attr("y", (d) => d.x)
                    .attr("rx", NODE_SIZE / 2)
                    .attr("ry", NODE_SIZE / 2)
                    .attr("transform", `translate(-${NODE_SIZE / 2}, -${NODE_SIZE / 2})`)
                    .attr("pathLength", 100)
                    .attr("height", NODE_SIZE)
                    .attr("width", NODE_SIZE)

                addedNode
                    .append("g")
                    .classed("node-text-box", true)
                    .attr("height", NODE_SIZE)
                    .attr("width", NODE_SIZE)

                    .append("text")
                    .classed("node-text", true)
                    .attr("x", (d) => actualWidth - d.y)
                    .attr("y", (d) => d.x)
                    .attr("text-anchor", "middle")
                    .attr("dominant-baseline", "central")
                    .style("font-family", "'Font Awesome 6 Pro'")
                    .style("font-weight", 900)
                    .style("font-size", function () {
                        return `${NODE_SIZE / 2}px`
                    })

                updateNode(addedNode)
                addedNode.each(function (graphNode) {
                    tooltips.create(this, graphNode.data)
                })

                return addedNode
            },
            (updatedSelection) => {
                updateNode(updatedSelection)
                updatedSelection.each(function (graphNode) {
                    tooltips.update(this, graphNode.data)
                })
                return updatedSelection
            },
            (exitedSelection) => {
                exitedSelection.each(function () {
                    tooltips.remove(this)
                })
                return exitedSelection.remove()
            },
        )

    // Links
    svg.select("g.links")
        .selectAll(".link")
        .data(dag.links())
        .join("path")
        .attr("class", "link")
        .attr(
            "d",
            d3
                .linkHorizontal<unknown, {x: number; y: number}>()
                .x((d) => actualWidth - d.y)
                .y((d) => d.x),
        )
}

const updateNode = (
    selection: Selection<SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>, SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>>,
) => {
    updateColor(selection)
    updateProgress(selection.select(".node-background"))
    updateText(selection.select(".node-text"))
    return selection
}

const updateText = (
    selection: Selection<SVGTextElement, MutGraphNode<ExecutionPlanNodeType, undefined>, SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>>,
) => {
    selection.text(function (d) {
        switch (d.data.type) {
            case "jobDefinition":
                return "\uf7d9"
            case "list":
                return "\uf0ae"
            case "struct":
                return "\uf7ea"
            case "input":
                return "\uf11c"
            case "value":
                return "\ue0f8"
            case "get":
                return "\uf0ab"
            case "externalJob":
                return "\ue479"
            case "progressGroup":
                return "\uf828"
            case "task": {
                return jobTaskIcon(d.data.dbState)
            }
        }
        return "\uf057"
    })

    return selection
}

const updateProgress = (
    selection: d3.Selection<SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>, SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>>,
) => {
    selection.style("stroke-dasharray", function (d) {
        if (d.data.type === "task" && d.data.progress) {
            return `${d.data.progress} ${100 - d.data.progress}`
        } else {
            return "10 90"
        }
    })
}

const updateColor = (
    selection: Selection<SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>, SVGGElement, MutGraphNode<ExecutionPlanNodeType, undefined>>,
) => {
    selection.attr("class", function (d) {
        if (d.data.error) {
            return "node node--error"
        }
        switch (d.data.type) {
            case "externalJob":
            case "task": {
                return `node node--${jobTaskNodeClass(d.data.dbState)}`
            }
            default: {
                if (d.data.ready) {
                    return "node node--success"
                } else {
                    return "node node--init"
                }
            }
        }
    })
}
