import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import paper from "paper"
import {BehaviorSubject, debounceTime, merge} from "rxjs"
import {Vector2} from "@cm/lib/math"

export class LabelToolboxItem extends CanvasBaseToolboxItemBase {
    readonly position$ = new BehaviorSubject(new Vector2())
    readonly text$ = new BehaviorSubject("<no text set>")
    readonly angle$ = new BehaviorSubject(0)
    readonly screenSpaceOffset$ = new BehaviorSubject(new Vector2())

    constructor(parent: CanvasBaseToolboxItemBase) {
        super(parent)
        this.label = new paper.PointText(new paper.Point(0, 0))
        this.label.fillColor = new paper.Color("white")
        this.label.justification = "center"
        this.label.fontSize = this.baseFontSize
        this.label.strokeColor = new paper.Color("black")
        this.label.strokeWidth = this.baseFontStrokeWidth / this.zoomLevel
        this.label.fontFamily = "Roboto"
        this.label.fontWeight = "Bold"
        this.label.fillColor = new paper.Color("white")
        this.updateLabel()

        merge(this.position$, this.text$, this.angle$)
            .pipe(debounceTime(10))
            .subscribe(() => this.updateLabel())
        merge(this.viewChange, this.screenSpaceOffset$).subscribe(() => this.updateZoomDependent())
    }

    private updateLabel(): void {
        this.label.content = this.text$.value
        this.label.rotation = this.angle$.value
        this.updateZoomDependent()
    }

    private updateZoomDependent() {
        this.label.fontSize = this.baseFontSize / this.zoomLevel
        this.label.strokeWidth = this.baseFontStrokeWidth / this.zoomLevel
        this.label.position = new paper.Point(this.position$.value.add(this.screenSpaceOffset$.value.div(this.zoomLevel)))
    }

    private label!: paper.PointText
    private baseFontStrokeWidth = 1
    readonly baseFontSize = 28
}
