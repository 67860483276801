import {DestroyRef, Inject, inject, Injectable, signal} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {DataObjectState, MembershipRole, OrganizationType, SdkServiceDataObjectGQL, SystemRole} from "@api"
import {maybeEnum} from "@common/helpers/utils/enum"
import {AuthorizationSilo} from "@common/models/auth/authorization-silo"
import {CM_COLORMASS_CLIENT} from "@common/modules/client-injector"
import {TokenService} from "@common/services/auth/token.service"
import {environment} from "@environment"
import {getSdk as getRawSdk} from "generated/raw"
import {getSdk} from "generated/sdk"
import {GraphQLClient} from "graphql-request"
import {filter, firstValueFrom, map} from "rxjs"

/**
 * Convenience service for accessing the GraphQL API.
 * Inject this service into your components and use the `gql` property to access the API.
 */
@Injectable({
    providedIn: "root",
})
export class SdkService {
    constructor(@Inject(CM_COLORMASS_CLIENT) private colormassClient: string) {
        void this.loadSiloFromLocalStorage()
    }

    public raw = getRawSdk(new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "all"}), (action) => action(this.requestHeaders()))
    public gql = getSdk(new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "ignore"}), (action) => action(this.requestHeaders()))
    public gqlWithoutSilo = getSdk(new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "ignore"}), (action) => action(this.tokenHeaders))
    public throwable = getSdk(new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "none"}), (action) => action(this.requestHeaders()))

    client = new GraphQLClient(`${environment.gqlApiEndpoint}/graphql`, {errorPolicy: "none"})
    public requestHeaders = () => ({...this.tokenHeaders, ...this.siloHeaders})

    public getDataObject = inject(SdkServiceDataObjectGQL)
    tokenService = inject(TokenService)

    $silo = signal<AuthorizationSilo | null | undefined>(undefined)
    silo$ = toObservable(this.$silo)

    async loadSiloFromLocalStorage() {
        try {
            const systemRole = localStorage.getItem("cm-auth-silo-system-role")
            const organizationId = localStorage.getItem("cm-auth-silo-organization")
            const organization = organizationId
                ? await this.gqlWithoutSilo.sdkServiceOrganization({id: organizationId}).then(({organization}) => organization)
                : undefined
            this.$silo.set({organization, systemRole: maybeEnum(systemRole, SystemRole), label: systemRole ?? organization?.name ?? "-"})
        } catch (error) {
            console.error("Failed to load auth silo from local storage", error)
        }
    }

    activateMembership(membership: {
        __typename: "Membership"
        organization: {id: string; name?: string | null; type?: OrganizationType | null}
        role?: MembershipRole
    }) {
        this.$silo.set({organization: membership.organization, label: membership.organization.name ?? "-"})
        try {
            localStorage.removeItem("cm-auth-silo-system-role")
            localStorage.setItem("cm-auth-silo-organization", membership.organization.id)
        } catch (error) {
            console.error("Failed to save auth silo to local storage", error)
        }
    }

    async activateSystemRole(systemRole: SystemRole) {
        try {
            localStorage.setItem("cm-auth-silo-system-role", systemRole)
            const {organizations} = await this.gqlWithoutSilo.sdkServiceColormassOrganization()
            const organizationId = organizations?.[0]?.id
            if (organizationId) {
                localStorage.setItem("cm-auth-silo-organization", organizationId)
            } else {
                localStorage.removeItem("cm-auth-silo-organization")
            }
            this.$silo.set({systemRole, label: systemRole, organization: organizations?.[0]})
        } catch (error) {
            console.error("Failed to save auth silo to local storage", error)
        }
    }

    selectOrganization(organization: {__typename: "Organization"; id: string; name?: string | null; type?: OrganizationType | null}) {
        this.activateMembership({__typename: "Membership", organization, role: MembershipRole.Viewer})
        try {
            localStorage.removeItem("cm-auth-silo-system-role")
            localStorage.setItem("cm-auth-silo-organization", organization.id)
        } catch (error) {
            console.error("Failed to save auth silo to local storage", error)
        }
    }

    get siloHeaders(): Record<string, string> {
        const silo = this.$silo()
        const siloOrganizationId = silo?.organization?.id
        const siloSystemRole = silo?.systemRole
        return {
            ...(siloOrganizationId
                ? {
                      "X-Colormass-Auth-Silo-Organization": siloOrganizationId,
                  }
                : {}),
            ...(siloSystemRole ? {"X-Colormass-Auth-Silo-System-Role": siloSystemRole} : {}),
        }
    }

    /**
     * Add JWT token from local storage to request headers for authentication
     */
    private get tokenHeaders(): Record<string, string> {
        const token = this.tokenService.load()
        return token
            ? {
                  "X-Colormass-Client": this.colormassClient,
                  Authorization: `Bearer ${token}`,
              }
            : {
                  "X-Colormass-Client": this.colormassClient,
              }
    }

    public waitForUploadProcessing(dataObjectId: string, destroyRef?: DestroyRef) {
        return firstValueFrom(
            this.getDataObject.watch({id: dataObjectId}, {pollInterval: 1000}).valueChanges.pipe(
                takeUntilDestroyed(destroyRef),
                map(({data: subscriptionData}) => subscriptionData?.dataObject),
                filter((dataObject) => dataObject?.state === DataObjectState.Completed || dataObject?.state === DataObjectState.ProcessingFailed),
                map((dataObject) => dataObject!.id),
            ),
            {defaultValue: null},
        )
    }
}
