import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {TypedImageData} from "@cm/lib/image-processing/image-processing-actions"
import {assertNever} from "@cm/lib/utils/utils"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {ChannelLayout, DataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export function getImgProcChannelLayout(channelLayout: ChannelLayout): TypedImageData["channelLayout"] {
    switch (channelLayout) {
        case "R":
            return "L"
        case "RGB":
            return "RGB"
        case "RGBA":
            return "RGBA"
        default:
            assertNever(channelLayout)
    }
}

export function getImgProcDataType(format: DataType): TypedImageData["dataType"] {
    switch (format) {
        case "uint8":
            return "uint8"
        case "float32":
            return "float32"
        case "float16":
            return "float16"
        default:
            assertNever(format)
    }
}

export function getImgProcPixelAddressMode(addressMode: AddressMode): Nodes.PixelAddressMode {
    switch (addressMode) {
        case "clamp":
            return "clamp"
        case "wrap":
            return "wrap"
        case "wrap-mirrored":
            throw new Error("Mirror address mode is not supported by ImgProc")
        case "border":
            throw new Error("Border address mode is not supported by ImgProc")
        default:
            assertNever(addressMode)
    }
}
