import {ColorLike, Vector2, Vector2Like} from "ts-lib/dist/browser/math"
import {ImageDescriptorWithOptionals, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {rasterizeGeometry, Vertex} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-rasterize-geometry"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

const SCOPE_NAME = "ColorGradient"

export type ParameterType = {
    resultImageOrDescriptor: ImageRef | ImageDescriptorWithOptionals
    type: "linear"
    startPos: Vector2Like
    endPos: Vector2Like
    stops: {t: number; color: ColorLike}[] // stops must be sorted by t
}

export type ReturnType = ImageRef

export function colorGradient(cmdQueue: ImageOpCommandQueue, {resultImageOrDescriptor, type, startPos, endPos, stops}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)
    const geometry = (() => {
        if (type !== "linear") {
            throw new Error(`Unsupported type: ${type}`)
        }
        const maxWidth = 100000 // width of triangle strip TODO this should consider the actual dimensions of the image
        const start = Vector2.fromVector2Like(startPos)
        const end = Vector2.fromVector2Like(endPos)
        const delta = end.sub(start)
        const deltaNormalized = delta.normalized()
        const perpNormalized = deltaNormalized.perp()
        const vertices: Vertex[] = []
        const indices: number[] = []
        const addVertexPair = (pos: Vector2, color: ColorLike) => {
            const posLo = pos.sub(perpNormalized.mul(maxWidth))
            const posHi = pos.add(perpNormalized.mul(maxWidth))
            vertices.push({
                position: posLo,
                color,
            })
            vertices.push({
                position: posHi,
                color,
            })
        }
        // create two vertices for pre-start
        addVertexPair(start.sub(deltaNormalized.mul(maxWidth)), stops[0].color)
        // create two vertices for each stop
        for (let i = 0; i < stops.length; i++) {
            const pos = start.add(delta.mul(stops[i].t))
            addVertexPair(pos, stops[i].color)
        }
        // create two vertices for post-end
        addVertexPair(end.add(deltaNormalized.mul(maxWidth)), stops[stops.length - 1].color)
        // create indices
        for (let i = 0; i < vertices.length - 2; i += 2) {
            indices.push(i, i + 1, i + 2, i + 2, i + 1, i + 3)
        }
        return {vertices, indices}
    })()
    const result = rasterizeGeometry(cmdQueue, {
        topology: "triangleList",
        vertices: geometry.vertices,
        indices: geometry.indices,
        resultImageOrDescriptor: resultImageOrDescriptor,
    })
    cmdQueue.endScope(SCOPE_NAME)
    return result
}
