import {registerNode} from "@src/graph-system/register-node"
import {TemplateLike, templateLike} from "@src/templates/node-types"
import {DeclareObjectNodeTS, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {visitNone} from "@src/graph-system/declare-visitor-node"
import {RunTemplateNew} from "@src/templates/runtime-graph/nodes/run-template-new"
import {CompileTemplateNew} from "@src/templates/runtime-graph/nodes/compile-template-new"
import {FilterAndWrapInterfaceNew} from "@src/templates/runtime-graph/nodes/filter-and-wrap-interface-new"
import {nodeInstanceFwd} from "@src/graph-system/instance"
import {idNodeParameters, IdNodeParameters} from "@src/templates/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"
import {ParametersFwd} from "./parameters"
import {ObjectData} from "../interfaces/object-data"
import {TemplateContext} from "../types"
import {Matrix4} from "@src/math"

const templateInstanceParameters = namedNodeParameters.merge(idNodeParameters).merge(
    z.object({
        template: templateLike,
        parameters: nodeInstanceFwd<ParametersFwd>("Parameters"), //Workaround for circular dependency
    }),
)

export type TemplateInstanceParameters = NamedNodeParameters &
    IdNodeParameters & {
        template: TemplateLike
        parameters: ParametersFwd
    }

@registerNode
export class TemplateInstance extends DeclareObjectNodeTS<TemplateInstanceParameters>( //owns parameters
    {
        validation: {paramsSchema: templateInstanceParameters},
        onVisited: {
            onCompile: function (this: TemplateInstance, {context, parameters}) {
                const {evaluator, currentTemplate, topLevelObjectId, templateDepth, subTemplates, sceneProperties, render} = context
                const {allBounds, objectToNodeMap, nodeToObjectMap} = currentTemplate
                const {readyList, preDisplayLists, displayLists, solverDatas, lookupByExternalIdPathMap, descriptorLists, exposeClaimedSubTemplateInputs} =
                    subTemplates
                const {templateContext, templateScope} = evaluator
                const {template, id, visible} = parameters

                const scope = evaluator.getScope(this)

                const [inputs, claimedInputIds] = evaluator.evaluateTemplateInputs(scope, this)
                const [graphValid, graphInvalid] = scope.branch(evaluator.evaluateTemplate(scope, template))
                const graph = scope.phi(scope.get(graphValid, "graph"), graphInvalid)

                const objectId = scope.genNodeId(RunTemplateNew)
                objectToNodeMap.set(objectId, this)
                nodeToObjectMap.set(this, objectId)

                const [transformMatrix, solverObject] = this.setupTransform(scope, context, objectId, undefined)

                const compileTemplate = scope.node(CompileTemplateNew, {
                    templateContext: scope.lambda<[TemplateContext, Matrix4], TemplateContext>(
                        scope.tuple(templateContext, transformMatrix),
                        ([templateContext, transformMatrix]) => {
                            return {...templateContext, templateMatrix: transformMatrix}
                        },
                        "templateContext",
                    ),
                    graph,
                    sceneProperties: sceneProperties ?? null,
                    render: render ?? null,
                    inputs,
                    topLevelObjectId: topLevelObjectId ?? objectId,
                    templateDepth: templateDepth + 1,
                    exposeClaimedSubTemplateInputs: false,
                    overrideMaterial: context.overrideMaterial ?? null,
                })
                const runTemplate = scope.node(RunTemplateNew, {
                    $id: objectId,
                    compiledTemplate: compileTemplate.compiledTemplate,
                })

                readyList.push(runTemplate.ready)

                if (visible) {
                    preDisplayLists.push(runTemplate.preDisplayList)
                    displayLists.push(runTemplate.displayList)
                }
                solverDatas.push(runTemplate.solverData)
                lookupByExternalIdPathMap.set(this, runTemplate.lookupByExternalIdPath)

                descriptorLists.push(
                    scope.node(FilterAndWrapInterfaceNew, {
                        interface: runTemplate.descriptorList,
                        wrapWithId: id,
                        claimedInputIds,
                        includeClaimed: exposeClaimedSubTemplateInputs,
                    }).output,
                )

                allBounds.push(runTemplate.bounds)
                const localId = evaluator.getLocalId(this)
                templateScope.alias(runTemplate.outputs, `templateOutputs-${localId}`)

                const objectData = scope.struct<ObjectData>("ObjectData", {
                    id: objectId,
                    topLevelObjectId: topLevelObjectId ?? objectId,
                    matrix: transformMatrix,
                    solverObject,
                    bounds: runTemplate.bounds,
                    preDisplayList: runTemplate.preDisplayList,
                    displayList: runTemplate.displayList,
                    solverData: runTemplate.solverData,
                    visible,
                })

                templateScope.alias(objectData, `objectData-${localId}`)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "TemplateInstance"},
) {}

export type TemplateInstanceFwd = TemplateObjectNode<TemplateInstanceParameters>
