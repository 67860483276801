import {registerNode} from "@src/graph-system/register-node"
import {PositionValue, positionValue} from "@src/templates/types"
import {ToneMapping, defaultsForToneMapping, toneMapping} from "@src/templates/nodes/post-process-render"
import {ObjectLike, objectLike} from "@src/templates/node-types"
import {DeclareObjectNodeTS, ObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {VisitorNodeVersion, visitNone} from "@src/graph-system/declare-visitor-node"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"
import {versionChain} from "@src/graph-system/node-graph"
import {Vector3} from "@src/math"
import {cameraLookAt, cameraAutomaticTarget, automaticVerticalTilt as makeAutomaticVerticalTiltMatrix} from "@src/templates/utils/camera-utils"

const cameraParameters = namedNodeParameters.merge(
    z.object({
        resolutionX: z.number(),
        resolutionY: z.number(),
        target: positionValue,
        targeted: z.boolean(),
        filmGauge: z.number(),
        fStop: z.number(),
        focalLength: z.number(),
        autoFocus: z.boolean(),
        focalDistance: z.number(),
        zoomFactor: z.number().optional(),
        ev: z.number(),
        shiftX: z.number(),
        shiftY: z.number(),
        automaticVerticalTilt: z.boolean(),
        minDistance: z.number().optional(),
        maxDistance: z.number().optional(),
        minPolarAngle: z.number().optional(),
        maxPolarAngle: z.number().optional(),
        minAzimuthAngle: z.number().optional(),
        maxAzimuthAngle: z.number().optional(),
        enablePanning: z.boolean(),
        screenSpacePanning: z.boolean(),
        toneMapping: toneMapping,
        automaticTarget: objectLike.optional(),
        nearClip: z.number().optional(),
        farClip: z.number().optional(),
    }),
)
export type CameraParameters = NamedNodeParameters & {
    resolutionX: number
    resolutionY: number
    target: PositionValue
    targeted: boolean
    filmGauge: number
    fStop: number
    focalLength: number
    autoFocus: boolean
    focalDistance: number
    zoomFactor?: number
    ev: number
    shiftX: number
    shiftY: number
    automaticVerticalTilt: boolean
    minDistance?: number
    maxDistance?: number
    minPolarAngle?: number
    maxPolarAngle?: number
    minAzimuthAngle?: number
    maxAzimuthAngle?: number
    enablePanning: boolean
    screenSpacePanning: boolean
    toneMapping: ToneMapping
    automaticTarget?: ObjectLike
    nearClip?: number
    farClip?: number
}

type V0 = ObjectNode &
    NamedNodeParameters & {
        resolutionX: number
        resolutionY: number
        target: PositionValue
        filmGauge: number
        fStop: number
        focalLength: number
        autoFocus: boolean
        focalDistance: number
        zoomFactor?: number
        exposure: number
        shiftX: number
        shiftY: number
        automaticVerticalTilt: boolean
        minDistance?: number
        maxDistance?: number
        minPolarAngle?: number
        maxPolarAngle?: number
        minAzimuthAngle?: number
        maxAzimuthAngle?: number
        enablePanning: boolean
        screenSpacePanning: boolean
        toneMapping?: ToneMapping
        automaticTarget?: ObjectLike
        nearClip?: number
        farClip?: number
    }

type V1 = V0 & {toneMapping: ToneMapping; targeted: boolean}
const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        return {...parameters, targeted: true, toneMapping: parameters.toneMapping ?? defaultsForToneMapping("filmic")} // filmic was the default in previous platform versions
    },
}

type V2 = Omit<V1, "exposure"> & {ev: number}
const v1: VisitorNodeVersion<V1, V2> = {
    toNextVersion: (parameters) => {
        const {exposure, ...rest} = parameters
        return {ev: Math.log2(exposure), ...rest}
    },
}

@registerNode
export class Camera extends DeclareObjectNodeTS<CameraParameters>(
    {
        validation: {paramsSchema: cameraParameters},
        onVisited: {
            onCompile: function (this: CameraFwd, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {displayList} = currentTemplate
                const {templateContext} = evaluator
                const {sceneManager} = templateContext
                const {
                    resolutionX,
                    resolutionY,
                    targeted,
                    zoomFactor,
                    filmGauge,
                    automaticVerticalTilt,
                    name,
                    fStop,
                    ev,
                    shiftX,
                    nearClip,
                    farClip,
                    minDistance,
                    maxDistance,
                    minPolarAngle,
                    maxPolarAngle,
                    minAzimuthAngle,
                    maxAzimuthAngle,
                    enablePanning,
                    screenSpacePanning,
                    toneMapping,
                } = parameters

                const scope = evaluator.getScope(this)

                this.setupObject(scope, context, "Camera", undefined, undefined, ({transform: objectTransform, ...objectProps}) => {
                    const aspectRatio = resolutionX > 0 && resolutionY > 0 ? resolutionX / resolutionY : 1

                    // we need to read this param from the root node as this camera might be specified in a sub-template, but the param is only set to the root
                    const viewportSizeParameter = ((): [number, number] | undefined => {
                        const rootParameters = sceneManager.getRootNodeNew().parameters.parameters
                        const rootViewportSizeParameter = rootParameters?.parameters["$viewportSize"]
                        if (Array.isArray(rootViewportSizeParameter) && rootViewportSizeParameter.length === 2) {
                            const [x, y] = rootViewportSizeParameter
                            if (typeof x === "number" && typeof y === "number") return [x, y] as const
                        }

                        return undefined
                    })()

                    const uiSize = viewportSizeParameter ?? [resolutionX, resolutionY]
                    if (uiSize[0] <= 0 || uiSize[1] <= 0) {
                        console.warn(`Invalid camera viewport size: ${uiSize}`)
                        uiSize[0] = uiSize[1] = 1000
                    }

                    const focalLength =
                        zoomFactor !== undefined
                            ? adjustFocalLengthForZoomFactor(filmGauge, parameters.focalLength, zoomFactor, uiSize[0], uiSize[1])
                            : parameters.focalLength

                    const [automaticTarget, automaticTargetUndefined] = scope.branch(evaluator.evaluateObject(scope, parameters.automaticTarget ?? null))
                    const autoTargetBounds = scope.phi(scope.get(automaticTarget, "bounds"), automaticTargetUndefined)

                    const constrainedTransform = targeted
                        ? scope.lambda(
                              objectTransform,
                              (transform) => {
                                  return cameraLookAt(transform.getTranslation(), Vector3.fromArray(parameters.target))
                              },
                              "constrainedTransform",
                          )
                        : objectTransform

                    const transformAndFocus = scope.lambda(
                        scope.tuple(constrainedTransform, autoTargetBounds),
                        ([transform, autoTargetBounds]) => {
                            if (autoTargetBounds) {
                                const position = transform.getTranslation()
                                const target = Vector3.fromArray(autoTargetBounds.centroid)
                                const matrix = cameraAutomaticTarget(position, target, autoTargetBounds.aabb, focalLength, filmGauge, uiSize)
                                return [matrix, target, matrix.getTranslation().sub(target).norm()] as const
                            } else return [transform, Vector3.fromArray(parameters.target), parameters.focalDistance] as const
                        },
                        "autoTargetBounds",
                    )

                    const autoTargetTransform = scope.get(transformAndFocus, 0)
                    const target = scope.get(transformAndFocus, 1)
                    const focalDistance = scope.get(transformAndFocus, 2)

                    const transformAndShift = (() => {
                        if (!automaticVerticalTilt) return scope.tuple(autoTargetTransform, parameters.shiftY)
                        else {
                            return scope.lambda(
                                autoTargetTransform,
                                (autoTargetTransform) => {
                                    const [transform, additionalShiftY] = makeAutomaticVerticalTiltMatrix(
                                        autoTargetTransform,
                                        filmGauge,
                                        focalLength,
                                        uiSize[0],
                                        uiSize[1],
                                    )
                                    return [transform, parameters.shiftY + additionalShiftY] as const
                                },
                                "matrixAndShiftY",
                            )
                        }
                    })()

                    const transform = scope.get(transformAndShift, 0)
                    const shiftY = scope.get(transformAndShift, 1)

                    const autoFocus = scope.phi(
                        scope.lambda(automaticTarget, () => false, "autoFocusAutoTarget"),
                        scope.lambda(automaticTargetUndefined, () => parameters.autoFocus, "autoFocusUndefinedAutoTarget"),
                    )

                    return scope.struct<SceneNodes.Camera>("Camera", {
                        type: "Camera",
                        ...objectProps,
                        transform,
                        name,
                        focalLength,
                        focalDistance,
                        autoFocus,
                        aspectRatio,
                        target,
                        targeted: scope.lambda(
                            autoTargetBounds,
                            (autoTargetBounds) => {
                                if (autoTargetBounds !== null) return true
                                return targeted
                            },
                            "targeted",
                        ),
                        filmGauge,
                        fStop,
                        exposure: Math.pow(2, ev),
                        toneMapping,
                        shiftX,
                        shiftY,
                        nearClip,
                        farClip,
                        minDistance,
                        maxDistance,
                        minPolarAngle,
                        maxPolarAngle,
                        minAzimuthAngle,
                        maxAzimuthAngle,
                        enablePanning,
                        screenSpacePanning,
                    })
                })

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "Camera", versionChain: versionChain([v0, v1])},
) {}

export type CameraFwd = TemplateObjectNode<CameraParameters>

function adjustFocalLengthForZoomFactor(filmGauge: number, focalLength: number, zoomFactor: number, width: number, height: number): number {
    const maxFOV = 175.0 * (Math.PI / 180.0)
    const aspect = width > height ? width / height : height / width
    const origFOV = 2 * Math.atan(filmGauge / aspect / (2 * focalLength))
    const newFOV = Math.min(origFOV / Math.max(0.01, zoomFactor), maxFOV)
    return filmGauge / aspect / (2 * Math.tan(newFOV / 2))
}
