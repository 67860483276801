import {Component, DestroyRef, inject} from "@angular/core"
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCardModule} from "@angular/material/card"
import {MatInputModule} from "@angular/material/input"
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActivatedRoute, Router} from "@angular/router"
import {MutationLoginInput} from "@api"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {getPasswordHash} from "@common/helpers/auth/hash"
import {AuthService} from "@common/services/auth/auth.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"

@Component({
    selector: "cm-login-page",
    templateUrl: "login-page.component.html",
    styleUrls: ["login-page.component.scss"],
    standalone: true,
    imports: [MatCardModule, MatInputModule, ReactiveFormsModule, MatProgressSpinnerModule, MatButtonModule, InputContainerComponent, MatTooltipModule],
})
export class LoginPageComponent {
    credentials: MutationLoginInput
    showProgressCircle = false
    passwordResetEmail = ""
    forgotPassword = false
    passwordVariantOne = ""
    passwordVariantTwo = ""
    passwordToken?: string

    loginForm = new UntypedFormGroup({
        loginEmailFormControl: new UntypedFormControl("", [Validators.required, Validators.email]),
        loginPasswordFormControl: new UntypedFormControl("", [Validators.required]),
    })

    forgotPasswordForm = new UntypedFormGroup({
        passwordResetEmailFormControl: new UntypedFormControl("", [Validators.required, Validators.email]),
    })

    passwordResetForm = new UntypedFormGroup({
        passwordVariantOneFormControl: new UntypedFormControl("", [Validators.required]),
        passwordVariantTwoFormControl: new UntypedFormControl("", [Validators.required]),
    })

    cardHeading = "Reset Password"

    destroyRef = inject(DestroyRef)
    organizations = inject(OrganizationsService)

    notifications = inject(NotificationsService)
    sdk = inject(SdkService)

    constructor(
        public auth: AuthService,
        private snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.credentials = {email: "", password: ""}
        this.showProgressCircle = false
        this.route.queryParams.subscribe((params) => {
            if (params.token) {
                this.passwordToken = params.token
            }
            if (params.flow === "welcome") {
                this.cardHeading = "Set your password"
            }
        })
    }

    async login() {
        this.showProgressCircle = true
        try {
            const success = await this.auth.logIn(this.credentials)
            if (success) {
                const returnUrl = this.route.snapshot.queryParams.returnUrl
                if (returnUrl) {
                    void this.router.navigateByUrl(returnUrl)
                } else {
                    await this.navigateToDefaultView()
                }
            }
        } catch (error) {
            console.error(error)
            // TODO: deal with other errors (e.g. server unavailable) ?
            this.snackBar.open("Login failed. Wrong email or password.", "", {duration: 3000})
        } finally {
            this.showProgressCircle = false
        }
    }

    async sendForgotPasswordEmail(email: string) {
        try {
            this.showProgressCircle = true
            await this.sdk.gql.loginSendPasswordResetEmail({input: {email}})
            this.notifications.showInfo("You will receive an email to help you reset your password, please check your inbox in a bit.", 10000)
        } catch (error) {
            this.notifications.showError(error)
        } finally {
            this.showProgressCircle = false
        }
    }

    async setPassword(newPassword: string) {
        this.showProgressCircle = true
        // Make sure to clear existing tokens.
        this.auth.logOut()
        const newPasswordHash: string = getPasswordHash(newPassword)
        const token = this.passwordToken
        if (token) {
            await this.notifications.withUserFeedback(
                async () => {
                    const email = (
                        await this.sdk.gql.loginResetPassword({
                            input: {
                                token,
                                password: newPasswordHash,
                            },
                        })
                    ).resetPassword
                    this.credentials.email = email
                    this.credentials.password = newPassword
                    void this.login()
                    this.showProgressCircle = false
                },
                {
                    success: "Your password has been changed successfully",
                    error: "Cannot change password",
                },
            )
        }
    }

    /**
     * If the user is already set, use it to decide what the default view is.
     * Otherwise, get the user from the API. This normally happens at the login process.
     */
    async navigateToDefaultView() {
        const silo = this.sdk.$silo()
        const organizationId = silo?.organization?.id ?? null
        return this.router.navigate(["pictures"], {queryParams: {tab: "projects", organizationId}})
    }
}
