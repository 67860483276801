import {isImageWebGL2} from "app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {assertNever} from "@cm/lib/utils/utils"
import {ChannelLayout, ImageDescriptor, ImageDescriptorWithOptionals} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Size2Like} from "@cm/lib/math"
import {HalImageDescriptor} from "@common/models/hal/hal-image/types"

export function checkSameSize(sizeA: Size2Like, sizeB: Size2Like): boolean {
    return sizeA.width === sizeB.width && sizeA.height === sizeB.height
}

export function assertSameSize(descriptorA: ImageDescriptor, descriptorB: ImageDescriptor, includeBatchSize = true): void {
    if (!checkSameSize(descriptorA, descriptorB)) {
        throw Error(`Images must have the same size: ${JSON.stringify(descriptorA)} vs ${JSON.stringify(descriptorB)}`)
    }
    if (includeBatchSize && !checkSameSize(descriptorA.batchSize, descriptorB.batchSize)) {
        throw Error(`Images must have the same batch size: ${JSON.stringify(descriptorA)} vs ${JSON.stringify(descriptorB)}`)
    }
}

export function assertBatchSizeOne(descriptor: ImageDescriptor): void {
    if (descriptor.batchSize.width !== 1 || descriptor.batchSize.height !== 1) {
        throw Error(`Batch size must be 1: ${JSON.stringify(descriptor)}`)
    }
}

export function fillImageDescriptorOptionals(descriptor: ImageDescriptorWithOptionals): ImageDescriptor {
    return {
        ...descriptor,
        batchSize: descriptor.batchSize ?? {width: 1, height: 1},
    }
}

export function isHalDescriptorCompatible(descriptorA: HalImageDescriptor, descriptorB: HalImageDescriptor): boolean {
    // consider "float32" and "float16" to be compatible ("float32" might be used as a fallback if "float16" is not supported)
    const formatA = descriptorA.dataType === "float16" ? "float32" : descriptorA.dataType
    const formatB = descriptorB.dataType === "float16" ? "float32" : descriptorB.dataType
    return (
        descriptorA.width === descriptorB.width &&
        descriptorA.height === descriptorB.height &&
        descriptorA.channelLayout === descriptorB.channelLayout &&
        formatA === formatB
    )
}

export function getChannelLayoutByCount(count: number): ChannelLayout {
    switch (count) {
        case 1:
            return "R"
        case 3:
            return "RGB"
        case 4:
            return "RGBA"
        default:
            throw new Error(`Invalid channel count: ${count}`)
    }
}

export function getChannelCountByLayout(channelLayout: ChannelLayout): number {
    switch (channelLayout) {
        case "R":
            return 1
        case "RGB":
            return 3
        case "RGBA":
            return 4
        default:
            assertNever(channelLayout)
    }
}

export function traceObject(parameters: unknown): string {
    return JSON.stringify(
        parameters,
        (key, value) => {
            if (isImageWebGL2(value)) {
                return `ImageWebGL2(DebugInfo: ${value.debugInfo})`
            } else {
                return value
            }
        },
        2,
    )
}

// TODO move to ts-lib or check if there is something like this already
export function traverseObject(value: unknown, handler: (value: unknown, key?: string) => unknown, key?: string) {
    value = handler(value, key)
    if (Array.isArray(value)) {
        value.forEach((value, index) => traverseObject(value, handler, index.toString()))
    } else if (value && typeof value === "object") {
        Object.entries(value).forEach(([key, value]) => traverseObject(value, handler, key))
    }
}
