import {Component, ElementRef, inject, OnInit, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogActions, MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {
    BasicTagInfoFragment,
    ContentTypeModel,
    CreateMaterialNodeMutation,
    DataObjectAssignmentType,
    GetMaterialsGQL,
    GetMaterialsQuery,
    MaterialOrderByCriteria,
    MaterialsGridItemFragment,
    MaterialState,
    MutationCreateMaterialConnectionInput,
    MutationCreateMaterialInput,
    MutationCreateMaterialNodeInput,
    MutationCreateMaterialRevisionInput,
    MutationUpdateMaterialInput,
    NextActor,
    PaymentState,
    SortOrder,
} from "@api"
import {IsDefined} from "@cm/lib/utils/filter"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {CommentBoxesComponent} from "@common/components/comment-boxes/comment-boxes.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent} from "@common/components/filters/checkboxes-filter/checkboxes-filter.component"
import {PinnedFilterOptionComponent} from "@common/components/filters/pinned-filter-option/pinned-filter-option.component"
import {PinnedFilterComponent} from "@common/components/filters/pinned-filter/pinned-filter.component"
import {TagSearchFilterComponent} from "@common/components/filters/tag-search-filter/tag-search-filter.component"
import {AdvancedAction, BatchDownloadData, BatchMenuItem} from "@common/models/item/list-item"
import {ItemFiltersComponent} from "@common/components/item/item-filters/item-filters.component"
import {ItemListComponent} from "@common/components/item/item-list/item-list.component"
import {ListInfoComponent} from "@common/components/item/list-info/list-info.component"
import {InfiniteListComponent} from "@common/components/lists/infinite-list/infinite-list.component"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {StateLabel} from "@labels"
import {AddMaterialDialogComponent} from "@platform/components/materials/add-material-dialog/add-material-dialog.component"
import {MaterialsCardComponent} from "@platform/components/materials/materials-card/materials-card.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {TagLabelsComponent} from "@platform/components/tags/tag-labels/tag-labels.component"
import {AssignUserDialogComponent} from "@platform/components/users/assign-user-dialog/assign-user-dialog.component"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {CancellableRequest} from "@platform/models/data/cancellable-request"
import {MaterialBatchOperationService} from "@platform/services/material/material-batch-operation.service"

export type CreateMaterialData = MutationCreateMaterialInput & {materialRangeTagId?: string | null}

@Component({
    imports: [
        AssignUserDialogComponent,
        AssignUserComponent,
        CardErrorComponent,
        CardPlaceholderComponent,
        CheckboxesFilterComponent,
        CommentBoxesComponent,
        EntityCardComponent,
        EntityResponsiveSidebarComponent,
        FormsModule,
        InfiniteListComponent,
        ItemFiltersComponent,
        ListInfoComponent,
        MatButtonModule,
        MatDialogActions,
        MatDialogModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        PinnedFilterComponent,
        PinnedFilterOptionComponent,
        RouterModule,
        StateLabelComponent,
        TagLabelsComponent,
        TagSearchFilterComponent,
        AddMaterialDialogComponent,
        MatTooltipModule,
        MaterialsCardComponent,
    ],
    selector: "cm-materials-grid",
    standalone: true,
    styleUrls: ["materials-grid.component.scss"],
    templateUrl: "materials-grid.component.html",
})
export class MaterialsGridComponent extends ItemListComponent<MaterialsGridItemFragment, MutationUpdateMaterialInput, CreateMaterialData> implements OnInit {
    public stateLabels: StateLabel<MaterialState>[] = []
    public materialRangeTags: BasicTagInfoFragment[] = []
    public filteredMaterialRangeTags: BasicTagInfoFragment[] = []
    public extraBatchActions: BatchMenuItem<MaterialsGridItemFragment>[] = []
    public batchDownloadData: BatchDownloadData<MaterialsGridItemFragment>[] = []
    public advancedActions: AdvancedAction[] = []
    public newItemBatchMode = false
    public showMaterialExplorer = false
    public enableBatchUpdates = false

    @ViewChild("fileInput", {static: true}) fileInput!: ElementRef

    protected uploadService = inject(UploadGqlService)
    protected materialBatchOperationService = inject(MaterialBatchOperationService)

    private fetchRequest = new CancellableRequest<GetMaterialsQuery>(inject(GetMaterialsGQL), this.sdk, this.destroyRef)

    override ngOnInit() {
        this.stateLabels = this.materialBatchOperationService.getStateLabels()
        this.extraBatchActions = this.materialBatchOperationService.getExtraBatchActions()
        this.enableBatchUpdates =
            this.$can().read.menu("runGenericBatchOperations") ||
            this.$can().read.menu("batchDownloadMaterials") ||
            this.$can().read.menu("batchStartMaterialImageJobs")

        this.batchDownloadData = this.$can().read.menu("batchDownloadData") ? this.materialBatchOperationService.getBatchDownloadData() : []

        const organizationDetails = this.organizations.current
        if (organizationDetails) {
            this.sdk.gql.materialExplorerInfo({organizationId: organizationDetails.id}).then((materialExplorerInfo) => {
                this.showMaterialExplorer =
                    this.$can().read.material(null, "explorer") &&
                    materialExplorerInfo.organization?.matExplorerMaterial?.id !== undefined &&
                    materialExplorerInfo.organization?.matExplorerTemplate?.id !== undefined
            })
        }

        this.updateOrganization()

        this.advancedActions = this.materialBatchOperationService.getAdvancedActions(this.fileInput, this.route)

        super.ngOnInit()

        void this.sdk.gql.materialsGridMaterialRangeTags().then(({tags}) => {
            this.materialRangeTags = tags.filter(IsDefined)
        })
    }

    // OVERLOADS

    protected override _contentTypeModel = ContentTypeModel.Material
    protected override _initialNewItemData = () => ({
        organizationId: this.organizations.$current()?.id,
    })

    protected override _batchUpdate = async (
        property: "addTagId" | "assignUserId" | "nextActor" | "paymentState" | "removeTagId" | "removeUserAssignment" | "state",
        value: string | boolean,
    ): Promise<number> => {
        const material = await this.sdk.gql.batchUpdateMaterials({
            filter: this.filters.materialFilter() ?? {},
            [property]: value,
        })
        return material.batchUpdateMaterials
    }

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) => {
        return this.fetchRequest
            .fetch({
                take,
                skip,
                filter: this.filters.materialFilter(),
                orderBy: [
                    {key: MaterialOrderByCriteria.Priority, direction: SortOrder.Asc},
                    {key: MaterialOrderByCriteria.Id, direction: SortOrder.Desc},
                ],
            })
            .then(({materials, materialsCount}) => ({items: materials, totalCount: materialsCount}))
    }

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}): Promise<MaterialsGridItemFragment | undefined> =>
        this.sdk.gql
            .getMaterials({
                take: 1,
                filter: {
                    ...this.filters.materialFilter(),
                    id: id ? {equals: id} : undefined,
                    legacyId: legacyId ? {equals: legacyId} : undefined,
                },
            })
            .then(({materials}) => materials?.[0] || undefined)

    protected override _updateItem = (data: MutationUpdateMaterialInput) =>
        this.sdk.gql
            .updateMaterial({
                input: data,
            })
            .then(({updateMaterial: material}) => material)

    protected override _createItem = (data: CreateMaterialData) => {
        const {materialRangeTagId, tagAssignments, ...rest} = data
        return this.sdk.gql
            .createMaterial({
                input: {
                    ...rest,
                    tagAssignments: materialRangeTagId ? [materialRangeTagId, ...(tagAssignments ?? [])] : tagAssignments,
                    state: rest.state ?? MaterialState.Draft,
                    paymentState: rest.paymentState ?? PaymentState.OrderPlaced,
                    meshSpecific: rest.meshSpecific ?? false,
                    nextActor: rest.nextActor ?? NextActor.Team1,
                },
            })
            .then(({createMaterial: material}) => material)
    }

    override openNewItemDialog() {
        this.newItemBatchMode = false
        super.openNewItemDialog()
        this.updateOrganization()
    }

    async copyMaterial(item: {id: string}): Promise<void> {
        // TODO factor out common logic for duplication of material revision into material graph service
        const material = await this.sdk.gql.getMaterialForCopy({id: item.id}).then(({material}) => material)

        const {articleId, meshSpecific, nextActor, organization, public: isPublic, sampleArrival, tagAssignments} = material

        const createMaterialInput: MutationCreateMaterialInput = {
            articleId,
            meshSpecific,
            nextActor,
            organizationId: organization?.id,
            paymentState: PaymentState.OrderPlaced,
            public: isPublic,
            sampleArrival,
            tagAssignments: tagAssignments.map((tag) => tag.id),
            name: material.name + " (Copy)",
            description: `Copy of material with id "${material.legacyId}" and with name "${material.name}".`,
            state: MaterialState.Draft,
        }

        const createMaterialResult = await this._createItem(createMaterialInput)

        try {
            const orgId: number = material.legacyId
            const newId: string = createMaterialResult.id
            const newLegacyId: number = createMaterialResult.legacyId

            // find material with the highest number of revisions that has cycles material
            const latestCyclesRevision = material.revisions
                .sort((a, b) => b.number - a.number)
                .find((revision) => revision.hasCyclesMaterial && revision.graphSchema && revision.nodes.length > 0)

            if (!latestCyclesRevision) {
                this.notifications.showInfo(`Material with the id ${orgId} has been copied. The id of the copy is ${newLegacyId}.`)
                return
            }

            // Create a copy of the latest cycles revision.
            const createMaterialRevisionInput: MutationCreateMaterialRevisionInput = {
                graphSchema: latestCyclesRevision.graphSchema!,
                materialId: createMaterialResult.id,
                comment: latestCyclesRevision.comment,
            }

            const createMaterialRevisionResult = await this.sdk.gql
                .createMaterialRevision({input: createMaterialRevisionInput})
                .then(({createMaterialRevision}) => createMaterialRevision)

            // Create a copy of the material node graph.
            // First all the nodes should be copied and the mapping from old to newly created ids should be kept.
            // Then all the connections should be copied by using this mapping.
            const nodeMap: {[id: string]: CreateMaterialNodeMutation["createMaterialNode"]} = {}

            await Promise.all(
                latestCyclesRevision.nodes.map(async (node) => {
                    const createMaterialNodeInput: MutationCreateMaterialNodeInput = {
                        materialRevisionId: createMaterialRevisionResult.id,
                        name: node.name,
                        parameters: node.parameters,
                        textureRevisionId: node.textureRevision?.id,
                        textureSetRevisionId: node.textureSetRevision?.id,
                    }
                    nodeMap[node.id] = await this.sdk.gql
                        .createMaterialNode({input: createMaterialNodeInput})
                        .then(({createMaterialNode}) => createMaterialNode)
                }),
            )

            await Promise.all(
                latestCyclesRevision.connections.map(async (connection) => {
                    const createMaterialConnectionInput: MutationCreateMaterialConnectionInput = {
                        destinationId: nodeMap[connection.destination.id].id,
                        destinationParameter: connection.destinationParameter,
                        materialRevisionId: createMaterialRevisionResult.id,
                        sourceId: nodeMap[connection.source.id].id,
                        sourceParameter: connection.sourceParameter,
                    }
                    await this.sdk.gql.createMaterialConnection({input: createMaterialConnectionInput})
                }),
            )

            this.notifications.showInfo(`Material with the id ${orgId} has been copied. The id of the copy is ${newLegacyId}.`)

            setTimeout(() => {
                void this.router.navigate([newId], {
                    relativeTo: this.route,
                    queryParamsHandling: "preserve",
                })
            })
        } finally {
            this.refresh.contentTypeModel(ContentTypeModel.Material)
        }
    }

    batchCreateMaterials(event: Event) {
        const files = (<HTMLInputElement>event.target).files
        if (!files || files.length === 0) return

        if (!this.newItemDialog) {
            // put an `<ng-template #newItemDialog>` tag containing the form in the html template
            throw new Error("Missing newItemDialog ref in template")
        }

        this.newItemBatchMode = true
        this.newItemDialogRef = this.dialog.open(this.newItemDialog, {
            data: this._initialNewItemData(),
        })
        this.newItemDialogRef.afterClosed().subscribe(async (data) => {
            if (!data) return

            const {organizationId} = data

            if (!organizationId) return

            try {
                for (const file of Array.from(files)) {
                    try {
                        const dataObject = await this.uploadService.createAndUploadDataObject(
                            file,
                            {
                                organizationId: organizationId,
                            },
                            {processUpload: true, showUploadToolbar: true},
                        )

                        const material = await this._createItem({
                            ...data,
                            name: file.name.replace(/\.[^/.]+$/, ""),
                            state: MaterialState.InfoReview,
                        })

                        if (!material) throw Error("Returned material is undefined")

                        await this.sdk.gql.materialsGridCreateDataObjectAssignment({
                            input: {
                                dataObjectId: dataObject.id,
                                objectId: material.id,
                                contentTypeModel: ContentTypeModel.Material,
                                type: DataObjectAssignmentType.GalleryImage,
                            },
                        })
                    } catch (error) {
                        this.snackBar.open("Cannot create new material.", "", {duration: 3000})
                        console.error(error)
                    }
                }
            } finally {
                this.refresh.contentTypeModel(ContentTypeModel.Material)
            }
        })
    }

    // CONVENIENCE METHODS

    updateOrganization() {
        this.filteredMaterialRangeTags = this.materialRangeTags.filter((tag) => {
            return tag?.organization?.id === this.newItemData.organizationId
        })
        // reset the current selection if it is no longer available
        this.newItemData.materialRangeTagId = this.filteredMaterialRangeTags.some((tag) => tag.id === this.newItemData.materialRangeTagId)
            ? this.newItemData.materialRangeTagId
            : undefined
    }
}
