import {Component, inject, Signal} from "@angular/core"
import {Router} from "@angular/router"
import {FiltersService} from "@common/services/filters/filters.service"
import {InfiniteScrollService} from "@common/services/infinite-scroll/infinite-scroll.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PagingService} from "@common/services/paging/paging.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {LoadedData} from "@platform/models/data"
import {DataLoaderService, SortOrderService} from "@platform/services/data"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-base-table",
    standalone: true,
    imports: [],
    template: "",
    styleUrls: [],
})
export abstract class BaseTableComponent<EntityType> {
    dataLoader = inject(DataLoaderService)
    filters = inject(FiltersService)
    infiniteScroll = inject(InfiniteScrollService)
    notification = inject(NotificationsService)
    paging = inject(PagingService)
    permission = inject(PermissionsService)
    refresh = inject(RefreshService)
    router = inject(Router)
    sdk = inject(SdkService)
    sortOrder = inject(SortOrderService)
    $can = this.permission.$to
    abstract $columns: Signal<string[]>
    abstract $data: Signal<LoadedData<EntityType>>
}
