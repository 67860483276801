@if (uiStyle != "hidden" && interfaceDescriptors?.length > 0) {
    <div class="cm-configurator-menu">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <link href="https://storage.googleapis.com/cm-platform-prod-static/fonts/fontawesome-pro-6.0.0-alpha3/css/all.css" rel="stylesheet" />

        <div class="cm-menu-content">
            @if (minimized) {
                <div (click)="minimized = !minimized" class="cm-minimized-menu">
                    <i class="far fa-sliders-h"></i>
                </div>
            } @else {
                @if (uiStyle === "default") {
                    <cm-default-menu [$interfaceDescriptors]="interfaceDescriptors"></cm-default-menu>
                }
                @if (uiStyle === "accordion" || uiStyle === "icons") {
                    <cm-icons-accordion-menu
                        [$useCaptions]="useCaptions"
                        [$interfaceDescriptors]="interfaceDescriptors"
                        [$uiStyle]="uiStyle"
                        [$tooltipContainer]="tooltipContainer"
                        [$accordionStyle]="accordionStyle"
                    ></cm-icons-accordion-menu>
                }
            }
        </div>
        <div class="cm-minimize-menu-container">
            @if (!minimized && !externalMenu) {
                <div (click)="minimized = !minimized" class="cm-minimize-menu">
                    <i class="far fa-chevron-down"></i>
                </div>
            }
        </div>

        <div class="tooltip-container" #tooltipContainer></div>
    </div>
}
