import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {TypeDescriptors} from "../type-descriptors"

const TD = TypeDescriptors

const lambdaDescriptor = {
    input: TD.inlet(TD.Identity<any>()),
    fn: TD.inlet(TD.Identity<(x: any) => any>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class Lambda<I, O> implements NodeClassImpl<typeof lambdaDescriptor, typeof Lambda> {
    static descriptor = lambdaDescriptor
    static uniqueName = "Lambda"
    input!: Inlet<I>
    fn!: Inlet<(x: I) => O>
    output!: Outlet<O>

    run() {
        if (this.input === NotReady || this.fn === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        this.output.emitIfChanged(this.fn(this.input))
    }
}
