import {Component, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatIconButton} from "@angular/material/button"
import {MatIcon} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatMenu, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {RouterLink, RouterOutlet} from "@angular/router"
import {ContentTypeModel, ModelDetailsFragment, MutationUpdateModelInput, OrganizationType} from "@api"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {CopyLegacyIdButtonComponent} from "@common/components/buttons/copy-legacy-id-button/copy-legacy-id-button.component"
import {CopyToClipboardButtonComponent} from "@common/components/buttons/copy-to-clipboard-button/copy-to-clipboard-button.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {NativeInputTextComponent} from "@common/components/inputs/native/native-input-text/native-input-text.component"
import {BooleanLabelSelectComponent} from "@common/components/inputs/select/boolean-label-select/boolean-label-select.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {TagSelectComponent} from "@common/components/inputs/select/tag-select/tag-select.component"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {ImagePlaceholderComponent} from "@common/components/placeholders/image-placeholder/image-placeholder.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {DialogSize} from "@common/models/dialogs"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {ActivitySectionComponent} from "@platform/components/details/activity-section/activity-section.component"
import {ArticleIdSectionComponent} from "@platform/components/details/article-id-section/article-id-section.component"
import {CommentSectionComponent} from "@platform/components/details/comment-section/comment-section.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {ProjectManagementTasksSectionComponent} from "@platform/components/details/project-management-tasks-section/project-management-tasks-section.component"
import {ShowAssetsButtonComponent} from "@platform/components/details/show-assets-button/show-assets-button.component"
import {SidebarDescriptionSectionComponent} from "@platform/components/details/sidebar-description-section/sidebar-description-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {CornersLayoutComponent} from "@platform/components/layouts/corners-layout/corners-layout.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"

@Component({
    selector: "cm-model-details",
    templateUrl: "model-details.component.html",
    styleUrls: ["model-details.component.scss"],
    standalone: true,
    imports: [
        MemoizePipe,
        SectionComponent,
        ButtonComponent,
        RouterLink,
        NativeInputTextComponent,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        DetailsDialogLayoutComponent,
        BooleanLabelSelectComponent,
        TagSelectComponent,
        GalleryImageComponent,
        CornersLayoutComponent,
        StateLabelComponent,
        PlaceholderComponent,
        ImagePlaceholderComponent,
        RoutedDialogComponent,
        CopyToClipboardButtonComponent,
        MatIcon,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        MatMenuModule,
        MatTooltipModule,
        CopyLegacyIdButtonComponent,
        OrganizationSelectComponent,
        CommentSectionComponent,
        SidebarDescriptionSectionComponent,
        FilesSectionComponent,
        ActivitySectionComponent,
        ProjectManagementTasksSectionComponent,
        ArticleIdSectionComponent,
        TitleSectionComponent,
        ShowAssetsButtonComponent,
        TagSelectComponent,
        PaymentStateLabelComponent,
        RouterOutlet,
    ],
})
export class ModelDetailsComponent extends BaseDetailsComponent<ModelDetailsFragment, Omit<MutationUpdateModelInput, "id">> implements OnInit {
    override _contentTypeModel = ContentTypeModel.Model
    override _fetchItem = this.sdk.gql.modelDetails
    override _updateItem = this.sdk.gql.updateModelDetails
    override _deleteItem = this.sdk.raw.deleteModelDetails

    protected readonly ContentTypeModel = ContentTypeModel
    protected readonly DialogSize = DialogSize

    canNavigateToAssets = () => this.sdk.$silo()?.organization?.type !== OrganizationType.Photographer
}
