import {Component, inject, EventEmitter, Output, OnInit} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {FilterListComponent} from "@common/components/filters/filter-list/filter-list.component"
import {StateLabel} from "@labels"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-own-organizations-filter",
    standalone: true,
    imports: [FilterListComponent, MatTooltipModule],
    templateUrl: "./own-organizations-filter.component.html",
    styleUrl: "./own-organizations-filter.component.scss",
})
export class OwnOrganizationsFilterComponent implements OnInit {
    permission = inject(PermissionsService)
    organizations = inject(OrganizationsService)
    $can = this.permission.$to

    @Output() selectionChanged = new EventEmitter<string[] | undefined>()

    public selectedOrganizations: StateLabel<string>[] = []

    ngOnInit() {
        // if the user is allowed to view all organizations, we don't show this filter
        // and pretend an empty selection was made (e.g. for staff users, who can view all organizations)
        if (this.$can().read.organization()) {
            this.selectionChanged.emit(undefined)
        } else {
            this.selectOwnOrganizations()
        }
    }

    onRemoveOrganization(organization: StateLabel<string>) {
        this.selectedOrganizations = this.selectedOrganizations.filter((selectedOrganization) => selectedOrganization.state !== organization.state)
        this.selectionChanged.emit(this.selectedOrganizations.length ? this.selectedOrganizations.map((organization) => organization.state) : undefined)
    }

    selectOwnOrganizations() {
        this.selectedOrganizations =
            this.organizations.$own()?.map((organization) => ({
                label: organization.name ?? "",
                state: organization.id,
            })) ?? []
        this.selectionChanged.emit(this.selectedOrganizations.map((organization) => organization.state))
    }
}
