<cm-infinite-list [count]="totalCount" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
    <cm-list-info
        cm-list-header
        [parentList]="this"
        [enableBatchUpdates]="$can().update.model(null, 'batch')"
        [stateLabels]="stateLabels"
        [count]="totalCount"
        (createItemEvent)="openNewItemDialog()"
    ></cm-list-info>
    @for (item of items; track item) {
        @if (item.data; as model) {
            <cm-entity-card @fadeInCard [$entity]="model" [entityLink]="['/models', model.id]" [entityQueryParamsHandling]="'preserve'">
                @if ($can().read.model(model, "tags")) {
                    <cm-tag-labels cm-top-left [tags]="getTags(model)"></cm-tag-labels>
                }
                <ng-container cm-bottom-left>
                    @if ($can().read.model(model, "paymentState")) {
                        <cm-payment-state-label
                            [item]="{id: model.id, __typename: 'Model'}"
                            [editable]="true"
                            (stateChange)="updateItem({id: model.id, paymentState: $event})"
                        >
                        </cm-payment-state-label>
                    }
                    <cm-state-label
                        [stateLabels]="Labels.ModelState"
                        [state]="model.state"
                        [backgroundColor]="auth.$actingAsCustomer() && model.nextActor === Enums.NextActor.Customer ? '#f13536' : undefined"
                        [editable]="$can().update.model(model, 'state')"
                        (stateChange)="updateItem({id: model.id, state: $event})"
                    >
                    </cm-state-label>
                </ng-container>
                @if ($can().create.userAssignment()) {
                    <cm-assign-user
                        cm-bottom-right
                        (click)="$event.stopPropagation()"
                        [matMenuTriggerFor]="userMenu"
                        [matMenuTriggerData]="{entity: model}"
                        [$user]="model.currentUserAssignment"
                        [nextActor]="model.nextActor"
                    >
                    </cm-assign-user>
                }
                <ng-container cm-subtitle>
                    @if ($can().read.model(model, "commentBoxes")) {
                        <cm-comment-boxes [entity]="model"></cm-comment-boxes>
                    }
                </ng-container>
            </cm-entity-card>
        } @else if (item.placeholder) {
            <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
        } @else if (item.error) {
            <cm-card-error @fadeInPlaceholder></cm-card-error>
        }
    }
</cm-infinite-list>
<mat-menu #userMenu [overlapTrigger]="false" [hasBackdrop]="true">
    <ng-template matMenuContent let-entity="entity">
        <cm-assign-user-dialog
            [$user]="entity.currentUserAssignment"
            [nextActor]="entity.nextActor"
            (userChange)="updateAssignedUser(Enums.ContentTypeModel.Model, entity, $event)"
            (nextActorChange)="updateItem({id: entity.id, nextActor: $event})"
        ></cm-assign-user-dialog>
    </ng-template>
</mat-menu>
<ng-template #newItemDialog>
    <cm-add-model-dialog [addModel]="createItem.bind(this)"></cm-add-model-dialog>
</ng-template>
