export function parseOtherInfo(info?: {
    host_name?: string
    num_physical_cpus?: number
    os_version?: string
    total_memory?: number
    gpu_name?: string | undefined
    gpu_total_memory?: number | undefined
}):
    | {
          hostName: string | undefined
          numCpuCores: number | undefined
          osVersion: string | undefined
          totalMemoryGiB: number | undefined
          gpuName: string | undefined
          gpuTotalMemoryGiB: number | undefined
      }
    | undefined {
    if (!info) return undefined
    return {
        hostName: info.host_name as string | undefined,
        numCpuCores: info.num_physical_cpus as number | undefined,
        osVersion: info.os_version as string | undefined,
        totalMemoryGiB: info.total_memory ? Math.round(info.total_memory / (1024 * 1024 * 1024)) : undefined,
        gpuName: info.gpu_name as string | undefined,
        gpuTotalMemoryGiB: info.gpu_total_memory ? Math.round(info.gpu_total_memory / (1024 * 1024 * 1024)) : undefined,
    }
}
