<div id="job-state-tooltip" class="cm-job-state-tooltip">
    @if ($job()?.started) {
        <span class="state--light"> Started on {{ formatForDisplay($job().started, {shorten: false}) }}</span>
    }
    <span [class]="jobStateColor($job()?.state)"
        ><i class="fa" [class]="jobStateIconClass($job())"></i>&nbsp;
        @switch ($job()?.state) {
            @case (JobState.Complete) {
                <strong>Completed</strong>
                @if ($job().started && $job().finished) {
                    after {{ formatTimeDifference($job().started, $job().finished) }}
                }
            }
            @case (JobState.Cancelled) {
                <strong>Cancelled</strong>
                @if ($job().started && $job().cancelled) {
                    after {{ formatTimeDifference($job().started, $job().cancelled) }}
                }
            }
            @case (JobState.Failed) {
                <strong>Failed</strong>
                @if ($job().started && $job().finished) {
                    after {{ formatTimeDifference($job().started, $job().finished) }}
                }
            }
            @case (JobState.Init) {
                <strong>Init</strong>
            }
            @case (JobState.Running) {
                <strong>Running</strong>
                @if ($job()?.progress; as progress) {
                    @if (progress < 100) {
                        <div class="cm-simple-progress-container">
                            <div class="cm-simple-progress">
                                <div class="cm-simple-progress-bar" [style.width]="progress + '%'"></div>
                            </div>
                            <span class="state--light">{{ progress }}%</span>
                        </div>
                    }
                }
            }
            @case (JobState.Runnable) {
                <strong>Runnable</strong>
                @if ($job()?.progress; as progress) {
                    @if (progress < 100) {
                        <div class="cm-simple-progress-container">
                            <div class="cm-simple-progress">
                                <div class="cm-simple-progress-bar" [style.width]="progress + '%'"></div>
                            </div>
                            <span class="state--light">{{ progress }}%</span>
                        </div>
                    }
                }
            }
            @case (JobState.Runnable) {
                <strong>Waiting for task runner</strong>
                @if ($job()?.progress; as progress) {
                    @if (progress < 100) {
                        <div class="cm-simple-progress-container">
                            <div class="cm-simple-progress">
                                <div class="cm-simple-progress-bar" [style.width]="progress + '%'"></div>
                            </div>
                            <span class="state--light">{{ progress }}%</span>
                        </div>
                    }
                }
            }
        }
    </span>
    @if ($job()?.state === JobState.Init) {
        <span class="state--light">Waiting to be started automatically</span>
    }
</div>
