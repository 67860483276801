@if ($textureGroup(); as textureGroup) {
    @if ($can().read.textureSet({textureGroup})) {
        <cm-section>
            <ng-container cm-icon><i class="far fa-game-board-alt"></i></ng-container>
            <ng-container cm-title>
                <div class="cm-texture-sets-header">
                    Texture sets
                    <i class="far fa-plus cm-add-texture cm-button" (click)="addTextureSet()" matTooltip="Add texture set" matTooltipPosition="above"> </i>
                </div>
            </ng-container>
            <ng-container cm-content>
                <div class="cm-texture-sets">
                    @for (textureSet of textureGroup.textureSets; track textureSet) {
                        <div class="cm-texture-set">
                            <cm-texture-set [textureSetId]="textureSet.id">
                                <ng-container cm-content>
                                    <cm-scan-job [textureSetId]="textureSet.id"></cm-scan-job>
                                </ng-container>
                            </cm-texture-set>
                            @if ($showAssociatedJobs() && $can().read.job(null, "tiling")) {
                                <div class="cm-associated-jobs-header">Associated jobs</div>
                                <cm-associated-jobs-table
                                    [assignedTo]="{id: textureSet.id, contentTypeModel: ContentTypeModel.TextureSet}"
                                ></cm-associated-jobs-table>
                            }
                        </div>
                    }
                </div>
            </ng-container>
        </cm-section>
    }
}
