import {Injectable, inject} from "@angular/core"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {cmRenderTaskForPassNames, RenderOutputForPassName, PictureRenderJobOutput, renderTaskQueueDomain} from "@cm/lib/job-task/rendering"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {RenderNodes} from "@cm/lib/rendering/render-nodes"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {Settings} from "@common/models/settings/settings"
import {buildRenderGraph} from "@cm/lib/rendering/scene-graph-to-render-graph"
import {mapLegacyDataObjectToImageResource} from "@app/templates/legacy/material-node-graph"

@Injectable({
    providedIn: "root",
})
export class RenderingService {
    private sdkService = inject(SdkService)
    private uploadGqlService = inject(UploadGqlService)

    async submitRenderJob(data: {nodes: SceneNodes.SceneNode[]; final: boolean; name: string; organizationLegacyId: number}) {
        const jobName = `RenderJob: ${data.name}`
        const {mainRenderTask, shadowMaskRenderTask, combinedRenderTask} = await this.createRenderTask(data)
        const jobGraph = JobNodes.jobGraph<PictureRenderJobOutput>(combinedRenderTask, {
            platformVersion: Settings.APP_VERSION,
            progress: JobNodes.progressGroupNoWeights(shadowMaskRenderTask ? [mainRenderTask, shadowMaskRenderTask] : [mainRenderTask], {
                output: mainRenderTask,
            }),
        })

        return this.sdkService.gql
            .renderingServiceCreateJob({
                input: {
                    name: jobName,
                    organizationLegacyId: data.organizationLegacyId,
                    graph: graphToJson(jobGraph),
                },
            })
            .then(({createJob}) => createJob)
    }

    async createRenderTask(data: {nodes: SceneNodes.SceneNode[]; final: boolean; name: string; organizationLegacyId: number}) {
        const resolveFns = {
            getHdriDataObjectIdDetails: async (hdriIdDetails: {legacyId: number}): Promise<{legacyId: number}> => {
                const dataObjectIdDetails = (await this.sdkService.gql.hdriForRendering(hdriIdDetails)).hdri.dataObject
                if (!dataObjectIdDetails) throw Error("Failed to query hdri data object id details")
                return dataObjectIdDetails
            },
            mapLegacyDataObjectToImageResource,
        }
        const mainRenderGraph = await buildRenderGraph({...data, verifySchema: true}, resolveFns)
        const mainRenderTask = await this._createRenderTask(mainRenderGraph, data)

        let shadowMaskRenderTask: JobNodes.TypedTaskNode<RenderOutputForPassName<"ShadowCatcher">> | null = null
        if (mainRenderGraph.session.options.passes?.includes("ShadowCatcher")) {
            const shadowMaskRenderGraph = await buildRenderGraph(
                {
                    ...data,
                    aoMaskPass: true,
                    verifySchema: true,
                },
                resolveFns,
            )
            shadowMaskRenderTask = await this._createRenderTask(shadowMaskRenderGraph, data, ["ShadowCatcher"])
        }

        return {
            mainRenderTask,
            shadowMaskRenderTask,
            combinedRenderTask: JobNodes.struct({
                renderPasses: JobNodes.get(mainRenderTask, "renderPasses"),
                preview: JobNodes.get(mainRenderTask, "preview"),
                metadata: JobNodes.get(mainRenderTask, "metadata"),
                aoShadowMaskPass: shadowMaskRenderTask
                    ? JobNodes.get(JobNodes.get(shadowMaskRenderTask, "renderPasses"), "ShadowCatcher")
                    : JobNodes.value(undefined),
            }),
        }
    }

    private async _createRenderTask<T extends RenderNodes.PassName>(
        graph: RenderNodes.Render,
        data: {
            organizationLegacyId: number
        },
        passes: T[] = [],
    ) {
        const uploadResult = await this.uploadGqlService.createAndUploadDataObject(
            new File([JSON.stringify(graphToJson(graph))], "render.json", {type: "application/json"}),
            {
                organizationLegacyId: data.organizationLegacyId,
            },
            {showUploadToolbar: false, processUpload: true},
        )

        return JobNodes.task(cmRenderTaskForPassNames(...passes), {
            input: JobNodes.value({
                renderGraph: JobNodes.dataObjectReference(uploadResult.legacyId),
                customerId: data.organizationLegacyId,
            }),
            queueDomain: renderTaskQueueDomain(graph.session.options.gpu, graph.session.options.cloud),
        })
    }
}
