import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {ImageDescriptorWithOptionals, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {Vector2Like} from "@cm/lib/math/vector2"
import {ImageProcessingNodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ColorLike} from "@cm/lib/math"
import {isHalImageDescriptor} from "@common/helpers/hal"
import {getHalAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {assertBatchSizeOne} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

export type Vertex = {
    position: Vector2Like // in pixel space
    uv?: Vector2Like // in pixel space; default: {x: 0, y: 0}
    color?: ColorLike // default: {r: 1, g: 1, b: 1, a: 1}
}

export type ParameterType = {
    topology: "triangleList"
    vertices: Vertex[]
    indices?: number[] // default: [0, 1, 2, 3, 4, 5, ...]
    textureImage?: ImageRef
    addressMode?: AddressMode // default: "wrap"
    resultImageOrDescriptor: ImageRef | ImageDescriptorWithOptionals
}

export type ReturnType = ImageRef

export const imageOpRasterizeGeometry: ImageOpType<ParameterType, ReturnType> = {
    name: "RasterizeGeometry",

    WebGL2: ({cmdQueue, parameters: {topology, vertices, indices, textureImage, addressMode, resultImageOrDescriptor}}) => {
        addressMode ??= "wrap"
        if (topology !== "triangleList") {
            throw new Error("Only triangleList topology is supported in WebGL2")
        }
        const painter = cmdQueue.createPainter(
            "primitive",
            "rasterizeGeometry",
            `
            vec4 computeColor(vec2 worldPosition, vec2 uv, vec4 color) {
                ${textureImage ? `color *= texelFetchInterpolated0(uv, ${getHalAddressMode(addressMode)});` : ""}
                return color;
            }
        `,
        )
        const resultImage = isHalImageDescriptor(resultImageOrDescriptor) ? cmdQueue.createImage(resultImageOrDescriptor) : resultImageOrDescriptor
        assertBatchSizeOne(resultImage.descriptor)
        const vertexPositions = vertices.map((vertex) => vertex.position)
        const vertexUVs = vertices.map((vertex) => vertex.uv ?? {x: 0, y: 0})
        const vertexColors = vertices.map((vertex) => vertex.color ?? {r: 1, g: 1, b: 1, a: 1})
        indices ??= new Array(vertices.length).fill(0).map((_, index) => index)
        cmdQueue.paint(painter, {
            vertices: {
                positions: vertexPositions,
                uvs: vertexUVs,
                colors: vertexColors,
            },
            indices,
            sourceImages: textureImage,
            resultImage,
        })
        return resultImage
    },

    ImgProc: ({cmdQueue, parameters: {topology, vertices, indices, textureImage, addressMode, resultImageOrDescriptor}}) => {
        addressMode ??= "wrap"
        if (topology !== "triangleList") {
            throw new Error("Only triangleList topology is supported in ImgProc")
        }
        if (addressMode !== "wrap") {
            throw new Error("Only wrap address mode is supported in ImgProc")
        }
        const imgProcVertices: ImageProcessingNodes.Vertex[] = vertices.map((vertex) => ({
            position: [vertex.position.x, vertex.position.y],
            uv: vertex.uv ? [vertex.uv.x, vertex.uv.y] : undefined,
            color: vertex.color ? [vertex.color.r, vertex.color.g, vertex.color.b, vertex.color.a ?? 1] : undefined,
        }))
        const resultImage = isHalImageDescriptor(resultImageOrDescriptor) ? cmdQueue.createImage(resultImageOrDescriptor) : resultImageOrDescriptor
        return cmdQueue.createImage(resultImage.descriptor, {
            type: "rasterizeGeometry",
            topology: "triangleList",
            vertices: imgProcVertices,
            indices,
            texture: textureImage,
            target: resultImage,
        })
    },
}

export function rasterizeGeometry(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpRasterizeGeometry, parameters)
}
