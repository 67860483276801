import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {Subscription, from, map} from "rxjs"

const TD = TypeDescriptors

const loadMaterialRevisionNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    materialRevisionId: TD.inlet(TD.Number),
    materialGraph: TD.outlet(TD.MaterialGraph),
}

export class LoadMaterialRevisionNew implements NodeClassImpl<typeof loadMaterialRevisionNewDescriptor, typeof LoadMaterialRevisionNew> {
    static descriptor = loadMaterialRevisionNewDescriptor
    static uniqueName = "LoadMaterialRevision"
    sceneManager!: Inlet<ISceneManagerNew>
    materialRevisionId!: Inlet<number>
    materialGraph!: Outlet<IMaterialGraph>

    private pending: Subscription | null = null

    run() {
        this.materialGraph.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.materialRevisionId === NotReady) {
            return
        }

        this.pending = this.sceneManager.addTaskNew(
            `loadMaterial(${this.materialRevisionId})`,
            from(this.sceneManager.loadMaterialNew(this.materialRevisionId)).pipe(
                map((materialGraph) => {
                    this.pending = null
                    this.materialGraph.emitIfChanged(materialGraph)
                }),
            ),
            true,
        )
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
