import {Component, inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatOptionModule} from "@angular/material/core"
import {MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, MutationUpdateTagInput, TagDetailsItemFragment} from "@api"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {DialogSize} from "@common/models/dialogs"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {SectionComponent} from "@common/components/item/item-details/section/section.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {ColorInputComponent} from "@common/components/inputs/color-input/color-input.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {IsLoadingDirective} from "@common/directives"
import {TagAssignmentsComponent} from "@platform/components/tags/tag-assignments/tag-assignments.component"
import {TagThumbnailComponent} from "@platform/components/tags/tag-thumbnail/tag-thumbnail.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"

@Component({
    imports: [
        DetailsDialogLayoutComponent,
        MatButtonModule,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        FullPageFeedbackComponent,
        TagThumbnailComponent,
        InputContainerComponent,
        IsLoadingDirective,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        FormsModule,
        TagAssignmentsComponent,
        ColorInputComponent,
        NativeInputTextAreaComponent,
        StringInputComponent,
        SectionComponent,
        OrganizationSelectComponent,
        RoutedDialogComponent,
        TitleSectionComponent,
        PlaceholderComponent,
    ],
    selector: "cm-tag-details",
    standalone: true,
    styleUrl: "./tag-details.component.scss",
    templateUrl: "./tag-details.component.html",
})
export class TagDetailsComponent extends BaseDetailsComponent<TagDetailsItemFragment, Omit<MutationUpdateTagInput, "id">> {
    override _contentTypeModel = ContentTypeModel.Tag
    override _fetchItem = this.sdk.gql.getTagDetails
    override _updateItem = this.sdk.gql.updateTagDetails

    organizations = inject(OrganizationsService)
    protected readonly DialogSize = DialogSize
}
