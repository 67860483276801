<cm-infinite-list [count]="totalCount" [intersectionStatusChange]="pageFilledStatusChange" class="cm-items-list">
    <cm-list-info cm-list-header [parentList]="this" [count]="totalCount"></cm-list-info>
    @for (item of items; track item) {
        @if (item.data; as file) {
            <cm-entity-card
                @fadeInCard
                [$entity]="provideWithGalleryImageField | memoize: file : file"
                [entityLink]="['/files', file.id]"
                [entityQueryParamsHandling]="'preserve'"
            >
                <ng-container cm-bottom-left>
                    <cm-state-label
                        [stateLabels]="Labels.DataObjectState"
                        [(state)]="file.state"
                        [editable]="$can().read.dataObject(file, 'state')"
                        (stateChange)="updateItem({id: file.id, state: $event})"
                    >
                    </cm-state-label>
                </ng-container>
            </cm-entity-card>
        } @else if (item.placeholder) {
            <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
        } @else if (item.error) {
            <cm-card-error @fadeInPlaceholder></cm-card-error>
        }
    }
</cm-infinite-list>
<router-outlet></router-outlet>
