<div class="cm-node-flex-container">
    @if ($amountGroupNode()) {
        <div class="cm-node-icon" matTooltip="Amount Group" matTooltipPosition="above">
            <i class="fa-solid fa-hashtag"></i>
            <i class="fa-solid fa-hashtag"></i>
        </div>
        <span class="cm-node-text">{{ $description() }}</span>
        <div class="cm-tools-right">
            <input class="cm-input-width-limit" type="number" min="0" [(ngModel)]="$amountGroupNode().parameters.defaultAmount" />
        </div>
    }
</div>
