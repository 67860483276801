import {Component, inject, Inject} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {SdkService} from "@common/services/sdk/sdk.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {TextFieldModule} from "@angular/cdk/text-field"

@Component({
    selector: "cm-save-material-revision-dialog",
    templateUrl: "./save-material-revision-dialog.component.html",
    styleUrls: ["./save-material-revision-dialog.component.scss"],
    standalone: true,
    imports: [MatDialogContent, MatDialogTitle, MatInputModule, FormsModule, MatDialogActions, MatButtonModule, TextFieldModule],
})
export class SaveMaterialRevisionDialogComponent {
    sdk = inject(SdkService)

    result: SaveMaterialRevisionDialogResult
    materialInfo: {
        name: string
        legacyId: number
        customer?: string
        range?: string
    } | null = null

    permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(
        public dialogRef: MatDialogRef<SaveMaterialRevisionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {materialId: string},
    ) {
        if (!data) {
            throw new Error("No material id!")
        }
        this.result = {materialId: data.materialId, comment: ""}
        void this.materialChanged(data.materialId)
    }

    async materialChanged(materialId?: string, materialLegacyId?: number) {
        if (!materialId && !materialLegacyId) {
            this.materialInfo = null
            return
        }
        const material = await this.sdk.gql
            .saveMaterialRevisionDialogMaterial({id: materialId, legacyId: materialLegacyId})
            .then((response) => response.material)
            .catch(() => null)

        if (!material) {
            if (materialLegacyId) {
                this.materialInfo = {name: "", legacyId: materialLegacyId}
            } else {
                this.materialInfo = null
            }
            return
        }
        const materialRangeTag = material.tagAssignments[0]?.tag?.name
        this.materialInfo = {
            name: material.name ?? "",
            legacyId: material.legacyId,
            range: materialRangeTag ?? "",
            customer: material.organization?.name ?? undefined,
        }
        this.result.materialId = material.id
    }

    cancelAction(): void {
        this.dialogRef.close(false)
    }

    confirmAction(): void {
        this.dialogRef.close(this.result)
    }
}

export type SaveMaterialRevisionDialogResult = {
    materialId: string
    comment: string
}
