import {differenceInDays} from "date-fns/differenceInDays"
import {differenceInHours} from "date-fns/differenceInHours"
import {differenceInMinutes} from "date-fns/differenceInMinutes"
import {differenceInSeconds} from "date-fns/differenceInSeconds"
import {formatDate} from "date-fns/format"
import {isSameYear} from "date-fns/isSameYear"
import {isToday} from "date-fns/isToday"
import {isTomorrow} from "date-fns/isTomorrow"
import {isYesterday} from "date-fns/isYesterday"

export const formatForDisplay = (date?: string | Date | null, options: {shorten: boolean} = {shorten: true}) => {
    if (date) {
        if (options.shorten) {
            if (isToday(date)) {
                return formatDate(date, "HH:mm")
            }
            if (isYesterday(date)) {
                return `yesterday at ${formatDate(date, "HH:mm")}`
            }
            if (isTomorrow(date)) {
                return `tomorrow at ${formatDate(date, "HH:mm")}`
            }
            if (isSameYear(date, new Date())) {
                return `${formatDate(date, "EEEEEE MMM")} ${formatDate(date, "HH:mm")}`
            }
        }
        return `${formatDate(date, "EEEEEE MMM yy")} at ${formatDate(date, "HH:mm")}`
    }
    return null
}

export const formatTimeDifference = (fromDate?: string | Date, toDate?: string | Date) => {
    if (!fromDate || !toDate) {
        return null
    }
    const days = differenceInDays(toDate, fromDate)
    const hours = differenceInHours(toDate, fromDate)
    const minutes = differenceInMinutes(toDate, fromDate)
    const seconds = differenceInSeconds(toDate, fromDate)
    if (days > 0) {
        return `${days}d ${hours}h ${seconds}s`
    }
    if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`
    }
    if (minutes > 0) {
        return `${minutes}m ${seconds}s`
    }
    return `${seconds}s`
}

export const stringToDate = (dateOrString: string | Date | null | undefined): Date | undefined => {
    if (dateOrString) {
        if (dateOrString instanceof Date) {
            return dateOrString
        }
        return new Date(dateOrString)
    }
    return undefined
}
