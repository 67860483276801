import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode, colorValue} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {Render} from "@src/templates/nodes/render"
import {NumberLike, numberLike} from "@src/templates/node-types"
import {z} from "zod"
import {VisitorNodeVersion, skipped, visitNone} from "@src/graph-system/declare-visitor-node"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {ThisStructID} from "@src/templates/runtime-graph/types"
import {nodeInstance} from "@src/graph-system/instance"
import {versionChain} from "@src/graph-system/node-graph"

export const filmicToneMapping = z.object({
    mode: z.literal("filmic"),
})
export type FilmicToneMapping = z.infer<typeof filmicToneMapping>

export const filmicAdvancedToneMapping = z.object({
    mode: z.literal("filmic-advanced"),
    contrast: z.number(),
    balance: z.number(),
    colorBalance: z.number(),
})
export type FilmicAdvancedToneMapping = z.infer<typeof filmicAdvancedToneMapping>

export const contrastToneMapping = z.object({
    mode: z.literal("contrast"),
    contrast: z.number(),
    balance: z.number(),
    colorBalance: z.number(),
})
export type ContrastToneMapping = z.infer<typeof contrastToneMapping>

export const coronaToneMapping = z.object({
    mode: z.literal("corona"),
    highlightCompression: z.number(),
    contrast: z.number(),
    saturation: z.number(),
})
export type CoronaToneMapping = z.infer<typeof coronaToneMapping>

export const pbrNeutralToneMapping = z.object({
    mode: z.literal("pbr-neutral"),
})
export type PBRNeutralToneMapping = z.infer<typeof pbrNeutralToneMapping>

export const linearToneMapping = z.object({
    mode: z.literal("linear"),
})
export type LinearToneMapping = z.infer<typeof linearToneMapping>

const isRgbCurveMapping = (value: {
    mode: "rgbCurve"
    parameters: {
        [key: string]: [number, number] | number
    }
}): value is RGBCurveMapping => {
    return typeof value.parameters.fac === "number"
}
export const rgbCurveMapping = z
    .object({
        mode: z.literal("rgbCurve"),
        parameters: z.record(z.union([z.number(), z.tuple([z.number(), z.number()])])), //e.g. "internal.mapping.curves[0].points[0].location": [number, number],
    })
    .refine(isRgbCurveMapping, {message: "Invalid rgbCurve mapping"})
export type RGBCurveMapping = {
    mode: "rgbCurve"
    parameters: {
        fac: number
        [key: string]: [number, number] | number
        //e.g. "internal.mapping.curves[0].points[0].location": [number, number]
    }
}

export const hueSaturationMapping = z.object({
    mode: z.literal("hueSaturation"),
    parameters: z.object({
        fac: z.number(),
        hue: z.number(),
        saturation: z.number(),
        value: z.number(),
    }),
})
export type HueSaturationMapping = z.infer<typeof hueSaturationMapping>

export const toneMapping = z.union([
    filmicToneMapping,
    contrastToneMapping,
    coronaToneMapping,
    pbrNeutralToneMapping,
    linearToneMapping,
    filmicAdvancedToneMapping,
    rgbCurveMapping,
    hueSaturationMapping,
])
export type ToneMapping = z.infer<typeof toneMapping>

const postProcessRenderParameters = z.object({
    render: nodeInstance(Render).optional(),
    mode: z.literal("whiteBackground"),
    ev: numberLike,
    whiteBalance: numberLike,
    toneMapping: toneMapping,
    lutUrl: z.string().optional(),
    transparent: z.boolean(),
    composite: z.boolean(),
    backgroundColor: colorValue,
    processShadows: z.boolean(),
    shadowInner: z.number(),
    shadowOuter: z.number(),
    shadowFalloff: z.number(),
    autoCrop: z.boolean(),
    autoCropMargin: z.number(),
})
export type PostProcessRenderParameters = z.infer<typeof postProcessRenderParameters>

type V0 = {
    render: Render | null
    mode: "whiteBackground"
    exposure: NumberLike
    whiteBalance: NumberLike
    toneMapping?: ToneMapping
    lutUrl?: string
    transparent: boolean
    composite: boolean
    backgroundColor: [number, number, number]
    processShadows: boolean
    shadowInner: number
    shadowOuter: number
    shadowFalloff: number
    autoCrop: boolean
    autoCropMargin: number
}

type V1 = V0 & {toneMapping: ToneMapping}
const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        const {toneMapping, ...rest} = parameters
        return {...rest, toneMapping: toneMapping ?? defaultsForToneMapping("filmic")} // filmic was the default in previous versions of the platform
    },
}

type V2 = Omit<V1, "exposure"> & {ev: NumberLike}
const v1: VisitorNodeVersion<V1, V2> = {
    toNextVersion: (parameters) => {
        const {exposure, ...rest} = parameters
        return {...rest, ev: exposure}
    },
}

type V3 = Omit<V2, "render"> & {render: Render | undefined}
const v2: VisitorNodeVersion<V2, V3> = {
    toNextVersion: (parameters) => {
        const {render, ...rest} = parameters
        return {...rest, render: render ?? undefined}
    },
}

@registerNode
export class PostProcessRender extends DeclareTemplateNode(
    {parameters: postProcessRenderParameters},
    {
        onVisited: {
            onCompile: function (this: Render, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {displayList} = currentTemplate
                const {templateScope} = evaluator
                const {
                    mode,
                    ev,
                    whiteBalance,
                    toneMapping,
                    lutUrl,
                    transparent,
                    composite,
                    backgroundColor,
                    processShadows,
                    shadowInner,
                    shadowOuter,
                    shadowFalloff,
                    autoCrop,
                    autoCropMargin,
                } = parameters

                const scope = evaluator.getScope(this)

                const renderPostProcessingSettings = scope.struct<SceneNodes.RenderPostProcessingSettings>("RenderPostProcessingSettings", {
                    id: ThisStructID,
                    type: "RenderPostProcessingSettings",
                    mode,
                    exposure: scope.lambda(evaluator.evaluateNumber(scope, ev), (ev) => Math.pow(2, ev ?? 0), "exposure"),
                    whiteBalance: scope.lambda(evaluator.evaluateNumber(scope, whiteBalance), (whiteBalance) => whiteBalance ?? undefined, "whiteBalance"),
                    toneMapping,
                    lutUrl,
                    transparent,
                    composite,
                    backgroundColor,
                    processShadows,
                    shadowInner,
                    shadowOuter,
                    shadowFalloff,
                    autoCrop,
                    autoCropMargin,
                })

                displayList.push(renderPostProcessingSettings)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "PostProcessRender", versionChain: versionChain([v0, v1, v2])},
) {}

export type PostProcessRenderFwd = TemplateNode<PostProcessRenderParameters>

export function defaultsForToneMapping(mode: ToneMapping["mode"]): ToneMapping {
    if (mode === "filmic") return {mode: "filmic"}
    else if (mode === "filmic-advanced") return {mode, contrast: 1, balance: 0.5, colorBalance: 0}
    else if (mode === "linear") return {mode}
    else if (mode === "contrast") return {mode, contrast: 1, balance: 0.5, colorBalance: 0}
    else if (mode === "corona") return {mode, highlightCompression: 1, contrast: 1, saturation: 0}
    else if (mode === "pbr-neutral") return {mode}
    else if (mode === "rgbCurve") return {mode, parameters: {fac: 1}}
    else if (mode === "hueSaturation") return {mode, parameters: {fac: 1, hue: 0, saturation: 0, value: 0}}
    else throw new Error("Invalid tone mapping mode")
}

export function schemaForToneMapping(mode: ToneMapping["mode"]): z.ZodTypeAny {
    if (mode === "filmic") return filmicToneMapping
    else if (mode === "filmic-advanced") return filmicAdvancedToneMapping
    else if (mode === "linear") return linearToneMapping
    else if (mode === "contrast") return contrastToneMapping
    else if (mode === "corona") return coronaToneMapping
    else if (mode === "pbr-neutral") return pbrNeutralToneMapping
    else if (mode === "rgbCurve") return rgbCurveMapping
    else if (mode === "hueSaturation") return hueSaturationMapping
    else throw new Error("Invalid tone mapping mode")
}
