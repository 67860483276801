import {Size2Like, Vector2Like} from "ts-lib/dist/browser/math"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {rasterizeGeometry, Vertex} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-rasterize-geometry"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

const SCOPE_NAME = "GridMapping"

export type GridPoint = {
    sourcePixel: Vector2Like
    targetPixel: Vector2Like
}

export type ParameterType = {
    sourceImage: ImageRef
    gridPoints: GridPoint[][] // 2D array of grid points (inner array = x-axis, outer array = y-axis)
    addressMode?: AddressMode // default: "wrap"
    resultSize?: Size2Like // default: computed from targetPixel in gridPoints
    resultImage?: ImageRef
}

export type ReturnType = ImageRef

export function gridMapping(cmdQueue: ImageOpCommandQueue, {sourceImage, gridPoints, resultSize, addressMode, resultImage}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)
    const geometry = (() => {
        const vertices: Vertex[] = []
        const indices: number[] = []
        const numGridPointsX = gridPoints[0].length
        const numGridPointsY = gridPoints.length
        for (let y = 0; y < numGridPointsY; y++) {
            for (let x = 0; x < numGridPointsX; x++) {
                // vertex
                const point = gridPoints[y][x]
                vertices.push({
                    position: point.targetPixel,
                    uv: point.sourcePixel,
                })
                // index
                if (x > 0 && y > 0) {
                    const i0 = (y - 1) * numGridPointsX + x - 1
                    const i1 = i0 + 1
                    const i2 = i0 + numGridPointsX
                    const i3 = i0 + numGridPointsX + 1
                    indices.push(i0, i1, i2, i1, i3, i2)
                }
            }
        }
        return {vertices, indices}
    })()
    const resultImageOrDescriptorNode = (() => {
        if (resultImage && resultSize) {
            throw new Error("Only one of resultImage and resultSize can be specified")
        }
        if (resultImage) {
            return resultImage
        }
        const sourceImageDescriptor = sourceImage.descriptor
        if (resultSize) {
            return {
                ...sourceImageDescriptor,
                ...resultSize,
            }
        }
        // compute resultSize from gridPoints
        let maxX = Number.NEGATIVE_INFINITY
        let maxY = Number.NEGATIVE_INFINITY
        for (const row of gridPoints) {
            for (const point of row) {
                maxX = Math.max(maxX, Math.round(point.targetPixel.x))
                maxY = Math.max(maxY, Math.round(point.targetPixel.y))
            }
        }
        return {...sourceImageDescriptor, width: maxX, height: maxY}
    })()
    const result = rasterizeGeometry(cmdQueue, {
        topology: "triangleList",
        vertices: geometry.vertices,
        indices: geometry.indices,
        textureImage: sourceImage,
        addressMode: addressMode,
        resultImageOrDescriptor: resultImageOrDescriptorNode,
    })
    cmdQueue.endScope(SCOPE_NAME)
    return result
}
