import {Pipe, PipeTransform} from "@angular/core"
import {stringToDate} from "@common/helpers/utils/dates"
import {formatDistanceToNow} from "date-fns/formatDistanceToNow"

@Pipe({
    name: "cmTimeAgo",
    standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
    transform(dateOrString: string | Date | null | undefined): string | undefined {
        const date = stringToDate(dateOrString)
        if (date) {
            return formatDistanceToNow(date, {addSuffix: true})
        }
        return undefined
    }
}
