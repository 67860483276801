export type ColorLike = {r: number; g: number; b: number; a?: number}

export const isColorLike = (obj: unknown): obj is ColorLike => {
    return typeof obj === "object" && obj !== null && "r" in obj && "g" in obj && "b" in obj
}

export class Color {
    r: number
    g: number
    b: number
    a: number

    constructor(r?: number, g?: number, b?: number, a?: number) {
        this.r = r ?? 0
        this.g = g ?? this.r
        this.b = b ?? this.r
        this.a = a ?? 1
    }

    set(r: number, g: number, b: number, a = 1) {
        this.r = r
        this.g = g
        this.b = b
        this.a = a
    }

    setFromColorLike(v: ColorLike) {
        this.r = v.r
        this.g = v.g
        this.b = v.b
        this.a = v.a ?? 1
    }

    toArray(): [number, number, number, number] {
        return [this.r, this.g, this.b, this.a]
    }

    static fromArray(a: number[]) {
        return new this(a[0], a[1], a[2], a[3])
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    static fromColorLike(v: ColorLike) {
        return new this(v.r, v.g, v.b, v.a ?? 1)
    }

    equals(other: Color) {
        return this.r == other.r && this.g == other.g && this.b == other.b && this.a == other.a
    }

    copy(): Color {
        return new Color(this.r, this.g, this.b, this.a)
    }

    toUintARGB(): number {
        const r = Math.max(0, Math.min(1, this.r))
        const g = Math.max(0, Math.min(1, this.g))
        const b = Math.max(0, Math.min(1, this.b))
        const a = Math.max(0, Math.min(1, this.a))
        return ((a * 255) << 24) | ((r * 255) << 16) | ((g * 255) << 8) | (b * 255)
    }

    add(b: Color): Color {
        return new Color(this.r + b.r, this.g + b.g, this.b + b.b, this.a + b.a)
    }

    addInPlace(b: Color): Color {
        this.r += b.r
        this.g += b.g
        this.b += b.b
        this.a += b.a
        return this
    }

    sub(b: Color): Color {
        return new Color(this.r - b.r, this.g - b.g, this.b - b.b, this.a - b.a)
    }

    mul(s: number): Color {
        return new Color(this.r * s, this.g * s, this.b * s, this.a * s)
    }

    mulInPlace(s: ColorLike | number): Color {
        if (typeof s === "number") {
            this.r *= s
            this.g *= s
            this.b *= s
            this.a *= s
        } else {
            this.r *= s.r
            this.g *= s.g
            this.b *= s.b
            this.a *= s.a ?? 1
        }
        return this
    }
}
