import {Vector3} from "@src/math/vector3"
import {Quaternion} from "@src/math/quaternion"

export type Matrix4Like = {
    m11: number
    m12: number
    m13: number
    m14: number
    m21: number
    m22: number
    m23: number
    m24: number
    m31: number
    m32: number
    m33: number
    m34: number
    m41: number
    m42: number
    m43: number
    m44: number
}

export class Matrix4 {
    elements: number[]

    constructor(elements?: ArrayLike<number>) {
        this.elements = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
        if (elements !== undefined) {
            if (elements.length !== 16) {
                throw new Error("Matrix4 must have exactly 16 elements!")
            }
            for (let n = 0; n < 16; n++) {
                this.elements[n] = elements[n]
            }
        }
    }

    static identity(): Matrix4 {
        return new Matrix4()
    }

    toArray(): number[] {
        return this.elements
    }

    static fromArray(elements: ArrayLike<number>) {
        return new this(elements)
    }

    toArrayTransposed(): number[] {
        const e = this.elements
        return [e[0], e[4], e[8], e[12], e[1], e[5], e[9], e[13], e[2], e[6], e[10], e[14], e[3], e[7], e[11], e[15]]
    }

    static fromArrayTransposed(elements: ArrayLike<number>) {
        const e = elements
        return new this([e[0], e[4], e[8], e[12], e[1], e[5], e[9], e[13], e[2], e[6], e[10], e[14], e[3], e[7], e[11], e[15]])
    }

    // TODO is this the right order ?
    static fromMatrix4Like(m: Matrix4Like) {
        return new this([m.m11, m.m12, m.m13, m.m14, m.m21, m.m22, m.m23, m.m24, m.m31, m.m32, m.m33, m.m34, m.m41, m.m42, m.m43, m.m44])
    }

    determinant(): number {
        const e = this.elements
        const n11 = e[0],
            n21 = e[1],
            n31 = e[2],
            n41 = e[3]
        const n12 = e[4],
            n22 = e[5],
            n32 = e[6],
            n42 = e[7]
        const n13 = e[8],
            n23 = e[9],
            n33 = e[10],
            n43 = e[11]
        const n14 = e[12],
            n24 = e[13],
            n34 = e[14],
            n44 = e[15]
        const t11 = n23 * n34 * n42 - n24 * n33 * n42 + n24 * n32 * n43 - n22 * n34 * n43 - n23 * n32 * n44 + n22 * n33 * n44
        const t12 = n14 * n33 * n42 - n13 * n34 * n42 - n14 * n32 * n43 + n12 * n34 * n43 + n13 * n32 * n44 - n12 * n33 * n44
        const t13 = n13 * n24 * n42 - n14 * n23 * n42 + n14 * n22 * n43 - n12 * n24 * n43 - n13 * n22 * n44 + n12 * n23 * n44
        const t14 = n14 * n23 * n32 - n13 * n24 * n32 - n14 * n22 * n33 + n12 * n24 * n33 + n13 * n22 * n34 - n12 * n23 * n34
        return n11 * t11 + n21 * t12 + n31 * t13 + n41 * t14
    }

    decompose(): {position: Vector3; quaternion: Quaternion; scale: Vector3} {
        const e = this.elements

        let sx = Math.sqrt(e[0] * e[0] + e[1] * e[1] + e[2] * e[2])
        const sy = Math.sqrt(e[4] * e[4] + e[5] * e[5] + e[6] * e[6])
        const sz = Math.sqrt(e[8] * e[8] + e[9] * e[9] + e[10] * e[10])
        if (this.determinant() < 0) sx = -sx

        const isx = 1 / sx
        const isy = 1 / sy
        const isz = 1 / sz

        // extract (normalized) rotation matrix
        const m11 = e[0] * isx,
            m12 = e[4] * isy,
            m13 = e[8] * isz,
            m21 = e[1] * isx,
            m22 = e[5] * isy,
            m23 = e[9] * isz,
            m31 = e[2] * isx,
            m32 = e[6] * isy,
            m33 = e[10] * isz,
            trace = m11 + m22 + m33

        let qw!: number
        let qx!: number
        let qy!: number
        let qz!: number

        if (trace > 0) {
            const s = 0.5 / Math.sqrt(trace + 1.0)
            qw = 0.25 / s
            qx = (m32 - m23) * s
            qy = (m13 - m31) * s
            qz = (m21 - m12) * s
        } else if (m11 > m22 && m11 > m33) {
            const s = 2.0 * Math.sqrt(1.0 + m11 - m22 - m33)
            qw = (m32 - m23) / s
            qx = 0.25 * s
            qy = (m12 + m21) / s
            qz = (m13 + m31) / s
        } else if (m22 > m33) {
            const s = 2.0 * Math.sqrt(1.0 + m22 - m11 - m33)
            qw = (m13 - m31) / s
            qx = (m12 + m21) / s
            qy = 0.25 * s
            qz = (m23 + m32) / s
        } else {
            const s = 2.0 * Math.sqrt(1.0 + m33 - m11 - m22)
            qw = (m21 - m12) / s
            qx = (m13 + m31) / s
            qy = (m23 + m32) / s
            qz = 0.25 * s
        }

        return {
            position: new Vector3(e[12], e[13], e[14]),
            quaternion: new Quaternion(qx, qy, qz, qw),
            scale: new Vector3(sx, sy, sz),
        }
    }

    static compose(position: Vector3, quaternion: Quaternion, scale: Vector3): Matrix4 {
        const matrix = new Matrix4()
        const e = matrix.elements
        const x = quaternion.x,
            y = quaternion.y,
            z = quaternion.z,
            w = quaternion.w
        const x2 = x + x,
            y2 = y + y,
            z2 = z + z
        const xx = x * x2,
            xy = x * y2,
            xz = x * z2
        const yy = y * y2,
            yz = y * z2,
            zz = z * z2
        const wx = w * x2,
            wy = w * y2,
            wz = w * z2
        const sx = scale.x,
            sy = scale.y,
            sz = scale.z
        e[0] = (1 - (yy + zz)) * sx
        e[1] = (xy + wz) * sx
        e[2] = (xz - wy) * sx
        e[3] = 0
        e[4] = (xy - wz) * sy
        e[5] = (1 - (xx + zz)) * sy
        e[6] = (yz + wx) * sy
        e[7] = 0
        e[8] = (xz + wy) * sz
        e[9] = (yz - wx) * sz
        e[10] = (1 - (xx + yy)) * sz
        e[11] = 0
        e[12] = position.x
        e[13] = position.y
        e[14] = position.z
        e[15] = 1
        return matrix
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    equals(other: Matrix4 | THREE.Matrix4) {
        const ea = this.elements
        const eb = other.elements
        for (let n = 0; n < 16; n++) {
            if (ea[n] !== eb[n]) {
                return false
            }
        }
        return true
    }

    withinEpsilon(other: Matrix4 | THREE.Matrix4, eps: number) {
        const ea = this.elements
        const eb = other.elements
        for (let n = 0; n < 16; n++) {
            if (Math.abs(ea[n] - eb[n]) > eps) {
                return false
            }
        }
        return true
    }

    maxDifference(other: Matrix4 | THREE.Matrix4) {
        let md = 0
        const ea = this.elements
        const eb = other.elements
        for (let n = 0; n < 16; n++) {
            const d = Math.abs(ea[n] - eb[n])
            if (d > md) {
                md = d
            }
        }
        return md
    }

    multiply(other: Matrix4) {
        const ret = new Matrix4()
        const ae = this.elements
        const be = other.elements
        const re = ret.elements
        const a11 = ae[0],
            a12 = ae[4],
            a13 = ae[8],
            a14 = ae[12]
        const a21 = ae[1],
            a22 = ae[5],
            a23 = ae[9],
            a24 = ae[13]
        const a31 = ae[2],
            a32 = ae[6],
            a33 = ae[10],
            a34 = ae[14]
        const a41 = ae[3],
            a42 = ae[7],
            a43 = ae[11],
            a44 = ae[15]
        const b11 = be[0],
            b12 = be[4],
            b13 = be[8],
            b14 = be[12]
        const b21 = be[1],
            b22 = be[5],
            b23 = be[9],
            b24 = be[13]
        const b31 = be[2],
            b32 = be[6],
            b33 = be[10],
            b34 = be[14]
        const b41 = be[3],
            b42 = be[7],
            b43 = be[11],
            b44 = be[15]
        re[0] = a11 * b11 + a12 * b21 + a13 * b31 + a14 * b41
        re[4] = a11 * b12 + a12 * b22 + a13 * b32 + a14 * b42
        re[8] = a11 * b13 + a12 * b23 + a13 * b33 + a14 * b43
        re[12] = a11 * b14 + a12 * b24 + a13 * b34 + a14 * b44
        re[1] = a21 * b11 + a22 * b21 + a23 * b31 + a24 * b41
        re[5] = a21 * b12 + a22 * b22 + a23 * b32 + a24 * b42
        re[9] = a21 * b13 + a22 * b23 + a23 * b33 + a24 * b43
        re[13] = a21 * b14 + a22 * b24 + a23 * b34 + a24 * b44
        re[2] = a31 * b11 + a32 * b21 + a33 * b31 + a34 * b41
        re[6] = a31 * b12 + a32 * b22 + a33 * b32 + a34 * b42
        re[10] = a31 * b13 + a32 * b23 + a33 * b33 + a34 * b43
        re[14] = a31 * b14 + a32 * b24 + a33 * b34 + a34 * b44
        re[3] = a41 * b11 + a42 * b21 + a43 * b31 + a44 * b41
        re[7] = a41 * b12 + a42 * b22 + a43 * b32 + a44 * b42
        re[11] = a41 * b13 + a42 * b23 + a43 * b33 + a44 * b43
        re[15] = a41 * b14 + a42 * b24 + a43 * b34 + a44 * b44
        return ret
    }

    inverse(): Matrix4 {
        const ret = new Matrix4()
        const e = this.elements
        const re = ret.elements
        const n11 = e[0],
            n21 = e[1],
            n31 = e[2],
            n41 = e[3]
        const n12 = e[4],
            n22 = e[5],
            n32 = e[6],
            n42 = e[7]
        const n13 = e[8],
            n23 = e[9],
            n33 = e[10],
            n43 = e[11]
        const n14 = e[12],
            n24 = e[13],
            n34 = e[14],
            n44 = e[15]
        const t11 = n23 * n34 * n42 - n24 * n33 * n42 + n24 * n32 * n43 - n22 * n34 * n43 - n23 * n32 * n44 + n22 * n33 * n44
        const t12 = n14 * n33 * n42 - n13 * n34 * n42 - n14 * n32 * n43 + n12 * n34 * n43 + n13 * n32 * n44 - n12 * n33 * n44
        const t13 = n13 * n24 * n42 - n14 * n23 * n42 + n14 * n22 * n43 - n12 * n24 * n43 - n13 * n22 * n44 + n12 * n23 * n44
        const t14 = n14 * n23 * n32 - n13 * n24 * n32 - n14 * n22 * n33 + n12 * n24 * n33 + n13 * n22 * n34 - n12 * n23 * n34
        const det = n11 * t11 + n21 * t12 + n31 * t13 + n41 * t14
        if (det === 0) {
            console.warn("Could not compute matrix inverse!")
            return ret
        }
        const detInv = 1 / det
        re[0] = t11 * detInv
        re[1] = (n24 * n33 * n41 - n23 * n34 * n41 - n24 * n31 * n43 + n21 * n34 * n43 + n23 * n31 * n44 - n21 * n33 * n44) * detInv
        re[2] = (n22 * n34 * n41 - n24 * n32 * n41 + n24 * n31 * n42 - n21 * n34 * n42 - n22 * n31 * n44 + n21 * n32 * n44) * detInv
        re[3] = (n23 * n32 * n41 - n22 * n33 * n41 - n23 * n31 * n42 + n21 * n33 * n42 + n22 * n31 * n43 - n21 * n32 * n43) * detInv
        re[4] = t12 * detInv
        re[5] = (n13 * n34 * n41 - n14 * n33 * n41 + n14 * n31 * n43 - n11 * n34 * n43 - n13 * n31 * n44 + n11 * n33 * n44) * detInv
        re[6] = (n14 * n32 * n41 - n12 * n34 * n41 - n14 * n31 * n42 + n11 * n34 * n42 + n12 * n31 * n44 - n11 * n32 * n44) * detInv
        re[7] = (n12 * n33 * n41 - n13 * n32 * n41 + n13 * n31 * n42 - n11 * n33 * n42 - n12 * n31 * n43 + n11 * n32 * n43) * detInv
        re[8] = t13 * detInv
        re[9] = (n14 * n23 * n41 - n13 * n24 * n41 - n14 * n21 * n43 + n11 * n24 * n43 + n13 * n21 * n44 - n11 * n23 * n44) * detInv
        re[10] = (n12 * n24 * n41 - n14 * n22 * n41 + n14 * n21 * n42 - n11 * n24 * n42 - n12 * n21 * n44 + n11 * n22 * n44) * detInv
        re[11] = (n13 * n22 * n41 - n12 * n23 * n41 - n13 * n21 * n42 + n11 * n23 * n42 + n12 * n21 * n43 - n11 * n22 * n43) * detInv
        re[12] = t14 * detInv
        re[13] = (n13 * n24 * n31 - n14 * n23 * n31 + n14 * n21 * n33 - n11 * n24 * n33 - n13 * n21 * n34 + n11 * n23 * n34) * detInv
        re[14] = (n14 * n22 * n31 - n12 * n24 * n31 - n14 * n21 * n32 + n11 * n24 * n32 + n12 * n21 * n34 - n11 * n22 * n34) * detInv
        re[15] = (n12 * n23 * n31 - n13 * n22 * n31 + n13 * n21 * n32 - n11 * n23 * n32 - n12 * n21 * n33 + n11 * n22 * n33) * detInv
        return ret
    }

    transpose(): Matrix4 {
        const ret = new Matrix4()
        const e = this.elements
        const re = ret.elements
        re[0] = e[0]
        re[4] = e[1]
        re[8] = e[2]
        re[12] = e[3]
        re[1] = e[4]
        re[5] = e[5]
        re[9] = e[6]
        re[13] = e[7]
        re[2] = e[8]
        re[6] = e[9]
        re[10] = e[10]
        re[14] = e[11]
        re[3] = e[12]
        re[7] = e[13]
        re[11] = e[14]
        re[15] = e[15]
        return ret
    }

    multiplyVector(v: Vector3) {
        const e = this.elements
        const x = e[0] * v.x + e[4] * v.y + e[8] * v.z
        const y = e[1] * v.x + e[5] * v.y + e[9] * v.z
        const z = e[2] * v.x + e[6] * v.y + e[10] * v.z
        return new Vector3(x, y, z)
    }

    multiplyVectorXYZ(x: number, y: number, z: number) {
        const e = this.elements
        const rx = e[0] * x + e[4] * y + e[8] * z
        const ry = e[1] * x + e[5] * y + e[9] * z
        const rz = e[2] * x + e[6] * y + e[10] * z
        return [rx, ry, rz] as const
    }

    multiplyVectorXYZW(x: number, y: number, z: number, w: number) {
        const e = this.elements
        const rx = e[0] * x + e[4] * y + e[8] * z + e[12] * w
        const ry = e[1] * x + e[5] * y + e[9] * z + e[13] * w
        const rz = e[2] * x + e[6] * y + e[10] * z + e[14] * w
        const rw = e[3] * x + e[7] * y + e[11] * z + e[15] * w
        return [rx, ry, rz, rw] as const
    }

    getTranslation(): Vector3 {
        return new Vector3(this.elements[12], this.elements[13], this.elements[14])
    }

    setTranslationXYZ(x: number, y: number, z: number): void {
        this.elements[12] = x
        this.elements[13] = y
        this.elements[14] = z
    }

    setTranslation(value: Vector3): void {
        this.elements[12] = value.x
        this.elements[13] = value.y
        this.elements[14] = value.z
    }

    copy(): Matrix4 {
        return Matrix4.fromArray(this.toArray().slice())
    }

    static translation(x: number, y: number, z: number): Matrix4 {
        return new Matrix4([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, x, y, z, 1])
    }

    static scaling(x: number, y: number, z: number): Matrix4 {
        return new Matrix4([x, 0, 0, 0, 0, y, 0, 0, 0, 0, z, 0, 0, 0, 0, 1])
    }

    static rotationX(angleDegrees: number): Matrix4 {
        const theta = angleDegrees * (Math.PI / 180)
        const c = Math.cos(theta)
        const s = Math.sin(theta)
        return new Matrix4([1, 0, 0, 0, 0, c, s, 0, 0, -s, c, 0, 0, 0, 0, 1])
    }

    static rotationY(angleDegrees: number): Matrix4 {
        const theta = angleDegrees * (Math.PI / 180)
        const c = Math.cos(theta)
        const s = Math.sin(theta)
        return new Matrix4([c, 0, -s, 0, 0, 1, 0, 0, s, 0, c, 0, 0, 0, 0, 1])
    }

    static rotationZ(angleDegrees: number): Matrix4 {
        const theta = angleDegrees * (Math.PI / 180)
        const c = Math.cos(theta)
        const s = Math.sin(theta)
        return new Matrix4([c, s, 0, 0, -s, c, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])
    }

    static fromBasis(x: Vector3, y: Vector3, z: Vector3): Matrix4 {
        return new Matrix4([x.x, x.y, x.z, 0, y.x, y.y, y.z, 0, z.x, z.y, z.z, 0, 0, 0, 0, 1])
    }

    toBasis(): [Vector3, Vector3, Vector3] {
        const e = this.elements
        return [new Vector3(e[0], e[1], e[2]), new Vector3(e[4], e[5], e[6]), new Vector3(e[8], e[9], e[10])]
    }
}
