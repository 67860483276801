import {Component, inject, Input} from "@angular/core"
import {AuthService} from "@common/services/auth/auth.service"
import {UtilsService} from "@legacy/helpers/utils"
import {RouterLink} from "@angular/router"
import {InputDirective} from "@app/common/directives"
import {MemoizePipe} from "@app/common/pipes/memoize/memoize.pipe"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {ContentTypeModel, TextureSetViewDataFragment, TextureSetViewRevisionDataFragment, TextureType} from "@api"
import {IsDefined} from "@cm/lib/utils/filter"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {filter} from "rxjs"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {MatTooltipModule} from "@angular/material/tooltip"
import {TextureSetRevisionViewComponent} from "app/textures/texture-set-revision-view/texture-set-revision-view.component"
import {MatMenu, MatMenuModule} from "@angular/material/menu"
import {CommonModule, NgForOf} from "@angular/common"
import {getRevisionTitle} from "@app/textures/utils/texture-set-revision-utils"

@Component({
    selector: "cm-texture-set",
    templateUrl: "./texture-set.component.html",
    styleUrls: ["./texture-set.component.scss"],
    standalone: true,
    imports: [CommonModule, InputDirective, MemoizePipe, RouterLink, TextureSetRevisionViewComponent, MatTooltipModule, MatMenu, MatMenuModule, NgForOf],
})
export class TextureSetComponent {
    @Input() set textureSetId(value: string | undefined) {
        void this.loadTextureSet(value)
    }

    permission = inject(PermissionsService)
    editName: boolean = false
    $can = this.permission.$to

    constructor(
        public utils: UtilsService,
        public authService: AuthService,
        private notification: NotificationsService,
        private sdk: SdkService,
        private refreshService: RefreshService,
    ) {
        this.refreshService.itemSubject.pipe(filter(({id}) => id === this.data?.textureSetId)).subscribe(({id}) => this.loadTextureSet(id))
    }

    private async loadTextureSet(textureSetId: string | undefined) {
        this.data = undefined
        if (!textureSetId) {
            return
        }
        const textureSetRevisionId = await this.getOrCreateLatestTextureSetRevision(textureSetId)
        const textureSetData = await this.sdk.gql.queryTextureSetData({id: textureSetId}).then((result) => result.textureSet)
        this.setData(textureSetData, textureSetRevisionId)
    }

    protected async changeName($event: Event) {
        const data = this.data
        if (!data) {
            throw new Error("Data is not set.")
        }
        const name = ($event.target as HTMLInputElement).value
        data.textureSet.name = name
        return this.notification.withUserFeedback(
            async () => {
                await this.sdk.gql.updateTextureSetName({id: data.textureSet.id, name})
            },
            {
                success: "Changes saved.",
                error: "Cannot save changes.",
            },
        )
    }

    protected async deleteTextureSet() {
        const data = this.data
        if (!data) {
            return
        }
        this.notification
            .confirmationDialog({
                title: "Delete texture set",
                message: `Are you sure you want to delete the entire texture set ?`,
                confirm: "Delete",
                cancel: "Abort",
                isDestructive: true,
            })
            .then(async (confirmed) => {
                if (confirmed) {
                    const result = await this.sdk.raw.deleteTextureSet({id: data.textureSetId})
                    if (result.errors) {
                        this.notification.showError("Cannot delete texture set. Delete all revisions first.")
                    } else {
                        this.refreshService.item({id: data.textureSet.textureGroup.id, __typename: ContentTypeModel.TextureGroup})
                        this.notification.showInfo("Texture set deleted successfully.")
                    }
                }
            })
    }

    // this is a hack for now which makes sure there exists a texture set revision for the latest texture revisions (because scanning doesn't create them yet)
    private async getOrCreateLatestTextureSetRevision(textureSetId: string | undefined): Promise<string | undefined> {
        if (!textureSetId) {
            return undefined
        }

        const latestTextureSetRevisionId = await this.sdk.gql
            .queryLatestTextureSetRevisionId({textureSetId: textureSetId})
            .then((result) => result.textureSetRevisions[0]?.id)
        if (latestTextureSetRevisionId) {
            return latestTextureSetRevisionId // we already have a latest texture set revision
        }

        // there are no latest texture set revisions yet; let's create one from the initial texture revisions
        const initialTextureRevisionsResult = await this.sdk.gql.queryInitialTextureRevisions({id: textureSetId})
        const initialTextureRevisions = initialTextureRevisionsResult.textureSet.textures
            .filter((texture) => texture.type !== TextureType.Displacement)
            .map((texture) => {
                const initialTextureRevision = texture.revisions.find((revision) => revision.number === 1)
                return initialTextureRevision ? ([texture.type, initialTextureRevision] as const) : undefined
            })
            .filter(IsDefined)
        // we add the displacement map only if it has the same resolution as the other textures (to exclude them if they were generated by auto-tiling)
        const displacementTextureRevision = initialTextureRevisionsResult.textureSet.textures
            .find((texture) => texture.type === TextureType.Displacement)
            ?.revisions.find((revision) => revision.number === 1)
        if (displacementTextureRevision) {
            if (
                displacementTextureRevision.width === initialTextureRevisions[0][1].width &&
                displacementTextureRevision.height === initialTextureRevisions[0][1].height
            ) {
                initialTextureRevisions.push([TextureType.Displacement, displacementTextureRevision] as const)
            }
        }

        if (initialTextureRevisions.length === 0) {
            return undefined // there are no textures to create a texture set revision from
        }

        // // try to find texture set revision with those initial texture revisions
        // const initialTextureSetRevision = await this.sdk.gql
        //     .queryCheckForInitialTextureSetRevision({id: this._textureSetId})
        //     .then((result) =>
        //         result.textureSet.textureSetRevisions.find(
        //             (textureSetRevision) =>
        //                 textureSetRevision.mapAssignments.length === initialTextureRevisions.length &&
        //                 textureSetRevision.mapAssignments.every((mapAssignment) =>
        //                     initialTextureRevisions.find((textureRevision) => textureRevision[1].id === mapAssignment.textureRevision.id),
        //                 ),
        //         ),
        //     )
        // if (initialTextureSetRevision) {
        //     return initialTextureSetRevision.id // we already have an initial texture set revision for the initial set of texture revisions
        // }

        // let's create a new texture set revision
        console.info("Creating initial texture set revision for revisions: ", initialTextureRevisions)
        const initialCreationDate = initialTextureRevisions.map((revision) => new Date(revision[1].createdAt)).sort((a, b) => a.getTime() - b.getTime())[0]
        const createInitialTextureSetRevisionResult = await this.sdk.gql.createInitialTextureSetRevision({
            input: {
                textureSetId: textureSetId,
                createdAt: initialCreationDate?.toISOString(),
                createdBy: initialTextureRevisionsResult?.textureSet.scanJob?.createdBy?.id,
                width: initialTextureRevisions[0]?.[1].width ?? 30,
                height: initialTextureRevisions[0]?.[1].height ?? 30,
                displacement: initialTextureRevisions.find((revision) => revision[0] === TextureType.Displacement)?.[1].displacement,
                mapAssignments: initialTextureRevisions.map((revision) => ({
                    textureType: revision[0],
                    dataObjectId: revision[1].dataObject.id,
                })),
            },
        })
        return createInitialTextureSetRevisionResult.createTextureSetRevision.id
    }

    protected onSelectRevision(textureSetRevisionId: string) {
        if (!this.data) {
            return
        }
        this.setData(this.data.textureSet, textureSetRevisionId)
    }

    private setData(textureSet: TextureSetViewDataFragment, textureSetRevisionId: string | undefined) {
        this.data = {
            textureSetId: textureSet.id,
            textureSetRevisionId: textureSetRevisionId,
            textureSet: textureSet,
            textureSetRevision: textureSet.textureSetRevisions.find((revision) => revision.id === textureSetRevisionId),
        }
    }

    // deleteTextureRevision(set: TextureSet, texture: Texture) {
    //     const dialogRef: MatDialogRef<DialogComponent> = this.dialog.open(DialogComponent, {
    //         disableClose: false,
    //         width: "400px",
    //         data: {
    //             title: "Delete revision",
    //             message:
    //                 "The latest texture revision will be deleted. " +
    //                 "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
    //             confirmLabel: "Delete revision",
    //             cancelLabel: "Cancel",
    //         },
    //     })
    //     dialogRef
    //         .afterClosed()
    //         .pipe(takeUntil(this.unsubscribe))
    //         .subscribe((confirmed) => {
    //             if (!confirmed) return
    //             set.deleteLatestTextureRevision(texture).subscribe({
    //                 next: () => this.snackBar.open("Revision deleted successfully.", "", {duration: 3000}),
    //                 error: () =>
    //                     this.snackBar.open("Cannot delete revision. Probably there is a material revision using it. Delete that one first.", "", {
    //                         duration: 3000,
    //                     }),
    //             })
    //         })
    // }

    protected showEmptyTextures?: boolean = undefined
    protected data?: {
        textureSetId: string
        textureSetRevisionId: string | undefined
        textureSet: TextureSetViewDataFragment
        textureSetRevision?: TextureSetViewRevisionDataFragment
    }
    protected readonly getRevisionTitle = getRevisionTitle
}
