import {Injectable} from "@angular/core"
import {meshNodesToBinaryStl} from "@cm/lib/stl/stl-export"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {SceneManager} from "@app/templates/template-system/scene-manager"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {LegacyTemplateConverter} from "@cm/lib/templates/legacy-template-converter"
import {Node} from "@cm/lib/templates/node-types"
import {SceneProperties} from "@cm/lib/templates/nodes/scene-properties"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {Parameters} from "@cm/lib/templates/nodes/parameters"

@Injectable({
    providedIn: "root",
})
export class StlExportService {
    constructor() {}

    async exportStlFilesOld(sceneManagerOld: SceneManager, sceneManagerService: SceneManagerService) {
        const converter = new LegacyTemplateConverter()
        const templateInstance = converter.convertTemplateInstance(sceneManagerOld.getRootNode())
        const {template, parameters} = templateInstance.parameters
        if (!(template instanceof TemplateGraph)) throw new Error("Template is not a graph")

        return this.exportStlFiles(template, parameters, sceneManagerService)
    }

    async exportStlFiles(templateGraph: TemplateGraph, parameters: Parameters, sceneManagerService: SceneManagerService): Promise<Blob> {
        const clonedTemplateGraph = templateGraph.clone({cloneSubNode: () => true})
        const previousSceneProperties = clonedTemplateGraph.parameters.nodes.parameters.list.filter(
            (node: Node): node is SceneProperties => node instanceof SceneProperties,
        )
        for (const sceneProperty of previousSceneProperties) clonedTemplateGraph.parameters.nodes.removeEntry(sceneProperty)

        const sceneProperties = new SceneProperties({
            maxSubdivisionLevel: 9999,
            maxSubdivisionLevelOnMobile: 9999,
            uiColor: [0, 0, 0],
            uiStyle: "default",
            iconSize: 24,
            enableAr: false,
            enableSalesEnquiry: false,
            textureResolution: "2000px",
            textureFiltering: false,
            enableRealtimeShadows: false,
            enableRealtimeLights: false,
            enableRealtimeMaterials: false,
            enableOnboardingHint: false,
            enableGltfDownload: false,
            enableStlDownload: false,
            enablePdfGeneration: false,
            enableSnapshot: false,
            enableFullscreen: false,
            environmentMapMode: "full",
            showAnnotations: false,
            enableAdaptiveSubdivision: false,
        })
        clonedTemplateGraph.parameters.nodes.addEntry(sceneProperties)

        sceneManagerService.$templateGraph.set(clonedTemplateGraph)
        sceneManagerService.$instanceParameters.set(parameters)

        sceneManagerService.compileTemplate()
        await sceneManagerService.sync(true)

        const meshesHighres = sceneManagerService.$scene().filter((x): x is SceneNodes.Mesh => SceneNodes.Mesh.is(x) && (!x.isProcedural || x.isDecal))
        const binaryStlDataHighres = meshNodesToBinaryStl(meshesHighres)

        sceneProperties.updateParameters({maxSubdivisionLevel: 0, maxSubdivisionLevelOnMobile: 0})
        sceneManagerService.compileTemplate()
        await sceneManagerService.sync(true)

        const meshesLowres = sceneManagerService.$scene().filter((x): x is SceneNodes.Mesh => SceneNodes.Mesh.is(x) && (!x.isProcedural || x.isDecal))
        const binaryStlDataLowres = meshNodesToBinaryStl(meshesLowres)

        const {default: JSZip} = await import("jszip")
        const zip = new JSZip()
        if (binaryStlDataHighres.triangleCount === binaryStlDataLowres.triangleCount) {
            zip.file("mesh.stl", binaryStlDataHighres.blob)
        } else {
            zip.file("mesh_highres.stl", binaryStlDataHighres.blob)
            zip.file("mesh_lowres.stl", binaryStlDataLowres.blob)
        }

        return zip.generateAsync({type: "blob"})
    }
}
