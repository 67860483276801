import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    Injectable,
    OnDestroy,
    OnInit,
    ViewContainerRef,
    computed,
    inject,
    input,
    output,
    signal,
    viewChild,
} from "@angular/core"
import {
    isBooleanLikeNode,
    isImageLike,
    isJSONLikeNode,
    isMaterialLike,
    isMesh,
    isNode,
    isNodeOwner,
    isNumberLikeNode,
    isObject,
    isObjectLike,
    isStringLikeNode,
    isSwitch,
    isTemplateLike,
    isNodeValue,
} from "@cm/lib/templates/node-types"
import {TemplateTreeComponent, TemplateTreeNode, hasChildren} from "@template-editor/components/template-tree/template-tree.component"
import {ListItemComponent} from "@common/components/item"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {StoredMesh} from "@cm/lib/templates/nodes/stored-mesh"
import {MeshDecal} from "@cm/lib/templates/nodes/mesh-decal"
import {MeshCurve} from "@cm/lib/templates/nodes/mesh-curve"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {TemplateReference} from "@cm/lib/templates/nodes/template-reference"
import {Group} from "@cm/lib/templates/nodes/group"
import {MatMenuModule} from "@angular/material/menu"
import {TemplateMenuComponent} from "@app/template-editor/components/template-menu/template-menu.component"
import {TemplateMenuSectionComponent} from "@app/template-editor/components/template-menu-section/template-menu-section.component"
import {ToggleComponent} from "@app/common/components/buttons/toggle/toggle.component"
import {MatTooltipModule} from "@angular/material/tooltip"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {Parameters} from "@cm/lib/templates/nodes/parameters"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {Router} from "@angular/router"
import {TemplateListNode} from "@cm/lib/templates/declare-template-node"
import {MatDialog} from "@angular/material/dialog"
import {RenameDialogComponent} from "@app/common/components/dialogs/rename-dialog/rename-dialog.component"
import {map, switchMap, tap} from "rxjs/operators"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {v4 as uuid4} from "uuid"
import {BooleanSwitch, MaterialSwitch, NumberSwitch, ObjectSwitch, StringSwitch, ImageSwitch, TemplateSwitch, JSONSwitch} from "@cm/lib/templates/nodes/switch"
import {getNodeOwner, getTemplateNodeClassLabel, getTemplateNodeLabel, getTemplateSwitchItemLabel} from "@cm/lib/templates/utils"
import {getNodeIconClass, getNodeIconSeconaryClass} from "@template-editor/helpers/template-icons"
import {TemplateMenuItemComponent} from "@template-editor/components/template-menu-item/template-menu-item.component"
import {
    DropPosition,
    TemplateNodeDragService,
    TemplateDropTarget,
    DraggableSource,
    getDropPositionFromPosition,
} from "@app/template-editor/services/template-node-drag.service"
import {insertAfter, insertBefore} from "@cm/lib/utils/utils"
import {isNamedNode} from "@cm/lib/templates/nodes/named-node"
import {TemplateNode} from "@cm/lib/templates/types"
import {TemplateTreeObjectTransformComponent} from "../template-tree-object-transform/template-tree-object-transform.component"
import {FilesService} from "@app/common/services/files/files.service"
import {takeUntilDestroyed, toObservable, toSignal} from "@angular/core/rxjs-interop"
import {from, Observable, of} from "rxjs"
import {TemplateNodeComponent} from "../template-node/template-node.component"
import {Nodes} from "@cm/lib/templates/nodes/nodes"
import {TemplateEditorType, isNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {TemplateRevisionDetailsForTemplateTreeItemFragment, TemplateType} from "@api"
import {createLinkToEditorFromTemplatesForType} from "@app/common/helpers/routes"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {ApiRequest} from "@app/common/models/api-request/api-request"
import {IsUnique} from "@cm/lib/utils/filter"
import {BatchApiCall} from "@app/common/helpers/batch-api-call/batch-api-call"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {DataObjectReference} from "@cm/lib/templates/nodes/data-object-reference"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {Seam} from "@cm/lib/templates/nodes/seam"
import {Matrix4} from "@cm/lib/math"

type RequestPayload = {
    legacyId: number
}

type ResponsePayload = TemplateRevisionDetailsForTemplateTreeItemFragment

type BatchedRequestPayload = {
    requests: ApiRequest<RequestPayload, ResponsePayload>[]
}

@Injectable({
    providedIn: "root",
})
class TemplateRevisionDetailBatchApiCallService extends BatchApiCall<RequestPayload, ResponsePayload, BatchedRequestPayload> {
    private sdk = inject(SdkService)

    protected dispatchResponses(batchedPayload: BatchedRequestPayload, responses: ResponsePayload[]) {
        const requestsById = new Map<number, ApiRequest<RequestPayload, ResponsePayload>[]>()
        batchedPayload.requests.forEach((request) => {
            if (!requestsById.has(request.payload.legacyId)) {
                requestsById.set(request.payload.legacyId, [])
            }
            requestsById.get(request.payload.legacyId)!.push(request)
        })
        responses.forEach((response) => {
            const requests = requestsById.get(response.legacyId)
            if (!requests) {
                throw new Error("No request not found")
            }
            requests.forEach((request) => request.resolve(response))
            requestsById.delete(response.legacyId)
        })
        requestsById.forEach((requests) => requests.forEach((request) => request.reject("No response received")))
    }

    protected batchRequests(requests: ApiRequest<RequestPayload, ResponsePayload>[]): BatchedRequestPayload[] {
        return [{requests}]
    }

    protected async callApi(payload: BatchedRequestPayload): Promise<(ResponsePayload | undefined | null)[]> {
        const legacyIds = payload.requests.map((request) => request.payload.legacyId).filter(IsUnique)
        return this.sdk.gql
            .getTemplateRevisionDetailsForTemplateTreeItem({
                legacyIds,
            })
            .then((response) => response.templateRevisions)
    }
}

@Component({
    selector: "cm-template-tree-item",
    standalone: true,
    templateUrl: "./template-tree-item.component.html",
    styleUrls: ["./template-tree-item.component.scss", "./../../helpers/template-icons.scss"],
    imports: [
        ListItemComponent,
        MatMenuModule,
        TemplateMenuComponent,
        TemplateMenuSectionComponent,
        ToggleComponent,
        MatTooltipModule,
        TemplateMenuItemComponent,
        TemplateTreeObjectTransformComponent,
    ],
})
export class TemplateTreeItemComponent implements OnInit, OnDestroy {
    requestSaveInLibrary = output<TemplateGraph>()
    sceneManagerService = inject(SceneManagerService)
    private templateRevisionDetailBatchApiCallService = inject(TemplateRevisionDetailBatchApiCallService)
    private router = inject(Router)
    private notifications = inject(NotificationsService)
    private dialog = inject(MatDialog)
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
    private cdr = inject(ChangeDetectorRef)
    clipboardService = inject(TemplateNodeClipboardService)
    drag = inject(TemplateNodeDragService)
    files = inject(FilesService)
    sdk = inject(SdkService)
    protected destroyRef = inject(DestroyRef)
    TemplateInstance = TemplateInstance
    MeshDecal = MeshDecal
    MeshCurve = MeshCurve
    Seam = Seam

    private triggerRecompute = signal(0)
    treeNode = input.required<TemplateTreeNode>()
    node = computed(() => this.treeNode().node)
    isNodeValue = computed(() => isNodeValue(this.node()))
    isRootNode = computed(() => this.node() === this.sceneManagerService.$templateGraph())
    parent = computed(() => this.treeNode().parent)
    isHighlighted = computed(() => this.templateTree().isHighlighted(this.treeNode()) || this.sceneManagerService.isHighlightedNode(this.node()))
    expandable = computed(() => hasChildren(this.treeNode()))
    disabled = computed(() => !this.sceneManagerService.isNodeActive(this.node()))
    selected = computed(() => this.sceneManagerService.$selectedNodeParts().some((selectedNodePart) => selectedNodePart.templateNode === this.node()))
    templateTree = input.required<TemplateTreeComponent>()
    nodeIconClass = computed(() => getNodeIconClass(this.node().getNodeClass()))
    secondaryNodeIconClass = computed(() => getNodeIconSeconaryClass(this.node().getNodeClass()))
    class = computed(() => getTemplateNodeClassLabel(this.node()))
    label = computed(() => getTemplateNodeLabel(this.node()))
    storedMeshNode = computed(() => {
        const node = this.node()
        if (node instanceof StoredMesh) return node
        else return undefined
    })
    dataObjectReferenceNode = computed(() => {
        const node = this.node()
        if (node instanceof DataObjectReference) return node
        else return undefined
    })
    objectNode = computed(() => {
        const node = this.node()
        if (isObject(node)) return node
        else return undefined
    })
    templateReferenceNode = computed(
        () => {
            const node = this.node()
            this.triggerRecompute()
            if (node instanceof TemplateReference) return node
            else if (node instanceof TemplateInstance && node.parameters.template instanceof TemplateReference) return node.parameters.template
            else return undefined
        },
        {equal: () => false},
    )
    templateReferenceNodeLink = toSignal(
        toObservable(this.templateReferenceNode).pipe(
            switchMap((templateReferenceNode) => {
                if (!templateReferenceNode) return of(undefined)

                return from(
                    (async () => {
                        const {templateRevisionId} = templateReferenceNode.parameters
                        const {template} = await this.templateRevisionDetailBatchApiCallService.fetch({legacyId: templateRevisionId})
                        const {revisions} = template
                        const revision = revisions.find((revision) => revision.legacyId === templateRevisionId)

                        if (!revision) throw new Error("Revision not found")

                        const getType = () => {
                            if (template.type === TemplateType.Part || template.type === TemplateType.Product) {
                                return "products"
                            } else if (template.type === TemplateType.Room) {
                                return "scenes"
                            } else {
                                return "templates"
                            }
                        }

                        return createLinkToEditorFromTemplatesForType(
                            getType(),
                            template.id,
                            revision.id,
                            isNewTemplateSystem(revision.graph) ? TemplateEditorType.New : TemplateEditorType.Old,
                        )
                    })(),
                )
            }),
        ),
        {
            initialValue: undefined,
        },
    )
    materialReferenceNode = computed(
        () => {
            const node = this.node()
            this.triggerRecompute()
            if (node instanceof MaterialReference) return node
            else return undefined
        },
        {equal: () => false},
    )
    materialReferenceNodeLink = toSignal(
        toObservable(this.materialReferenceNode).pipe(
            switchMap((materialReferenceNode) => {
                if (!materialReferenceNode) return of(undefined)
                return from(this.sdk.gql.getMaterialRevisionDetailsForTemplateTreeItem({legacyId: materialReferenceNode.parameters.materialRevisionId}))
            }),
            map((materialRevisionData) => {
                if (!materialRevisionData) return undefined
                return ["/materials", materialRevisionData.materialRevision.material.id, "edit", materialRevisionData.materialRevision.id]
            }),
        ),
        {
            initialValue: undefined,
        },
    )
    nodeOwnerNode = computed(() => {
        const node = this.node()
        if (isNodeOwner(node)) return node
        else return undefined
    })
    templateLikeNode = computed(() => {
        const node = this.node()
        if (isTemplateLike(node)) return node
        else return undefined
    })
    templateGraphNode = computed(() => {
        const node = this.node()
        if (node instanceof TemplateGraph) return node
        else return undefined
    })
    namedNode = computed(() => {
        const node = this.node()
        if (isNamedNode(node)) return node
        else return undefined
    })
    groupNode = computed(() => {
        const node = this.node()
        if (node instanceof Group) return node
        else return undefined
    })
    meshNode = computed(() => {
        const node = this.node()
        if (isMesh(node)) return node
        else return undefined
    })
    meshCurveNode = computed(() => {
        const node = this.node()
        if (node instanceof MeshCurve) return node
        else return undefined
    })
    switchNode = computed(() => {
        const node = this.node()
        if (isSwitch(node)) return node
        else return undefined
    })
    parentSwitchNode = computed(() => {
        const parent = this.parent()
        if (!parent) return undefined
        const {node} = parent
        if (isSwitch(node)) return node
        else return undefined
    })
    objectProcessingNodes = computed(() => [...this.getProcessingNodes(false)].filter(isObject))
    getSwitchItemLabel = getTemplateSwitchItemLabel

    private dragPlaceholder = viewChild.required("dragPlaceholder", {read: ViewContainerRef})

    ngOnInit() {
        this.templateTree().registerChild(this)

        this.sceneManagerService.templateTreeChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((differences) => {
            const materialReferenceNode = this.materialReferenceNode()
            if (materialReferenceNode && differences.modifiedNodes.has(materialReferenceNode)) this.triggerRecompute.update((x) => x + 1)

            const templateReferenceNode = this.templateReferenceNode()
            if (templateReferenceNode && differences.modifiedNodes.has(templateReferenceNode)) this.triggerRecompute.update((x) => x + 1)
        })
    }

    ngOnDestroy() {
        this.templateTree().unregisterChild(this)
    }

    onClickedNode(mouseEvent: MouseEvent) {
        const templateNodePart = {templateNode: this.node(), part: "root" as const}
        if (mouseEvent.shiftKey) this.sceneManagerService.addNodeToSelection(templateNodePart)
        else if (mouseEvent.ctrlKey) this.sceneManagerService.removeNodeFromSelection(templateNodePart)
        else this.sceneManagerService.selectNode(templateNodePart)
    }

    onMouseEnter() {
        this.sceneManagerService.$hoveredNodePart.set({templateNode: this.node(), part: "root"})
    }

    onMouseLeave() {
        this.sceneManagerService.$hoveredNodePart.set(undefined)
    }

    dragOver(event: DragEvent) {
        event.stopPropagation()

        const getDropPosition = (): DropPosition => {
            const element = this.elementRef.nativeElement
            const targetRect = element.getBoundingClientRect()

            const node = this.treeNode()

            if (hasChildren(node)) {
                const offset = targetRect.height / 3
                const targetCenterY = targetRect.top + offset
                if (event.clientY < targetCenterY) return "before"
                else if (event.clientY < targetCenterY + offset) return "inside"
                else return "after"
            } else {
                const targetCenterY = targetRect.top + targetRect.height / 2
                return event.clientY < targetCenterY ? "before" : "after"
            }
        }

        const getDropTarget = (): TemplateDropTarget<TemplateTreeItemComponent> | null => {
            const dragSource = this.drag.dragSource()
            if (!dragSource) return null

            const dropTarget = {component: this, position: this.forceDropPosition() ?? getDropPosition()}

            if (!this.isMovingToAllowed(dragSource, dropTarget.position)) return null
            return dropTarget
        }

        this.drag.dropTarget.update((previous) => {
            const dropTarget = getDropTarget()
            if (previous === dropTarget) return previous
            if (
                previous &&
                dropTarget &&
                previous.component === dropTarget.component &&
                getDropPositionFromPosition(previous.position) === getDropPositionFromPosition(dropTarget.position)
            )
                return previous
            return dropTarget
        })

        if (this.drag.dropTarget() !== null) event.preventDefault()
    }

    dragLeave(event: DragEvent) {
        event.stopPropagation()

        this.drag.dragLeave(event, this, this.elementRef.nativeElement)
    }

    isDropTarget(dropPosition: DropPosition) {
        if (!this.drag.dragSource()) return false

        const dropTarget = this.drag.dropTarget()
        if (!dropTarget) return false

        const {component} = dropTarget
        if (!(component instanceof TemplateTreeItemComponent)) return false

        const position = getDropPositionFromPosition(dropTarget.position)
        const dropTargetNode = component.treeNode()

        const node = this.treeNode()
        if (dropTargetNode === node) return position === dropPosition
        else if (position === "inside" && hasChildren(dropTargetNode)) {
            if (dropPosition === "after") {
                const children = dropTargetNode.node.parameters.nodes.parameters.list
                if (children.length > 0) {
                    const lastChild = children[children.length - 1]
                    return node.node === lastChild && node.parent === dropTargetNode
                }
            }
        }
        return false
    }

    private async allowLeavePage() {
        if (!this.sceneManagerService.$templateGraphModified()) return true

        const confirmed = await this.notifications.confirmationDialog({
            title: "Leave page ?",
            message: "Changes you made may not be saved. Are you sure you want to leave?",
            confirm: "Leave",
            cancel: "Stay",
        })

        return confirmed
    }

    async openTemplateReference() {
        const templateReferenceNodeLink = this.templateReferenceNodeLink()
        if (!templateReferenceNodeLink) return

        if (!(await this.allowLeavePage())) return

        this.router.navigate(templateReferenceNodeLink, {
            queryParamsHandling: "preserve",
        })
    }

    async openMaterialReference() {
        const materialReferenceNodeLink = this.materialReferenceNodeLink()
        if (!materialReferenceNodeLink) return

        if (!(await this.allowLeavePage())) return

        this.router.navigate(materialReferenceNodeLink, {
            queryParamsHandling: "preserve",
        })
    }

    collapseAll() {
        const treeControl = this.templateTree().treeControl
        const treeNode = this.treeNode()
        treeControl.collapseDescendants(treeNode)
        treeControl.expand(treeNode)
    }

    private getStringFromDialog(currentValue: string): Observable<string | undefined> {
        const dialogRef = this.dialog.open(RenameDialogComponent, {
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                currentName: currentValue,
            },
        })

        return dialogRef.afterClosed()
    }

    renameNode() {
        const namedNode = this.namedNode()
        if (!namedNode) return

        this.getStringFromDialog(namedNode.parameters.name)
            .pipe(
                tap((newValue) => {
                    if (newValue) {
                        this.sceneManagerService.modifyTemplateGraph(() => {
                            namedNode.updateParameters({name: newValue})
                        })
                    }
                }),
            )
            .subscribe()
    }

    private getProcessingNodes(includeRoot: boolean) {
        const selectedNodes = this.sceneManagerService.$selectedNodeParts().map((selectedNodePart) => selectedNodePart.templateNode)
        const node = this.node()
        const copyNodes = selectedNodes.includes(node) ? selectedNodes : [node]
        return includeRoot ? copyNodes : copyNodes.filter((node) => node !== this.sceneManagerService.$templateGraph())
    }

    copySelection() {
        this.clipboardService.copy([...this.getProcessingNodes(true)])
    }

    pasteCopies() {
        const nodeOwner = this.nodeOwnerNode()
        if (!nodeOwner || !this.clipboardService.valid()) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            this.clipboardService
                .pasteDuplicates()
                .filter(isNode)
                .forEach((node) => this.addReference(nodeOwner.parameters.nodes, node)),
        )
    }

    private addReference<T>(nodes: TemplateListNode<T>, node: T, position?: {node: T; location: "before" | "after"}) {
        const {list} = nodes.parameters

        if (list.includes(node)) return
        if (!position) nodes.addEntry(node)
        else {
            const {location} = position
            const newList = [...list]
            if (location === "before") insertBefore(newList, position.node, node)
            else if (location === "after") insertAfter(newList, position.node, node)
            else throw new Error(`Invalid location: ${location}`)
            nodes.replaceParameters({list: newList})
        }
    }

    pasteReferences() {
        const switchNode = this.switchNode()
        if (!switchNode || !this.clipboardService.valid()) return

        const pastedNodes = this.clipboardService.pasteReferences().filter((node) => node !== switchNode)

        if (pastedNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            if (switchNode instanceof ObjectSwitch) pastedNodes.filter(isObjectLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof MaterialSwitch)
                pastedNodes.filter(isMaterialLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof TemplateSwitch)
                pastedNodes.filter(isTemplateLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof ImageSwitch) pastedNodes.filter(isImageLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof StringSwitch)
                pastedNodes.filter(isStringLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof NumberSwitch)
                pastedNodes.filter(isNumberLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof BooleanSwitch)
                pastedNodes.filter(isBooleanLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof JSONSwitch)
                pastedNodes.filter(isJSONLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else throw new Error("Unsupported switch node type")
        })
    }

    duplicateNode() {
        const processingNodes = [...this.getProcessingNodes(false)]
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            const originalNodes = this.clipboardService.pasteReferences()

            this.clipboardService.copy(processingNodes)
            const copiedNodes = this.clipboardService.pasteDuplicates()

            processingNodes.forEach((node, index) => {
                const copiedNode = copiedNodes[index]

                if (isNode(node) && isNode(copiedNode))
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, copiedNode, {node, location: "after"}))
            })

            this.clipboardService.copy(originalNodes)
        })
    }

    createInstance() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isTemplateLike(node)) {
                    const instance = new TemplateInstance({
                        name: isNamedNode(node) ? node.parameters.name + " instance" : "Template Instance",
                        id: uuid4(),
                        template: node,
                        parameters: new Parameters({}),
                        lockedTransform: Matrix4.identity().toArray(),
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => {
                        this.addReference(nodeOwner.parameters.nodes, instance, {node, location: "after"})
                    })
                }
            }),
        )
    }

    async promoteToLibraryTemplate() {
        const templateGraph = this.templateGraphNode()
        if (!templateGraph) throw new Error("Invalid node type")

        this.getStringFromDialog(templateGraph.parameters.name)
            .pipe(
                tap((newValue) => {
                    if (newValue) {
                        const templateGraphCopy = templateGraph.clone()
                        templateGraphCopy.updateParameters({name: newValue})
                        this.requestSaveInLibrary.emit(templateGraphCopy)
                    }
                }),
            )
            .subscribe()
    }

    deleteReference() {
        const parentSwitchNode = this.parentSwitchNode()
        if (!parentSwitchNode) return
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                ;(parentSwitchNode.parameters.nodes as TemplateListNode<TemplateNode>).removeEntry(node)
            }),
        )
    }

    deleteNode() {
        const processingNodes = this.getProcessingNodes(false)
        this.sceneManagerService.deleteNodes(processingNodes.map((node) => ({templateNode: node, part: "root"})))
    }

    moveSelectionToNewGroup() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        const node = this.node()
        if (!isNode(node)) return

        const nodeOwner = getNodeOwner(node)

        this.sceneManagerService.modifyTemplateGraph((templateGraph) => {
            const group = new Group({name: "Group", nodes: new Nodes({list: []}), active: true})

            const root = nodeOwner ?? templateGraph
            this.addReference(root.parameters.nodes, group, {node, location: "after"})

            processingNodes.forEach((node) => {
                if (isNode(node))
                    getNodeOwner(node, (nodeOwner) => {
                        nodeOwner.parameters.nodes.removeEntry(node)
                        group.parameters.nodes.addEntry(node)
                    })
            })
        })
    }

    dissolveGroup() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isNodeOwner(node)) {
                    getNodeOwner(node, (nodeOwner) => {
                        const {list: childNodes} = node.parameters.nodes.parameters
                        const childNodesCopy = [...childNodes]
                        childNodesCopy.forEach((childNode) => {
                            node.parameters.nodes.removeEntry(childNode)
                            this.addReference(nodeOwner.parameters.nodes, childNode, {node, location: "before"})
                        })
                        nodeOwner.parameters.nodes.removeEntry(node)
                    })
                }
            }),
        )
    }

    addDecal() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isMesh(node)) {
                    const decal = new MeshDecal({
                        name: node.parameters.name + " decal",
                        mesh: node,
                        offset: [0, 0],
                        rotation: 0,
                        size: [10, 10],
                        distance: 0.01,
                        mask: undefined,
                        invertMask: false,
                        maskType: "binary",
                        materialAssignment: null,
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, decal, {node, location: "after"}))
                }
            }),
        )
    }

    addCurve() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (isMesh(node)) {
                    const curve = new MeshCurve({
                        name: node.parameters.name + " curve",
                        mesh: node,
                        closed: false,
                        controlPoints: [],
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, curve, {node, location: "after"}))
                }
            }),
        )
    }

    addSeam() {
        const processingNodes = this.getProcessingNodes(false)
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() =>
            processingNodes.forEach((node) => {
                if (node instanceof MeshCurve) {
                    const curve = new Seam({
                        name: node.parameters.name + " seam",
                        curve: node,
                        item: null,
                        allowScaling: false,
                        visible: true,
                    })
                    getNodeOwner(node, (nodeOwner) => this.addReference(nodeOwner.parameters.nodes, curve, {node, location: "after"}))
                }
            }),
        )
    }

    forceDropPosition(): DropPosition | undefined {
        if (this.isRootNode()) return "inside"
        return undefined
    }

    private targetSwitchNode(position: DropPosition) {
        if (position === "inside") return this.switchNode()
        return this.parentSwitchNode()
    }

    isMovingToAllowed(source: DraggableSource, position: DropPosition) {
        const isMovingToAllowedSingle = (source: TemplateNode | TemplateTreeItemComponent) => {
            const sourceNode = this.drag.draggableSourceToTemplateNode(source)
            if (!sourceNode) return false

            if (source instanceof TemplateTreeItemComponent) {
                // Prevent dropping on itself
                if (source === this) return false
            }

            const targetSwitchNode = this.targetSwitchNode(position)

            //Prevent mixing references and non-references
            const sourceIsReference =
                source instanceof TemplateTreeItemComponent ? source.parentSwitchNode() !== undefined : isNode(sourceNode) && getNodeOwner(sourceNode) !== null
            const targetIsReference = targetSwitchNode !== undefined
            if (sourceIsReference !== targetIsReference) return false

            //Prevent dropping a node into a parent it is already part of
            if (source instanceof TemplateTreeItemComponent) if (position === "inside" && source.parent() === this.treeNode()) return false

            //Prevent dropping a node into a switch node that does not support it
            if (targetSwitchNode) {
                if (targetSwitchNode instanceof ObjectSwitch) {
                    if (!isObjectLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof MaterialSwitch) {
                    if (!isMaterialLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof TemplateSwitch) {
                    if (!isTemplateLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof ImageSwitch) {
                    if (!isImageLike(sourceNode)) return false
                } else if (targetSwitchNode instanceof StringSwitch) {
                    if (!isStringLikeNode(sourceNode)) return false
                } else if (targetSwitchNode instanceof NumberSwitch) {
                    if (!isNumberLikeNode(sourceNode)) return false
                } else if (targetSwitchNode instanceof BooleanSwitch) {
                    if (!isBooleanLikeNode(sourceNode)) return false
                } else if (targetSwitchNode instanceof JSONSwitch) {
                    if (!isJSONLikeNode(sourceNode)) return false
                } else throw new Error("Unsupported switch node type")
            } else if (!isNode(sourceNode)) return false

            //Prevent dropping a node into one of its descendants
            if (source instanceof TemplateTreeItemComponent) {
                const sourceTreeNode = source.treeNode()
                let {parent} = this.treeNode()
                while (parent) {
                    if (parent === sourceTreeNode) return false
                    parent = parent.parent
                }
            }

            return true
        }

        if (source instanceof Array) {
            return source.every((source) => isMovingToAllowedSingle(source))
        } else return isMovingToAllowedSingle(source)
    }

    moveNodeTo(source: DraggableSource, position: DropPosition) {
        if (!this.isMovingToAllowed(source, position)) return

        const targetNode = this.node()
        const targetSwitchNode = this.targetSwitchNode(position)

        const moveNodeToSingle = (source: TemplateNode | TemplateTreeItemComponent) => {
            const sourceNode = this.drag.draggableSourceToTemplateNode(source)
            if (!sourceNode) return

            if (targetSwitchNode) {
                const sourceSwitchParent = source instanceof TemplateTreeItemComponent ? source.parentSwitchNode() : undefined
                if (sourceSwitchParent) {
                    ;(sourceSwitchParent.parameters.nodes as TemplateListNode<TemplateNode>).removeEntry(sourceNode)
                }

                const getPosition = <T>(nodes: TemplateListNode<T>) => {
                    if (position === "inside") return undefined
                    const item = nodes.parameters.list.find((x) => x === targetNode)
                    if (!item) return undefined
                    return {node: item, location: position}
                }

                if (targetSwitchNode instanceof ObjectSwitch) {
                    if (isObjectLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof MaterialSwitch) {
                    if (isMaterialLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof TemplateSwitch) {
                    if (isTemplateLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof ImageSwitch) {
                    if (isImageLike(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof StringSwitch) {
                    if (isStringLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof NumberSwitch) {
                    if (isNumberLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof BooleanSwitch) {
                    if (isBooleanLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else if (targetSwitchNode instanceof JSONSwitch) {
                    if (isJSONLikeNode(sourceNode))
                        this.addReference(targetSwitchNode.parameters.nodes, sourceNode, getPosition(targetSwitchNode.parameters.nodes))
                    else throw new Error("Invalid node type")
                } else throw new Error("Unsupported switch node type")
            } else {
                if (!isNode(sourceNode)) throw new Error("Source node is not of type node")

                const sourceNodeOwner = getNodeOwner(sourceNode)
                if (sourceNodeOwner) sourceNodeOwner.parameters.nodes.removeEntry(sourceNode)

                if (position === "inside") {
                    if (!isNodeOwner(targetNode)) throw new Error("Target node is not of type node parent")
                    this.addReference(targetNode.parameters.nodes, sourceNode)
                } else {
                    if (!isNode(targetNode)) throw new Error("Target node is not of type node")
                    const targetNodeOwner = getNodeOwner(targetNode)
                    if (!targetNodeOwner) throw new Error("Target node is not of type node parent")
                    this.addReference(targetNodeOwner.parameters.nodes, sourceNode, {node: targetNode, location: position})
                }
            }
        }

        this.sceneManagerService.modifyTemplateGraph(() => {
            if (source instanceof Array) source.forEach((source) => moveNodeToSingle(source))
            else moveNodeToSingle(source)
        })
    }

    gotoReference() {
        if (!this.parentSwitchNode()) return
        this.sceneManagerService.selectNode({templateNode: this.node(), part: "root"})
    }

    setVisibility(visible: boolean) {
        const processingNodes = this.objectProcessingNodes()
        if (processingNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            processingNodes.forEach((templateNode) => {
                templateNode.updateParameters({
                    visible,
                })
            })
        })
    }

    private getDragImage() {
        const dragPlaceholder = this.dragPlaceholder()

        const dragImage = document.createElement("div")

        dragImage.style.display = "flex"
        dragImage.style.flexDirection = "column"
        dragImage.style.justifyContent = "center"
        dragImage.style.alignItems = "left"
        dragImage.style.position = "absolute"
        dragImage.style.zIndex = "1000"
        dragImage.style.left = "-1000px"
        dragImage.style.backgroundColor = "rgb(255, 255, 255)"
        dragImage.style.border = "1px solid black"
        dragImage.style.padding = "5px"
        dragImage.style.fontSize = "0.75rem"
        dragImage.style.fontWeight = "500"
        dragImage.style.color = "black"
        dragImage.style.gap = "5px"

        const draggedComponents = this.getDraggedComponents()

        for (const draggedComponent of draggedComponents) {
            const templateNodeComponent = dragPlaceholder.createComponent(TemplateNodeComponent)
            templateNodeComponent.setInput("node", draggedComponent.node())
            dragImage.appendChild(templateNodeComponent.location.nativeElement)
        }

        this.cdr.detectChanges()

        const nativeElement = dragPlaceholder.element.nativeElement as HTMLElement

        nativeElement.appendChild(dragImage)

        setTimeout(() => {
            nativeElement.removeChild(dragImage)
        }, 0)

        return dragImage
    }

    private getDraggedComponents() {
        const processingNodes = this.getProcessingNodes(false)

        const templateTreeItems = this.templateTree().children()

        const draggedParentSwitchNode = this.parentSwitchNode()

        return processingNodes
            .map((node) => {
                return templateTreeItems.find((child) => {
                    if (child.node() !== node) return false
                    if (child.parentSwitchNode() !== draggedParentSwitchNode) return false
                    return true
                })
            })
            .filter((child): child is TemplateTreeItemComponent => child !== undefined)
    }

    dragStart(event: DragEvent) {
        const dragImage = this.getDragImage()

        event.dataTransfer?.setDragImage(dragImage, 0, 0)

        this.drag.dragStart(event, this.getDraggedComponents())
    }
}
