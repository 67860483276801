<div class="cm-files" #gridList>
    <cm-drop-files
        [enableDrop]="canAddFiles"
        (filesDropped)="uploadFiles($event)"
        [showDropzone]="canAddFiles && attachments?.length === 0"
        [subtitle]="attachments?.length === 0 ? 'or click to browse for files' : 'to upload as attachments'"
    >
        <div class="cm-files-section">
            @if (attachments?.length === 0 || dropZoneActive.value) {
                <cm-full-page-feedback icon="no-files" text="No files"></cm-full-page-feedback>
            } @else {
                @if (attachments) {
                    <cm-grid-list [gridSize]="gridSizes.Small" [class.cm-grid-inactive]="!attachments?.length || dropZoneActive.value">
                        <ng-container cm-grid-tiles>
                            @for (assignment of attachments; track assignment) {
                                @if (isImage(assignment.dataObject)) {
                                    <cm-card [squareFormat]="true" [defaultThumbnail]="false" (selectItem)="openImageViewer(assignment.dataObject)">
                                        <ng-container cm-thumbnail>
                                            <cm-data-object-thumbnail
                                                [dataObjectId]="assignment?.dataObject.id"
                                                [allowLabel]="false"
                                            ></cm-data-object-thumbnail>
                                        </ng-container>
                                        <ng-container cm-top-left>
                                            <!-- Download button -->
                                            <a href="{{ assignment.dataObject.downloadUrl }}" download (click)="$event.stopPropagation()">
                                                <i class="far fa-arrow-to-bottom cm-download-button"></i>
                                            </a>
                                        </ng-container>
                                        <ng-container cm-top-right>
                                            <!-- Menu -->
                                            @if (canChangeFiles) {
                                                <i
                                                    [matMenuTriggerFor]="dataObjectMenu"
                                                    [matMenuTriggerData]="{assignment}"
                                                    (click)="$event.stopPropagation()"
                                                    class="far fa-ellipsis-v cm-menu-dots"
                                                ></i>
                                            }
                                        </ng-container>
                                        <ng-container cm-title>
                                            <div class="cm-title" [matTooltip]="assignment.dataObject.originalFileName">
                                                {{ assignment.dataObject.originalFileName }}
                                            </div>
                                        </ng-container>
                                    </cm-card>
                                } @else {
                                    <cm-card [squareFormat]="true" [defaultThumbnail]="false">
                                        <ng-container cm-thumbnail>
                                            <cm-data-object-thumbnail
                                                [dataObjectId]="assignment?.dataObject.id"
                                                [allowLabel]="false"
                                            ></cm-data-object-thumbnail>
                                        </ng-container>
                                        <ng-container cm-top-left>
                                            <!-- Download button -->
                                            <a href="{{ assignment.dataObject.downloadUrl }}" download (click)="$event.stopPropagation()">
                                                <i class="far fa-arrow-to-bottom cm-download-button"></i>
                                            </a>
                                        </ng-container>
                                        <ng-container cm-top-right>
                                            <!-- Menu -->
                                            @if (canChangeFiles) {
                                                <i
                                                    [matMenuTriggerFor]="dataObjectMenu"
                                                    [matMenuTriggerData]="{assignment}"
                                                    (click)="$event.stopPropagation()"
                                                    class="far fa-ellipsis-v cm-menu-dots"
                                                ></i>
                                            }
                                        </ng-container>
                                        <ng-container cm-title>
                                            <div class="cm-title" [matTooltip]="assignment.dataObject.originalFileName">
                                                {{ assignment.dataObject.originalFileName }}
                                            </div>
                                        </ng-container>
                                    </cm-card>
                                }
                            }
                            <!-- Menu -->
                            <mat-menu #dataObjectMenu="matMenu">
                                <ng-template matMenuContent let-assignment="assignment">
                                    <button mat-menu-item (click)="openRenameDialog(assignment.dataObject)">
                                        <span>Rename</span>
                                    </button>
                                    <button mat-menu-item (click)="deleteDataObject(assignment)">
                                        <span>Delete</span>
                                    </button>
                                    @if (isImage(assignment.dataObject) && assignment.dataObject?.state === Enums.DataObjectState.Completed) {
                                        @if ($showSetGalleryImageOption() && $can().update.item(this.item$.value, "galleryImage")) {
                                            <button mat-menu-item (click)="setGalleryImage(assignment)">
                                                <span>Set as gallery image</span>
                                            </button>
                                        }
                                        @if ($showSetCustomerLogoOption() && $can().update.item(this.item$.value, "customerLogo")) {
                                            <button mat-menu-item (click)="setCustomerLogo(assignment)">
                                                <span>Set as customer logo</span>
                                            </button>
                                        }
                                    }
                                    @if (isPdf(assignment.dataObject) && $can().update.item(this.item$.value, "pdfTemplate")) {
                                        <button mat-menu-item (click)="setPdfTemplate(assignment)">
                                            <span>Set as PDF template</span>
                                        </button>
                                    }
                                    @if (isFont(assignment.dataObject) && $can().update.item(this.item$.value, "customerFont")) {
                                        <button mat-menu-item (click)="setCorporateFont(assignment, false)">
                                            <span>Set as corporate font</span>
                                        </button>
                                        <button mat-menu-item (click)="setCorporateFont(assignment, true)">
                                            <span>Set as corporate font bold</span>
                                        </button>
                                    }
                                </ng-template>
                            </mat-menu>
                            @if (canAddFiles) {
                                <cm-add-file-card (filesSelected)="uploadFiles($event)"></cm-add-file-card>
                            }
                        </ng-container>
                    </cm-grid-list>
                } @else {
                    <div class="cm-placeholder-container">
                        <cm-placeholder [dark]="true"></cm-placeholder>
                        <cm-placeholder [dark]="true"></cm-placeholder>
                        <cm-placeholder [dark]="true"></cm-placeholder>
                        <cm-placeholder [dark]="true"></cm-placeholder>
                    </div>
                }
            }
        </div>
    </cm-drop-files>
</div>
<cm-image-viewer #imageViewer></cm-image-viewer>
