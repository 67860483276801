import {ImageOpContextWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-webgl2"
import {
    AddressSpace,
    DataType,
    ImageDescriptor,
    ImageDescriptorWithOptionals,
    ImageRef,
    ImageRefId,
    isImageDescriptor,
    isImageDescriptorWithOptionals,
    isImageRef,
    ManagedImageRef,
    RefCountedImageRef,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ReplaceAWithB} from "ts-lib/dist/browser/utils/type"
import {ImagePtrWebGl2} from "@app/textures/texture-editor/operator-stack/image-op-system/image-webgl2"
import {
    PainterCompositorRef,
    PainterPrimitiveRef,
    PainterRefByType,
    PainterType,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/painter-ref"
import {assertNever} from "ts-lib/dist/browser/utils/utils"
import {
    getFullScopeName,
    ImageOpCommandQueueBase,
    ImageOpScope,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-base"
import {IsDefined} from "ts-lib/dist/browser/utils/filter"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {HalPainterImageCompositor} from "@common/models/hal/hal-painter-image-compositor"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"
import {HalPainterParameterValueType} from "@common/models/hal/hal-painter/types"
import {HalPainterImageCompositorOptions} from "@common/models/hal/hal-painter-image-compositor/types"
import {HalPainterPrimitiveOptions} from "@common/models/hal/hal-painter-primitive/types"
import {ColorLike, Vector2Like} from "ts-lib/dist/browser/math"
import {fillImageDescriptorOptionals} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

const TRACE = TextureEditorSettings.EnableFullTrace

export class ImageOpCommandQueueWebGL2 extends ImageOpCommandQueueBase {
    constructor(readonly context: ImageOpContextWebGL2) {
        super(context)
    }

    // convenience method
    prepareResultImage(
        resultImageOrDataType: ImageRef | DataType | undefined,
        descriptorOrImageRef: ImageDescriptor | ImageRef,
        verifyAndSetSize = true,
    ): ImageRef {
        if (resultImageOrDataType) {
            const descriptor = isImageDescriptor(descriptorOrImageRef) ? descriptorOrImageRef : descriptorOrImageRef.descriptor
            if (isImageRef(resultImageOrDataType)) {
                // check for compatible size and set batch size
                if (verifyAndSetSize) {
                    if (descriptor.width !== resultImageOrDataType.descriptor.width || descriptor.height !== resultImageOrDataType.descriptor.height) {
                        throw new Error("Result image size does not match descriptor")
                    }
                    resultImageOrDataType.descriptor.batchSize = descriptor.batchSize
                }
                return resultImageOrDataType
            } else {
                return this.createImage({
                    ...descriptor,
                    dataType: resultImageOrDataType,
                })
            }
        } else {
            return this.createImage(descriptorOrImageRef)
        }
    }

    createImage(descriptorOrImageRef: ImageDescriptorWithOptionals | ImageRef): ImageRef {
        const descriptor = isImageDescriptorWithOptionals(descriptorOrImageRef)
            ? fillImageDescriptorOptionals(descriptorOrImageRef)
            : descriptorOrImageRef.descriptor
        return this.context.createTemporaryImage(descriptor)
    }

    createPainter<T extends PainterType>(type: T, name: string, source: string): PainterRefByType<T> {
        return this.context.createPainter(type, name, source)
    }

    lambda(lambda: LambdaFunction) {
        this.queueEntries.push({type: "lambda", lambda})
    }

    // TODO improve type safety (currently you can still pass in the wrong parameters for a given painter type)
    paint<T extends PainterType>(painterRef: PainterRefByType<T>, parameters: PaintParametersByType<T>) {
        switch (painterRef.type) {
            case "compositor":
                this.queueEntries.push({...(parameters as PaintParametersCompositor), painterRef, type: "paint", paintType: "compositor", scope: this.scope})
                break
            case "primitive":
                this.queueEntries.push({...(parameters as PaintParametersPrimitive), painterRef, type: "paint", paintType: "primitive", scope: this.scope})
                break
            default:
                assertNever(painterRef)
        }
    }

    // keep a imageRef alive until it is manually released via the returned ManagedImageRef (useful for caching intermediate results which are later used in an independent queue)
    keepAlive(imageRef: ImageRef): ManagedImageRef {
        const refCountedImageRef = new RefCountedImageRef(imageRef.addressSpace, imageRef.id, imageRef.descriptor, () => {
            const image = this.keptAliveImageRefs.get(managedImageRef)
            if (!image) {
                throw new Error("Image not found")
            }
            image.then((image) => image.release())
            this.keptAliveImageRefs.delete(managedImageRef)
        })
        const managedImageRef = new ManagedImageRef(refCountedImageRef)
        refCountedImageRef.release()
        const promisedImage = this.context.getImage(imageRef)
        this.keptAliveImageRefs.set(managedImageRef, promisedImage)
        return managedImageRef
    }

    async execute(imageRefsToEvaluate: ImageRef[], options?: {waitForCompletion?: boolean}): Promise<ExecutionResult<ImageRef[]>> {
        if (TRACE) {
            console.log("Executing ImageOpCommandQueueWebGL2")
            console.log(`ImageRefs to evaluate: ${imageRefsToEvaluate.map((imageRef) => imageRef.addressSpace + "[" + imageRef.id + "]").join(", ")}`)
        }

        const waitForCompletion = options?.waitForCompletion ?? false // default: false

        // collect at which queue index each result image is referenced last (either as a source or as a result)
        const imageRefHandleByAddressSpaceByImageRefId = new Map<AddressSpace, Map<ImageRefId, ImageRefHandle>>()
        const mapImageRefToHandle = (imageRef: ImageRef) => {
            let imageRefHandleByImageRef = imageRefHandleByAddressSpaceByImageRefId.get(imageRef.addressSpace)
            if (!imageRefHandleByImageRef) {
                imageRefHandleByImageRef = new Map()
                imageRefHandleByAddressSpaceByImageRefId.set(imageRef.addressSpace, imageRefHandleByImageRef)
            }
            let handle = imageRefHandleByImageRef.get(imageRef.id)
            if (!handle) {
                handle = {addressSpace: imageRef.addressSpace, id: imageRef.id}
                imageRefHandleByImageRef.set(imageRef.id, handle)
            }
            return handle
        }
        // const imageRefHandleByIdByAddressSpace = new Map<AddressSpace, Map<ImageRefId, ImageRefHandle>>()
        // this.paintCalls.forEach((paintCall) => {
        //     const handle = imageRefHandleByIdByAddressSpace.get(paintCall.resultImage
        // })

        const resultImageRefHandlesToCheck = new Set<ImageRefHandle>(
            this.queueEntries.filter(isPaintQueueEntry).map((queueEntry) => mapImageRefToHandle(queueEntry.resultImage)),
        )
        const resultImageRefHandlesToReleaseByQueueIndex = new Map<number, Set<ImageRefHandle>>()
        const resultImageRefHandlesToEvaluate = new Set<ImageRefHandle>()
        imageRefsToEvaluate.forEach((imageRef) => {
            const handle = mapImageRefToHandle(imageRef)
            resultImageRefHandlesToEvaluate.add(handle)
            resultImageRefHandlesToCheck.delete(handle)
        })
        resultImageRefHandlesToReleaseByQueueIndex.set(this.queueEntries.length, resultImageRefHandlesToEvaluate)

        for (let queueIndex = this.queueEntries.length - 1; queueIndex >= 0; queueIndex--) {
            const queueEntry = this.queueEntries[queueIndex]
            if (!isPaintQueueEntry(queueEntry)) {
                continue
            }
            const sourceImageRefs = queueEntry.sourceImages
                ? Array.isArray(queueEntry.sourceImages)
                    ? queueEntry.sourceImages
                    : [queueEntry.sourceImages]
                : []
            const resultImageRef = queueEntry.resultImage
            const referencedImageRefs = [resultImageRef, ...sourceImageRefs.filter(IsDefined)]
            referencedImageRefs.forEach((imageRef) => {
                const handle = mapImageRefToHandle(imageRef)
                if (resultImageRefHandlesToCheck.has(handle)) {
                    let resultImageRefHandlesToRelease = resultImageRefHandlesToReleaseByQueueIndex.get(queueIndex)
                    if (!resultImageRefHandlesToRelease) {
                        resultImageRefHandlesToRelease = new Set()
                        resultImageRefHandlesToReleaseByQueueIndex.set(queueIndex, resultImageRefHandlesToRelease)
                    }
                    resultImageRefHandlesToRelease.add(handle)
                    resultImageRefHandlesToCheck.delete(handle)
                }
            })
        }

        // TODO prune paintCalls that do not contribute to the result

        // run queue
        const resultImagesByImageRefHandle = new Map<ImageRefHandle, ImagePtrWebGl2[]>()
        let queueIndex = 0
        for (const queueEntry of this.queueEntries) {
            if (queueEntry.type === "lambda") {
                const result = queueEntry.lambda()
                if (result instanceof Promise) {
                    await result
                }
                continue
            }
            // get source images
            const sourceImageRefs = queueEntry.sourceImages
                ? Array.isArray(queueEntry.sourceImages)
                    ? queueEntry.sourceImages
                    : [queueEntry.sourceImages]
                : []
            const sourceImages = await Promise.all(sourceImageRefs.map((imageRef) => (imageRef ? this.context.getImage(imageRef) : undefined)))
            const painter = await this.context.getPainter(queueEntry.painterRef)
            if (queueEntry.parameters) {
                Object.entries(queueEntry.parameters).forEach(([key, value]) => painter.setParameter(key, value))
            }
            const halSourceImages = sourceImages.map((sourceImage) => (sourceImage ? sourceImage.ref.halImage : undefined))
            // paint
            if (TRACE) {
                console.log(`[${queueIndex}] Painting ${queueEntry.painterRef.name}`)
                console.log(`  Scope: ${getFullScopeName(queueEntry.scope)}`)
                console.log(`  Parameters: ${JSON.stringify(queueEntry.parameters)}`)
                console.log(`  Source images: ${sourceImageRefs.map((imageRef) => imageRef?.addressSpace + "[" + imageRef?.id + "]").join(", ")}`)
                console.log(`  Options: ${JSON.stringify(queueEntry.options)}`)
                console.log(`  Target image: ${queueEntry.resultImage.addressSpace}[${queueEntry.resultImage.id}]`)
            }
            // get target image
            const resultImage = await this.context.getImage(queueEntry.resultImage)
            const resultImageHandle = mapImageRefToHandle(queueEntry.resultImage)
            let resultImages = resultImagesByImageRefHandle.get(resultImageHandle)
            if (!resultImages) {
                resultImages = []
                resultImagesByImageRefHandle.set(resultImageHandle, resultImages)
            }
            resultImages.push(resultImage)
            // execute painter
            switch (queueEntry.paintType) {
                case "compositor": {
                    const painterCompositor = painter as HalPainterImageCompositor
                    await painterCompositor.paint(resultImage.ref.halImage, halSourceImages, queueEntry.options)
                    break
                }
                case "primitive": {
                    const painterPrimitive = painter as HalPainterPrimitive
                    painterPrimitive.clearGeometry()
                    painterPrimitive.addVertices(queueEntry.vertices.positions, queueEntry.vertices.uvs, queueEntry.vertices.colors)
                    painterPrimitive.addIndices(queueEntry.indices)
                    halSourceImages.forEach((sourceImage, index) => painterPrimitive.setSourceImage(index, sourceImage))
                    await painterPrimitive.paint(resultImage.ref.halImage, queueEntry.options)
                    break
                }
            }
            // release source images
            sourceImages.forEach((sourceImage) => sourceImage?.release())
            // release target image which are not referenced from later paint calls
            const resultImageRefHandlesToRelease = resultImageRefHandlesToReleaseByQueueIndex.get(queueIndex)
            if (resultImageRefHandlesToRelease) {
                if (TRACE) {
                    console.log(`[${queueIndex}] Releasing target images`)
                    console.log(
                        `  Images: ${Array.from(resultImageRefHandlesToRelease)
                            .map((imageRefHandle) => imageRefHandle.addressSpace + "[" + imageRefHandle.id + "]")
                            .join(", ")}`,
                    )
                }
                resultImageRefHandlesToRelease.forEach((imageRefHandle) => {
                    const resultImages = resultImagesByImageRefHandle.get(imageRefHandle)
                    if (!resultImages) {
                        throw new Error("Image not found")
                    }
                    resultImagesByImageRefHandle.delete(imageRefHandle)
                    resultImages.forEach((resultImage) => resultImage.release())
                })
            }
            queueIndex++
        }

        // collect result
        const result = await Promise.all(
            imageRefsToEvaluate.map(async (imageRef) => {
                const handle = mapImageRefToHandle(imageRef)
                const resultImages = resultImagesByImageRefHandle.get(handle)
                if (resultImages && resultImages.length > 0) {
                    return new ImagePtrWebGl2(resultImages[0])
                } else {
                    // the image was not the result of a paint call, so it must be a source image
                    return await this.context.getImage(imageRef)
                }
            }),
        )
        if (TRACE) {
            console.log("Result:")
            result.forEach((image) => {
                console.log(`  ${image.ref.addressSpace}[${image.ref.id}]`)
            })
        }

        // release all remaining images
        resultImagesByImageRefHandle.forEach((resultImages) => resultImages.forEach((resultImage) => resultImage.release()))

        // flush
        await this.context.flush(waitForCompletion)

        return result
    }

    private queueEntries: QueueEntry[] = []
    private keptAliveImageRefs = new Map<ManagedImageRef, Promise<ImagePtrWebGl2>>()
}

type ExecutionResult<RootNodeType> = ReplaceAWithB<RootNodeType, ImageRef, ImagePtrWebGl2>

export type PaintParametersBase = {
    parameters?: {[key: string]: HalPainterParameterValueType}
    sourceImages?: ImageRef | (ImageRef | undefined)[]
    resultImage: ImageRef
}

export type PaintParametersCompositor = PaintParametersBase & {
    options?: HalPainterImageCompositorOptions
}

export type PaintParametersPrimitive = PaintParametersBase & {
    options?: HalPainterPrimitiveOptions
    vertices: {
        positions: Vector2Like[]
        uvs?: Vector2Like[]
        colors?: ColorLike | ColorLike[]
    }
    indices: number[]
}

export type PaintParametersByType<T extends PainterType> = T extends "compositor"
    ? PaintParametersCompositor
    : T extends "primitive"
      ? PaintParametersPrimitive
      : never

export type PaintCallBase = {
    type: "paint"
    scope: ImageOpScope | undefined
}
export type PaintCallCompositor = PaintCallBase & {
    paintType: "compositor"
    painterRef: PainterCompositorRef
} & PaintParametersCompositor

export type PaintCallPrimitive = PaintCallBase & {
    paintType: "primitive"
    painterRef: PainterPrimitiveRef
} & PaintParametersPrimitive

export type QueueEntryPaintCall = PaintCallCompositor | PaintCallPrimitive

export type LambdaFunction = () => Promise<void> | void

export type QueueEntryLambda = {
    type: "lambda"
    lambda: LambdaFunction
}

export type QueueEntry = QueueEntryPaintCall | QueueEntryLambda

type ImageRefHandle = {
    addressSpace: AddressSpace
    id: ImageRefId
}

function isPaintQueueEntry(entry: QueueEntry): entry is QueueEntryPaintCall {
    return entry.type === "paint"
}
