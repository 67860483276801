<cm-routed-dialog [dialogSize]="dialogSizes.Large" [dialogScroll]="false" [needsConfirmationToClose]="false">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <link href="https://storage.googleapis.com/cm-platform-prod-static/fonts/fontawesome-pro-6.0.0-alpha3/css/all.css" rel="stylesheet" />
    <ng-container cm-overlay-content>
        <div class="cm-main-card">
            <div class="cm-menu-section">
                <cm-config-menu [uiStyleOverride]="'accordion'" [accordionStyle]="'manualClose'"></cm-config-menu>
            </div>
            <div class="cm-viewer-section">
                <!-- <cm-configurator-new [templateUuid]="'ecd43809-b53f-4581-83af-2e83abb58cb9'"></cm-configurator-new> -->
                <!-- dove -->
                <!-- <cm-configurator-new [templateUuid]="'22fee4a0-dc34-40c3-a2d3-290126fb8111'"></cm-configurator-new> -->
                <!-- lookdev -->
                <!-- <cm-configurator-new [templateUuid]="'1bcc23fa-3840-4b5f-92d6-d1c0dc342ae6'"></cm-configurator-new> -->
                <!-- <cm-configurator-new [templateUuid]="'d12dfd86-a8fb-46d9-81ab-5491866ce126'"></cm-configurator-new> -->
                <!-- cameo / vado -->
                <!-- <cm-configurator-new [templateUuid]="'d22c314a-e8f2-48f5-ad61-40754e7b5224'"></cm-configurator-new> -->
                <!-- material test -->
                <cm-configurator-new [templateUuid]="'fbb820a7-fadd-4489-8b4d-f7d8363871d4'"></cm-configurator-new>
            </div>
        </div>
    </ng-container>
</cm-routed-dialog>
