<div class="cm-unauthorized-container">
    <mat-card appearance="outlined" class="cm-unauthorized-card">
        <mat-card-content>
            <div class="cm-icon-container">
                <mat-icon>block</mat-icon>
            </div>
        </mat-card-content>
        <mat-card-title>Unauthorized.</mat-card-title>
        <span>You do not have permission to access this page.</span>
    </mat-card>
</div>
