import {Component, computed, DestroyRef, ElementRef, inject, Input, OnDestroy, OnInit, signal, ViewChild} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ContentTypeModel} from "@api"
import {ImageViewerComponent} from "@common/components/viewers"
import {DropFilesComponent} from "@common/components/files"
import {ColormassEntity} from "@common/models/abilities"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {ThumbnailsService} from "@platform/services/thumbnails/thumbnails.service"
import {GalleryImageService} from "@common/services/gallery-image/gallery-image.service"
import {IsTruthy} from "@cm/lib/utils/filter"
import {EndpointUrls} from "@common/models/constants/urls"
import {MimeType} from "@legacy/helpers/utils"
import {BehaviorSubject, filter, map, switchMap} from "rxjs"
import {MemoizePipe} from "@app/common/pipes/memoize/memoize.pipe"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"

@Component({
    selector: "cm-gallery-image",
    standalone: true,
    templateUrl: "./gallery-image.component.html",
    styleUrl: "./gallery-image.component.scss",
    imports: [DataObjectThumbnailComponent, ImageViewerComponent, DropFilesComponent, MemoizePipe],
})
export class GalleryImageComponent<
        ItemType extends {
            id: string
            galleryImage?: {id: string}
            organization?: {
                id: string
            }
        } & ColormassEntity,
    >
    implements OnInit, OnDestroy
{
    item$ = new BehaviorSubject<ItemType | null | undefined>(undefined)

    @Input({required: true}) set item(value: ItemType | null | undefined) {
        this.item$.next(value)
    }
    @ViewChild("imageViewer", {static: true}) imageViewer!: ImageViewerComponent

    destroyRef = inject(DestroyRef)
    elementRef = inject(ElementRef)
    galleryImageService = inject(GalleryImageService)
    notifications = inject(NotificationsService)
    permission = inject(PermissionsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    thumbnails = inject(ThumbnailsService)
    uploadService = inject(UploadGqlService)
    $can = this.permission.$to

    uploadProcessingUrl = EndpointUrls.DATA_OBJECT_PROCESSING_URL

    $galleryImage = signal<{id: string} | undefined>(undefined)
    $mouseHovering = signal<boolean>(false)
    $showDropzone = computed(
        () => !this.$galleryImage() && this.$mouseHovering() && (this.item$.value ? this.$can().update.item(this.item$.value, "galleryImage") : false),
    )

    ngOnInit() {
        this.item$
            .pipe(
                switchMap((item) => this.refresh.observeItem$(item).pipe(map(() => item))),
                takeUntilDestroyed(this.destroyRef),
                filter(IsTruthy),
                map((item) => this.$galleryImage.set(item.galleryImage)),
            )
            .subscribe()

        this.elementRef.nativeElement.addEventListener("mouseenter", this.onMouseEnter.bind(this))
        this.elementRef.nativeElement.addEventListener("mouseleave", this.onMouseLeave.bind(this))
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.removeEventListener("mouseenter", this.onMouseEnter.bind(this))
        this.elementRef.nativeElement.removeEventListener("mouseleave", this.onMouseLeave.bind(this))
    }

    onMouseEnter() {
        this.$mouseHovering.set(true)
    }

    onMouseLeave() {
        this.$mouseHovering.set(false)
    }

    openImageViewer(): void {
        if (this.$showDropzone()) return
        const galleryImage = this.$galleryImage()
        if (galleryImage) {
            this.imageViewer.openViewer(galleryImage.id, [galleryImage.id])
        } else {
            this.notifications.showInfo("No images to display")
        }
    }

    async assignGalleryImage(files: File[]) {
        if (files.length !== 1) {
            this.notifications.showError("Please drop a single image file")
            return
        }
        const item = this.item$.value
        const organizationId = item?.__typename === ContentTypeModel.Organization ? item.id : item?.organization?.id
        if (!(item?.id && organizationId)) {
            this.notifications.showError("Missing item or item organization details")
            return
        }
        const dataObject = await this.uploadService.createAndUploadDataObject(files[0], {organizationId: organizationId}, {processUpload: true})
        await this.galleryImageService.assign(item, dataObject.id)
        await this.thumbnails.waitUntilAvailable(dataObject.id, false)
        this.refresh.item(item)
    }

    MimeType = MimeType
}
