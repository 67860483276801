import {DeclareTemplateNode} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {z} from "zod"
import {NamedNodeParameters, namedNodeParameters} from "./named-node"
import {VisitorNodeVersion} from "@src/graph-system/declare-visitor-node"
import {versionChain} from "@src/graph-system/node-graph"

const dataObjectReferenceParameters = namedNodeParameters.merge(
    z.object({
        dataObjectId: z.number(),
    }),
)
export type DataObjectReferenceParameters = z.infer<typeof dataObjectReferenceParameters>

type V0 = {
    dataObjectId: number
}

type V1 = V0 & NamedNodeParameters
const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        return {name: "Data Object Reference", ...parameters}
    },
}

@registerNode
export class DataObjectReference
    extends DeclareTemplateNode({parameters: dataObjectReferenceParameters}, {}, {nodeClass: "DataObjectReference", versionChain: versionChain([v0])})
    implements EvaluableTemplateNode<TemplateImageDataNew>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return scope.struct<TemplateImageDataNew>("TemplateImageDataNew", {
            dataObject: evaluator.fetchDataObject(scope, this),
        })
    }
}

export type DataObjectReferenceFwd = TemplateNode<DataObjectReferenceParameters> & EvaluableTemplateNode<TemplateImageDataNew>
