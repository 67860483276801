import {CommonModule} from "@angular/common"
import {Component, computed, effect, inject, OnInit, Signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialogRef} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Params, RouterModule} from "@angular/router"
import {
    ContentTypeModel,
    DataObjectAssignmentType,
    MutationCreatePictureInput,
    MutationUpdatePictureInput,
    NextActor,
    OrganizationType,
    PaymentState,
    PictureOrderByCriteria,
    PicturesGridItemFragment,
    PicturesGridItemsGQL,
    PicturesGridItemsQuery,
    PictureState,
    SortOrder,
    TemplateState,
    TemplateType,
    UploadServiceDataObjectFragment,
} from "@api"
import {createLinkAddTemplateRevisionFromPictures, createLinkToEditorFromPictures} from "@app/common/helpers/routes"
import {TemplateEditorType} from "@app/templates/helpers/editor-type"
import * as TextureEditNodesUtils from "@app/textures/texture-editor/texture-edit-nodes-utils"
import {IsDefined} from "@cm/lib/utils/filter"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {CommentBoxesComponent} from "@common/components/comment-boxes/comment-boxes.component"
import {DialogRenameComponent} from "@common/components/dialogs/dialog-rename/dialog-rename.component"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent, PinnedFilterComponent, PinnedFilterOptionComponent, TagSearchFilterComponent} from "@common/components/filters"
import {DraggableItemListComponent, ItemFiltersComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {TabsComponent} from "@common/components/tabs/tabs.component"
import {BatchDownloadData, BatchUpdateProperty} from "@common/models/item/list-item"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {Enums} from "@enums"
import {Labels, StateLabel} from "@labels"
import {UtilsService} from "@legacy/helpers/utils"
import {Action, MoveCopyPictureDialogResult, MovePictureDialogComponent} from "@platform/components/pictures/move-picture-dialog/move-picture-dialog.component"
import {ProjectTreeComponent} from "@platform/components/pictures/project-tree/project-tree.component"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {AssignUserDialogComponent} from "@platform/components/users/assign-user-dialog/assign-user-dialog.component"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {CancellableRequest} from "@platform/models/data/cancellable-request"
import {firstValueFrom, from, map, of, Subscription, switchMap} from "rxjs"

@Component({
    imports: [
        AssignUserDialogComponent,
        AssignUserComponent,
        CardErrorComponent,
        CardPlaceholderComponent,
        CheckboxesFilterComponent,
        CommentBoxesComponent,
        CommonModule,
        EntityCardComponent,
        EntityResponsiveSidebarComponent,
        InfiniteListComponent,
        ItemFiltersComponent,
        ListInfoComponent,
        MatMenuModule,
        MatTooltipModule,
        MemoizePipe,
        PinnedFilterComponent,
        PinnedFilterOptionComponent,
        ProjectTreeComponent,
        RouterModule,
        StateLabelComponent,
        TabsComponent,
        TagSearchFilterComponent,
        PaymentStateLabelComponent,
    ],
    selector: "cm-pictures",
    standalone: true,
    styleUrls: ["./pictures-grid.component.scss"],
    templateUrl: "./pictures-grid.component.html",
})
export class PicturesGridComponent
    extends DraggableItemListComponent<PicturesGridItemFragment, MutationUpdatePictureInput, MutationCreatePictureInput>
    implements OnInit
{
    public stateLabels: StateLabel<PictureState>[] = Array.from(Labels.PictureState.values())
    public batchDownloadData: BatchDownloadData<PicturesGridItemFragment>[] = []

    public $tabs: Signal<{title: string; icon: string; id: "projects" | "filters"}[]> = computed(() => {
        if (this.sdk.$silo()?.organization?.type === OrganizationType.Photographer) {
            return [
                {
                    title: "Projects",
                    icon: "folder",
                    id: "projects",
                },
            ]
        } else {
            return [
                {
                    title: "Projects",
                    icon: "folder",
                    id: "projects",
                },
                {
                    title: "Filters",
                    icon: "filter",
                    id: "filters",
                },
            ]
        }
    })

    selectedTab: "projects" | "filters" = "projects"
    $organization = computed<{id: string} | null | undefined>(() => undefined)

    setId?: string
    projectId?: string

    public utils = inject(UtilsService)

    dataObjectUpdateSubscriptionsMap = new Map<string, Subscription>()
    visibleUsers: StateLabel<string>[] | null = null

    private fetchRequest = new CancellableRequest<PicturesGridItemsQuery>(inject(PicturesGridItemsGQL), this.sdk, this.destroyRef)

    constructor() {
        super()

        // if the currently selected tab disappears due to permissions, switch to the first available tab
        effect(() => {
            if (!this.$tabs().some((tab) => tab.id === this.selectedTab)) {
                this.selectedTab = this.$tabs()[0].id
            }
        })
    }

    override ngOnInit() {
        super.ngOnInit()

        this.route.queryParams
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap((queryParams) => from(this.updateSettingsFromParams(queryParams))),
            )
            .subscribe()

        this.batchDownloadData = this.getBatchDownloadData()

        if (this.auth.isStaff()) {
            this.sdk.gql.picturesGridVisibleUsers().then(({users}) => {
                this.visibleUsers = users.filter(IsDefined).map((user) => ({
                    label: user.name,
                    state: user.id,
                }))
            })
        }
    }

    getBatchDownloadData = (): BatchDownloadData<PicturesGridItemFragment>[] => {
        const imageExport = {
            pathInMenu: "Pictures",
            getDataObjectsForDownload: async (pictureListItem: PicturesGridItemFragment) => {
                const latestRevisionId = pictureListItem.latestRevision?.id
                if (!latestRevisionId) {
                    throw new Error("No latest revision found for picture")
                }
                const {
                    pictureRevision: {pictureData},
                } = await this.sdk.gql.picturesGridPictureData({id: latestRevisionId})
                const pictureDataItem = pictureData?.[0]?.dataObject
                if (!pictureDataItem) throw new Error("No data object found for picture")
                return [{path: pictureDataItem.originalFileName, dataObjectLegacyId: pictureDataItem.legacyId}]
            },
        }

        return [imageExport]
    }

    // OVERLOADS

    protected override _contentTypeModel = ContentTypeModel.Picture
    protected override _batchUpdate = (property: BatchUpdateProperty, value: string | boolean) =>
        this.sdk.gql
            .picturesGridBatchUpdatePictures({
                filter: this.filters.pictureFilter(),
                [property]: value,
            })
            .then(({batchUpdatePictures: count}) => count)

    protected override _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.fetchRequest
            .fetch({
                take,
                skip,
                filter: this.filters.pictureFilter(),
                orderBy:
                    this.selectedTab === "projects"
                        ? [
                              // {key: PictureOrderByCriteria.SetNumber, direction: SortOrder.Asc},
                              {key: PictureOrderByCriteria.Number, direction: SortOrder.Asc},
                              {key: PictureOrderByCriteria.Id, direction: SortOrder.Desc},
                          ]
                        : [
                              {key: PictureOrderByCriteria.PriorityOrder, direction: SortOrder.Asc},
                              {key: PictureOrderByCriteria.Id, direction: SortOrder.Desc},
                          ],
            })
            .then(({pictures, picturesCount}) => ({items: pictures, totalCount: picturesCount}))

    protected override _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .picturesGridItems({
                take: 1,
                filter: {
                    ...this.filters.pictureFilter(),
                    id: id ? {equals: id} : undefined,
                    legacyId: legacyId ? {equals: legacyId} : undefined,
                },
            })
            .then(({pictures}) => pictures?.[0] || undefined)

    protected override _updateItem = (data: MutationUpdatePictureInput) =>
        this.sdk.gql.picturesGridUpdatePicture({input: data}).then(({updatePicture}) => updatePicture)

    async onChangeTabEvent(selectedTab: "projects" | "filters") {
        const currentOrganizationId = this.route.snapshot.queryParams["organizationId"]
        await this.router.navigate([], {queryParams: {tab: selectedTab, organizationId: currentOrganizationId}})
    }

    async addPicture() {
        const setId = this.setId
        if (!setId) {
            this.notifications.showInfo("Select a Set/Subfolder in the Folders tab before adding a picture.")
            return
        }
        await this.notifications.withUserFeedback(
            async () => {
                const {createPicture: picture} = await this.sdk.gql.picturesGridCreatePicture({
                    input: {
                        setId,
                        state: PictureState.Draft,
                        paymentState: PaymentState.OrderPlaced,
                        nextActor: this.auth.$actingAsCustomer() ? NextActor.Customer : NextActor.Team1,
                    },
                })
                this.items = [{data: picture, placeholder: false, error: null}, ...this.items]
                this.refresh.contentTypeModel(ContentTypeModel.Picture)

                if (this.organizations.$current()?.type === OrganizationType.Photographer) {
                    await this.router.navigate(this.createLinkToEditor(picture), {
                        relativeTo: this.route,
                        queryParamsHandling: "preserve",
                    })
                }
            },
            {
                success: "Picture created.",
                error: "Cannot create picture.",
            },
        )
    }

    async updateSettingsFromParams(params: Params) {
        // photographers see only the project tab, so there should be no way to switch it
        if (this.sdk.$silo()?.organization?.type === OrganizationType.Photographer) {
            this.selectedTab = "projects"
        } else {
            this.selectedTab = params["tab"] ?? "filters"
        }

        if (this.selectedTab === "projects") {
            if (params["projectId"]) {
                this.projectId = params["projectId"]
                this.setId = undefined
            } else if (params["projectLegacyId"]) {
                this.projectId = undefined
                this.setId = undefined
                await this.sdk.gql.picturesGridProjectForPictures({projectLegacyId: parseInt(params["projectLegacyId"], 10)}).then(({project}) => {
                    this.projectId = project?.id
                    this.setId = undefined
                })
            } else {
                this.projectId = undefined
                if (params["setId"]) {
                    this.setId = params["setId"]
                } else if (params["setLegacyId"]) {
                    this.setId = undefined
                    await this.sdk.gql.picturesGridSetForPictures({setLegacyId: parseInt(params["setLegacyId"], 10)}).then(({set}) => {
                        this.setId = set?.id
                    })
                } else {
                    this.setId = undefined
                }
            }
        } else {
            this.projectId = undefined
            this.setId = undefined
        }
        if (params["organizationId"]) {
            this.$organization = this.organizations.$byId(params["organizationId"])
        } else {
            this.$organization = computed(() => (this.$can().read.organization() ? undefined : this.organizations.$current()))
        }
    }

    removePicture(selectedPicture: PicturesGridItemFragment) {
        this.items = this.items.filter((item) => item.data?.id !== selectedPicture.id)
    }

    async renamePicture(picture: PicturesGridItemFragment) {
        const dialogRef = this.dialog.open(DialogRenameComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: picture.name,
            },
        })
        dialogRef.componentInstance.onConfirm.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (name: string) =>
            this.notifications.withUserFeedback(
                async () => {
                    await this.sdk.gql.picturesGridUpdatePicture({
                        input: {
                            id: picture.id,
                            name,
                        },
                    })
                    this.refresh.item(picture)
                },
                {
                    success: "Changes saved.",
                    error: "Cannot rename picture.",
                },
            ),
        )
    }

    /**
     * Rename the picture's thumbnail
     */
    async renameDataObject(picture: PicturesGridItemFragment) {
        const latestRevisionId = picture.latestRevision?.id
        if (!latestRevisionId) {
            throw new Error("No latest revision found for picture")
        }
        const {
            pictureRevision: {pictureData},
        } = await this.sdk.gql.picturesGridPictureData({id: latestRevisionId})
        const dataObject = pictureData?.[0]?.dataObject
        if (dataObject) {
            const dialogRef = this.dialog.open(DialogRenameComponent, {
                disableClose: false,
                width: "400px",
                data: {
                    title: dataObject.originalFileName,
                },
            })
            dialogRef.componentInstance.onConfirm.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (title: string) =>
                this.notifications.withUserFeedback(
                    async () => {
                        await this.sdk.gql.picturesGridUpdateDataObject({
                            input: {
                                id: dataObject.id,
                                originalFileName: title,
                            },
                        })
                        this.refresh.item(picture)
                    },
                    {
                        success: "Changes saved.",
                        error: "Cannot rename file.",
                    },
                ),
            )
        }
    }

    copyTemplate(picture: PicturesGridItemFragment, setId: string | null = null): void {
        const getSetId = () => {
            if (setId !== null) return of(setId)

            const dialogRef = this.dialog.open(MovePictureDialogComponent, {
                disableClose: false,
                width: "400px",
                data: {
                    organizationId: picture.set.project.organization.id,
                    setId: picture.set.id,
                    action: Action.COPY_SCENE,
                },
            })

            return dialogRef.componentInstance.onConfirm.pipe(
                takeUntilDestroyed(this.destroyRef),
                map((result) => result.setId),
            )
        }

        getSetId().subscribe(async (setId) =>
            this.notifications.withUserFeedback(
                async () => {
                    const newPicture = await this.copyTemplateToSet(picture, setId)
                    this.refresh.item(newPicture)
                },
                {
                    success: "Picture copied.",
                    error: "Cannot copy picture.",
                },
            ),
        )
    }

    private async copyTemplateToSet(picture: PicturesGridItemFragment, setId: string) {
        const {createPicture: createdPicture} = await this.sdk.gql.picturesGridCreatePicture({
            input: {
                setId,
                nextActor: picture.nextActor,
                paymentState: PaymentState.OrderPlaced,
                state: PictureState.Draft,
            },
        })

        const {createTemplate: createdTemplate} = await this.sdk.gql.picturesGridCreateTemplate({
            input: {
                organizationId: createdPicture.organization.id,
                state: TemplateState.Draft,
                templateType: TemplateType.Scene,
                pictureId: createdPicture.id,
            },
        })

        if (picture.template) {
            const {template} = await this.sdk.gql.getTemplateWithLatestRevision({id: picture.template?.id})
            if (template.latestRevision) {
                await this.sdk.gql.createTemplateRevision({
                    input: {
                        completed: true,
                        graph: template.latestRevision.graph,
                        templateId: createdTemplate.id,
                    },
                })
            }
        }

        return createdPicture
    }

    movePicture(picture: PicturesGridItemFragment): void {
        const dialogRef = this.dialog.open(MovePictureDialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                organizationId: picture.set.project.organization.id,
                setId: picture.set.id,
                action: Action.MOVE_PICTURE,
            },
        })

        dialogRef.componentInstance.onConfirm.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (result: MoveCopyPictureDialogResult) =>
            this.notifications.withUserFeedback(
                async () => {
                    if (result.setId === picture.set.id) {
                        return
                    }
                    const {updatePicture: newPicture} = await this.sdk.gql.picturesGridUpdatePicture({
                        input: {
                            id: picture.id,
                            regenerateNumber: true,
                            setId: result.setId,
                        },
                    })
                    this.refresh.item(newPicture)
                },
                {
                    success: "Picture moved.",
                    error: "Cannot move picture.",
                },
            ),
        )
    }

    async deletePicture(picture: PicturesGridItemFragment) {
        const dialogRef: MatDialogRef<DialogComponent> = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Delete picture",
                message:
                    "Picture " +
                    picture.number +
                    " including the scene / template, all the revisions, comments and files will be deleted. " +
                    "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: "Delete picture",
                cancelLabel: "Cancel",
            },
        })
        await this.notifications.withUserFeedback(
            async () => {
                const confirmed = await firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
                if (confirmed) {
                    await this.sdk.gql.picturesGridDeletePicture({
                        id: picture.id,
                    })
                    const picturePollingSubscription = this.dataObjectUpdateSubscriptionsMap.get(picture.id)
                    if (picturePollingSubscription && !picturePollingSubscription.closed) {
                        picturePollingSubscription.unsubscribe()
                    }
                    this.refresh.item(picture)
                }
            },
            {
                success: "Picture deleted.",
                error: "Cannot delete picture.",
            },
        )
    }

    async setHighestPriority(picture: PicturesGridItemFragment) {
        if (this.selectedTab !== "projects") {
            const index = this.items.findIndex((item) => item.data?.id === picture.id)
            this.items.splice(index, 1)
            this.items.splice(0, 0, {data: picture, placeholder: false, error: null})
        }

        return this.updateItem({id: picture.id, priorityOrder: 0})
    }

    async dropZoneUploadSave(picture: PicturesGridItemFragment, dataObject: UploadServiceDataObjectFragment) {
        return this.notifications.withUserFeedback(
            async () => {
                await this.sdk.gql.picturesGridCreatePictureRevision({
                    input: {
                        pictureId: picture.id,
                        dataObjectAssignment: {
                            dataObjectId: dataObject.id,
                            type: DataObjectAssignmentType.PictureData,
                        },
                    },
                })
                await this.refreshItem(picture)
            },
            {
                error: "Cannot create picture revision with data object assignment.",
            },
        )
    }

    getEditorType(picture: PicturesGridItemFragment): TemplateEditorType {
        return picture.template?.latestRevision?.isLegacy ? TemplateEditorType.Old : TemplateEditorType.New
    }

    createLinkToEditor(picture: PicturesGridItemFragment): string[] {
        if (picture.template?.latestRevision) return createLinkToEditorFromPictures(picture.id, picture.template.latestRevision.id, this.getEditorType(picture))
        else return createLinkAddTemplateRevisionFromPictures(picture.id)
    }

    cardLink(picture: PicturesGridItemFragment) {
        if (this.organizations.$current()?.type === OrganizationType.Photographer) {
            return this.createLinkToEditor(picture)
        }

        return [picture.id, "revisions", picture.latestRevision?.number ?? "new"]
    }

    showInProgressOverlay(picture: PicturesGridItemFragment) {
        return (
            !this.sdk.$silo()?.systemRole &&
            picture.nextActor !== Enums.NextActor.Customer &&
            (picture.state === Enums.PictureState.Stage1 || picture.state == Enums.PictureState.Stage2 || picture.state == Enums.PictureState.FinalRender)
        )
    }

    protected readonly TextureEditNodesUtils = TextureEditNodesUtils
}
