<div class="cm-user-thumbnail" [class.cm-user-thumbnail--medium]="$size() === 'medium'">
    @if ($user()?.galleryImage?.id; as dataObjectId) {
        <cm-data-object-thumbnail
            class="cm-user-thumbnail-picture"
            [dataObjectId]="dataObjectId"
            [resolution]="DownloadResolution.Tiny"
            [layout]="ThumbnailLayout.Cover"
        ></cm-data-object-thumbnail>
    } @else {
        <i class="fa-solid fa-circle-user"></i>
    }
</div>
