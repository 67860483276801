import {animate, style, transition, trigger} from "@angular/animations"
import {AfterViewInit, Component, inject, OnInit, TemplateRef, ViewChild} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ContentTypeModel, UserAssignmentState, UserAssignmentType} from "@api"
import {BatchMenuItem, BatchUpdateProperty} from "@common/models/item/list-item"
import {fullName} from "@common/helpers/utils/user"
import {Paging} from "@common/models/constants"
import {GenericItemList} from "@common/models/item/generic-item-list"
import {AuthService} from "@common/services/auth/auth.service"
import {FiltersService} from "@common/services/filters/filters.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Enums} from "@enums"
import {Labels, StateLabel} from "@labels"
import {combineLatest, filter, ReplaySubject, startWith, Subject, switchMap, tap, throttleTime} from "rxjs"
import {IsDefined} from "@cm/lib/utils/filter"
import {BasePageComponent} from "@pages/base/base-page.component"

export type ItemListGqlItem<EntityType> =
    | {
          data: EntityType
          placeholder: false
          error: null
      }
    | {
          data: null
          placeholder: true
          error: null
      }
    | {
          data: null
          placeholder: false
          error: Error
      }

/**
 * Generic list of items. Implement a subclass for each item type.
 */
@Component({
    template: "",
    animations: [
        trigger("fadeInPlaceholder", [transition("void => *", [style({opacity: 0, scale: 0.9}), animate("600ms", style({opacity: 0.4, scale: 0.98}))])]),
        trigger("fadeInCard", [transition("void => *", [style({opacity: 0.6, scale: 0.98}), animate("200ms", style({opacity: 1, scale: 1}))])]),
    ],
    standalone: true,
})
export abstract class ItemListComponent<EntityType extends {id: string; legacyId: number}, UpdateType extends {id?: string | null}, CreateType>
    extends BasePageComponent
    implements GenericItemList<EntityType>, AfterViewInit, OnInit
{
    // the list of items to display - EntityType should be a GraphQL fragment
    public items: ItemListGqlItem<EntityType>[] = []
    // the total number of items matching the filters (not just the ones loaded)
    public totalCount: number | undefined = undefined

    public loadNextBatch = new Subject<void>()
    public pageFilledStatusChange = new ReplaySubject<boolean>()
    public pageFilled = false
    public readonly Enums = Enums
    public readonly Labels = Labels

    public userLabels: Map<string, StateLabel<string>> = new Map()

    @ViewChild("newItemDialog") newItemDialog?: TemplateRef<Element>

    public newItemData: Partial<CreateType> = {}
    public newItemDialogRef?: MatDialogRef<Element, CreateType>

    protected auth = inject(AuthService)
    dialog = inject(MatDialog)
    filters = inject(FiltersService)
    notifications = inject(NotificationsService)
    refresh = inject(RefreshService)
    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)
    snackBar = inject(MatSnackBar)
    $can = this.permission.$to

    override ngOnInit() {
        super.ngOnInit()

        this.sdk.gql.itemListVisibleUsers().then(({users}) => {
            this.userLabels = new Map(
                users.filter(IsDefined).map((user) => [
                    user.id,
                    {
                        label: user.name,
                        state: user.id,
                    },
                ]),
            )
        })

        // we reload the entire page when either the filters change (usually due to a URL update),
        // or the refresh service is told to refresh all data
        const reloadAllTrigger = combineLatest([this.filters.states, this.refresh.observeAllContentTypeModel$(this._contentTypeModel)]).pipe(
            tap(this._resetList),
        )
        // trigger loading a new batch of data either when the entire page has been reset,
        // when the loadNextBatch subject fires,
        // or when the visibility of the bottom of the list changes
        const loadBatchTrigger = combineLatest([
            reloadAllTrigger,
            this.loadNextBatch.pipe(startWith(null)),
            this.pageFilledStatusChange.pipe(
                tap((filled) => {
                    this.pageFilled = filled
                }),
                throttleTime(200),
            ),
        ])

        loadBatchTrigger
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter(() => !this.pageFilled || this.totalCount === undefined),
                switchMap(this.loadBatch),
                filter((value) => !!value),
            )
            .subscribe((batch) => (batch ? this._mergeResultsIntoList({...batch, errors: []}) : undefined))

        // subscribe to updates from the refresh service
        // this allows other components to trigger a refresh of a single item
        this.refresh.itemSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((item) => this.refreshItem(item))
    }

    ngAfterViewInit() {
        // scrolling doesn't happen in the window, so we need to scroll the main container by hand
        // in order to make sure that the page is scrolled to the top when initially loaded
        document.querySelector(".cm-app-main-container")?.scrollTo(0, 0)
    }

    // OVERLOADABLE OPERATIONS
    // implement these in a subclass using GraphQL queries/mutations
    // note that all methods prefixed with an underscore are not intended to be used directly
    // instead, use the public methods below
    // this is because we want to reduce boilerplate code in subclasses and only implement the core functionality

    /**
     * Update all items currently shown on the page at once, based on the current filter.
     * Implementation is optional and only required if batch update should be available.
     */
    protected readonly _batchUpdate:
        | ((
              _property: "addTagId" | "assignUserId" | "nextActor" | "paymentState" | "removeTagId" | "removeUserAssignment" | "state",
              _value: string | boolean,
          ) => Promise<number>)
        | undefined = undefined
    /**
     * Update items one-by-one currently shown on the page, based on the current filter.
     * Implementation is optional and only required if batch update with custom batch actions should be available.
     */
    protected readonly _singleBatchUpdate: ((_batchItem: BatchMenuItem<EntityType>, _item: EntityType) => Promise<void>) | undefined = undefined
    /**
     * Create an item after a "new item" dialog has been closed.
     */
    // TODO: make the "new item" dialog generic
    protected readonly _createItem: ((data: CreateType) => Promise<EntityType>) | undefined = undefined
    protected readonly openNewItem: () => boolean = () => true
    protected abstract readonly _fetchList: (_: {
        skip: number
        take: number
    }) =>
        | Promise<{items: (EntityType | null)[]; totalCount: number}>
        | Promise<{data: {items: (EntityType | null)[]; totalCount: number}; errors: Error[] | null}>
    protected abstract readonly _refreshItem: (_: {id?: string; legacyId?: number}) => Promise<EntityType | undefined>
    protected readonly _updateItem: ((_data: UpdateType) => Promise<EntityType>) | undefined = undefined
    protected abstract readonly _contentTypeModel: ContentTypeModel

    public batchUpdate = async (property: BatchUpdateProperty, value: string | boolean) => {
        if (this._batchUpdate) {
            const result = await this._batchUpdate(property, value)
            this.refresh.contentTypeModel(this._contentTypeModel)
            return result
        }
        return 0
    }

    public createItem = async (newItemData: CreateType): Promise<EntityType | undefined> => {
        const create = this._createItem
        if (!create) {
            throw new Error("Not implemented")
        }
        return this.notifications.withUserFeedback(
            async () => {
                const item = await create(newItemData)
                // need to reload the entire page to know where to insert the item and whether it is shown at all
                // (it might not match the current filter)
                this.refresh.contentTypeModel(this._contentTypeModel)
                return item
            },
            {
                success: "New item created.",
                error: (err: unknown) => (this.auth.isStaff() ? `${err}` : "Cannot create new item"),
            },
        )
    }

    protected loadBatch = async (): Promise<
        {skip: number; take: number; items: ItemListGqlItem<EntityType>[]; errors: Error[] | null; totalCount: number} | undefined
    > => {
        if (this.totalCount === undefined) {
            const skip = 0
            const take = Paging.ENTITY_PAGE_SIZE
            const fetchedData = await this._fetchList({skip, take})
            const items = "data" in fetchedData ? fetchedData.data.items : fetchedData.items
            const totalCount = "data" in fetchedData ? fetchedData.data.totalCount : fetchedData.totalCount
            return {
                skip,
                take,
                items: items.map((item) =>
                    item
                        ? {
                              data: item,
                              placeholder: false,
                              error: null,
                          }
                        : {
                              data: null,
                              placeholder: false,
                              error: new Error("Failed to load item."),
                          },
                ),
                errors: [],
                totalCount,
            }
        }
        if (this.items.length < this.totalCount) {
            this.items = [
                ...this.items,
                ...Array(Math.min(this.totalCount - this.items.length, Paging.ENTITY_PAGE_SIZE)).fill({placeholder: true, data: null, error: null}),
            ]
        }
        const skip = this.items.findIndex((item) => item.placeholder)
        if (skip === -1) {
            return undefined
        }
        const take = Math.min(this.items.length - skip, Paging.ENTITY_MAX_BATCH_SIZE)
        try {
            const fetchedData = await this._fetchList({skip, take})
            const items = "data" in fetchedData ? fetchedData.data.items : fetchedData.items
            const totalCount = "data" in fetchedData ? fetchedData.data.totalCount : fetchedData.totalCount
            return {
                skip,
                take,
                items: items.map((item) =>
                    item
                        ? {
                              data: item,
                              placeholder: false,
                              error: null,
                          }
                        : {
                              data: null,
                              placeholder: false,
                              error: new Error("Failed to load item."),
                          },
                ),
                errors: [],
                totalCount,
            }
        } catch (error) {
            if (error instanceof DOMException && error.name === "AbortError") {
                // ignore cancelled requests
                return undefined
            }
            console.error("fetch error", error)
            return undefined
        }
    }

    public getItems = async (skip: number, take: number): Promise<{items: (EntityType | null)[]; totalCount: number}> => {
        const result = await this._fetchList({skip, take})
        if ("data" in result) {
            return result.data
        }
        return result
    }

    protected _resetList = () => {
        this.totalCount = undefined
        // show some placeholders to make the page look less empty
        this.items = Array(Paging.ENTITY_PAGE_SIZE).fill({
            data: null,
            placeholder: true,
            error: null,
        })
    }

    protected _mergeResultsIntoList = ({
        skip,
        items,
        totalCount,
    }: {
        skip: number
        items: ItemListGqlItem<EntityType>[]
        errors: Error[]
        totalCount: number
    }) => {
        if (this.items.length > totalCount) {
            this.items = this.items.slice(0, totalCount)
        }
        if (this.items.length < skip + items.length) {
            this.items = [
                ...this.items,
                ...Array(skip + items.length - this.items.length).fill({
                    data: null,
                    placeholder: true,
                    error: null,
                }),
            ]
        }
        for (let i = skip; i < Math.min(skip + items.length, totalCount); i++) {
            this.items[i] = items[i - skip]
        }
        this.totalCount = totalCount
        if (!this.pageFilled) {
            this.loadNextBatch.next()
        }
    }

    /**
     * Query the item from the server and either update it or exclude it from the list of items based on the current filter.
     */
    public refreshItem = async (item: {id?: string; legacyId?: number}) => {
        const freshItem = await this._refreshItem({id: item.id, legacyId: item.legacyId})
        if (freshItem) {
            const itemIndex = this.items.findIndex((_item) => _item.data?.id === freshItem.id)
            if (itemIndex === -1) {
                this.refresh.contentTypeModel(this._contentTypeModel)
            } else {
                this.items = this.items.map((item) => {
                    if (item.data?.id === freshItem.id) {
                        return {
                            data: freshItem,
                            placeholder: false,
                            error: null,
                        }
                    }
                    return item
                })
            }
        } else {
            const previousLength = this.items.length
            if (item.id) {
                this.items = this.items.filter((_item) => _item.data?.id !== item.id)
            } else {
                this.items = this.items.filter((_item) => _item.data?.legacyId !== item.legacyId)
            }
            if (this.totalCount !== undefined) {
                this.totalCount -= previousLength - this.items.length
            }
        }
    }

    /**
     * Perform the update on the server and update the item in the list, with user feedback.
     */
    public updateItem = async (data: UpdateType): Promise<EntityType | undefined> => {
        const update = this._updateItem
        if (!update) {
            throw new Error("Not implemented")
        }
        return this.notifications.withUserFeedback(
            async () => {
                const item = await update(data)
                await this.refreshItem(item)
                return item
            },
            {
                success: "Changes saved.",
                error: "Cannot save changes.",
            },
        )
    }

    /**
     * Open a form dialog allowing the user to create a new item by entering data into the form.
     */
    openNewItemDialog() {
        if (!this.newItemDialog) {
            // put an `<ng-template #newItemDialog>` tag containing the form in the html template
            throw new Error("Missing newItemDialog ref in template")
        }
        this.newItemData = {...this._initialNewItemData()}
        this.newItemDialogRef = this.dialog.open(this.newItemDialog, {
            data: this.newItemData,
        })
        this.newItemDialogRef.afterClosed().subscribe(async (data) => {
            if (data) {
                await this.notifications.withUserFeedback(
                    async () => {
                        const newItem = await this.createItem(data)
                        if (newItem) {
                            this.items = [{data: newItem, placeholder: false, error: null}, ...this.items]

                            // need to reload the entire page to know where to insert the item and whether it is shown at all
                            // (it might not match the current filter)
                            this.refresh.contentTypeModel(this._contentTypeModel)

                            //If a specialization of this component wants to navigate to a different page, the navigation below leads to unexpected behavior
                            if (this.openNewItem()) {
                                setTimeout(() => {
                                    void this.router.navigate([newItem.id], {
                                        relativeTo: this.route,
                                        queryParamsHandling: "preserve",
                                    })
                                })
                            }

                            return newItem
                        }
                        return undefined
                    },
                    {
                        error: "Cannot create new item.",
                    },
                )
            }
            this.newItemDialogRef = undefined
        })
    }

    protected _initialNewItemData = () => ({})

    closeDialog() {
        this.newItemDialogRef?.close()
        this.newItemDialogRef = undefined
    }

    async updateAssignedUser(contentTypeModel: ContentTypeModel, item: {id: string; state: UserAssignmentState}, user: {id: string} | undefined) {
        try {
            if (user) {
                const {createUserAssignment} = await this.sdk.gql.createUserAssignment({
                    input: {
                        contentTypeModel,
                        objectId: item.id,
                        state: UserAssignmentState[item.state],
                        type: UserAssignmentType.Generic,
                        userId: user.id,
                        // delete previous assignment(s)
                        removeExisting: true,
                    },
                })
                await this.refreshItem(item)
                this.snackBar.open(`${fullName(createUserAssignment.user)} has been assigned.`, "", {duration: 2000})
            } else {
                await this.sdk.gql.deleteUserAssignments({
                    input: {
                        contentTypeModel,
                        objectId: item.id,
                        state: UserAssignmentState[item.state],
                        type: UserAssignmentType.Generic,
                    },
                })
                await this.refreshItem(item)
                this.snackBar.open("User assignment removed.", "", {duration: 2000})
            }
        } catch (error) {
            console.error(error)
            this.snackBar.open("Cannot save changes.", "", {duration: 2000})
        }
    }
}
