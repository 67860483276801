import {GlobalRenderConstants, SceneNodes, colorEqual} from "@cm/lib/templates/interfaces/scene-object"
import {ThreeObject, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import * as THREE from "three"
import {RectAreaLightUniformsLib} from "three/examples/jsm/lights/RectAreaLightUniformsLib"
import {DisplayMode, ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {ThreeStandHelper} from "@template-editor/helpers/three-stand-helper"
import {anyDifference, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {configThreeHelperObjectLayers, addObjectForHelperObjectsLayer} from "@template-editor/helpers/helper-objects"

RectAreaLightUniformsLib.init()

export class ThreeAreaLight extends ThreeObject<SceneNodes.AreaLight> {
    protected override renderObject: THREE.Group = new THREE.Group()
    private threeLight: THREE.RectAreaLight = new THREE.RectAreaLight(0xffffff, 1, 1, 1)
    private areaLightHelper: ThreeAreaLightHelper

    constructor(threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)
        this.areaLightHelper = new ThreeAreaLightHelper(this, threeSceneManagerService, onAsyncUpdate)

        this.renderObject.add(this.threeLight)
        addObjectForHelperObjectsLayer(this.threeLight)

        this.onDisplayModeChange(threeSceneManagerService.$displayMode())
    }

    override onDisplayModeChange(displayMode: DisplayMode) {
        //objects are added/removed only once internally
        if (displayMode === "editor") this.renderObject.add(this.areaLightHelper.getRenderObject())
        else if (displayMode === "configurator") this.renderObject.remove(this.areaLightHelper.getRenderObject())
    }

    override setup(sceneNode: SceneNodes.AreaLight) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["on", "color", "intensity", "width", "height"],
                (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") return colorEqual(valueA, valueB)
                    return valueA === valueB
                },
                ({on, color, intensity, width, height}) => {
                    this.threeLight.visible = on
                    this.threeLight.color.setRGB(...color)
                    this.threeLight.intensity = GlobalRenderConstants.lightIntensityScale * intensity
                    this.threeLight.width = width
                    this.threeLight.height = height
                    this.threeLight.castShadow = true
                },
            ),

            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform}) => {
                    updateTransform(transform, this.threeLight)
                },
            ),

            this.areaLightHelper.update(sceneNode),
        ])
    }

    override dispose() {
        this.areaLightHelper.dispose()
        this.threeLight.dispose()
    }

    override onSelectionChange(selected: boolean) {
        this.areaLightHelper.onSelectionChange(selected)
    }
}

class ThreeAreaLightHelper extends ThreeObject<SceneNodes.AreaLight> {
    protected override renderObject: THREE.Group = new THREE.Group()
    private localMesh = new THREE.Group()
    private globalMesh = new THREE.Group()
    private targetMesh = new THREE.Group()

    private proxyLine: THREE.Line<THREE.BufferGeometry, THREE.LineBasicMaterial>
    private proxyMesh: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
    private proxyLaser: THREE.Line<THREE.BufferGeometry, THREE.LineBasicMaterial>
    private proxyRays: THREE.LineSegments<THREE.BufferGeometry, THREE.LineBasicMaterial>
    private proxyTarget: THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>
    private proxyOccludedTarget: THREE.Mesh<THREE.BufferGeometry, THREE.MeshStandardMaterial>

    private standHelper: ThreeStandHelper

    private numRays = 5

    constructor(areaLight: ThreeAreaLight, threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void) {
        super(threeSceneManagerService, onAsyncUpdate)

        {
            const geometry = new THREE.BufferGeometry()
            geometry.setAttribute("position", new THREE.Float32BufferAttribute([0.5, 0.5, 0, -0.5, 0.5, 0, -0.5, -0.5, 0, 0.5, -0.5, 0, 0.5, 0.5, 0], 3))

            const material = new THREE.LineBasicMaterial({fog: false})
            this.proxyLine = new THREE.Line(geometry, material)
        }
        this.proxyMesh = new THREE.Mesh(new THREE.PlaneGeometry(), new THREE.MeshBasicMaterial({side: THREE.BackSide, fog: false}))
        {
            const geometry = new THREE.BufferGeometry()
            geometry.setAttribute("position", new THREE.Float32BufferAttribute(2 * 3 * this.numRays * this.numRays, 3))

            const material = new THREE.LineBasicMaterial({color: 0x666666, fog: false})
            this.proxyRays = new THREE.LineSegments(geometry, material)
        }

        this.localMesh.add(this.proxyLine)
        setThreeObjectPart(this.proxyLine, areaLight)
        this.localMesh.add(this.proxyMesh)
        setThreeObjectPart(this.proxyMesh, areaLight)
        this.localMesh.add(this.proxyRays)

        {
            const geometry = new THREE.BufferGeometry()
            geometry.setAttribute("position", new THREE.Float32BufferAttribute(6, 3))
            this.proxyLaser = new THREE.Line(geometry, new THREE.LineBasicMaterial({color: 0xee8888, fog: false}))
        }
        this.proxyTarget = new THREE.Mesh(new THREE.BoxGeometry(5, 5, 5), new THREE.MeshStandardMaterial({color: 0xff0000, fog: false}))

        this.proxyOccludedTarget = new THREE.Mesh(
            new THREE.BoxGeometry(5, 5, 5),
            new THREE.MeshStandardMaterial({color: 0xff0000, fog: false, transparent: true, opacity: 0.2, depthTest: false, depthWrite: false}),
        )
        this.standHelper = new ThreeStandHelper(threeSceneManagerService, onAsyncUpdate)

        this.globalMesh.add(this.proxyLaser)
        this.globalMesh.add(this.targetMesh)
        this.targetMesh.add(this.proxyTarget)
        this.targetMesh.add(this.proxyOccludedTarget)
        setThreeObjectPart(this.targetMesh, areaLight, "target")
        this.globalMesh.add(this.standHelper.getRenderObject())
        setThreeObjectPart(this.standHelper.getRenderObject(), areaLight)

        this.renderObject.add(this.localMesh)
        this.renderObject.add(this.globalMesh)

        configThreeHelperObjectLayers(this)
    }

    setup(sceneNode: SceneNodes.AreaLight) {
        return anyDifference([
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["color", "intensity"],
                (valueA, valueB) => {
                    if (typeof valueA === "object" && typeof valueB === "object") return colorEqual(valueA, valueB)
                    return valueA === valueB
                },
                ({color, intensity}) => {
                    this.proxyLine.material.color.copy(new THREE.Color(...color).multiplyScalar(intensity))
                    this.proxyMesh.material.color.copy(this.proxyLine.material.color)
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform", "target"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform, target}) => {
                    const {position} = updateTransform(transform, this.localMesh)

                    const transformMatrix = new THREE.Matrix4().setPosition(new THREE.Vector3(target.x, target.y, target.z))
                    updateTransform(transformMatrix, this.targetMesh)

                    const laserBuffer = this.proxyLaser.geometry.getAttribute("position") as THREE.Float32BufferAttribute
                    laserBuffer.setXYZ(0, position.x, position.y, position.z)
                    laserBuffer.setXYZ(1, target.x, target.y, target.z)
                    laserBuffer.needsUpdate = true
                },
            ),
            objectFieldsDifferent(sceneNode, this.parameters, ["width", "height"], undefined, ({width, height}) => {
                const scaleMatrix = new THREE.Matrix4().scale(new THREE.Vector3(width, height, 1))
                updateTransform(scaleMatrix, this.proxyLine)
                updateTransform(scaleMatrix, this.proxyMesh)
            }),
            objectFieldsDifferent(sceneNode, this.parameters, ["targeted"], undefined, ({targeted}) => {
                this.proxyLaser.visible = targeted
                this.proxyTarget.visible = targeted
                this.proxyOccludedTarget.visible = targeted
            }),
            this.updateRays(sceneNode),
            this.standHelper.update(sceneNode),
        ])
    }

    private updateRays(sceneNode: SceneNodes.AreaLight) {
        return objectFieldsDifferent(
            sceneNode,
            this.parameters,
            ["width", "height", "directionality", "intensity"],
            undefined,
            ({width, height, directionality, intensity}) => {
                const bufferPositionRays = this.proxyRays.geometry.getAttribute("position") as THREE.Float32BufferAttribute

                const directionalityRad = (Math.min(1.0, Math.max(0.0, directionality)) * Math.PI) / 2.0
                const edgefxy = Math.cos(directionalityRad)
                const edgefz = Math.sin(directionalityRad)
                const centerfz = 1.0
                const centerRayIdx = this.numRays % 2 === 1.0 ? Math.floor(this.numRays / 2.0) : -1

                for (let ix = 0; ix < this.numRays; ix++) {
                    for (let iy = 0; iy < this.numRays; iy++) {
                        const tx = (ix / (this.numRays - 1) - 0.5) * width * 0.9
                        const ty = (iy / (this.numRays - 1) - 0.5) * height * 0.9

                        let fx = 0.0
                        let fy = 0.0
                        let fz = 1.0

                        if (!(iy === centerRayIdx && ix === centerRayIdx)) {
                            const norm = Math.sqrt(tx ** 2 + ty ** 2)
                            const scaling =
                                (width / 2.0 / Math.abs(tx)) * Math.abs(ty) < height / 2.0 ? (2 * Math.abs(tx)) / width : (2.0 * Math.abs(ty)) / height
                            fx = (tx / norm) * scaling * edgefxy
                            fy = (ty / norm) * scaling * edgefxy
                            fz = scaling * edgefz + (1.0 - scaling) * centerfz
                        }

                        const fac = Math.sqrt(fx ** 2 + fy ** 2 + fz ** 2) / (2 * intensity)
                        bufferPositionRays.setXYZ(2 * (iy * this.numRays + ix), tx, ty, 0)
                        bufferPositionRays.setXYZ(2 * (iy * this.numRays + ix) + 1, tx + fx / fac, ty + fy / fac, -fz / fac)
                    }
                }

                bufferPositionRays.needsUpdate = true
            },
        )
    }

    override dispose() {
        this.proxyLine.geometry.dispose()
        this.proxyLine.material.dispose()
        this.proxyMesh.geometry.dispose()
        this.proxyMesh.material.dispose()
        this.proxyRays.geometry.dispose()
        this.proxyRays.material.dispose()

        this.proxyLaser.geometry.dispose()
        this.proxyLaser.material.dispose()
        this.proxyTarget.geometry.dispose()
        this.proxyTarget.material.dispose()
        this.proxyOccludedTarget.geometry.dispose()
        this.proxyOccludedTarget.material.dispose()
        this.standHelper.dispose()
    }

    override onSelectionChange(selected: boolean) {
        this.proxyTarget.visible = selected
        this.proxyOccludedTarget.visible = selected
        this.proxyLaser.visible = selected
    }
}
