import {Size2Like} from "ts-lib/dist/browser/math/size2"
import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {assertBatchSizeOne} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

export type ParameterType = {
    sourceImage: ImageRef
    resultSize: Size2Like
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export const imageOpResize: ImageOpType<ParameterType, ReturnType> = {
    name: "Resize",

    WebGL2: ({cmdQueue, parameters: {sourceImage, resultSize, resultImageOrDataType}}) => {
        assertBatchSizeOne(sourceImage.descriptor)
        // first we successively downsample to the nearest pow2 size which is still greater than the resultSize, then we resample to the final size
        let pow2ResampledSource = sourceImage
        // COMBINED (HORIZONTAL/VERTICAL) POW2 PASS
        // TODO: this could be optimized by using a single shader which does both horizontal and vertical downsampling in a single pass
        // HORIZONTAL POW2 PASS
        // the image should be shrinked horizontally; successively downsample by 2 until we get to the right size or just above it
        const halDownSampleH = cmdQueue.createPainter(
            "compositor",
            "downSampleH",
            `
            vec4 computeColor(ivec2 targetPixel) {
                vec4 c0 = texelFetch0(ivec2(targetPixel.x * 2 + 0, targetPixel.y), ADDRESS_MODE_CLAMP_TO_EDGE);
                vec4 c1 = texelFetch0(ivec2(targetPixel.x * 2 + 1, targetPixel.y), ADDRESS_MODE_CLAMP_TO_EDGE);
                return (c0 + c1) * 0.5;
            }
        `,
        )
        // eslint-disable-next-line no-constant-condition
        while (true) {
            if (resultSize.width * 2 > pow2ResampledSource.descriptor.width) {
                break
            }
            const downSampledSize = {
                width: Math.ceil(pow2ResampledSource.descriptor.width / 2),
                height: pow2ResampledSource.descriptor.height,
            }
            const nextDownSampledSource = cmdQueue.createImage({
                ...pow2ResampledSource.descriptor,
                ...downSampledSize,
            })
            cmdQueue.paint(halDownSampleH, {
                sourceImages: pow2ResampledSource,
                resultImage: nextDownSampledSource,
            })
            pow2ResampledSource = nextDownSampledSource
        }
        // VERTICAL POW2 PASS
        // the image should be shrinked vertically; successively downsample by 2 until we get to the right size or just above it
        const halDownSampleV = cmdQueue.createPainter(
            "compositor",
            "downSampleV",
            `
            vec4 computeColor(ivec2 targetPixel) {
                vec4 c0 = texelFetch0(ivec2(targetPixel.x, targetPixel.y * 2 + 0), ADDRESS_MODE_CLAMP_TO_EDGE);
                vec4 c1 = texelFetch0(ivec2(targetPixel.x, targetPixel.y * 2 + 1), ADDRESS_MODE_CLAMP_TO_EDGE);
                return (c0 + c1) * 0.5;
            }
        `,
        )
        // eslint-disable-next-line no-constant-condition
        while (true) {
            if (resultSize.height * 2 > pow2ResampledSource.descriptor.height) {
                break
            }
            const downSampledSize = {
                width: pow2ResampledSource.descriptor.width,
                height: Math.ceil(pow2ResampledSource.descriptor.height / 2),
            }
            const nextDownSampledSource = cmdQueue.createImage({
                ...pow2ResampledSource.descriptor,
                ...downSampledSize,
            })
            cmdQueue.paint(halDownSampleV, {
                sourceImages: pow2ResampledSource,
                resultImage: nextDownSampledSource,
            })
            pow2ResampledSource = nextDownSampledSource
        }
        // FINAL RESAMPLING PASS
        if (resultSize.width !== pow2ResampledSource.descriptor.width || resultSize.height !== pow2ResampledSource.descriptor.height) {
            const halResample = cmdQueue.createPainter(
                "compositor",
                "resample",
                `
                uniform vec2 u_texelStep;

                vec4 computeColor(ivec2 targetPixel) {
                    return texelFetchInterpolated0((vec2(targetPixel) + 0.5) * u_texelStep, ADDRESS_MODE_CLAMP_TO_EDGE); // +0.5 to sample the center of the texel
                }
            `,
            )
            resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, {
                ...pow2ResampledSource.descriptor,
                ...resultSize,
            })
            const texelStep = {
                x: resultSize.width > 1 ? (pow2ResampledSource.descriptor.width - 1) / (resultSize.width - 1) : 0,
                y: resultSize.height > 1 ? (pow2ResampledSource.descriptor.height - 1) / (resultSize.height - 1) : 0,
            }
            cmdQueue.paint(halResample, {
                parameters: {
                    u_texelStep: {type: "float2", value: texelStep},
                },
                sourceImages: pow2ResampledSource,
                resultImage: resultImageOrDataType,
            })
        } else if (resultImageOrDataType) {
            resultImageOrDataType = copyRegion(cmdQueue, {sourceImage: pow2ResampledSource, resultImageOrDataType})
        } else {
            resultImageOrDataType = pow2ResampledSource
        }
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, resultSize, resultImageOrDataType}}) => {
        assertBatchSizeOne(sourceImage.descriptor)
        const resultNode = cmdQueue.createImage(
            {
                ...sourceImage.descriptor,
                ...resultSize,
            },
            {type: "resize", input: sourceImage, width: resultSize.width, height: resultSize.height},
        )
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function resize(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpResize, parameters)
}
