// @ts-strict-ignore
import {SlicePipe} from "@angular/common"
import {Component, computed, inject, Input, OnInit, signal} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {BasicTagInfoFragment} from "@api"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {SearchComponent} from "@common/components/inputs/search/search.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {NodeReferenceSlotComponent} from "@editor/components/node-reference-slot/node-reference-slot.component"
import {IEditor} from "@editor/models/editor"
import {Labels} from "@labels"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {IsNonNull} from "@cm/lib/utils/filter"
import {AuthService} from "@common/services/auth/auth.service"
import {SdkService} from "@common/services/sdk/sdk.service"

@Component({
    selector: "cm-template-input-inspector",
    templateUrl: "./template-input-inspector.component.html",
    styleUrls: ["./template-input-inspector.component.scss"],
    standalone: true,
    imports: [
        InspectorSectionComponent,
        InputContainerComponent,
        StringInputComponent,
        NodeReferenceSlotComponent,
        SearchComponent,
        DropdownComponent,
        SlicePipe,
        MatTooltipModule,
    ],
})
export class TemplateInputInspectorComponent implements OnInit {
    @Input() editor: IEditor
    @Input() node: Nodes.TemplateInput | null = null

    public readonly Labels = Labels

    focusSearch = false
    searchTags?: BasicTagInfoFragment[]
    $searchText = signal<string | undefined>(undefined)
    $tagsForUser = signal<BasicTagInfoFragment[]>([])

    private auth = inject(AuthService)
    private sdk = inject(SdkService)

    isValidId = NodeUtils.isValidId

    updateNode(node: Nodes.TemplateInput): void {
        this.editor.sceneManager.markNodeChanged(node)
        this.getAssignedTags(node)
    }

    $assignedTags = signal<BasicTagInfoFragment[]>([])

    ngOnInit(): void {
        if (this.node) {
            this.getAssignedTags(this.node)
        }

        const userId = this.auth.$user()?.id
        if (userId) {
            void this.sdk.gql.templateInputInspectorTagsForUser({createdById: userId}).then(({tags}) => {
                this.$tagsForUser.set(tags)
            })
        }
    }

    $searchTags = computed(() => this.$tagsForUser().filter((tag) => tag.name.toLowerCase().search(this.$searchText()?.toLowerCase() ?? "") != -1))

    getTagIds(node: Nodes.TemplateInput): number[] {
        if (!node.tags) return []
        return node.tags.map((x) => x.tagId)
    }

    getTagById(legacyId: number): BasicTagInfoFragment | null {
        return this.$tagsForUser().find((item) => item.legacyId === legacyId)
    }

    getAssignedTags(node: Nodes.TemplateInput) {
        const tags = this.getTagIds(node)
            .map((id) => this.getTagById(id))
            .filter(IsNonNull)
        this.$assignedTags.set(tags)
    }

    addTag(node: Nodes.TemplateInput, tag: BasicTagInfoFragment): void {
        if (!node.tags) {
            node.tags = []
        }
        node.tags.push(
            Nodes.create<Nodes.TagReference>({
                type: "tagReference",
                name: tag.name,
                tagId: tag.legacyId,
            }),
        )
        this.updateNode(node)
    }

    removeTag(node: Nodes.TemplateInput, tag: BasicTagInfoFragment): void {
        if (!node.tags) return
        node.tags = node.tags.filter((x) => x.tagId !== tag.legacyId)
        this.updateNode(node)
    }
}
