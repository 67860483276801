import {Box2Like} from "ts-lib/dist/browser/math/box2"
import {Vector2Like} from "ts-lib/dist/browser/math/vector2"
import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {getHalAddressMode, getVec4Color} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {wrap} from "ts-lib/dist/browser/utils/utils"
import {HalPainterBlendMode} from "@common/models/hal/hal-painter/types"
import {ColorLike, Size2Like} from "ts-lib/dist/browser/math"
import {DataType, ImageDescriptor, ImageRef, isImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {assertBatchSizeOne} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"
import {isBox2Like} from "@cm/lib/math"

// either a box or an offsetImage (1x1 >=2-channel image for x/y offset) and offset is added to offsetImage along with the size
export type ExtRegion = Box2Like | ({offsetImage: ImageRef; offset?: Vector2Like} & Size2Like)

export type ParameterType = {
    sourceImage: ImageRef
    sourceRegion?: ExtRegion // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    targetOffset?: Vector2Like // default: {x: 0, y: 0}
    addressMode?: AddressMode // default: "wrap"
    borderColor?: ColorLike // default: {r: 0, g: 0, b: 0, a: 1}
    blendMode?: HalPainterBlendMode // default: "none"
    resultImageOrDataType?: ImageRef | DataType
    options?: {
        disableBatchSizeCheck?: boolean // default: false
    }
}

export type ReturnType = ImageRef

export const imageOpCopyRegion: ImageOpType<ParameterType, ReturnType> = {
    name: "CopyRegion",

    WebGL2: ({cmdQueue, parameters: {sourceImage, sourceRegion, targetOffset, addressMode, borderColor, resultImageOrDataType, blendMode, options}}) => {
        const disableBatchSizeCheck = options?.disableBatchSizeCheck
        if (!disableBatchSizeCheck) {
            assertBatchSizeOne(sourceImage.descriptor)
        }
        addressMode ??= "wrap"
        borderColor ??= {r: 0, g: 0, b: 0, a: 1}
        const painter = cmdQueue.createPainter(
            "compositor",
            "copyRegion",
            `
            uniform int u_useOffsetImage;
            uniform ivec2 u_offset;
        
            vec4 computeColor(ivec2 targetPixel) {
                ivec2 offset = u_offset;
                if (u_useOffsetImage != 0) {
                    offset += ivec2(texelFetch1(ivec2(0, 0)).xy);
                }
                return texelFetch0(targetPixel + offset, ${getHalAddressMode(addressMode)}, ${getVec4Color(borderColor)});
            }
        `,
        )
        sourceRegion ??= {x: 0, y: 0, width: sourceImage.descriptor.width, height: sourceImage.descriptor.height}
        targetOffset ??= {x: 0, y: 0}
        const targetRegion = {x: targetOffset.x, y: targetOffset.y, width: sourceRegion.width, height: sourceRegion.height}
        resultImageOrDataType = cmdQueue.prepareResultImage(
            resultImageOrDataType,
            {
                ...sourceImage.descriptor,
                width: targetOffset.x + sourceRegion.width,
                height: targetOffset.y + sourceRegion.height,
            },
            false,
        )
        const offsetImage = "offsetImage" in sourceRegion ? sourceRegion.offsetImage : undefined
        const offset = "offsetImage" in sourceRegion ? sourceRegion.offset ?? {x: 0, y: 0} : {x: sourceRegion.x, y: sourceRegion.y}
        cmdQueue.paint(painter, {
            parameters: {
                u_useOffsetImage: {type: "int", value: offsetImage ? 1 : 0},
                u_offset: {type: "int2", value: {x: offset.x - targetOffset.x, y: offset.y - targetOffset.y}},
            },
            sourceImages: [sourceImage, offsetImage],
            options: {targetRegion, blendMode},
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, sourceRegion, targetOffset, addressMode, resultImageOrDataType, options}}) => {
        if (sourceRegion && !isBox2Like(sourceRegion)) {
            throw new Error("OffsetImage not supported for ImageProcessing")
        }
        const disableBatchSizeCheck = options?.disableBatchSizeCheck
        if (!disableBatchSizeCheck) {
            assertBatchSizeOne(sourceImage.descriptor)
        }
        const boxSourceRegion = sourceRegion ?? {x: 0, y: 0, width: sourceImage.descriptor.width, height: sourceImage.descriptor.height}
        // somehow this is needed to convince TS that this is really a Box2Like (which it has to be at this point)
        if (!isBox2Like(boxSourceRegion)) {
            throw new Error("Invalid sourceRegion")
        }
        targetOffset ??= {x: 0, y: 0}
        if (isImageRef(resultImageOrDataType)) {
            assertBatchSizeOne(resultImageOrDataType.descriptor)
        } else {
            const descriptor: ImageDescriptor = {
                ...sourceImage.descriptor,
                width: targetOffset.x + boxSourceRegion.width,
                height: targetOffset.y + boxSourceRegion.height,
            }
            if (resultImageOrDataType) {
                descriptor.dataType = resultImageOrDataType
            }
            resultImageOrDataType = cmdQueue.createImage(descriptor)
        }

        addressMode ??= "wrap"
        switch (addressMode) {
            case "wrap":
                {
                    boxSourceRegion.x = wrap(boxSourceRegion.x, sourceImage.descriptor.width)
                    boxSourceRegion.y = wrap(boxSourceRegion.y, sourceImage.descriptor.height)
                    const sw = Math.min(boxSourceRegion.width, sourceImage.descriptor.width - boxSourceRegion.x)
                    const sh = Math.min(boxSourceRegion.height, sourceImage.descriptor.height - boxSourceRegion.y)
                    if (sw > 0 && sh > 0) {
                        resultImageOrDataType = cmdQueue.createImage(resultImageOrDataType.descriptor, {
                            type: "copyRegion",
                            source: sourceImage,
                            sourceRegion: {type: "region", region: [boxSourceRegion.x, boxSourceRegion.y, sw, sh]},
                            target: resultImageOrDataType,
                            targetOffset: {type: "offset", offset: [targetOffset.x, targetOffset.y]},
                        })
                    }
                    if (sw < boxSourceRegion.width) {
                        resultImageOrDataType = cmdQueue.createImage(resultImageOrDataType.descriptor, {
                            type: "copyRegion",
                            source: sourceImage,
                            sourceRegion: {type: "region", region: [0, boxSourceRegion.y, boxSourceRegion.width - sw, sh]},
                            target: resultImageOrDataType,
                            targetOffset: {type: "offset", offset: [targetOffset.x + sw, targetOffset.y]},
                        })
                    }
                    if (sh < boxSourceRegion.height) {
                        resultImageOrDataType = cmdQueue.createImage(resultImageOrDataType.descriptor, {
                            type: "copyRegion",
                            source: sourceImage,
                            sourceRegion: {type: "region", region: [boxSourceRegion.x, 0, sw, boxSourceRegion.height - sh]},
                            target: resultImageOrDataType,
                            targetOffset: {type: "offset", offset: [targetOffset.x, targetOffset.y + sh]},
                        })
                    }
                    if (sw < boxSourceRegion.width && sh < boxSourceRegion.height) {
                        resultImageOrDataType = cmdQueue.createImage(resultImageOrDataType.descriptor, {
                            type: "copyRegion",
                            source: sourceImage,
                            sourceRegion: {type: "region", region: [0, 0, boxSourceRegion.width - sw, boxSourceRegion.height - sh]},
                            target: resultImageOrDataType,
                            targetOffset: {type: "offset", offset: [targetOffset.x + sw, targetOffset.y + sh]},
                        })
                    }
                }
                break
            case "clamp":
                throw new Error("Clamp address mode not supported for ImageProcessing")
            case "border":
                throw new Error("Border address mode not supported for ImageProcessing")
        }
        return resultImageOrDataType
    },
}

export function copyRegion(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpCopyRegion, parameters)
}
