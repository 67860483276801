import {registerNode} from "@src/graph-system/register-node"
import {AnyJSONValue, anyJsonValue} from "@src/templates/types"
import {Node, node} from "@src/templates/node-types"
import {z} from "zod"
import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"

export type TemplateParameterValue = AnyJSONValue | Node
export type ParametersParameters = {[paramId: string]: TemplateParameterValue}

@registerNode
export class Parameters extends DeclareTemplateNodeTS<ParametersParameters>(
    {
        validation: {paramsSchema: z.record(z.string(), z.union([node, anyJsonValue]))}, //anyJsonValue can be expensive for large data, thus put it last
    },
    {nodeClass: "Parameters"},
) {}
export type ParametersFwd = TemplateNode<ParametersParameters>
