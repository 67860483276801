<cm-routed-dialog [dialogSize]="dialogSizes.Small" [dialogScroll]="true">
    @if ($item(); as task) {
        <ng-container cm-overlay-content>
            <div class="cm-comments-container">
                <div class="cm-header-container">
                    <div class="cm-header"><i class="far fa-comment"></i>Comments</div>
                    @if ($can().update.task()) {
                        <mat-slide-toggle [ngModel]="task.public" (ngModelChange)="updateItem({public: $event})" class="cm-slide-toggle">
                            <span> Public </span>
                        </mat-slide-toggle>
                    }
                </div>
                @for (comment of comments; track comment) {
                    <div class="cm-comment">
                        @if (auth.$user()?.id == comment.createdBy.id && $can().delete.taskComment(comment)) {
                            <button class="cm-simple-icon-button cm-comment-menu" [matMenuTriggerFor]="commentMenu">
                                <i class="fas fa-ellipsis-v"></i>
                            </button>
                        }
                        <mat-menu #commentMenu="matMenu">
                            <button mat-menu-item (click)="deleteComment(comment.id)">
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                        <div class="cm-comment-title">
                            {{ comment.createdBy.firstName }} {{ comment.createdBy.lastName
                            }}<span class="cm-comment-created"> — {{ comment.createdAt | cmTimeAgo }}</span>
                        </div>
                        <div class="cm-comment-text" [innerHTML]="replaceLinks(comment.text)"></div>
                    </div>
                }
                <div class="cm-new-comment-container">
                    <cm-input-container>
                        <ng-container cm-input>
                            <cm-native-input-text-area
                                [value]="newCommentText"
                                [autoFocus]="true"
                                [minRows]="3"
                                [placeholder]="'Enter a comment'"
                                (keyUp)="newCommentText = $event"
                            >
                            </cm-native-input-text-area>
                        </ng-container>
                    </cm-input-container>
                    <cm-button [disabled]="!newCommentText" (click)="addComment()" class="cm-comment-send-button">
                        <ng-container cm-text> Add task</ng-container>
                    </cm-button>
                </div>
            </div>
        </ng-container>
    }
</cm-routed-dialog>
