import {JobNodes, makeJobTaskDescriptor} from "@src/job-task/job-nodes"
import {RenderNodes} from "@src/rendering/render-nodes"
import {z} from "zod"
import DataObjectReference = JobNodes.DataObjectReference
import {CryptomatteManifestSchema} from "@src/image-processing/matte-processing"

export const RENDERING_TASK_NAME = "Rendering.cmRender"

export const RenderInputSchema = z.object({
    renderGraph: JobNodes.DataObjectReferenceSchema,
    customerId: z.number(),
    previewForProgressValue: z.array(z.number()).optional(),
})
export type RenderInput = z.infer<typeof RenderInputSchema>

export const RenderOutputSchema = z.object({
    renderPasses: z.record(RenderNodes.PassNameSchema, JobNodes.DataObjectReferenceSchema).nullable().optional(),
    preview: JobNodes.DataObjectReferenceSchema.nullable().optional(),
    metadata: JobNodes.DataObjectReferenceSchema.nullable().optional(),
})
export type RenderOutput = z.infer<typeof RenderOutputSchema>

type PassName = RenderNodes.PassName
export type RenderOutputForPassName<T extends PassName = PassName> = {
    renderPasses: Record<T, DataObjectReference>
    preview?: DataObjectReference | null
    metadata?: DataObjectReference | null
}
export function cmRenderTaskForPassNames<T extends PassName>(...passNames: T[]) {
    return makeJobTaskDescriptor<RenderInput, RenderOutputForPassName<T extends [] ? PassName : T>>(RENDERING_TASK_NAME, 1)
}

export const PictureRenderJobOutputSchema = z.object({
    ...RenderOutputSchema.shape,
    aoShadowMaskPass: JobNodes.DataObjectReferenceSchema.nullable().optional(),
})
export type PictureRenderJobOutput = z.infer<typeof PictureRenderJobOutputSchema>

export type PictureRenderJobOutputForPassNames<T extends PassName = PassName> = RenderOutputForPassName<T> & {
    aoShadowMaskPass?: JobNodes.DataObjectReference | null
}

export const RenderMetadataSchema = z.object({
    cryptomatteManifest: CryptomatteManifestSchema,
    passInfo: z.record(z.string(), z.object({bitDepth: z.union([z.literal(16), z.literal(32)])})).optional(),
})

export type RenderMetadata = z.infer<typeof RenderMetadataSchema>

export function renderTaskQueueDomain(useGpu: boolean | undefined, useCloud: boolean | undefined) {
    return `colormass-${useCloud ? "cloud" : "render-farm"}-${useGpu ? "gpu" : "cpu"}`
}
