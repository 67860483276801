<div class="cm-template-tree-container">
    <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="trackBy">
        <!-- Leaf nodes -->
        <cdk-nested-tree-node
            *cdkTreeNodeDef="let item"
            class="cm-template-tree-node"
            draggable="true"
            (dragstart)="dragAndDrop.dragStart($event, item.context, item.node)"
            (dragenter)="dragAndDrop.dragEnter($event, item.context, item.node)"
            (dragover)="dragAndDrop.dragOver($event, item.context, item.node, 'before')"
            (drop)="dragAndDrop.drop($event, item.context, item.node)"
            (dragend)="dragAndDrop.dragEnd()"
        >
            <!-- This template outlet is only used to avoid code duplication, since both leaf nodes and expandable nodes are very similar. -->
            <ng-container
                *ngTemplateOutlet="listItem; context: {treeControl: treeControl, context: item.context, node: item.node, expandable: false}"
            ></ng-container>
        </cdk-nested-tree-node>
        <!-- Expandable nodes -->
        <cdk-nested-tree-node
            *cdkTreeNodeDef="let item; when: hasChild"
            class="cm-template-tree-node"
            draggable="true"
            (dragstart)="dragAndDrop.dragStart($event, item.context, item.node)"
            (dragenter)="dragAndDrop.dragEnter($event, item.context, item.node)"
            (dragover)="dragAndDrop.dragOver($event, item.context, item.node, 'before')"
            (drop)="dragAndDrop.drop($event, item.context, item.node)"
            (dragend)="dragAndDrop.dragEnd()"
        >
            <!-- This template outlet is only used to avoid code duplication, since both the nodes and nodes with children are very similar. -->
            <ng-container
                *ngTemplateOutlet="listItem; context: {treeControl: treeControl, context: item.context, node: item.node, expandable: true, treeItem: item}"
            >
            </ng-container>
            <div [class.cm-template-tree-invisible]="!treeControl.isExpanded(item)" class="cm-child-nodes">
                <ng-container cdkTreeNodeOutlet></ng-container>
                <ng-container *ngTemplateOutlet="listItemAdd; context: {context: item.node}"></ng-container>
            </div>
        </cdk-nested-tree-node>
    </cdk-tree>
    <ng-container *ngTemplateOutlet="listItemAdd; context: {context: templateGraph}"></ng-container>
</div>
<ng-template #listItem let-treeControl="treeControl" let-context="context" let-node="node" let-expandable="expandable" let-treeItem="treeItem">
    <cm-list-item
        [disabled]="!editor.sceneManager.isNodeActive(node)"
        [selected]="isNodeSelected(node)"
        [expandable]="expandable"
        [expanded]="expandable ? treeControl.isExpanded(treeItem) : false"
        [matTooltip]="getErrorString(node)"
        [class.cm-template-node-exported]="isNodeExportedFromTemplate(node)"
        [class.cm-drag-over]="dragAndDrop.isDropTarget(context, node, 'before')"
        (click)="selectNode(node, $event)"
        (arrowToggle)="treeControl.toggle(treeItem)"
        (dblclick)="renameNode(node)"
        (mouseenter)="highlightNode.emit({node: node, highlight: true, transient: true, modifier: $event.shiftKey})"
        (mouseleave)="highlightNode.emit({node: node, highlight: false, transient: true, modifier: $event.shiftKey})"
    >
        <ng-container cm-pre-title>
            <div class="cm-title-icons-container">
                @if (getNodeIconClass | memoize: this : node) {
                    <i class="cm-node-icon" [class]="getNodeIconClass | memoize: this : node"></i>
                }
            </div>
        </ng-container>
        <ng-container cm-title>
            <span
                class="cm-list-item-title-text"
                [class.cm-node-error]="getErrorString(node)"
                [class.cm-node-ref]="context && NodeUtils.isReferenceOnlyContext(context)"
                >{{ getNodeLabel(node) }}</span
            >
        </ng-container>
        <ng-container cm-icons>
            @if (NodeUtils.isTemplateGraph(node)) {
                <i class="far fa-arrow-circle-right" matTooltip="View template" (click)="$event.stopPropagation(); _viewSubTemplate(node)"></i>
            }
            @if (NodeUtils.isMeshOrInstance(node)) {
                <i
                    [matMenuTriggerFor]="meshDownloadMenu"
                    [matMenuTriggerData]="{node: NodeUtils.resolveInstance(node)}"
                    matTooltip="Download"
                    class="far fa-arrow-to-bottom cm-action-icons"
                >
                </i>
            }
            @if (NodeUtils.isTransformable(node)) {
                <span [matMenuTriggerFor]="transformMenu" [matMenuTriggerData]="{context: context, node: node}">
                    @if (isNodeLocked(node)) {
                        <i class="far fa-lock" matTooltip="Transform is locked"></i>
                    } @else {
                        <i class="far fa-unlock" matTooltip="Transform is not locked"></i>
                    }
                </span>
            }
            @if (NodeUtils.isInstance(node)) {
                <i
                    class="far fa-external-link"
                    matTooltip="Node is an instance (click to go to defining node)"
                    (click)="$event.stopPropagation(); selectNode(node.node, $event)"
                ></i>
            }
            <!--FIXME: href used instead of routerlink because routerlink doesnt reload when the IDs change only-->
            @if (NodeUtils.isTemplateReference(node) || NodeUtils.isTemplateOrInstance(node)) {
                @if (resolveNodeLink | memoize: this : $any(node) | async; as templateLink) {
                    <a class="far fa-external-link" href="{{ templateLink }}" (click)="$event.stopPropagation()" matTooltip="Go to template"> </a>
                }
            }
            <i
                [matMenuTriggerFor]="nodeMenu"
                [matMenuTriggerData]="{context: context, node: node}"
                (click)="$event.stopPropagation()"
                matTooltip="Edit"
                class="far fa-pen cm-action-icons"
            >
            </i>
        </ng-container>
    </cm-list-item>
</ng-template>
<ng-template #listItemAdd let-context="context">
    <div
        (dragenter)="dragAndDrop.dragEnter($event, context, null)"
        (dragover)="dragAndDrop.dragOver($event, context, null, 'inside')"
        (drop)="dragAndDrop.drop($event, context, null)"
        (dragend)="dragAndDrop.dragEnd()"
        [class.cm-drag-over]="dragAndDrop.isDropTarget(context, null, 'inside')"
        class="cm-add-menu"
    >
        @if (NodeUtils.isConfigGroup | memoize: NodeUtils : context) {
            <div class="cm-add-menu-items">
                <div (click)="addConfigVariant(context)" class="cm-add-menu-item">+ Add variant</div>
                <div (click)="addSwitch(context)" class="cm-add-menu-item">+ Add switch</div>
            </div>
        } @else {
            @if (NodeUtils.isSwitch | memoize: NodeUtils : context) {
                <div [matMenuTriggerFor]="addToSwitchMenu" [matMenuTriggerData]="{context: context}" class="cm-add-menu-item">+ Add here</div>
            } @else {
                <div class="cm-add-menu-items-container">
                    @if ({placeholder: true}; as visibility) {
                        @if (visibility.placeholder) {
                            <div (mouseenter)="visibility.placeholder = false" class="cm-placeholder">+ Add items</div>
                        } @else {
                            <div class="cm-add-menu-items">
                                <div [matMenuTriggerFor]="moveMenu" [matMenuTriggerData]="{context: context}" class="cm-add-menu-item" matTooltip="Move here">
                                    <i class="far fa-arrow-right"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addLibraryMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    matTooltip="Add Items"
                                    class="cm-add-menu-item cm-library"
                                >
                                    + <i class="far fa-couch"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addLightMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item cm-lights"
                                    matTooltip="Add Lights"
                                >
                                    +<i class="far fa-lightbulb"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addCameraRenderMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item"
                                    matTooltip="Add Camera / Render Settings"
                                >
                                    +<i class="far fa-camera cm-camera"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addConfigMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item cm-inputs-outputs"
                                    matTooltip="Inputs & Outputs"
                                >
                                    +<i class="far fa-sign-in"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="advancedMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item cm-advanced"
                                    matTooltip="Advanced Settings"
                                >
                                    <i class="far fa-asterisk"></i>
                                </div>
                            </div>
                        }
                    }
                </div>
            }
        }
        <ng-template #meshMenuButton>
            @if (NodeUtils.isMesh | memoize: NodeUtils : context) {
                <button mat-menu-item [disabled]="true">(TODO: Add surface)</button>
            } @else {
                <div class="cm-add-menu-items-container">
                    @if ({placeholder: true}; as visibility) {
                        @if (visibility.placeholder) {
                            <div (mouseenter)="visibility.placeholder = false" class="cm-placeholder">+ Add items</div>
                        } @else {
                            <div class="cm-add-menu-items">
                                <div [matMenuTriggerFor]="moveMenu" [matMenuTriggerData]="{context: context}" class="cm-add-menu-item" matTooltip="Move here">
                                    <i class="far fa-arrow-right"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addLibraryMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    matTooltip="Add Items"
                                    class="cm-add-menu-item cm-library"
                                >
                                    + <i class="far fa-couch"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addLightMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item cm-lights"
                                    matTooltip="Add Lights"
                                >
                                    +<i class="far fa-lightbulb"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addCameraRenderMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item"
                                    matTooltip="Add Camera / Render Settings"
                                >
                                    +<i class="far fa-camera cm-camera"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="addConfigMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item cm-inputs-outputs"
                                    matTooltip="Inputs & Outputs"
                                >
                                    +<i class="far fa-sign-in"></i>
                                </div>
                                <div
                                    [matMenuTriggerFor]="advancedMenu"
                                    [matMenuTriggerData]="{context: context}"
                                    class="cm-add-menu-item cm-advanced"
                                    matTooltip="Advanced Settings"
                                >
                                    <i class="far fa-asterisk"></i>
                                </div>
                            </div>
                        }
                    }
                </div>
            }
        </ng-template>
    </div>
</ng-template>
<mat-menu #meshDownloadMenu="matMenu">
    <ng-template matMenuContent let-node="node">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="far fa-arrow-to-bottom"></i>
                    Download
                </ng-container>
                <ng-container cm-section>
                    @if (node.drcDataObjectId; as id) {
                        <cm-list-item (click)="files.downloadDataObjectByLegacyId(id)">
                            <ng-container cm-title>DRC</ng-container>
                        </cm-list-item>
                    }
                    @if (node.plyDataObjectId; as id) {
                        <cm-list-item (click)="files.downloadDataObjectByLegacyId(id)">
                            <ng-container cm-title>PLY</ng-container>
                        </cm-list-item>
                    }
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<mat-menu #transformMenu="matMenu">
    <ng-template matMenuContent let-context="context" let-node="node">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="far fa-arrows-up-down-left-right"></i>
                    Position
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="resetNodeTransform(node)" [disabled]="!node.lockedTransform">
                        <ng-container cm-title>Reset transform</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="$event.stopPropagation()">
                        <ng-container cm-pre-title>
                            <cm-toggle [toggle]="!!node.lockedTransform" (toggleChange)="setAllTransformLocked(node, $event)"></cm-toggle>
                        </ng-container>
                        <ng-container cm-title>Lock transform</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="moveSelectionToOrigin(node)" [disabled]="!node.lockedTransform">
                        <ng-container cm-title>Move to origin</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<mat-menu #nodeMenu="matMenu">
    <ng-template matMenuContent let-context="context" let-node="node">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="far fa-pen"></i>
                    Edit
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="renameNode(node)" [disabled]="node.name === undefined">
                        <ng-container cm-title>Rename</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="copySelection(node)">
                        <ng-container cm-title>Copy</ng-container>
                    </cm-list-item>
                    @if (NodeUtils.isContext(node)) {
                        <cm-list-item (click)="pasteSelection(node)" [disabled]="!clipboardService.valid">
                            <ng-container cm-title>Paste</ng-container>
                        </cm-list-item>
                    }
                    <cm-list-item (click)="duplicateNode(node)">
                        <ng-container cm-title>Duplicate</ng-container>
                    </cm-list-item>
                    @if (NodeUtils.isTemplateGraph(node)) {
                        <cm-list-item (click)="instanceSelection(context, node)">
                            <ng-container cm-title>Create Instance</ng-container>
                        </cm-list-item>
                    }
                    <cm-list-item class="cm-remove-icon" (click)="deleteNode(context, node)">
                        <ng-container cm-title>Delete</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
        </div>
        @if (NodeUtils.isGroup(node)) {
            <button mat-menu-item (click)="dissolveGroup(node)">Dissolve group</button>
        }
        @if (NodeUtils.isMeshOrInstance(node)) {
            <button mat-menu-item (click)="addDecal(context, node)">Add Decal</button>
        }
        @if (NodeUtils.isTemplateGraph(node)) {
            <button mat-menu-item (click)="promoteToLibraryTemplate(node)">Promote to library template</button>
        }
    </ng-template>
</mat-menu>
<!--Add to switch-->
<mat-menu #addToSwitchMenu="matMenu" class="cm-template-add-library">
    <ng-template matMenuContent let-context="context">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="far fa-plus"></i>
                    Add to switch
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addSelectedObjectToSwitch(context)">
                        <ng-container cm-title>Selected object(s)</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addSelectedSurfaceToSwitch(context)">
                        <ng-container cm-title>Selected surface</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addSelectedMaterialToSwitch(context)">
                        <ng-container cm-title>Selected material(s)</ng-container>
                    </cm-list-item>
                    <cm-list-item
                        [disabled]="!clipboardService.valid"
                        [style.pointer-events]="!clipboardService.valid ? 'none' : 'inherit'"
                        (click)="pasteSelection(context)"
                    >
                        <ng-container cm-title>Paste</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<!--Move here-->
<mat-menu #moveMenu="matMenu" class="cm-template-add-library">
    <ng-template matMenuContent let-context="context">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section>
                    <cm-list-item
                        [disabled]="!clipboardService.valid"
                        [style.pointer-events]="!clipboardService.valid ? 'none' : 'inherit'"
                        (click)="pasteSelection(context)"
                    >
                        <ng-container cm-title>Paste here</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="moveSelectionToContext(context)">
                        <ng-container cm-title>Move selection here</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="moveSelectionToNewGroup(context)">
                        <ng-container cm-title>Move selection to new group</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<!--Add library-->
<mat-menu #addLibraryMenu="matMenu" class="cm-template-add-library">
    <ng-template matMenuContent let-context="context">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="far fa-book"></i>
                    From library
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="openSelectTemplateDialog(context)">
                        <ng-container cm-title>Add Asset</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="openSelectMaterialDialog(context)">
                        <ng-container cm-title>Add Material</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fas fa-cog"></i>
                    Configuration
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addConfigGroup(context)">
                        <ng-container cm-title>Add Configuration Group</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fas fa-circle"></i>
                    Primitives
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addPlane(context)">
                        <ng-container cm-title>Add Plane</ng-container>
                    </cm-list-item>
                    @if ($can().read.template(null, "additionalMenuItems")) {
                        <cm-list-item (click)="addBox(context)">
                            <ng-container cm-title>Add Box</ng-container>
                        </cm-list-item>
                        <cm-list-item (click)="addSphere(context)">
                            <ng-container cm-title>Add Sphere</ng-container>
                        </cm-list-item>
                        <cm-list-item (click)="addStudio(context)">
                            <ng-container cm-title>Add Studio</ng-container>
                        </cm-list-item>
                        <cm-list-item (click)="addProceduralMesh(context)">
                            <ng-container cm-title>Add Other Procedural Mesh</ng-container>
                        </cm-list-item>
                    }
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<!--Add input/output menu-->
<mat-menu #addConfigMenu="matMenu" class="cm-template-add-library">
    <ng-template matMenuContent let-context="context">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-sign-in"></i>
                    Input
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addMaterialInput(context)">
                        <ng-container cm-title>Input: Material</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addTemplateInput(context)">
                        <ng-container cm-title>Input: Template</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addImageInput(context)">
                        <ng-container cm-title>Input: Image</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addStringInput(context)">
                        <ng-container cm-title>Input: String</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addNumberInput(context)">
                        <ng-container cm-title>Input: Number</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addBooleanInput(context)">
                        <ng-container cm-title>Input: Boolean</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fas fa-sign-out"></i>
                    Output
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addTemplateExport(context)">
                        <ng-container cm-title>Output</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<!--Add Light-->
<mat-menu #addLightMenu="matMenu" class="cm-template-add-library">
    <ng-template matMenuContent let-context="context">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-lightbulb"></i>
                    Lights
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addAreaLight(context)">
                        <ng-container cm-title>Add Area light</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addHdriLight(context)">
                        <ng-container cm-title>Add HDRI light</ng-container>
                    </cm-list-item>
                    @if ($can().read.template(null, "additionalMenuItems")) {
                        <cm-list-item (click)="addLightPortal(context)">
                            <ng-container cm-title>Add Light Portal</ng-container>
                        </cm-list-item>
                    }
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<!--Add camera and render-->
<mat-menu #addCameraRenderMenu="matMenu" class="cm-template-add-library">
    <ng-template matMenuContent let-context="context">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-camera"></i>
                    Camera
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addCamera(context)">
                        <ng-container cm-title>Add Camera</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-aperture"></i>
                    Render & Scene Settings
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addRender(context)">
                        <ng-container cm-title>Add Render</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addPostProcessRender(context)">
                        <ng-container cm-title>Add Post Process</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addSceneProperties(context)">
                        <ng-container cm-title>Add Scene Properties</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
<!--Advanced menu-->
<mat-menu #advancedMenu="matMenu" class="cm-template-add-library">
    <ng-template matMenuContent let-context="context">
        <div class="cm-add-menu-container">
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-image"></i>
                    Decal and Annotation
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addDecal(context)">
                        <ng-container cm-title>Add Decal</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addAnnotation(context)">
                        <ng-container cm-title>Add Annotation</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-pen"></i>
                    Template Creation
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="createTemplateFromSelection(context)">
                        <ng-container cm-title>Create Template From Selection</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="replaceSelectionWithTemplate(context)">
                        <ng-container cm-title>Replace Selection With Template</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addEmptyTemplateInstance(context)">
                        <ng-container cm-title>Add Empty Template Instance</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-arrow-right-arrow-left"></i>
                    Connection
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addRelation(context, 'attach')">
                        <ng-container cm-title>Add Constraint: Attach surfaces</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="addRelation(context, 'rigid')">
                        <ng-container cm-title>Add Constraint: Rigid Object Alignment</ng-container>
                    </cm-list-item>
                    <cm-list-item (click)="rigidlyConnectSelection(context)">
                        <ng-container cm-title>Rigidly Connect Selection</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-arrow-turn-down-left"></i>
                    Guides
                </ng-container>
                <ng-container cm-section>
                    <cm-list-item (click)="addPointGuideForSelection(context)">
                        <ng-container cm-title>Add Point Guide For Selection</ng-container>
                    </cm-list-item>
                    @if ($can().read.template(null, "additionalMenuItems")) {
                        <cm-list-item (click)="addAlignmentGuide(context, 'floor')">
                            <ng-container cm-title>Add Guide: Floor</ng-container>
                        </cm-list-item>
                        <cm-list-item (click)="addAlignmentGuide(context, 'wall')">
                            <ng-container cm-title>Add Guide: Wall</ng-container>
                        </cm-list-item>
                    }
                </ng-container>
            </cm-add-menu-section>
            <cm-add-menu-section>
                <ng-container cm-section-title>
                    <i class="fa fa-asterisk"></i>
                    Other
                </ng-container>
                <ng-container cm-section>
                    @if ($can().create.material(null, "overlayColor")) {
                        <cm-list-item (click)="addOverlayMaterialColor(context)">
                            <ng-container cm-title>Add Overlay Material Color</ng-container>
                        </cm-list-item>
                    }
                    <cm-list-item (click)="addValue(context)">
                        <ng-container cm-title>Add Value Node</ng-container>
                    </cm-list-item>
                </ng-container>
            </cm-add-menu-section>
        </div>
    </ng-template>
</mat-menu>
