import {inject, Injectable} from "@angular/core"
import {ContentTypeModel, DataObjectAssignmentType, DataObjectForArServiceFragment, JobTaskState, SceneViewerJobTaskStateWithOutputGQL} from "@api"
import {isAndroid, isIoS} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {triggerDOMLink} from "@app/common/helpers/routes"
import {EndpointUrls} from "@app/common/models/constants/urls"
import {Settings} from "@app/common/models/settings/settings"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {arGltfGenerationTask, arUsdzGenerationTask} from "@cm/lib/job-task/ar"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {Parameters} from "@cm/lib/templates/nodes/parameters"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {filter, firstValueFrom, map, Subject, take} from "rxjs"

type ArContentModel = "model/vnd.usdz+zip" | "model/gltf-binary"

@Injectable()
export class ArService {
    private sdk = inject(SdkService)
    private jobStateWithOutput = inject(SceneViewerJobTaskStateWithOutputGQL)

    //Events
    private desktopArUrl: Subject<string | undefined> = new Subject()
    desktopArUrl$ = this.desktopArUrl.asObservable()

    private creatingArSubject = new Subject<boolean>()
    creatingAr$ = this.creatingArSubject.asObservable()

    private async getExistingArModelDataObject(
        templateRevisionId: string,
        parameters: Parameters,
        contentType: ArContentModel,
    ): Promise<DataObjectForArServiceFragment | undefined> {
        const {dataObjectAssignments} = await this.sdk.gql.getDataObjectAssignmentsForArService({
            filter: {
                contentTypeModel: ContentTypeModel.TemplateRevision,
                objectId: templateRevisionId,
                assignmentKey: {equals: parameters.getHash()},
                assignmentType: [DataObjectAssignmentType.CachedTemplateGltf],
            },
        })

        if (dataObjectAssignments.length > 1)
            console.warn(`Multiple data object assignments found for template revision ${templateRevisionId} and config hash ${parameters.getHash()}`)

        const gltfDataObject = dataObjectAssignments[0]?.dataObject
        if (!gltfDataObject) return undefined

        /*The gltf is the main model, the usdz file is derived from this and thus attached as related, see processUsdz() in tasks.*/
        switch (contentType) {
            case "model/gltf-binary":
                return gltfDataObject
            case "model/vnd.usdz+zip":
                return gltfDataObject.related.find((x) => x.mediaType === "model/vnd.usdz+zip")
            default:
                throw Error(`Unknown type: ${contentType}.`)
        }
    }

    private async getArModelDataObject(
        templateRevisionId: string,
        parameters: Parameters,
        contentType: ArContentModel,
    ): Promise<DataObjectForArServiceFragment> {
        let existingArModel = await this.getExistingArModelDataObject(templateRevisionId, parameters, contentType)
        if (existingArModel) return existingArModel

        await this.generateNewArModels(templateRevisionId, parameters)

        existingArModel = await this.getExistingArModelDataObject(templateRevisionId, parameters, contentType)
        if (!existingArModel) throw new Error("Ar generation failed")

        return existingArModel
    }

    private makeJobGraph(templateRevisionId: string, configurationBase64: string, assignmentKey: string) {
        const gltfGenerationTask = JobNodes.task(arGltfGenerationTask, {
            input: JobNodes.struct({
                templateRevisionId: JobNodes.value(templateRevisionId),
                templateParametersBase64: JobNodes.value(configurationBase64),
                assignmentKey: JobNodes.value(assignmentKey),
            }),
        })
        const usdzGenerationTask = JobNodes.task(arUsdzGenerationTask, {
            input: gltfGenerationTask,
        })

        return JobNodes.jobGraph(JobNodes.list([gltfGenerationTask, usdzGenerationTask]), {
            platformVersion: Settings.APP_VERSION,
        })
    }

    private async waitForJobToFinish(jobId: string) {
        const jobTask = await firstValueFrom(
            this.jobStateWithOutput.watch({id: jobId}, {pollInterval: 2000}).valueChanges.pipe(
                map(({data: {jobTask}}) => jobTask),
                filter((jobTask) => {
                    switch (jobTask.state) {
                        case JobTaskState.Runnable:
                        case JobTaskState.Running:
                        case JobTaskState.Init:
                            return false
                        default:
                            return true
                    }
                }),
                take(1),
            ),
        )

        if (jobTask.state !== JobTaskState.Complete) {
            throw new Error("AR generation failed")
        }
    }

    private async generateNewArModels(templateRevisionId: string, currentParams: Parameters) {
        const {templateRevision} = await this.sdk.gql.getTemplateIdForArService({templateRevisionId})

        const templateParametersBase64 = btoa(JSON.stringify(currentParams.serialize()))

        console.log(
            `Starting AR generation job for revision ${templateRevisionId} of template ${templateRevision.template.id} with configuration ${templateParametersBase64} and assignment key ${currentParams.getHash()}`,
        )

        throw new Error("Generation of ar models requires different backend")

        const {createJob: arGenerationJob} = await this.sdk.gql.sceneViewerCreateJob({
            input: {
                graph: graphToJson(this.makeJobGraph(templateRevision.id, templateParametersBase64, currentParams.getHash())),
                name: "AR generation",
                organizationLegacyId: templateRevision.template.organizationLegacyId,
            },
        })

        await this.waitForJobToFinish(arGenerationJob.id)
    }

    async viewArModel(templateRevisionId: string, parameters: Parameters) {
        this.creatingArSubject.next(true)

        //TODO: change this when the new backend is in place
        let arModelDataObject: DataObjectForArServiceFragment
        try {
            arModelDataObject = await this.getArModelDataObject(templateRevisionId, parameters, isIoS ? "model/vnd.usdz+zip" : "model/gltf-binary")
        } catch (e) {
            this.creatingArSubject.next(false)
            throw e
        }

        if (isAndroid) {
            this.viewInArAndroid(arModelDataObject)
        } else if (isIoS) {
            this.viewInArIos(arModelDataObject)
        } else {
            this.desktopArUrl.next(`${EndpointUrls.AR_REDIRECT_URL}/${arModelDataObject.objectName}`)
        }

        this.creatingArSubject.next(false)
    }

    private viewInArAndroid(gltfDataObject: DataObjectForArServiceFragment) {
        const dataObjectURL = gltfDataObject.downloadUrl
        triggerDOMLink({
            href:
                `intent://arvr.google.com/scene-viewer/1.0?` +
                `mode=ar_only&` +
                `file=${dataObjectURL}#Intent;` +
                `scheme=https;package=com.google.android.googlequicksearchbox;` +
                `action=android.intent.action.VIEW;` +
                `S.browser_fallback_url=https://developers.google.com/ar;end;`,
        })
    }

    private viewInArIos(usdzDataObject: DataObjectForArServiceFragment) {
        const dataObjectURL = usdzDataObject.downloadUrl
        // need to create a link with an <img> tag inside, as noted here: https://cwervo.com/writing/quicklook-web
        const a = document.createElement("a")
        a.href = dataObjectURL
        a.rel = "ar"
        const img = document.createElement("img")
        a.appendChild(img)
        document.body.appendChild(a)
        a.style.display = "none"
        a.click()
        a.remove()
    }
}
