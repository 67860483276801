import {CommonModule, UpperCasePipe} from "@angular/common"
import {Component, inject, OnInit, TemplateRef, ViewChild} from "@angular/core"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {MatOption} from "@angular/material/autocomplete"
import {MatButtonModule} from "@angular/material/button"
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker"
import {MatDialog, MatDialogModule} from "@angular/material/dialog"
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field"
import {MatIconModule} from "@angular/material/icon"
import {MatInput} from "@angular/material/input"
import {MatMenu, MatMenuContent, MatMenuItem, MatMenuModule} from "@angular/material/menu"
import {MatSelect} from "@angular/material/select"
import {MatTooltip} from "@angular/material/tooltip"
import {RouterModule} from "@angular/router"
import {ContentTypeModel, MaterialDetailsFragment, MutationUpdateMaterialInput} from "@api"
import {Settings} from "@app/common/models/settings/settings"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {ScanJobComponent} from "@app/textures/texture-group-details/scan-job/scan-job.component"
import {TextureSetComponent} from "@app/textures/texture-set/texture-set.component"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {CopyLegacyIdButtonComponent} from "@common/components/buttons/copy-legacy-id-button/copy-legacy-id-button.component"
import {CopyToClipboardButtonComponent} from "@common/components/buttons/copy-to-clipboard-button/copy-to-clipboard-button.component"
import {DropdownComponent} from "@common/components/buttons/dropdown/dropdown.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {DropFilesComponent} from "@common/components/files"
import {HintComponent} from "@common/components/hint/hint.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {TagSelectComponent} from "@common/components/inputs/select/tag-select/tag-select.component"
import {BooleanLabelSelectComponent} from "@common/components/inputs/select/boolean-label-select/boolean-label-select.component"
import {SectionComponent, SectionContentComponent} from "@common/components/item"
import {ImagePlaceholderComponent} from "@common/components/placeholders/image-placeholder/image-placeholder.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {ContentLoaderModule} from "@ngneat/content-loader"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {ActivitySectionComponent} from "@platform/components/details/activity-section/activity-section.component"
import {ArticleIdSectionComponent} from "@platform/components/details/article-id-section/article-id-section.component"
import {CommentSectionComponent} from "@platform/components/details/comment-section/comment-section.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {ProjectManagementTasksSectionComponent} from "@platform/components/details/project-management-tasks-section/project-management-tasks-section.component"
import {SidebarDescriptionSectionComponent} from "@platform/components/details/sidebar-description-section/sidebar-description-section.component"
import {TemplatesSectionComponent} from "@platform/components/details/templates-section/templates-section.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {CornersLayoutComponent} from "@platform/components/layouts/corners-layout/corners-layout.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {MaterialExportsListComponent} from "@platform/components/materials/material-exports-list/material-exports-list.component"
import {MaterialOutputsComponent} from "@platform/components/materials/material-outputs/material-outputs.component"
import {ShowAssetsButtonComponent} from "@platform/components/details/show-assets-button/show-assets-button.component"
import {MaterialRevisionsHistoryDialogComponent} from "@platform/components/materials/material-revisions-history-dialog/material-revisions-history-dialog.component"
import {PaymentStateLabelComponent} from "@platform/components/shared/payment-state-label/payment-state-label.component"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {TextureSetsComponent} from "@app/textures/texture-sets/texture-sets.component"
import {ThumbnailsService} from "@app/platform/services/thumbnails/thumbnails.service"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"

@Component({
    selector: "cm-material-details",
    templateUrl: "./material-details.component.html",
    standalone: true,
    styleUrls: ["./material-details.component.scss"],
    imports: [
        CommonModule,
        ContentLoaderModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        DetailsDialogLayoutComponent,
        ButtonComponent,
        DropFilesComponent,
        HintComponent,
        InputContainerComponent,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatFormField,
        MatInput,
        MatLabel,
        MatMenu,
        MatMenuContent,
        MatMenuItem,
        MatOption,
        MatSelect,
        MatSuffix,
        MatTooltip,
        MaterialExportsListComponent,
        MemoizePipe,
        ReactiveFormsModule,
        ScanJobComponent,
        SectionComponent,
        TagSelectComponent,
        TextureSetComponent,
        UpperCasePipe,
        BooleanLabelSelectComponent,
        OrganizationSelectComponent,
        CornersLayoutComponent,
        StateLabelComponent,
        GalleryImageComponent,
        CopyToClipboardButtonComponent,
        DropdownComponent,
        MatMenuModule,
        FormsModule,
        PlaceholderComponent,
        ImagePlaceholderComponent,
        RoutedDialogComponent,
        SectionContentComponent,
        MaterialOutputsComponent,
        CopyLegacyIdButtonComponent,
        CommentSectionComponent,
        SidebarDescriptionSectionComponent,
        FilesSectionComponent,
        ActivitySectionComponent,
        ProjectManagementTasksSectionComponent,
        ArticleIdSectionComponent,
        TitleSectionComponent,
        MaterialRevisionsHistoryDialogComponent,
        ShowAssetsButtonComponent,
        TextureSetsComponent,
        PaymentStateLabelComponent,
        DataObjectThumbnailComponent,
        TimeAgoPipe,
        TemplatesSectionComponent,
    ],
})
export class MaterialDetailsComponent extends BaseDetailsComponent<MaterialDetailsFragment, Omit<MutationUpdateMaterialInput, "id">> implements OnInit {
    override _contentTypeModel = ContentTypeModel.Material
    override _fetchItem = this.sdk.gql.getMaterialDetails
    override _updateItem = this.sdk.gql.updateMaterialDetails
    override _deleteItem = this.sdk.raw.materialDetailsDelete
    @ViewChild("materialRevisionHistoryDialog") materialRevisionHistoryDialog?: TemplateRef<MaterialRevisionsHistoryDialogComponent>

    override displayName = "material"

    get materialIconAssignments() {
        return this.$item()?.iconAssignments
    }

    matDialog = inject(MatDialog)
    organizations = inject(OrganizationsService)
    upload = inject(UploadGqlService)
    thumbnailsService = inject(ThumbnailsService)

    nodeGraphMenu = false

    sampleArrivalMaxDate = new Date()

    showRevisionHistoryDialog() {
        if (!this.materialRevisionHistoryDialog) {
            throw new Error("Missing materialRevisionHistoryDialog ref in template")
        }
        this.matDialog.open(this.materialRevisionHistoryDialog, {
            data: {material: {id: this.$item()?.id}},
            width: "800px",
        })
    }

    async createTextureGroup() {
        const material = this.$item()
        if (material) {
            await this.notifications.withUserFeedback(
                async () => {
                    const {createTextureGroup} = await this.sdk.gql.materialDetailsCreateTextureGroup({
                        input: {
                            name: material.name,
                            organizationId: material.organization?.id,
                            materialId: material.id,
                            meshSpecific: false,
                        },
                    })
                    await this.sdk.gql.materialDetailsCreateTextureSet({
                        input: {textureGroupId: createTextureGroup.id},
                    })
                    this.refresh.item(this.$item())
                },
                {
                    error: "Cannot create new texture group.",
                },
            )
        }
    }

    resolveNewTexture(textureGroup: {textureSets: {id: string}[]}) {
        if (textureGroup.textureSets.length === 1) {
            void this.router.navigate(["setup", textureGroup.textureSets[0].id], {
                relativeTo: this.route,
                queryParamsHandling: "preserve",
            })
        } else if (textureGroup.textureSets.length > 1) {
            this.nodeGraphMenu = !this.nodeGraphMenu
        }
    }

    async uploadIcon(files: File[]) {
        const item = this.$item()
        if (!item) {
            throw Error("Gql material not set")
        }
        if (files.length > 1) {
            throw Error("Only single file upload is supported.")
        }
        const organizationId = item.organization?.id
        if (!organizationId) {
            throw Error("Organization not set")
        }

        const dataObjectDetails = await this.upload.createAndUploadDataObject(files[0], {organizationId}, {showUploadToolbar: true, processUpload: true})
        await this.sdk.gql.materialDetailsCreateIconAssignment({
            objectId: item.id,
            dataObjectId: dataObjectDetails.id,
        })
        this.refresh.item(this.$item())
        await this.thumbnailsService.waitUntilAvailable(dataObjectDetails.id)
    }

    deleteIcon() {
        const assignmentId = this.materialIconAssignments?.[0]?.id
        if (assignmentId) {
            this.sdk.gql.materialDetailsDeleteIconAssignment({
                dataObjectAssignmentId: assignmentId,
            })
            this.refresh.item(this.$item())
        }
    }

    protected readonly DialogSize = DialogSize
    protected readonly Settings = Settings
}
