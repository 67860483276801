<h1 mat-dialog-title>Edit Task Type</h1>
<div mat-dialog-content style="display: flex; flex-direction: column; gap: 8px">
    <mat-form-field>
        <mat-label>Task Type</mat-label>
        <input matInput [(ngModel)]="data.taskTypeInfo.taskType" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Queue Group</mat-label>
        <input matInput [(ngModel)]="data.taskTypeInfo.queueGroup" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Endpoint</mat-label>
        <input matInput [(ngModel)]="data.taskTypeInfo.endpoint" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Invocation method</mat-label>
        <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="data.taskTypeInfo.invocationMethod">
            @for (invocationType of Labels.InvocationMethod.values(); track invocationType.state) {
                <mat-option [value]="invocationType.state">
                    {{ invocationType.label }}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Default Timeout (seconds)</mat-label>
        <input matInput type="number" step="1" [(ngModel)]="data.taskTypeInfo.defaultTimeout" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Max Timeout (seconds)</mat-label>
        <input matInput type="number" step="1" [(ngModel)]="data.taskTypeInfo.maxTimeout" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Cancel Timeout (seconds)</mat-label>
        <input matInput type="number" step="1" [(ngModel)]="data.taskTypeInfo.cancelTimeout" />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button [matMenuTriggerFor]="deleteMenu">Delete</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>

<mat-menu #deleteMenu="matMenu">
    <button mat-menu-item (click)="onDelete()">
        <span>Delete task type</span>
    </button>
</mat-menu>
