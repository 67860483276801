import {Size2Like, Vector2, Vector2Like} from "@cm/lib/math"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"

const SCOPE_NAME = "PenalizeByDistance"

export type ParameterType = {
    correlation: ImageRef
    offsetImage: ImageRef
    offset: Vector2Like
    referencePosition: Vector2Like
    imageSize: Size2Like
    level: number
    penaltyPerPixel: Vector2Like // in each direction
    penaltyDirectionX?: Vector2Like // image-space direction of the x-component of the penalty
}

export type ReturnType = ImageRef

export const penalizeByDistance = (
    cmdQueue: ImageOpCommandQueueWebGL2,
    {correlation, offsetImage, offset, referencePosition, imageSize, level, penaltyPerPixel, penaltyDirectionX}: ParameterType,
): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)
    penaltyDirectionX ??= new Vector2(1, 0)
    penaltyDirectionX = Vector2.normalize(penaltyDirectionX)
    const penaltyDirectionY = Vector2.perp(penaltyDirectionX)
    const applyFalloff = cmdQueue.createPainter(
        "compositor",
        "applyFalloff",
        `
            uniform vec2 u_referencePosition;
            uniform vec2 u_offset;
            uniform ivec2 u_size;
            uniform int u_level;
            uniform vec2 u_penaltyPerPixel;
            uniform vec2 u_penaltyDirectionX;
            uniform vec2 u_penaltyDirectionY;

            float computePenalty(vec2 position) {
                if (position.x < 0.0 || position.y < 0.0 || position.x >= float(u_size.x) || position.y >= float(u_size.y)) {
                    return 10000.0;    // kinda infinitely penalize out-of-bounds pixels
                }
                vec2 delta = position - u_referencePosition;
                float penaltyX = dot(delta, u_penaltyDirectionX) * u_penaltyPerPixel.x;
                float penaltyY = dot(delta, u_penaltyDirectionY) * u_penaltyPerPixel.y;
                // apply penalty in an elliptical shape
                return sqrt(penaltyX * penaltyX + penaltyY * penaltyY);
            }

            vec4 computeColor(ivec2 targetPixel) {
                float correlation = texelFetch0(targetPixel).r;
                vec2 offset = u_offset + texelFetch1(ivec2(0, 0)).xy;
                vec2 position = (offset + vec2(targetPixel) + 0.5) * float(1 << u_level);
                float penalty = computePenalty(position);
                return vec4(correlation - penalty, 0, 0, 1);
            }
        `,
    )
    const resultImage = cmdQueue.createImage(correlation.descriptor)
    cmdQueue.paint(applyFalloff, {
        parameters: {
            u_referencePosition: {type: "float2", value: referencePosition},
            u_offset: {type: "float2", value: offset},
            u_size: {type: "int2", value: {x: imageSize.width, y: imageSize.height}},
            u_level: {type: "int", value: level},
            u_penaltyPerPixel: {type: "float2", value: penaltyPerPixel},
            u_penaltyDirectionX: {type: "float2", value: penaltyDirectionX},
            u_penaltyDirectionY: {type: "float2", value: penaltyDirectionY},
        },
        sourceImages: [correlation, offsetImage],
        resultImage,
    })
    cmdQueue.endScope(SCOPE_NAME)
    return resultImage
}
