import {Component, inject, OnInit} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent} from "@common/components/filters/checkboxes-filter/checkboxes-filter.component"
import {PinnedFilterOptionComponent} from "@common/components/filters/pinned-filter-option/pinned-filter-option.component"
import {PinnedFilterComponent} from "@common/components/filters/pinned-filter/pinned-filter.component"
import {TagSearchFilterComponent} from "@common/components/filters/tag-search-filter/tag-search-filter.component"
import {ItemFiltersComponent} from "@common/components/item/item-filters/item-filters.component"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"
import {Enums} from "@enums"
import {Labels, StateLabel} from "@labels"
import {MaterialsGridComponent} from "@platform/components/materials/materials-grid/materials-grid.component"
import {NativeCheckboxComponent} from "@common/components/inputs/native/native-checkbox/native-checkbox.component"
import {FiltersService} from "@common/services/filters/filters.service"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsDefined} from "@cm/lib/utils/filter"

@Component({
    selector: "cm-materials-page",
    standalone: true,
    imports: [
        SidebarLayoutComponent,
        CheckboxesFilterComponent,
        PinnedFilterComponent,
        TagSearchFilterComponent,
        ItemFiltersComponent,
        PinnedFilterOptionComponent,
        RouterOutlet,
        MaterialsGridComponent,
        NativeCheckboxComponent,
        PlaceholderComponent,
    ],
    templateUrl: "./materials-page.component.html",
    styleUrl: "./materials-page.component.scss",
})
export class MaterialsPageComponent implements OnInit {
    readonly Enums = Enums
    readonly Labels = Labels

    filters = inject(FiltersService)
    permission = inject(PermissionsService)
    organizations = inject(OrganizationsService)
    $can = this.permission.$to
    sdk = inject(SdkService)

    scannerUserLabels: StateLabel<string>[] | null = null
    userLabels: StateLabel<string>[] | null = null

    ngOnInit() {
        this.sdk.gql.materialsPageScannerUsers().then(({users}) => {
            this.scannerUserLabels = users.filter(IsDefined).map((user) => ({
                label: user.name,
                state: user.id,
            }))
        })
        this.sdk.gql.materialsPageVisibleUsers().then(({users}) => {
            this.userLabels = users.filter(IsDefined).map((user) => ({
                label: user.name,
                state: user.id,
            }))
        })
    }

    async toggleWithoutScan(scannedByNone: boolean) {
        if (scannedByNone) {
            await this.filters.updateCriteria("scannedByNone", ["1"])
        } else {
            await this.filters.updateCriteria("scannedByNone", [])
        }
    }
}
