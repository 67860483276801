import {
    booleanLikeNodeClasses,
    exportClasses,
    guideClasses,
    imageLikeClasses,
    inputClasses,
    jsonLikeNodeClasses,
    lightClasses,
    materialLikeClasses,
    numberLikeNodeClasses,
    objectLikeClasses,
    outputClasses,
    stringLikeNodeClasses,
    switchClasses,
    templateLikeClasses,
    valueClasses,
} from "@cm/lib/templates/node-types"
import {Camera} from "@cm/lib/templates/nodes/camera"
import {ProceduralMesh} from "@cm/lib/templates/nodes/procedural-mesh"
import {StringResolve} from "@cm/lib/templates/nodes/string-resolve"
import {StoredMesh} from "@cm/lib/templates/nodes/stored-mesh"
import {MeshDecal} from "@cm/lib/templates/nodes/mesh-decal"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {TemplateReference} from "@cm/lib/templates/nodes/template-reference"
import {Group} from "@cm/lib/templates/nodes/group"
import {ConfigGroup} from "@cm/lib/templates/nodes/config-group"
import {ConfigVariant} from "@cm/lib/templates/nodes/config-variant"
import {RigidRelation} from "@cm/lib/templates/nodes/rigid-relation"
import {Annotation} from "@cm/lib/templates/nodes/annotation"
import {SceneProperties} from "@cm/lib/templates/nodes/scene-properties"
import {Render} from "@cm/lib/templates/nodes/render"
import {PostProcessRender} from "@cm/lib/templates/nodes/post-process-render"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {MaterialExport, TemplateExport, ImageExport, ObjectExport, StringExport, NumberExport, BooleanExport, JSONExport} from "@cm/lib/templates/nodes/export"
import {HDRILight} from "@cm/lib/templates/nodes/hdri-light"
import {OverlayMaterialColor} from "@cm/lib/templates/nodes/overlay-material-color"
import {DataObjectReference} from "@cm/lib/templates/nodes/data-object-reference"
import {TransientDataObject} from "@cm/lib/templates/nodes/transient-data-object"
import {MeshCurve} from "@cm/lib/templates/nodes/mesh-curve"
import {LodType} from "@cm/lib/templates/nodes/lod-type"
import {Seam} from "@cm/lib/templates/nodes/seam"

export const getNodeIconClass = (nodeClass: string) => {
    if (nodeClass === Camera.getNodeClass()) return "far fa-camera"

    if (nodeClass === DataObjectReference.getNodeClass() || nodeClass === TransientDataObject.getNodeClass()) return "far fa-file-image"

    if (inputClasses.includes(nodeClass)) return "far fa-sign-in-alt cm-input-icon"
    if (exportClasses.includes(nodeClass)) return "far fa-sign-out-alt cm-export-icon"

    if (nodeClass === ProceduralMesh.getNodeClass()) return "far fa-hexagon"
    if (valueClasses.includes(nodeClass)) return "far fa-code"
    if (nodeClass === StringResolve.getNodeClass()) return "far fa-binoculars"

    if (nodeClass === StoredMesh.getNodeClass()) return "far fa-cube"
    if (nodeClass === MeshDecal.getNodeClass()) return "far fa-image"
    if (nodeClass === MeshCurve.getNodeClass()) return "far fa-scribble"
    if (nodeClass === Seam.getNodeClass()) return "far fa-ellipsis-stroke"
    if (nodeClass === MaterialReference.getNodeClass()) return "far fa-game-board-alt cm-material-icon"
    if (lightClasses.includes(nodeClass)) return "far fa-lightbulb cm-light-icon"
    if (nodeClass === HDRILight.getNodeClass()) return "far fa-sun cm-light-icon"

    if (outputClasses.includes(nodeClass)) return "far fa-sign-out-alt"

    if (switchClasses.includes(nodeClass)) return "fas fa-random cm-group-icon"
    if (nodeClass === Group.getNodeClass()) return "fas fa-folder cm-group-icon"
    if (nodeClass === ConfigGroup.getNodeClass()) return "fas fa-list cm-group-icon"
    if (nodeClass === ConfigVariant.getNodeClass()) return "fas fa-cog cm-group-icon"
    if (nodeClass === TemplateGraph.getNodeClass() || nodeClass === TemplateReference.getNodeClass()) return "fas fa-diagram-project cm-template-icon"

    if (nodeClass === TemplateInstance.getNodeClass()) return "far fa-cube cm-template-instance-icon"

    if (nodeClass === RigidRelation.getNodeClass()) return "far fa-arrow-to-right"
    if (guideClasses.includes(nodeClass)) return "far fa-map-marker-alt"
    if (nodeClass === Annotation.getNodeClass()) return "far fa-tag"

    if (nodeClass === SceneProperties.getNodeClass()) return "far fa-browser"
    if (nodeClass === Render.getNodeClass()) return "far fa-image"
    if (nodeClass === PostProcessRender.getNodeClass()) return "far fa-palette"

    if (nodeClass === OverlayMaterialColor.getNodeClass()) return "far fa-circles-overlap"

    if (nodeClass === LodType.getNodeClass()) return "far fa-sliders-simple"

    return "far fa-circle"
}

export const getNodeIconSeconaryClass = (nodeClass: string) => {
    if (
        inputClasses.includes(nodeClass) ||
        switchClasses.includes(nodeClass) ||
        outputClasses.includes(nodeClass) ||
        exportClasses.includes(nodeClass) ||
        valueClasses.includes(nodeClass)
    ) {
        if (objectLikeClasses.includes(nodeClass) || ObjectExport.getNodeClass() === nodeClass) return "far fa-cube"
        else if (materialLikeClasses.includes(nodeClass) || MaterialExport.getNodeClass() === nodeClass) return "far fa-game-board-alt"
        else if (templateLikeClasses.includes(nodeClass) || TemplateExport.getNodeClass() === nodeClass) return "far fa-diagram-project"
        else if (imageLikeClasses.includes(nodeClass) || ImageExport.getNodeClass() === nodeClass) return "far fa-image"
        else if (stringLikeNodeClasses.includes(nodeClass) || StringExport.getNodeClass() === nodeClass) return "far fa-input-text"
        else if (numberLikeNodeClasses.includes(nodeClass) || NumberExport.getNodeClass() === nodeClass) return "far fa-input-numeric"
        else if (booleanLikeNodeClasses.includes(nodeClass) || BooleanExport.getNodeClass() === nodeClass) return "far fa-square-check"
        else if (jsonLikeNodeClasses.includes(nodeClass) || JSONExport.getNodeClass() === nodeClass) return "far fa-brackets-curly"
    }

    return null
}
