import {ImageProcessingNodes as Nodes} from "ts-lib/dist/browser/image-processing/image-processing-nodes"
import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {
    ChannelLayout,
    DataType,
    ImageDescriptor,
    ImageDescriptorWithOptionals,
    ImageRef,
    isImageDescriptorWithOptionals,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Color, ColorLike} from "ts-lib/dist/browser/math/color"
import {TypedImageData} from "ts-lib/dist/browser/image-processing/image-processing-actions"
import {assertNever} from "ts-lib/dist/browser/utils/utils"
import {getImgProcDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {fillImageDescriptorOptionals} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

export type ParameterType = {
    imageOrDescriptor: ImageRef | ImageDescriptorWithOptionals
    fillColor?: ColorLike // default: (0,0,0,1)
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export const imageOpCreateImage: ImageOpType<ParameterType, ReturnType> = {
    name: "CreateImage",

    WebGL2: ({cmdQueue, parameters: {imageOrDescriptor, fillColor, resultImageOrDataType}}) => {
        const painter = cmdQueue.createPainter(
            "compositor",
            "fill",
            `
            uniform vec4 u_fillColor;

            vec4 computeColor(ivec2 targetPixel) {
                return u_fillColor;
            }
        `,
        )
        const descriptor = isImageDescriptorWithOptionals(imageOrDescriptor) ? fillImageDescriptorOptionals(imageOrDescriptor) : imageOrDescriptor.descriptor
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, descriptor)
        cmdQueue.paint(painter, {
            parameters: {
                u_fillColor: {type: "float4", value: {x: fillColor?.r ?? 0, y: fillColor?.g ?? 0, z: fillColor?.b ?? 0, w: fillColor?.a ?? 1}},
            },
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {imageOrDescriptor, fillColor, resultImageOrDataType}}) => {
        const getImgProcColorSpace = (_descriptor: ImageDescriptor): TypedImageData["colorSpace"] => {
            return "linear"
        }
        const getColorFromChannelLayout = (channelLayout: ChannelLayout, fillColor: ColorLike): number | Nodes.RGBColor | Nodes.RGBAColor => {
            switch (channelLayout) {
                case "R":
                    return fillColor.r
                case "RGB":
                    return [fillColor.r, fillColor.g, fillColor.b]
                case "RGBA":
                    return [fillColor.r, fillColor.g, fillColor.b, fillColor.a ?? 1]
                default:
                    assertNever(channelLayout)
            }
        }
        const descriptor = isImageDescriptorWithOptionals(imageOrDescriptor) ? fillImageDescriptorOptionals(imageOrDescriptor) : imageOrDescriptor.descriptor
        const resultNode = cmdQueue.createImage(descriptor, {
            type: "createImage",
            width: descriptor.width,
            height: descriptor.height,
            dataType: getImgProcDataType(descriptor.dataType),
            colorSpace: getImgProcColorSpace(descriptor),
            color: getColorFromChannelLayout(descriptor.channelLayout, fillColor ?? new Color(0, 0, 0, 1)),
        })
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function createImage(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpCreateImage, parameters)
}
