import {Component, inject, OnInit, ViewChild} from "@angular/core"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatMenuModule} from "@angular/material/menu"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute, Router} from "@angular/router"
import {MaterialEditorMaterialFragment, MaterialEditorMaterialRevisionFragment, MaterialEditorTextureSetFragment, TextureType} from "@api"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {AddMenuSectionComponent} from "@app/templates/template-publisher/template-tree/add-menu-section/add-menu-section.component"
import {IMaterialConnection, IMaterialGraph, IMaterialNode, IMaterialParameter, isTransientDataObject} from "@cm/lib/materials/material-node-graph"
import {IsDefined, IsNonNull} from "@cm/lib/utils/filter"
import {FloatingButtonComponent} from "@common/components/buttons/floating-button/floating-button.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {ListItemComponent} from "@common/components/item/list-item/list-item.component"
import {UtilsService} from "@legacy/helpers/utils"
import {Settings} from "@common/models/settings/settings"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {MaterialNodeComponentTypes, MaterialNodeType, MaterialNodeTypes} from "@material-editor/components/material-node-component-types"
import {BrightContrastNodeType, GammaNodeType, HsvNodeType, ImageTextureNodeType, InvertNodeType, RgbCurvesNodeType} from "@material-editor/components/nodes"
import {PreviewSceneComponent} from "@material-editor/components/preview-scene/preview-scene.component"
import {
    SaveMaterialRevisionDialogComponent,
    SaveMaterialRevisionDialogResult,
} from "@material-editor/components/save-material-revision-dialog/save-material-revision-dialog.component"
import {TitleBarComponent} from "@material-editor/components/title-bar/title-bar.component"
import {getDimensionRange} from "@material-editor/helpers/dimension-range"
import {
    ALLOWED_MAP_DIMENSION_VARIATION_IN_CM,
    EntryMode,
    entryModes,
    graphSchemaString,
    MATERIAL_TEMPLATE_0_DISPLACEMENT_MATERIAL_REVISION_ID,
    MATERIAL_TEMPLATE_0_MATERIAL_REVISION_ID,
    MATERIAL_TEMPLATE_0_TEXTURE_TYPES,
    MATERIAL_TEMPLATE_1_DISPLACEMENT_MATERIAL_REVISION_ID,
    MATERIAL_TEMPLATE_1_MATERIAL_REVISION_ID,
    MATERIAL_TEMPLATE_1_TEXTURE_TYPES,
    NEW_NODE_INIT_POSITION,
} from "@material-editor/models/material-editor"
import {formatToApiMaterialSocketType, getEnumSettingDefaultOption, getInputSocketDefaultValue} from "@material-editor/models/material-nodes"
import {ScreenSizes} from "@material-editor/models/screen-sizes"
import {NodeEditorComponent} from "@node-editor/components/node-editor/node-editor.component"
import {ConnectionEvent, MoveEvent, NodeAccessor, ParameterEvent, ParameterId, ParameterType, ParameterValue, SelectEvent} from "@node-editor/models"
import {firstValueFrom} from "rxjs"
import {BackgroundOperationService} from "@app/platform/services/background-operation/background-operation.service"
import tippy from "tippy.js"
import {PreviewSceneNewComponent} from "@app/material-editor/components/preview-scene-new/preview-scene-new.component"

@Component({
    selector: "cm-material-editor",
    templateUrl: "./material-editor.component.html",
    styleUrls: ["./material-editor.component.scss"],
    standalone: true,
    imports: [
        RoutedDialogComponent,
        NodeEditorComponent,
        MatMenuModule,
        FloatingButtonComponent,
        PreviewSceneComponent,
        TitleBarComponent,
        AddMenuSectionComponent,
        ListItemComponent,
        PreviewSceneNewComponent,
    ],
})
export class MaterialEditorComponent implements OnInit {
    @ViewChild("nodeEditor") nodeEditor!: NodeEditorComponent<IMaterialNode>

    screenSizes = ScreenSizes
    screenSize?: ScreenSizes
    dialogSizes = DialogSize
    nodeTypes = MaterialNodeTypes
    allowSave = true
    returnUrl: {url: string; relative: boolean} = {url: "../../", relative: true}

    private nodes: IMaterialNode[] = []
    private connections: IMaterialConnection[] = []

    material?: MaterialEditorMaterialFragment
    activeRevision: MaterialEditorMaterialRevisionFragment | null = null
    cyclesRevisions: MaterialEditorMaterialRevisionFragment[] = []
    materialGraph?: IMaterialGraph
    selectedNode: IMaterialNode | null = null

    /* TODO: The accessor is a super-structure that knows too much about the nodes. Ideally, all of its functions should be defined on a node
    itself. Some functions are anyways already added to the node in NodeEditorComponent.addComponentForNode.
    See https://github.com/colormass/platform/issues/1307#issuecomment-1566146464 */
    nodeAccessor: NodeAccessor<IMaterialNode> = {
        getNodeId: (node: IMaterialNode) => {
            return `${node.id}` // can be any string that uniquely identifies the given node
        },
        getPosition: (node: IMaterialNode) => {
            const param = node.parameters.find((x: IMaterialParameter) => x.name === "internal.location")
            const value = param?.value
            if (Array.isArray(value)) {
                return [value[0], -value[1]] // Y-axis is flipped
            } else {
                return [NEW_NODE_INIT_POSITION[0], -NEW_NODE_INIT_POSITION[1]]
            }
        },
        getParameter: (node: IMaterialNode, parameterId: string) => {
            //TODO: this should be more efficient, as it is called by change detection code
            return node.parameters.find((x: IMaterialParameter) => x.name === parameterId)?.value
        },
        setParameter: (node: IMaterialNode, parameterId: ParameterId, value: ParameterValue, type: ParameterType) => {
            const paramIdx = node.parameters.findIndex((param: IMaterialParameter) => param.name === parameterId)
            if (paramIdx >= 0) {
                if (value === undefined) {
                    node.parameters.splice(paramIdx, 1)
                } else {
                    node.parameters[paramIdx].value = value
                }
            } else if (value !== undefined) {
                node.parameters.push({name: parameterId, type: type, value: value})
            }
            this.addTask(this.updateMaterialGraph())
        },
        setParameterNoUpdate: (node: IMaterialNode, parameterId: ParameterId, value: ParameterValue, type: ParameterType) => {
            const paramIdx = node.parameters.findIndex((param: IMaterialParameter) => param.name === parameterId)
            if (paramIdx >= 0) {
                if (value === undefined) {
                    node.parameters.splice(paramIdx, 1)
                } else {
                    node.parameters[paramIdx].value = value
                }
            } else if (value !== undefined) {
                node.parameters.push({name: parameterId, type: type, value: value})
            }
        },
        getInputConnections: (node: IMaterialNode) => {
            return this.connections
                .filter((x) => x.destination === node.id)
                .map((x) => ({
                    sourceNodeId: `${x.source}`,
                    sourceSocketId: x.sourceParameter,
                    destinationSocketId: x.destinationParameter,
                }))
        },

        disabled: (node: IMaterialNode) => {
            const param = node.parameters.find((param: IMaterialParameter) => param.name === "internal.disabled")
            return param?.value as boolean
        },

        disableable: (node: IMaterialNode): boolean => {
            return (
                node.name === RgbCurvesNodeType.name ||
                node.name === HsvNodeType.name ||
                node.name === GammaNodeType.name ||
                node.name === BrightContrastNodeType.name ||
                node.name === InvertNodeType.name
            )
        },

        toggleDisabled: (node: IMaterialNode) => {
            let param = node.parameters.find((param: IMaterialParameter) => param.name === "internal.disabled")
            if (!param) {
                param = {name: "internal.disabled", type: "boolean", value: false}
                node.parameters.push(param)
            }

            param.value = !param.value
            this.addTask(this.updateMaterialGraph())
        },
    }

    nodeEditorTemplateId?: number

    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)
    materialGraphService = inject(MaterialGraphService)
    backgroundOperationService = inject(BackgroundOperationService)

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private utils: UtilsService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private refresh: RefreshService,
    ) {}

    ngOnInit() {
        void this.loadNodeEditorTemplateIds()
        void this.loadData()
    }

    async loadData() {
        const materialId = this.route.snapshot.paramMap.get("itemId") ?? ""

        const _entryModes = entryModes.filter((m) => this.route.snapshot.routeConfig?.path?.includes(m))
        if (_entryModes?.length !== 1) {
            throw new Error("Cannot parse the entry mode")
        }
        const entryMode: EntryMode = _entryModes[0]
        if (entryMode === "edit") {
            const materialRevisionId = this.route.snapshot.paramMap.get("revisionId") ?? ""
            const {material} = await this.sdk.gql.materialEditorMaterial({id: materialId})
            this.material = material
            this.material.revisions = this.material.revisions.sort((a, b) => (a.number > b.number ? -1 : 1))
            this.cyclesRevisions = material.revisions.filter((revision) => revision.hasCyclesMaterial)
            const revision = materialRevisionId == null ? material.revisions[0] : material.revisions.find((x) => x.id === materialRevisionId)
            this.loadRevision(revision ?? null)
        } else if (entryMode === "setup" || entryMode === "preview") {
            const pending: Promise<void>[] = []
            pending.push(
                this.sdk.gql.materialEditorMaterial({id: materialId}).then(({material}) => {
                    this.material = material
                }),
            )

            if (entryMode === "setup") {
                const textureSetId = this.route.snapshot.paramMap.get("textureSetId") ?? ""
                pending.push(this.sdk.gql.materialEditorTextureSet({id: textureSetId}).then(({textureSet}) => this.setupFromTextureSetNew(textureSet)))
            } else {
                const key = this.route.snapshot.paramMap.get("keyForKeyValueStore")
                if (!key) {
                    throw new Error("No key for key value store")
                }
                // const value: {output: OutputTextureInfo[]; returnUrl: string} = this.utils.keyValueStore.get(key)
                this.utils.keyValueStore.delete(key)
                throw new Error("Setting material from texture tiling output is not supported anymore")
                // pending.push(this.setupFromTilingOutputTextureInfo(value.output))
                // this.allowSave = false
                // this.returnUrl = {url: value.returnUrl, relative: false}
            }

            let errorMsg = "Setting material failed"
            if (entryMode === "setup") errorMsg = "Setting material from texture set failed"
            else if (entryMode === "preview") errorMsg = "Setting material from texture tiling output failed"

            this.addTask(
                (async () => {
                    await Promise.all(pending)
                    this.activeRevision = null
                    await this.updateMaterialGraph()
                })(),
                {errorMsg},
            )
        }
    }

    async loadNodeEditorTemplateIds() {
        const ownOrganizations = await this.organizations.own
        if (ownOrganizations) {
            this.sdk.gql.organizationsWithNodeEditorTemplateId({organizationIds: ownOrganizations.map((organization) => organization.id)}).then((data) => {
                this.nodeEditorTemplateId = data.organizations.map((organization) => organization?.nodeEditorTemplate?.legacyId).filter(IsNonNull)?.[0]
            })
        }
    }

    ngAfterViewInit() {
        const template = document.getElementById("addNodesContainer")
        if (template) {
            template.style.display = "block"
            tippy("#addNodes", {
                content: template,
                allowHTML: true,
                placement: "top-start",
                arrow: false,
                offset: [0, 10],
                interactive: true,
                interactiveBorder: 30,
                trigger: "click",
            })
        }
    }

    addTask(task: Promise<void>, options?: {successMsg?: string; errorMsg?: string}): void {
        task.then(() => {
            if (options?.successMsg) {
                this.snackBar.open(options?.successMsg, "", {duration: 3000})
            }
        }).catch((err) => {
            console.error(err)
            const title = options?.errorMsg ?? "Error"
            this.snackBar.open(`${title}: ${err.message}`, "Dismiss")
        })
    }

    async updateMaterialGraph(updateNodesAndConnections = true) {
        const activeRevisionId = this.activeRevision?.legacyId ?? -1
        if (this.material) {
            this.materialGraph = await this.materialGraphService.graphFromNodesAndConnections(
                this.nodes,
                this.connections,
                activeRevisionId,
                this.material.legacyId,
                this.material.name ?? "",
            )
            if (updateNodesAndConnections) {
                this.nodeEditor.updateNodesAndConnections(this.nodes)
            }
        }
    }

    updateUrlWithNewMaterialRevision(): void {
        const currUrl = window.location.href
        if (this.activeRevision) {
            const currMatRevisionStr = this.activeRevision.id.toString()
            const matRevisionStartIdx = currUrl.indexOf("edit/") + "edit/".length
            const matRevisionEndIdx = matRevisionStartIdx + currMatRevisionStr.length
            const newUrl = currUrl.substring(0, matRevisionStartIdx) + currMatRevisionStr + currUrl.substring(matRevisionEndIdx)

            window.history.replaceState({}, "", newUrl)
        }
    }

    loadRevision(revision: MaterialEditorMaterialRevisionFragment | null): void {
        this.activeRevision = revision
        if (revision) {
            this.addTask(
                (async () => {
                    const {materialNodes} = await this.sdk.gql.materialEditorMaterialNodes({materialRevisionId: revision.id})
                    const {materialConnections} = await this.sdk.gql.materialEditorMaterialConnections({materialRevisionId: revision.id})

                    this.updateUrlWithNewMaterialRevision()
                    this.nodes = materialNodes.filter(IsDefined).map(this.materialGraphService.convertNodeFromFragment)
                    this.connections = materialConnections.filter(IsDefined).map(this.materialGraphService.convertConnectionFromFragment)
                    return this.updateMaterialGraph()
                })(),
            )
        }
    }

    idCounter = 1000000

    // async addNode(nodeType: MaterialNodeType) {
    //     // TODO this is most likely wrong as when adding a new node this should only be done in memory and only upon the user hitting save it should save to a NEW revision !
    //     // TODO NOTE that there could be no current revision (in case we have just created one from a texture set and havn't saved yet)
    //     if (this.activeRevision) {
    //         const {createMaterialNode: nodeFragment} = await this.sdk.gql.materialEditorCreateMaterialNode({
    //             input: {
    //                 materialRevisionId: this.activeRevision.id,
    //                 name: nodeType.name,
    //                 parameters: [
    //                     ...nodeType.inputs.map((p) => {
    //                         return {
    //                             name: p.id,
    //                             type: formatToApiMaterialSocketType(p.valueType),
    //                             value: getInputSocketDefaultValue(p),
    //                         }
    //                     }),
    //                     ...nodeType.inputs.map((p) => {
    //                         return {
    //                             name: p.id,
    //                             type: formatToApiMaterialSocketType(p.valueType),
    //                             value: getInputSocketDefaultValue(p),
    //                         }
    //                     }),
    //                     ...(nodeType.settings?.map((p) => {
    //                         const option = getEnumSettingDefaultOption(p)
    //                         return {name: p.id, type: "string", value: option.value}
    //                     }) ?? []),
    //                     // In case of ValueNode or RgbNode, there are no inputs, only a single output which carries the value. In this case the output has to be added as a parameter.
    //                     ...(nodeType.inputs.length === 0
    //                         ? nodeType.outputs.map((p) => {
    //                               return {
    //                                   name: p.id,
    //                                   type: formatToApiMaterialSocketType(p.valueType),
    //                                   value: getInputSocketDefaultValue(p),
    //                               }
    //                           })
    //                         : []),
    //                     {name: "internal.location", type: "vector", value: NEW_NODE_INIT_POSITION},
    //                 ],
    //             },
    //         })
    //         this.nodes.push(node)
    //         this.addTask(this.updateMaterialGraph())
    //     }
    // }

    // async onAddConnection(event: ConnectionEvent<MaterialEditorMaterialNodeFragment>) {
    //     console.log("Connection added:", event)
    //     //TODO: type-check sockets, allowing implicit conversion
    //     //TODO: check graph invariants (no cycles, etc.)
    //     if (this.activeRevision) {
    //         const {createMaterialConnection: connection} = await this.sdk.gql.materialEditorCreateMaterialConnection({
    //             input: {
    //                 materialRevisionId: this.activeRevision.id,
    //                 sourceId: event.sourceNode.id,
    //                 sourceParameter: event.sourceSocketId,
    //                 destinationId: event.destinationNode.id,
    //                 destinationParameter: event.destinationSocketId,
    //             },
    //         })
    //         this.connections.push(connection)
    //         this.addTask(this.updateMaterialGraph())
    //     }
    // }

    addNode(nodeType: MaterialNodeType): void {
        const node: IMaterialNode = {
            id: (this.idCounter++).toString(),
            name: nodeType.name,
            parameters: [],
        }

        nodeType.inputs.forEach((p) => {
            const defaultValue = getInputSocketDefaultValue(p)
            // do not consider null to be a valid default value for input socket of type plain / input
            // these either need to be connected, or we rely on render engines (cycles / threejs) to properly fill in
            // the defaults for the given param
            // TODO verify that the above makes sense
            if (p.type === "input" && (p.valueType === "input" || p.valueType === "plain") && defaultValue === null) return
            node.parameters.push({name: p.id, type: formatToApiMaterialSocketType(p.valueType), value: defaultValue})
        })

        nodeType.settings?.forEach((p) => {
            const option = getEnumSettingDefaultOption(p)
            node.parameters.push({name: p.id, type: "string", value: option.value})
        })

        // In case of ValueNode or RgbNode, there are no inputs, only a single output which carries the value. In this case the output has to be added as a parameter.
        if (nodeType.inputs.length === 0) {
            nodeType.outputs.forEach((p) => {
                node.parameters.push({name: p.id, type: formatToApiMaterialSocketType(p.valueType), value: getInputSocketDefaultValue(p)})
            })
        }

        node.parameters.push({name: "internal.location", type: "vector", value: NEW_NODE_INIT_POSITION})
        this.nodes.push(node)
        this.addTask(this.updateMaterialGraph())
    }

    onAddConnection(event: ConnectionEvent<IMaterialNode>): void {
        console.log("Connection added:", event)
        //TODO: type-check sockets, allowing implicit conversion
        //TODO: check graph invariants (no cycles, etc.)
        const connection: IMaterialConnection = {
            source: event.sourceNode.id,
            sourceParameter: event.sourceSocketId,
            destination: event.destinationNode.id,
            destinationParameter: event.destinationSocketId,
        }
        this.connections.push(connection)
        this.addTask(this.updateMaterialGraph())
    }

    onDeleteNodes(event: IMaterialNode[]): void {
        console.log("Nodes deleted:", event)
        for (const node of event) {
            const connectionsToDeletedNode = this.connections.filter((connection) => connection.destination === node.id)
            const connectionsFromDeletedNode = this.connections.filter((connection) => connection.source === node.id)
            for (const connection of connectionsToDeletedNode) {
                const connectionIndex = this.connections.indexOf(connection)
                if (connectionIndex >= 0) this.connections.splice(connectionIndex, 1)
            }
            for (const connection of connectionsFromDeletedNode) {
                const connectionIndex = this.connections.indexOf(connection)
                if (connectionIndex >= 0) this.connections.splice(connectionIndex, 1)
            }
            const foundIdx = this.nodes.findIndex((x) => x.id === node.id)
            if (foundIdx >= 0) {
                //TODO: delete node entity
                this.nodes.splice(foundIdx, 1)
            }
        }
        this.addTask(this.updateMaterialGraph())
    }

    onDeleteConnections(event: ConnectionEvent<IMaterialNode>[]): void {
        console.log("Connections deleted:", event)
        for (const c of event) {
            const foundIdx = this.connections.findIndex(
                (x) =>
                    x.source === c.sourceNode.id &&
                    x.sourceParameter === c.sourceSocketId &&
                    x.destination === c.destinationNode.id &&
                    x.destinationParameter === c.destinationSocketId,
            )
            if (foundIdx >= 0) {
                //TODO: delete connection entity
                this.connections.splice(foundIdx, 1)
            }
        }
        this.addTask(this.updateMaterialGraph())
    }

    onMoveNode(event: MoveEvent<IMaterialNode>): void {
        let param = event.node.parameters.find((x: IMaterialParameter) => x.name === "internal.location")
        if (!param) {
            param = {name: "internal.location", type: undefined, value: undefined} //TODO: correct type?
        } else if (event.position) {
            param.value = [event.position[0], -event.position[1]] // Y axis is flipped
        }
        //TODO: save updated params
    }

    onSelectNode(event: SelectEvent<IMaterialNode | null>): void {
        if (event.node && this.selectedNode !== event.node) {
            this.selectedNode = event.node
        } else {
            this.selectedNode = null
        }
    }

    onParameterChange(event: ParameterEvent<IMaterialNode>): void {
        let param
        if (event.type === "add") {
            // TODO: Does this event type make sense? Normally no new parameters get added, the RgbCurvesNodes' curve only requires adding new parameters
            //  because at the moment we store each of those as separate parameters instead of an array (which we probably should do).
            // param = {name: event.parameter.id, type: event.parameter.type, value: event.parameter.value}
            // event.node.parameters.push(param)
        } else if (event.type === "update") {
            param = event.node.parameters.find((x: IMaterialParameter) => x.name === event.parameter.id)
            if (!param) throw Error(`Cannot find parameter: ${event.parameter.id}.`)
            param.value = event.parameter.value
        } else if (event.type === "delete") {
            // TODO: Does this event type make sense? Normally no new parameters get added, the RgbCurvesNodes' curve only requires adding new parameters
            //  because at the moment we store each of those as separate parameters instead of an array (which we probably should do).
            // const paramIndex = event.node.parameters.findIndex((param: any) => param.name === event.parameter.id)
            // event.node.parameters.splice(paramIndex, 1)
        } else if (event.type === "updateGraph") {
            // TODO: This is just a quick hack to be able to trigger graph update from ImageTextureNode.
            this.addTask(this.updateMaterialGraph(false))
        }
        this.addTask(this.updateMaterialGraph(false))
    }

    overlayClosed() {
        void this.router.navigate(
            [this.returnUrl.url],
            this.returnUrl.relative
                ? {
                      relativeTo: this.route,
                      queryParamsHandling: "preserve",
                  }
                : {queryParamsHandling: "preserve"},
        )
    }

    // async setupFromTilingOutputTextureInfo(output: Tiling.OutputTextureInfo[]) {
    //     const set0 = new Set(MATERIAL_TEMPLATE_0_TEXTURE_TYPES)
    //     const set1 = new Set(MATERIAL_TEMPLATE_1_TEXTURE_TYPES)
    //     let dimensions: [number, number] | null = null

    //     const checkTextures = new Set([...MATERIAL_TEMPLATE_0_TEXTURE_TYPES, ...MATERIAL_TEMPLATE_1_TEXTURE_TYPES])
    //     output.map((info) => {
    //         const infoType = mapRestToGqlTextureType(info.type)
    //         if (!checkTextures.has(infoType)) return

    //         if (set0.has(infoType)) {
    //             set0.delete(infoType)
    //         }
    //         if (set1.has(infoType)) {
    //             set1.delete(infoType)
    //         }

    //         if (!dimensions) {
    //             dimensions = [info.width_cm, info.height_cm]
    //         } else if (!(dimensions[0] === info.width_cm && dimensions[1] === info.height_cm)) {
    //             throw new Error("Non matching physical sizes for tiling outputs")
    //         }
    //     })

    //     if (set0.size !== 0 && set1.size !== 0) throw new Error("Not all required texture types present")
    //     const templateRevisionId = set0.size === 0 ? MATERIAL_TEMPLATE_0_MATERIAL_REVISION_ID : MATERIAL_TEMPLATE_1_MATERIAL_REVISION_ID

    //     const {materialNodes: templateMaterialNodes} = await this.sdk.gql.materialEditorMaterialNodes({
    //         materialRevisionId: templateRevisionId,
    //     })

    //     this.nodes = templateMaterialNodes.filter(IsDefined).map((nodeFragment) => {
    //         const node = this.materialGraphService.convertNodeFromFragment(nodeFragment)
    //         if (node.name === "Mapping") {
    //             const idx = node.parameters.findIndex((p: IMaterialParameter) => p.name === "Scale")
    //             node.parameters[idx].value = dimensions ? [...dimensions, 1.0] : [1.0]
    //         } else if (node.name === "TexImage") {
    //             if (!isTransientDataObject(node.textureRevision)) {
    //                 throw new Error("TextureRevision is not a transient data object")
    //             }
    //             const info = output.find((info) => mapRestToGqlTextureType(info.type) === nodeFragment.textureRevision?.texture?.type)
    //             if (info && node.textureRevision) {
    //                 return {
    //                     ...node,
    //                     textureRevision: {
    //                         ...node.textureRevision,
    //                         data: info.preview.data,
    //                         mediaType: parseMediaType(info.preview.contentType),
    //                         imageColorSpace: mapRestToGqlTextureType(info.type) === TextureType.Diffuse ? ImageColorSpace.Srgb : ImageColorSpace.Linear,
    //                     },
    //                 }
    //             }
    //         }
    //         return node
    //     })

    //     const {materialConnections: templateMaterialConnections} = await this.sdk.gql.materialEditorMaterialConnections({
    //         materialRevisionId: templateRevisionId,
    //     })
    //     this.connections = templateMaterialConnections.filter(IsDefined).map(this.materialGraphService.convertConnectionFromFragment)
    // }

    async setupFromTextureSet(textureSet: MaterialEditorTextureSetFragment): Promise<void> {
        const set0 = new Set(MATERIAL_TEMPLATE_0_TEXTURE_TYPES)
        const set1 = new Set(MATERIAL_TEMPLATE_1_TEXTURE_TYPES)
        const checkTextures = new Set([...MATERIAL_TEMPLATE_0_TEXTURE_TYPES, ...MATERIAL_TEMPLATE_1_TEXTURE_TYPES])

        const dimensionRange = getDimensionRange(
            textureSet.textures
                .filter((texture) => checkTextures.has(texture.type))
                .map((texture) => texture.latestRevision)
                .filter(IsDefined),
        )
        const typeToRevision = (type?: TextureType) => (type ? textureSet.textures.find((texture) => texture.type === type)?.latestRevision : undefined)

        textureSet.textures.forEach((texture) => {
            if (!checkTextures.has(texture.type)) return
            if (set0.has(texture.type)) set0.delete(texture.type)
            if (set1.has(texture.type)) set1.delete(texture.type)
        })
        const _textureRevisions = textureSet.textures.map((texture) => texture.latestRevision)
        if (!dimensionRange) {
            throw new Error("Non matching physical sizes for texture revisions")
        }
        if (
            Math.abs(dimensionRange.maxWidth - dimensionRange.minWidth) > ALLOWED_MAP_DIMENSION_VARIATION_IN_CM ||
            Math.abs(dimensionRange.maxHeight - dimensionRange.minHeight) > ALLOWED_MAP_DIMENSION_VARIATION_IN_CM
        ) {
            throw new Error("Non matching physical sizes for texture revisions")
        }

        const allTexturesPresent = (set: Set<TextureType>) => set.size === 0 || (set.size === 1 && set.has(TextureType.Displacement))

        if (!allTexturesPresent(set0) && !allTexturesPresent(set1)) throw new Error("Not all required texture types present")

        const getMaterialTemplateRevision = (set: Set<TextureType>, withoutDisplacementId: string, withDisplacementId: string) => {
            if (!allTexturesPresent(set)) throw new Error(`Not all required texture types present for set ${set}`)
            if (set.size === 0) return withDisplacementId
            else return withoutDisplacementId
        }

        const templateRevisionId = allTexturesPresent(set0)
            ? getMaterialTemplateRevision(set0, MATERIAL_TEMPLATE_0_MATERIAL_REVISION_ID, MATERIAL_TEMPLATE_0_DISPLACEMENT_MATERIAL_REVISION_ID)
            : getMaterialTemplateRevision(set1, MATERIAL_TEMPLATE_1_MATERIAL_REVISION_ID, MATERIAL_TEMPLATE_1_DISPLACEMENT_MATERIAL_REVISION_ID)

        const dimensions = [(dimensionRange.maxWidth + dimensionRange.minWidth) * 0.5, (dimensionRange.maxHeight + dimensionRange.minHeight) * 0.5]
        const {materialNodes} = await this.sdk.gql.materialEditorMaterialNodes({
            materialRevisionId: templateRevisionId,
        })
        this.nodes = materialNodes.filter(IsDefined).map((nodeFragment) => {
            const node = this.materialGraphService.convertNodeFromFragment(nodeFragment)
            if (node.name === "Mapping") {
                const parameters = node.parameters as Array<{name: string; value?: string}>
                const idx = parameters.findIndex((p) => p.name === "Scale")
                return {
                    ...node,
                    parameters: parameters.map((parameter, index) => {
                        if (index === idx) {
                            return {
                                ...parameter,
                                value: dimensions ? [...dimensions, 1.0] : [1.0],
                            }
                        }
                        return parameter
                    }),
                }
            } else if (node.name === "TexImage") {
                return {
                    ...node,
                    textureRevision: typeToRevision(nodeFragment.textureRevision?.texture?.type)?.legacyId,
                }
            } else if (node.name === "ShaderNodeDisplacement") {
                const idx = node.parameters.findIndex((p: IMaterialParameter) => p.name === "Scale")
                const displacementRevision = typeToRevision(TextureType.Displacement)
                if (displacementRevision && displacementRevision.displacement) {
                    node.parameters[idx].value = displacementRevision.displacement
                }
            }
            return node
        })

        const {materialConnections} = await this.sdk.gql.materialEditorMaterialConnections({
            materialRevisionId: templateRevisionId,
        })
        this.connections = materialConnections.filter(IsDefined).map(this.materialGraphService.convertConnectionFromFragment)
    }

    async setupFromTextureSetNew(textureSet: MaterialEditorTextureSetFragment): Promise<void> {
        const templateRevisionId = Settings.MATERIAL_TEMPLATE_2_MATERIAL_REVISION_ID
        const textureSetRevisionId = await this.sdk.gql
            .materialEditorLatestTextureSetRevision({textureSetId: textureSet.id})
            .then((result) => result.textureSetRevisions.find(IsDefined)?.id)
        if (!textureSetRevisionId) {
            throw new Error("No texture set revision found")
        }
        const {materialNodes} = await this.sdk.gql.materialEditorMaterialNodes({
            materialRevisionId: templateRevisionId,
        })
        this.nodes = materialNodes.filter(IsDefined).map((nodeFragment) => {
            const node = this.materialGraphService.convertNodeFromFragment(nodeFragment)
            if (node.name === "ShaderNodeTextureSet") {
                if (!node.parameters) {
                    node.parameters = []
                } else {
                    node.parameters = node.parameters.filter((p) => p.name !== "TextureSetRevisionId")
                }
                node.parameters.push({
                    name: "TextureSetRevisionId",
                    type: "string",
                    value: textureSetRevisionId,
                })
            }
            return node
        })

        const {materialConnections} = await this.sdk.gql.materialEditorMaterialConnections({
            materialRevisionId: templateRevisionId,
        })
        this.connections = materialConnections.filter(IsDefined).map(this.materialGraphService.convertConnectionFromFragment)
    }

    async saveNewMaterialRevision() {
        const _saveOp = async (result: SaveMaterialRevisionDialogResult | false): Promise<MaterialEditorMaterialRevisionFragment | null> => {
            if (typeof result === "boolean") {
                return null
            }

            const materialId = result.materialId
            if (materialId) {
                const backgroundOpItem = this.backgroundOperationService.addBackgroundOperationToList("Saving", "Material revision", false, true)
                backgroundOpItem.progressSubject.next(-1)
                const {createMaterialRevision: newRevision} = await this.sdk.gql.materialEditorCreateMaterialRevision({
                    input: {
                        materialId: materialId,
                        graphSchema: graphSchemaString(),
                        comment: `${result.comment} (Platform ${Settings.APP_VERSION})`.trim(),
                    },
                })

                const saveNodeOp = async (refNode: IMaterialNode) => {
                    let textureRevisionId: string | undefined = undefined
                    // only store explicit texture revisions if not referencing a whole set already
                    if (!refNode.textureSetRevision) {
                        // special case for transient data objects
                        if (isTransientDataObject(refNode.textureRevision)) {
                            throw new Error("Transient data object not supported in save operation")
                        }
                        textureRevisionId = refNode.textureRevision
                            ? await this.sdk.gql
                                  .materialEditorResolveTextureRevisionLegacyId({legacyId: refNode.textureRevision})
                                  .then((result) => result.textureRevision.id)
                            : undefined
                    }
                    const {createMaterialNode: newMaterialNodeFragment} = await this.sdk.gql.materialEditorCreateMaterialNode({
                        input: {
                            materialRevisionId: newRevision.id,
                            parameters: refNode.parameters,
                            name: refNode.name,
                            textureRevisionId: textureRevisionId,
                            textureSetRevisionId: refNode.textureSetRevision?.id,
                        },
                    })
                    return this.materialGraphService.convertNodeFromFragment(newMaterialNodeFragment)
                }

                const idToNodeMap_ = await Promise.all(this.nodes.map(async (node) => Promise.all([node.id, saveNodeOp(node)])))
                const idToNodeMap = new Map<string, IMaterialNode>(idToNodeMap_)
                this.nodes = Array.from(idToNodeMap.values())

                const saveConnectionOp = async (refConnection: IMaterialConnection) => {
                    const sourceNode = idToNodeMap.get(refConnection.source)
                    if (!sourceNode) throw new Error("Can't find source node")
                    const destinationNode = idToNodeMap.get(refConnection.destination)
                    if (!destinationNode) throw new Error("Can't find destination node")

                    const {createMaterialConnection: newConnectionFragment} = await this.sdk.gql.materialEditorCreateMaterialConnection({
                        input: {
                            destinationId: destinationNode.id,
                            destinationParameter: refConnection.destinationParameter,
                            materialRevisionId: newRevision.id,
                            sourceId: sourceNode.id,
                            sourceParameter: refConnection.sourceParameter,
                        },
                    })
                    return this.materialGraphService.convertConnectionFromFragment(newConnectionFragment)
                }

                this.connections = await Promise.all(this.connections.map(async (connection) => saveConnectionOp(connection)))

                backgroundOpItem.progressSubject.complete()

                this.refresh.item(this.material)

                return newRevision
            }

            return null
        }

        const dialogRef: MatDialogRef<SaveMaterialRevisionDialogComponent, SaveMaterialRevisionDialogResult> = this.dialog.open(
            SaveMaterialRevisionDialogComponent,
            {
                width: "450px",
                data: {
                    materialId: this.material?.id,
                },
            },
        )

        const result = await firstValueFrom(dialogRef.afterClosed())
        if (!result) return

        this.addTask(
            (async () => {
                this.activeRevision = await _saveOp(result)
                await this.updateMaterialGraph()
            })(),
            {
                successMsg: "Material revision saved.",
                errorMsg: "Can't save new material revision.",
            },
        )
    }

    protected isNodeTypePermitted(nodeType: MaterialNodeType) {
        if (nodeType === ImageTextureNodeType) {
            return false // don't allow to add ImageTextureNode as it is superceded by ImageTextureSetNodeType
        }
        return true
    }

    protected readonly MaterialNodeTypes = MaterialNodeComponentTypes
}
