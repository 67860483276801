import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {ChannelLayout, DataType, ImageDescriptor, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {deepEqual} from "@cm/lib/utils/utils"
import {getImgProcChannelLayout, getImgProcDataType} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {IMGPROC_USE_FLOAT32_EVERYWHERE} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-imgproc"

export type ParameterType = {
    sourceImage: ImageRef
    channelLayout?: ChannelLayout
    dataType?: DataType
    resultImage?: ImageRef
}

export type ReturnType = ImageRef

export const imageOpConvert: ImageOpType<ParameterType, ReturnType> = {
    name: "Convert",

    WebGL2: ({cmdQueue, parameters: {sourceImage, channelLayout, dataType, resultImage}}) => {
        const painter = cmdQueue.createPainter(
            "compositor",
            "convert",
            `
            vec4 computeColor(ivec2 targetPixel) {
                return texelFetch0(targetPixel);
            }
        `,
        )
        const resultDescriptor: ImageDescriptor = {
            ...sourceImage.descriptor,
            channelLayout: channelLayout ?? sourceImage.descriptor.channelLayout,
            dataType: dataType ?? sourceImage.descriptor.dataType,
        }
        if (!resultImage && deepEqual(resultDescriptor, sourceImage.descriptor)) {
            // if no conversion is needed and no result image is specified, return the source image
            return sourceImage
        }
        resultImage = cmdQueue.prepareResultImage(resultImage, resultDescriptor)
        cmdQueue.paint(painter, {
            sourceImages: sourceImage,
            resultImage,
        })
        return resultImage
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, channelLayout, dataType, resultImage}}) => {
        if (IMGPROC_USE_FLOAT32_EVERYWHERE) {
            dataType = "float32"
        }
        const resultDescriptor: ImageDescriptor = {
            ...sourceImage.descriptor,
            channelLayout: channelLayout ?? sourceImage.descriptor.channelLayout,
            dataType: dataType ?? sourceImage.descriptor.dataType,
        }
        if (!resultImage && deepEqual(resultDescriptor, sourceImage.descriptor)) {
            // if no conversion is needed and no result image is specified, return the source image
            return sourceImage
        }
        const result = cmdQueue.createImage(resultDescriptor, {
            type: "convert",
            input: sourceImage,
            dataType: getImgProcDataType(resultDescriptor.dataType),
            channelLayout: getImgProcChannelLayout(resultDescriptor.channelLayout),
        })
        return cmdQueue.copyToResultImage(result, resultImage)
    },
}

export function convert(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpConvert, parameters)
}
