import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export type ParameterType = {
    sourceImage: ImageRef
    mode: "luminance" | "average"
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export const imageOpToGrayscale: ImageOpType<ParameterType, ReturnType> = {
    name: "ToGrayscale",

    WebGL2: ({cmdQueue, parameters: {sourceImage, mode, resultImageOrDataType}}) => {
        const painter = cmdQueue.createPainter(
            "compositor",
            "toGrayscale",
            `
            float toGrayscale(vec3 rgb) {
                ${mode === "luminance" ? "return dot(rgb, vec3(0.2126, 0.7152, 0.0722));" : "return (rgb.r + rgb.g + rgb.b) / 3.0;"}
            }

            vec4 computeColor(ivec2 targetPixel) {
                vec4 source = texelFetch0(targetPixel);
                float grayscale = toGrayscale(source.rgb);
                return vec4(grayscale, grayscale, grayscale, 1.0);
            }
        `,
        )
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, {
            ...sourceImage.descriptor,
            channelLayout: "R",
        })
        cmdQueue.paint(painter, {
            sourceImages: sourceImage,
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, mode, resultImageOrDataType}}) => {
        const resultNode = cmdQueue.createImage(
            {
                ...sourceImage.descriptor,
                channelLayout: "R",
            },
            {type: "toGrayscale", input: sourceImage, mode},
        )
        return cmdQueue.copyToResultImage(resultNode, resultImageOrDataType)
    },
}

export function toGrayscale(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpToGrayscale, parameters)
}
