import {reduce} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-reduce"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

const SCOPE_NAME = "FindCorrelationPeak"

export type ParameterType = {
    correlation: ImageRef
}

export type ReturnType = ImageRef

export const findCorrelationPeak = (cmdQueue: ImageOpCommandQueue, {correlation}: ParameterType): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)
    const result = reduce(cmdQueue, {
        sourceImage: correlation,
        operator: "custom",
        //resultDataType: "float32", // TODO do we really need float32 here
        custom: {
            preProcess: {
                name: "preProcess",
                code: `
                        vec4 preProcess(vec4 value, ivec2 index) {
                            return vec4(value.r, float(index.x), float(index.y), 0);
                        }
                    `,
                resultChannelLayout: "RGB",
            },
            reduce: {
                name: "reduce",
                code: `
                        vec4 reduce(vec4 a, vec4 b) {
                            return a.r > b.r ? a : b;
                        }
                    `,
                resultChannelLayout: "RGB",
            },
        },
    })
    cmdQueue.endScope(SCOPE_NAME)
    return result
}
