// @ts-strict-ignore
import {Matrix4, Vector3} from "@cm/lib/math"
import {Navigation} from "@editor/helpers/scene/navigation"
import {ThreeScene} from "@editor/helpers/scene/three-proxies/scene"
import {toThreeMatrix, toThreeVector, fromThreeMatrix} from "@template-editor/helpers/three-utils"
import {TransformControls as THREETransformControls, TransformControlsEventMap} from "@editor/helpers/scene/three-proxies/transform-controls"
import {fromEvent, Observable, Subject, takeUntil} from "rxjs"
import * as THREE from "three"
import {RenderView} from "@editor/helpers/scene/three-proxies/render-view"

export enum TransformMode {
    Translate,
    Rotate,
    Scale,
}

export class TransformControls {
    readonly transformationBegin = new Subject<void>()
    readonly transformationChanged = new Subject<Matrix4>()
    readonly transformationEnd = new Subject<void>()
    private destroySubject = new Subject<void>()

    private _mode = TransformMode.Translate

    readonly transformControls: THREETransformControls

    private _worldMatrix: Matrix4 | null = null
    private _position = new Vector3(0, 0, 0)
    private _rotation = new Vector3(0, 0, 0)

    public allowScale = false

    constructor(
        private displayScene: ThreeScene,
        private viewsNavigationAccessor: () => Navigation[],
    ) {
        this.transformControls = new THREETransformControls(1)
        this.transformControls.size = (1.0 / 50.0) * 20.0
        this.transformControls.space = "world"

        const fromTypedEvent = <T extends keyof TransformControlsEventMap>(transformControls: THREETransformControls, key: T) => {
            return fromEvent(transformControls, key) as unknown as Observable<TransformControlsEventMap[T]>
        }

        fromTypedEvent(this.transformControls, "dragging-changed")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                if (event.value) {
                    this.viewsNavigationAccessor().map((n) => n.disable())
                    this.transformationBegin.next()
                } else {
                    this.viewsNavigationAccessor().map((n) => n.enable())
                    this.transformationEnd.next()
                }
            })

        fromTypedEvent(this.transformControls, "change")
            .pipe(takeUntil(this.destroySubject))
            .subscribe(() => {
                this.displayScene.update() // re-render transform controls
            })

        fromTypedEvent(this.transformControls, "transform")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                this.handleTransformEvent(event.position, event.quaternion)
            })
    }

    addView(view: RenderView): void {
        // FIXME SceneView
        const camera = view.camera.threeCamera
        const domElement = view.getDOMElement()

        this.transformControls.registerDOMElement(domElement)
        this.transformControls.setCameraForDOMElement(domElement, camera)

        // TODO: this is intercepting events even when input fields are focused
        fromEvent<KeyboardEvent>(domElement, "keydown")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((event) => {
                switch (event.key) {
                    case "w":
                    case "W":
                        event.stopPropagation()
                        this.objectSpace = event.shiftKey
                        this.mode = TransformMode.Translate
                        break
                    case "e":
                    case "E":
                        event.stopPropagation()
                        this.objectSpace = event.shiftKey
                        this.mode = TransformMode.Rotate
                        break
                    case "r":
                    case "R":
                        event.stopPropagation()
                        if (this.allowScale) {
                            this.objectSpace = event.shiftKey
                            this.mode = TransformMode.Scale
                        }
                        break
                }
            })
    }

    removeView(view: RenderView): void {
        const camera = view.camera.threeCamera
        this.transformControls.unregisterDOMElement(view.getDOMElement())

        // TODO unsubscribe from keyboard events
    }

    updateViewCamera(view: RenderView): void {
        this.transformControls.setCameraForDOMElement(view.getDOMElement(), view.camera.threeCamera)
    }

    private handleTransformEvent(newPosition?: THREE.Vector3, newQuaternion?: THREE.Quaternion) {
        // get current world space transform
        if (this._worldMatrix) {
            const position = new THREE.Vector3()
            const quaternion = new THREE.Quaternion()
            const scale = new THREE.Vector3()
            let matrixWorld = toThreeMatrix(this._worldMatrix)
            matrixWorld.decompose(position, quaternion, scale)

            // apply new transform components from event
            if (newPosition) position.copy(newPosition)
            if (newQuaternion) quaternion.copy(newQuaternion)

            matrixWorld = new THREE.Matrix4()
            matrixWorld.compose(position, quaternion, scale)

            this.transformationChanged.next(fromThreeMatrix(matrixWorld))
        }
    }

    set mode(mode: TransformMode) {
        this._mode = mode
        switch (mode) {
            case TransformMode.Translate: {
                this.transformControls.mode = "translate"
                break
            }
            case TransformMode.Rotate: {
                this.transformControls.mode = "rotate"
                break
            }
            case TransformMode.Scale: {
                this.transformControls.mode = "scale"
                break
            }
        }
        this.displayScene.update()
    }

    get mode() {
        return this._mode
    }

    set objectSpace(value: boolean) {
        this.transformControls.space = value ? "local" : "world"
    }

    get objectSpace() {
        return this.transformControls.space === "local"
    }

    set worldMatrix(matrix: Matrix4 | null) {
        let didChange = false
        if (matrix) {
            if (!this._worldMatrix || !matrix.equals(this._worldMatrix)) {
                didChange = true
            }
            this._worldMatrix = matrix
            const tmpPosition = new THREE.Vector3()
            const tmpQuaternion = new THREE.Quaternion()
            const tmpScale = new THREE.Vector3()
            const tmpEuler = new THREE.Euler()
            const threeMatrix = toThreeMatrix(this._worldMatrix)
            this.transformControls.transform = threeMatrix
            threeMatrix.decompose(tmpPosition, tmpQuaternion, tmpScale)
            this._position.x = tmpPosition.x
            this._position.y = tmpPosition.y
            this._position.z = tmpPosition.z
            tmpEuler.setFromQuaternion(tmpQuaternion)
            this._rotation.x = tmpEuler.x
            this._rotation.y = tmpEuler.y
            this._rotation.z = tmpEuler.z
        } else {
            if (this._worldMatrix) {
                didChange = true
            }
            this._worldMatrix = null
            this.transformControls.transform = null
        }

        if (didChange) {
            this.displayScene.update()
        }
    }

    get worldMatrix(): Matrix4 | null {
        return this._worldMatrix
    }

    get position(): Vector3 | null {
        return this._worldMatrix ? this._position : null
    }

    set position(position: Vector3) {
        this.handleTransformEvent(toThreeVector(position), undefined)
    }

    get rotation(): Vector3 | null {
        return this._worldMatrix ? this._rotation : null
    }

    set rotation(rotation: Vector3) {
        const quaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(rotation.x, rotation.y, rotation.z))
        this.handleTransformEvent(undefined, quaternion)
    }

    setPositionAndRotation(position: Vector3, rotation: Vector3): void {
        this.handleTransformEvent(toThreeVector(position), new THREE.Quaternion().setFromEuler(new THREE.Euler(rotation.x, rotation.y, rotation.z)))
    }

    destroy() {
        this.destroySubject.next()
        this.destroySubject.complete()
        ;(this.transformControls as any).dispose()
    }
}
