import {Component, inject, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatMenuModule} from "@angular/material/menu"
import {MatSlideToggleModule} from "@angular/material/slide-toggle"
import {ContentTypeModel, MutationUpdateTaskInput, TaskDetailsFragment, TaskState} from "@api"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {TasksService} from "@platform/services/tasks/tasks.service"

@Component({
    selector: "cm-task",
    templateUrl: "./task-details.component.html",
    styleUrls: ["./task-details.component.scss"],
    providers: [],
    standalone: true,
    imports: [
        MatSlideToggleModule,
        RoutedDialogComponent,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        ButtonComponent,
        TimeAgoPipe,
    ],
})
export class TaskDetailsComponent extends BaseDetailsComponent<TaskDetailsFragment, Omit<MutationUpdateTaskInput, "id">> implements OnInit {
    override _contentTypeModel = ContentTypeModel.Task
    override _fetchItem = this.sdk.gql.taskDetails
    override _updateItem = this.sdk.gql.updateTaskDetails

    override paramName = "taskId"

    tasks = inject(TasksService)
    comments: TaskDetailsFragment["comments"] = []

    newCommentText = ""
    dialogSizes = DialogSize

    override ngOnInit() {
        super.ngOnInit()
        this.item$.subscribe((item) => {
            this.comments = item?.comments ?? []
        })
    }

    replaceLinks(text: string) {
        text = text.replace(/((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g, '<a target="_blank" href="$1">$1</a>')
        return text
    }

    async addComment() {
        const task = this.$item()
        if (!task) {
            throw new Error("There is no selected task")
        }
        if (this.auth.$actingAsCustomer()) {
            await this.sdk.gql.updateTaskDetails({
                input: {
                    id: task.id,
                    state: TaskState.InProgress,
                },
            })
        }

        await this.sdk.gql.taskDetailsCreateComment({
            input: {
                taskId: task.id,
                text: this.newCommentText,
            },
        })
        this.newCommentText = ""
        this.refresh.item(this.$item())
    }

    deleteComment(commentId: string): void {
        const task = this.$item()
        if (!task) {
            throw new Error("There is no selected task")
        }

        const oldComments = this.comments
        this.comments = this.comments.filter((comment) => comment.id !== commentId)
        this.notifications.withUndo(
            "Comment deleted",
            async () => {
                await this.sdk.gql.taskDetailsDeleteComment({
                    id: commentId,
                })
                this.refresh.item(this.$item())
            },
            () => {
                this.comments = oldComments
            },
        )
    }

    ngOnDestroy() {
        this.refresh.contentTypeModel(ContentTypeModel.Task)
    }
}
