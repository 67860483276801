import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Size2Like} from "ts-lib/dist/browser/math"

export const reshape = (
    sourceImage: ImageRef,
    shape: {
        width: number
        height: number
        batchSize: Size2Like
    },
): ImageRef => {
    if (sourceImage.descriptor.width !== shape.width || sourceImage.descriptor.height !== shape.height) {
        throw new Error(
            `reshape: source image size (${sourceImage.descriptor.width}, ${sourceImage.descriptor.height}) does not match target image size (${shape.width}, ${shape.height})`,
        )
    }
    if (shape.width % shape.batchSize.width !== 0 || shape.height % shape.batchSize.height !== 0) {
        throw new Error(
            `reshape: target image size (${shape.width}, ${shape.height}) is not a multiple of batchSize (${shape.batchSize.width}, ${shape.batchSize.height})`,
        )
    }
    return {
        ...sourceImage,
        descriptor: {
            ...sourceImage.descriptor,
            ...shape,
            batchSize: shape.batchSize,
        },
    }
}
