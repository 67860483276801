import {Component, EventEmitter, inject, OnInit, Output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonToggleModule} from "@angular/material/button-toggle"
import {MatTooltipModule} from "@angular/material/tooltip"
import {AssignUserFragment, NextActor, UserListItemFragment} from "@api"
import {AssignUserComponent} from "@platform/components/users/assign-user/assign-user.component"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"
import {IsDefined} from "@cm/lib/utils/filter"
import {SdkService} from "@common/services/sdk/sdk.service"

@Component({
    selector: "cm-assign-user-dialog",
    templateUrl: "./assign-user-dialog.component.html",
    styleUrls: ["./assign-user-dialog.component.scss"],
    standalone: true,
    imports: [MatTooltipModule, MatButtonToggleModule, FormsModule, UserThumbnailComponent],
})
export class AssignUserDialogComponent extends AssignUserComponent implements OnInit {
    @Output() userChange = new EventEmitter<{id: string} | undefined>()
    @Output() nextActorChange = new EventEmitter<NextActor>()
    readonly NextActor = NextActor

    sdk = inject(SdkService)

    visibleUsers: AssignUserFragment[] | null = null

    updateNextActor(nextActor: NextActor) {
        this.nextActor = nextActor
        this.nextActorChange.emit(nextActor)
        this.update()
    }

    async assignUser(user: Pick<UserListItemFragment, "id" | "firstName" | "lastName" | "galleryImage">) {
        this.userChange.emit(user)
        this.update()
    }

    async deleteAssignedUser() {
        this.userChange.emit(undefined)
        this.update()
    }

    ngOnInit() {
        this.sdk.gql.assignUserDialogVisibleUsers().then(({users}) => {
            this.visibleUsers = users.filter(IsDefined)
        })
    }
}
