import {IMaterialNode, IMaterialConnection, IMaterialGraph, isMaterialGraph} from "@src/materials/material-node-graph"
import {TextureResolution} from "../nodes/scene-properties"
import {hashObject} from "@src/utils/hashing"
import {MeshRenderSettings} from "./scene-object"
import {removeUndefinedEntriesFromObject} from "@src/utils/utils"

export {IMaterialNode, IMaterialConnection, IMaterialGraph, isMaterialGraph}

export type MaterialSide = "front" | "back" | "double"

export interface IMaterialData {
    name: string
    materialGraph: IMaterialGraph
    side: MaterialSide
    alphaMaskThreshold?: number
    realtimeSettings?: {
        disable?: boolean
        textureResolution?: TextureResolution
        textureFiltering?: "linear" | "nearest"
    }
}

export interface IMaterialRevision {
    id: number
}

export interface IMaterial {
    id: number
    getLatestCyclesRevision(): IMaterialRevision | undefined
}

export function keyForMaterialData(materialData: IMaterialData) {
    return hashObject(
        removeUndefinedEntriesFromObject({
            uniqueId: materialData.materialGraph.uniqueId,
            side: materialData.side,
            alphaMaskThreshold: materialData.alphaMaskThreshold,
            disable: materialData.realtimeSettings?.disable,
            textureResolution: materialData.realtimeSettings?.textureResolution,
            textureFiltering: materialData.realtimeSettings?.textureFiltering,
        }),
    )
}

export function keyForMeshMaterialData(materialData: IMaterialData, meshRenderSettings: MeshRenderSettings) {
    const {
        displacementImageResource,
        displacementUvChannel,
        displacementMin,
        displacementMax,
        displacementNormalStrength,
        displacementNormalSmoothness,
        displacementNormalOriginalResolution,
    } = meshRenderSettings

    if (displacementImageResource === undefined) {
        return keyForMaterialData(materialData)
    }

    return hashObject(
        removeUndefinedEntriesFromObject({
            materialMaterialData: keyForMaterialData(materialData),
            displacementImageResource,
            displacementUvChannel,
            displacementMin,
            displacementMax,
            displacementNormalStrength,
            displacementNormalSmoothness,
            displacementNormalOriginalResolution,
        }),
    )
}
