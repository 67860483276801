import {Component, EventEmitter, HostListener, inject, Input, Output, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ActionBarComponent, ActionItemComponent, ActionPanelComponent, ActionPanelRowComponent} from "@common/components/menu"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {ResolutionOption, ScanParameters, TablePosition} from "@app/platform/models/scanning/types"
import {ScanRegion} from "@app/platform/models/scanning"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {SdkService} from "@common/services/sdk/sdk.service"
import {ScanningActionBarTagFragment} from "@api"

@Component({
    selector: "cm-scanning-action-bar",
    templateUrl: "./scanning-action-bar.component.html",
    styleUrls: ["./scanning-action-bar.component.scss"],
    standalone: true,
    imports: [
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ActionPanelRowComponent,
        ActionPanelComponent,
        ActionBarComponent,
        ActionItemComponent,
        StateLabelComponent,
        MemoizePipe,
        DataObjectThumbnailComponent,
    ],
})
export class ScanningActionBarComponent {
    @ViewChild("scanSettingsActionItem") scanSettingsActionItem!: ActionItemComponent
    @ViewChild("debugActionItem") debugActionItem!: ActionItemComponent

    @Input() scanParams!: ScanParameters
    @Output() scanParamsChange = new EventEmitter<ScanParameters>()

    private _scanRegion?: ScanRegion
    @Input() set scanRegion(value: ScanRegion | undefined) {
        this._scanRegion = value
        this.updateMaterialRangeTag()
    }

    get scanRegion() {
        return this._scanRegion
    }

    @Input() notReady?: boolean
    @Input() taskInProgress?: boolean
    @Input() canStartScan?: boolean
    @Input() resolutionOptions?: ResolutionOption[]
    @Input() hasTransparencySupport?: boolean

    @Output() materialSelect = new EventEmitter<void>()
    @Output() overviewScanStart = new EventEmitter<void>()
    @Output() scanStart = new EventEmitter<void>()
    @Output() cancel = new EventEmitter<void>()
    @Output() tableCalibrationSkip = new EventEmitter<void>()
    @Output() tableCalibrationStart = new EventEmitter<void>()
    @Output() backlightCalibrationStart = new EventEmitter<void>()
    @Output() tableMove = new EventEmitter<TablePosition>()
    @Output() testImageCapture = new EventEmitter<void>()
    @Output() logsClick = new EventEmitter<void>()

    @HostListener("window:keydown.control.d", ["$event"])
    toggleDebugMenu(event: KeyboardEvent) {
        event.preventDefault()
        if (!this.$can().update.menu("debug")) {
            return
        }
        this.showDebugMenu = !this.showDebugMenu
        if (!this.showDebugMenu) {
            this.debugActionItem.panel?.hide()
        }
    }

    permission = inject(PermissionsService)
    $can = this.permission.$to

    rangeTag: ScanningActionBarTagFragment | null = null
    showDebugMenu = false

    sdk = inject(SdkService)

    activateScanRegionSettingsPanel(): void {
        this.scanSettingsActionItem.active = true
    }

    async updateMaterialRangeTag(): Promise<void> {
        if (!this.scanRegion?.material) return

        const {
            material: {tagAssignments: rangeTagAssignments},
        } = await this.sdk.gql.materialWithRangeTags({id: this.scanRegion.material.id})

        if (rangeTagAssignments.length > 0) {
            this.rangeTag = rangeTagAssignments[0].tag
        } else {
            this.rangeTag = null
        }
    }

    updateParams() {
        this.scanParamsChange.emit(this.scanParams)
    }

    protected readonly Enums = Enums
    protected readonly Labels = Labels
    protected readonly ThumbnailLayout = ThumbnailLayout
}
