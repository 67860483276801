import {
    Operator,
    OperatorCanvasToolboxType,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export class OperatorInitial extends OperatorBase<undefined> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["is-internal", "no-clone", "apply-to-all-texture-types"])

    readonly panelComponentType?: OperatorPanelComponentType = undefined
    readonly canvasToolbox?: OperatorCanvasToolboxType = undefined

    readonly type = "operator-initial" as const

    constructor(callback: OperatorCallback) {
        super(callback, undefined)
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        throw new Error("Not supported")
    }

    // OperatorBase
    async queueImageOps(_cmdQueue: ImageOpCommandQueue, input: OperatorInput, _hints: OperatorProcessingHints): Promise<OperatorOutput> {
        return {resultImage: input}
    }
}
