import {NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {TypeDescriptors} from "../type-descriptors"

const TD = TypeDescriptors

const structDescriptor = {
    __output_value: TD.outlet(TD.Identity<any>()),
}

export class Struct implements NodeClassImpl<typeof structDescriptor, typeof Struct> {
    static descriptor = structDescriptor
    static uniqueName = "Struct"
    __output_value!: Outlet<any>
    $inletKeys?: string[]
    $constKeys?: string[]

    run() {
        const data: any = {}
        if (this.$inletKeys) {
            // don't emit value if any of the struct fields are still undefined
            let hasNotReady = false
            for (const key of this.$inletKeys) {
                const value = (this as any)[key]
                if (value === NotReady) {
                    hasNotReady = true
                }
                data[key] = value
            }
            if (hasNotReady) {
                this.__output_value.emitIfChanged(NotReady)
                return
            }
        }
        if (this.$constKeys) {
            for (const key of this.$constKeys) {
                const value = (this as any)[key]
                data[key] = value
            }
        }
        this.__output_value.emitIfChanged(data)
    }
}
