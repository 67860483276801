import {Size2Like} from "@src/math/size2"

export type Vector2Like = {x: number; y: number}

export class Vector2 {
    public x: number
    public y: number

    constructor()
    constructor(x: number, y: number)
    constructor(value: number)

    constructor(x?: number, y?: number) {
        this.x = x ?? 0
        this.y = y ?? x ?? 0
    }

    static zero = new Vector2(0, 0)
    static one = new Vector2(1, 1)

    set(x: number, y: number) {
        this.x = x
        this.y = y
        return this
    }

    setFromVector2Like(v: Vector2Like) {
        this.x = v.x
        this.y = v.y
        return this
    }

    toArray(): [number, number] {
        return [this.x, this.y]
    }

    static fromArray(a: number[] | readonly [number, number]) {
        return new this(a[0], a[1])
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    static fromVector2Like(v: Vector2Like) {
        return new this(v.x, v.y)
    }

    static fromSize2Like(v: Size2Like) {
        return new this(v.width, v.height)
    }

    equals(other: Vector2Like) {
        return this.x == other.x && this.y == other.y
    }

    clone(): Vector2 {
        return new Vector2(this.x, this.y)
    }

    static min(a: Vector2Like, b: Vector2Like): Vector2 {
        return new Vector2(Math.min(a.x, b.x), Math.min(a.y, b.y))
    }

    minInPlace(v: Vector2Like): Vector2 {
        this.x = Math.min(this.x, v.x)
        this.y = Math.min(this.y, v.y)
        return this
    }

    static max(a: Vector2Like, b: Vector2Like): Vector2 {
        return new Vector2(Math.max(a.x, b.x), Math.max(a.y, b.y))
    }

    maxInPlace(v: Vector2Like): Vector2 {
        this.x = Math.max(this.x, v.x)
        this.y = Math.max(this.y, v.y)
        return this
    }

    static dot(a: Vector2Like, b: Vector2Like): number {
        return a.x * b.x + a.y * b.y
    }

    dot(v: Vector2): number {
        return Vector2.dot(this, v)
    }

    static cross(a: Vector2Like, b: Vector2Like): number {
        return a.x * b.y - a.y * b.x
    }

    cross(v: Vector2): number {
        return Vector2.cross(this, v)
    }

    static norm(v: Vector2Like): number {
        return Math.sqrt(Vector2.dot(v, v))
    }

    norm(): number {
        return Vector2.norm(this)
    }

    static normalize(v: Vector2Like): Vector2 {
        return new Vector2(v.x, v.y).mulInPlace(1 / Vector2.norm(v))
    }

    normalized(): Vector2 {
        return Vector2.normalize(this)
    }

    static distanceSquared(a: Vector2Like, b: Vector2Like): number {
        const dx = a.x - b.x
        const dy = a.y - b.y
        return dx * dx + dy * dy
    }

    static distance(a: Vector2Like, b: Vector2Like): number {
        return Math.sqrt(Vector2.distanceSquared(a, b))
    }

    distance(v: Vector2Like): number {
        return Vector2.distance(this, v)
    }

    static add(a: Vector2Like, b: Vector2Like): Vector2 {
        return new Vector2(a.x + b.x, a.y + b.y)
    }

    add(b: Vector2Like | number): Vector2 {
        return this.clone().addInPlace(b)
    }

    addInPlace(b: Vector2Like | number): Vector2 {
        if (typeof b == "number") {
            this.x += b
            this.y += b
            return this
        } else {
            this.x += b.x
            this.y += b.y
            return this
        }
    }

    static sub(a: Vector2Like, b: Vector2Like): Vector2 {
        return new Vector2(a.x - b.x, a.y - b.y)
    }

    sub(b: Vector2Like | number): Vector2 {
        return this.clone().subInPlace(b)
    }

    subInPlace(b: Vector2Like | number): Vector2 {
        if (typeof b == "number") {
            this.x -= b
            this.y -= b
            return this
        } else {
            this.x -= b.x
            this.y -= b.y
            return this
        }
    }

    static mul(a: Vector2Like, b: Vector2Like | number): Vector2 {
        if (typeof b == "number") {
            return new Vector2(a.x * b, a.y * b)
        } else {
            return new Vector2(a.x * b.x, a.y * b.y)
        }
    }

    mul(s: Vector2Like | number): Vector2 {
        return Vector2.mul(this, s)
    }

    mulInPlace(s: Vector2Like | number): Vector2 {
        if (typeof s == "number") {
            this.x *= s
            this.y *= s
            return this
        } else {
            this.x *= s.x
            this.y *= s.y
            return this
        }
    }

    static div(a: Vector2Like, b: Vector2Like | number): Vector2 {
        if (typeof b == "number") {
            return new Vector2(a.x / b, a.y / b)
        } else {
            return new Vector2(a.x / b.x, a.y / b.y)
        }
    }

    div(s: Vector2Like | number): Vector2 {
        return Vector2.div(this, s)
    }

    divInPlace(s: Vector2Like | number): Vector2 {
        if (typeof s == "number") {
            this.x /= s
            this.y /= s
            return this
        } else {
            this.x /= s.x
            this.y /= s.y
            return this
        }
    }

    negate(): Vector2 {
        return new Vector2(-this.x, -this.y)
    }

    negateInPlace(): Vector2 {
        this.x = -this.x
        this.y = -this.y
        return this
    }

    static perp(a: Vector2Like): Vector2 {
        return new Vector2(-a.y, a.x)
    }

    perp(): Vector2 {
        return Vector2.perp(this)
    }

    perpInPlace(): Vector2 {
        const x = this.x
        this.x = -this.y
        this.y = x
        return this
    }

    static lerp(a: Vector2Like, b: Vector2Like, t: number): Vector2 {
        return new Vector2(a.x + (b.x - a.x) * t, a.y + (b.y - a.y) * t)
    }

    lerp(b: Vector2Like, t: number): Vector2 {
        return Vector2.lerp(this, b, t)
    }

    floor(): Vector2 {
        return this.clone().floorInPlace()
    }

    floorInPlace(): Vector2 {
        this.x = Math.floor(this.x)
        this.y = Math.floor(this.y)
        return this
    }

    ceil(): Vector2 {
        return this.clone().ceilInPlace()
    }

    ceilInPlace(): Vector2 {
        this.x = Math.ceil(this.x)
        this.y = Math.ceil(this.y)
        return this
    }

    round(): Vector2 {
        return this.clone().roundInPlace()
    }

    roundInPlace(): Vector2 {
        this.x = Math.round(this.x)
        this.y = Math.round(this.y)
        return this
    }
}
