import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {AutoTilingPanelComponent} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/panel/auto-tiling-panel.component"
import {assertNever, deepCopy} from "@cm/lib/utils/utils"
import {
    Operator,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {TilingAutoGenerateToolbox} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/toolbox/auto-tiling-toolbox"
import * as Tiling from "app/textures/texture-editor/operator-stack/operators/auto-tiling/service/auto-tiling.service"
import {TextureType} from "@api"
import {takeUntil} from "rxjs"
import {getSimpleJobState, SimpleJobState} from "@app/textures/utils/simple-job-state"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {ImageRef, ManagedImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export enum AutoTilingState {
    Setup = "setup",
    InProgress = "in-progress",
    Complete = "complete",
    Failed = "failed",
}

export enum AutoTilingViewMode {
    Setup = "setup",
    Result = "result",
}

export class OperatorAutoTiling extends OperatorBase<TextureEditNodes.OperatorAutoTiling> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["no-clone", "no-disable", "apply-to-all-texture-types"])

    readonly panelComponentType: OperatorPanelComponentType = AutoTilingPanelComponent

    readonly type = "operator-auto-tiling" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorAutoTiling | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-auto-tiling",
                enabled: true,
                mode: "tiling",
                area: undefined,
                hints: [],
                params: {
                    processingMode: "cloud",
                    blendingBorder: 100,
                    maxBlendingRadius: 256,
                    patternType: "large",
                    featureWeighting: "normal_diffuse",
                    gradientCorrection: true,
                    filterMode: "normal",
                    filterRadius: 4,
                    alignIndividualMaps: false,
                    edgeAlignmentWindowSize: 0,
                    edgeAlignmentSmoothingIterations: 15,
                },
                jobTaskId: undefined,
                outputMaps: undefined,
            },
        )

        this.tilingService = callback.injector.get(Tiling.AutoTilingService)

        if (this.callback.textureEditorData.textureSetRevisionSpecific.textureSetRevision.editsJson?.schema <= 3 && this.node.hints.length > 0) {
            this._autoTilingState = AutoTilingState.Complete // this is an old pre-texture-set-revision auto-tiling which by definition is complete
        } else if (this.node.jobTaskId === undefined && this.node.outputMaps === undefined) {
            this._autoTilingState = AutoTilingState.Setup // there is no auto-tiling job task given which means we are in the setup phase
        } else {
            if (this.callback.textureEditorData.textureSetRevisionSpecific.textureSetRevision.editsProcessingJob?.id !== this.node.jobTaskId) {
                if (!this.node.outputMaps) {
                    throw Error("INTERNAL ERROR: Output maps must be set if the job task id is not the current job task id")
                }
                this._autoTilingState = AutoTilingState.Complete // must be complete as the current job task is not the auto-tiling job task
            } else {
                // the current job task is the auto-tiling job task
                const jobState = this.callback.textureEditorData.textureSetRevisionSpecific.textureSetRevision.editsProcessingJob?.state
                if (!jobState) {
                    this._autoTilingState = AutoTilingState.Setup
                } else {
                    const simpleJobState = getSimpleJobState(jobState)
                    switch (simpleJobState) {
                        case SimpleJobState.Failed:
                        case SimpleJobState.Cancelled:
                            this._autoTilingState = AutoTilingState.Failed
                            break
                        case SimpleJobState.InProgress:
                            this._autoTilingState = AutoTilingState.InProgress
                            break
                        case SimpleJobState.Complete:
                            this._autoTilingState = AutoTilingState.Complete
                            break
                        default:
                            assertNever(simpleJobState)
                    }
                }
            }
        }

        this._viewMode = this._autoTilingState === AutoTilingState.Complete ? AutoTilingViewMode.Result : AutoTilingViewMode.Setup
        this.addTilingHints()
        this._canvasToolbox = new TilingAutoGenerateToolbox(this)

        this.callback.selectedOperatorChanged.pipe(takeUntil(this.unsubscribe)).subscribe((operator) => {
            if (operator === this) {
                // make sure we don't display the setup view if the setup cannot be changed anymore
                if (this.viewMode === AutoTilingViewMode.Setup && !this.canChangeAutoTilingSetup) {
                    this.viewMode = AutoTilingViewMode.Result
                }
                this.updateToolbox()
            }
        })
        this.updateToolbox()
    }

    // OperatorBase
    get canvasToolbox(): TilingAutoGenerateToolbox | null {
        return this.viewMode === AutoTilingViewMode.Setup ? this._canvasToolbox : null
    }

    // OperatorBase
    override dispose(): void {
        this._resultDataObjectImageRef?.imageRef.release()
        this._resultDataObjectImageRef = undefined
        super.dispose()
        this._canvasToolbox.remove()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        throw Error("Auto tiling operator cannot be cloned")
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        if (cmdQueue.mode === "preview" && this.selected && this.viewMode === AutoTilingViewMode.Setup) {
            return {resultImage: input, options: {stopEvaluation: true}}
        }
        cmdQueue.beginScope(this.type)
        switch (this.autoTilingState) {
            case AutoTilingState.Setup:
            case AutoTilingState.Failed:
            case AutoTilingState.InProgress:
                cmdQueue.endScope(this.type)
                return {resultImage: input}
            case AutoTilingState.Complete: {
                const resultImage = this.callback.prepareSourceImage(cmdQueue, hints.textureType, await this.getResultImage(cmdQueue, hints.textureType))
                cmdQueue.endScope(this.type)
                return {resultImage}
            }
            default:
                assertNever(this.autoTilingState)
        }
    }

    private async getResultImage(cmdQueue: ImageOpCommandQueue, textureType: TextureType): Promise<ImageRef> {
        if (this.autoTilingState !== AutoTilingState.Complete) {
            throw Error("Auto tiling must be complete to get result image")
        }

        const getDataObjectId = async (textureType: TextureType) => {
            let dataObjectId: string | undefined
            if (this.node.outputMaps) {
                dataObjectId = this.node.outputMaps.dataObjectByTextureType[textureType]?.dataObjectId
            } else {
                dataObjectId = this.callback.textureEditorData.textureSetRevisionSpecific.textureSetRevision.mapAssignments.find(
                    (assignment) => assignment.textureType === textureType,
                )?.dataObject.id
            }
            if (!dataObjectId) {
                throw Error(`No data object for texture type: ${textureType}`)
            }
            return dataObjectId
        }

        if (cmdQueue.mode === "preview") {
            if (!this._resultDataObjectImageRef || this._resultDataObjectImageRef.textureType !== textureType) {
                this._resultDataObjectImageRef?.imageRef.release()
                this._resultDataObjectImageRef = undefined
                const dataObjectId = await getDataObjectId(textureType)
                this._resultDataObjectImageRef = {
                    textureType,
                    imageRef: await cmdQueue.context.createDataObjectImageRef(dataObjectId),
                }
            }
            return this._resultDataObjectImageRef.imageRef.ref
        } else if (cmdQueue.mode === "final") {
            const dataObjectId = await getDataObjectId(textureType)
            return (await cmdQueue.context.createDataObjectImageRef(dataObjectId)).ref
        } else {
            throw Error(`Unexpected mode: ${cmdQueue.mode}`)
        }
    }

    private addTilingHints(): void {
        const dataObject = this.callback.textureEditorData?.textureTypeSpecific?.sourceDataObject
        if (!dataObject) {
            throw Error("Data-object not set")
        }
        if (!dataObject.width || !dataObject.height) {
            throw Error("Data-object width/height not set")
        }
        const radius = (dataObject.width + dataObject.height) / 100
        if (this.node.hints.length === 0) {
            const markerWidth: number = 0.6 * dataObject.width
            const x1: number = Math.round(dataObject.width / 2 - markerWidth / 2)
            const x2: number = Math.round(x1 + markerWidth)
            const y: number = Math.round(dataObject.height * 0.15)
            this.node.hints.push({x1, y1: y, x2, y2: y, radius})
        }
        if (this.node.hints.length === 1) {
            const markerHeight: number = 0.6 * dataObject.height
            const y1: number = Math.round(dataObject.height / 2 - markerHeight / 2)
            const y2: number = Math.round(y1 + markerHeight)
            const x: number = Math.round(dataObject.width * 0.15)
            this.node.hints.push({x1: x, y1, x2: x, y2, radius})
        }
    }

    private updateToolbox() {
        this.canvasToolbox?.setItemsEnabled(this.canChangeAutoTilingSetup)
        this.callback.tilingCanvas.toolbox = this.canvasToolbox
    }

    get canChangeAutoTilingSetup(): boolean {
        return this.callback.operators.filter((operator) => !operator.flags.has("is-internal")).length === 1 // only allow changing the setup if no further operators have been applied already
    }

    get autoTilingState(): AutoTilingState {
        return this._autoTilingState
    }

    get viewMode(): AutoTilingViewMode {
        return this._viewMode
    }

    set viewMode(viewMode: AutoTilingViewMode) {
        this._viewMode = viewMode
        this.updateToolbox()
        this.requestEval()
    }

    override async save(processingJobId: string | undefined): Promise<TextureEditNodes.Operator> {
        this.resetEdited()

        if (processingJobId) {
            // check if we're processing the auto-tiling job
            const processingIsAutoTilingJob = this.autoTilingState !== AutoTilingState.Complete
            if (processingIsAutoTilingJob) {
                this.node.jobTaskId = processingJobId
            } else {
                // if we're about to process further edits and havn't stored the output maps yet, store them now
                if (!this.node.outputMaps) {
                    const textureSetRevision = this.callback.textureEditorData.textureSetRevisionSpecific.textureSetRevision
                    this.node.outputMaps = {
                        type: "texture-set",
                        dataObjectByTextureType: {},
                    }
                    textureSetRevision.mapAssignments.forEach((mapAssignment) => {
                        this.node.outputMaps!.dataObjectByTextureType[mapAssignment.textureType] = {
                            type: "data-object-reference",
                            dataObjectId: mapAssignment.dataObject.id,
                        }
                    })
                }
            }
        }

        if (this._canvasToolbox.tilingCutoutActive) {
            return this.node
        } else {
            // if tiling cutout is disabled, don't write it to the final node
            const node = deepCopy(this.node)
            node.area = undefined
            return node
        }
    }

    get isTilingCutoutActive(): boolean {
        return this._canvasToolbox.tilingCutoutActive
    }

    get cutoutArea() {
        return this.node.area
    }

    set cutoutArea(area: Tiling.Area | undefined) {
        this.node.area = area
        this.invalidateTilingResult()
    }

    get tilingHints() {
        return this.node.hints
    }

    set tilingHints(hints: Tiling.Hint[]) {
        this.node.hints = hints
        this.invalidateTilingResult()
    }

    get tilingParams() {
        return this.node.params
    }

    toggleCutoutActive() {
        this.invalidateTilingResult()
        return this._canvasToolbox.toggleCutoutActive()
    }

    // addTilingHint() {
    //     return this._canvasToolbox.onAddTilingHintClick()
    // }

    // clearTilingHints() {
    //     return this._canvasToolbox.clearTilingHints()
    // }

    invalidateTilingResult() {
        this.markEdited()
        this.node.outputMaps = undefined // reset output maps
        this._autoTilingState = AutoTilingState.Setup // when we made edits to the auto-tiling operator, we need to return to the setup state
    }

    // startTilingDetection() {
    //     return this._canvasToolbox.startTiling("detection", undefined, this.callback.textureEditorData.textureSetRevisionSpecific.textureSet.legacyId)
    // }

    // startTilingAlignment() {
    //     return this._canvasToolbox.startTiling("alignment", undefined, this.callback.textureEditorData.textureSetRevisionSpecific.textureSet.legacyId)
    // }

    async createTilingTaskFromParams(organizationLegacyId: number, sourceMapDataObjectIds: Map<TextureType, string>, pxPerCm: number) {
        const graph = await this.tilingService.buildTilingGraph(
            organizationLegacyId,
            "tiling",
            this.node.params,
            this._canvasToolbox.getTilingArea(),
            this.node.hints,
            pxPerCm,
            sourceMapDataObjectIds,
        )
        if (this.node.params.processingMode !== "cloud") {
            throw Error("Tiling task can only be created for cloud tiling.")
        }
        return this.tilingService.createTilingTask(graph, this.callback.customerLegacyId)
    }

    private tilingService: Tiling.AutoTilingService
    private _canvasToolbox: TilingAutoGenerateToolbox
    private _autoTilingState: AutoTilingState
    private _viewMode: AutoTilingViewMode
    private _resultDataObjectImageRef?: {
        textureType: TextureType
        imageRef: ManagedImageRef
    }
}
