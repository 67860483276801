import {EventEmitter} from "@angular/core"
import {HalImage} from "@common/models/hal/hal-image"
import {DrawableImageCache} from "app/textures/texture-editor/operator-stack/image-op-system/detail/drawable-image-cache"
import {BrushToolboxItem} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {takeUntil} from "rxjs"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {CanvasBaseToolboxItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item"

export class DrawableImageBrushToolboxItem extends BrushToolboxItem {
    readonly drawableImageUpdated = new EventEmitter<void>()

    constructor(
        parent: CanvasBaseToolboxItem,
        private drawableImageCache: DrawableImageCache,
    ) {
        super(parent)
        this.drawableImageCache.imageCreated.pipe(takeUntil(this.unsubscribe)).subscribe((event) => this.onImageCreated(event.ref, event.isEmpty))
        this.brushStrokeUpdated.subscribe(() => this.onBrushStrokeUpdated())
        this.brushStrokeCompleted.subscribe(() => this.onBrushStrokeCompleted())
    }

    async setDrawableImageRef(ref: DrawableImageRef | undefined, setStrokeImage: boolean): Promise<void> {
        if (this._drawableImage === ref) {
            return
        }
        this._drawableImage = ref
        if (this._drawableImage) {
            if (this._drawableImage.maskReference.dataObjectId !== "") {
                this.drawableImageCache.setDataObjectId(this._drawableImage, this._drawableImage.maskReference.dataObjectId)
            }
            // the mask may not exist yet, but get created later
            const hasMaskImage = this.drawableImageCache.hasDrawableImage(this._drawableImage)
            if (hasMaskImage) {
                const maskImage = await this.drawableImageCache.getWebGl2ImageByRef(this._drawableImage)
                if (setStrokeImage) {
                    await super.setStrokeImage(maskImage.halImage)
                } else {
                    await super.setStrokeImageDescriptor(maskImage.descriptor)
                }
                this.drawableImageUpdated.emit()
            }
        }
    }

    override async setStrokeImage(value: HalImage): Promise<void> {
        await super.setStrokeImage(value)
        await this.updateDrawableImage()
    }

    override async getStrokeImage(): Promise<HalImage> {
        if (!this._drawableImage) {
            throw Error("Drawable image is not set")
        }
        await this.updateDrawableImage()
        return await super.getStrokeImage()
    }

    private async updateDrawableImage() {
        if (this._drawableImage && this.drawableImageCache.hasDrawableImage(this._drawableImage)) {
            const strokeImage = await super.getStrokeImage()
            const strokeImageBoundingBox = this.getCurrentStrokeImageBoundingBox()
            const maskImage = await this.drawableImageCache.getHalPaintableImageByRef(this._drawableImage)
            await this.halBlitter.paint(maskImage, strokeImage, {
                sourceRegion: strokeImageBoundingBox,
                targetOffset: strokeImageBoundingBox.min,
            })
            this.drawableImageCache.markMaskReferenceDirty(this._drawableImage)
        }
    }

    private async onImageCreated(ref: DrawableImageRef, isEmpty: boolean) {
        if (ref === this._drawableImage) {
            // the image has been recreated, so we need to update the stroke image
            const maskImage = await this.drawableImageCache.getHalPaintableImageByRef(this._drawableImage)
            await super.setStrokeImage(maskImage)
            if (isEmpty) {
                // if new image is empty; reset the dirty region
                this.resetDirtyRegion()
            }
            // flush out remaining strokes
            await super.updateStrokeImage()
            if (this.isDrawing) {
                this.brushStrokeUpdated.emit()
            }
        }
    }

    private async onBrushStrokeUpdated() {
        await this.updateDrawableImage()
        this.drawableImageUpdated.emit()
    }

    private onBrushStrokeCompleted() {
        this.drawableImageUpdated.emit()
    }

    private _drawableImage: DrawableImageRef | undefined
}
