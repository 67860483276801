import {DestroyRef, Injectable, OnDestroy, computed, effect, inject, signal, untracked} from "@angular/core"
import {SceneManagerService, SceneNodePart} from "@template-editor/services/scene-manager.service"
import * as THREE from "three"
import {ObjectId, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {ThreeObject, ThreeObjectPart} from "@template-editor/helpers/three-object"
import {ThreeGrid} from "@template-editor/helpers/three-grid"
import {ThreeMesh} from "@app/template-editor/helpers/three-mesh"
import {ThreeMaterialManagerService} from "@template-editor/services/three-material-manager.service"
import {ThreeEnvironment} from "@template-editor/helpers/three-environment"
import {ThreeAreaLight} from "@template-editor/helpers/three-area-light"
import {Subject, debounceTime, defer, from, range, switchMap, concatMap, timer} from "rxjs"
import {ThreeProgressiveUVShadowMap} from "@template-editor/helpers/three-progressive-uv-shadow-map"
import {ThreeCamera} from "@template-editor/helpers/three-camera"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {ThreeShadowCatcher} from "@cm/lib/materials/three-shadow-catcher"
import {objectDifferent} from "../helpers/change-detection"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"
import {threeValueNode} from "@cm/lib/materials/three-utils"
import {ThreeRectangle} from "../helpers/three-rectangle"
import {ThreePoint} from "../helpers/three-point"
import {ThreeAnnotation} from "../helpers/three-annotation"
import {ThreeLightPortal} from "../helpers/three-light-portal"
import {Matrix4, Vector3} from "@cm/lib/math"
import {ThreePreloadMaterial} from "../helpers/three-preload-material"
import {ThreeWireframeMesh} from "../helpers/three-wireframe-mesh"
import {ThreeMeshCurveControl} from "../helpers/three-mesh-curve-control"
import {ThreeSeam} from "../helpers/three-seam"

export const DEFAULT_NUM_SHADOW_MAP_OUTER_UPDATE_ITERATIONS = 75
export const DEFAULT_NUM_SHADOW_MAP_INNER_UPDATE_ITERATIONS = 4
export const DEFAULT_NUM_SHADOW_MAP_OUTER_SMOOTH_ITERATIONS = 0
export const DEFAULT_NUM_SHADOW_MAP_INNER_SMOOTH_ITERATIONS = 1
export const DEFAULT_SHADOW_MAP_RESOLUTION = 1024

export type DisplayMode = "configurator" | "editor"

const defaultGrid = {
    type: "Grid",
    id: "grid",
    size: 1000,
    divisions: 10,
    color1: [0.26, 0.26, 0.26],
    color2: [0.73, 0.73, 0.73],
    transform: Matrix4.identity(),
} as SceneNodes.Grid

const defaultEnvironment = {
    id: "defaultEnvironment",
    type: "Environment",
    envData: {
        type: "url",
        url: "assets/images/template-editor-envmap.exr",
        originalFileExtension: "exr",
    },
    intensity: 1,
    rotation: new Vector3(0, 0, 0),
    mirror: false,
    priority: 9999,
} as SceneNodes.Environment

@Injectable()
export class ThreeSceneManagerService implements OnDestroy {
    $ambientLight = signal(true)
    $showGrid = signal(true)
    $displayMode = signal<DisplayMode>("editor")
    $showProgressiveLightMapDebug = signal(false)

    sceneManagerService = inject(SceneManagerService)
    materialManagerService = inject(ThreeMaterialManagerService)
    private destroyRef = inject(DestroyRef)

    protected threeFactory: {
        [key in SceneNodes.SceneNode["type"]]?: {
            new (threeSceneManagerService: ThreeSceneManagerService, onAsyncUpdate: () => void): ThreeObject
        }
    } = {
        Grid: ThreeGrid,
        Mesh: ThreeMesh,
        MeshCurveControl: ThreeMeshCurveControl,
        WireframeMesh: ThreeWireframeMesh,
        Environment: ThreeEnvironment,
        AreaLight: ThreeAreaLight,
        Camera: ThreeCamera,
        Rectangle: ThreeRectangle,
        Point: ThreePoint,
        Annotation: ThreeAnnotation,
        LightPortal: ThreeLightPortal,
        PreloadMaterial: ThreePreloadMaterial,
        Seam: ThreeSeam,
    }

    private objectCache = new Map<ObjectId, ThreeObject>()
    private baseScene = new THREE.Scene()

    getObjectsFromSceneNodes(sceneNode: SceneNodePart[]): ThreeObjectPart[] {
        const allThreeObjects = Array.from(this.objectCache.values())

        return sceneNode
            .map((sceneNodePart) => {
                return allThreeObjects
                    .filter((threeObject) => {
                        const objectSceneNode = threeObject.getSceneNode()
                        return sceneNodePart.sceneNode === objectSceneNode
                    })
                    .map((threeObject) => ({
                        threeObject,
                        part: sceneNodePart.part,
                    }))
            })
            .flat()
    }

    $selectedObjects = computed(() => this.getObjectsFromSceneNodes(this.sceneManagerService.$selectedSceneNodes()))
    $hoveredObjects = computed(() => this.getObjectsFromSceneNodes(this.sceneManagerService.$hoveredSceneNodes()))
    private renderer: THREE.WebGLRenderer | undefined

    private requestedRedraw = new Subject<void>()
    requestedRedraw$ = this.requestedRedraw.asObservable()

    requestedResize = new Subject<void>()
    requestedResize$ = this.requestedResize.asObservable()

    $cursorPosition = signal<Vector3 | undefined>(undefined)
    private $cursorObject = computed(() => {
        const cursorPosition = this.$cursorPosition()
        if (!cursorPosition) return undefined

        const transform = new Matrix4()
        transform.setTranslation(cursorPosition)
        return {id: "cursorObject", type: "Point", size: 20, transform} as SceneNodes.Point
    })

    private shadowMapMeshes = new Set<THREE.Mesh<THREE.BufferGeometry, THREE.Material>>()
    private shadowMapMeshesChanged = new Subject<void>()

    private $sceneOptions = computed(() => this.sceneManagerService.$scene().find(SceneNodes.SceneOptions.is))
    private $shadowCatcherFalloff = computed(() => this.$sceneOptions()?.shadowCatcherFalloff, {
        equal: (a, b) => (a === undefined ? a === b : !objectDifferent(a, b, undefined)),
    })
    private $environmentMapMode = computed(() => this.$sceneOptions()?.environmentMapMode)
    private $materialModifier = computed(() => {
        const shadowCatcherFalloff = this.$shadowCatcherFalloff()
        const environmentMapMode = this.$environmentMapMode()
        const reviewMode = this.sceneManagerService.$reviewMode()

        return (material: THREE.Material) => {
            if (material instanceof ThreeShadowCatcher) {
                if (shadowCatcherFalloff) material.setFilterParameters({...shadowCatcherFalloff, bias: 0.0})
                else material.resetFilterParameters()
            }

            const specularOnlyMaterialModifier = (material: THREE.Material, remove: boolean) => {
                if (!(material instanceof THREENodes.MeshStandardNodeMaterial)) return false
                if (material.roughnessNode) {
                    if (remove) {
                        //TODO THREE UPGRADE
                        //@ts-ignore
                        material.envMapIntensityNode = null
                    } else {
                        const zero = threeValueNode(0)
                        const one = threeValueNode(1)

                        //TODO THREE UPGRADE
                        //@ts-ignore
                        material.envMapIntensityNode = THREENodes.pow(
                            THREENodes.sub(one, THREENodes.clamp(material.roughnessNode, zero, one)),
                            threeValueNode(4),
                        )
                    }

                    return true
                }

                return false
            }

            if (reviewMode === "wireframe") {
                material.polygonOffset = true
                material.polygonOffsetFactor = 5
            } else {
                material.polygonOffset = false
                material.polygonOffsetFactor = 0
            }

            if (specularOnlyMaterialModifier(material, environmentMapMode !== "specularOnly")) material.needsUpdate = true
        }
    })
    private $renderLights = computed(() => this.$sceneOptions()?.enableRealtimeLights !== false)
    private $realtimeShadowMapOptions = computed(() => this.$sceneOptions()?.realtimeShadowMapOptions)
    private $shadowMapResolution = computed(() => this.$realtimeShadowMapOptions()?.resolution ?? DEFAULT_SHADOW_MAP_RESOLUTION)
    private $shadowMapOuterUpdateIterations = computed(
        () => this.$realtimeShadowMapOptions()?.outerUpdateIterations ?? DEFAULT_NUM_SHADOW_MAP_OUTER_UPDATE_ITERATIONS,
    )
    private $shadowMapInnerUpdateIterations = computed(
        () => this.$realtimeShadowMapOptions()?.innerUpdateIterations ?? DEFAULT_NUM_SHADOW_MAP_INNER_UPDATE_ITERATIONS,
    )
    private $shadowMapSmoothOuterIterations = computed(
        () => this.$realtimeShadowMapOptions()?.outerSmoothIterations ?? DEFAULT_NUM_SHADOW_MAP_OUTER_SMOOTH_ITERATIONS,
    )
    private $shadowMapSmoothInnerIterations = computed(
        () => this.$realtimeShadowMapOptions()?.innerSmoothIterations ?? DEFAULT_NUM_SHADOW_MAP_INNER_SMOOTH_ITERATIONS,
    )

    private $staticDisplayList = computed<SceneNodes.SceneNode[]>(() => [
        ...(this.$showGrid() ? [defaultGrid] : []),
        ...(() => {
            const cursorObject = this.$cursorObject()
            return cursorObject ? [cursorObject] : []
        })(),
    ])
    private $renderScene = computed(() => {
        const getScene = () => {
            const scene = this.sceneManagerService.$scene()
            const reviewMode = this.sceneManagerService.$reviewMode()
            const channel = reviewMode === "wireframe" ? this.sceneManagerService.$reviewFocus() : this.sceneManagerService.$reviewedUvChannel()

            const sceneAdaptedForLights =
                !this.$renderLights() || reviewMode !== undefined ? scene.map((x) => (SceneNodes.AreaLight.is(x) ? {...x, on: false} : x)) : scene
            const sceneAdaptedForWireframes =
                reviewMode !== undefined
                    ? [
                          ...sceneAdaptedForLights,
                          ...sceneAdaptedForLights.filter(SceneNodes.Mesh.is).map(({meshData, transform, id, topLevelObjectId}) => {
                              const ret: SceneNodes.WireframeMesh = {
                                  type: "WireframeMesh",
                                  meshData,
                                  transform,
                                  id: id + "_wireframe",
                                  topLevelObjectId: topLevelObjectId === undefined ? undefined : topLevelObjectId + "_wireframe",
                                  channel,
                              }
                              return ret
                          }),
                      ]
                    : sceneAdaptedForLights

            return sceneAdaptedForWireframes
        }

        const scene = [...getScene(), ...this.$staticDisplayList()]

        const hasIllumination = () => scene.some((x) => (SceneNodes.AreaLight.is(x) && x.on) || SceneNodes.Environment.is(x))

        const envMapNodes = scene.filter(SceneNodes.Environment.is)
        const otherNodes = scene.filter((item) => !SceneNodes.Environment.is(item))

        const selectedEnvironment =
            envMapNodes.reduce<SceneNodes.Environment | undefined>((acc, item) => {
                if (!acc) return item
                if (item.priority <= acc.priority) return item
                return acc
            }, undefined) ?? (this.$ambientLight() && !hasIllumination() ? defaultEnvironment : undefined)

        return selectedEnvironment ? [selectedEnvironment, ...otherNodes] : otherNodes
    })
    private $hasAreaLights = computed(() => this.$renderScene().some((x) => SceneNodes.AreaLight.is(x) && x.on))
    $hasIllumination = computed(() => this.$hasAreaLights() || this.sceneManagerService.$scene().some((x) => SceneNodes.Environment.is(x)))
    private $hasMeshes = computed(() => this.$renderScene().some(SceneNodes.Mesh.is))

    private $requiresProgressiveLightMap = computed(() => this.$hasAreaLights() && this.$hasMeshes() && this.$sceneOptions()?.enableRealtimeShadows !== false)
    private progressiveLightMap: ThreeProgressiveUVShadowMap | undefined
    private progressiveLightMapUpdate$ = new Subject<void>()

    constructor() {
        //this.materialManagerService.requestedRedraw$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.requestRedraw())

        effect(() => {
            const selectedSceneNodes = this.sceneManagerService.$selectedSceneNodes().map((sceneNodePart) => sceneNodePart.sceneNode)

            this.objectCache.forEach((threeObject) => {
                const objectSceneNode = threeObject.getSceneNode()
                threeObject.setSelected(selectedSceneNodes.includes(objectSceneNode))
            })
            this.requestRedraw()
        })

        effect(() => {
            const displayMode = this.$displayMode()
            this.objectCache.forEach((threeObject) => {
                threeObject.onDisplayModeChange(displayMode)
            })
            this.requestRedraw()
        })

        effect(() => {
            this.updateScene()
        })

        effect(() => {
            const showProgressiveLightMapDebug = untracked(this.$showProgressiveLightMapDebug)

            if (this.$requiresProgressiveLightMap()) {
                if (!this.progressiveLightMap) {
                    this.progressiveLightMap = new ThreeProgressiveUVShadowMap(this.getRenderer(), this.$shadowMapResolution())

                    if (showProgressiveLightMapDebug) this.baseScene.add(this.progressiveLightMap.getDebugObject())
                    this.progressiveLightMap.attachedUVShadowMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mesh) => {
                        if (!this.progressiveLightMap) throw new Error("Progressive light map not set")
                        const {material} = mesh

                        const newMaterial = this.materialManagerService.acquireVariation(
                            material,
                            (material) => {
                                //@ts-ignore
                                return material.uvShadowMap !== null
                            },
                            (newMaterial) => {},
                        )
                        //@ts-ignore
                        newMaterial.uvShadowMap = this.progressiveLightMap.getUVShadowMap()
                        this.materialManagerService.releaseMaterial(material)
                        mesh.material = newMaterial
                    })
                    this.progressiveLightMap.detachedUVShadowMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mesh) => {
                        const {material} = mesh

                        const newMaterial = this.materialManagerService.acquireVariation(
                            material,
                            (material) => {
                                //@ts-ignore
                                return material.uvShadowMap === null
                            },
                            (newMaterial) => {
                                //@ts-ignore
                                newMaterial.uvShadowMap = null
                            },
                        )
                        this.materialManagerService.releaseMaterial(material)
                        mesh.material = newMaterial
                    })

                    this.shadowMapMeshesChanged.next()
                } else {
                    const resolution = this.$shadowMapResolution()
                    if (this.progressiveLightMap.getResolution() !== resolution) {
                        this.progressiveLightMap.setResolution(resolution)
                        this.shadowMapMeshesChanged.next()
                    }
                }
            } else {
                if (this.progressiveLightMap) {
                    if (showProgressiveLightMapDebug) this.baseScene.remove(this.progressiveLightMap.getDebugObject())
                    this.progressiveLightMap.dispose()
                    this.progressiveLightMap = undefined
                    this.requestRedraw()
                }
            }
        })

        effect(() => {
            this.$shadowMapOuterUpdateIterations()
            this.$shadowMapInnerUpdateIterations()
            this.$shadowMapSmoothOuterIterations()
            this.$shadowMapSmoothInnerIterations()
            this.requestShadowMapUpdate()
        })

        effect(() => {
            const showProgressiveLightMapDebug = this.$showProgressiveLightMapDebug()
            if (!this.progressiveLightMap) return

            if (showProgressiveLightMapDebug) this.baseScene.add(this.progressiveLightMap.getDebugObject())
            else this.baseScene.remove(this.progressiveLightMap.getDebugObject())
            this.requestRedraw()
        })

        effect(() => {
            this.materialManagerService.materialModifier = this.$materialModifier()
            this.materialManagerService.updateMaterials(this.materialManagerService.materialModifier)
            this.requestRedraw()
        })

        toObservable(this.$displayMode)
            .pipe(
                switchMap((displayMode) =>
                    this.shadowMapMeshesChanged
                        .asObservable()
                        .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(displayMode === "configurator" ? 10 : 1000)),
                ),
            )
            .subscribe(() => this.shadowMapMeshesSettled())

        const shadowUpdate = async (outerIteration: number) => {
            return new Promise<void>((resolve) => {
                if (!this.progressiveLightMap) resolve()

                requestAnimationFrame(() => {
                    if (this.progressiveLightMap) {
                        //if (iteration === 0) this.progressiveLightMap.reset()
                        const shadowMapComputeInnerIterations = this.$shadowMapInnerUpdateIterations()
                        const iteration = outerIteration * shadowMapComputeInnerIterations
                        this.progressiveLightMap.compute(this.baseScene, iteration, shadowMapComputeInnerIterations)

                        this.requestedRedraw.next()
                    }
                    resolve()
                })
            })
        }

        const shadowSmooth = async (outerIteration: number) => {
            return new Promise<void>((resolve) => {
                if (!this.progressiveLightMap) resolve()

                requestAnimationFrame(() => {
                    if (this.progressiveLightMap) {
                        this.progressiveLightMap.smoothResult(this.$shadowMapSmoothInnerIterations())

                        this.requestedRedraw.next()
                    }
                    resolve()
                })
            })
        }

        //If for 1000ms there are no other progressive light map update events, start accumulating shadow map updates
        this.progressiveLightMapUpdate$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(() =>
                    timer(this.$displayMode() === "configurator" ? 10 : 1000).pipe(
                        switchMap(() =>
                            range(this.$shadowMapOuterUpdateIterations()).pipe(concatMap((iteration) => defer(() => from(shadowUpdate(iteration))))),
                        ),
                    ),
                ),
                switchMap(() =>
                    timer(this.$displayMode() === "configurator" ? 10 : 1000).pipe(
                        switchMap(() =>
                            range(this.$shadowMapSmoothOuterIterations()).pipe(concatMap((iteration) => defer(() => from(shadowSmooth(iteration))))),
                        ),
                    ),
                ),
            )
            .subscribe()
    }

    init(canvas: HTMLCanvasElement) {
        if (this.renderer) throw new Error("Renderer already initialized")

        this.renderer = new THREE.WebGLRenderer({
            canvas,
            preserveDrawingBuffer: true,
            powerPreference: "high-performance",
            antialias: false,
            alpha: true,
        })

        const pixelRatio = window.devicePixelRatio
        this.renderer.setPixelRatio(pixelRatio)
        this.renderer.setClearColor(0x000000, 0)
    }

    resizeRenderer(width: number, height: number) {
        if (!this.renderer) throw new Error("Renderer not set, forgot to call init()?")
        this.renderer.setSize(width, height)
        this.requestedResize.next()
    }

    private updateScene() {
        const scene = this.$renderScene()

        const toDelete = new Set<ObjectId>()
        for (const [id] of this.objectCache) toDelete.add(id)

        const getThreeObject = (sceneNode: SceneNodes.SceneNode) => {
            const cachedThreeObject = this.objectCache.get(sceneNode.id)
            if (cachedThreeObject) return cachedThreeObject

            const Factory = this.threeFactory[sceneNode.type]
            if (!Factory) return undefined

            const threeObject = new Factory(this, () => this.requestRedraw())
            this.objectCache.set(sceneNode.id, threeObject)
            return threeObject
        }

        let needsUpdate = false
        let needsShadowMapUpdate = false
        for (const sceneNode of scene) {
            const threeObject = getThreeObject(sceneNode)
            if (threeObject) {
                if (threeObject.update(sceneNode)) {
                    if (threeObject instanceof ThreeMesh || threeObject instanceof ThreeAreaLight || threeObject instanceof ThreeSeam)
                        needsShadowMapUpdate = true
                    needsUpdate = true
                }

                const threeRenderObject = threeObject.getRenderObject()
                if (!threeRenderObject.parent) this.baseScene.add(threeRenderObject)
                toDelete.delete(sceneNode.id)
            }
        }

        for (const id of toDelete) {
            const threeObject = this.objectCache.get(id)
            if (threeObject) {
                this.baseScene.remove(threeObject.getRenderObject())
                this.objectCache.delete(id)
                threeObject.dispose(true)
                if (threeObject instanceof ThreeMesh || threeObject instanceof ThreeAreaLight || threeObject instanceof ThreeSeam) needsShadowMapUpdate = true
                needsUpdate = true
            }
        }

        if (needsUpdate) this.requestRedraw()
        if (needsShadowMapUpdate) this.progressiveLightMapUpdate$.next()
    }

    getRenderer() {
        if (!this.renderer) throw new Error("Renderer not set")
        return this.renderer
    }

    modifyBaseScene(modifier: (scene: THREE.Scene) => void) {
        modifier(this.baseScene)
    }

    getSceneReference() {
        return this.baseScene
    }

    ngOnDestroy() {
        if (this.progressiveLightMap) this.progressiveLightMap.dispose()

        for (const threeObject of this.objectCache.values()) threeObject.dispose(true)
        this.objectCache.clear()

        if (this.renderer) this.renderer.dispose()
    }

    async sync(waitForOptionalTasks: boolean): Promise<void> {
        await this.sceneManagerService.sync(waitForOptionalTasks)
        this.updateScene()
        while (this.sceneManagerService.requiresSync(waitForOptionalTasks)) {
            await this.sceneManagerService.sync(waitForOptionalTasks)
            this.updateScene()
        }
    }

    private requestRedraw() {
        this.requestedRedraw.next()
    }

    addShadowMapMesh(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        if (!this.shadowMapMeshes.has(mesh)) {
            this.shadowMapMeshes.add(mesh)
            this.requestShadowMapUpdate()
        }
    }

    removeShadowMapMesh(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>, willBeDisposed: boolean) {
        if (this.shadowMapMeshes.has(mesh)) {
            this.shadowMapMeshes.delete(mesh)
            if (willBeDisposed && this.progressiveLightMap) this.progressiveLightMap.onDisposingMesh(mesh)
            this.requestShadowMapUpdate()
        }
    }

    requestShadowMapUpdate() {
        this.shadowMapMeshesChanged.next()
    }

    getThreeObject(id: ObjectId) {
        return this.objectCache.get(id)
    }

    exchangedShadowMapMeshTexture(mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>) {
        if (this.progressiveLightMap && this.progressiveLightMap.has(mesh)) {
            const {material} = mesh

            const newMaterial = this.materialManagerService.acquireVariation(
                material,
                (material) => {
                    //@ts-ignore
                    return material.uvShadowMap !== null
                },
                (newMaterial) => {},
            )
            //@ts-ignore
            newMaterial.uvShadowMap = this.progressiveLightMap.getUVShadowMap()
            this.materialManagerService.releaseMaterial(material)
            mesh.material = newMaterial
        }
    }

    protected shadowMapMeshesSettled() {
        if (this.progressiveLightMap) {
            this.progressiveLightMap.attachToMeshes(this.shadowMapMeshes)
            this.progressiveLightMapUpdate$.next()
        }
    }
}
