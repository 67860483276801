import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {deepCopy} from "@cm/lib/utils/utils"
import {
    Operator,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {HalPainterImageBlit} from "@common/models/hal/common/hal-painter-image-blit"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {Box2Like} from "@cm/lib/math/box2"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {Vector2Like} from "@cm/lib/math/vector2"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {CloneStampPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/clone-stamp/panel/clone-stamp-panel.component"
import {CloneStampToolbox} from "@app/textures/texture-editor/operator-stack/operators/clone-stamp/toolbox/clone-stamp-toolbox"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-drawable-image"
import {blend} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-blend"

export class OperatorCloneStamp extends OperatorBase<TextureEditNodes.OperatorCloneStamp> {
    readonly panelComponentType: OperatorPanelComponentType = CloneStampPanelComponent
    readonly canvasToolbox: CloneStampToolbox

    readonly type = "operator-clone-stamp" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorCloneStamp | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-clone-stamp",
                enabled: true,
                strokes: [],
            },
        )

        this.canvasToolbox = new CloneStampToolbox(this)

        this.halBlitter = new HalPainterImageBlit(this.callback.halContext)
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        for (const drawableImageRef of this.drawableImageRefByMaskReference.values()) {
            this.callback.drawableImageCache.removeDrawableImage(drawableImageRef)
        }
        this.callback.drawableImageCache.removeDrawableImage(this.canvasToolbox.currentStrokeDrawableImageRef)
        this.canvasToolbox.remove()
        this.halBlitter.dispose()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorCloneStamp(this.callback, deepCopy(this.node))
        await Promise.all(
            this.node.strokes.map((stroke, index) => {
                const clonedStroke = clonedOperator.node.strokes[index]
                const strokeMaskDrawableImageRef = this.getDrawableImageRefByMaskReference(stroke.maskReference)
                const clonedStrokeMaskDrawableImageRef = clonedOperator.getDrawableImageRefByMaskReference(clonedStroke.maskReference)
                return this.callback.drawableImageCache.cloneImage(strokeMaskDrawableImageRef, clonedStrokeMaskDrawableImageRef)
            }),
        )
        return clonedOperator
    }

    get showMask(): boolean {
        return this._showMask
    }

    set showMask(value: boolean) {
        this._showMask = value
        this.requestEval()
    }

    get canUndoStroke(): boolean {
        return this.node.strokes.length > 0
    }

    undoStroke(): void {
        if (this.node.strokes.length === 0) {
            return
        }
        const lastStroke = this.node.strokes.pop()!
        this.removeDrawableImageRefByMaskReference(lastStroke.maskReference)
        this.markEdited()
        this.requestEval()
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        cmdQueue.beginScope(this.type)
        const showMaskOnly = cmdQueue.mode === "preview" && this.selected && this.showMask
        let sourceImage: ImageRef
        let resultImage: ImageRef
        if (showMaskOnly) {
            sourceImage = createImage(cmdQueue, {
                imageOrDescriptor: input.descriptor,
                fillColor: {r: 1, g: 1, b: 1, a: 1},
            })
            resultImage = createImage(cmdQueue, {
                imageOrDescriptor: input.descriptor,
                fillColor: {r: 0, g: 0, b: 0, a: 1},
            })
        } else {
            sourceImage = input
            resultImage = copyRegion(cmdQueue, {
                sourceImage: sourceImage,
            })
        }
        const applyStroke = (
            drawableImageRef: DrawableImageRef,
            maskRegion: TextureEditNodes.Region,
            sourceOffsetInPixels: Vector2Like,
            sourceImage: ImageRef,
            resultImage: ImageRef,
            optionalMaskStrokeBoundingBox?: Box2Like,
        ): ImageRef => {
            const maskImage = drawableImage(cmdQueue, {
                drawableImageRef,
                descriptor: {
                    width: maskRegion.width,
                    height: maskRegion.height,
                    channelLayout: "R",
                    dataType: TextureEditorSettings.PreviewProcessingImageFormat,
                },
            })
            // TODO if we had views AND imageOpBlend would allow for resultImage==backgroundImage by utilizing actual alpha-blending
            // TODO we could use the following op instead of all of the ones below
            // resultImage = imageOpList.push(imageOpBlend, {
            //     backgroundImage: resultImage,
            //     foregroundImage: makeImageView(sourceImage, {
            //          x: stroke.sourceOffsetInPixels.x,
            //          y: stroke.sourceOffsetInPixels.y,
            //          width: stroke.maskRegion.width,
            //          height: stroke.maskRegion.height,
            //      }),
            //     alphaImage: maskImage,
            //     premultipliedAlpha: false,
            //     blendMode: "normal",
            //     resultImage: makeImageView(sourceImage, stroke.maskRegion),
            // })
            const hasStrokeBoundingBox = optionalMaskStrokeBoundingBox !== undefined
            const maskStrokeBoundingBox = hasStrokeBoundingBox ? deepCopy(optionalMaskStrokeBoundingBox) : maskRegion
            const cutoutShiftedSourceImage = copyRegion(cmdQueue, {
                sourceImage: sourceImage,
                sourceRegion: {
                    x: sourceOffsetInPixels.x - maskRegion.x + maskStrokeBoundingBox.x,
                    y: sourceOffsetInPixels.y - maskRegion.y + maskStrokeBoundingBox.y,
                    width: maskStrokeBoundingBox.width,
                    height: maskStrokeBoundingBox.height,
                },
                addressMode: "wrap",
            })
            const cutoutResultImage = copyRegion(cmdQueue, {
                sourceImage: resultImage,
                sourceRegion: maskStrokeBoundingBox,
                addressMode: "wrap",
            })
            const cutoutMaskImage = hasStrokeBoundingBox
                ? copyRegion(cmdQueue, {
                      sourceImage: maskImage,
                      sourceRegion: maskStrokeBoundingBox,
                      addressMode: "wrap",
                  })
                : maskImage
            const stampedRegion = blend(cmdQueue, {
                backgroundImage: cutoutResultImage,
                foregroundImage: cutoutShiftedSourceImage,
                alpha: cutoutMaskImage,
                premultipliedAlpha: false,
                blendMode: "normal",
            })
            resultImage = copyRegion(cmdQueue, {
                sourceImage: stampedRegion,
                targetOffset: {
                    x: maskStrokeBoundingBox.x,
                    y: maskStrokeBoundingBox.y,
                },
                addressMode: "wrap",
                resultImageOrDataType: resultImage,
            })
            return resultImage
        }
        for (const stroke of this.node.strokes) {
            const maskDrawableImageRef = this.getDrawableImageRefByMaskReference(stroke.maskReference)
            resultImage = applyStroke(maskDrawableImageRef, stroke.maskRegion, stroke.sourceOffsetInPixels, sourceImage, resultImage)
        }
        if (cmdQueue.mode === "preview" && this.canvasToolbox.isStrokeInProgress) {
            resultImage = applyStroke(
                this.canvasToolbox.currentStrokeDrawableImageRef,
                {
                    type: "region",
                    x: 0,
                    y: 0,
                    width: sourceImage.descriptor.width,
                    height: sourceImage.descriptor.height,
                },
                this.canvasToolbox.sourceOffset,
                sourceImage,
                resultImage,
                this.canvasToolbox.strokeImageBoundingBox,
            )
        }
        cmdQueue.endScope(this.type)
        return {
            resultImage,
            options: {
                stopEvaluation: showMaskOnly,
            },
        }
    }

    private getDrawableImageRefByMaskReference(maskReference: TextureEditNodes.MaskReference): DrawableImageRef {
        const drawableImageRef = this.drawableImageRefByMaskReference.get(maskReference)
        if (drawableImageRef) {
            return drawableImageRef
        }
        const newDrawableImageRef = new DrawableImageRef(maskReference, this.callback.customerLegacyId)
        this.drawableImageRefByMaskReference.set(maskReference, newDrawableImageRef)
        return newDrawableImageRef
    }

    private removeDrawableImageRefByMaskReference(maskReference: TextureEditNodes.MaskReference): void {
        const drawableImageRef = this.drawableImageRefByMaskReference.get(maskReference)
        if (!drawableImageRef) {
            return
        }
        this.drawableImageRefByMaskReference.delete(maskReference)
        this.callback.drawableImageCache.removeDrawableImage(drawableImageRef)
    }

    async addCloneStampEdit(): Promise<TextureEditNodes.CloneStampStroke | null> {
        const boundingBox = this.canvasToolbox.strokeImageBoundingBox
        if (boundingBox.isEmpty()) {
            return null
        }
        const cloneStampStroke: TextureEditNodes.CloneStampStroke = {
            maskReference: {
                type: "data-object-reference",
                dataObjectId: "",
            },
            maskRegion: {
                type: "region",
                x: boundingBox.x,
                y: boundingBox.y,
                width: boundingBox.width,
                height: boundingBox.height,
            },
            sourceOffsetInPixels: this.canvasToolbox.sourceOffset.add(boundingBox.position),
        }
        const strokeImage = await this.canvasToolbox.getStrokeImage()
        const cloneStampStrokeMaskDrawableImageRef = this.getDrawableImageRefByMaskReference(cloneStampStroke.maskReference)
        const cutoutMaskRT = await this.callback.drawableImageCache.createDrawableImageFromDescriptor(cloneStampStrokeMaskDrawableImageRef, {
            width: boundingBox.width,
            height: boundingBox.height,
            channelLayout: strokeImage.descriptor.channelLayout,
            dataType: strokeImage.descriptor.dataType,
            options: {
                ...strokeImage.descriptor.options,
                useMipMaps: false,
            },
        })
        await this.halBlitter.paint(cutoutMaskRT, strokeImage, {sourceRegion: boundingBox})
        this.node.strokes.push(cloneStampStroke)
        await this.canvasToolbox.clearStrokeImage()
        this.markEdited()
        this.requestEval()
        return cloneStampStroke
    }

    readonly brushSettings = new BrushSettings()

    private halBlitter: HalPainterImageBlit // TODO replace by image-op
    private _showMask = false
    private drawableImageRefByMaskReference = new Map<TextureEditNodes.MaskReference, DrawableImageRef>()
}
