export const forceEnum = <ValueType>(value: string | null | undefined, enumType: Record<string, ValueType>): ValueType => {
    if (!value) {
        throw new Error(`Invalid enum value "${value}"`)
    }
    const result = enumType[value as keyof typeof enumType]
    if (!result) {
        throw new Error(`Invalid enum value "${value}", expected one of ${Object.keys(enumType).join(", ")}`)
    }
    return result
}

export const maybeEnum = <KeyType extends string | number, ValueType>(
    value: KeyType | null | undefined,
    enumType: Record<KeyType, ValueType>,
): ValueType | undefined => {
    if (!value) {
        return undefined
    }
    const result = enumType[value as keyof typeof enumType]
    if (!result) {
        return undefined
    }
    return result
}
