import {Component, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialogModule} from "@angular/material/dialog"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {ContentTypeModel, HdrisGridItemFragment, MutationCreateHdriInput, MutationUpdateHdriInput} from "@api"
import {CardErrorComponent, CardPlaceholderComponent} from "@common/components/cards"
import {EntityResponsiveSidebarComponent} from "@common/components/entity/entity-responsive-sidebar/entity-responsive-sidebar.component"
import {CheckboxesFilterComponent, SearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent, ItemListComponent, ListInfoComponent} from "@common/components/item"
import {InfiniteListComponent} from "@common/components/lists"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"

@Component({
    imports: [
        CardErrorComponent,
        EntityResponsiveSidebarComponent,
        ItemFiltersComponent,
        SearchFilterComponent,
        CheckboxesFilterComponent,
        InfiniteListComponent,
        ListInfoComponent,
        CardPlaceholderComponent,
        MatDialogModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        EntityCardComponent,
        OrganizationSelectComponent,
    ],
    selector: "cm-hdris-grid",
    standalone: true,
    styleUrls: ["./hdris-grid.component.scss"],
    templateUrl: "./hdris-grid.component.html",
})
export class HdrisGridComponent extends ItemListComponent<HdrisGridItemFragment, MutationUpdateHdriInput, MutationCreateHdriInput> implements OnInit {
    protected override _contentTypeModel = ContentTypeModel.Hdri
    protected override _createItem = (data: MutationCreateHdriInput) =>
        this.sdk.gql.createHdrisGridItem({input: data}).then((result) => {
            this.items = [{data: result.createHdri, placeholder: false, error: null}, ...this.items]
            return result.createHdri
        })

    protected _fetchList = ({skip, take}: {skip: number; take: number}) =>
        this.sdk.gql
            .hdrisGridItems({
                take: take,
                skip: skip,
                filter: this.filters.hdriFilter(),
            })
            .then(({hdris, hdrisCount}) => ({items: hdris, totalCount: hdrisCount}))

    protected _refreshItem = ({id, legacyId}: {id?: string; legacyId?: number}) =>
        this.sdk.gql
            .hdrisGridItems({
                take: 1,
                filter: {
                    ...this.filters.hdriFilter(),
                    id: id ? {equals: id} : undefined,
                    legacyId: legacyId ? {equals: legacyId} : undefined,
                },
            })
            .then(({hdris}) => hdris?.[0] || undefined)

    updateOrganization = async (data: {organizationId: string}) => {
        this.newItemData.organizationId = data.organizationId
    }
}
