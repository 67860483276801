import {TextureType} from "@api"

export type TextureTypeDescriptor = {
    label: string
    channelLayout: "RGB" | "L"
    isColorData: boolean
    legacyEnumId: number
}

const descriptorByTextureTypeMap = new Map<TextureType, TextureTypeDescriptor>([
    [TextureType.Diffuse, {label: "Diffuse", channelLayout: "RGB", isColorData: true, legacyEnumId: 1}],
    [TextureType.DiffuseRoughness, {label: "Diffuse roughness", channelLayout: "L", isColorData: false, legacyEnumId: 2}],
    [TextureType.Normal, {label: "Normal", channelLayout: "RGB", isColorData: false, legacyEnumId: 3}],
    [TextureType.Roughness, {label: "Roughness", channelLayout: "L", isColorData: false, legacyEnumId: 4}],
    [TextureType.F0, {label: "F0", channelLayout: "RGB", isColorData: false, legacyEnumId: 5}],
    [TextureType.Ior, {label: "IOR", channelLayout: "L", isColorData: false, legacyEnumId: 6}],
    [TextureType.AnisotropyRotation, {label: "Anisotropy rotation", channelLayout: "L", isColorData: false, legacyEnumId: 7}],
    [TextureType.AnisotropyStrength, {label: "Anisotropy strength", channelLayout: "L", isColorData: false, legacyEnumId: 8}],
    [TextureType.Error, {label: "Error", channelLayout: "L", isColorData: false, legacyEnumId: 9}],
    [TextureType.Mask, {label: "Mask", channelLayout: "L", isColorData: false, legacyEnumId: 11}],
    [TextureType.Displacement, {label: "Displacement", channelLayout: "L", isColorData: false, legacyEnumId: 12}],
    [TextureType.SpecularStrength, {label: "Specular strength", channelLayout: "L", isColorData: false, legacyEnumId: 13}],
    [TextureType.SpecularTint, {label: "Specular tint", channelLayout: "RGB", isColorData: false, legacyEnumId: 14}],
    [TextureType.Anisotropy, {label: "Anisotropy", channelLayout: "RGB", isColorData: false, legacyEnumId: 15}],
    [TextureType.Metalness, {label: "Metalness", channelLayout: "L", isColorData: false, legacyEnumId: 16}],
    [TextureType.Transmission, {label: "Transmission", channelLayout: "RGB", isColorData: true, legacyEnumId: 17}],
])

export const textureTypes = Array.from(descriptorByTextureTypeMap.keys())

export const descriptorByTextureType = (textureType: TextureType): TextureTypeDescriptor => {
    const descriptor = descriptorByTextureTypeMap.get(textureType)
    if (!descriptor) {
        throw new Error(`Unsupported texture type: ${textureType}`)
    }
    return descriptor
}

export const textureTypeByLegacyEnumId = (legacyEnumId: number): TextureType => {
    for (const [textureType, descriptor] of descriptorByTextureTypeMap) {
        if (descriptor.legacyEnumId === legacyEnumId) {
            return textureType
        }
    }
    throw new Error(`Unsupported legacy enum id: ${legacyEnumId}`)
}
