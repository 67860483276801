import {Component, inject, Input, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatDatepicker, MatDatepickerInput} from "@angular/material/datepicker"
import {MatInputModule} from "@angular/material/input"
import {ArStatisticsFilterInput, SceneStatisticsFilterInput} from "@api"
import {SdkService} from "@common/services/sdk/sdk.service"
import {endOfDay} from "date-fns/endOfDay"
import {startOfDay} from "date-fns/startOfDay"

@Component({
    selector: "cm-picture-statistics",
    standalone: true,
    imports: [FormsModule, MatDatepickerInput, MatDatepicker, MatInputModule],
    templateUrl: "./picture-statistics.component.html",
    styleUrl: "./picture-statistics.component.scss",
})
export class PictureStatisticsComponent implements OnInit {
    fromDate = startOfDay(new Date())
    toDate = endOfDay(new Date())
    datePickerMaxValue: Date = new Date()

    @Input() templateId?: string
    @Input() templateRevisionId?: string

    sdk = inject(SdkService)

    sceneStatisticsItemsCount = 0
    arStatisticsItemsCount = 0

    ngOnInit() {
        void this.updateStatistics()
    }

    async updateStatistics() {
        this.fromDate = startOfDay(new Date())
        this.toDate = endOfDay(new Date())
        if (this.templateRevisionId) {
            const filter: ArStatisticsFilterInput = {
                templateRevisionId: {equals: this.templateRevisionId},
                created: {gte: this.fromDate.toJSON(), lte: this.toDate.toJSON()},
            }
            const {arStatisticsItemsCount} = await this.sdk.gql.pictureArStatistics({filter})
            this.arStatisticsItemsCount = arStatisticsItemsCount
        }
        if (this.templateId) {
            const filter: SceneStatisticsFilterInput = {
                templateId: {equals: this.templateId},
                created: {gte: this.fromDate.toJSON(), lte: this.toDate.toJSON()},
            }
            const {sceneStatisticsItemsCount} = await this.sdk.gql.pictureSceneStatistics({filter})
            this.sceneStatisticsItemsCount = sceneStatisticsItemsCount
        }
    }
}
