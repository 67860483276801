import {registerNode} from "@src/graph-system/register-node"
import {ObjectLike, objectLike} from "@src/templates/node-types"
import {z} from "zod"
import {skipped, visitNone} from "@src/graph-system/declare-visitor-node"
import {DeclareObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {namedNodeParameters} from "@src/templates/nodes/named-node"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {nodeInstance} from "@src/graph-system/instance"
import {MeshCurve} from "./mesh-curve"
import {SampleCurve} from "../runtime-graph/nodes/sample-curve"
import {Vector3} from "@src/math/vector3"
import {mergeBounds, transformBounds} from "../utils/scene-geometry-utils"

const seamParameters = namedNodeParameters.merge(
    z.object({
        item: objectLike.nullable(),
        curve: nodeInstance(MeshCurve).nullable(),
        allowScaling: z.boolean(),
    }),
)
export type SeamParameters = {
    item: ObjectLike | null
    curve: MeshCurve | null
    allowScaling: boolean
}

@registerNode
export class Seam extends DeclareObjectNode(
    {parameters: seamParameters},
    {
        onVisited: {
            onFilterActive: ({parameters}) => {
                if (parameters.item === null || parameters.curve === null) return skipped
                return visitNone(parameters)
            },
            onCompile: function (this: SeamFwd, {context, parameters}) {
                const {item, curve, allowScaling} = parameters

                if (!item || !curve) return skipped

                const {closed, controlPoints, mesh} = curve.parameters

                if (!mesh) return skipped

                const {evaluator} = context

                const scope = evaluator.getScope(this)

                const [objectDataValid] = scope.branch(evaluator.evaluateObject(scope, item))
                const meshesAndBounds = scope.lambda(
                    objectDataValid,
                    ({preDisplayList, displayList, matrix}) => {
                        const inverseTransform = matrix.inverse()
                        const meshes = [...preDisplayList, ...displayList]
                            .filter(SceneNodes.Mesh.is)
                            .map<SceneNodes.Mesh>((mesh) => ({...mesh, transform: inverseTransform.multiply(mesh.transform)}))
                        const bounds = mergeBounds(meshes.map((mesh) => transformBounds(mesh.meshData.bounds, mesh.transform)))
                        return {meshes, bounds}
                    },
                    "item",
                )

                const transformedControlPoints = controlPoints.map((controlPoint) => ({
                    position: Vector3.fromArray(controlPoint.position),
                    normal: Vector3.fromArray(controlPoint.normal),
                    corner: controlPoint.corner,
                }))

                const {curvePoints} = scope.node(SampleCurve, {
                    closed,
                    allowScaling,
                    controlPoints: transformedControlPoints,
                    segmentLength: scope.get(meshesAndBounds, "bounds"),
                })

                const meshObjectData = evaluator.evaluateMesh(scope, mesh)

                this.setupObject(scope, context, "Seam", undefined, meshObjectData, (objectProps) => {
                    return scope.struct<SceneNodes.Seam>("Seam", {
                        type: "Seam",
                        ...objectProps,
                        item: scope.get(meshesAndBounds, "meshes"),
                        curvePoints,
                        meshId: scope.get(meshObjectData, "id"),
                    })
                })

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "Seam"},
) {}

export type SeamFwd = TemplateObjectNode<SeamParameters>
