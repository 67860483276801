import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {
    Operator,
    OperatorCanvasToolboxType,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {RotatePanelComponent} from "app/textures/texture-editor/operator-stack/operators/rotate/panel/rotate-panel.component"
import {EventEmitter} from "@angular/core"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {deepCopy, wrap} from "@cm/lib/utils/utils"
import {deg2rad} from "@cm/lib/math/utils"
import {TextureType} from "@api"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {affineTransform} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-affine-transform"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {extractChannel} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-extract-channel"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {combineChannels} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-combine-channels"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"

export class OperatorRotate extends OperatorBase<TextureEditNodes.OperatorRotate> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["apply-to-all-texture-types"]) // rotation is not preserving texture size, so it must be applied to all texture types

    readonly angleInDegreesChanged = new EventEmitter<number>()

    readonly panelComponentType: OperatorPanelComponentType = RotatePanelComponent
    readonly canvasToolbox: OperatorCanvasToolboxType = null

    readonly type = "operator-rotate" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorRotate | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-rotate",
                enabled: true,
                angleInDegrees: 0,
            },
        )
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorRotate(this.callback, deepCopy(this.node))
        return clonedOperator
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        if (this.angleInDegrees === 0) {
            // don't do anything if angle is 0
            return {resultImage: input}
        } else {
            cmdQueue.beginScope(this.type)
            let resultImage = affineTransform(cmdQueue, {
                sourceImage: input,
                transform: new Matrix3x2().rotate(this.angleInDegrees),
            })
            if (hints.textureType === TextureType.Normal) {
                // normal maps need to be rotated as well to keep consistent
                resultImage = this.rotateVectorMap(cmdQueue, resultImage, this.angleInDegrees, -1) // we need to rotate the opposite direction
            } else if (hints.textureType === TextureType.Anisotropy) {
                // anisotropy maps need to be rotated as well to keep consistent
                resultImage = this.rotateVectorMap(cmdQueue, resultImage, this.angleInDegrees, -2) // we need to rotate by twice the angle because anisotropy is periodic in 180°
            } else if (hints.textureType === TextureType.AnisotropyRotation) {
                // anisotropy rotation map need to be rotated as well to keep consistent
                resultImage = this.rotateAngleMap(cmdQueue, resultImage, this.angleInDegrees, -1) // anisotropy is periodic in 180° but the map stores only 0..0.5 range
            }
            cmdQueue.endScope(this.type)
            return {resultImage}
        }
    }

    private rotateVectorMap(cmdQueue: ImageOpCommandQueue, source: ImageRef, angleInDegrees: number, angleFactor = 1): ImageRef {
        let x = extractChannel(cmdQueue, {
            sourceImage: source,
            channel: "R",
        })
        let y = extractChannel(cmdQueue, {
            sourceImage: source,
            channel: "G",
        })
        const z = extractChannel(cmdQueue, {
            sourceImage: source,
            channel: "B",
        })

        x = this.zeroOneToMinusOnePlusOneRangeConversion(cmdQueue, x)
        y = this.zeroOneToMinusOnePlusOneRangeConversion(cmdQueue, y)

        const xTimesAngleCos = math(cmdQueue, {
            operandA: x,
            operandB: Math.cos(deg2rad(angleInDegrees * angleFactor)),
            operator: "*",
        })
        const yTimesAngleSin = math(cmdQueue, {
            operandA: y,
            operandB: Math.sin(deg2rad(angleInDegrees * angleFactor)),
            operator: "*",
        })
        let rotatedX = math(cmdQueue, {
            operandA: xTimesAngleCos,
            operandB: yTimesAngleSin,
            operator: "+",
        })

        const yTimesAngleCos = math(cmdQueue, {
            operandA: y,
            operandB: Math.cos(deg2rad(angleInDegrees * angleFactor)),
            operator: "*",
        })
        const xTimesAngleSin = math(cmdQueue, {
            operandA: x,
            operandB: Math.sin(deg2rad(angleInDegrees * angleFactor)),
            operator: "*",
        })
        let rotatedY = math(cmdQueue, {
            operandA: yTimesAngleCos,
            operandB: xTimesAngleSin,
            operator: "-",
        })

        rotatedX = this.minusOnePlusOneToZeroOneRangeConversion(cmdQueue, rotatedX)
        rotatedY = this.minusOnePlusOneToZeroOneRangeConversion(cmdQueue, rotatedY)

        const result = combineChannels(cmdQueue, {
            sourceImages: [rotatedX, rotatedY, z],
        })

        return convert(cmdQueue, {
            sourceImage: result,
            dataType: source.descriptor.dataType,
        })
    }

    private rotateAngleMap(cmdQueue: ImageOpCommandQueue, source: ImageRef, angleInDegrees: number, angleFactor = 1): ImageRef {
        const result = math(cmdQueue, {
            operandA: math(cmdQueue, {
                operandA: source,
                operandB: wrap((angleFactor * angleInDegrees) / 360, 1),
                operator: "+",
                resultImageOrDataType: source.descriptor.dataType === "float32" ? "float32" : "float16",
            }),
            operandB: 0.5,
            operator: "mod",
        })
        return convert(cmdQueue, {
            sourceImage: result,
            dataType: source.descriptor.dataType,
        })
    }

    // [0..1] -> [-1..1]
    private zeroOneToMinusOnePlusOneRangeConversion(cmdQueue: ImageOpCommandQueue, source: ImageRef): ImageRef {
        let result = math(cmdQueue, {
            operandA: source,
            operandB: 2,
            operator: "*",
            resultImageOrDataType: source.descriptor.dataType === "float32" ? "float32" : "float16",
        })
        result = math(cmdQueue, {
            operandA: result,
            operandB: 1,
            operator: "-",
        })
        return result
    }

    // [-1..1] -> [0..1]
    private minusOnePlusOneToZeroOneRangeConversion(cmdQueue: ImageOpCommandQueue, source: ImageRef): ImageRef {
        let result = math(cmdQueue, {
            operandA: source,
            operandB: 0.5,
            operator: "*",
        })
        result = math(cmdQueue, {
            operandA: result,
            operandB: 0.5,
            operator: "+",
        })
        return result
    }

    get angleInDegrees(): number {
        return this.node.angleInDegrees
    }

    set angleInDegrees(value: number) {
        if (this.node.angleInDegrees === value) {
            return
        }
        this.node.angleInDegrees = value
        this.markEdited()
        this.requestEval()
        this.angleInDegreesChanged.emit(value)
    }
}
