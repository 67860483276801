import {skipped, visitAll, visitNone} from "@src/graph-system/declare-visitor-node"
import {registerNode} from "@src/graph-system/register-node"
import {BooleanLike, booleanLike} from "@src/templates/node-types"
import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {z} from "zod"
import {groupNodeParameters, GroupNodeParameters} from "@src/templates/nodes/group-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"

export const groupParameters = namedNodeParameters.merge(groupNodeParameters).merge(z.object({active: booleanLike}))
export type GroupParameters = NamedNodeParameters & GroupNodeParameters & {active: BooleanLike}

@registerNode
export class Group extends DeclareTemplateNodeTS<GroupParameters>(
    {
        validation: {paramsSchema: groupParameters},
        onVisited: {
            onFilterActive: function (this: Group, {visit, context, parameters}) {
                const {evaluator} = context

                const {nodes, ...rest} = parameters
                const {active} = rest

                return evaluator.evaluateActive(active)
                    ? {
                          nodes: visit(nodes),
                          ...visitNone(rest),
                      }
                    : skipped
            },
            onCompile: ({visit, context, parameters}) => {
                return visitAll(parameters, visit)
            },
        },
    },
    {nodeClass: "Group"},
) {}

export type GroupFwd = TemplateNode<GroupParameters>
