<ng-content></ng-content>

<cm-inspector-section>
    <ng-container cm-title> Mesh Curve </ng-container>
    <ng-container cm-content>
        <cm-value-slot [node]="node()" [key]="'mesh'" [label]="'Base Mesh'"></cm-value-slot>
    </ng-container>
</cm-inspector-section>

<cm-button class="cm-action-button" (click)="addPoint()" [disabled]="!mesh() || sceneManagerService.watchingForClickedTemplateNodePart()">
    <ng-container cm-text> Add Point </ng-container>
</cm-button>

@if (controlPoints().length > 0) {
    <cm-inspector-section>
        <ng-container cm-title>Control Points </ng-container>
        <ng-container cm-content>
            @for (index of controlPoints(); track index) {
                <cm-value-slot
                    [node]="node()"
                    [key]="'controlPoints'"
                    [subKey]="[index, 'corner']"
                    [label]="'Corner Point (' + (index + 1) + ')'"
                    (mouseenter)="onMouseEnter(index)"
                    (mouseleave)="onMouseLeave()"
                ></cm-value-slot>
            }
            <cm-value-slot [node]="node()" [key]="'closed'" [label]="'Close Path'"></cm-value-slot>
        </ng-container>
    </cm-inspector-section>

    <cm-button class="cm-action-button" (click)="deletePoints()" [disabled]="!mesh() || sceneManagerService.watchingForClickedTemplateNodePart()">
        <ng-container cm-text> Delete </ng-container>
    </cm-button>
}
