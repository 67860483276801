import {Component, EventEmitter, Input, OnDestroy, Output} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatMenuModule} from "@angular/material/menu"
import {Subject} from "rxjs"
import {TextureSetRevisionDataFragment, TextureType} from "@api"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"

@Component({
    selector: "cm-texture-selector",
    templateUrl: "./texture-selector.component.html",
    styleUrls: ["./texture-selector.component.scss"],
    standalone: true,
    imports: [MatMenuModule, MatTooltipModule],
})
export class TextureSelectorComponent implements OnDestroy {
    @Input() set textureSetRevision(value: TextureSetRevisionDataFragment | undefined) {
        if (this._textureSetRevision === value) {
            return
        }
        this._textureSetRevision = value
        this.updateTexturesByType(value)
    }
    @Input() set availableTextureTypes(value: TextureType[]) {
        if (this._availableTextureTypes === value) {
            return
        }
        this._availableTextureTypes = value
        this.updateTexturesByType(this._textureSetRevision)
    }
    @Input() selectedTextureType!: TextureType
    @Output() selectedTextureTypeChange = new EventEmitter<TextureType>()

    get textureSetRevision(): TextureSetRevisionDataFragment | undefined {
        return this._textureSetRevision
    }

    get availableTextureTypes(): TextureType[] {
        return this._availableTextureTypes
    }

    constructor() {}

    ngOnDestroy(): void {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    textureItemByType: Map<TextureType, TextureItem> = new Map<TextureType, TextureItem>()

    textureTypeOrder: TextureType[] = [
        TextureType.Diffuse,
        TextureType.Normal,
        TextureType.DiffuseRoughness,
        TextureType.Roughness,
        TextureType.Metalness,
        TextureType.SpecularStrength,
        TextureType.SpecularTint,
        TextureType.F0,
        TextureType.Anisotropy,
        TextureType.AnisotropyRotation,
        TextureType.AnisotropyStrength,
        TextureType.Displacement,
        TextureType.Ior,
        TextureType.Mask,
        TextureType.Transmission,
        TextureType.Error,
    ]

    private updateTexturesByType(textureSetRevision: TextureSetRevisionDataFragment | undefined): void {
        this.textureItemByType.clear()
        if (textureSetRevision) {
            for (const textureType of this.textureTypeOrder) {
                if (!this.availableTextureTypes.includes(textureType)) {
                    continue
                }
                // if (this.selectedTextureRevisionInfo.mode === TextureMapCollection.TextureRevisionMode.LocallyTiled) {
                //     if (this.textureMapCollection.hasTiledTextureByType(textureType)) {
                //         const tiledTexture = this.textureMapCollection.getTiledTextureByType(textureType)
                //         blobToDataURL(new Blob([tiledTexture.preview.data], {type: tiledTexture.preview.contentType})).subscribe((dataURL) => {
                //             const textureItem: TextureItem = {
                //                 textureType: textureType,
                //                 tiledTexture: tiledTexture,
                //                 thumbnailUrl: dataURL,
                //             }
                //             this.textureItemByType.set(textureType, textureItem)
                //         })
                //     }
                // } else
                {
                    const mapAssignment = textureSetRevision.mapAssignments.find((mapAssignment) => mapAssignment.textureType === textureType)
                    if (mapAssignment) {
                        if (!mapAssignment.dataObject.thumbnail) {
                            throw new Error("Texture revision has no thumbnail")
                        }
                        const textureItem: TextureItem = {
                            textureType: textureType,
                            thumbnailUrl: mapAssignment.dataObject.thumbnail.downloadUrl,
                        }
                        this.textureItemByType.set(textureType, textureItem)
                    }
                }
            }
        }
    }

    // getTiledTextureByType(type: TextureType): Tiling.OutputTextureInfo | null {
    //     if (!this.textureMapCollection.hasTiledTextureByType(type)) {
    //         return null
    //     }
    //     return this.textureMapCollection.getTiledTextureByType(type)
    // }

    itemClicked(item: TextureItem): void {
        this.selectedTextureTypeChange.emit(item.textureType)
    }

    // get isTiledTexture(): boolean {
    //     return this.selectedTextureRevisionInfo?.mode === TextureMapCollection.TextureRevisionMode.LocallyTiled
    // }

    // get activeTiledTexture(): Tiling.OutputTextureInfo | null {
    //     return this.textureMapCollection.getTiledTextureByType(this.textureMapCollection.selectedTextureType)
    // }

    // get activeTextureRevision(): TextureRevision | undefined {
    //     return this.textureMapCollection.getTextureAndRevisionByType(this.textureMapCollection.selectedTextureType)?.textureRevision
    // }

    // get selectedTextureType(): TextureType {
    //     return this.textureMapCollection.selectedTextureType
    // }

    // get activeTexture(): Texture | undefined {
    //     return this.textureMapCollection.getTextureAndRevisionByType(this.textureMapCollection.selectedTextureType)?.texture
    // }

    // get selectedTextureRevisionInfo(): TextureMapCollection.RevisionInfo | undefined {
    //     return this.textureMapCollection.selectedTextureRevisionInfo
    // }

    // get label(): string {
    //     return this.selectedTextureRevisionInfo?.label ?? ""
    // }

    private unsubscribe = new Subject<void>()
    protected descriptorByTextureType = descriptorByTextureType
    private _textureSetRevision: TextureSetRevisionDataFragment | undefined = undefined
    private _availableTextureTypes: TextureType[] = []
}

export type TextureItem = {
    thumbnailUrl: string
    textureType: TextureType
    // tiledTexture?: Tiling.OutputTextureInfo
}
