import {HalImageDescriptor, HalImageHTMLCanvasElement, HalImageHTMLImageElement, HalImageUrl} from "@common/models/hal/hal-image/types"

export function isHalImageDescriptor(obj?: unknown): obj is HalImageDescriptor {
    return (
        obj != undefined &&
        typeof obj === "object" &&
        "width" in obj &&
        obj.width != undefined &&
        "height" in obj &&
        obj.height != undefined &&
        "channelLayout" in obj &&
        obj.channelLayout != undefined &&
        "dataType" in obj &&
        obj.dataType != undefined
    )
}

export function isHalImageUrl(obj?: unknown): obj is HalImageUrl {
    return obj != undefined && typeof obj === "object" && "url" in obj && obj.url != undefined
}

export function isHalImageHTMLImageElement(obj?: unknown): obj is HalImageHTMLImageElement {
    return obj != undefined && typeof obj === "object" && "htmlImageElement" in obj && obj.htmlImageElement instanceof HTMLImageElement
}

export function isHalImageHTMLCanvasElement(obj?: unknown): obj is HalImageHTMLCanvasElement {
    return obj != undefined && typeof obj === "object" && "htmlCanvasElement" in obj && obj.htmlCanvasElement instanceof HTMLCanvasElement
}
