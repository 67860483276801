import {registerNode} from "@src/graph-system/register-node"
import {namedNodeParameters} from "@src/templates/nodes/named-node"
import {DeclareObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import {visitNone} from "@src/graph-system/declare-visitor-node"

const lightPortalParameters = namedNodeParameters.merge(
    z.object({
        width: z.number(),
        height: z.number(),
    }),
)
export type LightPortalParameters = z.infer<typeof lightPortalParameters>

@registerNode
export class LightPortal extends DeclareObjectNode(
    {parameters: lightPortalParameters},
    {
        onVisited: {
            onCompile: function (this: LightPortalFwd, {context, parameters}) {
                const {evaluator} = context
                const {width, height} = parameters

                const scope = evaluator.getScope(this)

                this.setupObject(scope, context, "AreaLight", undefined, undefined, (objectProps) => {
                    return scope.struct<SceneNodes.LightPortal>("LightPortal", {
                        type: "LightPortal",
                        ...objectProps,
                        width,
                        height,
                    })
                })

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "LightPortal"},
) {}

export type LightPortalFwd = TemplateObjectNode<LightPortalParameters>
