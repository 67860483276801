import {AnyJSONValue, ColorValue, ExternalId, TemplateData} from "@src/templates/types"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {Nodes} from "@src/templates/legacy/template-nodes"
import {ConfiguratorParameter} from "@src/templates/configurator-parameters"
import {TemplateParameterValue} from "@src/templates/nodes/parameters"

export type InterfaceId = string
export type VariantId = string

export type DescriptorProps<V, T extends object = {}> = {
    id: InterfaceId
    name: string
    isSetByTemplate?: boolean
    type: "input" | "output"
    value: V
    origin?: TemplateParameterValue
} & T

export abstract class InterfaceDescriptor<V, T extends object = {}> {
    constructor(public props: DescriptorProps<V, T>) {}
    clone(id: string, isSetByTemplate?: boolean): this {
        throw new Error("Method not implemented.")
    }
    toLegacy(): Nodes.Meta.InterfaceDescriptor {
        throw new Error("Method not implemented.")
    }
    toConfiguratorParameter(): ConfiguratorParameter {
        throw new Error("Method not implemented.")
    }
}

export type VariantInfo = {
    id: VariantId
    name: string
    iconDataObjectId?: number
    iconColor?: ColorValue
    excludeFromPermutations?: boolean
}

export type ConfigInfoParameters = {
    variants: VariantInfo[]
    displayWithLabels: boolean
}

export class ConfigInfo extends InterfaceDescriptor<VariantInfo | null, ConfigInfoParameters> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new ConfigInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        const retValue: Nodes.Meta.ConfigInfo = {
            inputType: "config",
            interfaceType: "input",
            id: this.props.id,
            name: this.props.name,
            isSetByTemplate: this.props.isSetByTemplate,
            value: this.props.value?.id,
            variants: this.props.variants,
        }
        return retValue
    }

    override toConfiguratorParameter(): ConfiguratorParameter {
        return {
            id: this.props.id,
            type: "config",
            name: this.props.name,
            value: this.props.value?.id,
            values: this.props.variants.map((v) => ({id: v.id, name: v.name})),
        }
    }
}

export class ObjectInfo extends InterfaceDescriptor<ObjectData | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new ObjectInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        if (this.props.type === "input") {
            const retValue: Nodes.Meta.ObjectInputInfo = {
                inputType: "object",
                interfaceType: "input",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        } else {
            const retValue: Nodes.Meta.ObjectOutput = {
                outputType: "object",
                interfaceType: "output",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        }
    }
}
export class MaterialInfo extends InterfaceDescriptor<IMaterialGraph | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new MaterialInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        if (this.props.type === "input") {
            const retValue: Nodes.Meta.MaterialInputInfo = {
                inputType: "material",
                interfaceType: "input",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        } else {
            const retValue: Nodes.Meta.MaterialOutput = {
                outputType: "material",
                interfaceType: "output",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        }
    }

    override toConfiguratorParameter(): ConfiguratorParameter {
        return {
            id: this.props.id,
            type: "material",
            name: this.props.name,
            value: this.props.value?.materialId,
        }
    }
}

export class TemplateInfo extends InterfaceDescriptor<TemplateData | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new TemplateInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        if (this.props.type === "input") {
            const retValue: Nodes.Meta.TemplateInputInfo = {
                inputType: "template",
                interfaceType: "input",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        } else {
            const retValue: Nodes.Meta.TemplateOutput = {
                outputType: "template",
                interfaceType: "output",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        }
    }
}

export class ImageInfo extends InterfaceDescriptor<TemplateImageDataNew | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new ImageInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        if (this.props.type === "input") {
            const retValue: Nodes.Meta.ImageInputInfo = {
                inputType: "image",
                interfaceType: "input",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        } else {
            const retValue: Nodes.Meta.ImageOutput = {
                outputType: "image",
                interfaceType: "output",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        }
    }
}

export class StringInfo extends InterfaceDescriptor<string | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new StringInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        if (this.props.type === "input") {
            const retValue: Nodes.Meta.StringInputInfo = {
                inputType: "string",
                interfaceType: "input",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        } else {
            const retValue: Nodes.Meta.StringOutput = {
                outputType: "string",
                interfaceType: "output",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        }
    }
}

export class NumberInfo extends InterfaceDescriptor<number | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new NumberInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        if (this.props.type === "input") {
            const retValue: Nodes.Meta.NumberInputInfo = {
                inputType: "number",
                interfaceType: "input",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        } else {
            const retValue: Nodes.Meta.NumberOutput = {
                outputType: "number",
                interfaceType: "output",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        }
    }
}

export class BooleanInfo extends InterfaceDescriptor<boolean | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new BooleanInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }

    override toLegacy() {
        if (this.props.type === "input") {
            const retValue: Nodes.Meta.BooleanInputInfo = {
                inputType: "boolean",
                interfaceType: "input",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        } else {
            const retValue: Nodes.Meta.BooleanOutput = {
                outputType: "boolean",
                interfaceType: "output",
                id: this.props.id,
                name: this.props.name,
                isSetByTemplate: this.props.isSetByTemplate,
                value: this.props.value ?? undefined,
            }
            return retValue
        }
    }
}

export class JSONInfo extends InterfaceDescriptor<AnyJSONValue | null> {
    override clone(id: InterfaceId, isSetByTemplate?: boolean) {
        return new JSONInfo({
            ...this.props,
            id,
            isSetByTemplate,
        }) as this
    }
}

export function wrapInterfaceId(prefix: ExternalId, id: InterfaceId): InterfaceId {
    return `${prefix}/${id}`
}

export function unwrapInterfaceId(id: InterfaceId): [ExternalId | null, InterfaceId] {
    const idx = id.lastIndexOf("/")
    if (idx === -1) return [null, id]
    return [id.slice(0, idx), id.slice(idx + 1)]
}

export function getInterfaceIdPrefix(id: InterfaceId): [ExternalId | null, ExternalId] {
    const idx = id.indexOf("/")
    if (idx === -1) return [null, id]
    return [id.slice(0, idx), id.slice(idx + 1)]
}

export function isConfigInput(descriptor: InterfaceDescriptor<unknown, {}>): descriptor is ConfigInfo {
    return descriptor instanceof ConfigInfo && descriptor.props.type === "input"
}

export function isMaterialInput(descriptor: InterfaceDescriptor<unknown, {}>): descriptor is MaterialInfo {
    return descriptor instanceof MaterialInfo && descriptor.props.type === "input"
}
