import {ReplaceAWithB} from "ts-lib/dist/browser/utils/type"
import {
    AddressSpace,
    ChannelLayout,
    DataType,
    ImageDescriptor,
    ImageDescriptorWithOptionals,
    ImageRef,
    ImageRefId,
    isImageDescriptorWithOptionals,
    isImageRef,
    makeImageRef,
} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpContextImgProc, ImgProcImageToImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-context-imgproc"
import {ImageProcessingNodes as Nodes, ImageProcessingNodes} from "ts-lib/dist/browser/image-processing/image-processing-nodes"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {ImageOpCommandQueueBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-base"
import {assertNever} from "@cm/lib/utils/utils"
import {assertBatchSizeOne, fillImageDescriptorOptionals} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils"

export const IMGPROC_USE_FLOAT32_EVERYWHERE = true // if true, it will use float32 for all images, otherwise it will use the requested data type

export class ImageOpCommandQueueImgProc extends ImageOpCommandQueueBase {
    constructor(readonly context: ImageOpContextImgProc) {
        super(context)
    }

    // convenience method
    copyToResultImage(imageRef: ImageRef, resultImageOrDataType?: ImageRef | DataType): ImageRef {
        if (resultImageOrDataType) {
            return copyRegion(this, {sourceImage: imageRef, resultImageOrDataType})
        } else {
            return imageRef
        }
    }

    createImage<T extends ImageProcessingNodes.ImageNode>(
        descriptorOrImageRef: ImageDescriptorWithOptionals | ImageRef,
        node?: ImgProcImageToImageRef<T>,
    ): ImageRef {
        const id = this._nextImageRefId++
        const descriptor = isImageDescriptorWithOptionals(descriptorOrImageRef)
            ? fillImageDescriptorOptionals(descriptorOrImageRef)
            : descriptorOrImageRef.descriptor
        if (IMGPROC_USE_FLOAT32_EVERYWHERE) {
            descriptor.dataType = "float32"
        }
        assertBatchSizeOne(descriptor)
        const mappedNode = node ?? this.createImageNode(descriptor)
        this._nodeByImageRefId.set(id, mappedNode)
        return makeImageRef("temporary", id, descriptor)
    }

    async execute(imageRefsToEvaluate: ImageRef[]): Promise<ExecutionResult<ImageRef[]>> {
        // TODO there is a potential issue here: doing something like this "copyRegion(cmdQueue, {sourceImage, resultImage})" without using the returned resultImage will cause
        //  different behaviour in the WebGL2 and ImgProc execution paths. The WebGL2 path will write to the resultImage, while the ImgProc path will not.
        //  This potential issue should be revisited !
        const nodeCacheByImageRefIdByAddressSpace = new Map<AddressSpace, Map<ImageRefId, ImageProcessingNodes.ImageNode>>()
        const traverse = async (imageRef: ImageRef) => {
            const cachedNode = nodeCacheByImageRefIdByAddressSpace.get(imageRef.addressSpace)?.get(imageRef.id)
            if (cachedNode) {
                return cachedNode
            }
            const mapImageRefToNode = async (imageRefId: ImageRefId): Promise<ImageProcessingNodes.ImageNode> => {
                const node = this._nodeByImageRefId.get(imageRefId)
                const copyAndMap = async (
                    value: unknown,
                    mappingFn: (value: unknown) => Promise<{mappedValue: unknown; recurseOnMappedValue: boolean}>,
                ): Promise<unknown> => {
                    const mappedResult = await mappingFn(value)
                    value = mappedResult.mappedValue
                    if (mappedResult.recurseOnMappedValue && value && typeof value == "object") {
                        if (Array.isArray(value)) {
                            return await Promise.all(value.map((e) => copyAndMap(e, mappingFn)))
                        } else {
                            const mappedObject: Record<string, unknown> = {}
                            for (const [objKey, objValue] of Object.entries(value)) {
                                mappedObject[objKey] = await copyAndMap(objValue, mappingFn)
                            }
                            return mappedObject
                        }
                    } else {
                        return value
                    }
                }
                return (await copyAndMap(node, async (value) => {
                    if (isImageRef(value)) {
                        return {
                            mappedValue: await traverse(value),
                            recurseOnMappedValue: false,
                        }
                    } else {
                        return {
                            mappedValue: value,
                            recurseOnMappedValue: true,
                        }
                    }
                })) as ImageProcessingNodes.ImageNode
            }
            let evaluatedNode: ImageProcessingNodes.ImageNode | undefined
            switch (imageRef.addressSpace) {
                case "temporary":
                    evaluatedNode = await mapImageRefToNode(imageRef.id)
                    break
                case "drawable":
                case "data-object":
                    evaluatedNode = await this.context.getImage(imageRef)
                    break
                default:
                    assertNever(imageRef.addressSpace)
            }
            if (!evaluatedNode) {
                throw new Error(`ImageRef not found: ${imageRef.id}`)
            }
            let nodeCacheByImageRefId = nodeCacheByImageRefIdByAddressSpace.get(imageRef.addressSpace)
            if (!nodeCacheByImageRefId) {
                nodeCacheByImageRefId = new Map()
                nodeCacheByImageRefIdByAddressSpace.set(imageRef.addressSpace, nodeCacheByImageRefId)
            }
            nodeCacheByImageRefId.set(imageRef.id, evaluatedNode)
            return evaluatedNode
        }

        return Promise.all(imageRefsToEvaluate.map((imageRef) => traverse(imageRef)))
    }

    private createImageNode(descriptor: ImageDescriptor): ImgProcImageToImageRef<ImageProcessingNodes.ImageNode> {
        const getColor = (channels: ChannelLayout): number | Nodes.RGBColor | Nodes.RGBAColor => {
            switch (channels) {
                case "RGBA":
                    return [0, 0, 0, 1]
                case "RGB":
                    return [0, 0, 0]
                case "R":
                    return 0
                default:
                    throw new Error(`Unsupported channel layout: ${channels}`)
            }
        }
        const color = getColor(descriptor.channelLayout)
        return Nodes.createImage(
            descriptor.width,
            descriptor.height,
            "float32",
            "linear", // TODO which color space?
            color,
        )
    }

    private _nextImageRefId = 1
    private _nodeByImageRefId = new Map<ImageRefId, ImgProcImageToImageRef<ImageProcessingNodes.ImageNode>>()
}

type ExecutionResult<RootNodeType> = ReplaceAWithB<RootNodeType, ImageRef, ImageProcessingNodes.ImageNode>
