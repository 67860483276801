import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {AddressSpace, ImageDescriptor, ImageRefId} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

const TRACE = TextureEditorSettings.EnableFullTrace

export class ImageBase {
    constructor(
        readonly descriptor: ImageDescriptor,
        readonly addressSpace: AddressSpace,
        readonly id: ImageRefId,
        private disposeFn: ((image: ImageBase) => void) | undefined,
        readonly debugInfo?: string,
    ) {
        if (TRACE) {
            console.log(`ImageRefBase: creation of ${addressSpace}[${id}] (${debugInfo}); refCount=${this._refCount}`)
        }
    }

    get refCount(): number {
        return this._refCount
    }

    addRef() {
        ++this._refCount
        if (TRACE) {
            console.log(`ImageRefBase: addRef of ${this.addressSpace}[${this.id}] (${this.debugInfo}); refCount=${this._refCount}`)
        }
    }

    release() {
        --this._refCount
        if (TRACE) {
            console.log(`ImageRefBase: release of ${this.addressSpace}[${this.id}] (${this.debugInfo}); refCount=${this._refCount}`)
        }
        if (this._refCount === 0 && this.disposeFn) {
            if (TRACE) {
                console.log(`ImageRefBase: dispose of ${this.addressSpace}[${this.id}] (${this.debugInfo})`)
            }
            this.disposeFn(this)
        }
    }

    equals(other: ImageBase) {
        return this.addressSpace === other.addressSpace && this.id === other.id
    }

    private _refCount = 1
}
