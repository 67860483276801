import {MeshBuffers, createMeshBuffersForMaterialGroups} from "@src/geometry-processing/mesh-data"
import {SceneNodes} from "@src/templates/interfaces/scene-object"
import * as THREE from "three"

export type BinaryStlData = {
    blob: Blob
    triangleCount: number
}

function vertexToStr(x: number, y: number, z: number): string {
    return `vertex ${x} ${y} ${z}\n`
}

function faceToStr(vA: THREE.Vector3, vB: THREE.Vector3, vC: THREE.Vector3): string {
    const normal = computeNormal(vA, vB, vC)
    return (
        `facet normal ${normal.x} ${normal.y} ${normal.z}\n` +
        "  outer loop\n" +
        `    ${vertexToStr(vA.x, vA.y, vA.z)}` +
        `    ${vertexToStr(vB.x, vB.y, vB.z)}` +
        `    ${vertexToStr(vC.x, vC.y, vC.z)}` +
        "  endloop\n" +
        "endfacet\n"
    )
}

function writeFloat32(buffer: DataView, offset: number, value: number): number {
    buffer.setFloat32(offset, value, true)
    return offset + 4
}

function writeUint16(buffer: DataView, offset: number, value: number): number {
    buffer.setUint16(offset, value, true)
    return offset + 2
}

function faceToBinary(buffer: DataView, offset: number, vA: THREE.Vector3, vB: THREE.Vector3, vC: THREE.Vector3): number {
    const normal = computeNormal(vA, vB, vC)

    offset = writeFloat32(buffer, offset, normal.x)
    offset = writeFloat32(buffer, offset, normal.y)
    offset = writeFloat32(buffer, offset, normal.z)
    ;[vA, vB, vC].forEach((vertex) => {
        offset = writeFloat32(buffer, offset, vertex.x)
        offset = writeFloat32(buffer, offset, vertex.y)
        offset = writeFloat32(buffer, offset, vertex.z)
    })

    return writeUint16(buffer, offset, 0)
}

function computeNormal(vA: THREE.Vector3, vB: THREE.Vector3, vC: THREE.Vector3): THREE.Vector3 {
    const cb = new THREE.Vector3().subVectors(vC, vB)
    const ab = new THREE.Vector3().subVectors(vA, vB)
    return new THREE.Vector3().crossVectors(cb, ab).normalize()
}

function transformVertices(geometry: THREE.BufferGeometry, matrix: THREE.Matrix4): THREE.Vector3[] {
    const positions = geometry.attributes.position
    const transformedVertices: THREE.Vector3[] = []

    for (let i = 0; i < positions.count; i++) {
        const vertex = new THREE.Vector3().fromBufferAttribute(positions, i).applyMatrix4(matrix)
        transformedVertices.push(vertex)
    }

    return transformedVertices
}

function createBufferGeometry(meshBuffers: MeshBuffers): THREE.BufferGeometry {
    const {vertices, normals, uvs, indices} = meshBuffers

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3))
    geometry.setAttribute("normal", new THREE.BufferAttribute(normals, 3))
    geometry.setAttribute("uv", new THREE.BufferAttribute(uvs[0], 2))
    for (let uvIdx = 1; uvIdx < uvs.length; uvIdx++) geometry.setAttribute(`uv${uvIdx}`, new THREE.BufferAttribute(uvs[uvIdx], 2))
    geometry.setIndex(new THREE.BufferAttribute(indices, 1))

    return geometry
}

function processGeometry(mesh: SceneNodes.Mesh, processFace: (vA: THREE.Vector3, vB: THREE.Vector3, vC: THREE.Vector3) => void) {
    const threeMatrix = new THREE.Matrix4().fromArray(mesh.transform.toArray())
    if (!(threeMatrix instanceof THREE.Matrix4)) throw new Error("Expected a THREE.Matrix4")

    const {vertices, normals, uvs, faceIDs, materialGroups} = mesh.meshData
    const meshBuffersByGroup = createMeshBuffersForMaterialGroups(vertices, normals, uvs, faceIDs, materialGroups)
    const geometry = meshBuffersByGroup.map(createBufferGeometry)

    geometry.forEach((geometry) => {
        const indices = geometry.index?.array
        if (indices) {
            const transformedVertices = transformVertices(geometry, threeMatrix)
            for (let i = 0; i < indices.length; i += 3) {
                const vA = transformedVertices[indices[i]]
                const vB = transformedVertices[indices[i + 1]]
                const vC = transformedVertices[indices[i + 2]]
                processFace(vA, vB, vC)
            }
        } else {
            console.warn("No indices found for mesh", mesh)
        }
    })
}

export function meshNodesToTxtStl(meshes: SceneNodes.Mesh[]): string {
    let stlStr = "solid mesh\n"

    meshes.forEach((mesh) => {
        processGeometry(mesh, (vA, vB, vC) => {
            stlStr += faceToStr(vA, vB, vC)
        })
    })

    stlStr += "endsolid mesh\n"
    return stlStr
}

export function meshNodesToBinaryStl(meshes: SceneNodes.Mesh[]): {blob: Blob; triangleCount: number} {
    let triangleCount = 0

    meshes.forEach((mesh) => {
        processGeometry(mesh, () => {
            triangleCount++
        })
    })

    const headerSize = 84
    const bufferSize = headerSize + 50 * triangleCount // 84 bytes header + 50 bytes per triangle
    const buffer = new DataView(new ArrayBuffer(bufferSize))
    let offset = headerSize

    meshes.forEach((mesh) => {
        processGeometry(mesh, (vA, vB, vC) => {
            offset = faceToBinary(buffer, offset, vA, vB, vC)
        })
    })

    buffer.setUint32(80, triangleCount, true)

    return {blob: new Blob([buffer], {type: "application/vnd.ms-pki.stl"}), triangleCount}
}
