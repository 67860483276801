import {AsyncPipe, NgClass, NgTemplateOutlet} from "@angular/common"
import {Component, inject, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatInputModule} from "@angular/material/input"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ConfigMenuMaterialFragment, ConfigMenuMaterialIconFragment, MaterialFilterInput} from "@api"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {Breakpoints} from "@app/common/models/constants"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {AccordionStyle} from "@cm/lib/templates/nodes/scene-properties"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {TriggeredDialogComponent} from "@common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {StringInputComponent} from "@common/components/inputs/string-input/string-input.component"
import {ConfigButtonLegacyComponent} from "@app/common/components/menu/config-menu/config-button-legacy/config-button-legacy.component"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {DialogService} from "@common/services/dialog/dialog.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {TippyDirective} from "@ngneat/helipopper"
import {SelectMaterialComponent} from "@platform/components/materials/select-material/select-material.component"
import {from, Observable, of, of as observableOf, Subject, takeUntil} from "rxjs"

@Component({
    standalone: true,
    selector: "cm-config-menu-legacy",
    templateUrl: "./config-menu.component.html",
    styleUrls: ["./config-menu.component.scss"],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [
        MemoizePipe,
        ButtonComponent,
        AsyncPipe,
        FormsModule,
        MatCheckboxModule,
        InputContainerComponent,
        StringInputComponent,
        NumericInputComponent,
        MatInputModule,
        MatSelectModule,
        NgTemplateOutlet,
        MatTooltipModule,
        SelectMaterialComponent,
        MatButtonModule,
        NgClass,
        TippyDirective,
        ConfigButtonLegacyComponent,
        TriggeredDialogComponent,
    ],
})
export class ConfigMenulegacyComponent implements OnInit, OnDestroy {
    interfaceDescriptors: Nodes.Meta.InterfaceDescriptor[] = []
    _uiStyle: Nodes.SceneProperties["uiStyle"]
    iconSize?: number
    selectedConfigGroups = new Set<string>()
    @Input() hideInputsSetByTemplate = false //Not covered by MenuService
    @Input() uiStyleOverride?: Nodes.SceneProperties["uiStyle"] //this is used at darran and vado, will try to replace it with "uiStyle".
    @Input() uiStyle?: Nodes.SceneProperties["uiStyle"]
    @Input() accordionStyle?: AccordionStyle
    @Input() externalMenu: boolean = false
    @Input() useCaptions: boolean = false

    //for outputs, see menu.service.ts

    minimized = false
    materialFilters: MaterialFilterInput = {}

    private unsubscribe$ = new Subject<void>()
    private _accordionStyle?: AccordionStyle

    materialInput?: Nodes.Meta.MaterialInputInfo

    dialog = inject(DialogService)
    permission = inject(PermissionsService)
    sdk = inject(SdkService)
    $can = this.permission.$to
    public configMenuService = inject(ConfigMenuLegacyService)

    ngOnInit(): void {
        this.materialFilters = {hasCyclesMaterial: true}
        if (!this.$can().read.material(null, "private")) {
            this.materialFilters["public"] = false
        }

        if (window.innerWidth < Breakpoints.mobileScreen) {
            this.minimized = true
        }

        this.configMenuService.interface$.pipe(takeUntil(this.unsubscribe$)).subscribe((interfaceDescriptors) => {
            this.interfaceDescriptors = interfaceDescriptors
            this.configMenuService.updateConfigVariantColors()
        })
        this.configMenuService.uiStyle$.pipe(takeUntil(this.unsubscribe$)).subscribe((uiStyle) => {
            if (this.uiStyleOverride != null) this._uiStyle = this.uiStyleOverride
            else if (this.uiStyle != null) this._uiStyle = this.uiStyle
            else this._uiStyle = uiStyle
        })
        this.configMenuService.iconSize$.pipe(takeUntil(this.unsubscribe$)).subscribe((iconSize) => {
            this.iconSize = iconSize
        })

        this._accordionStyle = this.accordionStyle ?? "autoClose"
    }

    shouldShowInput(desc: Nodes.Meta.InputDescriptor) {
        return this.hideInputsSetByTemplate ? !desc.isSetByTemplate : true
    }

    async fetchMaterial(materialLegacyId?: number): Promise<ConfigMenuMaterialFragment | null> {
        if (materialLegacyId != null) {
            return this.sdk.gql
                .configMenuFetchMaterial({
                    materialLegacyId,
                })
                .then(({material}) => material)
        } else {
            return null
        }
    }

    private dataObjectThumbnailCache = new AsyncCacheMap<number, string | null>((dataObjectLegacyId) =>
        from(
            this.sdk.gql
                .configMenuDataObject({
                    dataObjectLegacyId,
                })
                .then(({dataObject}) => dataObject.thumbnail?.downloadUrl ?? null),
        ),
    )

    fetchThumbnailForDataObject(dataObjectLegacyId?: number): Observable<string | null> {
        if (dataObjectLegacyId != null) {
            return this.dataObjectThumbnailCache.get(dataObjectLegacyId)
        } else {
            return observableOf(null)
        }
    }

    getMaterialIcon(material: ConfigMenuMaterialFragment): Observable<ConfigMenuMaterialIconFragment | undefined> {
        return of(material.dataObjectAssignments?.[0]?.dataObject)
    }

    isConfigInput = Nodes.Meta.isConfigInput
    isMaterialInput = Nodes.Meta.isMaterialInput
    getValueForInterface = Nodes.Meta.getValueForInterface
    isNumberInput = Nodes.Meta.isNumberInput
    isBooleanInput = Nodes.Meta.isBooleanInput
    isStringInput = Nodes.Meta.isStringInput

    getMaterialId(desc: Nodes.Meta.MaterialInputInfo): number | undefined {
        if (Nodes.Meta.isMaterial(desc)) return desc.value?.materialId
        else return undefined
    }

    selectVariant(config: Nodes.Meta.ConfigInfo, variant: Nodes.Meta.VariantInfo): void {
        this.configMenuService.emitConfigSelected({config, variant})
    }

    changeParameter(desc: Nodes.Meta.InputDescriptor, value: unknown): void {
        this.configMenuService.emitParameterChanged({input: desc, value})
    }

    fetchAndEmitMaterialSelection(input: Nodes.Meta.MaterialInputInfo, materialId: number) {
        this.sdk.gql.configMenuFetchMaterial({materialLegacyId: materialId}).then(({material}) => {
            this.configMenuService.emitMaterialSelected({input, material})
        })
    }

    chooseMaterialForInput(input: Nodes.Meta.MaterialInputInfo): void {
        if (input.tags?.length) {
            this.materialFilters = {tagLegacyId: {in: input.tags.map((x) => x.tagId)}}
        } else {
            this.materialFilters = {}
        }
        this.materialInput = input
        this.dialog.selectInDialog(SelectMaterialComponent, {
            filters: this.materialFilters,
            onSelect: ({legacyId}) => {
                if (this.materialInput) {
                    this.fetchAndEmitMaterialSelection(this.materialInput, legacyId)
                }
            },
        })
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    getValueForInterfaceConfigInfo(desc: Nodes.Meta.ConfigInfo): string | undefined {
        return desc.value
    }

    onConfigGroupClicked(configGroup: Nodes.Meta.ConfigInfo) {
        if (this._accordionStyle === "autoClose") this.selectedConfigGroups.clear()

        if (this.selectedConfigGroups.has(configGroup.id)) {
            this.selectedConfigGroups.delete(configGroup.id)
        } else {
            this.selectedConfigGroups.add(configGroup.id)
        }
    }
}
