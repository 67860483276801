import {Type} from "@angular/core"
import {CombineRgbNodeComponent} from "@material-editor/components/nodes/combine-rgb-node/combine-rgb-node.component"
import {
    BrightContrastNodeComponent,
    CombineXYZNodeComponent,
    DisplacementNodeComponent,
    FresnelNodeComponent,
    GammaNodeComponent,
    HsvNodeComponent,
    ImageTextureNodeComponent,
    ImageTextureSetNodeComponent,
    InvertNodeComponent,
    MappingNodeComponent,
    MathNodeComponent,
    NormalMapNodeComponent,
    OutputNodeComponent,
    PrincipledBsdfNodeComponent,
    RgbCurvesNodeComponent,
    RgbMixNodeComponent,
    RgbNodeComponent,
    SetImageTextureNodeComponent,
    SeparateRgbNodeComponent,
    SeparateXyzNodeComponent,
    TangentNodeComponent,
    UvMapNodeComponent,
    ValueNodeComponent,
    VectorMathNodeComponent,
    EmissionNodeComponent,
    TranslucentBsdfNodeComponent,
    AddShaderNodeComponent,
} from "@material-editor/components/nodes"

export type MaterialNodeComponent =
    | MathNodeComponent
    | ImageTextureNodeComponent
    | ImageTextureSetNodeComponent
    | MappingNodeComponent
    | CombineRgbNodeComponent
    | NormalMapNodeComponent
    | OutputNodeComponent
    | UvMapNodeComponent
    | PrincipledBsdfNodeComponent
    | RgbCurvesNodeComponent
    | SetImageTextureNodeComponent
    | SeparateRgbNodeComponent
    | SeparateXyzNodeComponent
    | TangentNodeComponent
    | ValueNodeComponent
    | HsvNodeComponent
    | RgbNodeComponent
    | RgbMixNodeComponent
    | VectorMathNodeComponent
    | GammaNodeComponent
    | DisplacementNodeComponent
    | BrightContrastNodeComponent
    | FresnelNodeComponent
    | InvertNodeComponent
    | CombineXYZNodeComponent
    | EmissionNodeComponent
    | TranslucentBsdfNodeComponent
    | AddShaderNodeComponent

import {
    BrightContrastNodeType,
    CombineXYZNodeType,
    DisplacementNodeType,
    FresnelNodeType,
    GammaNodeType,
    HsvNodeType,
    ImageTextureNodeType,
    ImageTextureSetNodeType,
    InvertNodeType,
    MappingNodeType,
    MathNodeType,
    NormalMapNodeType,
    OutputNodeType,
    PrincipledBsdfNodeType,
    RgbCurvesNodeType,
    RgbMixNodeType,
    RgbNodeType,
    SetImageTextureNodeType,
    SeparateRgbNodeType,
    SeparateXyzNodeType,
    TangentNodeType,
    UVMapNodeType,
    ValueNodeType,
    VectorMathNodeType,
    EmissionNodeType,
    TranslucentBsdfNodeType,
    AddShaderNodeType,
    ScannedTransmissionNodeType,
} from "@material-editor/components/nodes"
import {CombineRGBNodeType} from "@material-editor/components/nodes/combine-rgb-node/combine-rgb-node.component"
import {MaterialEnumSetting, MaterialNodeTypeId, MaterialSocket} from "@material-editor/models/material-socket"

import {NodeTypeInfo} from "@node-editor/models/node-type-info"

export const MaterialNodeComponentTypes = [
    CombineRGBNodeType,
    HsvNodeType,
    ImageTextureNodeType,
    ImageTextureSetNodeType,
    MappingNodeType,
    MathNodeType,
    NormalMapNodeType,
    OutputNodeType,
    PrincipledBsdfNodeType,
    RgbNodeType,
    RgbCurvesNodeType,
    RgbMixNodeType,
    SetImageTextureNodeType,
    SeparateRgbNodeType,
    SeparateXyzNodeType,
    TangentNodeType,
    UVMapNodeType,
    ValueNodeType,
    VectorMathNodeType,
    GammaNodeType,
    DisplacementNodeType,
    BrightContrastNodeType,
    FresnelNodeType,
    InvertNodeType,
    CombineXYZNodeType,
    EmissionNodeType,
    TranslucentBsdfNodeType,
    AddShaderNodeType,
    ScannedTransmissionNodeType,
]

export interface MaterialNodeType extends NodeTypeInfo {
    id: MaterialNodeTypeId
    name: string
    inputs: MaterialSocket[]
    outputs: MaterialSocket[]
    settings?: MaterialEnumSetting[]
    component?: Type<
        | MathNodeComponent
        | ImageTextureNodeComponent
        | ImageTextureSetNodeComponent
        | MappingNodeComponent
        | CombineRgbNodeComponent
        | NormalMapNodeComponent
        | OutputNodeComponent
        | UvMapNodeComponent
        | PrincipledBsdfNodeComponent
        | RgbCurvesNodeComponent
        | SetImageTextureNodeComponent
        | SeparateRgbNodeComponent
        | SeparateXyzNodeComponent
        | TangentNodeComponent
        | ValueNodeComponent
        | HsvNodeComponent
        | RgbNodeComponent
        | RgbMixNodeComponent
        | VectorMathNodeComponent
        | GammaNodeComponent
        | DisplacementNodeComponent
        | BrightContrastNodeComponent
        | FresnelNodeComponent
        | InvertNodeComponent
        | CombineXYZNodeComponent
        | EmissionNodeComponent
        | TranslucentBsdfNodeComponent
        | AddShaderNodeComponent
    >
}

export const MaterialNodeTypes: MaterialNodeType[] = [
    CombineRGBNodeType,
    HsvNodeType,
    ImageTextureNodeType,
    ImageTextureSetNodeType,
    MappingNodeType,
    MathNodeType,
    NormalMapNodeType,
    OutputNodeType,
    PrincipledBsdfNodeType,
    RgbNodeType,
    RgbCurvesNodeType,
    RgbMixNodeType,
    SetImageTextureNodeType,
    SeparateRgbNodeType,
    SeparateXyzNodeType,
    TangentNodeType,
    UVMapNodeType,
    ValueNodeType,
    VectorMathNodeType,
    GammaNodeType,
    DisplacementNodeType,
    BrightContrastNodeType,
    FresnelNodeType,
    InvertNodeType,
    CombineXYZNodeType,
    EmissionNodeType,
    TranslucentBsdfNodeType,
    AddShaderNodeType,
    ScannedTransmissionNodeType,
]
