import {Currency, PriceGraphState} from "@api"
import {deserializeNodeGraph} from "@cm/lib/graph-system/node-graph"
import {PricingContext} from "@cm/lib/pricing/nodes/core"
import {FlattenPriceList} from "@cm/lib/pricing/nodes/flatten-pricelist"
import {SdkService} from "@common/services/sdk/sdk.service"

export interface PriceGraphWithContext {
    rootNode: FlattenPriceList
    pricingContext: PricingContext
}

export async function getPricingContext(sdk: SdkService, organizationId: string, originalIds: string[]): Promise<PricingContext> {
    const numPrices = 2000 //overrides the standard value of 25 and should be sufficient for all cases. Since price graphs are verified on save, this is ok for now.
    const {prices} = await sdk.gql.pricingHelperGetPrices({
        filter: {originalId: {in: originalIds}, organizationId: {equals: organizationId}},
        take: numPrices,
    })

    if (prices?.length !== originalIds.length) console.warn("Not all prices found")

    let commonCurrency: Currency | undefined = undefined
    const priceMap = new Map()

    for (const price of prices) {
        if (price === null) continue //the null comes from implementFindManyQuery, should not happen here though.
        if (!price.idFromCatalog.originalId) throw new Error("Price object is missing originalId")

        if (commonCurrency === undefined) commonCurrency = price.currency
        else if (price.currency !== commonCurrency) throw new Error(`Mismatched currencies found: ${price.currency} and ${commonCurrency}`)

        priceMap.set(price.idFromCatalog.originalId, price.price)
    }

    if (commonCurrency === undefined) throw new Error("No valid prices found to determine currency")
    if (priceMap.size < prices.length) throw new Error("Duplicate ids")

    return new PricingContext(priceMap, commonCurrency)
}

export async function loadPriceGraph(sdk: SdkService, templateId: string, state: PriceGraphState): Promise<FlattenPriceList | null> {
    const {priceGraphs} = await sdk.gql.pricingHelperGetPriceGraphs({filter: {templateId: {equals: templateId}, state: state}})

    if (priceGraphs.length > 1) throw new Error("More than one price graph found, revisions not implemented yet")
    if (priceGraphs.length === 0) return null

    const priceGraph = deserializeNodeGraph(priceGraphs[0]!.graph)
    if (!(priceGraph instanceof FlattenPriceList)) throw new Error("Deserialized node is not a FlattenPriceList")

    return priceGraph
}

export async function loadPricingData(
    sdk: SdkService,
    organizationId: string,
    templateId: string,
    state: PriceGraphState,
): Promise<PriceGraphWithContext | null> {
    const priceGraph = await loadPriceGraph(sdk, templateId, state)
    if (!priceGraph) return null

    const originalIds = priceGraph.getOriginalIdsFromCatalog()
    const pricingContext = await getPricingContext(sdk, organizationId, originalIds)

    return {
        rootNode: priceGraph,
        pricingContext: pricingContext,
    }
}
