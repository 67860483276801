import {NestedTreeControl} from "@angular/cdk/tree"
import {HttpClient} from "@angular/common/http"
import {AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatListModule} from "@angular/material/list"
import {MatTreeModule, MatTreeNestedDataSource} from "@angular/material/tree"
import {ActivatedRoute, Router} from "@angular/router"
import {MutationCreatePriceGraphInput, MutationUpdatePriceGraphInput, PriceGraphState} from "@api"
import {UnCachedNodeGraphResult} from "@cm/lib/graph-system/evaluators/uncached-node-graph-result"
import {Catalog, CatalogItem} from "@cm/lib/pricing/catalogs/catalog-interface"
import {parseXml} from "@cm/lib/pricing/catalogs/darran/maincatalog"
import {createCameoPriceGraph} from "@cm/lib/pricing/catalogs/vado/cameo-graph"
import {VadoCatalog} from "@cm/lib/pricing/catalogs/vado/catalog"
import {PricingNode} from "@cm/lib/pricing/declare-pricing-node"
import {ConditionalAmountGroupNode} from "@cm/lib/pricing/nodes/conditional-amount-group-node"
import {ConditionalAmountNode} from "@cm/lib/pricing/nodes/conditional-amount-node"
import {NamedConfiguratorVariant, PricedItem, PricingContext} from "@cm/lib/pricing/nodes/core"
import {PriceContainer} from "@cm/lib/pricing/nodes/price-container"
import {PricedItemNode} from "@cm/lib/pricing/nodes/priced-item-node"
import {checkConfigurationGroupNodes} from "@cm/lib/pricing/verification"
import {DialogSize} from "@common/models/dialogs"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {SceneViewerComponent} from "@common/components/viewers/scene-viewer/scene-viewer.component"
import {lastValueFrom, Subject, takeUntil} from "rxjs"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {downloadFromMemory} from "@app/common/helpers/utils/download-from-memory"
import {ExporterYed} from "@app/platform/helpers/simple-graph/exporter/exporter-yed"
import {NodeSettingsComponent} from "@app/pricing/components/node-settings/node-settings/node-settings.component"
import {PriceTableComponent} from "@app/pricing/components/price-table/price-table.component"
import {SdkService} from "@common/services/sdk/sdk.service"
import {PriceNodeVisualizerComponent} from "@pricing/components/node-visualizers/price-nodes-visualizer/price-node-visualizer.component"
import {getPricingContext} from "@pricing/helpers"
import {PriceGraphAccessor, PriceGraphBuilder} from "@pricing/models/pricing/price-graph"
import {ConfiguratorComponent} from "@app/common/components/viewers/configurator/configurator/configurator.component"
import {ConfigMenuComponent} from "@app/common/components/viewers/configurator/config-menu/config-menu.component"
import {getCatalogFromFileInput} from "@app/pricing/helpers/catalog"
import {updatePrices} from "@app/pricing/helpers/catalog-update"

@Component({
    standalone: true,
    selector: "cm-price-mapping",
    templateUrl: "./price-mapping.component.html",
    styleUrls: ["./price-mapping.component.scss"],
    imports: [
        RoutedDialogComponent,
        MatButtonModule,
        MatListModule,
        PriceTableComponent,
        MatTreeModule,
        PriceNodeVisualizerComponent,
        FormsModule,
        SceneViewerComponent,
        ConfigMenuComponent,
        MatIconModule,
        NodeSettingsComponent,
        ConfiguratorComponent,
    ],
})
export class PriceMappingComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("configuratorMenu") configuratorMenu!: ConfigMenuComponent
    @ViewChild("configurator") configurator!: ConfiguratorComponent

    dialogSizes = DialogSize
    modified = false

    templateId?: string
    priceGraphId?: string
    priceGraphStates = Object.values(PriceGraphState)
    priceGraphState: PriceGraphState = PriceGraphState.Draft

    priceGraphBuilder = new PriceGraphBuilder("test")

    catalog?: Catalog
    catalogNew?: Catalog
    pricingContext?: PricingContext

    currentPrices: PricedItem[] = []
    error: string = ""

    //move this to own component
    searchResults: CatalogItem[] = []
    selectedSearchItem?: CatalogItem

    treeControl = new NestedTreeControl<PricingNode>((node) => {
        if (node instanceof PricedItemNode || node instanceof PriceContainer) return node.getSubprices()
        if (node instanceof ConditionalAmountGroupNode) return node.getAmountNodes()

        throw new Error("Unexpected node type")
    })

    dataSource = new MatTreeNestedDataSource<PricingNode>()
    selectedTreeNode: PricingNode | undefined = undefined

    sdk = inject(SdkService)

    private unsubscribe$ = new Subject<void>()

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private configMenuService: ConfigMenuLegacyService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    hasChild = (_: number, node: PricingNode) => {
        if (node instanceof PricedItemNode || node instanceof PriceContainer) return node.getSubprices().length > 0
        if (node instanceof ConditionalAmountGroupNode) return node.getAmountNodes().length > 0
        if (node instanceof ConditionalAmountNode) return false
        throw new Error("Unexpected node type")
    }

    ngOnInit() {
        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => (this.templateId = params["templateuuid"]))
    }

    async ngAfterViewInit(): Promise<void> {
        /*No proper catalog management yet, I first want to understand better what we actually get from the customers. So far, we have:
        - Darran: One consistent catalog, one inconsistent excel sheet with grades (subject to format changes)
        - Vado: Two loose excel sheets (subject to format changes)*/
        try {
            this.catalog = await parseXml(await lastValueFrom(this.http.get("assets/DAR.xml", {responseType: "text"})))
            //this.catalogNew = await parseXml(await lastValueFrom(this.http.get("assets/DAR-new.xml", {responseType: "text"})))

            // const data = await lastValueFrom(this.http.get("assets/Cameo Price Export.xlsb", {responseType: "blob"}))
            // this.catalog = parseXlsb(new Uint8Array(await data.arrayBuffer())) as VadoCatalog

            this.pricingContext = this.catalog.getAllPricesAsContext()

            //this.initCameoPriceGraph()
        } catch (error) {
            console.error("Error reading catalog:", error)
        }

        if (this.templateId) {
            this.load()
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    searchCatalog(searchTerm: string): void {
        if (this.catalog) {
            this.searchResults = this.catalog.search(searchTerm)
        }
    }

    downloadGraph(): void {
        const simpleGraph = this.priceGraphBuilder.transformToSimpleGraph()
        const exporter = new ExporterYed()
        const xml = exporter.export(simpleGraph)

        downloadFromMemory(xml, "Pricegraph.graphml")
    }

    onSearchItemClick(catalogItem: CatalogItem): void {
        this.selectedSearchItem = catalogItem
    }

    onSearchItemDoubleClick(catalogItem: CatalogItem): void {
        this.selectedSearchItem = catalogItem

        const priceGraph = catalogItem.createPriceGraph((uniqueId: string) => {
            return this.priceGraphBuilder.getSubgraph(uniqueId)
        })

        this.priceGraphBuilder.addSubPriceGraph(priceGraph)
        this.dataSource.data = this.priceGraphBuilder.getNodesForDisplay()
    }

    onTreeNodeClick(node: PricingNode, event: MouseEvent) {
        this.selectedTreeNode = node
        event.stopPropagation()
    }

    onTreeNodeDoubleClick(node: PricingNode, event: MouseEvent) {
        this.priceGraphBuilder.removeDummyPriceNode(node)

        //This is the only way the tree view updated correctly; the change detection does not capture changes in the nested data structures.
        this.dataSource.data = []
        this.changeDetectorRef.detectChanges()
        this.dataSource.data = this.priceGraphBuilder.getNodesForDisplay()
        event.stopPropagation()
    }

    private canDrop(node: PricingNode): boolean {
        if (node instanceof PricedItemNode && node.parameters.condition) {
            return true
        }
        if (node instanceof ConditionalAmountNode) {
            return true
        }
        return node instanceof PriceContainer && !!node.parameters.condition
    }

    onDragOver(event: DragEvent, node: PricingNode): void {
        event.stopPropagation()
        if (!this.canDrop(node)) return
        event.preventDefault()
    }

    onDrop(event: DragEvent, node: PricingNode): void {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer === null) return
        const serializedData = event.dataTransfer.getData("text/plain")
        const data = JSON.parse(serializedData) as NamedConfiguratorVariant

        if (!(node instanceof PricedItemNode) && !(node instanceof ConditionalAmountNode) && !(node instanceof PriceContainer))
            throw new Error("Unexpected node type")
        if (!node.canAddDependency(data.groupId, data.variantId)) throw new Error("Cannot add dependency") //the drag/drop api does not allow access to this data before the drop event

        this.priceGraphBuilder.addDependency(node, data).then(() => {
            this.updateCurrentConfigurations()
        })
    }

    load(): void {
        if (!this.templateId) throw new Error("No template uuid provided")
        this.configurator.load(this.templateId, undefined) //price graph loading is triggered by loadingCompleted of the scene viewer to prevent errors.
    }

    async updateCatalog() {
        if (!this.catalog || !this.catalogNew || !this.templateId) throw new Error("Catalogs or templateId not initialized")
        const organizationId = (await this.sdk.gql.priceMappingOrganizationIdFromTemplateId({templateId: this.templateId})).template.organizationId
        updatePrices(organizationId, this.catalog, this.catalogNew, this.sdk)
    }

    async loadPriceGraph() {
        const {priceGraphs} = await this.sdk.gql.priceMappingGetPriceGraphs({filter: {templateId: {equals: this.templateId}}})
        if (priceGraphs.length > 1) throw new Error("More than one price graph found, revisions not implemented yet")
        if (priceGraphs.length === 0) return

        const priceGraph = priceGraphs[0]!

        const graph = priceGraph.graph
        this.priceGraphBuilder.initFromSerialized(graph)
        this.priceGraphId = priceGraph.id

        if (!this.catalog) {
            if (!this.templateId) {
                throw new Error("No template uuid provided")
            }
            const organizationId = (await this.sdk.gql.priceMappingOrganizationIdFromTemplateId({templateId: this.templateId})).template.organizationId
            this.pricingContext = await getPricingContext(this.sdk, organizationId, this.priceGraphBuilder.rootNode.getOriginalIdsFromCatalog())
        }
        this.dataSource.data = []
        this.changeDetectorRef.detectChanges()
        this.dataSource.data = this.priceGraphBuilder.getNodesForDisplay()
        this.priceGraphState = priceGraph.state
        console.log("price graph id", this.priceGraphId)

        this.updateCurrentConfigurations()
    }

    initCameoPriceGraph(): void {
        const cameoData = createCameoPriceGraph(this.catalog as VadoCatalog)
        cameoData.amountGroups.forEach((group) => this.priceGraphBuilder.addAmountGroupNode(group))
        cameoData.priceContainers.forEach((container) => this.priceGraphBuilder.addSubPriceGraph(container))

        this.dataSource.data = this.priceGraphBuilder.getNodesForDisplay()
    }

    async deletePriceGraph() {
        if (!this.priceGraphId) {
            throw new Error("No price graph uuid provided")
        }
        const result = await this.sdk.gql.priceMappingDeletePriceGraph({priceGraphId: this.priceGraphId})
        console.log("Delete result", result)
    }

    async initCatalogFromLocalFile(event: Event) {
        this.catalog = await getCatalogFromFileInput(event)
        this.pricingContext = this.catalog.getAllPricesAsContext()
    }

    async initNewCatalogFromLocalFile(event: Event) {
        this.catalogNew = await getCatalogFromFileInput(event)
    }

    async verifyAndSave() {
        if (!this.priceGraphBuilder.rootNode) {
            return
        }
        if (!this.templateId) {
            throw new Error("No template uuid provided")
        }

        const organizationId = await this.sdk.gql
            .priceMappingOrganizationIdFromTemplateId({templateId: this.templateId})
            .then(({template}) => template.organizationId)

        try {
            //Only save, if a pricing context can be initialized for this graph, otherwise the price computation will not work in practice.
            await getPricingContext(this.sdk, organizationId, this.priceGraphBuilder.rootNode.getOriginalIdsFromCatalog())
            checkConfigurationGroupNodes(this.priceGraphBuilder.rootNode)

            /*Check, to what extend this is actually necessary:
            - the check is *very* helpful for some graph types (Darran-style)
            - Cannot be used for Vado at the moment
            - The check must be adapted to new node conditions
            - Some nodes must be excludeable*/
            //checkMutuallyExclusiveConditions(this.priceGraphBuilder.rootNode)
            return this.save(organizationId)
        } catch (error) {
            console.error("An error occurred, did not save:", error)
        }
    }

    async save(organizationId: string) {
        const originalIdsFromCatalog = this.priceGraphBuilder.rootNode.getOriginalIdsFromCatalog()
        if (!this.priceGraphId) {
            if (!this.templateId) {
                throw new Error("No template uuid provided")
            }
            const createPriceGraphInput: MutationCreatePriceGraphInput = {
                originalIdsFromCatalog: originalIdsFromCatalog,
                templateId: this.templateId,
                organizationId: organizationId,
                state: this.priceGraphState,
                graph: this.priceGraphBuilder.rootNode.serialize(),
            }
            const {createPriceGraph} = await this.sdk.gql.priceMappingCreatePriceGraph({input: createPriceGraphInput})
            this.priceGraphId = createPriceGraph?.id
        } else {
            const updatePriceGraphInput: MutationUpdatePriceGraphInput = {
                originalIdsFromCatalog: originalIdsFromCatalog,
                priceGraphId: this.priceGraphId,
                organizationId: organizationId,
                state: this.priceGraphState,
                graph: this.priceGraphBuilder.rootNode.serialize(), //graph: {key1: "value134"},
            }
            const result = await this.sdk.gql.priceMappingUpdatePriceGraph({input: updatePriceGraphInput})
            console.log("Update result", result)
        }
    }

    updateCurrentConfigurations(): void {
        const graphAccessor = new PriceGraphAccessor(this.priceGraphBuilder.rootNode)
        graphAccessor.setCurrentConfigurations(this.configMenuService.getSelectedVariants())
        this.updatePrice()
    }

    updatePrice(): void {
        if (!this.pricingContext) throw new Error("Pricing context not initialized")
        new UnCachedNodeGraphResult(this.priceGraphBuilder.rootNode, this.pricingContext)
            .run()
            .then((result) => {
                this.currentPrices = result
                this.error = ""
            })
            .catch((error) => {
                this.error = error.message
                this.currentPrices = []
            })
    }

    nodeSelected(node: PricingNode): boolean {
        return this.selectedTreeNode === node
    }
}
