import {FlatThumbnailOptionLabels} from "@platform/models/state-labels/flat-thumbnail-option-labels"
import {TaskStateLabels} from "@platform/models/state-labels/task-state-labels"
import {AssetStateLabels} from "@platform/models/state-labels/asset-state-labels"
import {DataObjectStateLabels} from "@platform/models/state-labels/data-object-state-labels"
import {JobStateLabels} from "@platform/models/state-labels/job-state-labels"
import {JobTaskStateLabels} from "@platform/models/state-labels/job-task-state-labels"
import {MaterialStateLabels} from "@platform/models/state-labels/material-state-labels"
import {ModelStateLabels} from "@platform/models/state-labels/model-state-labels"
import {NextActorLabels} from "@platform/models/state-labels/next-actor-labels"
import {PaymentStateLabels} from "@platform/models/state-labels/payment-state-labels"
import {PictureStateLabels} from "@platform/models/state-labels/picture-state-labels"
import {StateLabel} from "@platform/models/state-labels/state-label"
import {TagTypeLabels, TagTypeOptions} from "@platform/models/state-labels/tag-type-labels"
import {TemplateStateLabels} from "@platform/models/state-labels/template-state-labels"
import {TemplateTypeLabels} from "@platform/models/state-labels/template-type-labels"
import {SystemRoleLabels} from "@platform/models/state-labels/system-role-labels"
import {ImageColorSpaceLabels} from "@platform/models/state-labels/image-color-space-labels"
import {TextureTypeLabels} from "@platform/models/state-labels/texture-type-labels"
import {ScanJobStateLabels} from "@platform/models/state-labels/scan-job-state-labels"
import {ScanSubJobStateLabels} from "@platform/models/state-labels/scan-sub-job-state-labels"
import {InvocationMethodLabels} from "@platform/models/state-labels/invocation-method-labels"
import {ProductTypeLabels} from "@app/platform/models/state-labels/product-type-labels"
import {SceneTypeLabels} from "@app/platform/models/state-labels/scene-type-labels"

export * from "@platform/models/state-labels/asset-state-labels"
export * from "@platform/models/state-labels/data-object-state-labels"
export * from "@platform/models/state-labels/flat-thumbnail-option-labels"
export * from "@platform/models/state-labels/job-state-labels"
export * from "@platform/models/state-labels/job-task-state-labels"
export * from "@platform/models/state-labels/material-state-labels"
export * from "@platform/models/state-labels/model-state-labels"
export * from "@platform/models/state-labels/next-actor-labels"
export * from "@platform/models/state-labels/payment-state-labels"
export * from "@platform/models/state-labels/picture-state-labels"
export * from "@platform/models/state-labels/state-label"
export * from "@platform/models/state-labels/tag-type-labels"
export * from "@platform/models/state-labels/template-state-labels"
export * from "@platform/models/state-labels/template-type-labels"
export * from "@platform/models/state-labels/product-type-labels"
export * from "@platform/models/state-labels/scene-type-labels"

/**
 * State labels embellish the various GQL enums describing entity states with additional properties used to create UI elements.
 */

export const BooleanLabels = (falseOption: string, trueOption: string): Map<string, StateLabel<string>> =>
    new Map([
        ["0", {label: falseOption, state: "0"}],
        ["1", {label: trueOption, state: "1"}],
    ])
export const Labels = {
    AssetState: AssetStateLabels,
    Assigned: BooleanLabels("Not assigned", "Assigned"),
    Available: BooleanLabels("Not available", "Available"),
    Completed: BooleanLabels("Not completed", "Completed"),
    DataObjectState: DataObjectStateLabels,
    Enabled: BooleanLabels("Disabled", "Enabled"),
    FlatThumbnail: FlatThumbnailOptionLabels,
    ImageColorSpace: ImageColorSpaceLabels,
    InvocationMethod: InvocationMethodLabels,
    IsPhotographer: BooleanLabels("Non-photographer", "Photographer"),
    IsRelated: BooleanLabels("Not related", "Related"),
    IsStaff: BooleanLabels("Non-staff", "Staff"),
    JobState: JobStateLabels,
    JobTaskState: JobTaskStateLabels,
    MaterialState: MaterialStateLabels,
    MeshSpecific: BooleanLabels("General", "Mesh specific"),
    ModelState: ModelStateLabels,
    NextActor: NextActorLabels,
    PaymentState: PaymentStateLabels,
    PictureState: PictureStateLabels,
    Public: BooleanLabels("Private", "Public"),
    SystemRole: SystemRoleLabels,
    TagType: TagTypeLabels,
    TagTypeOptions: TagTypeOptions,
    TaskState: TaskStateLabels,
    TemplateState: TemplateStateLabels,
    TemplateType: TemplateTypeLabels,
    ProductType: ProductTypeLabels,
    SceneType: SceneTypeLabels,
    TextureType: TextureTypeLabels,
    States: {
        Asset: AssetStateLabels,
        DataObject: DataObjectStateLabels,
        Material: MaterialStateLabels,
        Model: ModelStateLabels,
        Picture: PictureStateLabels,
        ScanJob: ScanJobStateLabels,
        ScanSubJob: ScanSubJobStateLabels,
        Template: TemplateStateLabels,
        Task: TaskStateLabels,
    },
}
