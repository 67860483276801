import {AuthenticatedUserFragment, PictureState, SystemRole} from "@api"
import {AbilityBuilder, createMongoAbility, PureAbility} from "@casl/ability"
import {authorizationContext} from "@common/helpers/auth/context"
import {ColormassEntity} from "@common/models/abilities"
import {AuthorizationSilo} from "@common/models/auth/authorization-silo"
import {Enums} from "@enums"

export const abilitiesForSilo = (silo: AuthorizationSilo | null | undefined, user: AuthenticatedUserFragment | null | undefined): PureAbility => {
    const {isStaff, isSuperuser, isFabricManufacturer, isPhotographer, ownOrganizationIds} = authorizationContext(silo, user)

    const {can: allow, cannot: forbid, build} = new AbilityBuilder(createMongoAbility)

    allow("delete", "Picture", {state: Enums.PictureState.Draft})
    allow("delete", "TaskComment", {createdBy: {id: user?.id}})

    allow("read", "Organization", {visibleInFilters: true})

    allow("read", "DataObject")

    allow("read", "Page", ["models", "materials", "assets", "pictures"])

    if (user?.memberships && user.memberships.length > 1) {
        allow("read", "Menu", ["authenticateForOrganization"])
    }

    if (isStaff) {
        allow("create", "all", "batch")
        allow("create", "DataObject")
        allow("create", "ScanJob", "testImage")
        allow("create", "MaterialAssignment")
        allow("create", "MaterialRevision")
        allow("create", "Task")
        allow("create", "Template")
        allow("create", "UserAssignment")

        allow("delete", "DataObject")
        allow("delete", "Asset")
        allow("delete", "Material")
        allow("delete", "MaterialAssignment")
        allow("delete", "MaterialMapsExport")
        allow("delete", "Model")
        allow("delete", "Organization")
        allow("delete", "Picture")
        allow("delete", "PictureRevision")
        allow("delete", "TaskComment")
        allow("delete", "Template")
        allow("delete", "TemplateRevision")
        allow("delete", "User")

        allow("update", "Asset")
        allow("update", "DataObject")
        allow("update", "Job")
        allow("update", "JobTask")
        allow("update", "Material")
        allow("update", "MaterialRevision")
        allow("update", "Menu")
        allow("update", "Model")
        allow("update", "Organization")
        allow("update", "Picture")
        allow("update", "Project")
        allow("update", "ScanJob", "params")
        allow("update", "Set")
        allow("update", "Tag")
        allow("update", "Task")
        allow("update", "TemplateRevision")
        allow("update", "TextureGroup")
        allow("update", "TextureSet")
        allow("update", "Template", ["organization", "state", "type", "public", "batch"])
        allow("update", "User")

        allow("update", ["Asset", "Material", "Model", "Organization", "Template", "User"], "galleryImage")
        allow("update", ["Asset", "DataObject", "Hdri", "JobTask", "Material", "Model", "Organization", "Picture", "Template"])

        allow("read", "Asset")
        allow("read", "Filters")
        allow("read", "Job", "tiling")
        allow("read", "Tag")
        allow("read", "Task", "public")
        allow("read", "TextureSet")
        allow("read", "all", "paymentState")
        allow("read", "Material")
        allow("read", "MaterialRevision")
        allow("read", "Menu", [
            "advancedAutoTiling",
            "archiveTask",
            "authenticate",
            "authenticateAsSystemRole",
            "authenticateForAnyOrganization",
            "batchDownloadData",
            "batchDownloadMaterials",
            "batchStartMaterialImageJobs",
            "cloudRender",
            "debugScanJob",
            "extraFilters",
            "files",
            "filterByOrganization",
            "gpuRender",
            "hdris",
            "jobFarmGroups",
            "jobFarmInstances",
            "narrowScanningDebugMenu",
            "onlineLinks",
            "operator",
            "pictureAssignmentType",
            "runGenericBatchOperations",
            "scanning",
            "tags",
        ])
        allow("read", "Model")
        allow("read", "Organization")
        allow("read", "Page", ["errorsInSnackBar", "pictures", "staffAreas"])
        allow("read", "Picture")
        allow("read", "ScanJob")
        allow("read", "Template", ["files", "templates"])

        allow("read", "Page", ["files", "hdris", "jobs", "products", "render-nodes", "scanning", "scenes", "statistics", "tags"])
    }

    if (user?.role === SystemRole.Superadmin || user?.role === SystemRole.Staff || user?.isStaff || user?.isSuperuser) {
        allow("read", "Menu", ["authenticate", "authenticateAsStaff", "authenticateForAnyOrganization"])
    }

    if (user?.role === SystemRole.Superadmin || user?.isSuperuser) {
        allow("read", "Menu", ["authenticateAsSuperadmin"])
    }

    if (isSuperuser) {
        allow("create", "Material", "overlayColor")
        allow("update", "ScanJob")
        allow("update", "ScanSubJob")
        allow("update", "User", "systemRole")
        allow("read", "Job", "jsonGraph")
        allow("read", "Template", "debug")

        allow("read", "Menu", ["jobs", "organizations", "taskTypes", "users", "statistics"])

        allow("read", "Page")
    }

    ownOrganizationIds.forEach((organizationId) => {
        allow("create", "DataObject", {"organization.id": organizationId})
        allow("create", "MaterialAssignment", {"asset.organization.id": organizationId})
        allow("create", "MaterialRevision", {"material.organization.id": organizationId})

        allow("delete", "Asset", {"organization.id": organizationId})
        allow("delete", "Material", {"organization.id": organizationId})
        allow("delete", "MaterialAssignment", {"material.organization.id": organizationId})
        allow("delete", "Model", {"organization.id": organizationId})
        allow("delete", "Organization", {id: organizationId})
        allow("delete", "PictureRevision", {"picture.organization.id": organizationId})
        allow("delete", "Template", {"organization.id": organizationId})

        allow("update", "Asset", "articleId", {"organization.id": organizationId})
        allow("update", "Asset", "name", {"organization.id": organizationId})
        allow("update", "DataObject", {"organization.id": organizationId})
        allow("update", "Material", ["articleId", "description", "info"], {"organization.id": organizationId})
        allow("update", "Material", "info", {"organization.id": organizationId})
        allow("update", "MaterialRevision", {"material.organization.id": organizationId})
        allow("update", "Model", {"organization.id": organizationId})
        allow("update", "Organization", {id: organizationId})
        allow("update", "Project", {"organization.id": organizationId})
        allow("update", "Set", {"project.organization.id": organizationId})
        allow("update", "Task", "pins", {"organization.id": organizationId})

        allow("update", ["Asset", "Material", "Model", "Template"], "galleryImage", {"organization.id": organizationId})
        allow("update", ["User"], "galleryImage", {memberships: {"organization.id": organizationId}})
        allow("update", ["Asset", "DataObject", "Hdri", "Job", "JobTask", "Material", "Model", "Picture", "Template"], {"organization.id": organizationId})

        allow("read", "Asset", "files", {"organization.id": organizationId})
        allow("read", "Material", ["outputs", "templates"], {"organization.id": organizationId})
        allow("read", "Material", ["sampleArrival"])
        allow("read", "Template", ["comment", "files", "additionalMenuItems", "templates", "transformDialog"], {"organization.id": organizationId})
        allow("read", "Model", "files", {"organization.id": organizationId})
        allow("read", "Organization", {id: organizationId})
        allow("read", "Picture", "state", {"organization.id": organizationId})
        allow("read", "Picture", "tasks", {"organization.id": organizationId})
        allow("create", "Task", {"organization.id": organizationId})
        allow("read", "Menu", "archiveTask")

        if (isFabricManufacturer) {
            allow("update", "Material", "state", {"organization.id": organizationId})
            allow("update", "Menu", "debug")
            allow("create", "MaterialRevision", {"material.organization.id": organizationId})
            allow("delete", "MaterialMapsExport")
            allow("update", "TextureSet", {"textureGroup.organization.id": organizationId})
            allow("update", "Template", ["comment", "name", "3d"], {"organization.id": organizationId})

            allow("read", "Job", "tiling", {"organization.id": organizationId})
            allow("read", "TextureSet", {"textureGroup.organization.id": organizationId})
            allow("read", "Template", ["comment", "files"], {"organization.id": organizationId})

            allow("read", "Material", ["batchDownload", "explorerAdvanced", "files", "revisions", "state", "textures"], {
                "organization.id": organizationId,
            })
            allow("read", "Material", ["explorer"])
            allow("read", "MaterialRevision", {"material.organization.id": organizationId})
            allow("read", "Menu", ["batchDownloadMaterials", "batchDownloadData", "batchStartMaterialImageJobs", "onlineLinks", "scanning", "tags"])
            allow("read", "Picture", "editor", {"organization.id": organizationId})
            allow("read", "ScanJob", {"textureSet.textureGroup.organization.id": organizationId})

            allow("read", "Page", ["products", "scenes", "tags"])
        }

        if (isPhotographer) {
            allow("create", "Template", {"organization.id": organizationId})
            allow("delete", "TemplateRevision", {"template.organization.id": organizationId})
            allow("update", "TemplateRevision", {"template.organization.id": organizationId})
            allow("read", "Job", "tiling", {"organization.id": organizationId})
            allow("read", "Menu", ["jobs", "onlineLinks"])
            allow("read", "Picture", "renderJobs")

            allow("read", "Page", ["products", "scenes"])
        }

        if (!isFabricManufacturer && !isPhotographer) {
            allow("read", "Material", "assets")
        }

        if (!isStaff) {
            forbid(["create", "read", "update", "delete"], "all", ["batch", "completion", "drawing", "paymentState", "position", "priority"])
            forbid("update", "Model", ["organization"])
            forbid("update", "Material", ["organization", "public", "sampleArrival", "type", "usage"])
            forbid("update", "Template", ["organization", "public", "state", "type"])
            forbid("update", "Picture", "state", {state: PictureState.Completed})
            forbid("update", "Picture", "nextActor", {
                state: {$in: [PictureState.Completed, PictureState.Draft, PictureState.OnHold, PictureState.Review]},
            })

            if (!isFabricManufacturer) {
                forbid("update", "Asset", "state")
                forbid("update", "Material", "state")
                forbid("update", "Model", "state")
                forbid("update", "Picture", "state")
            }
        }
    })

    return build({
        detectSubjectType: (object: ColormassEntity) => object.__typename,
    })
}
