import * as THREE from "three"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {DisplayMode, ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {SceneNodePart, TemplateNodePart} from "@template-editor/services/scene-manager.service"
import {toThreeMatrix} from "@template-editor/helpers/three-utils"
import {Vector3, Matrix4} from "@cm/lib/math"

export abstract class ThreeObject<T extends SceneNodes.SceneNode = SceneNodes.SceneNode> {
    protected renderObject: THREE.Object3D | undefined
    protected parameters: T | undefined
    protected selected = false

    constructor(
        protected threeSceneManagerService: ThreeSceneManagerService,
        protected onAsyncUpdate: () => void,
    ) {}

    protected abstract setup(sceneNode: T): boolean
    abstract dispose(final: boolean): void

    update(sceneNode: T): boolean {
        const needsUpdate = this.setup(sceneNode) !== false
        if (!this.parameters) this.onSelectionChange(this.selected) // First init
        this.parameters = sceneNode
        return needsUpdate
    }

    getRenderObject() {
        if (!this.renderObject) throw new Error("ThreeObject not initialized (forgot to call update?)")
        return this.renderObject
    }

    getSceneNode(): T {
        if (!this.parameters) throw new Error("ThreeObject not initialized (forgot to call update?)")
        return this.parameters
    }

    setSelected(selected: boolean) {
        if (this.selected !== selected) {
            this.selected = selected
            this.onSelectionChange(selected)
        }
    }

    onSelectionChange(selected: boolean) {
        // Override if needed
    }

    onDisplayModeChange(displayMode: DisplayMode) {
        // Override if needed
    }
}

export type ThreeObjectPart = {
    threeObject: ThreeObject
    part: TemplateNodePart["part"]
}

export const setThreeObjectPart = (object: THREE.Object3D, threeObject: ThreeObject, part: ThreeObjectPart["part"] = "root") => {
    object.userData.threeObjectPart = {
        threeObject,
        part,
    } as ThreeObjectPart
}

export const getThreeObjectPart = (object: THREE.Object3D): ThreeObjectPart | undefined => {
    return object.userData.threeObjectPart as ThreeObjectPart | undefined
}

export const getNextThreeObjectPart = (object: THREE.Object3D): ThreeObjectPart | undefined => {
    const threeObjectPart = getThreeObjectPart(object)
    if (threeObjectPart) return threeObjectPart
    return object.parent ? getNextThreeObjectPart(object.parent) : undefined
}

export const threeObjectPartToSceneNodePart = (threeObjectPart: ThreeObjectPart): SceneNodePart => {
    const {threeObject, part} = threeObjectPart
    return {
        sceneNode: threeObject.getSceneNode(),
        part,
    }
}

export const updateTransform = (transform: Matrix4 | THREE.Matrix4, object: THREE.Object3D) => {
    const threeMatrix = transform instanceof THREE.Matrix4 ? transform : toThreeMatrix(transform)
    if (!(threeMatrix instanceof THREE.Matrix4)) throw new Error("Expected a THREE.Matrix4")
    object.matrix = threeMatrix.clone()
    object.matrix.decompose(object.position, object.quaternion, object.scale)
    return {
        position: object.position,
        quaternion: object.quaternion,
        scale: object.scale,
    }
}

export const mathIsEqual = (a: Vector3 | Matrix4, b: Vector3 | Matrix4): boolean => {
    if (a instanceof Vector3 && b instanceof Vector3) return a.equals(b)
    if (a instanceof Matrix4 && b instanceof Matrix4) return a.equals(b)
    return a === b
}

export const mathIsWithinEpsilon = (a: Vector3 | Matrix4, b: Vector3 | Matrix4, epsilon = 1e-6): boolean => {
    if (a instanceof Vector3 && b instanceof Vector3) return a.withinEpsilon(b, epsilon)
    if (a instanceof Matrix4 && b instanceof Matrix4) return a.withinEpsilon(b, epsilon)
    return a === b
}
