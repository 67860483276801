import {ThreeCamera} from "@editor/helpers/scene/three-proxies/camera"
import {OrbitControls} from "@editor/helpers/scene/three-proxies/orbit-controls"
import {RenderView} from "@editor/helpers/scene/three-proxies/render-view"
import {Subject, switchMap} from "rxjs"

export class Navigation {
    change = new Subject<void>()
    end = new Subject<void>()

    orbitControls: OrbitControls

    constructor(
        private displayView: RenderView,
        domElem: HTMLElement,
    ) {
        this.orbitControls = new OrbitControls(domElem)
        this.orbitControls.screenSpacePanning = true
        this.orbitControls.minDistance = 20
        this.orbitControls.maxDistance = 1600

        let camera: ThreeCamera
        // in case the camera changes we need to update the orbit controls to reflect the new camera position.
        this.displayView.camera$
            .pipe(
                switchMap((_camera) => {
                    camera = _camera
                    const orbitControls = this.orbitControls
                    orbitControls.object = camera.threeCamera
                    return camera.positionAndTarget$
                }),
            )
            .subscribe(([position, target]) => {
                const orbitControls = this.orbitControls
                if (position) {
                    orbitControls.position.set(position.x, position.y, position.z)
                }
                if (target) {
                    orbitControls.target.set(target.x, target.y, target.z)
                }
                if (camera?.node) {
                    orbitControls.enablePan = camera.node.enablePanning ?? true
                    orbitControls.screenSpacePanning = camera.node.screenSpacePanning ?? true
                    orbitControls.minPolarAngle = (camera.node.minPolarAngle ?? 0) * (Math.PI / 180)
                    orbitControls.maxPolarAngle = (camera.node.maxPolarAngle ?? 180) * (Math.PI / 180)
                    orbitControls.minAzimuthAngle = (camera.node.minAzimuthAngle ?? -Infinity) * (Math.PI / 180)
                    orbitControls.maxAzimuthAngle = (camera.node.maxAzimuthAngle ?? Infinity) * (Math.PI / 180)
                    orbitControls.minDistance = camera.node.minDistance ?? 0
                    orbitControls.maxDistance = camera.node.maxDistance ?? 10000
                }
            })

        this.orbitControls.addEventListener("change", () => {
            this.change.next()
        })
        this.orbitControls.addEventListener("end", () => this.end.next())
    }

    zoomIn(amount: number): void {
        this.orbitControls.zoomIn(amount)
    }

    zoomOut(amount: number): void {
        this.orbitControls.zoomOut(amount)
    }

    enable(): void {
        this.orbitControls.enabled = true
    }

    disable(): void {
        this.orbitControls.enabled = false
    }

    destroy() {
        this.orbitControls.dispose()
    }

    set focused(focused: boolean) {
        this.orbitControls.focused = focused
    }

    get focused(): boolean {
        return this.orbitControls.focused
    }
}
