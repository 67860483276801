import * as paper from "paper"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {TilingAreaControlPointToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-control-point-item"
import {EventEmitter} from "@angular/core"
import {GridInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/grid-interpolator"
import {filter, merge, Observable, Subject, takeUntil, throttleTime} from "rxjs"
import {OperatorToolboxItemBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-item-base"
import {OperatorToolboxBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-toolbox-base"
import {TilingAreaBoundaryCurveToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-boundary-curve-item"
import {CurveInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/curve-interpolator"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {LinearInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/linear-interpolator"
import {CurveBoundaryInterpolator} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/curve-boundary-interpolator"
import {GridPoint} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/grid-mapping"
import {OperatorTiling} from "@app/textures/texture-editor/operator-stack/operators/tiling/operator-tiling"

export enum ViewMode {
    Source = "source",
    Result = "result",
}

export class TilingAreaToolboxItem extends OperatorToolboxItemBase<OperatorTiling> {
    readonly areaChanged$ = new EventEmitter<void>()
    readonly invalidateAlignment$ = new EventEmitter<void>()

    constructor(parent: OperatorToolboxBase<OperatorTiling>) {
        super(parent)

        this.viewChange.subscribe(() => this.onViewChange())

        const node = parent.operator.node

        // create corner control points
        const cp00 = this.createControlPointItem(ControlPointType.User)
        const cp10 = this.createControlPointItem(ControlPointType.User)
        const cp01 = this.createControlPointItem(ControlPointType.User)
        const cp11 = this.createControlPointItem(ControlPointType.User)
        this.identifyControlPointItems([cp00, cp01, cp10, cp11])
        this._cornerControlPointItems = new Set([cp00, cp01, cp10, cp11])

        const createCurveItem = (flipLabelSide: boolean) => {
            const curve = new TilingAreaBoundaryCurveToolboxItem(this, flipLabelSide)
            curve.clicked.subscribe((event) => this.onCurveClicked(curve, event))
            return curve
        }
        const addBoundaryCurve = (boundary: Boundary, flipLabelSide: boolean) => {
            const boundaryCurve: BoundaryCurve = {
                boundary,
                controlPoints: [],
                curveItem: createCurveItem(flipLabelSide),
            }
            boundary.curves.push(boundaryCurve)
            return boundaryCurve
        }
        const createBoundary = (direction: BoundaryDirection): Boundary => {
            return {
                direction: direction,
                curves: [],
            }
        }

        // Horizontal boundary
        const horizontalBoundary = createBoundary(BoundaryDirection.Horizontal)
        const horizontalBoundaryCurveTop = addBoundaryCurve(horizontalBoundary, false)
        this.addBoundaryCurveControlPoint(horizontalBoundaryCurveTop, {
            type: ControlPointType.User,
            t: 0,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.topLeft.positionPx),
            item: cp00,
        })
        this.addBoundaryCurveControlPoint(horizontalBoundaryCurveTop, {
            type: ControlPointType.User,
            t: 1,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.topRight.positionPx),
            item: cp10,
        })
        const horizontalBoundaryCurveBottom = addBoundaryCurve(horizontalBoundary, true)
        this.addBoundaryCurveControlPoint(horizontalBoundaryCurveBottom, {
            type: ControlPointType.User,
            t: 0,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.bottomLeft.positionPx),
            item: cp01,
        })
        this.addBoundaryCurveControlPoint(horizontalBoundaryCurveBottom, {
            type: ControlPointType.User,
            t: 1,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.bottomRight.positionPx),
            item: cp11,
        })
        node.boundaries.horizontal.controlPoints.forEach((controlPoint) => {
            const cpLo = this.addBoundaryCurveControlPoint(horizontalBoundaryCurveTop, {
                type: controlPoint.type as ControlPointType,
                t: controlPoint.loBound.normalizedCurvePosition,
                sourcePosition: Vector2.fromVector2Like(controlPoint.loBound.positionPx),
            })
            const cpHi = this.addBoundaryCurveControlPoint(horizontalBoundaryCurveBottom, {
                type: controlPoint.type as ControlPointType,
                t: controlPoint.hiBound.normalizedCurvePosition,
                sourcePosition: Vector2.fromVector2Like(controlPoint.hiBound.positionPx),
            })
            this.identifyControlPointItems([cpLo.item, cpHi.item])
        })

        // Vertical boundary
        const verticalBoundary = createBoundary(BoundaryDirection.Vertical)
        const verticalBoundaryCurveLeft = addBoundaryCurve(verticalBoundary, true)
        this.addBoundaryCurveControlPoint(verticalBoundaryCurveLeft, {
            type: ControlPointType.User,
            t: 0,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.topLeft.positionPx),
            item: cp00,
        })
        this.addBoundaryCurveControlPoint(verticalBoundaryCurveLeft, {
            type: ControlPointType.User,
            t: 1,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.bottomLeft.positionPx),
            item: cp01,
        })
        const verticalBoundaryCurveRight = addBoundaryCurve(verticalBoundary, false)
        this.addBoundaryCurveControlPoint(verticalBoundaryCurveRight, {
            type: ControlPointType.User,
            t: 0,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.topRight.positionPx),
            item: cp10,
        })
        this.addBoundaryCurveControlPoint(verticalBoundaryCurveRight, {
            type: ControlPointType.User,
            t: 1,
            sourcePosition: Vector2.fromVector2Like(node.cornerControlPoints.bottomRight.positionPx),
            item: cp11,
        })
        node.boundaries.vertical.controlPoints.forEach((controlPoint) => {
            const cpLo = this.addBoundaryCurveControlPoint(verticalBoundaryCurveLeft, {
                type: controlPoint.type as ControlPointType,
                t: controlPoint.loBound.normalizedCurvePosition,
                sourcePosition: Vector2.fromVector2Like(controlPoint.loBound.positionPx),
            })
            const cpHi = this.addBoundaryCurveControlPoint(verticalBoundaryCurveRight, {
                type: controlPoint.type as ControlPointType,
                t: controlPoint.hiBound.normalizedCurvePosition,
                sourcePosition: Vector2.fromVector2Like(controlPoint.hiBound.positionPx),
            })
            this.identifyControlPointItems([cpLo.item, cpHi.item])
        })

        this._boundaries = [horizontalBoundary, verticalBoundary]

        this._snapControlPointItem = new TilingAreaControlPointToolboxItem(this, {radius: SNAP_CIRCLE_RADIUS, color: SNAP_CIRCLE_COLOR, movable: false})
        this._snapControlPointItem.visible = false

        const applyPipe = <T>(obs: Observable<T>) =>
            obs.pipe(
                takeUntil(this.unsubscribe),
                filter(() => this.visible && !this.disabled),
            )

        this._scheduleUpdateArea$.pipe(throttleTime(20, undefined, {leading: false, trailing: true})).subscribe(() => this.updateArea())

        this.updateAll()

        merge(parent.operator.viewMode$, parent.operator.borderBlendEnabled$, parent.operator.borderBlendDistancePx$).subscribe(() => this.updateAllDisplay())

        const hotkeys = this.parentItem.operator.callback.injector.get(Hotkeys)
        applyPipe(hotkeys.addShortcut(["Delete", "Backspace"])).subscribe(() => this.deleteSelectedControlPoints())
        applyPipe(hotkeys.addShortcut(["ArrowLeft"])).subscribe(() => this.nudgeSelectedControlPoints({x: -1, y: 0}))
        applyPipe(hotkeys.addShortcut(["ArrowRight"])).subscribe(() => this.nudgeSelectedControlPoints({x: 1, y: 0}))
        applyPipe(hotkeys.addShortcut(["ArrowUp"])).subscribe(() => this.nudgeSelectedControlPoints({x: 0, y: -1}))
        applyPipe(hotkeys.addShortcut(["ArrowDown"])).subscribe(() => this.nudgeSelectedControlPoints({x: 0, y: 1}))

        this._updateSnapPosition$.pipe(throttleTime(200, undefined, {leading: false, trailing: true})).subscribe(async (event) => {
            const snapPosition = this._isDraggingControlPoint ? await this.computeSnapPosition(event.position, event.referencePosition) : undefined
            this.setSnapPosition(snapPosition)
        })
    }

    override remove() {
        super.remove()
        this._area?.remove()
        this._borderAreas.forEach((borderArea) => borderArea.remove())
        this._borderAreas = []
        this._gridLines.forEach((line) => line.remove())
    }

    computeBoundaryTValues(direction: BoundaryDirection, side: BoundarySide, borderWidthInPixels: number) {
        const borderT =
            borderWidthInPixels /
            this.getBoundaryMappedLength(direction === BoundaryDirection.Horizontal ? BoundaryDirection.Vertical : BoundaryDirection.Horizontal)
        switch (direction) {
            case BoundaryDirection.Horizontal:
                return {
                    tMinU: 0,
                    tMaxU: 1,
                    tMinV: side === BoundarySide.Low ? -borderT : 1,
                    tMaxV: side === BoundarySide.Low ? 0 : 1 + borderT,
                }
            case BoundaryDirection.Vertical:
                return {
                    tMinU: side === BoundarySide.Low ? -borderT : 1,
                    tMaxU: side === BoundarySide.Low ? 0 : 1 + borderT,
                    tMinV: 0,
                    tMaxV: 1,
                }
        }
    }

    getBoundaryMappedLength(direction: BoundaryDirection): number {
        return this._boundaries[direction].mappedLength ?? 0
    }

    getBoundaryNumControlPoints(direction: BoundaryDirection): number {
        return this._boundaries[direction].curves[0].controlPoints.length
    }

    getBoundaryPoints(direction: BoundaryDirection, side: BoundarySide, ignoreCornerControlPoints: boolean): ControlPointPosition[] {
        const isCornerControlPoint = (index: number, array: Array<unknown>) => index === 0 || index === array.length - 1
        return this._boundaries[direction].curves[side].controlPoints
            .filter((_, index, array) => !ignoreCornerControlPoints || !isCornerControlPoint(index, array))
            .map((controlPoint) => controlPoint)
    }

    getBoundarySlope(direction: BoundaryDirection, side: BoundarySide, t: number): Vector2 {
        const curve = this._boundaries[direction].curves[side]
        if (!curve.curveInterpolator) {
            throw new Error("Curve interpolator not found")
        }
        const eps = 1e-6
        const pos = curve.curveInterpolator.evaluate(t + eps)
        const neg = curve.curveInterpolator.evaluate(t - eps)
        return pos.sub(neg).normalized()
    }

    insertBoundaryPoints(direction: BoundaryDirection, type: ControlPointType, t: number, controlPoint0Position?: Vector2, controlPoint1Position?: Vector2) {
        const boundary = this._boundaries[direction]

        // insert control points
        const controlPoint0 = this.insertCurveControlPoint(boundary.curves[0], type, t)
        const controlPoint1 = this.insertCurveControlPoint(boundary.curves[1], type, t)

        // set control point positions
        if (controlPoint0Position) {
            controlPoint0.sourcePosition = controlPoint0Position
        }
        if (controlPoint1Position) {
            controlPoint1.sourcePosition = controlPoint1Position
        }

        // identify control points
        this.identifyControlPointItems([controlPoint0.item, controlPoint1.item])

        // update boundary
        this.updateBoundary(boundary)

        return {controlPoint0, controlPoint1}
    }

    removeControlPoints(controlPoints: CurveControlPoint[]) {
        const controlPointItems = new Set(controlPoints.map((controlPoint) => controlPoint.item))
        controlPointItems.forEach((controlPointItem) => this.deleteControlPointItem(controlPointItem))
        // update all
        if (controlPointItems.size > 0) {
            this.updateAll()
        }
    }

    removeControlPointsOfType(type: ControlPointType) {
        const controlPoints = this._boundaries
            .flatMap((boundary) => boundary.curves)
            .flatMap((curve) => curve.controlPoints)
            .filter((controlPoint) => controlPoint.type === type)
        this.removeControlPoints(controlPoints)
    }

    hasControlPointsOfType(type: ControlPointType) {
        return this._boundaries
            .flatMap((boundary) => boundary.curves)
            .flatMap((curve) => curve.controlPoints)
            .some((controlPoint) => controlPoint.type === type)
    }

    private nudgeSelectedControlPoints(offset: Vector2Like) {
        this._controlPointItems
            .filter((controlPointItem) => controlPointItem.selected)
            .forEach((controlPointItem) => {
                controlPointItem.position = controlPointItem.position.add(offset)
            })
    }

    private createControlPointItem(type: ControlPointType) {
        let radius: number
        let color: string
        let movable: boolean
        switch (type) {
            case ControlPointType.User:
                radius = USER_CONTROL_POINT_RADIUS
                color = USER_CONTROL_POINT_COLOR
                movable = true
                break
            case ControlPointType.Alignment:
                radius = AUTO_CONTROL_POINT_RADIUS
                color = AUTO_CONTROL_POINT_COLOR
                movable = false
                break
        }
        const controlPointItem = new TilingAreaControlPointToolboxItem(this, {radius, color, movable})
        controlPointItem.positionChanged.subscribe((event) => this.onControlPointPositionChanged(controlPointItem, event))
        controlPointItem.dragging.subscribe(() => this.onControlPointDragging(controlPointItem))
        controlPointItem.dragFinished.subscribe(() => this.onControlPointDragFinished(controlPointItem))
        controlPointItem.selectedChange.subscribe((selected) => {
            // inform all identified points about the selection change
            const identifiedControlPointItems = this.getIdentifiedControlPointItems(controlPointItem)
            identifiedControlPointItems.forEach(
                (controlPointItem) => (controlPointItem.color = selected ? USER_CONTROL_POINT_COLOR_IDENTIFIED_SELECTED : USER_CONTROL_POINT_COLOR),
            )
        })
        this._controlPointItems.push(controlPointItem)
        return controlPointItem
    }

    private addBoundaryCurveControlPoint(
        boundaryCurve: BoundaryCurve,
        pointInfo: {
            type: ControlPointType
            t: number
            sourcePosition: Vector2
            item?: TilingAreaControlPointToolboxItem
        },
    ) {
        const item = pointInfo.item ?? this.createControlPointItem(pointInfo.type)
        const controlPoint: CurveControlPoint = {...pointInfo, item, curve: boundaryCurve}
        const insertionIndex = boundaryCurve.controlPoints.findIndex((controlPoint) => controlPoint.t > pointInfo.t)
        boundaryCurve.controlPoints.splice(insertionIndex === -1 ? boundaryCurve.controlPoints.length : insertionIndex, 0, controlPoint)
        const controlPoints = this._controlPointByItem.get(controlPoint.item)
        if (!controlPoints) {
            this._controlPointByItem.set(controlPoint.item, new Set([controlPoint]))
        } else {
            controlPoints.add(controlPoint)
        }
        return controlPoint
    }

    private removeAlignment() {
        this.removeControlPointsOfType(ControlPointType.Alignment)
        this.invalidateAlignment$.emit()
    }

    private onControlPointPositionChanged(controlPointItem: TilingAreaControlPointToolboxItem, event: {delta: Vector2}) {
        const viewMode = this.parentItem.operator.viewMode$.value

        // remove all alignment when moving user control point
        this.removeAlignment()

        // update all boundaries containing this control point
        const controlPoints = this._controlPointByItem.get(controlPointItem)
        if (controlPoints) {
            controlPoints.forEach((controlPoint) => {
                switch (viewMode) {
                    case ViewMode.Source:
                        controlPoint.sourcePosition = controlPoint.sourcePosition.add(event.delta)
                        break
                    case ViewMode.Result:
                        controlPoint.sourcePosition = controlPoint.sourcePosition.add(event.delta) // TODO project delta to source space
                        break
                    default:
                        throw new Error("Invalid view mode")
                }
                this.updateBoundary(controlPoint.curve.boundary)
            })
        }
    }

    private onControlPointDragging(controlPointItem: TilingAreaControlPointToolboxItem) {
        this._isDraggingControlPoint = true
        this.triggerUpdateSnapPosition(controlPointItem)
        this.showSnapRadius(controlPointItem.position)
    }

    private triggerUpdateSnapPosition(controlPointItem: TilingAreaControlPointToolboxItem) {
        const controlPoints = this._controlPointByItem.get(controlPointItem)
        if (!controlPoints || controlPoints.size <= 0) {
            throw new Error("Control point not found")
        }
        const controlPoint = controlPoints.values().next().value
        // find most recently used identified control point item
        const identifiedControlPointItems = Array.from(this.getIdentifiedControlPointItems(controlPointItem))
        const referenceControlPointItem = identifiedControlPointItems
            .filter((controlPointItem) => controlPointItem.lastTouchedTimeStamp)
            .sort((a, b) => b.lastTouchedTimeStamp - a.lastTouchedTimeStamp)
            .find(() => true)
        this._snapSourceAvailable = referenceControlPointItem !== undefined
        if (referenceControlPointItem) {
            referenceControlPointItem.color = USER_CONTROL_POINT_COLOR_SNAP_REFERENCE
            // retrieve reference control point
            const referenceControlPoints = this._controlPointByItem.get(referenceControlPointItem)
            if (!referenceControlPoints || referenceControlPoints.size <= 0) {
                throw new Error("Reference control point not found")
            }
            const referenceControlPoint = referenceControlPoints.values().next().value
            this._updateSnapPosition$.next({position: controlPoint.sourcePosition, referencePosition: referenceControlPoint.sourcePosition})
        }
    }

    private onControlPointDragFinished(controlPointItem: TilingAreaControlPointToolboxItem) {
        const currentSnapPosition = this._currentSnapPosition
        if (currentSnapPosition) {
            const controlPoints = this._controlPointByItem.get(controlPointItem)
            if (controlPoints) {
                controlPoints.forEach((controlPoint) => {
                    controlPoint.sourcePosition = currentSnapPosition
                    this.updateBoundary(controlPoint.curve.boundary)
                })
            }
            this.setSnapPosition(undefined)
        }
        this._isDraggingControlPoint = false
        this.showSnapRadius(undefined)
    }

    private showSnapRadius(position: Vector2 | undefined) {
        const snapEnabled = this.parentItem.operator.snapEnabled$.value && this._snapSourceAvailable
        if (position && snapEnabled) {
            if (!this._snapAreaPath) {
                this._snapAreaPath = this.createSnapAreaPath(
                    this.parentItem.operator.snapDistancePx$.value * 1.5,
                    this.parentItem.operator.snapDistancePenalty$.value,
                )
            }
            this._snapAreaPath.position = new paper.Point(position)
        } else {
            this._snapAreaPath?.remove()
            this._snapAreaPath = undefined
        }
    }

    private setSnapPosition(position: Vector2 | undefined) {
        this._currentSnapPosition = position
        if (this._currentSnapPosition && this._isDraggingControlPoint) {
            this._snapControlPointItem.position = this._currentSnapPosition
            this._snapControlPointItem.visible = true
        } else {
            this._snapControlPointItem.visible = false
        }
    }

    private onCurveClicked(curveItem: TilingAreaBoundaryCurveToolboxItem, event: {position: Vector2; t: number}) {
        // remove all alignment when adding user control points
        this.removeAlignment()

        // find boundary and insert control points
        const boundary = this._boundaries.find((boundary) => boundary.curves.find((boundaryCurve) => boundaryCurve.curveItem === curveItem))
        if (!boundary) {
            throw new Error("Boundary curve not found")
        }
        const {controlPoint0, controlPoint1} = this.insertBoundaryPoints(boundary.direction, ControlPointType.User, event.t)
        // mark the control point that was clicked as being touched
        switch (curveItem) {
            case boundary.curves[0].curveItem:
                controlPoint0.item.markTouched()
                break
            case boundary.curves[1].curveItem:
                controlPoint1.item.markTouched()
                break
        }
    }

    private insertCurveControlPoint(boundaryCurve: BoundaryCurve, type: ControlPointType, t: number) {
        return this.addBoundaryCurveControlPoint(boundaryCurve, {
            type: type,
            t,
            sourcePosition: boundaryCurve.curveInterpolator!.evaluate(t),
            item: this.createControlPointItem(type),
        })
    }

    private identifyControlPointItems(controlPointItems: TilingAreaControlPointToolboxItem[]) {
        for (const controlPointItem of controlPointItems) {
            for (const otherPoint of controlPointItems) {
                if (controlPointItem === otherPoint) {
                    continue
                }
                const identifiedControlPointItems = this._identifiedControlPointItemsByControlPointItem.get(controlPointItem)
                if (!identifiedControlPointItems) {
                    this._identifiedControlPointItemsByControlPointItem.set(controlPointItem, new Set([otherPoint]))
                } else {
                    identifiedControlPointItems.add(otherPoint)
                }
            }
        }
    }

    private getIdentifiedControlPointItems(controlPointItem: TilingAreaControlPointToolboxItem): Set<TilingAreaControlPointToolboxItem> {
        const identifiedControlPointItems = this._identifiedControlPointItemsByControlPointItem.get(controlPointItem)
        if (!identifiedControlPointItems) {
            return new Set()
        }
        return identifiedControlPointItems
    }

    private deleteSelectedControlPoints() {
        const controlPointItemsToDelete = new Set<TilingAreaControlPointToolboxItem>()
        const selectedControlPointItems = this._controlPointItems.filter(
            (controlPointItem) => controlPointItem.selected && !this._cornerControlPointItems.has(controlPointItem),
        )
        // delete identified points too
        selectedControlPointItems.forEach((controlPointItem) => {
            controlPointItemsToDelete.add(controlPointItem)
            const identifiedControlPoints = this.getIdentifiedControlPointItems(controlPointItem)
            identifiedControlPoints.forEach((identifiedControlPointItem) => controlPointItemsToDelete.add(identifiedControlPointItem))
        })
        // delete control points
        for (const controlPointItem of controlPointItemsToDelete) {
            this.deleteControlPointItem(controlPointItem)
        }
        if (controlPointItemsToDelete.size > 0) {
            // remove all fine adjustments when removing user control points
            this.removeAlignment()

            // update all
            this.updateAll()
        }
    }

    private deleteControlPointItem(controlPointItem: TilingAreaControlPointToolboxItem) {
        const controlPoints = this._controlPointByItem.get(controlPointItem)
        if (controlPoints) {
            controlPoints.forEach((controlPoint) => {
                const index = controlPoint.curve.controlPoints.findIndex((controlPoint) => controlPoint.item === controlPointItem)
                if (index !== -1) {
                    controlPoint.curve.controlPoints.splice(index, 1)
                }
            })
        }
        this._controlPointByItem.delete(controlPointItem)
        controlPointItem.remove()
    }

    updateAll() {
        this._boundaries.forEach((boundary) => this.updateBoundary(boundary))
        this.updateArea()
    }

    private updateAllDisplay() {
        this._boundaries.forEach((boundary) => this.updateBoundaryDisplay(boundary))
        this.updateAreaDisplay()
    }

    private updateBoundary(boundary: Boundary) {
        // update all curves
        boundary.curves.forEach((curve) => this.updateCurveInterpolator(curve))
        const curveCumulativeArcLengths = boundary.curves.map((curve) => this.computeCurveCumulativeArcLengths(curve))

        // compute curve arc lengths
        const curveArcLengths = curveCumulativeArcLengths.map((curveCumulativeArcLength) => curveCumulativeArcLength[curveCumulativeArcLength.length - 1])

        // compute average arc length
        boundary.mappedLength = Math.ceil(
            curveCumulativeArcLengths.reduce((sum, curveInfo, index) => sum + curveArcLengths[index], 0) / curveCumulativeArcLengths.length,
        )

        // no normalization if any curve has zero length
        const containsZeroArcLength = curveArcLengths.some((arcLength) => arcLength === 0)
        if (!containsZeroArcLength) {
            // adjust control points t-values to the average of all identified control points
            const tValues = curveCumulativeArcLengths.map((curveCumulativeArcLength, index) =>
                curveCumulativeArcLength.map((arcLength) => arcLength / curveArcLengths[index]),
            )
            for (let i = 0; i < tValues[0].length; i++) {
                const avgT = tValues.reduce((sum, t) => sum + t[i], 0) / tValues.length
                boundary.curves.forEach((curve) => {
                    const controlPoint = curve.controlPoints[i]
                    controlPoint.t = avgT
                })
            }
        }

        // update curve interpolator again with new t-values
        boundary.curves.forEach((curve) => this.updateCurveInterpolator(curve))

        this.invalidateArea()
    }

    private updateBoundaryDisplay(boundary: Boundary) {
        // update display curves
        boundary.curves.forEach((curve) => this.updateDisplayCurve(curve))
    }

    private updateCurveInterpolator(curve: BoundaryCurve) {
        const controlPointPositions = curve.controlPoints.map((controlPoint) => controlPoint.sourcePosition)
        const controlPointTValues = curve.controlPoints.map((controlPoint) => controlPoint.t)
        curve.curveInterpolator = new LinearInterpolator(controlPointPositions, controlPointTValues)
    }

    private computeCurveCumulativeArcLengths(curve: BoundaryCurve): number[] {
        if (!curve.curveInterpolator) {
            throw new Error("Curve interpolator not found")
        }
        const numSegments = curve.controlPoints.length - 1
        const cumulativeControlPointArcLengths = new Array<number>(numSegments + 1)
        cumulativeControlPointArcLengths[0] = 0
        for (let i = 0; i < numSegments; i++) {
            const tStart = curve.controlPoints[i].t
            const tEnd = curve.controlPoints[i + 1].t
            let segmentLength = 0
            let lastPosition: Vector2 | undefined
            for (let j = 0; j <= this.numGridPointSubdivisionsPerSegment; j++) {
                const t = tStart + (tEnd - tStart) * (j / this.numGridPointSubdivisionsPerSegment)
                const position = curve.curveInterpolator.evaluate(t)
                if (lastPosition) {
                    segmentLength += Vector2.distance(lastPosition, position)
                }
                lastPosition = position
            }
            cumulativeControlPointArcLengths[i + 1] = cumulativeControlPointArcLengths[i] + segmentLength
        }

        return cumulativeControlPointArcLengths
    }

    private updateDisplayCurve(curve: BoundaryCurve) {
        const viewMode = this.parentItem.operator.viewMode$.value

        const getViewModePosition = (controlPoint: CurveControlPoint) => {
            switch (viewMode) {
                case ViewMode.Source:
                    return controlPoint.sourcePosition
                case ViewMode.Result: {
                    const mappedPosition = controlPoint.t * curve.boundary.mappedLength!
                    switch (curve.boundary.direction) {
                        case BoundaryDirection.Horizontal:
                            return new Vector2(mappedPosition, curve === curve.boundary.curves[0] ? 0 : this._resultSize.y)
                        case BoundaryDirection.Vertical:
                            return new Vector2(curve === curve.boundary.curves[0] ? 0 : this._resultSize.x, mappedPosition)
                        default:
                            throw new Error("Invalid boundary direction")
                    }
                }
                default:
                    throw new Error("Invalid view mode")
            }
        }

        // set control point positions
        curve.controlPoints.forEach((controlPoint) => {
            const position = getViewModePosition(controlPoint)
            controlPoint.item.setPosition(position, false)
        })

        const controlPointDisplayPositions = curve.controlPoints.map((controlPoint) => getViewModePosition(controlPoint))
        const controlPointTValues = curve.controlPoints.map((controlPoint) => controlPoint.t)
        const curveDisplayInterpolator = new LinearInterpolator(controlPointDisplayPositions, controlPointTValues)

        const numSegments = curve.controlPoints.length - 1
        const numCurvePoints = numSegments * this.numGridPointSubdivisionsPerSegment + 1
        const curveTValues = new Array<number>(numCurvePoints)
        const curveDisplayPoints = new Array<Vector2>(numCurvePoints)
        for (let i = 0; i < numSegments; i++) {
            const tStart = curve.controlPoints[i].t
            const tEnd = curve.controlPoints[i + 1].t
            for (let j = 0; j < this.numGridPointSubdivisionsPerSegment; j++) {
                const t = tStart + (tEnd - tStart) * (j / this.numGridPointSubdivisionsPerSegment)
                const index = i * this.numGridPointSubdivisionsPerSegment + j
                curveTValues[index] = t
                curveDisplayPoints[index] = curveDisplayInterpolator.evaluate(t)
            }
        }
        // add last point
        curveTValues[numCurvePoints - 1] = 1
        curveDisplayPoints[numCurvePoints - 1] = curveDisplayInterpolator.evaluate(1)
        // set curve points
        curve.curveItem.setCurvePoints(curveDisplayPoints, curveTValues)
    }

    private invalidateArea() {
        this._needUpdateArea = true
        this._scheduleUpdateArea$.next()
    }

    private updateArea() {
        if (!this._needUpdateArea) {
            return
        }
        this._needUpdateArea = false

        const mappedWidth = this._boundaries[BoundaryDirection.Horizontal].mappedLength
        const mappedHeight = this._boundaries[BoundaryDirection.Vertical].mappedLength
        if (mappedWidth === undefined || mappedHeight === undefined) {
            throw new Error("Invalid result size")
        }
        this._resultSize = new Vector2(mappedWidth, mappedHeight)

        const topCurveInterpolator = this._boundaries[BoundaryDirection.Horizontal].curves[0].curveInterpolator
        const bottomCurveInterpolator = this._boundaries[BoundaryDirection.Horizontal].curves[1].curveInterpolator
        const leftCurveInterpolator = this._boundaries[BoundaryDirection.Vertical].curves[0].curveInterpolator
        const rightCurveInterpolator = this._boundaries[BoundaryDirection.Vertical].curves[1].curveInterpolator
        if (!topCurveInterpolator || !bottomCurveInterpolator || !leftCurveInterpolator || !rightCurveInterpolator) {
            throw new Error("Curve interpolator not found")
        }
        this._gridInterpolator = new CurveBoundaryInterpolator(topCurveInterpolator, bottomCurveInterpolator, leftCurveInterpolator, rightCurveInterpolator)
        this.updateAllDisplay()

        this.areaChanged$.emit()
    }

    private updateAreaDisplay() {
        const viewMode = this.parentItem.operator.viewMode$.value

        this.beginPaperCreation()

        this._area?.remove()
        this._borderAreas.forEach((borderArea) => borderArea.remove())
        this._borderAreas = []
        this._gridLines.forEach((line) => line.remove())

        const getViewModePosition = (gridPoint: GridPoint): Vector2Like => {
            switch (viewMode) {
                case ViewMode.Source:
                    return gridPoint.sourcePixel
                case ViewMode.Result:
                    return gridPoint.targetPixel
                default:
                    throw new Error("Invalid view mode")
            }
        }

        const numGridPointSubdivisions = this.getNumGridPointSubdivisions()

        const borderBlendDistancePx = Math.round(this.parentItem.operator.borderBlendDistancePx$.value)
        if (this.parentItem.operator.borderBlendEnabled$.value && borderBlendDistancePx > 0) {
            const createBorderAreaDisplay = (direction: BoundaryDirection, side: BoundarySide) => {
                const tValues = this.computeBoundaryTValues(direction, side, borderBlendDistancePx)
                const borderTessellatedGridLines = this.computeGridPoints(
                    {
                        numSteps: direction === BoundaryDirection.Horizontal ? numGridPointSubdivisions.x : 2,
                        tMin: tValues.tMinU,
                        tMax: tValues.tMaxU,
                    },
                    {
                        numSteps: direction === BoundaryDirection.Vertical ? numGridPointSubdivisions.y : 2,
                        tMin: tValues.tMinV,
                        tMax: tValues.tMaxV,
                    },
                )

                // create border area
                const borderPositions: Vector2Like[] = []
                borderTessellatedGridLines[0].forEach((point) => borderPositions.push(getViewModePosition(point)))
                borderTessellatedGridLines.forEach((line) => borderPositions.push(getViewModePosition(line[line.length - 1])))
                Array.from(borderTessellatedGridLines[borderTessellatedGridLines.length - 1])
                    .reverse()
                    .forEach((point) => borderPositions.push(getViewModePosition(point)))
                Array.from(borderTessellatedGridLines.map((line) => line[0]))
                    .reverse()
                    .forEach((point) => borderPositions.push(getViewModePosition(point)))
                const borderArea = new paper.Path(borderPositions)
                borderArea.closed = true
                borderArea.fillColor = new paper.Color(COLOR_BORDER_AREA)
                borderArea.fillColor.alpha = 0.1
                borderArea.sendToBack()
                return borderArea
            }
            this._borderAreas.push(createBorderAreaDisplay(BoundaryDirection.Horizontal, BoundarySide.Low))
            this._borderAreas.push(createBorderAreaDisplay(BoundaryDirection.Horizontal, BoundarySide.High))
            this._borderAreas.push(createBorderAreaDisplay(BoundaryDirection.Vertical, BoundarySide.Low))
            this._borderAreas.push(createBorderAreaDisplay(BoundaryDirection.Vertical, BoundarySide.High))
        }

        const tessellatedGridLinesH = this.computeGridPoints({numSteps: numGridPointSubdivisions.x, tMin: 0, tMax: 1}, {numSteps: 2, tMin: 0, tMax: 1})
        const tessellatedGridLinesV = this.computeGridPoints({numSteps: 2, tMin: 0, tMax: 1}, {numSteps: numGridPointSubdivisions.y, tMin: 0, tMax: 1})

        // create area
        const boundaryControlPointPositions: Vector2Like[] = []
        tessellatedGridLinesH[0].forEach((point) => boundaryControlPointPositions.push(getViewModePosition(point)))
        tessellatedGridLinesV.forEach((line) => boundaryControlPointPositions.push(getViewModePosition(line[line.length - 1])))
        Array.from(tessellatedGridLinesH[tessellatedGridLinesH.length - 1])
            .reverse()
            .forEach((point) => boundaryControlPointPositions.push(getViewModePosition(point)))
        Array.from(tessellatedGridLinesV.map((line) => line[0]))
            .reverse()
            .forEach((point) => boundaryControlPointPositions.push(getViewModePosition(point)))
        this._area = new paper.Path(boundaryControlPointPositions)
        this._area.closed = true
        this._area.fillColor = new paper.Color(COLOR_AREA)
        this._area.fillColor.alpha = 0.1
        this._area.sendToBack()

        // control point alignment helper lines
        const intermediateControlPointsH = this.getBoundaryPoints(BoundaryDirection.Horizontal, BoundarySide.Low, true)
        const intermediateControlPointsV = this.getBoundaryPoints(BoundaryDirection.Vertical, BoundarySide.Low, true)
        const createGridLine = (tessellatedGridLine: GridPoint[][]) => {
            const linePoints = tessellatedGridLine.flatMap((points) => points.map((point) => getViewModePosition(point)))
            const line = new paper.Path(linePoints)
            line.strokeColor = new paper.Color(COLOR_AREA)
            line.strokeColor.alpha = 0.7
            line.sendToBack()
            this._gridLines.push(line)
        }
        // horizontal grid lines
        const intermediateUserControlPointsV = intermediateControlPointsV.filter((controlPoint) => controlPoint.type === ControlPointType.User)
        for (const controlPoint of intermediateUserControlPointsV) {
            const tessellatedGridLine = this.computeGridPoints(
                {numSteps: intermediateControlPointsH.length + 2, tMin: 0, tMax: 1},
                {
                    numSteps: 1,
                    tMin: controlPoint.t,
                    tMax: controlPoint.t,
                },
            )
            createGridLine(tessellatedGridLine)
        }
        // vertical grid lines
        const intermediateUserControlPointsH = intermediateControlPointsH.filter((controlPoint) => controlPoint.type === ControlPointType.User)
        for (const controlPoint of intermediateUserControlPointsH) {
            const tessellatedGridLine = this.computeGridPoints(
                {numSteps: 1, tMin: controlPoint.t, tMax: controlPoint.t},
                {
                    numSteps: intermediateControlPointsH.length + 2,
                    tMin: 0,
                    tMax: 1,
                },
            )
            createGridLine(tessellatedGridLine)
        }

        this.updateZoomDependent()
    }

    get resultSize(): Vector2 {
        this.updateArea()
        return this._resultSize
    }

    async computeSnapPosition(position: Vector2, referencePosition: Vector2): Promise<Vector2 | undefined> {
        return await this.parentItem.operator.computeSnapPosition(position, referencePosition)
    }

    private updateZoomDependent() {
        this._gridLines.forEach((line) => (line.strokeWidth = window.devicePixelRatio / this.zoomLevel))
    }

    private onViewChange() {
        this.updateZoomDependent()
    }

    getNumGridPointSubdivisionsForDirection(direction: BoundaryDirection) {
        const maxPixelsPerGridSegment = 128 // minimum number of pixels per grid segment
        const minGridSegmentsPerControlSegment = 1 // minimum number of grid segments per control segment
        return (
            1 +
            Math.max(
                Math.ceil(this.getBoundaryMappedLength(direction) / maxPixelsPerGridSegment),
                (this.getBoundaryNumControlPoints(direction) - 1) * minGridSegmentsPerControlSegment,
            )
        )
    }

    getNumGridPointSubdivisions() {
        return {
            x: this.getNumGridPointSubdivisionsForDirection(BoundaryDirection.Horizontal),
            y: this.getNumGridPointSubdivisionsForDirection(BoundaryDirection.Vertical),
        }
    }

    computeGridPoints(uSubDiv: SubDivisionDesc, vSubDiv: SubDivisionDesc): GridPoint[][] {
        if (uSubDiv.numSteps < 1 || vSubDiv.numSteps < 1) {
            throw new Error("Invalid number of steps")
        }
        const resultSize = this.resultSize
        const gridPoints = []
        for (let y = 0; y < vSubDiv.numSteps; y++) {
            const ty = vSubDiv.numSteps === 1 ? vSubDiv.tMin : vSubDiv.tMin + ((vSubDiv.tMax - vSubDiv.tMin) * y) / (vSubDiv.numSteps - 1)
            const linePoints: GridPoint[] = []
            for (let x = 0; x < uSubDiv.numSteps; x++) {
                const tx = uSubDiv.numSteps === 1 ? uSubDiv.tMin : uSubDiv.tMin + ((uSubDiv.tMax - uSubDiv.tMin) * x) / (uSubDiv.numSteps - 1)
                const targetPixel = new Vector2(tx, ty).mulInPlace(resultSize)
                const sourcePixel = this.computeSourcePosition(targetPixel)
                linePoints.push({
                    sourcePixel,
                    targetPixel,
                })
            }
            gridPoints.push(linePoints)
        }
        return gridPoints
    }

    private computeSourcePosition(targetPosition: Vector2): Vector2 {
        this.updateArea()
        if (!this._gridInterpolator) {
            throw new Error("Grid interpolator not found")
        }
        if (this.resultSize.x <= 0 || this.resultSize.y <= 0) {
            return new Vector2(0, 0)
        }
        const normalizedTargetPosition = targetPosition.div(this.resultSize)
        return this._gridInterpolator.interpolate(normalizedTargetPosition)
    }

    private createSnapAreaPath(radius: number, distancePenalty: number) {
        this.beginPaperCreation()
        const position = new paper.Point(0, 0)
        const path = new paper.Path.Circle({
            center: position,
            radius: radius,
        })
        const stops: paper.GradientStop[] = []
        const numStops = 10
        for (let i = 0; i < numStops; i++) {
            const t = i / (numStops - 1)
            const alphaScale = 0.2
            const color = new paper.Color(SNAP_AREA_COLOR)
            color.alpha = alphaScale * Math.pow(1 - t, 1 + 4 * (distancePenalty - 0.2)) //distancePenalty > 0.5 ? 1 + 4 * (distancePenalty - 0.5) : )
            stops.push(new paper.GradientStop(color, t))
        }
        const gradient = new paper.Gradient()
        gradient.stops = stops
        gradient.radial = true
        path.fillColor = new paper.Color({
            gradient,
            origin: path.position,
            destination: path.bounds.rightCenter,
        })
        return path
    }

    private numGridPointSubdivisionsPerSegment = 1 // increase this when using curve interpolators other than linear
    private _area?: paper.Path
    private _borderAreas: paper.Path[] = []
    private _gridLines: paper.Path[] = []
    private _boundaries: [Boundary, Boundary]
    private _controlPointItems: TilingAreaControlPointToolboxItem[] = []
    private _cornerControlPointItems: Set<TilingAreaControlPointToolboxItem>
    private _identifiedControlPointItemsByControlPointItem = new Map<TilingAreaControlPointToolboxItem, Set<TilingAreaControlPointToolboxItem>>()
    private _controlPointByItem = new Map<TilingAreaControlPointToolboxItem, Set<CurveControlPoint>>()
    private _snapControlPointItem: TilingAreaControlPointToolboxItem
    private _snapAreaPath?: paper.Path
    private _gridInterpolator?: GridInterpolator
    private _resultSize = new Vector2(0, 0)

    private _needUpdateArea = false
    private _scheduleUpdateArea$ = new Subject<void>()
    private _updateSnapPosition$ = new Subject<{position: Vector2; referencePosition: Vector2}>()
    private _currentSnapPosition?: Vector2
    private _isDraggingControlPoint = false
    private _snapSourceAvailable = false
}

export type ControlPointPosition = {
    type: ControlPointType
    t: number
    sourcePosition: Vector2
}

export enum BoundaryDirection {
    Horizontal = 0,
    Vertical = 1,
}

export enum BoundarySide {
    Low = 0,
    High = 1,
}

export enum ControlPointType {
    User = "user",
    Alignment = "alignment",
}

type Boundary = {
    direction: BoundaryDirection
    curves: BoundaryCurve[]
    mappedLength?: number
}

type BoundaryCurve = {
    boundary: Boundary
    controlPoints: CurveControlPoint[]
    curveItem: TilingAreaBoundaryCurveToolboxItem
    curveInterpolator?: CurveInterpolator
}

export type CurveControlPoint = ControlPointPosition & {
    curve: BoundaryCurve
    item: TilingAreaControlPointToolboxItem
}

export type SubDivisionDesc = {
    tMin: number
    tMax: number
    numSteps: number
}

const COLOR_AREA = "red"
const COLOR_BORDER_AREA = "blue"
const SNAP_AREA_COLOR = "darkgreen"
const SNAP_CIRCLE_COLOR = "lightgreen"
const SNAP_CIRCLE_RADIUS = 10
const USER_CONTROL_POINT_COLOR = "red"
const USER_CONTROL_POINT_COLOR_IDENTIFIED_SELECTED = "pink"
const USER_CONTROL_POINT_COLOR_SNAP_REFERENCE = SNAP_CIRCLE_COLOR
const USER_CONTROL_POINT_RADIUS = 10
const AUTO_CONTROL_POINT_COLOR = "blue"
const AUTO_CONTROL_POINT_RADIUS = 2
