import {DatePipe} from "@angular/common"
import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatProgressBar} from "@angular/material/progress-bar"
import {MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTableModule} from "@angular/material/table"
import {MatTooltipModule} from "@angular/material/tooltip"
import {AssociatedJobsTableItemFragment, ContentTypeModel, JobState} from "@api"
import {IsDefined} from "@cm/lib/utils/filter"
import {OverflowableTextComponent} from "@common/components/data"
import {TableCellComponent} from "@common/components/tables/table-cell/table-cell.component"
import {EntityMetadataComponent} from "@common/components/entity/entity-metadata/entity-metadata.component"
import {FullPageFeedbackComponent} from "@common/components/full-page-feedback/full-page-feedback.component"
import {keepCheckingRunningJobs$} from "@common/helpers/jobs"
import {AuthService} from "@common/services/auth/auth.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {TippyDirective} from "@ngneat/helipopper"
import {ExecutionPlanTooltipComponent} from "@platform/components/jobs/execution-plan-tooltip/execution-plan-tooltip.component"
import {JobStateTooltipComponent} from "@platform/components/jobs/job-state-tooltip/job-state-tooltip.component"
import {JobTaskThumbnailComponent} from "@platform/components/jobs/job-task-thumbnail/job-task-thumbnail.component"
import {JobThumbnailComponent} from "@platform/components/jobs/job-thumbnail/job-thumbnail.component"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"
import {LoadedData} from "@platform/models/data"
import {DataLoaderService} from "@platform/services/data"
import {startWith, switchMap} from "rxjs"
import {PLACEHOLDER_ITEMS} from "@platform/models/data/constants"
import {TabStateService} from "@common/services/tab-state/tab-state.service"

@Component({
    selector: "cm-associated-jobs-table",
    standalone: true,
    imports: [
        DatePipe,
        FullPageFeedbackComponent,
        MatCell,
        MatCellDef,
        MatColumnDef,
        MatHeaderCell,
        MatHeaderRow,
        MatHeaderRowDef,
        MatProgressBar,
        MatRow,
        MatRowDef,
        MatTableModule,
        TableCellComponent,
        OverflowableTextComponent,
        MatTooltipModule,
        JobThumbnailComponent,
        UserThumbnailComponent,
        TippyDirective,
        ExecutionPlanTooltipComponent,
        EntityMetadataComponent,
        JobTaskThumbnailComponent,
    ],
    templateUrl: "./associated-jobs-table.component.html",
    styleUrl: "./associated-jobs-table.component.scss",
})
export class AssociatedJobsTableComponent implements OnInit {
    @Input() displayedColumns = ["id", "state", "name", "creator"]
    @Output() clickItem = new EventEmitter<AssociatedJobsTableItemFragment>()

    auth = inject(AuthService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)

    @Input({required: true}) assignedTo!: {
        id?: string
        legacyId?: number
        contentTypeModel: ContentTypeModel
    }

    dataLoader = inject(DataLoaderService)
    destroyRef = inject(DestroyRef)
    tabState = inject(TabStateService)

    $data = signal(PLACEHOLDER_ITEMS(5) as LoadedData<AssociatedJobsTableItemFragment>)

    ngOnInit() {
        const assignedTo = this.assignedTo

        this.tabState.becomesActive$
            .pipe(
                switchMap(() =>
                    this.sdk.gql
                        .associatedJobsTableItems({
                            objectId: assignedTo.id,
                            objectLegacyId: assignedTo.legacyId,
                            contentTypeModel: assignedTo.contentTypeModel,
                        })
                        .then((data) => ({
                            items: data.items.filter(IsDefined).map((item) => ({data: item?.job, error: null})),
                            totalCount: data.totalCount,
                            error: null,
                            complete: true,
                        })),
                ),
                startWith(PLACEHOLDER_ITEMS(5) as LoadedData<AssociatedJobsTableItemFragment>),
            )
            .pipe(takeUntilDestroyed(this.destroyRef), keepCheckingRunningJobs$(this.sdk, this.tabState))
            .subscribe((data) => {
                this.$data.set({
                    items: data.items,
                    error: null,
                    complete: false,
                    totalCount: data.totalCount,
                })
            })
    }

    protected readonly JobState = JobState
    protected readonly JobStateTooltipComponent = JobStateTooltipComponent
}
