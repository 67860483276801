import {Pass, FullScreenQuad} from "three/examples/jsm/postprocessing/Pass"
import * as THREE from "three"
import {DEFAULT_FLOAT_TEXTURE_TYPE} from "@app/template-editor/helpers/three-utils"
import {renderHelperObjects} from "@app/template-editor/helpers/helper-objects"

export class HelperObjectsPass extends Pass {
    static Shader = {
        uniforms: {
            tBackground: {value: null as THREE.Texture | null},
            tImage: {value: null as THREE.Texture | null},
        },
        vertexShader: /* glsl */ `
            varying vec2 vBgUv;
            void main() {
                vBgUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }`,
        fragmentShader: /* glsl */ `
            uniform sampler2D tBackground;
            uniform sampler2D tImage;
            varying vec2 vBgUv;

            void main() {
                vec4 bg = texture2D(tBackground, vBgUv);
                vec4 img = texture2D(tImage, vBgUv);
                gl_FragColor = img + bg * (1.0 - img.a);
            }`,
    }

    private uniforms: (typeof HelperObjectsPass.Shader)["uniforms"]
    private quad: FullScreenQuad
    private renderTarget: THREE.WebGLRenderTarget | null = null

    constructor(
        private scene: THREE.Scene,
        private camera: THREE.PerspectiveCamera,
        private threeDepthTexture: THREE.DepthTexture,
    ) {
        super()

        this.uniforms = THREE.UniformsUtils.clone(HelperObjectsPass.Shader.uniforms)
        const material = new THREE.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: HelperObjectsPass.Shader.vertexShader,
            fragmentShader: HelperObjectsPass.Shader.fragmentShader,
            depthTest: false,
            depthWrite: false,
        })
        this.quad = new FullScreenQuad(material)
    }

    override render(
        renderer: THREE.WebGLRenderer,
        writeBuffer: THREE.WebGLRenderTarget,
        readBuffer: THREE.WebGLRenderTarget,
        deltaTime: number,
        maskActive: boolean,
    ) {
        const oldTarget = renderer.getRenderTarget()

        if (!this.renderTarget) {
            this.renderTarget = new THREE.WebGLRenderTarget(readBuffer.width, readBuffer.height, {
                format: THREE.RGBAFormat,
                type: DEFAULT_FLOAT_TEXTURE_TYPE,
                depthTexture: this.threeDepthTexture,
            })
            this.renderTarget.texture.name = "HelperObjectsPass.renderTarget"
        }

        renderer.setRenderTarget(this.renderTarget)
        renderHelperObjects(renderer, this.scene, this.camera, true, false)

        renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer)
        this.uniforms.tBackground.value = readBuffer.texture
        this.uniforms.tImage.value = this.renderTarget.texture
        this.quad.render(renderer)

        renderer.setRenderTarget(oldTarget)
    }

    override setSize(width: number, height: number) {
        if (this.renderTarget) this.renderTarget.setSize(width, height)
    }

    override dispose() {
        this.quad.material.dispose()
        this.quad.dispose()
        this.renderTarget?.dispose()
        this.renderTarget = null
    }
}
