import {DestroyRef, Injectable, OnDestroy, Signal, computed, effect, inject, signal} from "@angular/core"
import {Object, isOutput, isTemplateContainer, Node} from "@cm/lib/templates/node-types"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {Matrix4} from "@cm/lib/math"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {Parameters, TemplateParameterValue} from "@cm/lib/templates/nodes/parameters"
import {ISceneManagerNew, MeshLoadSettings} from "@cm/lib/templates/interfaces/scene-manager"
import {UtilsService} from "@legacy/helpers/utils"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {TransientDataObject} from "@app/common/helpers/transient-data-object/transient-data-object"
import {AnyJSONValue, ImageColorSpace, LodType, TemplateGraphDifferences, TemplateGraphSnapshot, TemplateNode} from "@cm/lib/templates/types"
import {MeshData} from "@cm/lib/geometry-processing/mesh-data"
import {RenderNodes} from "@cm/lib/rendering/render-nodes"
import {isIDataObjectNew} from "@cm/lib/templates/interfaces/data-object"
import {StoredMesh} from "@cm/lib/templates/nodes/stored-mesh"
import {Observable, tap, Subject, delay, firstValueFrom, take, catchError, finalize, filter, Subscription} from "rxjs"
import {WebAssemblyWorkerService} from "@editor/helpers/mesh-processing/mesh-processing"
import {ConnectionSolver} from "@app/editor/helpers/connection-solver/connection-solver"
import {MeshDataCache} from "@app/template-editor/helpers/mesh-data-cache"
import {TemplateRevisionGraphCache} from "@template-editor/helpers/template-revision-graph-cache"
import {GraphBuilder} from "@cm/lib/templates/runtime-graph/graph-builder"
import {GraphScheduler} from "@cm/lib/templates/runtime-graph/graph-scheduler"
import {TemplateContext} from "@cm/lib/templates/types"
import {CompactUIDTable} from "@cm/lib/utils/utils"
import {NodeEvaluator} from "@cm/lib/templates/node-evaluator"
import {Solver} from "@cm/lib/templates/runtime-graph/nodes/solver"
import {CompileTemplateNew} from "@cm/lib/templates/runtime-graph/nodes/compile-template-new"
import {RunTemplateNew} from "@cm/lib/templates/runtime-graph/nodes/run-template-new"
import {SolverState} from "@cm/lib/templates/runtime-graph/nodes/solver/state"
import {NotReady} from "@cm/lib/templates/runtime-graph/slots"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {
    BooleanInfo,
    ConfigInfo,
    ImageInfo,
    InterfaceDescriptor,
    JSONInfo,
    MaterialInfo,
    NumberInfo,
    ObjectInfo,
    StringInfo,
    TemplateInfo,
    getInterfaceIdPrefix,
} from "@cm/lib/templates/interface-descriptors"
import {ObjectId} from "@cm/lib/templates/interfaces/connection-solver"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {IdDetails} from "@cm/lib/api-gql/common"
import {MediaTypeSchema} from "@cm/lib/api-gql/data-object"
import {z} from "zod"
import {EndpointUrls} from "@app/common/models/constants/urls"
import {extensionForContentType} from "@cm/lib/utils/content-types"
import {Camera} from "@cm/lib/templates/nodes/camera"
import {AreaLight} from "@cm/lib/templates/nodes/area-light"
import {TransformAccessor} from "@cm/lib/templates/runtime-graph/nodes/transform"
import {ConfigVariant} from "@cm/lib/templates/nodes/config-variant"
import {ConfigGroup} from "@cm/lib/templates/nodes/config-group"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {
    getReferencingDeletedTemplateNodes,
    getNodeOwner,
    getUniqueTemplateNodeParent,
    deleteNodesFromTemplateGraph,
    getTemplateNodeLabel,
} from "@cm/lib/templates/utils"
import {asapScheduler} from "rxjs"
import {takeUntilDestroyed, toObservable, toSignal} from "@angular/core/rxjs-interop"
import {MaterialAssignment, MaterialAssignments} from "@cm/lib/templates/nodes/material-assignment"
import {Nodes} from "@cm/lib/templates/nodes/nodes"
import {DataObjectReference} from "@cm/lib/templates/nodes/data-object-reference"
import {DataObjectCache} from "../helpers/data-object-cache"
import {MaterialGraphCache} from "../helpers/material-graph-cache"
import {ProceduralMeshDataCache} from "../helpers/procedural-mesh-data-cache"
import {DecalMeshDataCache} from "../helpers/decal-mesh-data-cache"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {TransientDataObject as TransientDataObjectNode} from "@cm/lib/templates/nodes/transient-data-object"
import {ObjectValue} from "@cm/lib/templates/nodes/value"
import {encodeConfiguratorURLParams} from "@app/common/components/viewers/configurator/helpers/parameters"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {MeshCurve} from "@cm/lib/templates/nodes/mesh-curve"

const getEmtpyTemplateGraph = () => new TemplateGraph({name: "Empty Graph", nodes: new Nodes({list: []})})
const instantiateTemplateGraph = (templateGraph: TemplateGraph, parameters: Parameters, lockedTransform: Matrix4) =>
    new TemplateInstance({
        name: "Root Template",
        id: "root",
        template: templateGraph,
        parameters,
        lockedTransform: lockedTransform.toArray(),
        visible: true,
    })

export type TemplateNodePart = {
    templateNode: TemplateNode
    part: "root" | "target" | `group${number}` | `controlPoint${number}`
}

export const isGroupPart = (part: string): part is `group${number}` => part.match(/group\d+/) !== null
export const getGroupPartNumber = (part: `group${number}`): number => {
    const match = part.match(/group(\d+)$/)

    if (match && match.length > 1) {
        const slot = match[1]
        return parseInt(slot)
    }

    throw new Error(`Invalid group part: ${part}`)
}

export const isControlPointPart = (part: string): part is `controlPoint${number}` => part.match(/controlPoint\d+/) !== null

export const getControlPointPartNumber = (part: `controlPoint${number}`): number => {
    const match = part.match(/controlPoint(\d+)$/)

    if (match && match.length > 1) {
        const slot = match[1]
        return parseInt(slot)
    }

    throw new Error(`Invalid control point part: ${part}`)
}

export type SceneNodePart = {
    sceneNode: SceneNodes.SceneNode
    part: TemplateNodePart["part"]
}

export enum TransformMode {
    Translate = "translate",
    Rotate = "rotate",
    Scale = "scale",
}

export type Intersection = {
    distance: number
    point: {x: number; y: number; z: number}
    faceIndex?: number | undefined
    uv?: {x: number; y: number} | undefined
    uv1?: {x: number; y: number} | undefined
    normal?: {x: number; y: number; z: number}
}

export type TemplateNodePartIntersection = {templateNodePart: TemplateNodePart; intersection?: Intersection}

export type TemplateNodePartClickEvent = {
    target: TemplateNodePartIntersection[]
    modifiers: {shiftKey: boolean; ctrlKey: boolean}
}

const uvWireframeTestMaterial = new MaterialReference({name: "Pebble gray", materialRevisionId: 2390})

const uv1TestMaterialUv0 = new MaterialReference({name: "UV Test Pattern - Checker map B", materialRevisionId: 50260})
const uv1TestMaterialUv1 = new MaterialReference({name: "UV Test Pattern - Checker map B", materialRevisionId: 50260})
const uv1TestMaterialUv2 = new MaterialReference({name: "UV Test Pattern - Checker map B", materialRevisionId: 50260})

const uv2TestMaterialUv0 = new MaterialReference({name: "UV Test Pattern - Checker map Grain", materialRevisionId: 50261})
const uv2TestMaterialUv1 = new MaterialReference({name: "UV Test Pattern - Checker map Grain", materialRevisionId: 50261})
const uv2TestMaterialUv2 = new MaterialReference({name: "UV Test Pattern - Checker map Grain", materialRevisionId: 50261})

@Injectable()
export class SceneManagerService implements OnDestroy {
    //Inputs
    $defaultCustomerId = signal<number | undefined>(undefined)
    $templateRevisionId = signal<string | undefined>(undefined)
    $transformMode = signal<TransformMode>(TransformMode.Translate)
    $exposeClaimedSubTemplateInputs = signal(true)
    $templateGraph = signal(getEmtpyTemplateGraph())
    $instanceParameters = signal(new Parameters({}))
    $instanceTransform = signal(Matrix4.identity())
    $lodType = signal<LodType>("web")
    $reviewMode = signal<"wireframe" | "uvTest1" | "uvTest2" | undefined>(undefined)
    $reviewFocus = signal<SceneNodes.WireframeMesh["channel"]>("faces")

    //Undo / Redo related
    $templateGraphChangeReference = signal<TemplateGraphSnapshot | undefined>(undefined)
    private $undoHistory = signal<TemplateGraphDifferences[]>([])
    private $redoHistory = signal<TemplateGraphDifferences[]>([])
    $canUndo = computed(() => this.$undoHistory().length > 0 && this.$templateGraphChangeReference() === undefined)
    $canRedo = computed(() => this.$redoHistory().length > 0 && this.$templateGraphChangeReference() === undefined)

    //Current configuration
    $currentLocalConfiguration = computed(() => this.getCurrentConfiguration(false))
    $currentGlobalConfiguration = computed(() => this.getCurrentConfiguration(true))

    //Events
    private _templateTreeChanged$ = new Subject<TemplateGraphDifferences>()
    public templateTreeChanged$ = this._templateTreeChanged$.asObservable()
    private _templateSwapped$ = new Subject<void>()
    public templateSwapped$ = this._templateSwapped$.asObservable()

    //Modified state
    private $updatedSerializedTemplateGraphSignal = signal(0)
    synchronizeSerializedTemplateGraph() {
        this.$updatedSerializedTemplateGraphSignal.update((x) => x + 1)
    }
    private $serializedTemplateGraphHash = computed(() => {
        this.$updatedSerializedTemplateGraphSignal()
        const templateGraph = this.$templateGraph()
        return templateGraph.getHash()
    })
    private $templateTreeChangedSignal = toSignal(this.templateTreeChanged$)
    private $currentTemplateGraphHash = computed(() => {
        this.$templateTreeChangedSignal()
        const templateGraph = this.$templateGraph()
        return templateGraph.getHash()
    })
    $templateGraphModified = computed(() => this.$serializedTemplateGraphHash() !== this.$currentTemplateGraphHash())

    private _clickedTemplateNodePart$ = new Subject<TemplateNodePartClickEvent>()
    private clickedTemplateNodePart$ = this._clickedTemplateNodePart$.asObservable()
    watchForClickedTemplateNodePart() {
        if (this.watchingForClickedTemplateNodePart()) throw new Error("Already watching for clicked scene node part")

        return this.clickedTemplateNodePart$.pipe(take(1))
    }
    watchingForClickedTemplateNodePart() {
        return this._clickedTemplateNodePart$.observed
    }

    $templateInstance = computed(() => instantiateTemplateGraph(this.$templateGraph(), this.$instanceParameters(), this.$instanceTransform()))

    private $preDisplayList = signal<SceneNodes.SceneNode[]>([])
    private $displayList = signal<SceneNodes.SceneNode[]>([])
    private $descriptorList = signal<InterfaceDescriptor<unknown, object>[]>([])
    descriptorList$ = toObservable(this.$descriptorList)
    private $_ready = signal<boolean>(false)
    public $ready = this.$_ready.asReadonly()
    ready$ = toObservable(this.$_ready)
    templateRevisionId$ = toObservable(this.$templateRevisionId)
    defaultCustomerId$ = toObservable(this.$defaultCustomerId)
    templateGraph$ = toObservable(this.$templateGraph)

    $scene = computed(() => [...this.$preDisplayList(), ...this.$displayList()])

    $cameras = computed(() => this.$scene().filter(SceneNodes.Camera.is))

    $reviewedUvChannel = computed(() => {
        const reviewFocus = this.$reviewFocus()
        if (reviewFocus === "faces") return "uv0"
        else return reviewFocus
    })

    private $_selectedNodeParts = signal<TemplateNodePart[]>([])
    public $selectedNodeParts = this.$_selectedNodeParts.asReadonly()
    public selectedNodeParts$ = toObservable(this.$selectedNodeParts)
    $selectedSceneNodes = computed(() => {
        return this.$selectedNodeParts()
            .map((selectedNodePart) => this.getSceneNodeParts(selectedNodePart))
            .flat()
    })

    $hoveredNodePart = signal<TemplateNodePart | undefined>(undefined)
    hoveredNodePart$ = toObservable(this.$hoveredNodePart)
    $hoveredSceneNodes = computed(() => {
        const hoveredNode = this.$hoveredNodePart()
        return hoveredNode ? this.getSceneNodeParts(hoveredNode) : []
    })

    private $_highlightedNodes = signal<TemplateNode[]>([])
    $highlightedNodes = this.$_highlightedNodes.asReadonly()

    private $_criticalTasks = signal<number>(0)
    private criticalTasks$ = toObservable(this.$_criticalTasks)
    private $_optionalTasks = signal<number>(0)
    private optionalTasks$ = toObservable(this.$_optionalTasks)
    $pendingTasks = computed(() => this.$_criticalTasks() + this.$_optionalTasks())

    $configuratorUrlParams = computed(() => encodeConfiguratorURLParams(this.$descriptorList(), this.sdkService))

    private $activeNodeSet = signal(new Set<TemplateNode>())
    private $nodeToObjectMap = signal(new Map<TemplateNode, ObjectId>())
    private $objectToNodeMap = signal(new Map<ObjectId, TemplateNode>())
    private $solverUpdateAll = signal<(postStep: boolean) => void>(() => {})
    private $transformAccessorMap = signal<Map<ObjectId, TransformAccessor>>(new Map())

    private sdkService = inject(SdkService)
    private utilsService = inject(UtilsService)
    workerService = inject(WebAssemblyWorkerService)
    private materialGraphService = inject(MaterialGraphService)
    private destroyRef = inject(DestroyRef)
    private dialog = inject(MatDialog)
    private sceneManager = new SceneManager(this, this.sdkService, this.workerService, this.materialGraphService, this.$templateInstance)
    private scheduler = new GraphScheduler()
    private builder = new GraphBuilder("root", this.scheduler)

    constructor() {
        effect(() => this.compileTemplate())

        effect(() => {
            this.$scene()

            const solver = this.sceneManager.getConnectionSolver()
            if (solver.checkReady()) this.updateAllMeshPositions(solver)
            else console.warn("Solver not ready")
        })

        effect(() => {
            this.$templateGraph()
            asapScheduler.schedule(() => this._templateSwapped$.next())
        })

        this.templateSwapped$.pipe(takeUntilDestroyed(this.destroyRef), delay(0)).subscribe(() => {
            this.$templateGraphChangeReference.set(undefined)
            this.$undoHistory.set([])
            this.$redoHistory.set([])

            this.$_selectedNodeParts.set([])
            this.$hoveredNodePart.set(undefined)
            this.$_highlightedNodes.set([])
        })
    }

    getObjectId(node: TemplateNode) {
        return this.$nodeToObjectMap().get(node)
    }

    getSceneNodeParts(nodePart: TemplateNodePart): SceneNodePart[] {
        const {templateNode, part} = nodePart
        const objectId = this.getObjectId(templateNode)
        if (!objectId) return []

        const scene = this.$scene()
        return scene
            .filter((sceneNode) => {
                const sceneNodeObjectId = sceneNode.topLevelObjectId ?? sceneNode.id
                return sceneNodeObjectId === objectId
            })
            .map((sceneNode) => ({sceneNode, part}))
    }

    compileTemplate(): void {
        const templateInstance = this.$templateInstance()

        //console.log("Template instance changed", templateInstance)

        const scope = this.builder.scope()

        const templateMatrix = this.$instanceTransform()

        const templateContext: TemplateContext = {
            sceneManager: this.sceneManager,
            templateMatrix,
            lodType: this.$lodType(),
            defaultCustomerId: this.$defaultCustomerId(),
        }

        const evaluator = new NodeEvaluator(new CompactUIDTable<string>(), scope, templateContext, {}, undefined)
        const [graphValid, graphInvalid] = scope.branch(evaluator.evaluateTemplate(scope, templateInstance.parameters.template))
        const graph = scope.phi(scope.get(graphValid, "graph"), graphInvalid)

        const [inputs] = evaluator.evaluateTemplateInputs(scope, templateInstance)

        const overrideMaterial = (() => {
            switch (this.$reviewMode()) {
                case "wireframe":
                    return uvWireframeTestMaterial
                case "uvTest1":
                    switch (this.$reviewedUvChannel()) {
                        case "uv0":
                            return uv1TestMaterialUv0
                        case "uv1":
                            return uv1TestMaterialUv1
                        case "uv2":
                            return uv1TestMaterialUv2
                    }
                case "uvTest2":
                    switch (this.$reviewedUvChannel()) {
                        case "uv0":
                            return uv2TestMaterialUv0
                        case "uv1":
                            return uv2TestMaterialUv1
                        case "uv2":
                            return uv2TestMaterialUv2
                    }
                default:
                    return undefined
            }
        })()

        const compileTemplate = scope.node(CompileTemplateNew, {
            graph,
            sceneProperties: null,
            render: null,
            inputs,
            templateContext,
            topLevelObjectId: null,
            templateDepth: 0,
            exposeClaimedSubTemplateInputs: this.$exposeClaimedSubTemplateInputs(),
            overrideMaterial: () => overrideMaterial,
        })

        const runTemplate = scope.node(RunTemplateNew, {
            compiledTemplate: compileTemplate.compiledTemplate,
        })

        const solver = scope.node(Solver, {
            state: scope.state(SolverState, "solverState"),
            sceneManager: this.sceneManager,
            data: runTemplate.solverData,
        })

        scope.tap(compileTemplate.activeNodeSet, (activeNodeSet) => {
            if (activeNodeSet !== NotReady) this.$activeNodeSet.set(activeNodeSet)
        })

        scope.tap(compileTemplate.nodeToObjectMap, (nodeToObjectMap) => {
            if (nodeToObjectMap !== NotReady) this.$nodeToObjectMap.set(nodeToObjectMap)
        })

        scope.tap(compileTemplate.objectToNodeMap, (objectToNodeMap) => {
            if (objectToNodeMap !== NotReady) this.$objectToNodeMap.set(objectToNodeMap)
        })

        scope.tap(runTemplate.preDisplayList, (preDisplayList) => {
            if (preDisplayList !== NotReady) this.$preDisplayList.set(preDisplayList)
        })

        scope.tap(runTemplate.displayList, (displayList) => {
            if (displayList !== NotReady) this.$displayList.set(displayList)
        })

        scope.tap(runTemplate.descriptorList, (descriptorList) => {
            if (descriptorList !== NotReady) this.$descriptorList.set(descriptorList)
        })

        scope.tap(runTemplate.ready, (ready) => {
            if (ready !== NotReady) this.$_ready.set(ready)
            else this.$_ready.set(false)
        })

        scope.tap(runTemplate.transformAccessorList, (transformAccessorList) => {
            if (transformAccessorList !== NotReady) this.$transformAccessorMap.set(new Map(transformAccessorList.map((x) => [x.objectId, x.transformAccessor])))
        })

        scope.tap(solver.updateAll, (updateAll) => {
            if (updateAll !== NotReady) this.$solverUpdateAll.set(updateAll)
        })

        this.builder.finalizeChanges()
    }

    ngOnDestroy(): void {
        this.sceneManager.destroy()
    }

    requiresSync(waitForOptionalTasks: boolean) {
        return this.scheduler.hasPendingUpdates || this.$_criticalTasks() > 0 || (waitForOptionalTasks && this.$_optionalTasks() > 0) || !this.$ready()
    }

    async sync(waitForOptionalTasks: boolean): Promise<void> {
        if (this.scheduler.hasPendingUpdates) {
            await firstValueFrom(this.scheduler.sync())
            return this.sync(waitForOptionalTasks)
        } else if (this.$_criticalTasks() > 0) {
            await firstValueFrom(
                this.criticalTasks$.pipe(
                    filter((numCriticalTasks) => numCriticalTasks === 0),
                    take(1),
                ),
            )
            return this.sync(waitForOptionalTasks)
        } else if (waitForOptionalTasks && this.$_optionalTasks() > 0) {
            await firstValueFrom(
                this.optionalTasks$.pipe(
                    filter((numOptionalTasks) => numOptionalTasks === 0),
                    take(1),
                ),
            )
            return this.sync(waitForOptionalTasks)
        } else if (!this.$ready()) {
            await firstValueFrom(
                this.ready$.pipe(
                    filter((ready) => ready === true),
                    take(1),
                ),
            )
            return this.sync(waitForOptionalTasks)
        }
    }

    sceneNodePartToTemplateNodePart(sceneNodePart: SceneNodePart): TemplateNodePart | undefined {
        const templateNode = this.$objectToNodeMap().get(sceneNodePart.sceneNode.topLevelObjectId ?? sceneNodePart.sceneNode.id)
        if (templateNode) return {templateNode, part: sceneNodePart.part}
        else return undefined
    }

    selectNode(templateNodePart: TemplateNodePart | undefined) {
        const selectedNodeParts = this.$selectedNodeParts()
        if (!templateNodePart && selectedNodeParts.length === 0) return
        if (templateNodePart) {
            const {templateNode, part} = templateNodePart

            if (selectedNodeParts.length === 1 && selectedNodeParts[0].templateNode === templateNode && selectedNodeParts[0].part === part) {
                if ((templateNode instanceof Camera || templateNode instanceof AreaLight) && part === "root") {
                    this.$_selectedNodeParts.set([{templateNode, part: "target"}])
                    return
                } else return
            }
        }

        this.$_selectedNodeParts.set(templateNodePart ? [templateNodePart] : [])
        if (templateNodePart?.templateNode instanceof ConfigVariant) {
            const {templateNode: configVariant} = templateNodePart
            getNodeOwner(configVariant, (nodeOwner) => {
                if (nodeOwner instanceof ConfigGroup) {
                    const instanceParameters = this.$instanceParameters()
                    instanceParameters.replaceParameters({
                        ...instanceParameters.parameters,
                        [nodeOwner.parameters.id]: configVariant.parameters.id,
                    })
                    this.compileTemplate()
                }
            })
        }
    }

    setTemplateParameter(paramId: string, value: TemplateParameterValue) {
        const instanceParameters = this.$instanceParameters()

        instanceParameters.replaceParameters({
            ...instanceParameters.parameters,
            [paramId]: value,
        })
        this.compileTemplate()
    }

    addNodeToSelection(templateNodePart: TemplateNodePart) {
        const selectedNodeParts = this.$selectedNodeParts()
        if (templateNodePart.templateNode instanceof MeshCurve) {
            if (!selectedNodeParts.some((x) => x.templateNode === templateNodePart.templateNode && x.part === templateNodePart.part)) {
                this.$_selectedNodeParts.set([...selectedNodeParts, templateNodePart])
            }
        } else if (!selectedNodeParts.some((x) => x.templateNode === templateNodePart.templateNode)) {
            this.$_selectedNodeParts.set([...selectedNodeParts, templateNodePart])
        }
    }

    removeNodeFromSelection(templateNodePart: TemplateNodePart) {
        const selectedNodeParts = this.$selectedNodeParts()
        const selectedNodePartIdx = selectedNodeParts.findIndex((x) => x.templateNode === templateNodePart.templateNode && x.part === templateNodePart.part)
        if (selectedNodePartIdx >= 0) {
            selectedNodeParts.splice(selectedNodePartIdx, 1)
            this.$_selectedNodeParts.set([...selectedNodeParts])
        }
    }

    handleClickEvent(event: TemplateNodePartClickEvent) {
        if (this.watchingForClickedTemplateNodePart()) {
            this._clickedTemplateNodePart$.next(event)
            return
        }

        const {target, modifiers} = event
        const {shiftKey, ctrlKey} = modifiers
        if (target.length > 0) {
            const {templateNodePart} = target[0]
            if (shiftKey) this.addNodeToSelection(templateNodePart)
            else if (ctrlKey) this.removeNodeFromSelection(templateNodePart)
            else this.selectNode(templateNodePart)
        } else if (!shiftKey && !ctrlKey) this.selectNode(undefined)
    }

    hoverNode(templateNodePart: TemplateNodePart | undefined) {
        this.$hoveredNodePart.set(templateNodePart)
    }

    highlightNode(templateNode: TemplateNode, value: boolean) {
        const highlightedNodes = this.$highlightedNodes()

        if (value) {
            if (!highlightedNodes.includes(templateNode)) this.$_highlightedNodes.set([...highlightedNodes, templateNode])
        } else {
            const idx = highlightedNodes.indexOf(templateNode)
            if (idx >= 0) {
                highlightedNodes.splice(idx, 1)
                this.$_highlightedNodes.set([...highlightedNodes])
            }
        }
    }

    isHighlightedNode(templateNode: TemplateNode) {
        return this.$highlightedNodes().includes(templateNode)
    }

    addTask<T>(description: string, task: Observable<T>, critical: boolean): Subscription {
        const startTime = Date.now()

        const tasksSignal = critical ? this.$_criticalTasks : this.$_optionalTasks

        asapScheduler.schedule(() => tasksSignal.update((x) => x + 1))
        return task
            .pipe(
                tap(() => {
                    const endTime = Date.now()
                    const timeDiff = endTime - startTime
                    console.log(`Task ${description} took ${timeDiff}ms`)
                }),
                catchError((error) => {
                    const endTime = Date.now()
                    const timeDiff = endTime - startTime
                    console.error(`Task ${description} failed after ${timeDiff}ms`)
                    throw error
                }),
                finalize(() => {
                    asapScheduler.schedule(() => tasksSignal.update((x) => x - 1))
                }),
            )
            .subscribe()
    }

    async getHdriAsBufferAndExtension(hdriIdDetails: IdDetails) {
        const {hdri} = await this.sdkService.gql.getHDRIDetailsForSceneManagerService(hdriIdDetails)

        const parsedDetails = z
            .object({dataObject: z.object({mediaType: MediaTypeSchema, bucketName: z.string(), objectName: z.string(), originalFileName: z.string()})})
            .safeParse(hdri)
        if (!parsedDetails.success) throw Error("Failed to parse hdri details")

        const {dataObject} = parsedDetails.data

        const extension = (() => {
            try {
                return extensionForContentType(dataObject.mediaType)
            } catch (e) {
                return dataObject.originalFileName.split(".").pop() ?? "hdr"
            }
        })()

        const arrayBuffer = await firstValueFrom(
            this.utilsService.getResourceAsBuffer(EndpointUrls.GoogleStorage(dataObject.bucketName, dataObject.objectName)),
        )
        return {buffer: new Uint8Array(arrayBuffer), extension}
    }

    isNodeActive(node: TemplateNode) {
        return this.$activeNodeSet().has(node)
    }

    private updateAllMeshPositions(solver: ConnectionSolver) {
        const solverUpdateAll = this.$solverUpdateAll()

        solverUpdateAll(false)
        while (solver.step()) {}
        solverUpdateAll(true)

        return true
    }

    getISceneManager() {
        return this.sceneManager
    }

    getTransformAccessor(node: TemplateNode) {
        const objectId = this.getObjectId(node)
        if (objectId) return this.$transformAccessorMap().get(objectId)
        return undefined
    }

    beginModifyTemplateGraph() {
        const templateGraphChangeReference = this.$templateGraphChangeReference()
        if (templateGraphChangeReference) return

        this.$templateGraphChangeReference.set(this.$templateGraph().getSnapshot())
    }

    endModifyTemplateGraph() {
        const templateGraphChangeReference = this.$templateGraphChangeReference()
        if (!templateGraphChangeReference) return

        const templateGraph = this.$templateGraph()
        const graphDifference = templateGraph.getDifferences(templateGraphChangeReference)
        this.notifyTemplateTreeChange(graphDifference)
        this.pushUndoHistory(graphDifference)

        this.$templateGraphChangeReference.set(undefined)
    }

    private pushUndoHistory(graphDifference: TemplateGraphDifferences) {
        const undoHistory = this.$undoHistory()
        undoHistory.push(graphDifference)
        if (undoHistory.length > 20) undoHistory.shift()
        this.$undoHistory.set([...undoHistory])
        this.$redoHistory.set([])
    }

    private notifyTemplateTreeChange(differences: TemplateGraphDifferences) {
        const {deletedNodes, modifiedNodes} = differences

        if (deletedNodes.size > 0) {
            const selectedNodeParts = this.$selectedNodeParts()
            const selectedNodePartsFiltered = selectedNodeParts.filter((x) => !deletedNodes.has(x.templateNode))
            if (selectedNodePartsFiltered.length !== selectedNodeParts.length) this.$_selectedNodeParts.set(selectedNodePartsFiltered)

            const hoveredNodePart = this.$hoveredNodePart()
            if (hoveredNodePart && deletedNodes.has(hoveredNodePart.templateNode)) this.$hoveredNodePart.set(undefined)

            const highlightedNodes = this.$highlightedNodes()
            const highlightedNodesFiltered = highlightedNodes.filter((x) => !deletedNodes.has(x))
            if (highlightedNodesFiltered.length !== highlightedNodes.length) this.$_highlightedNodes.set(highlightedNodesFiltered)
        }

        if (modifiedNodes.size > 0) {
            const selectedNodeParts = this.$selectedNodeParts()
            const selectionChanged = selectedNodeParts.find((x) => modifiedNodes.has(x.templateNode)) !== undefined
            if (selectionChanged) this.$_selectedNodeParts.set([...selectedNodeParts])

            const hoveredNodePart = this.$hoveredNodePart()
            if (hoveredNodePart && modifiedNodes.has(hoveredNodePart.templateNode))
                this.$hoveredNodePart.set({templateNode: hoveredNodePart.templateNode, part: hoveredNodePart.part})

            const highlightedNodes = this.$highlightedNodes()
            const highlightedNodesChanged = [...highlightedNodes].find((x) => modifiedNodes.has(x)) !== undefined
            if (highlightedNodesChanged) this.$_highlightedNodes.set([...highlightedNodes])
        }

        this._templateTreeChanged$.next(differences)
    }

    private modifyTemplateGraphImpl(modifier: (templateGraph: TemplateGraph) => void, undoable: boolean) {
        const templateGraph = this.$templateGraph()
        const templateGraphChangeReference = this.$templateGraphChangeReference()

        if (!templateGraphChangeReference) {
            const graphDifference = templateGraph.trackDifferences(modifier)
            this.notifyTemplateTreeChange(graphDifference)
            if (undoable) this.pushUndoHistory(graphDifference)
        } else modifier(templateGraph)

        this.compileTemplate()
    }

    modifyTemplateGraph(modifier: (templateGraph: TemplateGraph) => void) {
        this.modifyTemplateGraphImpl(modifier, true)
    }

    undo = () => {
        if (!this.$canUndo()) return

        const undoHistory = this.$undoHistory()
        const redoHistory = this.$redoHistory()

        const graphDifference = undoHistory[undoHistory.length - 1]
        if (graphDifference) {
            this.modifyTemplateGraphImpl(() => graphDifference.applyBackward(), false)
            undoHistory.pop()

            redoHistory.push(graphDifference)
            this.$undoHistory.set([...undoHistory])
            this.$redoHistory.set([...redoHistory])
        }
    }

    redo = () => {
        if (!this.$canRedo()) return

        const undoHistory = this.$undoHistory()
        const redoHistory = this.$redoHistory()

        if (redoHistory.length === 0) return

        const graphDifference = redoHistory[redoHistory.length - 1]
        if (graphDifference) {
            this.modifyTemplateGraphImpl(() => graphDifference.applyForward(), false)
            redoHistory.pop()

            undoHistory.push(graphDifference)
            this.$undoHistory.set([...undoHistory])
            this.$redoHistory.set([...redoHistory])
        }
    }

    deleteNodes(templateNodeParts: TemplateNodePart[]) {
        const selectedControlPoints = templateNodeParts.filter(
            (
                templateNodePart,
            ): templateNodePart is {
                templateNode: MeshCurve
                part: `controlPoint${number}`
            } => templateNodePart.templateNode instanceof MeshCurve && isControlPointPart(templateNodePart.part),
        )
        const otherTemplateNodeParts = templateNodeParts.filter(
            (templateNodePart) => !selectedControlPoints.some((selectedControlPoint) => selectedControlPoint === templateNodePart),
        )

        const nodesToDelete = new Set([...otherTemplateNodeParts.map((x) => x.templateNode)])

        if (nodesToDelete.size === 0 && selectedControlPoints.length === 0) return

        const templateGraph = this.$templateGraph()

        if (nodesToDelete.has(templateGraph)) return

        const deleteNodes = () => {
            this.modifyTemplateGraph((templateGraph) => {
                const selectedControlPointsByTemplateNode = new Map<MeshCurve, Set<number>>()
                for (const selectedControlPoint of selectedControlPoints) {
                    const {templateNode, part} = selectedControlPoint
                    const index = getControlPointPartNumber(part)
                    const controlPoints = selectedControlPointsByTemplateNode.get(templateNode) ?? new Set()
                    controlPoints.add(index)
                    selectedControlPointsByTemplateNode.set(templateNode, controlPoints)
                }
                for (const [templateNode, controlPoints] of selectedControlPointsByTemplateNode)
                    templateNode.updateParameters({controlPoints: [...templateNode.parameters.controlPoints].filter((_, index) => !controlPoints.has(index))})

                deleteNodesFromTemplateGraph(templateGraph, nodesToDelete)
            })
        }

        const referencingNodes = getReferencingDeletedTemplateNodes(templateGraph, nodesToDelete)
        if (referencingNodes.size === 0) deleteNodes()
        else {
            const describeNode = (node: TemplateNode, isReference: boolean): string => {
                if (isTemplateContainer(node)) {
                    const containerParent = getUniqueTemplateNodeParent(node)
                    return getTemplateNodeLabel(containerParent)
                } else if (node instanceof MaterialAssignments) {
                    const parentMesh = getUniqueTemplateNodeParent(node)
                    return getTemplateNodeLabel(parentMesh)
                } else if (node instanceof MaterialAssignment) {
                    if (isReference) return getTemplateNodeLabel(node.parameters.node)
                    else return `${getTemplateNodeLabel(node)} of ${describeNode(getUniqueTemplateNodeParent(node), false)} `
                } else if (node instanceof Parameters) {
                    const parentMesh = getUniqueTemplateNodeParent(node)
                    return getTemplateNodeLabel(parentMesh)
                } else if (isOutput(node)) {
                    if (isReference) return getTemplateNodeLabel(node.parameters.template)
                    else return `${getTemplateNodeLabel(node)} of ${describeNode(getUniqueTemplateNodeParent(node), false)} `
                }

                return getTemplateNodeLabel(node)
            }

            const count = [...referencingNodes].reduce((acc, [, children]) => acc + children.size, 0)
            const description = [...referencingNodes].reduce((acc, [node, children]) => {
                const addNewLine = acc.length > 0 ? acc + "<br><br>" : acc
                return (
                    addNewLine +
                    "<strong>" +
                    describeNode(node, false) +
                    "</strong>" +
                    " references " +
                    [...children].reduce((acc, child, index) => {
                        const addComma = acc.length > 0 ? (index === children.size - 1 ? acc + " and " : acc + ", ") : acc
                        return addComma + "<strong>" + describeNode(child, true) + "</strong>"
                    }, "")
                )
            }, "")

            const dialogRef: MatDialogRef<DialogComponent, boolean> = this.dialog.open(DialogComponent, {
                width: DIALOG_DEFAULT_WIDTH,
                data: {
                    title: "Delete Nodes",
                    message:
                        "There are <strong>" +
                        count.toString() +
                        "</strong> references to the nodes about to be deleted: <br><br>" +
                        description +
                        "<br><br>Those references will also be removed in the process. The nodes themselves will not be deleted.<br><br>Are you sure you want to proceed?",
                    confirmLabel: "Delete References",
                    cancelLabel: "Cancel",
                    isDestructive: true,
                },
            })
            dialogRef.afterClosed().subscribe((confirmed) => {
                if (!confirmed) return
                deleteNodes()
            })
        }
    }

    getDescriptorsForNode(node: TemplateInstance) {
        const descriptorList = this.$descriptorList()
        if (node === this.$templateInstance()) return descriptorList
        return descriptorList.filter((descriptor) => {
            const [prefix, _] = getInterfaceIdPrefix(descriptor.props.id)
            return prefix === node.parameters.id
        })
    }

    private getCurrentConfiguration(includeAllSubTemplateInputs: boolean) {
        const configuration: {[paramId: string]: AnyJSONValue | Node} = {}

        for (const descriptor of this.$descriptorList()) {
            if (descriptor.props.type !== "input") continue
            if (descriptor.props.isSetByTemplate && !includeAllSubTemplateInputs) continue

            const {id, name} = descriptor.props
            if (descriptor.props.value === null) continue

            if (descriptor instanceof ConfigInfo) configuration[id] = descriptor.props.value.id
            else if (descriptor instanceof ObjectInfo) {
                configuration[id] = new ObjectValue({value: descriptor.props.value})
            } else if (descriptor instanceof MaterialInfo) {
                configuration[id] = new MaterialReference({name, materialRevisionId: descriptor.props.value.materialRevisionId})
            } else if (descriptor instanceof TemplateInfo) {
                configuration[id] = descriptor.props.value.graph
            } else if (descriptor instanceof ImageInfo) {
                const {value, name} = descriptor.props
                const {dataObject} = value
                if (isIDataObjectNew(dataObject)) configuration[id] = new DataObjectReference({name, dataObjectId: dataObject.legacyId})
                else
                    configuration[id] = new TransientDataObjectNode({
                        data: dataObject.data,
                        imageColorSpace: (dataObject.imageColorSpace ?? "Unknown") as ImageColorSpace,
                        contentType: dataObject.mediaType,
                    })
            } else if (
                descriptor instanceof StringInfo ||
                descriptor instanceof NumberInfo ||
                descriptor instanceof BooleanInfo ||
                descriptor instanceof JSONInfo
            ) {
                configuration[id] = descriptor.props.value
            } else throw new Error("Invalid descriptor type")
        }

        return new Parameters(configuration)
    }

    getDescriptors() {
        return this.$descriptorList()
    }
}

class SceneManager implements ISceneManagerNew {
    private connectionSolver = new ConnectionSolver()
    private meshCache = new MeshDataCache(this.workerService)
    private proceduralMeshCache = new ProceduralMeshDataCache(this.workerService)
    private decalMeshCache = new DecalMeshDataCache(this.workerService)
    private templateRevisionGraphCache = new TemplateRevisionGraphCache(this.sdkService)
    private dataObjectCache = new DataObjectCache(this.sdkService)
    private materialGraphCache = new MaterialGraphCache(this.materialGraphService)

    constructor(
        private sceneManagerService: SceneManagerService,
        private sdkService: SdkService,
        private workerService: WebAssemblyWorkerService,
        private materialGraphService: MaterialGraphService,
        private templateInstance: Signal<TemplateInstance>,
    ) {}

    destroy() {
        this.connectionSolver.destroy()
    }

    getConnectionSolver() {
        return this.connectionSolver
    }

    isMobileDevice() {
        return isMobileDevice
    }

    defaultTransformForObjectNew(node: Object, useLockedTransform: boolean = true): Matrix4 | undefined {
        if (useLockedTransform && node.parameters.lockedTransform) {
            return Matrix4.fromArray(node.parameters.lockedTransform)
        } else if (node instanceof StoredMesh) {
            const {metaData} = node.parameters
            const {defaultPosition} = metaData ?? {}
            if (defaultPosition) {
                const [x, y, z] = defaultPosition
                return Matrix4.translation(x, y, z)
            }
        }

        return undefined
    }

    getRootNodeNew() {
        return this.templateInstance()
    }

    createTransientDataObjectNew(data: Uint8Array, contentType: string, imageColorSpace: ImageColorSpace) {
        const parsedMediaType = MediaTypeSchema.safeParse(contentType)
        if (!parsedMediaType.success) throw Error(`Invalid media/content type: ${contentType}`)
        return new TransientDataObject({data, mediaType: parsedMediaType.data, imageColorSpace: imageColorSpace})
    }

    addTaskNew(descriptions: string, task: Observable<unknown>, critical: boolean): Subscription {
        return this.sceneManagerService.addTask(descriptions, task, critical)
    }

    loadDataObjectNew(dataObjectId: number) {
        return firstValueFrom(this.dataObjectCache.get(dataObjectId))
    }

    async findMaterialNew(articleId: string | null, customerId: number | null) {
        const {materials} = await this.sdkService.throwable.getMaterialsDetailsForSceneManagerService({
            filter: {articleId: {equals: articleId ?? undefined}, organizationLegacyId: {equals: customerId ?? undefined}},
        })

        if (materials.length > 1) console.error(`Multiple materials found for articleId: ${articleId} and customerId: ${customerId}`)

        const latestCyclesRevision = materials[0]?.latestCyclesRevision
        if (!latestCyclesRevision) return null
        return firstValueFrom(this.materialGraphCache.get(latestCyclesRevision.legacyId))
    }

    loadMaterialNew(materialRevisionId: number) {
        return firstValueFrom(this.materialGraphCache.get(materialRevisionId))
    }

    loadMeshDataNew(data: Uint8Array, plyDataObjectId: number, settings: MeshLoadSettings) {
        return firstValueFrom(this.meshCache.getMeshData(data, plyDataObjectId, settings))
    }

    generateProceduralMeshDataNew(graph: RenderNodes.MeshData) {
        return firstValueFrom(this.proceduralMeshCache.getMeshData(graph))
    }

    loadTemplateGraphNew(templateRevisionId: number) {
        return firstValueFrom(this.templateRevisionGraphCache.get(templateRevisionId))
    }

    clipAndOffsetMeshForDecalNew(mesh: MeshData, uvCenter: [number, number], uvRotation: number, size: [number, number], offset: number) {
        return firstValueFrom(this.decalMeshCache.getMeshData(mesh, uvCenter, uvRotation, size, offset))
    }
}
