import {JobState, JobTaskState} from "@api"
import {formatDistance} from "date-fns/formatDistance"

export const jobStateTooltip = (job: {state: JobState; started?: Date | string; cancelled?: Date | string; finished?: Date | string} | null) => {
    switch (job?.state) {
        case JobState.Init:
            return "Initializing"
        case JobState.Running:
            return job.started ? `Started ${formatDistance(job.started, new Date(), {addSuffix: true})}` : "Running"
        case JobState.Complete:
            return job.finished ? `Completed ${formatDistance(job.finished, new Date(), {addSuffix: true})}` : "Completed"
        case JobState.Failed:
            return job.finished ? `Failed ${formatDistance(job.finished, new Date(), {addSuffix: true})}` : "Failed"
        case JobState.Cancelled:
            return job.cancelled ? `Cancelled ${formatDistance(job.cancelled, new Date(), {addSuffix: true})}` : "Cancelled"
    }
    return ""
}

export const jobTaskStateTooltip = (job: {state: JobTaskState; started?: Date | string; cancelled?: Date | string; finished?: Date | string} | null) => {
    switch (job?.state) {
        case JobTaskState.Init:
            return "Initializing"
        case JobTaskState.Runnable:
            return job.started ? `Started ${formatDistance(job.started, new Date(), {addSuffix: true})}` : "Runnable"
        case JobTaskState.Queued:
            return job.started ? `Queued ${formatDistance(job.started, new Date(), {addSuffix: true})}` : "Queued"
        case JobTaskState.Running:
            return job.started ? `Started ${formatDistance(job.started, new Date(), {addSuffix: true})}` : "Running"
        case JobTaskState.Complete:
            return job.finished ? `Completed ${formatDistance(job.finished, new Date(), {addSuffix: true})}` : "Completed"
        case JobTaskState.Failed:
            return job.finished ? `Failed ${formatDistance(job.finished, new Date(), {addSuffix: true})}` : "Failed"
        case JobTaskState.Fatal:
            return job.finished ? `Fatally failed ${formatDistance(job.finished, new Date(), {addSuffix: true})}` : "Fatally failed"
        case JobTaskState.Cancelled:
            return job.cancelled ? `Cancelled ${formatDistance(job.cancelled, new Date(), {addSuffix: true})}` : "Cancelled"
    }
    return ""
}
