import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {ColorValue, colorValue} from "@src/templates/types"
import {z} from "zod"
import {groupNodeParameters, GroupNodeParameters} from "@src/templates/nodes/group-node"
import {idNodeParameters, IdNodeParameters} from "@src/templates/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"
import {DataObjectReference} from "@src/templates/nodes/data-object-reference"
import {nodeInstance} from "@src/graph-system/instance"
import {visitAll, visitNone} from "@src/graph-system/declare-visitor-node"

export const configVariantParameters = namedNodeParameters
    .merge(groupNodeParameters)
    .merge(idNodeParameters)
    .merge(
        z.object({
            iconDataObject: nodeInstance(DataObjectReference).optional(),
            iconColor: colorValue.optional(),
        }),
    )

export type ConfigVariantParameters = NamedNodeParameters &
    GroupNodeParameters &
    IdNodeParameters & {
        iconDataObject?: DataObjectReference
        iconColor?: ColorValue
    }

@registerNode
export class ConfigVariant extends DeclareTemplateNodeTS<ConfigVariantParameters>(
    {
        validation: {paramsSchema: configVariantParameters},
        onVisited: {
            onFilterActive: ({visit, context, parameters}) => {
                const {nodes, ...rest} = parameters

                return {
                    nodes: visit(nodes),
                    ...visitNone(rest),
                }
            },
            onCompile: ({visit, context, parameters}) => {
                return visitAll(parameters, visit)
            },
        },
    },
    {nodeClass: "ConfigVariant"},
) {}

export type ConfigVariantFwd = TemplateNode<ConfigVariantParameters>
