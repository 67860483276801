// @ts-strict-ignore
//TODO: This can be removed together with the old template system
import {ContentTypeModel, SceneViewerTemplateRevisionFragment} from "@api"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {sleep, sortedJSONStringify} from "@cm/lib/utils/utils"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {DataObject} from "@legacy/api-model/data-object"
import {DataObjectAssignmentType} from "@api"
import {SceneManager} from "app/templates/template-system/scene-manager"
import {finalize, firstValueFrom, from as fromPromise, Observable} from "rxjs"
import {APIService} from "@legacy/services/api/api.service"
import {MeshDataBatchApiCallService} from "@app/templates/template-system/mesh-data-batch-api-call.service"
import {DataObjectBatchApiCallService} from "@app/templates/template-system/data-object-batch-api-call.service"
import {TemplateRevisionBatchApiCallService} from "@app/templates/template-system/template-revision-batch-api-call.service"

export async function uploadAndAttachGLTFToEntity(
    entityRevision: SceneViewerTemplateRevisionFragment,
    glbData: ArrayBuffer,
    configString: string,
    uploadService: UploadGqlService,
    sdk: SdkService,
) {
    const organizationId = entityRevision.template.organizationId

    const dataObject = await uploadService.createAndUploadDataObject(new File([glbData], "export.glb"), {
        organizationId,
        mediaType: "model/gltf-binary",
        size: glbData.byteLength,
    })
    return sdk.gql.arViewerCreateDataObjectAssignment({
        input: {
            dataObjectId: dataObject.id,
            contentTypeModel: ContentTypeModel.TemplateRevision,
            objectId: entityRevision.id,
            type: DataObjectAssignmentType.CachedTemplateGltf,
            assignmentKey: configString,
        },
    })
}

export type ConfigurationInfo<T = void> = {
    configString: string
    name: string
    extra: T
}
export function gatherAllConfigurations<T = void>(
    sceneManager: SceneManager,
    rootNode: Nodes.TemplateInstance,
    includeAllSubTemplateInputs: boolean,
    meshDataBatchApiCallService: MeshDataBatchApiCallService,
    dataObjectBatchApiCallService: DataObjectBatchApiCallService,
    templateRevisionBatchApiCallService: TemplateRevisionBatchApiCallService,
    legacyApi: APIService,
    extraCb?: (sceneManager: SceneManager) => T,
): Observable<ConfigurationInfo<T>[]> {
    let running = true

    // create a copy of the root node and a local scene manager, so that the evaluation can happen in the background
    sceneManager = new SceneManager(
        sceneManager.sdk,
        sceneManager.refresh,
        sceneManager.workerService,
        sceneManager.materialGraphManager,
        includeAllSubTemplateInputs,
        meshDataBatchApiCallService,
        dataObjectBatchApiCallService,
        templateRevisionBatchApiCallService,
        legacyApi,
    )
    rootNode = NodeUtils.cloneNode(rootNode)

    const doGather = async () => {
        sceneManager.updateRoot(rootNode)
        await firstValueFrom(sceneManager.sync(false))

        // const originalParameters = Nodes.Meta.getAllParameters(rootNode);

        const prevDataObjectCacheState = DataObject.enableCache()

        // displayScene.renderSuspended

        const configMap = new Map<string, ConfigurationInfo<T>>()
        type PermutationProposal = Record<string, string>
        const visitedProposals = new Set<string>()
        const pendingProposals: PermutationProposal[] = []
        let countUpperBound = 1
        while (true) {
            if (!running) {
                // exportProgress$.error("aborted");
                break
            }
            const configString = sceneManager.getConfigurationString(includeAllSubTemplateInputs)
            // console.log("configString:", configString);
            if (!configMap.has(configString)) {
                const descriptors = sceneManager.getInterfaceForNode(rootNode)
                const curConfigs: PermutationProposal = {}
                const configNames: string[] = []
                for (const descriptor of descriptors) {
                    if (Nodes.Meta.isConfigInput(descriptor)) {
                        curConfigs[descriptor.id] = descriptor.value
                        const valueName = descriptor.variants.find((x) => x.id === descriptor.value)?.name
                        configNames.push(`${descriptor.name}: ${valueName}`)
                    }
                }
                configMap.set(configString, {
                    configString,
                    name: configNames.join(", "),
                    extra: extraCb?.(sceneManager),
                })
                for (const descriptor of descriptors) {
                    if (Nodes.Meta.isConfigInput(descriptor)) {
                        const config = descriptor
                        for (const variant of config.variants.filter((x) => !x.excludeFromPermutations)) {
                            if (variant.id !== config.value) {
                                const proposal: PermutationProposal = {
                                    ...curConfigs,
                                    [config.id]: variant.id,
                                }
                                const key = sortedJSONStringify(proposal)
                                if (!visitedProposals.has(key)) {
                                    // console.log("Adding proposal:", key);
                                    ++countUpperBound
                                    visitedProposals.add(key)
                                    pendingProposals.push(proposal)
                                }
                            }
                        }
                    }
                }
            }
            console.log(`Proposals remaining: ${pendingProposals.length} / ${countUpperBound}`)
            // exportProgress$.next({type: 'progress', current: (countUpperBound - pendingProposals.length), total: countUpperBound});
            if (pendingProposals.length > 0) {
                // pop entry from candidate list
                const proposal = pendingProposals.shift()
                Nodes.Meta.setAllParameters(rootNode, proposal)
                sceneManager.markNodeChanged(rootNode)
                // await firstValueFrom(editor.sync(false));
                await firstValueFrom(sceneManager.sync(false))
            } else {
                break
            }
        }

        DataObject.restoreCacheState(prevDataObjectCacheState)
        // displayScene.dropDeferredUpdates();
        // Nodes.Meta.setAllParameters(rootNode, originalParameters);
        // sceneManager.markNodeChanged(rootNode);

        return Array.from(configMap.values())
    }

    return fromPromise(doGather()).pipe(
        finalize(() => {
            running = false
            sceneManager.destroy()
        }),
    )
}

export function mapOverConfigurations<T = void, R = void>(
    sceneManager: SceneManager,
    rootNode: Nodes.TemplateInstance,
    includeAllSubTemplateInputs: boolean,
    configInfoList: ConfigurationInfo<T>[],
    fn: (sceneManager: SceneManager, configInfo: ConfigurationInfo<T>) => Observable<R>,
    meshDataBatchApiCallService: MeshDataBatchApiCallService,
    dataObjectBatchApiCallService: DataObjectBatchApiCallService,
    templateRevisionBatchApiCallService: TemplateRevisionBatchApiCallService,
    legacyApi: APIService,
): Observable<ConfigurationInfo<R>[]> {
    let running = true

    // create a copy of the root node and a local scene manager, so that the evaluation can happen in the background
    sceneManager = new SceneManager(
        sceneManager.sdk,
        sceneManager.refresh,
        sceneManager.workerService,
        sceneManager.materialGraphManager,
        includeAllSubTemplateInputs,
        meshDataBatchApiCallService,
        dataObjectBatchApiCallService,
        templateRevisionBatchApiCallService,
        legacyApi,
    )
    rootNode = NodeUtils.cloneNode(rootNode)

    const doGather = async () => {
        sceneManager.updateRoot(rootNode)
        await firstValueFrom(sceneManager.sync(false))

        const prevDataObjectCacheState = DataObject.enableCache()

        const retInfoList: ConfigurationInfo<R>[] = []

        for (const configInfo of configInfoList) {
            if (!running) break
            Nodes.Meta.setAllParameters(rootNode, JSON.parse(configInfo.configString))
            sceneManager.markNodeChanged(rootNode)
            await firstValueFrom(sceneManager.sync(false))
            // need to explicity call this instead of setting syncSolver = true, because there is no background anim tick for the local scene manager
            while (sceneManager.updateAllMeshPositions()) {
                await sleep(1)
            }
            const retVal = await firstValueFrom(fn(sceneManager, configInfo))
            retInfoList.push({
                name: configInfo.name,
                configString: configInfo.configString,
                extra: retVal,
            })
        }

        DataObject.restoreCacheState(prevDataObjectCacheState)

        return retInfoList
    }

    return fromPromise(doGather()).pipe(
        finalize(() => {
            running = false
            sceneManager.destroy()
        }),
    )
}
