import {Component, EventEmitter, inject, Input, input, Output} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {UploadServiceDataObjectFragment} from "@api"
import {CardComponent, CardPlaceholderComponent} from "@common/components/cards"
import {DropFilesComponent} from "@common/components/files"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {Subject} from "rxjs"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {PermissionsService} from "@common/services/permissions/permissions.service"

/**
 * Card showing a thumbnail, some text and optional overlaid UI elements
 */
@Component({
    selector: "cm-entity-card",
    templateUrl: "./entity-card.component.html",
    styleUrls: ["./entity-card.component.scss"],
    standalone: true,
    imports: [DropFilesComponent, CardComponent, CardPlaceholderComponent, MatTooltipModule, DataObjectThumbnailComponent],
})
export class EntityCardComponent<
    EntityType extends {
        name?: string | null
        legacyId?: number
        organization?: {id?: string; name?: string | null}
        galleryImage?: {
            id: string
        }
    },
> {
    @Input() entityLink: Array<unknown> | undefined
    @Input() entityQueryParamsHandling: "merge" | "preserve" | "" | null = null
    @Input() squareFormat = false
    @Output() selectEntity: EventEmitter<EntityType> = new EventEmitter<EntityType>()
    @Input() enableDropZone = false
    @Output() dropZoneUpload = new EventEmitter<UploadServiceDataObjectFragment>()
    dropZoneActive = {value: false}
    unsubscribe = new Subject<void>()
    // Once support for default content is added: https://github.com/angular/angular/issues/12530 the default inputs should be removed.
    @Input() defaultTitle = true
    @Input() defaultSubtitle = true
    @Input() defaultThumbnail = true
    @Input() showTooltip = true

    $entity = input<EntityType | undefined>(undefined)

    protected permission = inject(PermissionsService)
    private uploadService = inject(UploadGqlService)
    $can = this.permission.$to

    hasName(entity: EntityType): entity is EntityType & {name: string} {
        return "name" in entity
    }

    hasCustomer(entity: EntityType): entity is EntityType & {customer: number} {
        return "customer" in entity
    }

    hasArticleId(entity: EntityType): entity is EntityType & {articleId: number} {
        return "articleId" in entity
    }

    async handleDroppedFiles(files: File[]) {
        if (files.length > 1) throw new Error("Only one file can be uploaded at a time")
        const organizationId = this.$entity()?.organization?.id
        if (organizationId) {
            const dataObject = await this.uploadService.createAndUploadDataObject(files[0], {organizationId}, {showUploadToolbar: true, processUpload: true})
            this.dropZoneUpload.emit(dataObject)
        } else {
            console.warn("Cannot handle dropped files because of missing customer ID")
            return
        }
    }

    protected readonly ThumbnailLayout = ThumbnailLayout
}
