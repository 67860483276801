<div class="cm-maps-export-main">
    <div class="cm-image-pointer" (click)="expanded = !expanded">
        @if (!expanded) {
            <i class="fa-regular fa-chevron-down"></i>
        }
        @if (expanded) {
            <i class="fa-regular fa-chevron-up"></i>
        }
    </div>
    <div class="cm-maps-export-name">
        {{ info.config.root.name }}
    </div>
    @if (info.config.state == "done") {
        @if (info.dataObjectId !== undefined) {
            <i
                class="far fa-arrow-down-to-line cm-image-pointer"
                [matTooltip]="'Download export: ' + info.config.root.name"
                (click)="downloadDataObject(info.dataObjectId)"
            >
            </i>
        }
        @if (deleteMapsExport && $can().delete.materialMapsExport()) {
            <i
                [matTooltip]="'Delete export: ' + info.config.root.name"
                (click)="$event.stopPropagation(); deleteMapsExport(info)"
                class="fa-regular fa-trash cm-image-remove cm-image-pointer"
            ></i>
        }
        @if (info.updateAvailable && $can().read.material(null, "sourceUpdates")) {
            <i [matTooltip]="'Source updates available'" class="fa-regular fa-circle-exclamation"></i>
        }
        @if (renameMapsExport && $can().update.material(null, "name")) {
            <i
                [matTooltip]="'Rename export: ' + info.config.root.name"
                (click)="$event.stopPropagation(); renameMapsExport(info)"
                class="fa-light fa-wrench cm-image-pointer"
            ></i>
        }
    } @else {
        <i class="fa-solid fa-rotate"></i>
        @if (deleteMapsExport && $can().delete.materialMapsExport()) {
            <i
                [matTooltip]="'Delete export: ' + info.config.root.name"
                (click)="$event.stopPropagation(); deleteMapsExport(info)"
                class="fa-regular fa-trash cm-image-remove cm-image-pointer"
            ></i>
        }
    }
</div>
@if (expanded) {
    <div class="cm-subtitle">
        <table>
            @if (info.config.sourceInfo) {
                <tr>
                    <td>{{ "Source" }}</td>
                    <td>{{ sourceInfoRequestToString(info.config.sourceInfo) }}</td>
                </tr>
            }
            @if (info.summary.workflow.length > 0) {
                <tr>
                    <td>Workflow</td>
                    <td>{{ mapWorkflows(info.summary.workflow).join(", ") }}</td>
                </tr>
            }
            @if (info.summary.engine.length > 0) {
                <tr>
                    <td>Engine</td>
                    <td>{{ mapEngines(info.summary.engine).join(", ") }}</td>
                </tr>
            }
            @if (info.summary.normalY.length > 0) {
                <tr>
                    <td>Normal</td>
                    <td>{{ mapNormalYs(info.summary.normalY).join(", ") }}</td>
                </tr>
            }
            @if (info.summary.format.length > 0) {
                <tr>
                    <td>Format</td>
                    <td>{{ info.summary.format.join(", ") | uppercase }}</td>
                </tr>
            }
            @if (info.summary.resolution.length > 0) {
                <tr>
                    <td>Resolution</td>
                    <td>{{ mapResolutions(info.summary.resolution).join(", ") }}</td>
                </tr>
            }
        </table>
    </div>
}
