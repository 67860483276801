import {inject, Injectable} from "@angular/core"
import {PriceGraphState} from "@api"
import {loadPricingData} from "@pricing/helpers"
import {UnCachedNodeGraphResult} from "@cm/lib/graph-system/evaluators/uncached-node-graph-result"
import {PricedItem, PricingContext} from "@cm/lib/pricing/nodes/core"

//It has happened that some nodes were not registered in the factory after graph deserialization, so we import them here to make sure they are registered
import "@cm/lib/pricing/nodes/conditional-amount-group-node"
import "@cm/lib/pricing/nodes/conditional-amount-list"
import "@cm/lib/pricing/nodes/conditional-amount-node"
import "@cm/lib/pricing/nodes/configuration-group-node"
import "@cm/lib/pricing/nodes/configuration-list"
import "@cm/lib/pricing/nodes/flatten-pricelist"
import "@cm/lib/pricing/nodes/price-container"
import "@cm/lib/pricing/nodes/price-list"
import "@cm/lib/pricing/nodes/priced-item-node"
import {PriceGraphAccessor} from "@pricing/models/pricing/price-graph"
import {SdkService} from "@common/services/sdk/sdk.service"
import "@cm/lib/pricing/nodes/variant-condition-node"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"

@Injectable({
    providedIn: "root",
})
export class PricingService {
    templateUuid: string | undefined = undefined
    priceGraphAccessor: PriceGraphAccessor | undefined = undefined
    pricingContext: PricingContext | undefined = undefined

    sdk = inject(SdkService)
    configMenuLegacyService = inject(ConfigMenuLegacyService)
    configMenuService = inject(ConfigMenuService)

    async load(templateUuid: string) {
        this.pricingContext = undefined
        this.priceGraphAccessor = undefined

        const {template} = await this.sdk.gql.pricingServiceGetTemplate({templateId: templateUuid})
        const organizationId = template?.organizationId
        if (!organizationId) return

        const pricingData = await loadPricingData(this.sdk, organizationId, templateUuid, PriceGraphState.Ready)
        if (!pricingData) return

        this.priceGraphAccessor = new PriceGraphAccessor(pricingData.rootNode)
        this.pricingContext = pricingData.pricingContext
    }

    getPricesAsList(): Promise<PricedItem[]> {
        if (!this.priceGraphAccessor || !this.pricingContext) return Promise.resolve([])
        this.setSelectedVariants()
        return new UnCachedNodeGraphResult(this.priceGraphAccessor.rootNode, this.pricingContext).run()
    }

    static computeTotalPrice(prices: PricedItem[]): number {
        return prices.reduce((acc, item) => acc + item.price * item.amount, 0)
    }

    private setSelectedVariants() {
        if (!this.priceGraphAccessor || !this.pricingContext) return

        const selectedVariantsLegacy = this.configMenuLegacyService.getSelectedVariants()
        const selectedVariants = this.configMenuService.getSelectedVariants()

        if (selectedVariants.length > 0) this.priceGraphAccessor.setCurrentConfigurations(selectedVariants)
        else if (selectedVariantsLegacy.length > 0) this.priceGraphAccessor.setCurrentConfigurations(selectedVariantsLegacy)
    }
}
