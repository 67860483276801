import {Component, effect, inject, input} from "@angular/core"
import {TemplatesSectionItemFragment} from "@api"
import {EntityCardComponent} from "@common/components/entity/entity-card/entity-card.component"
import {SectionComponent} from "@common/components/item"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Labels} from "@labels"
import {TaskListComponent} from "@platform/components/tasks/task-list/task-list.component"

type ItemType = {id: string; __typename: "Material"} | {id: string; __typename: "Template"} | null | undefined

@Component({
    selector: "cm-templates-section",
    standalone: true,
    imports: [SectionComponent, ToggleComponent, TaskListComponent, EntityCardComponent, PlaceholderComponent],
    templateUrl: "./templates-section.component.html",
    styleUrl: "./templates-section.component.scss",
})
export class TemplatesSectionComponent {
    $item = input<ItemType>(null)
    $title = input<string | null | undefined>(null, {alias: "title"})

    permission = inject(PermissionsService)
    sdk = inject(SdkService)
    $can = this.permission.$to

    templates: TemplatesSectionItemFragment[] | undefined | null = undefined

    constructor() {
        effect(() => this.loadTemplates(this.$item()))
    }

    loadTemplates(item: ItemType) {
        this.templates = undefined
        switch (item?.__typename) {
            case "Material": {
                const materialId = this.$item()!.id
                this.sdk.gql.templatesSectionItemsForMaterial({materialId}).then(({material}) => {
                    // use map to prevent duplicates
                    const templatesById = new Map<string, TemplatesSectionItemFragment>()
                    material.revisions.forEach((revision) =>
                        revision.templateRevisions.forEach((templateRevision) => templatesById.set(templateRevision.template.id, templateRevision.template)),
                    )
                    // sort by legacyId to ensure consistent order
                    this.templates = Array.from([...templatesById.values()].sort((a, b) => a.legacyId - b.legacyId))
                })
                break
            }
            case "Template": {
                const templateId = item!.id
                this.sdk.gql.templatesSectionItemsForTemplate({templateId}).then(({template}) => {
                    // use map to prevent duplicates
                    const templatesById = new Map<string, TemplatesSectionItemFragment>()
                    template.revisions.forEach((revision) =>
                        revision.referencedBy.forEach((templateRevision) => templatesById.set(templateRevision.template.id, templateRevision.template)),
                    )
                    // sort by legacyId to ensure consistent order
                    this.templates = Array.from([...templatesById.values()].sort((a, b) => a.legacyId - b.legacyId))
                })
                break
            }
            default: {
                this.templates = null
            }
        }
    }

    get showSection() {
        return this.templates === undefined || !!this.templates?.length
    }

    get showPlaceholders() {
        return this.templates === undefined
    }

    protected readonly Labels = Labels
}
