import {Component, ChangeDetectorRef, OnInit, AfterViewInit, inject, ViewChild, ElementRef} from "@angular/core"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {MaterialSocket} from "@app/material-editor/models/material-socket"
import {SdkService} from "@common/services/sdk/sdk.service"
import {ImageTextureSetNodeTextureSetRevisionFragment, ImageTextureSetNodeTextureSetRevisionInfoFragment, JobState, TextureType} from "@api"
import {MaterialNodeBase} from "@material-editor/models/material-node-base"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {getRevisionTitle} from "@app/textures/utils/texture-set-revision-utils"
import {Enums} from "@enums"
import {Labels} from "@labels"
import tippy from "tippy.js"

@Component({
    selector: "cm-base-image-texture-set",
    standalone: true,
    imports: [],
    templateUrl: "./base-image-texture-set.component.html",
    styleUrl: "./base-image-texture-set.component.scss",
})
export abstract class BaseImageTextureSetComponent<T> implements OnInit {
    @ViewChild("nodeBase", {static: true}) nodeBase!: MaterialNodeBase
    @ViewChild("textureIcon", {static: false}) textureIcon: ElementRef<HTMLElement> | undefined
    @ViewChild("textureInfoTemplate", {static: false}) textureInfoTemplate: ElementRef<HTMLElement> | undefined

    abstract outputs: Record<string, MaterialSocket>
    abstract inputs: Record<string, MaterialSocket>
    abstract typeInfo: MaterialNodeType<T>

    constructor(public changeDetectionRef: ChangeDetectorRef) {}

    sdk = inject(SdkService)

    textureSetRevisionInfos?: ImageTextureSetNodeTextureSetRevisionInfoFragment[] = []
    textureSetRevision?: ImageTextureSetNodeTextureSetRevisionFragment
    textureSetRevisionVersion?: number
    textureSetLegacyId?: number
    thumbnailId?: string

    ngOnInit() {
        void this.fromNodeParameters()
    }

    ngAfterViewInit() {
        this.setupTextureInfo()
    }

    abstract fromNodeParameters(): Promise<void>
    abstract updateNodeParameters(applyDefaults: boolean): void
    abstract resetNodeParameters(): void
    abstract resetParameters(): void
    abstract setThumbnail(): void

    async loadTextureSetRevision(revisionId: string | undefined) {
        if (revisionId === this.textureSetRevision?.id) {
            return
        }

        if (!revisionId) {
            this.resetParameters()
        } else {
            const {textureSetRevision} = await this.sdk.gql.imageTextureSetNodeTextureSetRevision({
                textureSetRevisionId: revisionId,
            })
            this.textureSetRevision = textureSetRevision
        }
    }

    async loadTextureSet(textureSetLegacyId: number | undefined) {
        if (this.textureSetLegacyId === textureSetLegacyId) {
            return
        }
        if (!textureSetLegacyId) {
            this.resetParameters()
            return
        }

        this.textureSetLegacyId = textureSetLegacyId
        const {textureSet} = await this.sdk.gql.imageTextureSetNodeTextureSet({
            textureSetLegacyId,
        })
        this.textureSetRevisionInfos = textureSet?.textureSetRevisions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) ?? []
    }

    setupTextureInfo() {
        this.changeDetectionRef.detectChanges()
        if (!this.textureInfoTemplate || !this.textureInfoTemplate.nativeElement) throw Error("textureInfo template not found")
        const textureInfoTemplate = this.textureInfoTemplate.nativeElement
        if (!textureInfoTemplate) {
            return
        }
        if (!this.textureIcon || !this.textureIcon.nativeElement) {
            return
        }
        textureInfoTemplate.style.display = "block"
        tippy(this.textureIcon.nativeElement, {
            content: textureInfoTemplate,
            allowHTML: true,
            placement: "right",
            arrow: false,
            offset: [0, 20],
            interactive: false,
            interactiveBorder: 30,
            trigger: "mouseenter",
        })
    }

    // FIXME: this is a quick hack to update the material graph after changing the texture revision.
    triggerGraphUpdate(): void {
        this.nodeBase?.emitParameterChange({
            node: this.nodeBase.node,
            type: "updateGraph",
            parameter: {id: "", type: "string", value: ""},
        })
    }

    setTextureSetRevisionVersion(revisionId: string | undefined) {
        this.textureSetRevisionVersion =
            revisionId && this.textureSetRevisionInfos
                ? this.textureSetRevisionInfos.length - this.textureSetRevisionInfos.findIndex((revision) => revision.id === revisionId)
                : undefined
    }

    get isProcessing() {
        return this.textureSetRevision && this.textureSetRevision.editsProcessingJob && this.textureSetRevision.editsProcessingJob.state !== JobState.Complete
    }

    protected readonly Enums = Enums
    protected readonly Labels = Labels
    protected readonly ThumbnailLayout = ThumbnailLayout
    protected readonly getRevisionTitle = getRevisionTitle
}
