import {inject, Injectable} from "@angular/core"
import {FilesService} from "@app/common/services/files/files.service"
import {PdfGenerationService} from "@app/common/services/pdf-generation/pdf-generation.service"
import {StlExportService} from "@app/common/services/stl-export/stl-export.service"
import {captureSnapshot as captureSnapshotExt} from "@app/template-editor/helpers/snapshot"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {Parameters} from "@cm/lib/templates/nodes/parameters"
import {Subject} from "rxjs"

@Injectable()
export class ActionMenuService {
    private readonly jpegQuality = 98
    private stlExportService = inject(StlExportService)
    private pdfService = inject(PdfGenerationService)

    private creatingStlSubject = new Subject<boolean>()
    creatingStl$ = this.creatingStlSubject.asObservable()

    private toggleFullscreenSubject = new Subject<void>()
    toggleFullscreen$ = this.toggleFullscreenSubject.asObservable()

    toggleFullscreen(): void {
        this.toggleFullscreenSubject.next()
    }

    async downloadStl(templateGraph: TemplateGraph, parameters: Parameters, sceneManagerService: SceneManagerService): Promise<void> {
        this.creatingStlSubject.next(true)
        const zippedStls = await this.stlExportService.exportStlFiles(templateGraph, parameters, sceneManagerService)
        FilesService.downloadFile("stl-export.zip", zippedStls)
        this.creatingStlSubject.next(false)
    }

    captureSnapshot(threeSceneManagerService: ThreeSceneManagerService): void {
        const snapshot = captureSnapshotExt(threeSceneManagerService, "image/jpeg", 95)
        FilesService.downloadFile("snapshot.jpg", snapshot)
    }

    async downloadPdf(threeSceneManagerService: ThreeSceneManagerService, templateId: string): Promise<void> {
        const snapshot = captureSnapshotExt(threeSceneManagerService, "image/jpeg", this.jpegQuality)
        const pdf = await this.pdfService.generatePdfNew(snapshot, templateId)
        FilesService.downloadFile("Configuration.pdf", pdf)
    }
}
