import {DecimalPipe} from "@angular/common"
import {Component, computed, input} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActionPanelRowComponent} from "@common/components/menu"
import {FilesizePipe} from "@common/pipes/filesize/filesize.pipe"
import {FormatDatePipe} from "@common/pipes/format-date/format-date.pipe"
import {TimeAgoPipe} from "@common/pipes/time-ago/time-ago.pipe"
import {Labels} from "@labels"
import {Size2Like} from "@cm/lib/math/size2"
import {ImageColorSpace} from "@api"

@Component({
    selector: "cm-tiling-info-panel",
    templateUrl: "./tiling-info-panel.component.html",
    styleUrls: ["./tiling-info-panel.component.scss"],
    standalone: true,
    imports: [ActionPanelRowComponent, DecimalPipe, FilesizePipe, MatTooltipModule, TimeAgoPipe, FormatDatePipe],
})
export class TilingInfoPanelComponent {
    $createdByName = input<string | undefined>(undefined, {alias: "createdByName"})
    $createdAt = input<string | undefined>(undefined, {alias: "createdAt"})
    $updatedByName = input<string | undefined>(undefined, {alias: "updatedByName"})
    $updatedAt = input<string | undefined>(undefined, {alias: "updatedAt"})
    $imageColorSpace = input<ImageColorSpace | undefined>(undefined, {alias: "imageColorSpace"})
    $pxPerCm = input<number | Size2Like | undefined>(undefined, {alias: "pxPerCm"})
    $resultResolution = input<Size2Like>({width: 0, height: 0}, {alias: "resultResolution"})
    $resultFileSize = input<number | undefined>(undefined, {alias: "resultFileSize"})
    $displacement = input<number | undefined>(undefined, {alias: "displacement"})
    $individualPxPerCm = computed(() => {
        const pxPerCm = this.$pxPerCm()
        if (!pxPerCm) {
            return {width: 1, height: 1}
        }
        const widthPxPerCm = typeof pxPerCm === "number" ? pxPerCm : pxPerCm.width
        const heightPxPerCm = typeof pxPerCm === "number" ? pxPerCm : pxPerCm.height
        return {width: widthPxPerCm, height: heightPxPerCm}
    })
    $uniformPxPerCm = computed(() => {
        const pxPerCm = this.$individualPxPerCm()
        return (pxPerCm.width + pxPerCm.height) / 2
    })
    $individualDpi = computed(() => {
        const pxPerCm = this.$individualPxPerCm()
        return {width: pxPerCm.width * 2.54, height: pxPerCm.height * 2.54}
    })
    $uniformDpi = computed(() => {
        return this.$uniformPxPerCm() * 2.54
    })
    $hasSquarePixels = computed(() => {
        const pxPerCm = this.$individualPxPerCm()
        return Math.abs(pxPerCm.width - pxPerCm.height) < 1e-8
    })
    $resultSize = computed(() => {
        const pxPerCm = this.$individualPxPerCm()
        const resultResolution = this.$resultResolution()
        return {
            width: resultResolution.width / pxPerCm.width,
            height: resultResolution.height / pxPerCm.height,
        }
    })

    protected readonly Labels = Labels
}
