import {ElementRef, inject, Injectable} from "@angular/core"
import {
    BasicTagInfoFragment,
    BatchMaterialRenderDetailsFragment,
    DataObjectAssignmentType,
    DataObjectDetailsForBatchDownloadServiceFragment as DataObjectDetailsFragment,
    ImageDownloadDetailsForBatchDownloadServiceFragment as ImageDownloadDetailsFragment,
    MaterialBatchOperationFragment,
    MaterialsGridItemFragment,
} from "@api"
import {MaterialMapsExporterService} from "@common/services/material-maps-exporter/material-maps-exporter.service"
import {DownloadResolution, mapDownloadResolutionToImageResolution} from "@cm/lib/api-gql/data-object"
import {MaterialMapsExporter} from "@cm/lib/materials/material-maps-exporter"
import {BatchDownloadData, BatchMenuItem} from "@common/models/item/list-item"
import {getDefaultExportConfigs} from "@common/helpers/material-maps-exporter"
import {MaterialAssetsRenderingService} from "@common/services/material-assets-rendering/material-assets-rendering.service"
import {NameAssetFromSchemaService} from "@common/services/name-asset-from-schema/name-asset-from-schema.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {FlatThumbnailOptionLabels, Labels} from "@labels"
import {ActivatedRoute, Router} from "@angular/router"
import {PermissionsService} from "@app/common/services/permissions/permissions.service"

@Injectable()
export class MaterialBatchOperationService {
    sdk = inject(SdkService)
    router = inject(Router)
    route = inject(ActivatedRoute)
    permission = inject(PermissionsService)
    $can = this.permission.$to

    public constructor(
        protected uploadService: UploadGqlService,
        protected matAssetsRenderingSvc: MaterialAssetsRenderingService,
        protected matMapsExporterSvc: MaterialMapsExporterService,
        protected nameAssetFromSchemaSvc: NameAssetFromSchemaService,
    ) {}

    private getBatchMaterialRenderDetails = async (
        materialListItem: MaterialBatchOperationFragment,
        dataObjectAssignmentType?: DataObjectAssignmentType,
    ): Promise<BatchMaterialRenderDetailsFragment> => {
        const material = (
            await this.sdk.gql.getBatchMaterialRenderDetails({
                id: materialListItem.id,
                dataObjectAssignmentFilter: dataObjectAssignmentType ? {assignmentType: [dataObjectAssignmentType]} : {},
                includeAssignments: !!dataObjectAssignmentType,
            })
        ).material

        return material
    }

    getStateLabels() {
        if (!this.$can().read.menu("runGenericBatchOperations")) return []
        return Array.from(Labels.MaterialState.values())
    }

    getExtraBatchActions = (): BatchMenuItem<MaterialsGridItemFragment>[] => {
        const result: BatchMenuItem<MaterialsGridItemFragment>[] = []

        if (this.$can().read.menu("batchStartMaterialImageJobs")) {
            result.push(
                {groupLabel: "Flat Thumbnails", items: this.getThumbnailBatchItems()},
                {groupLabel: "Tileable", items: this.getTileableBatchItems()},
                {groupLabel: "Export", items: this.getExportBatchItems()},
            )
        }

        if (this.$can().read.menu("runGenericBatchOperations")) {
            result.push({
                groupLabel: "Advanced",
                items: this.getAdvancedBatchItems(),
            })
        }

        return result
    }

    // ActivatedRoute has a different context when injected into this service, use the one from MaterialsGridComponent for correct navigation.
    getAdvancedActions = (fileInput: ElementRef, _route: ActivatedRoute) => {
        if (!this.$can().read.menu("runGenericBatchOperations")) return []
        return [
            {
                label: "Batch create materials",
                operation: () => {
                    fileInput.nativeElement.click()
                },
            },
        ]
    }

    private getThumbnailBatchItems = (): BatchMenuItem<MaterialBatchOperationFragment>["items"] => {
        const createThumbnails: BatchMenuItem<MaterialBatchOperationFragment>["items"] = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => ({
            label: flatOption.label,
            operation: async (materialListItem) => {
                const itemDetails = await this.getBatchMaterialRenderDetails(materialListItem, flatOption.state.dataObjectAssignmentType)
                if (!itemDetails?.latestCyclesRevision) {
                    return false
                }

                await this.matAssetsRenderingSvc.generateThumbnail(materialListItem.id, flatOption.state)

                return true
            },
            tooltip: `Resolution: ${flatOption.state.resolution} x ${flatOption.state.resolution} px`,
        }))

        const deleteThumbnails: BatchMenuItem<MaterialBatchOperationFragment>["items"] = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => ({
            label: `Delete ${flatOption.label}`,
            operation: async (materialListItem) => {
                const itemDetails = await this.getBatchMaterialRenderDetails(materialListItem, flatOption.state.dataObjectAssignmentType)
                const dataObjectId = itemDetails?.dataObjectAssignments?.[0]?.dataObject?.id
                if (!dataObjectId) {
                    return false
                }

                await this.sdk.gql.materialsGridDeleteThumbnail({dataObjectId})
                return true
            },
        }))

        const nameThumbnails: BatchMenuItem<MaterialBatchOperationFragment>["items"] = Array.from(FlatThumbnailOptionLabels.values()).map((flatOption) => ({
            label: `Name ${flatOption.label} from schema`,
            operation: async (materialListItem) => {
                const itemDetails = await this.getBatchMaterialRenderDetails(materialListItem, flatOption.state.dataObjectAssignmentType)
                const dataObjectId = itemDetails?.dataObjectAssignments?.[0]?.dataObject?.id
                if (!dataObjectId) {
                    return false
                }

                await this.nameAssetFromSchemaSvc.renameRenderFromSchema(materialListItem.id, flatOption.state.dataObjectAssignmentType)
                return true
            },
        }))

        return createThumbnails.concat(deleteThumbnails, nameThumbnails)
    }

    private getTileableBatchItems = (): BatchMenuItem<MaterialBatchOperationFragment>["items"] => {
        return [
            {
                label: "Create Tileable",
                operation: async (materialListItem) => {
                    const itemDetails = await this.getBatchMaterialRenderDetails(materialListItem, DataObjectAssignmentType.MaterialTileableRender)
                    if (!itemDetails?.latestCyclesRevision) {
                        return false
                    }

                    await this.matAssetsRenderingSvc.generateTile(materialListItem.id)
                    return true
                },
            },
            {
                label: "Delete tileable",
                operation: async (materialListItem) => {
                    const itemDetails = await this.getBatchMaterialRenderDetails(materialListItem, DataObjectAssignmentType.MaterialTileableRender)
                    const dataObjectId = itemDetails?.dataObjectAssignments?.[0]?.dataObject?.id
                    if (!dataObjectId) {
                        return false
                    }

                    await this.sdk.gql.materialsGridDeleteThumbnail({dataObjectId})
                    return true
                },
            },
            {
                label: "Name tileable from schema",
                operation: async (materialListItem) => {
                    const itemDetails = await this.getBatchMaterialRenderDetails(materialListItem, DataObjectAssignmentType.MaterialTileableRender)
                    const dataObjectId = itemDetails?.dataObjectAssignments?.[0]?.dataObject?.id
                    if (!dataObjectId) {
                        return false
                    }

                    await this.nameAssetFromSchemaSvc.renameRenderFromSchema(materialListItem.id, DataObjectAssignmentType.MaterialTileableRender)
                    return true
                },
            },
        ]
    }

    private getExportBatchItems = (): BatchMenuItem<MaterialBatchOperationFragment>["items"] => {
        const createExport: BatchMenuItem<MaterialBatchOperationFragment>["items"] = getDefaultExportConfigs().map((exportRequest) => ({
            label: exportRequest.displayName,
            operation: async (materialListItem) => {
                const configDB = await this.matMapsExporterSvc.fetchExportConfigsDB(materialListItem)
                const defaultConfig = configDB.find((config) => config.config.root.name === exportRequest.root.name)
                if (defaultConfig) {
                    return false
                }

                const itemDetails = await this.getBatchMaterialRenderDetails(materialListItem)
                if (!itemDetails?.latestCyclesRevision) {
                    return false
                }

                await this.matMapsExporterSvc.generateMapsExport(exportRequest, materialListItem)
                return true
            },
        }))

        const deleteExport: BatchMenuItem<MaterialBatchOperationFragment>["items"] = getDefaultExportConfigs().map((exportRequest) => ({
            label: `Delete ${exportRequest.displayName}`,
            operation: async (materialListItem) => {
                const configDB = await this.matMapsExporterSvc.fetchExportConfigsDB(materialListItem)
                const defaultConfig = configDB.find((config) => config.config.root.name === exportRequest.root.name)
                if (!defaultConfig) {
                    return false
                }

                await this.matMapsExporterSvc.deleteMapsExport(defaultConfig)
                return true
            },
        }))

        return createExport.concat(deleteExport)
    }

    private getAdvancedBatchItems = (): BatchMenuItem<MaterialBatchOperationFragment>["items"] => {
        const setArticleId = async (materialListItem: MaterialBatchOperationFragment, test: boolean) => {
            const {
                material: {tagAssignments: materialRangeTagAssignments},
            } = await this.sdk.gql.materialBatchOperationMaterialWithMaterialRangeTags({legacyId: materialListItem.legacyId})
            const materialRangeTag: BasicTagInfoFragment | undefined = materialRangeTagAssignments?.[0]?.tag

            const regex = /\b\d{3}\b/
            const filteredText = materialListItem.name?.match(regex)
            if (materialRangeTag && materialRangeTag.description && filteredText?.length == 1) {
                if (test) {
                    return false
                }
                await this.sdk.gql.materialsGridUpdateMaterial({input: {id: materialListItem.id, articleId: materialRangeTag.description + filteredText[0]}})
                return true
            } else {
                console.log(`${materialListItem.legacyId} -> Article ID could not be determined`)
                return false
            }
        }
        return [
            {
                label: "Fill article ID (test)",
                operation: async (item) => setArticleId(item, true),
            },
            {
                label: "Fill article ID",
                operation: async (item) => setArticleId(item, false),
            },
        ]
    }

    private getBatchDownloadDetail = async (
        materialListItem: MaterialBatchOperationFragment,
        dataObjectAssignment: DataObjectAssignmentType,
    ): Promise<ImageDownloadDetailsFragment> => {
        const material = (
            await this.sdk.gql.getImageDownloadDetailsForBatchDownloadService({
                materialId: materialListItem.id,
                assignmentType: dataObjectAssignment,
            })
        ).material

        if (material.dataObjectAssignments.length === 0) throw new Error(`No ${dataObjectAssignment} found for material ${materialListItem.id}`)
        if (material.dataObjectAssignments.length > 1) console.warn(`Multiple ${dataObjectAssignment} assignments found for material ${materialListItem.id}`)

        return material.dataObjectAssignments[0].dataObject
    }

    private makeDownloadItem = async (
        materialDownloadData: DataObjectDetailsFragment | undefined | null,
        _materialListItem: MaterialBatchOperationFragment,
    ) => {
        if (!materialDownloadData) throw new Error("No data for download found")
        const path = materialDownloadData.originalFileName
        return [{path: path, dataObjectLegacyId: materialDownloadData.legacyId}]
    }

    getBatchDownloadData = (): BatchDownloadData<MaterialBatchOperationFragment>[] => {
        if (!this.$can().read.menu("batchDownloadMaterials")) return []

        const tileableImageJpegExports: BatchDownloadData<MaterialBatchOperationFragment>[] = Object.values(DownloadResolution).map((resolution) => {
            return {
                pathInMenu: `Tileable Image/JPEG/${mapDownloadResolutionToImageResolution(resolution)}`,
                getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                    const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, DataObjectAssignmentType.MaterialTileableRender)
                    if (batchDownloadDetails.mediaType === "application/zip") throw new Error("No jpeg data for download found")
                    return this.makeDownloadItem(batchDownloadDetails[`jpeg${resolution}`], materialListItem)
                },
            }
        })

        const tileableImageTiffExport: BatchDownloadData<MaterialBatchOperationFragment> = {
            pathInMenu: "Tileable Image/TIFF",
            getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, DataObjectAssignmentType.MaterialTileableRender)
                if (batchDownloadDetails.mediaType === "application/zip") throw new Error("No tiff data for download found")
                return this.makeDownloadItem(batchDownloadDetails.tiff, materialListItem)
            },
        }

        const thumbnailExports: BatchDownloadData<MaterialBatchOperationFragment>[] = Array.from(FlatThumbnailOptionLabels.values()).flatMap((flatOption) => {
            const jpegPaths = Object.values(DownloadResolution).map((resolution) => ({
                pathInMenu: `Flat Thumbnails/${flatOption.label}/JPEG/${mapDownloadResolutionToImageResolution(resolution)}`,
                getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                    const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, flatOption.state.dataObjectAssignmentType)
                    return this.makeDownloadItem(batchDownloadDetails[`jpeg${resolution}`], materialListItem)
                },
            }))

            const tiffPath = {
                pathInMenu: `Flat Thumbnails/${flatOption.label}/TIFF`,
                getDataObjectsForDownload: async (materialListItem: MaterialBatchOperationFragment) => {
                    const batchDownloadDetails = await this.getBatchDownloadDetail(materialListItem, flatOption.state.dataObjectAssignmentType)
                    return this.makeDownloadItem(batchDownloadDetails.tiff, materialListItem)
                },
            }

            return [...jpegPaths, tiffPath]
        })

        const pbrExports: BatchDownloadData<MaterialBatchOperationFragment>[] = getDefaultExportConfigs().reduce((acc, exportRequest) => {
            const targetFormats: MaterialMapsExporter.Format[] = ["jpeg", "tiff"]
            if (targetFormats.some((format) => exportRequest.root.name.includes(format))) {
                const config = {
                    pathInMenu: "PBR Exports/" + exportRequest.displayName,
                    getDataObjectsForDownload: async (
                        materialListItem: MaterialBatchOperationFragment,
                    ): Promise<{path: string; dataObjectLegacyId: number}[]> => {
                        const allExports = await this.matMapsExporterSvc.queryMapsExportsForMaterial({legacyId: materialListItem.legacyId})
                        const targetExport = allExports.find((x) => x.config.root.name === exportRequest.root.name)
                        if (!targetExport || !targetExport.dataObjectId) throw new Error("Data not found for pbr export")

                        const path = (materialListItem.name ? materialListItem.name : "unnamed material") + "-" + targetExport.config.root.name + ".zip"
                        return [{path: path, dataObjectLegacyId: targetExport.dataObjectId}]
                    },
                }
                acc.push(config)
            }
            return acc
        }, [] as BatchDownloadData<MaterialBatchOperationFragment>[])

        return [...tileableImageJpegExports, tileableImageTiffExport, ...thumbnailExports, ...pbrExports]
    }
}
