// @ts-strict-ignore
import {AsyncPipe, NgClass, NgTemplateOutlet} from "@angular/common"
import {HttpParams} from "@angular/common/http"
import {ChangeDetectorRef, Component, DoCheck, inject, NgZone, OnDestroy, OnInit, ViewChild} from "@angular/core"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatListModule} from "@angular/material/list"
import {MatMenuModule} from "@angular/material/menu"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ActivatedRoute, Router} from "@angular/router"
import {ContentTypeModel, JobState, MaterialFilterInput, MaterialInspectorMaterialFragment} from "@api"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {HdriService} from "@app/editor/services/hdri.service"
import {Material} from "@app/legacy/api-model/material"
import {ImageProcessingNodes} from "@cm/lib/image-processing/image-processing-nodes"
import {postProcessingGraph, PostProcessingSettings} from "@cm/lib/image-processing/render-post-processing"
import {arGltfGenerationTask, arUsdzGenerationTask} from "@cm/lib/job-task/ar"
import {ImageProcessingInput, ImageProcessingOutput, imageProcessingTask} from "@cm/lib/job-task/image-processing"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {PictureRenderJobOutput} from "@cm/lib/job-task/rendering"
import {ObjectId, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {CentroidAccumulator} from "@cm/lib/templates/utils/scene-geometry-utils"
import {graphToJson, jsonToGraph} from "@cm/lib/utils/graph-json"
import {dataURLToBlob, queueDeferredTask, sleep} from "@cm/lib/utils/utils"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {DropFilesComponent} from "@common/components/files"
import {ListItemComponent} from "@common/components/item"
import {GridListComponent} from "@common/components/lists"
import {ConfigMenulegacyComponent} from "@common/components/menu"
import {LoadingSpinnerComponent, LoadingSpinnerIconComponent} from "@common/components/progress"
import {createTaskProgressOperator, ObservableTaskManager, ProgressInfo, TaskProgressEvent} from "@common/helpers/tasks/observable-task-manager"
import {quaternionToAngleDegrees} from "@common/helpers/utils/math-utils"
import {combineLatestZeroOrMore, fileToArrayBuffer, GridSize, join, UtilsService} from "@legacy/helpers/utils"
import {Matrix4, Vector3} from "@cm/lib/math"
import {CmmMesh, CmmMeshFormat} from "@common/models/cmm/cmm-mesh"
import {CmmMeta, CmmModel} from "@common/models/cmm/cmm-model"
import {ConfiguratorParameterType} from "@cm/lib/templates/configurator-parameters"
import {Settings} from "@common/models/settings/settings"
import {encodeConfiguratorURLParams} from "@common/helpers/viewers"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {FilesService} from "@common/services/files/files.service"
import {HotkeyLayerId, Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RenderingService} from "@common/services/rendering/rendering.service"
import {AnnotationInspectorComponent} from "@editor/components/annotation-inspector/annotation-inspector.component"
import {CameraInspectorComponent} from "@editor/components/camera-inspector/camera-inspector.component"
import {ConfigGroupInspectorComponent} from "@editor/components/config-inspector/config-group-inspector/config-group-inspector.component"
import {ConfigVariantInspectorComponent} from "@editor/components/config-inspector/config-variant-inspector/config-variant-inspector.component"
import {ConnectionInspectorComponent} from "@editor/components/connection-inspector/connection-inspector.component"
import {DecalInspectorComponent} from "@editor/components/decal-inspector/decal-inspector.component"
import {FloatingControlsComponent} from "@editor/components/floating-controls/floating-controls.component"
import {GenericNodeInspectorComponent} from "@editor/components/generic-node-inspector/generic-node-inspector.component"
import {GroupInspectorComponent} from "@editor/components/group-inspector/group-inspector.component"
import {GuideInspectorComponent} from "@editor/components/guide-inspector/guide-inspector.component"
import {HdriLightInspectorComponent} from "@editor/components/hdri-light-inspector/hdri-light-inspector.component"
import {LightInspectorComponent} from "@editor/components/light-inspector/light-inspector.component"
import {MaterialInspectorComponent} from "@editor/components/material-inspector/material-inspector.component"
import {MeshInspectorComponent} from "@editor/components/mesh-inspector/mesh-inspector.component"
import {PostProcessingInspectorComponent} from "@editor/components/post-processing-inspector/post-processing-inspector.component"
import {RenderInspectorComponent} from "@editor/components/render-inspector/render-inspector.component"
import {mapRenderOutputToPostProcessingInput, RenderOutputViewerComponent} from "@editor/components/render-output-viewer/render-output-viewer.component"
import {ScenePropertiesInspectorComponent} from "@editor/components/scene-properties-inspector/scene-properties-inspector.component"
import {InteractiveSceneViewComponent, SceneViewComponent} from "@editor/components/scene-view/scene-view.component"
import {SurfaceDefinerComponent} from "@editor/components/surface-definer/surface-definer.component"
import {TemplateInputInspectorComponent} from "@editor/components/template-input-inspector/template-input-inspector.component"
import {TemplateInspectorComponent} from "@editor/components/template-inspector/template-inspector.component"
import {TemplateOutputInspectorComponent} from "@editor/components/template-output-inspector/template-output-inspector.component"
import {ValueInspectorComponent} from "@editor/components/value-inspector/value-inspector.component"
import {convertOBJToDracoAndPLY, convertPLYToDraco} from "@editor/helpers/mesh-processing"
import {ConfigurationInfo, gatherAllConfigurations, mapOverConfigurations} from "app/legacy/helpers/ar-viewer-export"
import {Selection, SelectionEvent} from "@editor/helpers/scene/selection"
import {TransformControls, TransformMode} from "@editor/helpers/scene/transformation"
import {DisplayScene, IEditor, InteractiveSceneView, SceneViewManager} from "@editor/models/editor"
import {EditorService} from "@editor/services/editor.service"
import {WebAssemblyWorkerService} from "@editor/services/webassembly-worker.service"
import {DataObject, DataObjectType} from "@legacy/api-model/data-object"
import {DataObjectAssignment, DataObjectAssignmentType} from "@legacy/api-model/data-object-assignment"
import {MaterialRevision} from "@legacy/api-model/material-revision"
import {Template} from "@legacy/api-model/template"
import {TemplateRevision} from "@legacy/api-model/template-revision"
import {EntityType} from "@legacy/models/entity-type"
import {UploadService} from "@legacy/services/upload/upload.service"
import {SelectMaterialComponent} from "@platform/components/materials/select-material/select-material.component"
import {ExporterYed} from "@platform/helpers/simple-graph"
import {RuntimeGraphExportOptionsComponent} from "app/templates/template-publisher/graph-exporter/runtime-graph-export-options/runtime-graph-export-options.component"
import {RuntimeGraphExporter} from "app/templates/template-publisher/graph-exporter/runtime-graph-exporter"
import {TemplateGraphExporter} from "app/templates/template-publisher/graph-exporter/template-graph-exporter"
import {SelectionService} from "app/templates/template-publisher/selection.service"
import {TemplateTreeComponent} from "app/templates/template-publisher/template-tree/template-tree.component"
import {SceneManager} from "app/templates/template-system/scene-manager"
import {TemplateService} from "app/templates/template.service"
import {
    BehaviorSubject,
    concatMap,
    filter,
    finalize,
    forkJoin,
    from as observableFrom,
    from,
    interval,
    map,
    mapTo,
    mergeMap,
    Observable,
    of as observableOf,
    of,
    shareReplay,
    Subject,
    Subscription,
    switchMap,
    take,
    takeUntil,
    tap,
    toArray,
} from "rxjs"
import {InspectorSectionComponent} from "@template-editor/components/inspectors/inspector-section/inspector-section.component"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {downloadFromMemory} from "@common/helpers/utils/download-from-memory"
import {TriggeredDialogComponent} from "@common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {DialogService} from "@common/services/dialog/dialog.service"
import {isNewTemplateSystem, TemplateEditorType} from "@app/templates/helpers/editor-type"
import {createLinkToEditorFromPictures, createLinkToEditorFromTemplates} from "@app/common/helpers/routes"
import {MeshDataBatchApiCallService} from "@app/templates/template-system/mesh-data-batch-api-call.service"
import {APIService} from "@legacy/services/api/api.service"
import {DataObjectBatchApiCallService} from "@app/templates/template-system/data-object-batch-api-call.service"
import {TemplateRevisionBatchApiCallService} from "@app/templates/template-system/template-revision-batch-api-call.service"
import {cameraLookAt} from "@cm/lib/templates/utils/camera-utils"

type JobAssignmentType = "render" | "postProcess"

@Component({
    selector: "cm-template-publisher",
    templateUrl: "./template-publisher.component.html",
    styleUrls: ["./template-publisher.component.scss"],
    providers: [EditorService],
    standalone: true,
    imports: [
        LoadingSpinnerComponent,
        ButtonComponent,
        RoutedDialogComponent,
        MatMenuModule,
        ConfigMenulegacyComponent,
        ListItemComponent,
        MemoizePipe,
        AsyncPipe,
        NgClass,
        LoadingSpinnerIconComponent,
        MatTooltipModule,
        TemplateTreeComponent,
        MeshInspectorComponent,
        DecalInspectorComponent,
        MaterialInspectorComponent,
        ConfigGroupInspectorComponent,
        ConfigVariantInspectorComponent,
        ConnectionInspectorComponent,
        TemplateInspectorComponent,
        GuideInspectorComponent,
        GroupInspectorComponent,
        ValueInspectorComponent,
        TemplateInputInspectorComponent,
        LightInspectorComponent,
        HdriLightInspectorComponent,
        CameraInspectorComponent,
        InspectorSectionComponent,
        SceneViewComponent,
        AnnotationInspectorComponent,
        ScenePropertiesInspectorComponent,
        PostProcessingInspectorComponent,
        RenderInspectorComponent,
        GenericNodeInspectorComponent,
        SurfaceDefinerComponent,
        MatProgressBarModule,
        DropFilesComponent,
        InteractiveSceneViewComponent,
        RenderOutputViewerComponent,
        MatListModule,
        GridListComponent,
        ToggleComponent,
        FloatingControlsComponent,
        TemplateOutputInspectorComponent,
        NgTemplateOutlet,
        SelectMaterialComponent,
        TriggeredDialogComponent,
    ],
})
export class TemplatePublisherComponent implements OnInit, OnDestroy, IEditor, DoCheck {
    @ViewChild("mainSceneView") mainSceneView: InteractiveSceneViewComponent
    @ViewChild("cameraView") cameraView: SceneViewComponent
    @ViewChild("templateTree") templateTree: TemplateTreeComponent
    @ViewChild("selectMaterialDialog") selectMaterialDialog: SelectMaterialComponent
    @ViewChild("surfaceDefiner") surfaceDefiner: SurfaceDefinerComponent

    private unsubscribe: Subject<void> = new Subject<void>()
    private jobRefresh$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined) //TODO: use Apollo cache for watch/refresh
    displayScene: DisplayScene
    entity: Template
    entityRevision?: TemplateRevision
    viewManager: SceneViewManager
    dialogSizes = DialogSize
    gridSizes = GridSize
    showSceneMaterials: boolean
    sceneMaterials: {materialReference: Nodes.Material; material: Material | undefined}[] = []
    NodeUtils = NodeUtils
    Material = Material
    materialFilters?: MaterialFilterInput
    hdris: {name: string; id: number}[] = []
    totalTasks = 0
    completeTasks = 0
    sceneNodesAccessor = () => this.sceneManager?.getAllSceneNodes() ?? []
    rootNode: Nodes.TemplateInstance
    templateGraph: Nodes.TemplateGraph
    sceneManager: SceneManager
    nodeSorter: NodeUtils.NodeSorter
    modified = false
    //TODO: Remove these. These are temporary values
    sideBarMode: "structure" | "interface" | "variations" = "structure"
    activeCameraNode?: SceneNodes.Camera
    trackActiveCamera = false
    readonly enableDebugFeatures: boolean
    private hotkeyLayerId: HotkeyLayerId
    private _mainViewMode: "3dedit" | "image" | "configurator" = "3dedit"
    /* When we switch back between the main view tabs after initialization, three.js loses environment maps.
    The environment maps should be reloaded for this case. After updating three.js to the latest revision probably we
    won't need this. */
    private reloadEnvMapsAfterLoading = false
    selectionMgr: Selection
    jobsForVariation: {
        render: {state: JobState} | undefined
        postProcess: {state: JobState} | undefined
    } = {
        render: undefined,
        postProcess: undefined,
    }

    changeDetectorRef = inject(ChangeDetectorRef)
    dialog = inject(DialogService)
    files = inject(FilesService)
    private hdriService = inject(HdriService)
    private materialGraphService = inject(MaterialGraphService)
    organizations = inject(OrganizationsService)
    permission = inject(PermissionsService)
    private refreshService = inject(RefreshService)
    private sdk = inject(SdkService)
    $can = this.permission.$to

    constructor(
        private ngZone: NgZone,
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationsService,
        public authService: AuthService,
        public utils: UtilsService,
        private workerService: WebAssemblyWorkerService,
        private uploadService: UploadService,
        public editorService: EditorService,
        public selectionService: SelectionService,
        public templateService: TemplateService,
        private renderingSvc: RenderingService,
        private matDialog: MatDialog,
        private runtimeGraphExporterOptionsDialog: MatDialog,
        private hotkeys: Hotkeys,
        private configMenuService: ConfigMenuLegacyService,
        private meshDataBatchApiCallService: MeshDataBatchApiCallService,
        private dataObjectBatchApiCallService: DataObjectBatchApiCallService,
        private templateRevisionBatchApiCallService: TemplateRevisionBatchApiCallService,
        private legacyApi: APIService,
    ) {
        this.entity = route.snapshot.data.templateData.template
        this.entityRevision = route.snapshot.data.templateData.revision

        this.editorService.organizationLegacyId = this.entity.customer
        this.editorService.addTask = this.addTask.bind(this)

        this.enableDebugFeatures = this.$can().read.template(null, "debug")

        // register hotkeys
        this.hotkeyLayerId = hotkeys.createLayer() // create a layer since this is a modal dialog
    }

    ngOnInit() {
        this.materialFilters = {hasCyclesMaterial: true}

        this.displayScene = new DisplayScene(this.workerService, this.materialGraphService, this.hdriService, {
            showGrid: true,
            editMode: true,
            backgroundColor: [0.9, 0.9, 0.9],
            textureResolution: "2000px",
            ambientLight: true,
        })

        this.viewManager = new SceneViewManager(this.displayScene)

        this.sceneManager = new SceneManager(
            this.sdk,
            this.refreshService,
            this.workerService,
            this.materialGraphService,
            true,
            this.meshDataBatchApiCallService,
            this.dataObjectBatchApiCallService,
            this.templateRevisionBatchApiCallService,
            this.legacyApi,
        )
        this.sceneManager.errorReport$.pipe(takeUntil(this.unsubscribe)).subscribe((error) => {
            this.showError(error)
        })

        Material.enableCache()
        this.workerService.startInitialWorkers()

        this.transformControls = new TransformControls(this.displayScene, this.viewManager.viewsNavigationAccessor.bind(this.viewManager))

        this.displayScene.initTransformControls(this.transformControls)

        this.addBinding(
            this.viewManager.viewAdded$.pipe(
                takeUntil(this.unsubscribe),
                map((view) => {
                    if (view instanceof InteractiveSceneView) {
                        this.transformControls.addView(view.renderView)
                    }
                }),
            ),
        )

        this.addBinding(
            this.viewManager.viewRemoved$.pipe(
                takeUntil(this.unsubscribe),
                map((view) => {
                    this.transformControls.removeView(view.renderView)
                }),
            ),
        )

        this.addBinding(
            this.viewManager.viewCameraChanged$.pipe(
                takeUntil(this.unsubscribe),
                map((view) => {
                    this.transformControls.updateViewCamera(view.renderView)
                }),
            ),
        )

        this.addBinding(
            ObservableTaskManager.getInstance().progressSubject.pipe(
                takeUntil(this.unsubscribe),
                map((progressMap: Map<string, ProgressInfo>) => {
                    let totalBytes = 0
                    let completeBytes = 0
                    let totalTasks = 0
                    let completeTasks = 0
                    for (const [taskID, taskInfo] of progressMap) {
                        if (taskInfo.total !== undefined) {
                            if (taskInfo.state === "complete") {
                                totalTasks += 1
                                completeTasks += 1
                                totalBytes += taskInfo.total
                                completeBytes += taskInfo.total
                            } else {
                                totalTasks += 1
                                completeTasks += 0
                                totalBytes += taskInfo.total
                                completeBytes += taskInfo.current
                            }
                        } else {
                            if (taskInfo.state === "complete") {
                                totalTasks += 1
                                completeTasks += 1
                            } else {
                                totalTasks += 1
                                completeTasks += 0
                            }
                        }
                    }
                    this.completeTasks = completeTasks
                    this.totalTasks = totalTasks
                    // console.log(`PROGRESS: ${completeTasks}/${totalTasks} tasks (${Math.round(100 * completeTasks / totalTasks)}%), ${completeBytes}/${totalBytes} bytes (${Math.round(100 * completeBytes / totalBytes)}%)`);
                }),
            ),
        )

        this.addBinding(
            this.transformControls.transformationChanged.pipe(
                takeUntil(this.unsubscribe),
                map((transform) => {
                    this.updateTransformOfObjects(this.selectionService.selectedNodes, this.transformObjectId, this.transformObjectExtraId, transform, true)
                }),
            ),
        )

        this.addBinding(
            this.transformControls.transformationEnd.pipe(
                takeUntil(this.unsubscribe),
                map(() => {
                    this.endTransformOfObjects(this.selectionService.selectedNodes)
                }),
            ),
        )

        // If there is no template revision, the publisher is called with the intention to add a new revision
        if (this.entityRevision) {
            this.setEntityRevision(this.entityRevision)
        } else {
            const latestRevision: TemplateRevision = this.entity.revisions.find((revision) => !isNewTemplateSystem(revision.templateGraph))
            if (latestRevision) {
                if (latestRevision.completed) {
                    this.duplicateEntityRevision(latestRevision)
                } else {
                    this.setEntityRevision(latestRevision)
                }

                this.addTaskBusy(
                    from(this.sdk.gql.templatePublisherTemplateRevision({legacyId: latestRevision.id})).pipe(
                        tap((entityRevision) => {
                            const {templateRevision} = entityRevision

                            const currentPath = this.router.url.split("?")[0] // Get the current path without query params

                            const newPath = (() => {
                                if (!currentPath.includes("/pictures")) {
                                    const templateId = this.route.snapshot.paramMap.get("templateId")
                                    return createLinkToEditorFromTemplates(this.router, templateId, templateRevision.id, TemplateEditorType.Old)
                                } else {
                                    const pictureId = this.route.snapshot.paramMap.get("pictureId")
                                    return createLinkToEditorFromPictures(pictureId, templateRevision.id, TemplateEditorType.Old)
                                }
                            })()

                            this.router.navigate(newPath, {
                                queryParamsHandling: "preserve",
                            })
                        }),
                    ),
                )
            } else {
                this.createNewEntityRevision()
            }
        }

        this.selectionService.nodeSelected.pipe(takeUntil(this.unsubscribe)).subscribe(([node, extraID]) => this.nodeSelected(node, extraID))

        this.sceneManager.modified$.pipe(takeUntil(this.unsubscribe)).subscribe((nodes) => {
            let nonRootModified = false
            let interfaceMaybeChanged = false
            for (const node of nodes) {
                if (node === this.rootNode) continue // ignore changes to the root node
                nonRootModified = true
                //TODO: compare to previous interface structure to determine if variations should be invalidated
                if (!NodeUtils.isPostProcessRender(node)) {
                    interfaceMaybeChanged = true
                }
            }
            if (nonRootModified) {
                this.modified = true
            }
            if (interfaceMaybeChanged) {
                this.invalidateVariations()
            }
        })

        this.addTask(
            from(
                // TODO: do we really need to get all of these ?!
                this.sdk.gql.templatePublisherGetAllHdris().then(
                    ({hdris}) =>
                        (this.hdris = hdris.map((hdri) => ({
                            name: hdri.name,
                            id: hdri.legacyId,
                        }))),
                ),
            ),
        )

        this.ngZone.runOutsideAngular(() => this.animate())

        interval(5000)
            .pipe(
                takeUntil(this.unsubscribe),
                filter(() => {
                    return this.sideBarMode === "variations" && this.mainViewMode !== "image"
                }),
            )
            .subscribe(() => {
                this.jobRefresh$.next()
            })

        interval(5000 * 60)
            .pipe(
                takeUntil(this.unsubscribe),
                filter(() => {
                    return this.mainViewMode === "image" && this.jobsForVariation.render && this.isJobPending(this.jobsForVariation.render)
                }),
            )
            .subscribe(() => {
                this.jobRefresh$.next()
            })

        this.configMenuService.setUiStyle("default")

        this.configMenuService.configSelected$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => this.activateConfigVariantByMeta(event.config, event.variant))

        this.configMenuService.parameterChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((event) => this.setRootParameter(event.input, event.value))
    }

    ngDoCheck(): void {
        //TODO: Replace this with a more explicit / precise trigger, see comment in SceneViewerComponent.ngDoCheck()
        this.configMenuService.setInterfaceLegacy(this.getRootInterface())
    }

    set mainViewMode(mode: "3dedit" | "image" | "configurator") {
        this._mainViewMode = mode

        if (this._mainViewMode === "3dedit") {
            this.reloadEnvMapsAfterLoading = true
        }
    }

    get mainViewMode() {
        return this._mainViewMode
    }

    public downloadTemplateGraph() {
        new TemplateGraphExporter()
            .generateSimpleGraph(this.sceneManager, this.templateGraph, this.entityName)
            .pipe(
                map((simpleGraph) => new ExporterYed().export(simpleGraph)),
                map((yedGraph) => downloadFromMemory("Template-graph " + this.entityName + ".graphml", yedGraph)),
            )
            .subscribe()
    }

    public downloadRuntimeGraph() {
        const dialogRef = this.runtimeGraphExporterOptionsDialog.open(RuntimeGraphExportOptionsComponent, {
            data: {
                mergeCompileAndRun: true,
                outputConstants: false,
            },
        })
        dialogRef.afterClosed().subscribe((options) => {
            if (options) {
                new RuntimeGraphExporter()
                    .generateSimpleGraph(this.sceneManager.getGraphBuilder(), options)
                    .pipe(
                        map((simpleGraph) => new ExporterYed().export(simpleGraph)),
                        map((yedGraph) => downloadFromMemory("Runtime-graph " + this.entityName + ".graphml", yedGraph)),
                    )
                    .subscribe()
            }
        })
    }

    openRuntimeGraphExporterOptions() {}

    get entityName(): string {
        return this.entity.name
    }

    public isJobPending(job: {state: JobState}): boolean {
        switch (job.state) {
            case JobState.Init:
            case JobState.Running:
            case JobState.Runnable:
                return true
            default:
                return false
        }
    }

    public isJobComplete(job: {state: JobState}): boolean {
        return job.state === JobState.Complete
    }

    public isJobError(job: {state: JobState}): boolean {
        switch (job.state) {
            case JobState.Failed:
            case JobState.Cancelled:
                return true
            default:
                return false
        }
    }

    private fetchSceneMaterials() {
        this.sceneMaterials = []
        for (const materialReference of this.nodeSorter.getUsedMaterials()) {
            if (materialReference.type === "materialReference") {
                this.fetchMaterialFromRevisionId(materialReference.materialRevisionId).subscribe((material) => {
                    this.sceneMaterials.push({materialReference: materialReference, material: material})
                })
            } else if (materialReference.type === "switch") {
                this.sceneMaterials.push({materialReference: materialReference, material: undefined})
            }
        }
    }

    private fetchMaterialFromRevisionId(id: number | undefined): Observable<Material> {
        if (id !== undefined && id !== null) {
            return MaterialRevision.get(id).pipe(switchMap((materialRevision) => Material.get(materialRevision.material)))
        } else {
            return observableOf(null)
        }
    }

    displayConfigChanged(): void {
        this.displayScene.setModifiableConfigParams(this.displayConfig)
    }

    defaultEnvironmentMapActive() {
        return this.displayScene.defaultEnvironmentMapActive()
    }

    displayConfig = {
        ambientLight: true,
        showGrid: true,
    }

    editViewConfig = {
        editMode: true,
        showOutlines: true,
        // screenSpacePanning: true
    }

    handleDroppedFiles(files: File[]) {
        if (files.length != 1) {
            this.notificationService.showError("Only one file can be dropped at a time.")
            return
        }
        this.loadModelFile(files[0])
    }

    private animate(): void {
        if (this.destroyed) return
        requestAnimationFrame(this.animate.bind(this))
        // setTimeout(this.animate.bind(this), 1000);
        this.updateAnimationFrame()
    }

    private duplicateEntityRevision(prevEntityRevision: TemplateRevision): void {
        this.addTaskBusy(
            this.createRevisionInstance().pipe(
                switchMap((newEntityRevision) => {
                    const prevGraph = this.sceneManager.importGraph(prevEntityRevision.templateGraph)
                    const newGraph = this.sceneManager.exportGraph({
                        type: "templateGraph",
                        schema: Nodes.currentTemplateGraphSchema,
                        name: prevEntityRevision.templateName,
                        nodes: prevGraph ? [...prevGraph.nodes] : [],
                    })
                    if (newEntityRevision instanceof TemplateRevision) {
                        newEntityRevision.templateGraph = newGraph
                    } else {
                        throw Error("Invalid instance type")
                    }
                    return newEntityRevision.save().pipe(mapTo(newEntityRevision))
                }),
                map((newEntityRevision) => {
                    this.showMessage("Template revision created based on the previous one.")
                    this.setEntityRevision(newEntityRevision)
                }),
            ),
        )
    }

    private showMessage(msg: string) {
        this.notificationService.showInfo(msg)
    }

    private showError(error: any) {
        if (error.alreadyReported) return
        if (!error) error = "Unknown error."
        const errorMsg: string = error.toString()
        if (errorMsg.startsWith("Error: You are not authorized to access")) {
            this.notificationService.showError("You are not authorized to access all the required resources.")
        } else this.notificationService.showError(errorMsg)
    }

    private addBinding(binding: Observable<any>): Subscription {
        return binding.subscribe()
    }

    addTask(task: Observable<any>): void {
        // used by template tree
        task.subscribe(null, (err: any) => {
            console.error(err)
            this.showError(err)
        })
    }

    private _busyTaskSet = new Set<any>()

    get isLoading() {
        return this._busyTaskSet.size > 0
    }

    private addTaskBusy(task: Observable<any>) {
        // workaround for isLoading state changing during Angular template evaluation
        queueDeferredTask(() => {
            this._busyTaskSet.add(task)
            return this.addTask(
                task.pipe(
                    finalize(() => {
                        this._busyTaskSet.delete(task)
                        this.changeDetectorRef.markForCheck()
                    }),
                ),
            )
        })
    }

    nodeSelected(node: Nodes.Node | null, extraID: number | null): void {
        const objId = node && this.sceneManager.getObjectIdForNode(node)

        // extraID/helper toggling
        if (node && NodeUtils.hasTargetPosition(node)) {
            if (objId === this.transformObjectId) {
                if (this.transformObjectExtraId) {
                    extraID = null
                } else {
                    extraID = 1
                }
            }
        } else {
            extraID = null
        }

        this.transformObjectId = objId
        this.transformObjectExtraId = extraID
        this.helpersVisible = objId ? [objId] : null

        this.selectionOutlines = this.selectionService.selectedNodes.reduce(
            (list, node) => {
                const objId = this.sceneManager.getObjectIdForNode(node)
                if (objId) {
                    list.push([objId, undefined])
                }
                return list
            },
            [] as [string, number][],
        )

        this.surfaceDefiner.endModal(false)
        if (node && NodeUtils.isConfigVariant(node)) {
            const context = NodeUtils.findContextForNode(this.templateGraph, node)
            if (NodeUtils.isConfigGroup(context as Nodes.Node)) {
                this.activateConfigVariant(context as Nodes.ConfigGroup, node)
            }
        }
    }

    updateNode(node: Nodes.Node) {
        this.sceneManager.markNodeChanged(node)
    }

    isNodeActive(node: Nodes.Node): boolean {
        return this.sceneManager.isNodeActive(node)
    }

    onSelectMaterial:
        | ((
              selection:
                  | {type: "materialNode"; node: Nodes.Material}
                  | {
                        type: "libraryMaterial"
                        material: MaterialInspectorMaterialFragment
                    }
                  | null,
          ) => void)
        | null = null

    onSelectLibraryMaterial: (material?: {id: string}) => void = async (material: {id: string}) => {
        if (material) {
            return this.sdk.gql.templatePublisherMaterial({id: material.id}).then(({material}) => {
                this.onSelectMaterial({
                    type: "libraryMaterial",
                    material,
                })
            })
        }
    }

    chooseMaterialNode(callback: (node: Nodes.Material | null) => void) {
        // choose an existing material node, or create a new reference, and return that via the supplied callback
        //TODO: use observables?
        this.fetchSceneMaterials()
        this.showSceneMaterials = true
        this.onSelectMaterial = (selection) => {
            this.onSelectMaterial = null
            if (!selection) {
                callback(null)
            } else if (selection.type === "materialNode") {
                callback(selection.node)
            } else if (selection.type === "libraryMaterial") {
                this.sdk.gql.templatePublisherMaterial({id: selection.material.id}).then(({material}) => {
                    if (!material.latestCyclesRevision) {
                        throw new Error("Material has no latest cycles revision")
                    }
                    const newMaterialReference = Nodes.create<Nodes.MaterialReference>({
                        type: "materialReference",
                        name: material.name,
                        materialRevisionId: material.latestCyclesRevision.legacyId,
                    })
                    this.templateGraph.nodes.push(newMaterialReference)
                    this.updateNode(this.templateGraph)
                    callback(newMaterialReference)
                })
            }
        }
        this.dialog.selectInDialog(SelectMaterialComponent, {
            onSelect: this.onSelectLibraryMaterial,
        })
    }

    chooseLibraryMaterial(callback: (material: MaterialInspectorMaterialFragment | null) => void) {
        // choose a library material, and return that via the supplied callback
        //TODO: use observables?
        this.showSceneMaterials = false
        this.onSelectMaterial = (selection) => {
            this.onSelectMaterial = null
            if (!selection) {
                callback(null)
            } else if (selection.type === "libraryMaterial") {
                callback(selection.material)
            }
        }
        this.dialog.selectInDialog(SelectMaterialComponent, {
            onSelect: this.onSelectLibraryMaterial,
        })
    }

    chooseMeshUV(meshNode: Nodes.MeshOrInstance, callback: (u: number, v: number) => void) {
        if (!meshNode) return
        const objectId = this.sceneManager.getObjectIdForNode(meshNode)
        const subscription = this.mainSceneView.sceneView.selectionMgr
            .singleSelection((ids) => objectId)
            .subscribe((event) => {
                callback(event.surfacePointInfo.u, event.surfacePointInfo.v)
            })
    }

    private convertedMeshToCmmMesh(convMesh: any): CmmMesh {
        const cmmMesh = new CmmMesh()
        cmmMesh.id = convMesh.id
        cmmMesh.name = convMesh.name
        cmmMesh.type = convMesh.type
        cmmMesh.dracoBitDepth = convMesh.dracoBitDepth
        cmmMesh.dracoResolution = convMesh.dracoResolution
        cmmMesh.osdUseRenderIterations = convMesh.osdUseRenderIterations
        cmmMesh.osdRenderIterations = convMesh.osdRenderIterations
        cmmMesh.defaultPosition = convMesh.defaultPosition
        cmmMesh.exporterVersion = convMesh.exporterVersion
        cmmMesh.originalFileName = convMesh.originalFileName ?? convMesh.name
        cmmMesh.data.set("drc", convMesh.drcData)
        cmmMesh.data.set("ply", convMesh.plyData)
        return cmmMesh
    }

    private convertOBJFile(file: File, resolution = 0.001): Observable<CmmModel> {
        return fileToArrayBuffer(file).pipe(
            switchMap((buffer) => convertOBJToDracoAndPLY(this.workerService, buffer, resolution)),
            map((converted) => {
                const cmm = new CmmModel()
                cmm.meta = new CmmMeta()
                cmm.meta.originalFileSize = 0
                cmm.meta.coordinateSystem = 1 // 1 = flipYZ;
                cmm.meshes = []
                for (const mesh of converted) {
                    cmm.meshes.push(this.convertedMeshToCmmMesh(mesh))
                }
                return cmm
            }),
        )
    }

    private convertPLYFile(file: File, resolution = 0.001): Observable<CmmModel> {
        return fileToArrayBuffer(file).pipe(
            switchMap((buffer) => convertPLYToDraco(this.workerService, buffer, resolution)),
            map((converted) => {
                converted.plyData = fileToArrayBuffer(file)
                console.log("conversion results:", converted)
                const cmm = new CmmModel()
                cmm.meta = new CmmMeta()
                cmm.meta.originalFileSize = 0
                cmm.meta.coordinateSystem = 1 // 1 = flipYZ;
                cmm.meshes = [this.convertedMeshToCmmMesh(converted)]
                return cmm
            }),
        )
    }

    private loadModelFile(file: File): void {
        const extension: string = UtilsService.getExtension(file.name)
        let pendingCmmModel: Observable<CmmModel>
        if (extension === "cmm") {
            pendingCmmModel = CmmModel.fromCmmFile(file)
        } else if (extension === "obj") {
            //TODO: prompt for conversion options
            pendingCmmModel = this.convertOBJFile(file)
        } else if (extension === "ply") {
            //TODO: prompt for conversion options
            pendingCmmModel = this.convertPLYFile(file)
        } else {
            this.showMessage(`File with unhandled extension: ${extension}`)
            return
        }

        this.addTaskBusy(
            pendingCmmModel.pipe(
                //TODO: handle mesh replacement
                mergeMap((cmmModel: CmmModel) => this.uploadAndAddMeshes(cmmModel, `Uploaded Meshes (${new Date().toLocaleString()})`)),
                //NOTE: a value will come through the observable as soon as all of the uploads _start_, but
                // the completion will only occur when all uploads have _finished_. Thus the use of finalize here to
                // close the upload toolbar:
                finalize(() => {
                    this.uploadService.finalizeUploads()
                    this.templateTree.updateTree()
                }),
            ),
        )
    }

    private uploadAndAddMeshes(cmmModel: CmmModel, groupName: string): Observable<Nodes.Mesh[]> {
        const group = Nodes.create<Nodes.Group>({
            type: "group",
            name: groupName,
            active: true,
            nodes: [],
        })
        this.templateGraph.nodes.push(group)
        this.updateNode(this.templateGraph)

        return combineLatestZeroOrMore(
            cmmModel.meshes.map((cmmMesh) => {
                return this.uploadMesh(cmmMesh).pipe(
                    switchMap((cmmMesh) => {
                        this.sceneManager.meshCacheOld.populateWithCmmMesh(cmmMesh)
                        const meshNode = Nodes.create<Nodes.StoredMesh>({
                            type: "mesh",
                            name: cmmMesh.name,
                            metadata: {
                                dracoBitDepth: cmmMesh.dracoBitDepth,
                                dracoResolution: cmmMesh.dracoResolution,
                                osdUseRenderIterations: cmmMesh.osdUseRenderIterations,
                                osdRenderIterations: cmmMesh.osdRenderIterations,
                                defaultPosition: cmmMesh.defaultPosition,
                                exporterVersion: cmmMesh.exporterVersion,
                            },
                            drcDataObjectId: cmmMesh.dataObjectIds.get("drc"),
                            plyDataObjectId: cmmMesh.dataObjectIds.get("ply"),
                            subdivisionRenderIterations: cmmMesh.osdRenderIterations,
                            surfaces: [],
                            materialAssignments: {},
                            materialSlotNames: {},
                        })
                        if (cmmMesh.defaultPosition) {
                            meshNode.lockedTransform = Matrix4.translation(...cmmMesh.defaultPosition).toArray()
                        }
                        //TODO: avoid fetching mesh data here
                        return DataObject.get(meshNode.drcDataObjectId).pipe(
                            switchMap((dataObject) =>
                                this.sceneManager.meshCacheOld.getMeshData(dataObject, meshNode.plyDataObjectId, {
                                    displaySubdivisionLevel: 0,
                                    renderSubdivisionLevel: 0,
                                }),
                            ),
                            map((meshData) => {
                                for (const materialGroup of meshData.materialGroups) {
                                    const slotStr = `${materialGroup.materialIndex}`
                                    if (meshNode.materialAssignments[slotStr] === undefined) {
                                        meshNode.materialAssignments[slotStr] = null
                                    }
                                }
                                this.sceneManager.markNodeChanged(meshNode)
                                group.nodes.push(meshNode)
                                NodeUtils.sortNamedNodesAlphabetically(group.nodes)
                                this.updateNode(group)
                                return meshNode
                            }),
                        )
                    }),
                )
            }),
        )
    }

    // private suspend() {
    //     ++this.saveSuspendCount;
    //     this.displayScene.renderSuspended = true;
    //     this.suspendAnimation = true;
    // }

    // private resume() {
    //     if (this.saveSuspendCount > 1) {
    //         --this.saveSuspendCount;
    //     } else {
    //         this.saveSuspendCount = 0;
    //         this.displayScene.renderSuspended = false;
    //         this.suspendAnimation = false;
    //         if (this.saveDuringSuspend) {
    //             this.saveDuringSuspend = false;
    //             this.modifiedEvent.next();
    //         }
    //     }
    // }

    private createRevisionInstance() {
        let entityRevision: TemplateRevision
        if (this.entity instanceof Template) {
            entityRevision = new TemplateRevision()
            entityRevision.template = this.entity.id
        } else {
            throw Error("Invalid instance type")
        }
        return entityRevision.save().pipe(mapTo(entityRevision))
    }

    private createNewEntityRevision() {
        this.addTaskBusy(
            this.createRevisionInstance().pipe(
                switchMap((entityRevision) => {
                    this.setEntityRevision(entityRevision)

                    return this.sdk.gql.templatePublisherTemplateRevision({legacyId: entityRevision.id})
                }),
                tap((entityRevision) => {
                    const {templateRevision} = entityRevision

                    const currentPath = this.router.url.split("?")[0] // Get the current path without query params

                    const newPath = (() => {
                        if (!currentPath.includes("/pictures")) {
                            const templateId = this.route.snapshot.paramMap.get("templateId")
                            return createLinkToEditorFromTemplates(this.router, templateId, templateRevision.id, TemplateEditorType.Old)
                        } else {
                            const pictureId = this.route.snapshot.paramMap.get("pictureId")
                            return createLinkToEditorFromPictures(pictureId, templateRevision.id, TemplateEditorType.Old)
                        }
                    })()

                    this.router.navigate(newPath, {
                        queryParamsHandling: "preserve",
                    })
                }),
            ),
        )
    }

    private uploadMesh(cmmMesh: CmmMesh): Observable<CmmMesh> {
        const entityRevision = this.entityRevision
        const customer = this.entity.customer
        const pending: Observable<any>[] = []
        cmmMesh.data.forEach((buffer: ArrayBuffer, format: CmmMeshFormat) => {
            let fileName: string = cmmMesh.originalFileName
            let dataObject: DataObject = null
            const assignment = new DataObjectAssignment()
            if (format === "drc") {
                dataObject = new DataObject()
                fileName += ".drc"
                assignment.type = DataObjectAssignmentType.MeshDataDRC
            } else if (format === "drc_proxy") {
                dataObject = new DataObject()
                fileName += "_proxy.drc"
                assignment.type = DataObjectAssignmentType.MeshDataDRCProxy
            } else if (format === "ply") {
                dataObject = new DataObject()
                fileName += ".ply"
                assignment.type = DataObjectAssignmentType.MeshDataPLY
            } else {
                throw Error(`Unrecognized mesh format: ${format}`)
            }
            dataObject.type = DataObjectType.Mesh
            dataObject.customer = customer
            assignment.dataObject = dataObject
            assignment.objectId = entityRevision.id
            assignment.objectEntityType = entityRevision.entityType
            const file: File = UtilsService.arrayBufferToFile(buffer, fileName, dataObject.contentType)
            // NOTE: This triggers the async upload (waitForCompletion = false), which will emit an event after the data object has been saved,
            // which assigns an ID. This ID is required to set up the new mesh revision. The observable will only be _completed_ once the upload
            // is finished!
            const uploadStarted: Observable<DataObject> = this.uploadService.uploadFile(file, dataObject, true, false, false, false, false)
            pending.push(
                uploadStarted.pipe(
                    switchMap((dataObject) => {
                        cmmMesh.dataObjectIds.set(format, dataObject.id)
                        return assignment.save().pipe(tap(() => entityRevision.dataObjectAssignments.push(assignment)))
                    }),
                ),
            )
        })
        return join(pending).pipe(mapTo(cmmMesh))
    }

    private setEntityRevision(entityRevision: TemplateRevision): void {
        this.entityRevision = entityRevision
        this.sceneManager?.invalidateCaches()
        // this.dropZoneActive.value = false //TODO: check if template is empty

        this.addTaskBusy(
            this.loadEntityRevision(this.entity, entityRevision).pipe(
                tap(() => {
                    this.templateService.templateGraph = this.templateGraph
                }),
            ),
        )
    }

    highlightNode(node: Nodes.Node, highlight: boolean, transient?: boolean, mod?: boolean) {
        //TODO: keep selection and highlight outlines separate
        if (NodeUtils.isObject(node)) {
            const objId = this.sceneManager.getObjectIdForNode(node)
            if (highlight && objId) {
                this.highlightOutlines = [[objId, undefined]]
            } else {
                this.highlightOutlines = null
            }
        } else if (node.type === "surfaceReference") {
            this.highlightSurface(node.object, node.surfaceId)
        } else if (node.type === "rigidRelation") {
            if (highlight) {
                const nodeA = this.sceneManager.resolveSwitch(node.targetA)
                const nodeB = this.sceneManager.resolveSwitch(node.targetB)
                const objIdA = nodeA && this.sceneManager.getObjectIdForNode(nodeA)
                const objIdB = nodeB && this.sceneManager.getObjectIdForNode(nodeB)
                const outlines: any[] = []
                if (objIdA) outlines.push([objIdA, undefined])
                if (!mod && objIdB) outlines.push([objIdB, undefined])
                this.highlightOutlines = outlines
            } else {
                this.highlightOutlines = null
            }
        }
    }

    highlightSurface(objectNode: Nodes.Object | null, surfaceId: string | null) {
        if (objectNode && surfaceId) {
            const objId = this.sceneManager.getObjectIdForNode(objectNode)
            if (objId && NodeUtils.isMeshOrInstance(objectNode)) {
                const meshNode = NodeUtils.resolveInstance(objectNode)
                const surfNode = (meshNode.surfaces as Nodes.MeshSurface[]).find((x) => Nodes.getExternalId(x) === surfaceId)
                this.surfaceDefiner.showSurface(objId, meshNode, surfNode) //TODO: transient hightlight state (non-modal)
            } else {
                //TODO: highlight other surface instances
                this.surfaceDefiner.endModal(false)
            }
        } else {
            this.surfaceDefiner.endModal(false)
        }
    }

    async save() {
        const templateRevision = (await this.sdk.gql.templatePublisherTemplateRevision({legacyId: this.entityRevision.id})).templateRevision
        if (templateRevision.graph && isNewTemplateSystem(templateRevision.graph)) throw Error("Incompatible graph type")
        if (templateRevision.number !== 1) throw Error("Only the first revision can be saved")

        this.modified = false
        const json = this.sceneManager.exportGraph(this.templateGraph)
        this.entityRevision.templateGraph = json
        this.addTask(this.entityRevision.save())
    }

    saveThumbnail(): void {
        const mimeType = "image/jpeg"
        const entity = this.entity
        this.addTask(
            this.mainSceneView.renderCanvasToDataURL(mimeType, 0.75).pipe(
                switchMap((dataURL) => {
                    const file = new File([dataURLToBlob(dataURL)], "thumbnail.jpg", {type: mimeType})
                    const dataObject = new DataObject()
                    dataObject.customer = entity.customer
                    return this.uploadService.uploadFile(file, dataObject)
                }),
                switchMap((dataObject) => {
                    if (entity instanceof Template) return entity.setGalleryImage(dataObject)
                    else return of()
                }),
            ),
        )
    }

    onViewSelectionEvent(event: SelectionEvent) {
        const node = this.sceneManager.getNodeForObjectId(event.objectId)
        this.selectionService.selectNode(node, event.extendSelection, event.materialSlot)
    }

    onViewMouseEvent(event: MouseEvent): void {
        switch (event.type) {
            case "mouseup":
                // if (this.pointTargetRef) {
                //TODO: (this.connectionSolver)
                // this.connectionSolver.setObjectActive(this.pointTargetObjId, true, true, true); //TODO: restore locked
                // this.connectionSolver.removePointTarget(this.pointTargetRef);
                // this.pointTargetRef = null;
                // this.pointTargetObjId = null;
                // }
                break
            case "mousedown":
                const shift = event.getModifierState("Shift")
                const alt = event.getModifierState("Alt")
                if (shift || alt) {
                    const info = this.mainSceneView.surfaceInfoAtPoint(event.clientX, event.clientY)
                    if (info && info.objectId) {
                        // event.stopPropagation();
                        // this.pointTargetObjId = info.objectId;
                        //TODO: manipulate point
                    }
                }
                break
            case "mousemove":
                // if (this.pointTargetRef) {
                //TODO: (this.connectionSolver)
                // event.stopPropagation();
                // const [normScreenX, normScreenY] = this.displayScene.mousePositionToNormalizedScreenCoords(event.clientX, event.clientY);
                // this.connectionSolver.setPointTarget(this.pointTargetRef, normScreenX, normScreenY);
                // }
                break
            default:
                break
        }
    }

    onInitCompletedEvent() {
        if (this.reloadEnvMapsAfterLoading) {
            this.reloadEnvMapsAfterLoading = false
            this.displayScene.reloadEnvironmentMaps(true)
            this.displayScene.update()
        }
    }

    hasRenderNode() {
        return this.getRenderSettings() != null
    }

    hasPostProcessRenderNode() {
        return this.getPostProcessingSettings() != null
    }

    private allVariations?: Observable<ConfigurationInfo[]>

    getAllVariations() {
        if (!this.allVariations) {
            this.allVariations = gatherAllConfigurations(
                this.sceneManager,
                this.rootNode,
                false,
                this.meshDataBatchApiCallService,
                this.dataObjectBatchApiCallService,
                this.templateRevisionBatchApiCallService,
                this.legacyApi,
            ).pipe(
                map((variations) => variations.sort((a, b) => a.name.localeCompare(b.name))),
                shareReplay(1),
            )
            this.addTaskBusy(this.allVariations.pipe(take(1)))
        }
        return this.allVariations
    }

    private invalidateVariations() {
        this.allVariations = undefined
    }

    //TODO: cache this
    getConfigurationString(includeAllSubTemplateInputs: boolean) {
        return this.sceneManager.getConfigurationString(includeAllSubTemplateInputs)
    }

    setConfigurationString(value: string) {
        Nodes.Meta.setAllParameters(this.rootNode, JSON.parse(value))
        this.sceneManager.markNodeChanged(this.rootNode)
        this.addTask(this.sync())
    }

    private assignmentFieldsForVariation(type: JobAssignmentType, configString: string | undefined) {
        const assignmentKey = configString && `${type}:${configString}`
        let itemId: number
        let itemType: ContentTypeModel
        if (this.entityRevision instanceof TemplateRevision) {
            itemId = this.entityRevision.id
            itemType = ContentTypeModel.TemplateRevision
        } else {
            throw Error(`Invalid entity type`)
        }
        return {item: {id: itemId, type: itemType}, assignmentKey}
    }

    private _submitRenderJobForVariation(sceneManager: SceneManager, configString: string, skipExisting: boolean) {
        //TODO: clean this up and move it somewhere else
        const entity = this.entity

        const sceneNodes = sceneManager.getAllSceneNodes()

        const jobName = `Render template ${entity.id} (variation)`

        const assignmentFields = this.assignmentFieldsForVariation("render", configString)

        return this.fetchJobAssignment(assignmentFields).pipe(
            switchMap((assignment) => {
                if (assignment?.job) {
                    if (skipExisting) {
                        return observableOf(null)
                    } else {
                        throw Error(`A render job already exists for this variation. (id: ${assignment.job.id})`)
                    }
                }
                return observableFrom(
                    this.renderingSvc.submitRenderJob({
                        nodes: sceneNodes,
                        final: true,
                        name: jobName,
                        organizationLegacyId: entity.customer,
                    }),
                )
            }),
            switchMap((job) => {
                if (!job) return observableOf(null)
                return from(
                    this.sdk.gql.templatePublisherCreateJobAssignment({
                        input: {
                            objectLegacyId: assignmentFields.item.id,
                            contentTypeModel: assignmentFields.item.type,
                            assignmentKey: assignmentFields.assignmentKey,
                            jobId: job.id,
                        },
                    }),
                ).pipe(map(({createJobAssignment}) => createJobAssignment))
            }),
        )
    }

    private _submitPostProcessingJobForVariation(sceneManager: SceneManager, configString: string, skipExisting: boolean) {
        //TODO: clean this up and move it somewhere else
        const entity = this.entity

        const sceneNodes = sceneManager.getAllSceneNodes()

        const postNode = sceneNodes.find(SceneNodes.RenderPostProcessingSettings.is)
        if (!postNode) throw Error("Cannot find postprocess node")

        const jobName = `Postprocess template ${entity.id} (variation)`

        const renderAssignmentFields = this.assignmentFieldsForVariation("render", configString)
        const postProcessAssignmentFields = this.assignmentFieldsForVariation("postProcess", configString)
        return this.fetchJobAssignment(postProcessAssignmentFields).pipe(
            switchMap((assignment) => {
                if (assignment?.job) {
                    if (skipExisting) {
                        return observableOf(null)
                    } else {
                        throw Error(`A postprocess job already exists for this variation. (id: ${assignment.job.id})`)
                    }
                }
                return this.fetchJobAssignment(renderAssignmentFields).pipe(
                    switchMap((assignment) => {
                        if (!assignment) throw Error("No existing render assignment")
                        return this.fetchJobOutput<PictureRenderJobOutput>(assignment.job)
                    }),
                    map((jobOutput) => ({jobOutput, needMasks: false})),
                    filter(
                        (x) =>
                            (x.jobOutput.renderPasses !== null && x.jobOutput.renderPasses !== undefined) ||
                            (x.jobOutput.preview !== null && x.jobOutput.preview !== undefined),
                    ),
                    mapRenderOutputToPostProcessingInput(),
                    switchMap((postProcessInput) => {
                        let graph: ImageProcessingNodes.Node = postProcessingGraph(postProcessInput, postNode).image
                        graph = {
                            type: "encode",
                            mediaType: "image/tiff",
                            input: {
                                type: "convert",
                                input: graph,
                                channelLayout: "RGBA",
                                dataType: "uint8",
                                sRGB: true,
                            },
                        }
                        const input: ImageProcessingInput = {
                            graph,
                        }
                        const jobGraph = JobNodes.jobGraph<ImageProcessingOutput>(JobNodes.task(imageProcessingTask, {input: JobNodes.value(input)}), {
                            platformVersion: Settings.APP_VERSION,
                        })
                        return from(
                            this.sdk.gql.templatePublisherCreateJob({
                                input: {
                                    name: jobName,
                                    organizationLegacyId: entity.customer,
                                    graph: graphToJson(jobGraph),
                                },
                            }),
                        ).pipe(map(({createJob}) => createJob))
                    }),
                    switchMap((job) => {
                        if (!job) return observableOf(null)
                        return from(
                            this.sdk.gql.templatePublisherCreateJobAssignment({
                                input: {
                                    objectLegacyId: postProcessAssignmentFields.item.id,
                                    contentTypeModel: postProcessAssignmentFields.item.type,
                                    assignmentKey: postProcessAssignmentFields.assignmentKey,
                                    jobId: job.id,
                                },
                            }),
                        ).pipe(map(({createJobAssignment}) => createJobAssignment))
                    }),
                )
            }),
        )
    }

    private _submitJobForVariation(type: JobAssignmentType, sceneManager: SceneManager, configString: string, skipExisting: boolean) {
        if (type === "render") {
            return this._submitRenderJobForVariation(sceneManager, configString, skipExisting)
        } else if (type === "postProcess") {
            return this._submitPostProcessingJobForVariation(sceneManager, configString, skipExisting)
        } else {
            return undefined
        }
    }

    submitJobForVariation(type: JobAssignmentType, configString: string) {
        this.addTaskBusy(
            this._submitJobForVariation(type, this.sceneManager, configString, false).pipe(
                map((assignment) => {
                    this.jobRefresh$.next()
                    this.showMessage(`Job submitted. (id = ${assignment.job.id})`)
                }),
            ),
        )
    }

    submitJobsForAllVariations(type: JobAssignmentType) {
        this.addTaskBusy(
            this.getAllVariations().pipe(
                switchMap((variations) => {
                    const count = variations.length
                    const limitCount = 200
                    if (count > limitCount) {
                        throw Error(`Attempted to start ${count} jobs, which is above the limit of ${limitCount}`)
                    }
                    return this.confirmRenderSubmission(count).pipe(
                        takeUntil(this.unsubscribe),
                        switchMap((confirmed) => {
                            if (!confirmed) return []
                            return mapOverConfigurations(
                                this.sceneManager,
                                this.rootNode,
                                false,
                                variations,
                                (sceneManager, configInfo) => {
                                    return this._submitJobForVariation(type, sceneManager, configInfo.configString, true).pipe(
                                        map((assignment) => {
                                            if (assignment) {
                                                console.log(`Submitted job ${assignment.job.id}`)
                                            } else {
                                                console.log(`Skipped job`)
                                            }
                                            return assignment
                                        }),
                                    )
                                },
                                this.meshDataBatchApiCallService,
                                this.dataObjectBatchApiCallService,
                                this.templateRevisionBatchApiCallService,
                                this.legacyApi,
                            ).pipe(
                                map((retConfigInfo) => {
                                    const numSubmitted = retConfigInfo.filter((x) => x.extra).length
                                    const numSkipped = retConfigInfo.length - numSubmitted
                                    this.showMessage(`Submitted ${numSubmitted} jobs.` + (numSkipped ? ` (Skipped ${numSkipped})` : ""))
                                }),
                            )
                        }),
                    )
                }),
            ),
        )
    }

    private confirmRenderSubmission(variationCount: number): Observable<boolean> {
        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Submit jobs",
                message: `You are submitting ${variationCount} jobs.<br>Are you sure you want to continue?`,
                confirmLabel: "Submit jobs",
                cancelLabel: "Cancel",
            },
        })

        return dialogRef.afterClosed()
    }

    private fetchJobAssignment(fields: {item: {id: number; type: ContentTypeModel}; assignmentKey?: string}) {
        return from(
            this.sdk.gql.templatePublisherJobAssignments({
                filter: {
                    objectLegacyId: fields.item.id,
                    contentTypeModel: fields.item.type,
                    assignmentKey: {equals: fields.assignmentKey},
                },
            }),
        ).pipe(map(({jobAssignments}) => jobAssignments?.[0]))
    }

    private fetchJobForVariation(type: JobAssignmentType, configString: string) {
        return this.fetchJobAssignment(this.assignmentFieldsForVariation(type, configString)).pipe(map((x) => x?.job))
    }

    public fetchDataObjectFromRef(ref?: JobNodes.DataObjectReference): Observable<DataObject> {
        return ref ? DataObject.get(ref.dataObjectId) : observableOf(undefined)
    }

    watchJobsForVariation(configString: string) {
        //TODO; fetch in single query
        return this.jobRefresh$.pipe(
            switchMap(() =>
                join({
                    render: this.fetchJobForVariation("render", configString),
                    postProcess: this.fetchJobForVariation("postProcess", configString),
                }),
            ),
            tap((jobs) => {
                this.jobsForVariation = jobs
            }),
        )
    }

    refreshJob() {
        this.jobRefresh$.next()
    }

    deleteJob(job: {id: string}) {
        return this.addTaskBusy(from(this.sdk.gql.templatePublisherDeleteJob({id: job.id})).pipe(tap(() => this.jobRefresh$.next())))
    }

    deleteAllJobs(type: JobAssignmentType) {
        //TODO: prompt
        const fields = this.assignmentFieldsForVariation(type, undefined)
        if (!fields.item.id && fields.item.type) throw Error()
        return this.addTaskBusy(
            from(
                this.sdk.gql.templatePublisherJobAssignments({
                    filter: {
                        objectLegacyId: fields.item.id,
                        contentTypeModel: fields.item.type,
                    },
                }),
            ).pipe(
                switchMap(({jobAssignments}) => observableFrom(jobAssignments.filter((x) => x.assignmentKey?.startsWith(`${type}:`)).map((x) => x.job))),
                concatMap((job) => this.sdk.gql.templatePublisherDeleteJob({id: job.id})),
                toArray(),
                tap((res) => {
                    this.jobRefresh$.next()
                    this.showMessage(`Deleted ${res.length} jobs.`)
                }),
            ),
        )
    }

    reRenderJob(jobs: {render: {id: string}; postProcess: {id: string} | undefined}) {
        return this.addTaskBusy(
            from(this.sdk.gql.templatePublisherDeleteJob({id: jobs.render.id})).pipe(
                concatMap(() => {
                    if (jobs.postProcess) {
                        return this.sdk.gql.templatePublisherDeleteJob({id: jobs.postProcess.id})
                    } else return observableOf(null)
                }),
                concatMap(() => this._submitRenderJobForVariation(this.sceneManager, this.getConfigurationString(false), false)),
                tap((assignment) => {
                    this.jobRefresh$.next()
                    this.showMessage(`Job submitted. (id = ${assignment.job.id})`)
                }),
            ),
        )
    }

    fetchJobOutput<T = any>(job: {id: string}) {
        return from(this.sdk.gql.templatePublisherJobWithOutput({id: job.id})).pipe(
            map(({job}) => job),
            map((renderJob) => jsonToGraph(renderJob.output) as T),
        )
    }

    watchJobOutput<T = any>(job: {id: string}) {
        //TODO: watch
        return this.fetchJobOutput(job)
    }

    getRenderSettings(): SceneNodes.RenderSettings {
        const nodes = this.sceneManager.getAllSceneNodes()
        return nodes.find(SceneNodes.RenderSettings.is) //TODO: cache this
    }

    getPostProcessingSettings(): PostProcessingSettings {
        const nodes = this.sceneManager.getAllSceneNodes()
        return nodes.find(SceneNodes.RenderPostProcessingSettings.is) //TODO: cache this
    }

    getCameraList() {
        const nodes = this.sceneManager.getAllSceneNodes()
        return nodes.filter(SceneNodes.Camera.is)
    }

    getRootInterface() {
        return this.sceneManager.getInterfaceForNode(this.rootNode)
    }

    copyConfiguratorURL(): void {
        const iface = this.getRootInterface()
        const parameters: Record<string, {type: ConfiguratorParameterType; value: any}> = {}
        const pending: Observable<any>[] = []
        for (const desc of iface) {
            if (Nodes.Meta.isConfigInput(desc)) {
                if (desc.value !== undefined) {
                    parameters[desc.id] = {type: "config", value: desc.value}
                }
            } else if (Nodes.Meta.isMaterialInput(desc)) {
                if (desc.value?.materialRevisionId !== undefined) {
                    pending.push(
                        MaterialRevision.get(desc.value?.materialRevisionId).pipe(
                            map((materialRevision) => {
                                parameters[desc.id] = {type: "material", value: materialRevision.material}
                            }),
                        ),
                    )
                }
            }
        }
        join(pending).subscribe(async () => {
            const {template} = await this.sdk.gql.templatePublisherUuidFromLegacyId({legacyId: this.entity.id})
            const templateUuid = template.id
            const encodedParams = encodeConfiguratorURLParams(parameters) ?? {}
            let url = Settings.CONFIGURATOR_URL + `&templateId=${templateUuid}` // Settings.CONFIGURATOR_URL should already include ?apiVersion=...
            for (const [id, value] of Object.entries(encodedParams)) {
                url += `&${encodeURIComponent(id)}=${encodeURIComponent(value as string | number | boolean)}`
            }
            console.log(url)
            await navigator.clipboard?.writeText(url)
            this.showMessage("Configurator URL copied to clipboard")
        })
    }

    triggerARGeneration(mode: "backend" | "local" | "info"): void {
        //The ar generation task uses the new template system, the code below will not work anymore: The arGltfGenerationTask now ueses the template revision id
        //instead of the template id and the serialized parameters from the new system instead of the configString.
        throw new Error("Not implemented")
        // const templateId = this.entityRevision.template
        // const configString = this.sceneManager.getConfigurationString(true)
        // const configStringB64 = btoa(configString)
        // const pubSubMessage = {
        //     templateId,
        //     configString: configStringB64,
        // }
        // if (mode == "local") {
        //     const url = this.router.serializeUrl(this.router.createUrlTree(["/backend-ar-generation"], {queryParams: pubSubMessage}))
        //     window.open(url)
        // } else if (mode == "backend") {
        //     const gltfGenerationTask = JobNodes.task(arGltfGenerationTask, {
        //         input: JobNodes.struct({
        //             templateId: JobNodes.value(templateId),
        //             configString: JobNodes.value(configStringB64),
        //         }),
        //     })
        //     const usdzGenerationTask = JobNodes.task(arUsdzGenerationTask, {
        //         input: gltfGenerationTask,
        //     })
        //     const graph = JobNodes.jobGraph(JobNodes.list([gltfGenerationTask, usdzGenerationTask]), {
        //         platformVersion: Settings.APP_VERSION,
        //     })
        //     this.sdk.gql
        //         .templatePublisherCreateJob({
        //             input: {
        //                 name: "AR generation",
        //                 organizationLegacyId: this.entity.customer,
        //                 graph: graphToJson(graph),
        //             },
        //         })
        //         .then(() => this.showMessage("Task submitted."))
        // } else {
        //     console.log("Config string:", configString)
        //     console.log("pubSubMessage:", JSON.stringify(pubSubMessage))
        //     this.showMessage("Info printed to console.")
        // }
    }

    exportARFile(format: "gltf") {
        if (format == "gltf") {
            this.addTask(
                this.displayScene.exportScene().pipe(
                    map((glbData) => {
                        FilesService.downloadFile("export.glb", new Blob([glbData], {type: "application/octet-stream"}))
                    }),
                ),
            )
        }
    }

    downloadARFile(format: "gltf" | "usdz"): void {
        const configString = this.sceneManager.getConfigurationString(true)

        const fetchArDataObject = (entityRevision: TemplateRevision, configString: string, format: "gltf" | "usdz") => {
            let filters: HttpParams = new HttpParams()
            filters = filters.set("assigned_to_content_type", EntityType.TemplateRevision)
            filters = filters.set("assigned_to_id", entityRevision.id)
            filters = filters.set("assignment_key", configString)
            filters = filters.set("assignment_type", [DataObjectAssignmentType.CachedTemplateGLTF].join(","))
            return DataObject.getAll(filters).pipe(
                map((dataObjects) => {
                    if (dataObjects.length === 0) return null
                    else if (format === "usdz") return dataObjects[0].getRelated({contentType: "model/vnd.usdz+zip"})
                    else return dataObjects[0]
                }),
            )
        }

        fetchArDataObject(this.entityRevision, configString, format).subscribe((dataObject) => {
            this.files.downloadDataObjectByLegacyId(dataObject.id)
        })
    }

    generateAllARVariants(): void {
        //The ar generation task uses the new template system, the code below will not work anymore: The arGltfGenerationTask now ueses the template revision id
        //instead of the template id and the serialized parameters from the new system instead of the configString.
        throw new Error("Not implemented")
        // const exportProgress$ = new Subject<TaskProgressEvent<null>>()
        // let running = true

        // // Since configs can be dependant on other configs, we need to actually explore the reachable set of permutations one by one :(
        // const doGeneration = async (variations: {configString: string; name: string}[]) => {
        //     const entityRevision = this.entityRevision

        //     console.log(`Triggering AR generation for ${variations.length} configs...`)
        //     const jobs = await Promise.all(
        //         variations.map(({configString}) => {
        //             const configStringB64 = btoa(configString)
        //             const gltfGenerationTask = JobNodes.task(arGltfGenerationTask, {
        //                 input: JobNodes.struct({
        //                     templateId: JobNodes.value(entityRevision.template),
        //                     configString: JobNodes.value(configStringB64),
        //                 }),
        //             })
        //             const usdzGenerationTask = JobNodes.task(arUsdzGenerationTask, {
        //                 input: gltfGenerationTask,
        //             })
        //             const graph = JobNodes.jobGraph(JobNodes.list([gltfGenerationTask, usdzGenerationTask]), {
        //                 platformVersion: Settings.APP_VERSION,
        //             })
        //             return this.sdk.gql
        //                 .templatePublisherCreateJob({
        //                     input: {
        //                         graph: graphToJson(graph),
        //                         name: "AR generation",
        //                         organizationLegacyId: this.entity.customer,
        //                     },
        //                 })
        //                 .then(({createJob}) => createJob)
        //         }),
        //     )

        //     while (true) {
        //         await sleep(5000)
        //         if (!running) {
        //             exportProgress$.error("aborted")
        //             return
        //         }

        //         const {jobs: jobStates} = await this.sdk.gql.templatePublisherJobStates({
        //             filter: {
        //                 id: {in: jobs.map(({id}) => id)},
        //             },
        //         })

        //         const stats = {
        //             inProgress: jobStates.filter(({state}) => state === JobState.Running || state === JobState.Runnable || state === JobState.Init),
        //             done: jobStates.filter(({state}) => state === JobState.Complete),
        //             error: jobStates.filter(({state}) => state === JobState.Failed || state === JobState.Cancelled),
        //         }

        //         exportProgress$.next({
        //             type: "progress",
        //             current: stats.done.length + stats.error.length,
        //             total: jobs.length,
        //         })
        //         console.log(
        //             `Waiting for AR generation to finish (${stats.inProgress.length} remaining, ${stats.error.length} errors, ${stats.done.length} done)`,
        //         )

        //         if (stats.inProgress.length === 0) {
        //             break
        //         }
        //     }

        //     console.log("All AR generation finished.")
        //     exportProgress$.next({type: "complete", value: null})
        //     exportProgress$.complete()
        // }

        // // this.suspend();
        // // let suspended = true;
        // this.addTaskBusy(
        //     gatherAllConfigurations(
        //         this.sceneManager,
        //         this.rootNode,
        //         true,
        //         this.meshDataBatchApiCallService,
        //         this.dataObjectBatchApiCallService,
        //         this.templateRevisionBatchApiCallService,
        //         this.legacyApi,
        //     ).pipe(
        //         switchMap((variations) => {
        //             // this.resume();
        //             // suspended = false;
        //             return this.sync().pipe(map(() => variations))
        //         }),
        //         switchMap((variations) => {
        //             doGeneration(variations)
        //             return exportProgress$
        //         }),
        //         createTaskProgressOperator("Batch export"),
        //         finalize(() => {
        //             running = false
        //             // if (suspended) this.resume();
        //         }),
        //     ),
        // )
    }

    private clearARDataObject(dataObject: DataObject) {
        //Delete related data objects first, then delete the main data object
        return forkJoin(dataObject.related.map((x) => x.delete())).pipe(switchMap(() => dataObject.delete()))
    }

    clearCurrentARCache(): void {
        const configString = this.sceneManager.getConfigurationString(true)

        let filters: HttpParams = new HttpParams()
        filters = filters.set("content_type", this.entityRevision.entityType)
        filters = filters.set("object_id", this.entityRevision.id)
        filters = filters.set("assignment_type", [DataObjectAssignmentType.CachedTemplateGLTF].join(","))
        filters = filters.set("assignment_key", configString)

        this.addTask(
            DataObject.getAll(filters).pipe(
                mergeMap((dataObjects) => {
                    return forkJoin(dataObjects.map((x) => this.clearARDataObject(x)))
                }),
                tap((complete) => {
                    this.showMessage(`Deleted ${complete.length} cached objects.`)
                }),
            ),
        )
    }

    clearAllARCaches(): void {
        let filters: HttpParams = new HttpParams()
        filters = filters.set("assigned_to_content_type", this.entityRevision.entityType)
        filters = filters.set("assigned_to_id", this.entityRevision.id)
        filters = filters.set("assignment_type", [DataObjectAssignmentType.CachedTemplateGLTF].join(","))

        this.addTask(
            DataObject.getAll(filters).pipe(
                mergeMap((dataObjects) => {
                    return forkJoin(dataObjects.map((x) => this.clearARDataObject(x)))
                }),
                tap((complete) => {
                    this.showMessage(`Deleted ${complete.length} cached objects.`)
                }),
            ),
        )
    }

    getRenderSize(): readonly [number, number] | undefined {
        const camera = this.nodeSorter?.getCamera()
        if (!camera) return undefined
        const width = Math.floor(camera.resolutionX)
        const height = Math.floor(camera.resolutionY)
        return [width, height]
    }

    overlayClosed(): void {
        const closeNavigationPath = this.route.snapshot.data.closeNavigationPath ?? "../"
        this.router.navigate([closeNavigationPath], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }

    destroyed = false

    ngOnDestroy(): void {
        this.destroyed = true
        this.unsubscribe.next()
        this.unsubscribe.complete()
        this.jobRefresh$.complete()
        this.transformControls.destroy()
        this.viewManager.destroy()
        this.workerService.terminateJobsAndWorkers()
        this.sceneManager.destroy()
        this.displayScene.destroy()
        this.uploadService.finalizeUploads()
        this.selectionService.clearSelection()
        this.hotkeys.removeLayer(this.hotkeyLayerId)
        Material.disableCache()
    }

    updateTransformOfObjects(nodes: Nodes.Node[], primaryObjectId: ObjectId, extraID: number | null, transform: Matrix4, interactive: boolean) {
        if (nodes.length === 1) {
            this.updateTransformOfNode(nodes[0], extraID, transform, interactive, this.transformControls.mode === TransformMode.Rotate)
        } else if (nodes.length > 0) {
            const sceneManager = this.sceneManager
            const worldMatrix = sceneManager.getWorldTransformForObject(primaryObjectId)
            if (worldMatrix && !transform.equals(worldMatrix)) {
                const delta = transform.multiply(worldMatrix.inverse()) // world-space delta
                this.applyWorldTransformToNodes(nodes, delta, false, interactive)
            }
        }
    }

    updateTransformOfNode(node: Nodes.Node, extraID: number, transform: Matrix4, interactive: boolean, rotateMode = false): void {
        if (node) {
            if (NodeUtils.hasTargetPosition(node)) {
                const objPosition = node.lockedTransform ? Matrix4.fromArray(convertMatrix4_JSON(node.lockedTransform)).getTranslation() : new Vector3(0, 0, 0)
                const objTarget = Vector3.fromArray(convertPosition_JSON(node.target))
                let curTarget = extraID ? transform.getTranslation() : objTarget
                const curPosition = extraID ? objPosition : transform.getTranslation()
                if (rotateMode) {
                    if (extraID) {
                        //TODO: rotation around the target
                    } else {
                        //TODO: fix this
                        let updateMatrix = cameraLookAt(curPosition, curTarget)
                        updateMatrix.setTranslationXYZ(0, 0, 0)
                        updateMatrix = updateMatrix.transpose()
                        updateMatrix = transform.multiply(updateMatrix)
                        let relTarget = curTarget.sub(curPosition)
                        relTarget = updateMatrix.multiplyVector(relTarget)
                        curTarget = relTarget.add(curPosition)
                        // To apply rotation around the axis connecting object and target
                        //const tiltAngle = 25; //deg
                        //const tiltMatrix = Matrix4.rotationZ(tiltAngle);
                        //curTransform = curTransform.multiply(tiltMatrix);
                    }
                }
                const newTransform = cameraLookAt(curPosition, curTarget)
                node.target = curTarget.toArray()
                node.lockedTransform = newTransform.toArray()
                this.sceneManager.markNodeChanged(node)
            } else if (NodeUtils.isTransformable(node)) {
                if (node.lockedTransform) {
                    node.lockedTransform = transform.toArray()
                    this.sceneManager.markNodeChanged(node)
                }
            }
        }
    }

    endTransformOfObjects(nodes: Nodes.Node[]) {
        nodes.forEach((node) => {
            const objId = NodeUtils.isTransformable(node) && this.sceneManager.getObjectIdForNode(node)
            if (objId) {
                this.sceneManager.endTransform(objId)
                this.sceneManager.markNodeChanged(node)
            }
        })
    }

    applyWorldTransformToNodes(nodes: Nodes.Node[], delta: Matrix4, lockTransform: boolean, interactive = false): void {
        const sceneManager = this.sceneManager
        nodes.forEach((node) => {
            if (NodeUtils.isTransformable(node)) {
                const objId = sceneManager.getObjectIdForNode(node)
                let newTransform: Matrix4
                if (objId) {
                    const tb = sceneManager.getWorldTransformForObject(objId)
                    newTransform = delta.multiply(tb)
                    sceneManager.setTransform(objId, newTransform, interactive)
                } else {
                    const tb =
                        (node.lockedTransform ? Matrix4.fromArray(convertMatrix4_JSON(node.lockedTransform)) : sceneManager.defaultTransformForObject(node)) ??
                        Matrix4.identity()
                    newTransform = delta.multiply(tb)
                }
                if (node.lockedTransform || lockTransform) {
                    node.lockedTransform = newTransform.toArray()
                }
                this.sceneManager.markNodeChanged(node)
            }
        })
    }

    resetNodeTransform(node: Nodes.Object) {
        const sceneManager = this.sceneManager
        let origNode = node
        while (NodeUtils.isInstance(origNode)) {
            origNode = origNode.node
        }
        if (node.lockedTransform) {
            let transformMatrix = Matrix4.identity()
            if (origNode && NodeUtils.isObject(origNode)) {
                // This will cause defaultTransformForObject to return the original default.
                node.lockedTransform = undefined
                const defaultTransform = sceneManager.defaultTransformForObject(node)
                if (defaultTransform) {
                    transformMatrix = defaultTransform
                }
            }
            node.lockedTransform = transformMatrix.toArray()
            sceneManager.markNodeChanged(node)
            this.displayScene.update() //TODO: why?
        }
    }

    centroidForNodes(nodes: Nodes.Node[]): Vector3 {
        const centroidAcc = new CentroidAccumulator()
        nodes.forEach((node) => {
            const objId = this.sceneManager.getObjectIdForNode(node)
            const meshData = objId && this.sceneManager.getMeshDataForObject(objId)
            const transform = objId && this.sceneManager.getWorldTransformForObject(objId)
            if (meshData && transform) {
                centroidAcc.accumulate(meshData, transform)
            }
        })
        return Vector3.fromArray(centroidAcc.finalize().centroid)
    }

    worldTransformForNode(node: Nodes.Node): Matrix4 {
        const objId = this.sceneManager.getObjectIdForNode(node)
        return (objId && this.sceneManager.getWorldTransformForObject(objId)) ?? Matrix4.identity()
    }

    centerNodesAtOrigin(nodes: Nodes.Node[], lockTransform: boolean): Vector3 {
        const centroid = this.centroidForNodes(nodes)
        const wDelta = Matrix4.translation(-centroid.x, -centroid.y, -centroid.z)
        this.applyWorldTransformToNodes(nodes, wDelta, lockTransform)
        return centroid
    }

    loadEntityRevision(entity: Template, entityRevision: TemplateRevision): Observable<void> {
        this.displayScene.clearCaches()

        this.entity = entity
        this.entityRevision = entityRevision

        const graphJson = entityRevision.templateGraph

        if (graphJson) {
            if (isNewTemplateSystem(graphJson)) throw new Error("New template system not supported by this editor")
            this.templateGraph = this.sceneManager.importGraph(graphJson)
        } else if (this.route.snapshot.data.ensureScenePropertiesNodeExists) {
            this.templateGraph = {
                type: "templateGraph",
                schema: Nodes.currentTemplateGraphSchema,
                name: `Template ${entity.id}`,
                nodes: [],
            }
        } else {
            this.templateGraph = {
                type: "templateGraph",
                schema: Nodes.currentTemplateGraphSchema,
                name: "Untitled Template",
                nodes: [],
            }
        }

        // Add a scene properties node if it doesn't exist
        if (this.route.snapshot.data.ensureScenePropertiesNodeExists && !NodeUtils.findSceneProperties(this.templateGraph)) {
            this.templateGraph.nodes.push(
                Nodes.create<Nodes.SceneProperties>({
                    type: "sceneProperties",
                    backgroundColor: [1, 1, 1],
                    maxSubdivisionLevel: 1,
                    uiStyle: "default",
                    uiColor: [0, 0, 0],
                    textureResolution: "2000px",
                    showAnnotations: true,
                }),
            )
        }

        this.rootNode = {
            name: "Root Template",
            id: "root",
            type: "templateInstance",
            template: this.templateGraph,
            lockedTransform: Matrix4.identity().toArray(),
        }

        this.nodeSorter = new NodeUtils.NodeSorter(this.templateGraph)

        this.displayScene.renderSuspended = true
        this.sceneManager.defaultCustomerId = this.entity.customer
        this.sceneManager.updateRoot(this.rootNode)
        return this.sync(true)
    }

    updateAnimationFrame(): boolean {
        let didUpdate = false
        if (this.sceneManager?.updateAllMeshPositions()) {
            const nodes = this.sceneManager.getAllSceneNodes()
            this.displayScene.updateAll(nodes)
            this.activeCameraNode = nodes.find(SceneNodes.Camera.is)
            didUpdate = true
        }
        const node = this.transformObjectId && this.sceneManager?.getNodeForObjectId(this.transformObjectId)
        if (node && NodeUtils.hasTargetPosition(node) && this.transformObjectExtraId) {
            this.transformControls.worldMatrix = Matrix4.translation(...convertPosition_JSON(node.target))
        } else if (node && NodeUtils.isTransformable(node) && node.lockedTransform) {
            this.transformControls.worldMatrix = Matrix4.fromArray(node.lockedTransform.map((x) => (typeof x === "string" ? Number(x) : x)))
        } else {
            this.transformControls.worldMatrix = null
        }
        return didUpdate
    }

    captureRelationParams(relation: Nodes.Relation): number[] {
        if (relation.type === "rigidRelation") {
            let directObjRefA = null
            let directObjRefB = null

            if (NodeUtils.isSwitchOf<any>(relation.targetA, NodeUtils.isObject)) {
                if (relation.targetA.nodes.length < 1) {
                    throw new Error("target A is a switch with < 1 nodes")
                }
                for (const node of relation.targetA.nodes) {
                    if (this.sceneManager.isNodeActive(node)) {
                        directObjRefA = node
                        break
                    }
                }
                if (!directObjRefA) {
                    throw new Error("target A is a switch with no active nodes")
                }
            } else {
                directObjRefA = relation.targetA
            }

            if (NodeUtils.isSwitchOf<any>(relation.targetB, NodeUtils.isObject)) {
                if (relation.targetB.nodes.length < 1) {
                    throw new Error("target B is a switch with < 1 nodes")
                }
                for (const node of relation.targetB.nodes) {
                    if (this.sceneManager.isNodeActive(node)) {
                        directObjRefB = node
                        break
                    }
                }
                if (!directObjRefB) {
                    throw new Error("target B is a switch with no active nodes")
                }
            } else {
                directObjRefB = relation.targetB
            }

            const objA = this.sceneManager.getObjectIdForNode(directObjRefA)
            const objB = this.sceneManager.getObjectIdForNode(directObjRefB)
            const matrixA = this.sceneManager.getWorldTransformForObject(objA)
            const matrixB = this.sceneManager.getWorldTransformForObject(objB)
            const {position, quaternion, scale} = matrixA.inverse().multiply(matrixB).decompose()
            return [position.x, position.y, position.z, ...quaternionToAngleDegrees(quaternion)]
        } else if (relation.type === "attachSurfaces") {
            throw new Error("TODO: getRelativeTransformationBetweenSurfaces")
            //const ret = this.connectionSolver.getRelativeTransformationBetweenSurfaces(objA.id, objB.id, relation.id);
            //return ret;
        }
        return null
    }

    activateConfigVariant(config: Nodes.ConfigGroup, node: Nodes.ConfigVariant) {
        Nodes.Meta.setParameter(this.rootNode, Nodes.getExternalId(config, true), Nodes.getExternalId(node, true))
        this.sceneManager.markNodeChanged(this.rootNode)
    }

    activateConfigVariantByMeta(config: Nodes.Meta.ConfigInfo, variant: Nodes.Meta.VariantInfo) {
        Nodes.Meta.setParameter(this.rootNode, config.id, variant.id)
        this.sceneManager.markNodeChanged(this.rootNode)
    }

    setRootParameter(desc: Nodes.Meta.InputDescriptor, value: any) {
        Nodes.Meta.setParameter(this.rootNode, desc.id, value)
        this.sceneManager.markNodeChanged(this.rootNode)
    }

    viewSubTemplate(node: Nodes.TemplateGraph | null) {
        this.rootNode.template = node ?? this.templateGraph
        this.sceneManager.markNodeChanged(this.rootNode)
    }

    async openPriceMapping() {
        const {template} = await this.sdk.gql.getTemplateUuidFromLegacyId({legacyId: this.entity.id})
        //This should open in a new tab for now, which is not directly possible with the router
        const domain = window.location.origin
        const queryParams = {templateuuid: template.id}
        const path = this.router.createUrlTree(["/price-mapping"], {queryParams}).toString()
        const fullPath = `${domain}${path}`
        window.open(fullPath, "_blank")
    }

    async openInNewEditor() {
        const latestRevision = (await this.sdk.gql.templatePublisherLatestTemplateRevision({templateRevisionlegacyId: this.entityRevision.id})).templateRevision
            .template.latestRevision
        if (!latestRevision) throw Error("No latest revision found")

        const currentPath = this.router.url.split("?")[0] // Get the current path without query params
        let newPath: string[] = []

        /*later revisions are always from the new editor. If one exists, navigate to it
        instead of opening the old template graph in the new editor.*/
        let revisionId = this.route.snapshot.paramMap.get("templateRevisionId")
        if (revisionId != latestRevision.id) revisionId = latestRevision.id

        if (currentPath.includes("/pictures")) {
            const pictureId = this.route.snapshot.paramMap.get("pictureId")
            newPath = createLinkToEditorFromPictures(pictureId, revisionId, TemplateEditorType.New)
        } else {
            const templateId = this.route.snapshot.paramMap.get("templateId")
            newPath = createLinkToEditorFromTemplates(this.router, templateId, revisionId, TemplateEditorType.New)
        }

        this.router.navigate(newPath, {
            queryParamsHandling: "preserve",
        })
    }

    getNodeErrors(node: Nodes.Node) {
        return this.sceneManager?.getNodeErrors(node) ?? []
    }

    getAccessibleNodes(fromNode: Nodes.Node): Nodes.Node[] {
        //TODO: filter accessible nodes
        const idList = this.sceneManager.getTopLevelObjectIds()
        if (!idList) return []
        return idList.map((id) => this.sceneManager.getNodeForObjectId(id)).filter((x) => x)
    }

    deleteNodes(nodes: Nodes.Node[]) {
        //TODO: gather all descendents if deleting contexts
        const {deletedNodes, modifiedNodes} = NodeUtils.deleteNodesAndReferences(nodes, this.templateGraph)
        for (const node of modifiedNodes) {
            this.updateNode(node)
        }
        for (const node of deletedNodes) {
            this.selectionService.removeFromSelection(node)
        }
        this.templateTree.updateTree()
    }

    markNodeChanged(node: Nodes.Node): void {
        this.sceneManager.markNodeChanged(node)
    }

    sync(syncRender = true): Observable<void> {
        return this.sceneManager.sync(true).pipe(
            switchMap(() => {
                if (this.displayScene) {
                    if (this.sceneManager) {
                        this.sceneManager.updateAllMeshPositions()
                        const nodes = this.sceneManager.getAllSceneNodes()
                        this.displayScene.updateAll(nodes)
                        this.activeCameraNode = nodes.find(SceneNodes.Camera.is)
                    }
                    return this.displayScene.syncTasks()
                } else {
                    return observableOf(null)
                }
            }),
            switchMap(() => {
                if (syncRender && this.displayScene) {
                    if (this.displayScene.renderSuspended) {
                        this.displayScene.renderSuspended = false
                    }
                    return this.displayScene.syncRender()
                } else {
                    return observableOf(null)
                }
            }),
        )
    }

    transformControls: TransformControls

    private _helpersVisible: ObjectId[] | null = null

    private _targetObjectID: ObjectId | null = null
    private _targetObjectExtraID: number | null = null

    set helpersVisible(helpers: ObjectId[] | null) {
        this._helpersVisible = helpers
        this.displayScene.setHelpersVisible(this._helpersVisible || [])
    }

    get helpersVisible() {
        return this._helpersVisible
    }

    set transformObjectId(value: ObjectId) {
        this._targetObjectID = value
    }

    get transformObjectId() {
        return this._targetObjectID
    }

    set transformObjectExtraId(value: number) {
        this._targetObjectExtraID = value
    }

    get transformObjectExtraId() {
        return this._targetObjectExtraID
    }

    private _selectionOutlines: [ObjectId, number | undefined][] | null = null
    private _highlightOutlines: [ObjectId, number | undefined][] | null = null

    private updateOutlines(): void {
        //TODO: restore outlines when switching back to 3d view
        if (this.mainSceneView) {
            this.mainSceneView.sceneView.setOutlines(this._highlightOutlines ?? this._selectionOutlines)
        }
    }

    set selectionOutlines(outlines: [ObjectId, number | undefined][] | null) {
        this._selectionOutlines = outlines
        this.updateOutlines()
    }

    get selectionOutlines() {
        return this._selectionOutlines
    }

    set highlightOutlines(outlines: [ObjectId, number | undefined][] | null) {
        this._highlightOutlines = outlines
        this.updateOutlines()
    }

    get highlightOutlines() {
        return this._highlightOutlines
    }

    protected readonly DialogSize = DialogSize
}

function stringOrNumberToNumber(item: string | number): number {
    return typeof item === "string" ? Number(item) : item
}

function convertMatrix4_JSON(matrix: (number | string)[]): number[] {
    return matrix.map((x) => stringOrNumberToNumber(x))
}

function convertPosition_JSON(position: [number | string, number | string, number | string]): [number, number, number] {
    return [stringOrNumberToNumber(position[0]), stringOrNumberToNumber(position[1]), stringOrNumberToNumber(position[2])]
}
