import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {DataType, ImageRef, isImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export type ParameterType = {
    sourceImage: ImageRef
    blackLevel: ImageRef | number
    whiteLevel: ImageRef | number
    gamma?: number
    resultImageOrDataType?: ImageRef | DataType
}

export type ReturnType = ImageRef

export const imageOpLevels: ImageOpType<ParameterType, ReturnType> = {
    name: "Levels",

    WebGL2: ({cmdQueue, parameters: {sourceImage, blackLevel, whiteLevel, gamma, resultImageOrDataType}}) => {
        const painter = cmdQueue.createPainter(
            "compositor",
            "levels",
            `
            uniform float u_gamma;

            vec3 getBlackLevel(ivec2 index) {
                ${isImageRef(blackLevel) ? "return texelFetch1(index, ADDRESS_MODE_CLAMP_TO_EDGE).rgb;" : `return vec3(${blackLevel});`}
            }

            vec3 getWhiteLevel(ivec2 index) {
                ${isImageRef(whiteLevel) ? "return texelFetch2(index, ADDRESS_MODE_CLAMP_TO_EDGE).rgb;" : `return vec3(${whiteLevel});`}
            }

            vec3 getGamma() {
                return vec3(u_gamma);
            }

            vec3 getOffset(vec3 blackLevel, vec3 whiteLevel) {
                return -blackLevel;
            }

            vec3 getScale(vec3 blackLevel, vec3 whiteLevel) {
                return vec3(1.0) / max(vec3(1e-3), whiteLevel - blackLevel);
            }

            vec4 computeColor(ivec2 targetPixel) {
                vec3 bl = getBlackLevel(targetPixel);
                vec3 wl = getWhiteLevel(targetPixel);
                vec3 gamma = getGamma();
                vec3 offset = getOffset(bl, wl);
                vec3 scale = getScale(bl, wl);
                vec4 source = texelFetch0(targetPixel);
                vec4 result = vec4(pow(max(vec3(0.0), (source.rgb + offset) * scale), gamma), source.a);
                return result;
            }
        `,
        )
        resultImageOrDataType = cmdQueue.prepareResultImage(resultImageOrDataType, sourceImage.descriptor)
        const blackLevelImage = isImageRef(blackLevel) ? blackLevel : undefined
        const whiteLevelImage = isImageRef(whiteLevel) ? whiteLevel : undefined
        cmdQueue.paint(painter, {
            parameters: {
                u_gamma: {type: "float", value: gamma ?? 1.0},
            },
            sourceImages: [sourceImage, blackLevelImage, whiteLevelImage],
            resultImage: resultImageOrDataType,
        })
        return resultImageOrDataType
    },

    ImgProc: ({cmdQueue, parameters: {sourceImage, blackLevel, whiteLevel, gamma, resultImageOrDataType}}) => {
        const result = cmdQueue.createImage(sourceImage.descriptor, {
            type: "levels",
            input: sourceImage,
            blackLevel: blackLevel,
            whiteLevel: whiteLevel,
            gamma: gamma,
        })
        return cmdQueue.copyToResultImage(result, resultImageOrDataType)
    },
}

export function levels(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpLevels, parameters)
}
