import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {MeshData} from "@src/geometry-processing/mesh-data"
import {RenderNodes} from "@src/rendering/render-nodes"
import {commonGeometryGraphTable} from "@src/geometry-processing/common-geometry-graphs"
import {packStandardGeometryAttributes} from "@src/geometry-processing/geometry-graph"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {Subscription, from, map} from "rxjs"

const TD = TypeDescriptors

function getGeometryGraph(geometryGraph: any, parameters: any): RenderNodes.MeshData | null {
    const fn = commonGeometryGraphTable[geometryGraph]
    return fn ? {type: "geomGraph", graph: packStandardGeometryAttributes(fn(parameters ?? {}))} : null
}

const generateMeshNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    graphPresetName: TD.inlet(TD.String),
    parameters: TD.inlet(TD.JSON()),
    meshData: TD.outlet(TD.MeshData),
}

export class GenerateMeshNew implements NodeClassImpl<typeof generateMeshNewDescriptor, typeof GenerateMeshNew> {
    static descriptor = generateMeshNewDescriptor
    static uniqueName = "GenerateMeshNew"
    sceneManager!: Inlet<ISceneManagerNew>
    graphPresetName!: Inlet<string>
    parameters: Inlet<any>
    meshData!: Outlet<MeshData>

    private pending: Subscription | null = null

    run() {
        this.meshData.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.graphPresetName === NotReady || this.parameters === NotReady) {
            return
        }

        const graph = getGeometryGraph(this.graphPresetName, this.parameters)
        if (graph) {
            this.pending = this.sceneManager.addTaskNew(
                `generateProceduralMesh(${graph.type})`,
                from(this.sceneManager.generateProceduralMeshDataNew(graph)).pipe(
                    map((meshData) => {
                        this.pending = null
                        this.meshData.emitIfChanged(meshData)
                    }),
                ),
                true,
            )
        } else {
            console.warn(`Invalid proceduralMesh graph name: '${this.graphPresetName}'`)
        }
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
