import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {TypeDescriptors} from "../type-descriptors"

const TD = TypeDescriptors

const filterDescriptor = {
    input: TD.inlet(TD.Array(TD.Identity<any>())),
    pred: TD.inlet(TD.Identity<((x: any) => boolean) | undefined>()),
    output: TD.outlet(TD.Array(TD.Identity<any>())),
}

export class Filter<T> implements NodeClassImpl<typeof filterDescriptor, typeof Filter> {
    static descriptor = filterDescriptor
    static uniqueName = "Filter"
    input!: Inlet<T[]>
    pred!: Inlet<((x: T) => boolean) | undefined>
    output!: Outlet<T[]>

    run() {
        const pred = this.pred
        if (this.input === NotReady || pred === NotReady) {
            this.output.emitIfChanged(NotReady)
            return
        }
        const list: T[] = []
        for (const x of this.input) {
            if (pred ? pred(x) : x) {
                list.push(x)
            }
        }
        this.output.emitIfChanged(list)
    }
}
