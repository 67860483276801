import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {Subscription, from, map} from "rxjs"

const TD = TypeDescriptors

const findMaterialNewDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    customerId: TD.inlet(TD.Nullable(TD.Number)),
    articleId: TD.inlet(TD.Nullable(TD.String)),
    materialGraph: TD.outlet(TD.Nullable(TD.MaterialGraph)),
}

export class FindMaterialNew implements NodeClassImpl<typeof findMaterialNewDescriptor, typeof FindMaterialNew> {
    static descriptor = findMaterialNewDescriptor
    static uniqueName = "FindMaterialNew"
    sceneManager!: Inlet<ISceneManagerNew>
    customerId!: Inlet<number | null>
    articleId!: Inlet<string | null>
    materialGraph!: Outlet<IMaterialGraph | null>

    private pending: Subscription | null = null

    run() {
        this.materialGraph.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.articleId === NotReady || this.customerId === NotReady) return

        if (this.articleId === null) {
            this.materialGraph.emitIfChanged(null)
            return
        }

        this.pending = this.sceneManager.addTaskNew(
            `findMaterial(${this.articleId}, ${this.customerId})`,
            from(this.sceneManager.findMaterialNew(this.articleId, this.customerId)).pipe(
                map((materialGraph) => {
                    this.pending = null
                    this.materialGraph.emitIfChanged(materialGraph)
                }),
            ),
            true,
        )
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
