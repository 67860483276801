// @ts-strict-ignore
import {Component, Input, OnDestroy, OnInit} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MatDialog} from "@angular/material/dialog"
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms"
import {MatInputModule} from "@angular/material/input"
import {MatTooltipModule} from "@angular/material/tooltip"
import {Matrix4, Vector3} from "@cm/lib/math"
import {ISurfaceMap, renderMap, SurfaceOverrides} from "@editor/helpers/connection-solver/surface-map"
import {surfaceMapForMultipleFaces, surfaceMapForMultiplePoints} from "@editor/helpers/connection-solver/surface-map-builder"
import {TransformControls} from "@editor/helpers/scene/transformation"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {map, Observable, Subscription} from "rxjs"
import {IDisplayScene, IMeshGeometryAccessor, ObjectId, SceneNodes, SurfacePointCoordinates, SurfacePointInfo} from "@cm/lib/templates/interfaces/scene-object"
import {MeshData} from "@cm/lib/geometry-processing/mesh-data"
import {Selection} from "@editor/helpers/scene/selection"

export interface IEditorView {
    displayScene: IDisplayScene
    transformControls: TransformControls
    selectionMgr: Selection
    sceneManager: {
        getMeshDataForObject(id: ObjectId): MeshData
        getWorldTransformForObject(id: ObjectId): Matrix4
    }
    markNodeChanged(node: Nodes.Node): void
    addTask(obs: Observable<void>): void
}

@Component({
    selector: "cm-surface-definer",
    templateUrl: "./surface-definer.component.html",
    styleUrls: ["./surface-definer.component.scss"],
    standalone: true,
    imports: [MatCheckboxModule, FormsModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatTooltipModule],
})
export class SurfaceDefinerComponent implements OnInit, OnDestroy {
    private meshNode: Nodes.Mesh | null = null
    private surfaceNode: Nodes.MeshSurface | null = null
    private sceneObjId: ObjectId | null = null
    private surfaceOverlay: SceneNodes.SurfaceOverlay | null = null
    private planeOverlay: SceneNodes.PlaneOverlay | null = null
    private triangleHighlights: SceneNodes.TriangleHighlights | null = null
    private selectedPoints: SurfacePointCoordinates[] = []
    private selectedFaceIDs: number[] = []
    private curMap: ISurfaceMap = null
    private geomAccessor: IMeshGeometryAccessor | undefined
    private localMatrix: Matrix4 | undefined
    private prevTransform: Matrix4
    private allowTransform = false
    private autoAssignMatrix = true
    private _active = false
    private transformSubscription: Subscription | null = null
    private selectSubscription: Subscription | null = null
    private hoverSubscription: Subscription | null = null
    private cursor: SceneNodes.Marker | null = null

    @Input() editor: IEditorView | null = null
    @Input() selectionModeCoplanarTriangles = true
    private _surfaceModeProjection = true
    private _selectionModeTriangles = true
    editing: boolean

    formGroupConnection: UntypedFormGroup
    private surfaceOverrides: SurfaceOverrides = {rotationAngle: 0.0, scaleZ: 1.0, centroidOffset: [0.0, 0.0], flipNormals: false}

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {
        this.formGroupConnection = new UntypedFormGroup({
            centroidOffsetX: new UntypedFormControl(0, [Validators.required, Validators.min(-10000), Validators.max(10000)]),
            centroidOffsetY: new UntypedFormControl(0, [Validators.required, Validators.min(-10000), Validators.max(10000)]),
            scaleZ: new UntypedFormControl(1, [Validators.required, Validators.min(-10000), Validators.max(10000)]),
            planeRotation: new UntypedFormControl(0, [Validators.required, Validators.min(-10000), Validators.max(10000)]),
        })
    }

    ngOnDestroy(): void {
        this.endModal(false)
    }

    onSubmit() {
        this.surfaceOverrides.centroidOffset[0] = parseFloat(this.formGroupConnection.get("centroidOffsetX").value)
        this.surfaceOverrides.centroidOffset[1] = parseFloat(this.formGroupConnection.get("centroidOffsetY").value)
        this.surfaceOverrides.scaleZ = parseFloat(this.formGroupConnection.get("scaleZ").value)
        this.surfaceOverrides.rotationAngle = parseFloat(this.formGroupConnection.get("planeRotation").value)

        this.autoAssignMatrix = true
        this.updateSurfaceMap()
    }

    @Input() set surfaceModeProjection(value: boolean) {
        this._surfaceModeProjection = value
        this.autoAssignMatrix = true
        this.updateSurfaceMap()
    }

    get surfaceModeProjection(): boolean {
        return this._surfaceModeProjection
    }

    @Input() set flipNormals(value: boolean) {
        this.surfaceOverrides.flipNormals = value
        this.autoAssignMatrix = true
        this.updateSurfaceMap()
    }

    get flipNormals(): boolean {
        return this.surfaceOverrides.flipNormals
    }

    @Input() set selectionModeTriangles(value: boolean) {
        this._selectionModeTriangles = value
        if (this.editing) {
            this.updateSurfaceMap()
        }
    }

    get selectionModeTriangles(): boolean {
        return this._selectionModeTriangles
    }

    private show(sceneObjId: ObjectId, meshNode: Nodes.MeshOrInstance, surface: Nodes.MeshSurface, editing: boolean): void {
        if (this._active) {
            this.endModal(false)
        }
        this.sceneObjId = sceneObjId
        this.meshNode = NodeUtils.resolveInstance(meshNode)
        this.allowTransform = false
        this.autoAssignMatrix = true
        this.geomAccessor = null
        this._active = true
        this.editing = editing

        this.surfaceNode = surface

        if (this.surfaceNode && this.surfaceNode.type === "meshSurface") {
            this.selectedPoints = this.surfaceNode.sourceData.points as SurfacePointCoordinates[]
            this.selectedFaceIDs = this.surfaceNode.sourceData.faceIDs || []
            this.localMatrix = Matrix4.fromArray(this.surfaceNode.sourceData.localMatrix)
            this.surfaceModeProjection = this.surfaceNode.sourceData.surfaceModeProjection || false
            this.flipNormals = this.surfaceNode.sourceData.overrideFlipNormals || false
            this.selectionModeTriangles = this.surfaceNode.sourceData.selectionModeTriangles || false
            this.surfaceOverrides.centroidOffset = this.surfaceNode.sourceData.overrideCentroidOffset || [0, 0]
            this.surfaceOverrides.scaleZ = this.surfaceNode.sourceData.overrideScaleZ || 1
            this.surfaceOverrides.rotationAngle = this.surfaceNode.sourceData.overrideRotationAngle || 0
            this.formGroupConnection.setValue({
                centroidOffsetX: this.surfaceOverrides.centroidOffset[0],
                centroidOffsetY: this.surfaceOverrides.centroidOffset[1],
                scaleZ: this.surfaceOverrides.scaleZ,
                planeRotation: this.surfaceOverrides.rotationAngle,
            })
            this.autoAssignMatrix = false
        } else {
            this.surfaceModeProjection = true
            this.selectionModeTriangles = true
        }

        if (this.editing) {
            this.transformSubscription = this.editor.transformControls.transformationChanged.subscribe((transform) => {
                if (this.allowTransform && !transform.equals(this.prevTransform)) {
                    this.localMatrix = this.editor.sceneManager.getWorldTransformForObject(sceneObjId).inverse().multiply(transform)
                    this.autoAssignMatrix = false
                    this.updateSurfaceMap()
                }
            })

            this.selectSubscription = this.editor.selectionMgr.selectionEvent.subscribe(({objectId, materialSlot, surfacePointInfo}) => {
                const selectionMgr = this.editor.selectionMgr
                if (surfacePointInfo && surfacePointInfo.objectId === sceneObjId) {
                    if (this.selectionModeTriangles) this.addFace(surfacePointInfo)
                    else this.addPoint(surfacePointInfo)
                } else {
                    this.endModal(true)
                }
            })

            this.hoverSubscription = this.editor.selectionMgr.hoverEvent.subscribe(({objectId, materialSlot, surfacePointInfo}) => {
                if (this.selectionModeTriangles) {
                    if (surfacePointInfo) {
                        //TODO:
                        //this.editor.displayScene.setTriangleHighlight(surfacePointInfo.triIndex, surfacePointInfo.displayMeshToken, surfacePointInfo.objectId);
                    } else {
                        //this.editor.displayScene.clearTriangleHighlight();
                    }
                } else {
                    if (surfacePointInfo) {
                        if (!this.cursor) {
                            this.cursor = {
                                id: "surfaceCursor",
                                type: "Marker",
                                position: new Vector3(0, 0, 0),
                                normal: new Vector3(0, 1, 0),
                            }
                        }
                        this.cursor.position.set(surfacePointInfo.worldX, surfacePointInfo.worldY, surfacePointInfo.worldZ)
                        this.cursor.normal.set(0, 1, 0)
                        //this.editor.displayScene.updateSceneNode(this.cursor);
                    } else if (this.cursor) {
                        //this.editor.displayScene.removeSceneNode(this.cursor);
                        this.cursor = null
                    }
                }
            })
        }

        const meshData = this.editor.sceneManager.getMeshDataForObject(this.sceneObjId)
        this.editor.addTask(
            this.editor.displayScene.getGeometryAccessorForMeshData(meshData).pipe(
                map((geomAccessor) => {
                    this.geomAccessor = geomAccessor
                    this.updateSurfaceMap()
                    return null
                }),
            ),
        )
    }

    defineNewSurface(sceneObjId: ObjectId, meshNode: Nodes.MeshOrInstance): void {
        this.show(sceneObjId, meshNode, null, true)
    }

    editSurface(sceneObjId: ObjectId, meshNode: Nodes.MeshOrInstance, surface: Nodes.MeshSurface): void {
        this.show(sceneObjId, meshNode, surface, true)
    }

    showSurface(sceneObjId: ObjectId, meshNode: Nodes.MeshOrInstance, surface: Nodes.MeshSurface): void {
        this.show(sceneObjId, meshNode, surface, false)
        this.allowTransform = false
    }

    get active(): boolean {
        return this._active
    }

    endModal(confirm: boolean) {
        if (!this._active) return
        if (this.editing && confirm && this.curMap) {
            let node: Nodes.MeshSurface
            let name: string
            const sourceData = {
                schema: "meshSurfaceData_v0",
                localMatrix: this.curMap.matrix.toArray(),
                points: this.selectedPoints,
                faceIDs: this.selectedFaceIDs,
                selectionModeTriangles: this.selectionModeTriangles,
                surfaceModeProjection: this.surfaceModeProjection,
                overrideCentroidOffset: this.surfaceOverrides.centroidOffset,
                overrideScaleZ: this.surfaceOverrides.scaleZ,
                overrideRotationAngle: this.surfaceOverrides.rotationAngle,
                overrideFlipNormals: this.surfaceOverrides.flipNormals,
            }
            if (!this.surfaceNode) {
                const meshNode = this.meshNode
                //TODO: prompt for name?
                for (let i = 1; i < 100; i++) {
                    name = `New Surface ${i}`
                    if (!(meshNode.surfaces as Nodes.MeshSurface[]).find((x) => x.name.toLowerCase() === name.toLowerCase())) {
                        break
                    }
                }
                this.surfaceNode = {
                    name,
                    type: "meshSurface",
                    sourceData,
                }
                if (meshNode.type === "mesh" && this.surfaceNode.type === "meshSurface") {
                    meshNode.surfaces.push(this.surfaceNode)
                }
                this.editor.markNodeChanged(this.surfaceNode)
                this.editor.markNodeChanged(meshNode)
            } else if (this.surfaceNode.type === "meshSurface") {
                this.surfaceNode.sourceData = sourceData
                this.editor.markNodeChanged(this.surfaceNode)
            }
        }
        // if (this.cursor) this.editor.displayScene.removeSceneNode(this.cursor);
        // if (this.triangleHighlights) this.editor.displayScene.removeSceneNode(this.triangleHighlights);
        // if (this.surfaceOverlay) this.editor.displayScene.removeSceneNode(this.surfaceOverlay);
        // if (this.planeOverlay) this.editor.displayScene.removeSceneNode(this.planeOverlay);
        this.cursor = null
        this.surfaceOverlay = null
        this.planeOverlay = null
        this.curMap = null
        this.selectedPoints = []
        this.selectedFaceIDs = []
        this.geomAccessor = null
        if (this.transformSubscription) {
            this.transformSubscription.unsubscribe()
            this.transformSubscription = null
        }
        if (this.selectSubscription) {
            this.selectSubscription.unsubscribe()
            this.selectSubscription = null
        }
        if (this.hoverSubscription) {
            this.hoverSubscription.unsubscribe()
            this.hoverSubscription = null
        }
        if (this.editing) {
            this.editor.transformControls.allowScale = false
            this.editor.transformControls.objectSpace = false
        }
        this._active = false
        this.editing = false
    }

    private addPoint(info: SurfacePointInfo | null): void {
        //TODO: remove point selection
    }

    private addFace(info: SurfacePointInfo): void {
        const [faceID] = this.geomAccessor.triangleIndicesToFaceIDs([info.triIndex])
        const triIndices: number[] = this.geomAccessor.faceIDsToTriangleIndices([faceID])

        if (this.selectedFaceIDs.includes(faceID)) {
            // triangle already in selection, remove triangle from selection
            const idx = this.selectedFaceIDs.indexOf(faceID, 0)
            if (idx > -1) this.selectedFaceIDs.splice(idx, 1)
        } else {
            // new face selected
            if (this.selectionModeCoplanarTriangles) {
                // add all coplanar neighbors too
                const newTriSet = new Set<number>()
                let boundaryTriSet = new Set(triIndices)

                while (boundaryTriSet.size > 0) {
                    // add all from new to current
                    boundaryTriSet.forEach(newTriSet.add, newTriSet)
                    boundaryTriSet.clear()
                    boundaryTriSet = this.geomAccessor.getCoplanarNeighborsForTriangles(newTriSet, triIndices[0])
                }

                const newFaceIDs = this.geomAccessor.triangleIndicesToFaceIDs(Array.from(newTriSet))

                this.selectedFaceIDs = Array.from(new Set([...this.selectedFaceIDs, ...newFaceIDs])) // set union
            } else {
                this.selectedFaceIDs.push(faceID)
            }
        }

        // update highlights (TODO: only add/remove as needed)

        if (!this.triangleHighlights) {
            this.triangleHighlights = {
                id: "surfaceDefinerTriangleHighlights",
                type: "TriangleHighlights",
                vertices: [],
                transform: this.editor.sceneManager.getWorldTransformForObject(this.sceneObjId),
            }
        }

        this.triangleHighlights.vertices.length = 0
        const selectedTriIndices = this.geomAccessor.faceIDsToTriangleIndices(this.selectedFaceIDs)
        for (const triIdx of selectedTriIndices) {
            const [v1, v2, v3] = this.geomAccessor.getVerticesForTriangle(triIdx)
            this.triangleHighlights.vertices.push(v1, v2, v3)
        }

        //this.editor.displayScene.updateSceneNode(this.triangleHighlights);

        this.autoAssignMatrix = true
        this.updateSurfaceMap()
    }

    private updateSurfaceMap() {
        if (!(this._active && this.geomAccessor)) return

        let curMap: ISurfaceMap
        if (this.selectionModeTriangles) {
            if (this.selectedFaceIDs.length < 1) return
            curMap = surfaceMapForMultipleFaces(
                this.geomAccessor,
                this.selectedFaceIDs,
                this.surfaceModeProjection,
                this.autoAssignMatrix ? undefined : this.localMatrix,
                this.surfaceOverrides,
            )
        } else {
            if (this.selectedPoints.length < 3) return
            curMap = surfaceMapForMultiplePoints(
                this.geomAccessor,
                this.selectedPoints,
                this.surfaceModeProjection,
                this.autoAssignMatrix ? undefined : this.localMatrix,
                this.surfaceOverrides,
            )
        }

        this.curMap = curMap
        this.localMatrix = curMap.matrix

        const renderSz = 128

        if (!this.surfaceOverlay) {
            this.surfaceOverlay = {
                id: "surfaceDefiner/surfaceOverlay",
                type: "SurfaceOverlay",
                width: 1,
                height: 1,
                data: [],
                transform: Matrix4.identity(),
            }
        }

        if (!this.planeOverlay) {
            this.planeOverlay = {
                id: "surfaceDefiner/planeOverlay",
                type: "PlaneOverlay",
                width: renderSz,
                height: renderSz,
                transform: Matrix4.identity(),
            }
        }

        const objMatrix = this.editor.sceneManager.getWorldTransformForObject(this.sceneObjId)

        this.surfaceOverlay.data = renderMap(curMap, renderSz, renderSz)
        this.surfaceOverlay.transform = objMatrix //.multiply(this.localMatrix);

        this.planeOverlay.transform = objMatrix.multiply(this.curMap.matrix)
        this.planeOverlay.width = this.curMap.centerX / this.curMap.scaleX
        this.planeOverlay.height = this.curMap.centerY / this.curMap.scaleY

        if (this.editing) {
            this.editor.transformControls.allowScale = true
            this.editor.transformControls.objectSpace = true
            //TODO:
            // this.sceneEmpty.transform = objMatrix.multiply(this.localMatrix);
            // this.prevTransform = this.sceneEmpty.transform;
        }

        //this.editor.displayScene.updateSceneNode(this.planeOverlay);
        //this.editor.displayScene.updateSceneNode(this.surfaceOverlay);

        this.allowTransform = true
    }
}
