<div class="cm-node-flex-container">
    <div class="cm-tools-right">
        <div class="cm-variant-container">
            @for (item of getVariantIds(); track item) {
                <div style="margin-left: 5px">
                    <cm-config-button
                        [$asLabel]="false"
                        [$config]="getDescriptorForVariant(item)"
                        [$variant]="getVariant(item)"
                        (click)="removeVariant(item, $event)"
                    ></cm-config-button>
                </div>
            }
        </div>
    </div>
</div>
