<cm-inspector-section>
    <ng-container cm-title> Position </ng-container>
    <ng-container cm-content>
        <div class="cm-xyz" (click)="transformModeChange.emit(TransformMode.Translate)">
            <div class="cm-xyz-label" [class.cm-active]="transformMode == TransformMode.Translate">
                <i class="far fa-arrows-up-down-left-right"></i>
            </div>
            <cm-input-container>
                <ng-container cm-inline-label> X: </ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="translateValueX" [decimalPlaces]="2"> </cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Y: </ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="translateValueY" [decimalPlaces]="2"> </cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Z: </ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="translateValueZ" [decimalPlaces]="2"> </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        </div>
        <div class="cm-xyz" (click)="transformModeChange.emit(TransformMode.Rotate)">
            <div [class.cm-active]="transformMode == TransformMode.Rotate" class="cm-xyz-label">
                <i class="far fa-arrows-rotate"></i>
            </div>
            <cm-input-container>
                <ng-container cm-inline-label> X: </ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="rotationValueX" [decimalPlaces]="2"> </cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Y: </ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="rotationValueY" [decimalPlaces]="2"> </cm-numeric-input>
                </ng-container>
            </cm-input-container>
            <cm-input-container>
                <ng-container cm-inline-label> Z: </ng-container>
                <ng-container cm-input>
                    <cm-numeric-input [(value)]="rotationValueZ" [decimalPlaces]="2"> </cm-numeric-input>
                </ng-container>
            </cm-input-container>
        </div>
        @if ($can().read.template(null, "transformDialog")) {
            <div (click)="openTransformDialog()" class="cm-max-settings">
                <ng-container cm-text> Max settings </ng-container>
            </div>
        }
    </ng-container>
</cm-inspector-section>
