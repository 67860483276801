import {HalImage} from "@common/models/hal/hal-image"
import {HalPaintable} from "@common/models/hal/hal-paintable"
import {HalPainterBlendMode} from "@common/models/hal/hal-painter/types"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {HalPainterPrimitiveOptions} from "@common/models/hal/hal-painter-primitive/types"
import * as WebGl2ShaderUtils from "@common/helpers/webgl2/webgl2-shader-utils"
import {WebGl2ShaderInstance} from "@common/helpers/webgl2/webgl2-shader-instance"
import {Box2Like} from "@cm/lib/math/box2"
import {Color, ColorLike} from "@cm/lib/math/color"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {assertNever} from "@cm/lib/utils/utils"
import {WebGl2Context} from "@common/models/webgl2/webgl2-context"
import {WebGl2Image} from "@common/models/webgl2/webgl2-image"

const TRACE = false

const MAX_TEXTURE_UNITS = 4

export class WebGl2PainterPrimitive extends WebGl2ShaderInstance implements HalPainterPrimitive {
    constructor(context: WebGl2Context, shadingFunction: string) {
        super(context, shadingFunction)
        if (MAX_TEXTURE_UNITS > this.context.maxTextureUnits) {
            throw Error(
                `WebGlLayerImagesComposite requires ${MAX_TEXTURE_UNITS} texture units, but the WebGlCanvas only supports ${this.context.maxTextureUnits}`,
            )
        }
        this.imageAssignments.length = MAX_TEXTURE_UNITS
        for (let i = 0; i < MAX_TEXTURE_UNITS; i++) {
            this.setSourceImage(i, undefined)
        }
        this.allocateBuffers()
    }

    // WebGl2ShaderInstance
    override dispose(): void {
        this.releaseBuffers()
    }

    // HalPainterPrimitive
    setSourceImage(slot: number, image: HalImage | undefined): void {
        if (slot < 0 || slot >= MAX_TEXTURE_UNITS) {
            throw Error(`Invalid image index: ${slot}`)
        }
        if (!image) {
            this.imageAssignments[slot] = undefined
        } else {
            if (!(image instanceof WebGl2Image)) {
                throw Error("Unsupported image type")
            }
            this.imageAssignments[slot] = image
        }
    }

    // HalPainterPrimitive
    clearGeometry(): void {
        this._numVertices = 0
        this._numIndices = 0
    }

    // HalPainterPrimitive
    addVertices(positions: Vector2Like[], uvs?: Vector2Like[], colors?: ColorLike | ColorLike[]): number {
        const numVertices = positions.length
        if (uvs && numVertices !== uvs.length) {
            throw Error("Number of positions and uvs must match or uvs must be undefined")
        }
        const baseIndex = this._numVertices
        const minVertexBufferSize = this._numVertices + numVertices
        if (minVertexBufferSize > this.maxNumVertices) {
            while (minVertexBufferSize > this.maxNumVertices) {
                this.maxNumVertices *= 2
            }
            this.allocateVertexBuffers()
        }
        if (!this.positions) {
            throw Error("Positions not allocated")
        }
        if (!this.uvs) {
            throw Error("UVs not allocated")
        }
        if (!this.colors) {
            throw Error("Colors not allocated")
        }
        for (let i = 0; i < numVertices; i++) {
            const pos = positions[i]
            const uv = uvs ? uvs[i] : new Vector2(0, 0)
            const color = colors ? (Array.isArray(colors) ? colors[i] : colors) : new Color(1, 1, 1, 1)
            this.positions[this._numVertices * 2 + 0] = pos.x
            this.positions[this._numVertices * 2 + 1] = pos.y
            this.uvs[this._numVertices * 2 + 0] = uv.x
            this.uvs[this._numVertices * 2 + 1] = uv.y
            this.colors[this._numVertices] = Color.fromColorLike(color).toUintARGB()
            this._numVertices++
        }
        this.needBufferUpdate = true
        return baseIndex
    }

    // HalPainterPrimitive
    addIndices(indices: number[]): void {
        const numIndices = indices.length
        const minIndexBufferSize = this._numIndices + numIndices
        if (minIndexBufferSize > this.maxNumIndices) {
            while (minIndexBufferSize > this.maxNumIndices) {
                this.maxNumIndices *= 2
            }
            this.allocateIndexBuffers()
        }
        if (!this.indices) {
            throw Error("Indices not allocated")
        }
        this.indices.set(indices, this._numIndices)
        this._numIndices += numIndices
        this.needBufferUpdate = true
    }

    // HalPainterPrimitive
    addRect(rectPos: Box2Like, rectUV?: Box2Like, color?: ColorLike): void {
        const baseVertexIndex = this.addVertices(
            [
                new Vector2(rectPos.x, rectPos.y),
                new Vector2(rectPos.x + rectPos.width, rectPos.y),
                new Vector2(rectPos.x + rectPos.width, rectPos.y + rectPos.height),
                new Vector2(rectPos.x, rectPos.y + rectPos.height),
            ],
            rectUV
                ? [
                      new Vector2(rectUV.x, rectUV.y),
                      new Vector2(rectUV.x + rectUV.width, rectUV.y),
                      new Vector2(rectUV.x + rectUV.width, rectUV.y + rectUV.height),
                      new Vector2(rectUV.x, rectUV.y + rectUV.height),
                  ]
                : undefined,
            color,
        )
        this.addIndices([baseVertexIndex, baseVertexIndex + 1, baseVertexIndex + 2])
        this.addIndices([baseVertexIndex, baseVertexIndex + 2, baseVertexIndex + 3])
    }

    // HalPainterPrimitive
    addLine(from: Vector2Like, to: Vector2Like, thickness: number, color?: ColorLike): void {
        this.addPath([from, to], thickness, color)
    }

    // HalPainterPrimitive
    addPath(path: Vector2Like[], thickness: number, color?: ColorLike): void {
        if (thickness <= 0) {
            throw Error("Thickness must be positive")
        }
        if (path.length < 2) {
            throw Error("Path must have at least 2 points")
        }
        let baseVertexIndex: number | undefined = undefined
        for (let i = 0; i < path.length; i++) {
            const pos = Vector2.fromVector2Like(path[i])
            const tangent = this.getLengthCorrectedTangent(path, i, closed)
            const perp = tangent.perp()
            const pa = pos.add(perp.mul(thickness / 2))
            const pb = pos.sub(perp.mul(thickness / 2))
            const baseIndex = this.addVertices([pa, pb], undefined, color)
            if (baseVertexIndex === undefined) {
                baseVertexIndex = baseIndex
            }
        }
        if (!baseVertexIndex) {
            throw Error("Invalid path")
        }
        for (let i = 1; i < path.length; i++) {
            this.addIndices([baseVertexIndex + 2 * i - 2, baseVertexIndex + 2 * i - 1, baseVertexIndex + 2 * i])
            this.addIndices([baseVertexIndex + 2 * i - 1, baseVertexIndex + 2 * i + 1, baseVertexIndex + 2 * i])
        }
        if (closed) {
            this.addIndices([baseVertexIndex + 2 * path.length - 2, baseVertexIndex + 2 * path.length - 1, baseVertexIndex])
            this.addIndices([baseVertexIndex + 2 * path.length - 1, baseVertexIndex + 1, baseVertexIndex])
        }
    }

    // HalPainterPrimitive
    async paint(target: HalPaintable, options?: HalPainterPrimitiveOptions): Promise<void> {
        if (this._numIndices >= 3) {
            const transform = options?.transform ? Matrix3x2.fromMatrix3x2Like(options.transform) : new Matrix3x2()
            const blendMode: HalPainterBlendMode = options?.blendMode ?? "none"
            const gl = this.context.gl
            this.shader.setProgramAndData(this.imageAssignments)
            this.shader.setUniforms(target, transform, this.imageAssignments, [1, 1, 1, 1])
            this.setGlBlendMode(blendMode)
            this.uploadVerticesAndIndices()
            gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer)
            gl.vertexAttribPointer(this.shader.LOC_POSITION, 2, gl.FLOAT, false, 0, 0)
            gl.enableVertexAttribArray(this.shader.LOC_POSITION)
            gl.bindBuffer(gl.ARRAY_BUFFER, this.uvBuffer)
            gl.vertexAttribPointer(this.shader.LOC_UV, 2, gl.FLOAT, false, 0, 0)
            gl.enableVertexAttribArray(this.shader.LOC_UV)
            gl.bindBuffer(gl.ARRAY_BUFFER, this.colorBuffer)
            gl.vertexAttribPointer(this.shader.LOC_COLOR, 4, gl.UNSIGNED_BYTE, true, 0, 0)
            gl.enableVertexAttribArray(this.shader.LOC_COLOR)
            target.preDraw()
            const numPasses = target.getNumDrawPasses()
            for (let pass = 0; pass < numPasses; pass++) {
                target.setDrawPass(pass)
                const viewTransform = target.computeTransform(pass)
                this.shader.setViewTransform(viewTransform)
                gl.drawElements(gl.TRIANGLES, this._numIndices, gl.UNSIGNED_INT, 0)
            }
            target.postDraw()
            this.shader.unsetProgramAndData() // unbind textures to avoid potential subsequent feedback framebuffer operation
        }
    }

    private setGlBlendMode(blendMode: HalPainterBlendMode) {
        const gl = this.context.gl
        switch (blendMode) {
            case "none":
                gl.disable(gl.BLEND)
                break
            case "normal":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA)
                break
            case "add":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "sub":
                gl.enable(gl.BLEND)
                gl.blendEquationSeparate(gl.FUNC_REVERSE_SUBTRACT, gl.MAX) // TODO should this be separate ?
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "mul":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.DST_COLOR, gl.ZERO)
                break
            case "min":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.MIN)
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "max":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.MAX)
                gl.blendFunc(gl.ONE, gl.ONE)
                break
            case "screen":
                gl.enable(gl.BLEND)
                gl.blendEquation(gl.FUNC_ADD)
                gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_COLOR)
                break
            default:
                assertNever(blendMode)
        }
    }

    protected getUniformLocation(uniformName: string): WebGLUniformLocation {
        return this.shader.getUniformLocation(uniformName)
    }

    private uploadVerticesAndIndices() {
        if (this.needBufferUpdate) {
            this.needBufferUpdate = false
            if (!this.positions) {
                throw Error("Positions not allocated")
            }
            if (!this.uvs) {
                throw Error("UVs not allocated")
            }
            if (!this.colors) {
                throw Error("Colors not allocated")
            }
            if (!this.indices) {
                throw Error("Indices not allocated")
            }
            const gl = this.context.gl
            gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer)
            gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.positions)
            gl.bindBuffer(gl.ARRAY_BUFFER, this.uvBuffer)
            gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.uvs)
            gl.bindBuffer(gl.ARRAY_BUFFER, this.colorBuffer)
            gl.bufferSubData(gl.ARRAY_BUFFER, 0, this.colors)
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer)
            gl.bufferSubData(gl.ELEMENT_ARRAY_BUFFER, 0, this.indices)
        }
    }

    private allocateBuffers() {
        this.allocateVertexBuffers()
        this.allocateIndexBuffers()
    }

    private releaseBuffers() {
        this.releaseVertexBuffers()
        this.releaseIndexBuffers()
    }

    private allocateVertexBuffers() {
        if (TRACE) {
            console.log(`Allocating vertex buffers for ${this.maxNumVertices} vertices`)
        }
        const gl = this.context.gl
        const prevPositions = this.positions
        const prevUVs = this.uvs
        const prevColors = this.colors
        this.releaseVertexBuffers()
        this.positions = new Float32Array(this.maxNumVertices * 2)
        if (prevPositions) {
            this.positions.set(prevPositions)
        }
        this.uvs = new Float32Array(this.maxNumVertices * 2)
        if (prevUVs) {
            this.uvs.set(prevUVs)
        }
        this.colors = new Uint32Array(this.maxNumVertices)
        if (prevColors) {
            this.colors.set(prevColors)
        }
        this.positionBuffer = WebGl2ShaderUtils.createDynamicBuffer(gl, gl.ARRAY_BUFFER, this.maxNumVertices * 2 * 4) // 2D 32bit float
        this.uvBuffer = WebGl2ShaderUtils.createDynamicBuffer(gl, gl.ARRAY_BUFFER, this.maxNumVertices * 2 * 4) // 2D 32bit float
        this.colorBuffer = WebGl2ShaderUtils.createDynamicBuffer(gl, gl.ARRAY_BUFFER, this.maxNumVertices * 4) // 8bit RGBA
    }

    private releaseVertexBuffers() {
        const gl = this.context.gl
        if (this.positionBuffer) {
            gl.deleteBuffer(this.positionBuffer)
            this.positionBuffer = null
        }
        if (this.uvBuffer) {
            gl.deleteBuffer(this.uvBuffer)
            this.uvBuffer = null
        }
        if (this.colorBuffer) {
            gl.deleteBuffer(this.colorBuffer)
            this.colorBuffer = null
        }
    }

    private allocateIndexBuffers() {
        if (TRACE) {
            console.log(`Allocating index buffers for ${this.maxNumIndices} indices`)
        }
        const gl = this.context.gl
        const prevIndices = this.indices
        this.releaseIndexBuffers()
        this.indices = new Uint32Array(this.maxNumIndices)
        if (prevIndices) {
            this.indices.set(prevIndices)
        }
        this.indexBuffer = WebGl2ShaderUtils.createDynamicBuffer(gl, gl.ELEMENT_ARRAY_BUFFER, this.maxNumIndices * 4) // 32bit indices
    }

    private releaseIndexBuffers() {
        const gl = this.context.gl
        if (this.indexBuffer) {
            gl.deleteBuffer(this.indexBuffer)
            this.indexBuffer = null
        }
    }

    private getLengthCorrectedTangent(path: Vector2Like[], index: number, closed: boolean): Vector2 {
        let pn: Vector2 | undefined = undefined
        let p0: Vector2
        let pp: Vector2 | undefined = undefined
        const getPoint = (index: number) => Vector2.fromVector2Like(path[index])
        if (index === 0) {
            if (closed) {
                pn = getPoint(path.length - 1)
                p0 = getPoint(0)
                pp = getPoint(1)
            } else {
                p0 = getPoint(0)
                pp = getPoint(1)
            }
        } else if (index === path.length - 1) {
            if (closed) {
                pn = getPoint(index - 1)
                p0 = getPoint(index)
                pp = getPoint(0)
            } else {
                pn = getPoint(index - 1)
                p0 = getPoint(index)
            }
        } else {
            pn = getPoint(index - 1)
            p0 = getPoint(index)
            pp = getPoint(index + 1)
        }
        if (pn === undefined && pp !== undefined) {
            const d = pp.sub(p0)
            if (d.norm() < 1e-8) {
                throw Error("Invalid path")
            }
            return d.normalized()
        } else if (pp === undefined && pn !== undefined) {
            const d = p0.sub(pn)
            if (d.norm() < 1e-8) {
                throw Error("Invalid path")
            }
            return d.normalized()
        } else if (pp !== undefined && pn !== undefined) {
            const dn = p0.sub(pn)
            const dp = pp.sub(p0)
            if (dn.norm() < 1e-8 || dp.norm() < 1e-8) {
                throw Error("Invalid path")
            }
            const tn = dn.normalized()
            const tp = dp.normalized()
            // correct length such that multiplying the perp by thickness will result in the correct line width
            const dot = tn.dot(tp)
            return tn.add(tp).mul(1 / (1 + dot))
        } else {
            throw Error("Invalid path")
        }
    }

    private imageAssignments: (WebGl2Image | undefined)[] = []
    private _numVertices = 0
    private _numIndices = 0
    private maxNumVertices = 16
    private maxNumIndices = 16
    private positions: Float32Array | null = null
    private uvs: Float32Array | null = null
    private colors: Uint32Array | null = null
    private indices: Uint32Array | null = null
    private needBufferUpdate = false
    private positionBuffer: WebGLBuffer | null = null
    private uvBuffer: WebGLBuffer | null = null
    private colorBuffer: WebGLBuffer | null = null
    private indexBuffer: WebGLBuffer | null = null
}
