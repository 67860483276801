import {Component, computed, input} from "@angular/core"
import {MatTooltipModule} from "@angular/material/tooltip"
import {VariantConditionNodeSettingsComponent} from "@app/pricing/components/node-settings/variant-condition-node/variant-condition-node.component"
import {PricingNode} from "@cm/lib/pricing/declare-pricing-node"
import {PricedItemNode} from "@cm/lib/pricing/nodes/priced-item-node"
import {VariantConditionNode} from "@cm/lib/pricing/nodes/variant-condition-node"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"

@Component({
    standalone: true,
    selector: "cm-node-settings",
    templateUrl: "./node-settings.component.html",
    styleUrl: "./node-settings.component.scss",
    imports: [ToggleComponent, VariantConditionNodeSettingsComponent, MatTooltipModule],
})
export class NodeSettingsComponent {
    $node = input<PricingNode>()
    $pricedItemNode = computed<PricedItemNode | undefined>(() => {
        if (this.$node() instanceof PricedItemNode) return this.$node() as PricedItemNode
        return undefined
    })
    $condition = computed<VariantConditionNode | undefined>(() => {
        const node = this.$pricedItemNode()
        if (node) return node.parameters.condition
        return undefined
    })
}
