import {NodeGraphClass, NodeParameters, nodeParameters} from "@src/graph-system/node-graph"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {DeclareTemplateNodeTS, TemplateNodeTSImplementation, TemplateNodeImplementation, TemplateNodeMeta} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {z} from "zod"
import {OnCompileContext, matrix4Value} from "@src/templates/types"
import {SolverObjectData} from "@src/templates/runtime-graph/nodes/solver/object-data"
import {Transform, TransformState} from "@src/templates/runtime-graph/nodes/transform"
import {ThisStructID} from "@src/templates/runtime-graph/types"
import {MeshData, BoundsData} from "@src/geometry-processing/mesh-data"
import {BuilderInlet, BuilderOutlet} from "@src/templates/runtime-graph/graph-builder"
import {transformBounds} from "@src/templates/utils/scene-geometry-utils"
import {Matrix4} from "@src/math"
import {TransformAccessorListEntry} from "@src/templates/runtime-graph/nodes/compile-template-new"
import {SceneNodes} from "./interfaces/scene-object"
import {SolverData} from "./runtime-graph/nodes/solver/data"

const objectNode = z.object({
    lockedTransform: matrix4Value.optional(),
    $defaultTransform: matrix4Value.optional(),
    visible: z.boolean().default(true),
})
export type ObjectNode = z.infer<typeof objectNode>

export function DeclareObjectNode<ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        parameters: ZodParamTypes
    },
    implementation: TemplateNodeImplementation<z.infer<typeof definition.parameters> & ObjectNode>,
    meta: TemplateNodeMeta<z.infer<typeof definition.parameters> & ObjectNode>,
) {
    const {parameters: paramsSchema} = definition
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareObjectNodeTS<ParamTypes>({...implementation, validation: {paramsSchema}}, meta)
}

export function DeclareObjectNodeTS<ParamTypes extends NodeParameters>(
    implementation: TemplateNodeTSImplementation<ParamTypes & ObjectNode>,
    meta: TemplateNodeMeta<ParamTypes & ObjectNode>,
): NodeGraphClass<TemplateObjectNode<ParamTypes>> {
    const retClass = class
        extends DeclareTemplateNodeTS<ParamTypes & ObjectNode>(
            {...implementation, validation: {paramsSchema: objectNode.and(implementation.validation?.paramsSchema ?? nodeParameters)}},
            meta,
        )
        implements EvaluableTemplateNode<ObjectData>
    {
        evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
            return evaluator.templateScope.resolve<ObjectData>(`objectData-${evaluator.getLocalId(this)}`)
        }

        setupTransform(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            objectId: string,
            transform: BuilderInlet<Matrix4> | undefined,
        ): readonly [BuilderOutlet<Matrix4>, BuilderOutlet<SolverObjectData>] {
            const {evaluator, currentTemplate} = context
            const {solverData, transformAccessorList} = currentTemplate
            const {templateContext} = evaluator
            const {templateMatrix} = templateContext
            const {sceneManager} = templateContext
            const {lockedTransform} = this.parameters
            const {solverObjects} = solverData

            const transformNode = scope.node(Transform, {
                state: scope.state(TransformState, "transformState"),
                defaultTransform: sceneManager.defaultTransformForObjectNew(this)?.toArray(),
                lockedTransform: scope.lambda(transform, (transform) => transform?.toArray() ?? lockedTransform, "lockedTransform"),
                parentMatrix: templateMatrix,
            })

            const solverObject = scope.struct<SolverObjectData>("SolverObjectData", {
                id: ThisStructID,
                transformAccessor: transformNode.accessor,
            })
            solverObjects.push(solverObject)

            transformAccessorList.push(
                scope.struct<TransformAccessorListEntry>("TransformAccessorListEntry", {
                    objectId,
                    transformAccessor: transformNode.accessor,
                }),
            )

            return [transformNode.matrix, solverObject] as const
        }

        setupObject(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            typeName: string,
            meshData: BuilderInlet<MeshData> | undefined,
            parent: BuilderInlet<ObjectData> | undefined,
            generator: (objectProps: {
                $id: string
                id: string
                topLevelObjectId: string
                transform: BuilderInlet<Matrix4>
            }) => BuilderOutlet<SceneNodes.SceneNode>,
        ) {
            const {evaluator, topLevelObjectId, currentTemplate} = context
            const {allBounds, objectToNodeMap, nodeToObjectMap, displayList, preDisplayList} = currentTemplate
            const {templateScope} = evaluator
            const {visible} = this.parameters

            const objectId = scope.genStructId(typeName)
            objectToNodeMap.set(objectId, this)
            nodeToObjectMap.set(this, objectId)

            const [transformMatrix, solverObject] = this.setupTransform(scope, context, objectId, parent ? scope.get(parent, "matrix") : undefined)

            const bounds = scope.lambda(
                scope.tuple(
                    ((): BuilderInlet<BoundsData> => {
                        if (meshData) return scope.get(meshData, "bounds")
                        else {
                            //TODO: proper bounds for planes, etc.
                            return {
                                centroid: [0, 0, 0],
                                surfaceArea: 0,
                                aabb: [
                                    [0, 0, 0],
                                    [0, 0, 0],
                                ],
                                radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
                            }
                        }
                    })(),
                    transformMatrix,
                ),
                ([bounds, matrix]) => transformBounds(bounds, matrix),
                "transformBounds",
            )
            allBounds.push(bounds)

            const sceneNode = generator({
                $id: objectId,
                id: objectId,
                topLevelObjectId: topLevelObjectId ?? objectId,
                transform: transformMatrix,
            })

            const preDisplayItem = (sceneNode: SceneNodes.SceneNode) => SceneNodes.AreaLight.is(sceneNode)
            const preDisplaySceneNode = scope.lambda(sceneNode, (sceneNode) => (preDisplayItem(sceneNode) ? sceneNode : null), "preDisplaySceneNode")
            const displaySceneNode = scope.lambda(sceneNode, (sceneNode) => (preDisplayItem(sceneNode) ? null : sceneNode), "displaySceneNode")

            if (visible) {
                if (!parent) {
                    preDisplayList.push(preDisplaySceneNode)
                    displayList.push(displaySceneNode)
                } else {
                    preDisplayList.push(
                        scope.lambda(
                            scope.tuple(parent, preDisplaySceneNode),
                            ([parent, preDisplaySceneNode]) => (parent.visible ? preDisplaySceneNode : null),
                            "preDisplayList",
                        ),
                    )
                    displayList.push(
                        scope.lambda(
                            scope.tuple(parent, displaySceneNode),
                            ([parent, displaySceneNode]) => (parent.visible ? displaySceneNode : null),
                            "displayList",
                        ),
                    )
                }
            }

            const objectData = scope.struct<ObjectData>("ObjectData", {
                id: objectId,
                topLevelObjectId: topLevelObjectId ?? objectId,
                matrix: transformMatrix,
                solverObject,
                bounds,
                preDisplayList: scope.lambda(preDisplaySceneNode, (sceneNode) => (sceneNode ? [sceneNode] : []), "objectDataPreDisplayList"),
                displayList: scope.lambda(displaySceneNode, (sceneNode) => (sceneNode ? [sceneNode] : []), "objectDataDisplayList"),
                solverData: scope.struct<SolverData>("SolverData", {
                    objects: scope.lambda(solverObject, (solverObject) => [solverObject], "solverObject"),
                    relations: [],
                    variables: [],
                }),
                visible,
            })

            templateScope.alias(objectData, `objectData-${evaluator.getLocalId(this)}`)
        }
    }
    return retClass
}

export type TemplateObjectNode<ParamTypes extends NodeParameters = {}> = TemplateNode<ParamTypes & ObjectNode> &
    EvaluableTemplateNode<ObjectData> & {
        setupTransform(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            objectId: string,
            transform: BuilderInlet<Matrix4> | undefined,
        ): readonly [BuilderOutlet<Matrix4>, BuilderOutlet<SolverObjectData>]
        setupObject(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            typeName: string,
            meshData: BuilderInlet<MeshData> | undefined,
            parent: BuilderInlet<ObjectData> | undefined,
            generator: (objectProps: {
                $id: string
                id: string
                topLevelObjectId: string
                transform: BuilderInlet<Matrix4>
            }) => BuilderOutlet<SceneNodes.SceneNode>,
        ): void
    }
