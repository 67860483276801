import {HalPaintableImage} from "@common/models/hal/hal-paintable-image"
import {SmartPtrArray} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr-array"
import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {ImageBase} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-base"
import {AddressSpace, ImageDescriptor, ImageRefId} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"

export class ImageWebGL2 extends ImageBase {
    constructor(
        addressSpace: AddressSpace,
        id: ImageRefId,
        disposeFn: ((image: ImageBase) => void) | undefined,
        readonly halImage: HalPaintableImage,
        descriptor: ImageDescriptor, // = descriptorFromHalImage(halImage),
        debugId = "no-debug-id",
    ) {
        super(descriptor, addressSpace, id, disposeFn, debugId)
    }
}

export function isImageWebGL2(obj: unknown): obj is ImageWebGL2 {
    return obj instanceof ImageWebGL2
}

export class ImagePtrWebGl2 extends SmartPtr<ImageWebGL2> {}

export class ImagePtrArrayWebGl2 extends SmartPtrArray<ImageWebGL2> {}

export function isImagePtrWebGl2(obj: unknown): obj is ImagePtrWebGl2 {
    return obj instanceof ImagePtrWebGl2 && obj.isAssigned && isImageWebGL2(obj.ref)
}
