import {MaterialSocket} from "@material-editor/models/material-socket"

export const ScannedTransmissionOutputs: Record<string, MaterialSocket> = {
    bsdf: {paramType: "socket", id: "BSDF", type: "output", valueType: "output", label: "BSDF"},
}

export const ScannedTransmissionInputs: Record<string, MaterialSocket> = {
    bsdf: {paramType: "socket", id: "BSDF", type: "input", valueType: "plain", label: "BSDF"},
    alpha: {paramType: "socket", id: "Alpha", type: "input", valueType: "plain", label: "Alpha"},
    normal: {paramType: "socket", id: "Normal", type: "input", valueType: "plain", label: "Normal"},
    transmission: {paramType: "socket", id: "Transmission", type: "input", valueType: "plain", label: "Transmission"},
    normalStrength: {
        paramType: "socket",
        id: "NormalStrength",
        type: "input",
        valueType: "scalar",
        label: "Normal Strength",
        range: {min: 0, max: 10},
        defaultValue: 4,
    },
    minTransmission: {
        paramType: "socket",
        id: "MinTransmission",
        type: "input",
        valueType: "scalar",
        label: "Min. Transmission",
        range: {min: 0, max: 1},
        defaultValue: 0.2,
    },
    amount: {paramType: "socket", id: "Amount", type: "input", valueType: "scalar", label: "Amount", range: {min: 0, max: 1}, defaultValue: 1.0},
}
