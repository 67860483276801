import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {TypeDescriptors} from "../type-descriptors"

const TD = TypeDescriptors

const stateDescriptor = {
    stateClass: TD.inlet(TD.Identity<{new (): any}>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class State<T> implements NodeClassImpl<typeof stateDescriptor, typeof State> {
    static descriptor = stateDescriptor
    static uniqueName = "State"
    stateClass!: Inlet<{new (): T}>
    output!: Outlet<T>
    private _state!: T

    run() {
        if (this.stateClass === NotReady) return
        this._state = new this.stateClass()
        this.output.emit(this._state)
    }
}
