// @ts-strict-ignore
import {AfterViewInit, Component, inject, OnDestroy, ViewChild} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatInputModule} from "@angular/material/input"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router"
import {DialogSize} from "@common/models/dialogs"
import {Material} from "@app/legacy/api-model/material"
import {ScannerControlInterface, ScanningCanvasToolbox, ScanRegion} from "@app/platform/models/scanning"
import {IsDefined} from "@cm/lib/utils/filter"
import {CanvasBaseComponent, CanvasPhysicalInfo} from "@common/components/canvas"
import {blobToDataURL, forkJoinZeroOrMore} from "@legacy/helpers/utils"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {SelectMaterialComponent} from "@platform/components/materials/select-material/select-material.component"
import {ScanningActionBarComponent} from "@platform/components/scanning/scanning-action-bar/scanning-action-bar.component"
import {ScanningService} from "@platform/services/scanning/scanning.service"
import {Observable, of, Subject, takeUntil} from "rxjs"
import {ResolutionOption, ScanParameters, TablePosition} from "@platform/models/scanning/types"
import {TriggeredDialogComponent} from "@common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {DialogService} from "@common/services/dialog/dialog.service"

@Component({
    selector: "cm-scanning",
    templateUrl: "./scanning.component.html",
    styleUrls: ["./scanning.component.scss"],
    standalone: true,
    imports: [
        MatProgressBarModule,
        ScanningActionBarComponent,
        MatButtonModule,
        MatInputModule,
        FormsModule,
        SelectMaterialComponent,
        RouterOutlet,
        CanvasBaseComponent,
        SelectMaterialComponent,
        TriggeredDialogComponent,
    ],
})
export class ScanningComponent implements AfterViewInit, OnDestroy {
    @ViewChild("scanningCanvas") canvasBase: CanvasBaseComponent
    @ViewChild("scanningActionBar") scanningActionBar: ScanningActionBarComponent
    @ViewChild("selectMaterialDialog") selectMaterialDialog: SelectMaterialComponent

    // Set this to true in order to allow testing the UI including the regions without an actual connection to the scanner.
    developmentMode = false
    developmentModeData: {resolutionOptions: ResolutionOption[]; scanParams: ScanParameters} = {
        scanParams: {
            state: "scanning",
            captureReference: false,
            localScanName: "",
            mode: "cloud",
            regions: [],
            processTiles: true,
            tileSize: 10,
        },
        resolutionOptions: [
            {id: "debug1", name: "debugRes1", pixelsPerMm: 10},
            {id: "debug2", name: "debugRes2", pixelsPerMm: 20},
        ],
    }

    private unsubscribe = new Subject<void>()
    overviewImageUrl: string = null
    overviewImageValid = false
    scanner: ScannerControlInterface = null

    scannerUrl: string
    connectionState: ConnectionState = "notSetup"

    readonly DialogSize = DialogSize
    readonly Material = Material

    selectedScanRegion: ScanRegion

    private toolbox: ScanningCanvasToolbox

    dialog = inject(DialogService)
    organizations = inject(OrganizationsService)
    sdk = inject(SdkService)

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private scanningService: ScanningService,
        private hotkeys: Hotkeys,
    ) {
        if (this.developmentMode) {
            this.connectionState = "connected"
            this.overviewImageUrl =
                "https://storage.googleapis.com/cm-platform-prod-media/th9NMyV56s9QVM1yapFOsOsbJsxSH2K6ttNY6jmNxd3oaNnpFzvIMO4HbkagAyoTsW2iiKXi" +
                "kts9JpKYW8EtMGl9KEkUftID3hIdS8cN4tIzlEiVWwi4xHknrrMKmpLn-jpg"
            this.overviewImageValid = true
            this.physicalInfo.pixelsPerCm = 10
        }
    }

    ngAfterViewInit() {
        this.toolbox = new ScanningCanvasToolbox(this.canvasBase, this.hotkeys, (materialLegacyId: number) =>
            this.sdk.gql.scanRegionMaterial({legacyId: materialLegacyId}).then((res) => res.material),
        )

        this.canvasBase.toolbox = this.toolbox
        this.toolbox.selectionChange.pipe(takeUntil(this.unsubscribe)).subscribe((region: ScanRegion) => {
            this.selectedScanRegion = region
            if (region) {
                region.bringToFront()
                this.scanningActionBar?.activateScanRegionSettingsPanel()
            }
        })

        this.toolbox.scanRegionCreated.pipe(takeUntil(this.unsubscribe)).subscribe((regionUi) => {
            regionUi.regionModified.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.regionModified(regionUi))
            this.openSelectMaterialDialog()
        })

        this.toolbox.scanRegionDeleted.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.updateScanParameters()
        })

        if (this.developmentMode) this.toolbox.defaultResolution = this.resolutionOptions[0]
        // need to do this _outside_ of the current change detection context
        if (!this.developmentMode) {
            const scannerUrlParam = this.route.snapshot.queryParams["scanner-url"]

            if (typeof scannerUrlParam === "string" && scannerUrlParam.length > 0) {
                this.scannerUrl = scannerUrlParam
                this.connectToScanner()
            } else {
                this.organizations.ownIds
                    .then((organizationsIds) => {
                        if (!organizationsIds || organizationsIds.length === 0) {
                            throw new Error("No organizations found")
                        }
                        return this.sdk.gql.organizationsWithScanningUrl({organizationIds: organizationsIds})
                    })
                    .then(({organizations}) => {
                        const scannerUrls = organizations.map((organization) => organization?.defaultScannerUrl).filter(IsDefined)
                        if (scannerUrls.length === 0) {
                            throw new Error("No scanner URL found")
                        } else {
                            this.scannerUrl = scannerUrls[0]
                            this.connectToScanner()
                        }
                    })
            }
        }
    }

    private cleanupConnection(): void {
        this.scanner?.destroy()
        this.scanner = null
        this.scanningService.clearLogs()
        this.overviewImageUrl = null
        this.overviewImageValid = false
        this.connectionState = "notConnected"
    }

    connectToScanner(): void {
        this.cleanupConnection()
        this.connectionState = "connecting"
        const logReplayFn = (entry: string) => this.scanningService.log.next(entry)
        ScannerControlInterface.connect(`wss://${this.scannerUrl}:5555/`, logReplayFn).subscribe({
            next: (scanner) => {
                this.scanner = scanner
                scanner.logEvent$.subscribe((entry) => this.scanningService.log.next(entry))
                this.connectionState = "connected"

                scanner.connectionClosed$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                    this.cleanupConnection()
                })

                this.toolbox.defaultResolution = scanner.globalInfo.resolutionOptions[1]

                this.showOverview()
                this.loadScanParameters()
                this.loadScanInfo()
                this.updateEditMode()

                scanner.stateChangeEvent$.pipe(takeUntil(this.unsubscribe)).subscribe(({key}) => {
                    if (key === "scanParameters") {
                        this.loadScanParameters()
                    } else if (key === "scanInfo") {
                        this.loadScanInfo()
                    } else if (key === "overviewState") {
                        this.showOverview()
                    }
                    this.updateEditMode()
                })
            },
            error: () => {
                this.connectionState = "failed"
            },
        })
    }

    imageLoadingComplete(): void {
        this.canvasBase.navigation.zoomToFitImage(0.95)
    }

    get notReady(): boolean {
        if (this.developmentMode) return false
        return !this.scanner || this.scanner.taskState === "busy"
    }

    get taskInProgress(): boolean {
        return this.scanner?.taskState === "busy"
    }

    get canStartScan(): boolean {
        if (this.developmentMode) return true
        const params = this.scanner.scanParameters
        if (this.notReady || params == null) {
            return false
        } else if (this.toolbox.scanRegions.length == 0) {
            return false
        } else if (params.mode === "local") {
            return (params.localScanName ?? "").length > 0
        } else {
            for (const region of params.regions) {
                if (region.materialId == null) {
                    return false
                }
            }
            return true
        }
    }

    get resolutionOptions() {
        return this.developmentMode ? this.developmentModeData.resolutionOptions : this.scanner.globalInfo.resolutionOptions
    }

    get hasTransparencySupport() {
        return this.scanner.globalInfo.hasTransparencySupport ?? false
    }

    calibrateTable(useExisting: boolean): void {
        this.scanner.calibrateTable(useExisting)
    }

    moveTable(positionName: TablePosition): void {
        this.scanner.moveTable(positionName)
    }

    captureTestImage(): void {
        this.scanner.captureTestImage().subscribe(() => {
            const state = this.scanner.overviewState
            blobToDataURL(new Blob([state.imageData], {type: "image/jpeg"})).subscribe((dataURL) => {
                //TODO: release data URL
                this.overviewImageUrl = dataURL
                this.overviewImageValid = true
            })
        })
    }

    startOverviewScan(regionSize?: "full" | "half" | "quarter"): void {
        this.scanner.startOverviewScan(regionSize)
    }

    startBacklightCalibrationScan(): void {
        this.updateScanParameters()
        this.scanner.startBacklightCalibrationScan()
    }

    get overviewScanState() {
        return this.scanner.overviewState.state
    }

    // this must be a persistent object, as ScanRegions retain the reference in the ctor
    physicalInfo: CanvasPhysicalInfo = {
        pixelsPerCm: 0,
        originXCm: 0,
        originYCm: 0,
    }

    private showOverview(): void {
        const state = this.scanner.overviewState
        if (state.state === "valid") {
            this.physicalInfo.pixelsPerCm = state.imagePixelsPerCm
            this.physicalInfo.originXCm = state.imageRegion[0]
            this.physicalInfo.originYCm = state.imageRegion[1]
            blobToDataURL(new Blob([state.imageData], {type: "image/jpeg"})).subscribe((dataURL) => {
                //TODO: release data URL
                this.overviewImageUrl = dataURL
                this.overviewImageValid = true
            })
        } else {
            this.overviewImageUrl = null
            this.overviewImageValid = false
            this.physicalInfo.pixelsPerCm = 0
            this.physicalInfo.originXCm = 0
            this.physicalInfo.originYCm = 0
        }
    }

    startScan(): void {
        this.updateScanParameters()
        this.scanner.startScan()
    }

    cancelTask(): void {
        this.scanner?.cancel()
    }

    private updateEditMode(): void {
        const allowEdit = !this.notReady
        //this.canvasBase.navigation.mode = allowEdit ? "ScanRegionCreation" : "Panning";
        this.toolbox.allowScanRegionCreation = allowEdit
        this.getUiScanRegions().forEach((region) => (region.allowEdit = allowEdit))
    }

    private getUiScanRegions(): ScanRegion[] {
        return this.toolbox.scanRegions
    }

    private loadScanInfo(): void {
        const scanInfo = this.scanner.scanInfo
        if (!scanInfo) return
        this.getUiScanRegions().forEach((regionUi, idx) => {
            const regionInfo = idx < scanInfo.regions.length ? scanInfo.regions[idx] : null
            regionUi.updateInfo(regionInfo)
        })
    }

    private loadScanParameters(): void {
        const pendingRegions: Observable<ScanRegion>[] = []
        const params = this.scanner.scanParameters
        const selectedIdx = this.getUiScanRegions().indexOf(this.selectedScanRegion)
        this.toolbox.removeAllItems()
        this.selectedScanRegion = null
        params?.regions?.forEach((region) => {
            const regionUi = this.toolbox.addScanRegion(0, 0, 1, 1)
            regionUi.area = region.area
            regionUi.state = region.state
            regionUi.materialId = region.materialId
            regionUi.resolution = undefined
            for (const resOption of this.scanner.globalInfo.resolutionOptions) {
                if (resOption.id === region.resolutionId) {
                    regionUi.resolution = resOption
                    break
                }
            }
            regionUi.sampleRotation = region.sampleRotation
            regionUi.transparent = region.transparent
            regionUi.regionModified.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.regionModified(regionUi))
            pendingRegions.push(of(regionUi))
        })
        forkJoinZeroOrMore(pendingRegions).subscribe()
        this.loadScanInfo()
        const uiRegions = this.getUiScanRegions()
        this.selectedScanRegion = selectedIdx < 0 || selectedIdx >= uiRegions.length ? null : uiRegions[selectedIdx]
        if (this.selectedScanRegion) {
            this.selectedScanRegion.selected = true
        }
    }

    private regionModified(regionUi: ScanRegion): void {
        if (this.notReady && !this.developmentMode) throw new Error("Tried to modify region while scanner was not ready!")
        regionUi.state = "editing"
        this.updateScanParameters()
    }

    updateScanParameters(): void {
        const params = this.developmentMode ? this.developmentModeData.scanParams : this.scanner.scanParameters
        if (!params) return
        params.regions = []
        this.getUiScanRegions().forEach((regionUi) => {
            if (!regionUi.materialId) {
                throw Error("Encountered material-id of null in updateScanParameters.")
            }
            params.regions.push({
                state: regionUi.state,
                materialId: regionUi.materialId,
                resolutionId: regionUi.resolution.id,
                sampleRotation: regionUi.sampleRotation,
                transparent: regionUi.transparent,
                area: regionUi.area,
            })
        })
        if (!this.developmentMode) this.scanner.scanParameters = params
    }

    showLogs(): void {
        this.router.navigate(["logs"], {relativeTo: this.route})
    }

    ngOnDestroy(): void {
        this.toolbox.remove()
        this.canvasBase.toolbox = null
        this.scanner?.destroy()
        this.scanner = null
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    openSelectMaterialDialog() {
        this.dialog.selectInDialog(SelectMaterialComponent, {
            onSelect: (material) => {
                if (material) {
                    this.selectedScanRegion.materialId = material.legacyId
                    this.selectedScanRegion.state = "editing"
                    this.scanningActionBar.updateMaterialRangeTag()
                    this.updateScanParameters()
                }
            },
            onCancel: () => {
                if (!this.selectedScanRegion) return
                if (!this.selectedScanRegion.material) {
                    this.selectedScanRegion.remove()
                    this.selectedScanRegion = null
                }
            },
        })
    }
}

type ConnectionState = "notSetup" | "notConnected" | "connecting" | "connected" | "failed"
