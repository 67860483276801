import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
    computed,
    effect,
    inject,
    signal,
    viewChild,
} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {ActionMenuLegacyComponent} from "@app/common/components/menu/action-menu/action-menu/action-menu-legacy.component"
import {ConfigMenulegacyComponent} from "@app/common/components/menu/config-menu/config-menu.component"
import {ConfigMenuLegacyService} from "@app/common/components/menu/config-menu/services/config-menu-legacy.service"
import {LoadingSpinnerComponent} from "@app/common/components/progress"
import {ActionMenuComponent} from "@app/common/components/viewers/configurator/action-menu/action-menu.component"
import {ActionMenuService} from "@app/common/components/viewers/configurator/action-menu/services/action-menu.service"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {
    createMaterialReferenceFromLegacyId,
    decodeConfiguratorUrlParameters,
    initializeTemplateParameterValue,
} from "@app/common/components/viewers/configurator/helpers/parameters"
import {ArService} from "@app/common/components/viewers/configurator/services/ar.service"
import {exitFullscreen, fullscreenActive, inIframe, openFullscreen} from "@app/common/helpers/fullscreen/fullscreen"
import {FilesService} from "@app/common/services/files/files.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {CMConfiguratorElement} from "@app/configurator/types/cm-configurator"
import {PricingService} from "@app/pricing/services/pricing.service"
import {ThreeTemplateSceneProviderComponent} from "@app/template-editor/components/three-template-scene-provider/three-template-scene-provider.component"
import {captureSnapshot} from "@app/template-editor/helpers/snapshot"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {loadGraphForNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {ConfiguratorParameter, ConfiguratorParameterType} from "@cm/lib/templates/configurator-parameters"
import {SceneProperties} from "@cm/lib/templates/nodes/scene-properties"
import {Parameters} from "@cm/lib/templates/nodes/parameters"
import {SceneCamera, ThreeTemplateSceneViewerComponent} from "@template-editor/components/three-template-scene-viewer/three-template-scene-viewer.component"
import {ConfigMenuComponent} from "@app/common/components/viewers/configurator/config-menu/config-menu.component"
import {IMaterialGraph} from "@cm/lib/materials/material-node-graph"
import {MaterialGraphReference} from "@cm/lib/templates/nodes/material-graph-reference"
import {isMaterialInput} from "@cm/lib/templates/interface-descriptors"
import {increaseOnboardingCounter, isOnboardingRequired} from "@app/common/components/viewers/configurator/helpers/onboarding"
import {CmOnboardingHintComponent} from "@app/common/components/viewers/configurator/configurator/onboarding-hint/onboarding-hint.component"
import {asapScheduler} from "rxjs"
import {toDataURL} from "qrcode"

@Component({
    selector: "cm-configurator-new",
    standalone: true,
    templateUrl: "./configurator.component.html",
    styleUrl: "./configurator.component.scss",
    providers: [SceneManagerService, TemplateNodeClipboardService, TemplateNodeDragService, ActionMenuService, ArService],
    imports: [
        ThreeTemplateSceneViewerComponent,
        ConfigMenulegacyComponent,
        ActionMenuLegacyComponent,
        ActionMenuComponent,
        LoadingSpinnerComponent,
        ThreeTemplateSceneProviderComponent,
        ConfigMenuComponent,
        CmOnboardingHintComponent,
    ],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ConfiguratorComponent implements OnChanges, OnInit, AfterViewInit {
    //https://github.com/angular/angular/issues/53981
    //input/output signals currently do not work properly on web components:
    //Angular elements does translate them to html attributes, just as @Input decorators, i.e. useExternalMenu is usable as use-external-menu
    //on the web component. However, calling it as useExternalMenu() inside this component then yields an error
    @Input() useExternalMenu = "false" //used externally on the web components, do not rename this. Html attribute, thus a string (not a boolean)
    @Input() parameters: string | undefined = undefined
    @Input() showUi = true
    @Input() urlParameters: string | undefined = undefined
    @Input() templateUuid: string | undefined = undefined
    @Input() sceneLegacyId: number | undefined = undefined

    //@Output is translated to custom html events by angular elements and required for the web components.
    //Angular elements throws an error for output<void>() in Angular 17
    @Output() loadingCompleted: EventEmitter<void> = new EventEmitter<void>()
    @Output() changeCompleted: EventEmitter<{id: string; value: string; type: string}> = new EventEmitter<{
        id: string
        value: string
        type: string
    }>() //emitted after any parameter was changed from outer website, i.e. more often than configurationLoaded
    @Output() configurationLoaded: EventEmitter<void> = new EventEmitter<void>()
    @Output() arUrl: EventEmitter<string> = new EventEmitter<string>()

    $runningOperation = signal<"none" | "stl" | "ar" | "loading">("none")

    localSceneManagerService = inject(SceneManagerService)
    sdk = inject(SdkService)
    configMenuService = inject(ConfigMenuService)
    configMenuLegacyService = inject(ConfigMenuLegacyService)
    actionMenuService = inject(ActionMenuService)
    arService = inject(ArService)
    pricingService = inject(PricingService)

    private destroyRef = inject(DestroyRef)
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef)
    private threeSceneManagerService: ThreeSceneManagerService | undefined
    private viewer = viewChild.required<ThreeTemplateSceneViewerComponent>("viewer")
    protected $inFullscreen = signal<boolean>(false)

    showMenu = computed<boolean>(() => {
        const scenePropertiesHideMenu = this.sceneProperties()?.parameters.uiStyle === "hidden"
        return !scenePropertiesHideMenu && (this.$inFullscreen() || this.useExternalMenu !== "true")
    })

    sceneProperties = computed<SceneProperties | undefined>(() => {
        const templateGraph = this.localSceneManagerService.$templateGraph()
        return templateGraph.parameters.nodes.parameters.list.find((node): node is SceneProperties => node instanceof SceneProperties)
    })

    cameraEqualityFn = (prev: SceneCamera | undefined, next: SceneCamera | undefined) => {
        if (!prev || !next) return prev === next
        return (
            prev.parameters.focalLength === next.parameters.focalLength &&
            prev.parameters.focalDistance === next.parameters.focalDistance &&
            prev.parameters.target.equals(next.parameters.target)
        )
    }

    camera = computed<SceneCamera | undefined>(
        () => {
            const cameras = this.localSceneManagerService.$cameras()
            if (cameras.length > 0) return {parameters: cameras[0], transform: cameras[0].transform, ignoreAspectRatio: true}
            return undefined
        },
        {equal: this.cameraEqualityFn},
    )

    onboardingClicked = signal<boolean>(false)

    onboardingHintVisible = computed(() => {
        return !this.onboardingClicked() && Boolean(this.sceneProperties()?.parameters.enableOnboardingHint && isOnboardingRequired())
    })

    constructor() {
        effect(() => {
            const sceneProperties = this.sceneProperties()
            asapScheduler.schedule(() => {
                this.configMenuService.setUiStyle(sceneProperties?.parameters.uiStyle ?? "default")
                this.configMenuService.setIconSize(sceneProperties?.parameters.iconSize ?? 24)

                this.configMenuLegacyService.setUiStyle(sceneProperties?.parameters.uiStyle ?? "default")
                this.configMenuLegacyService.setIconSize(sceneProperties?.parameters.iconSize ?? 24)
            })
        })
    }

    ngOnInit() {
        this.localSceneManagerService.descriptorList$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((descriptors) => {
            this.configMenuService.setInterface(descriptors)
            this.configMenuLegacyService.setInterfaceLegacy(descriptors.map((descriptor) => descriptor.toLegacy()))
        })

        this.configMenuService.configSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((config) => {
            this.localSceneManagerService.setTemplateParameter(config.config.props.id, config.variant.id)
            this.syncConfigChangesAndNotify()
        })

        this.configMenuService.materialSelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (material) => {
            const materialReference = await createMaterialReferenceFromLegacyId(material.legacyId, this.sdk)
            this.localSceneManagerService.setTemplateParameter(material.input.props.id, materialReference)
        })

        this.actionMenuService.toggleFullscreen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.toggleFullscreen()
        })

        this.actionMenuService.creatingStl$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isCreating) => {
            this.creatingStl(isCreating)
        })

        this.arService.creatingAr$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isCreating) => {
            this.creatingAr(isCreating)
        })

        this.arService.desktopArUrl$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((url) => {
            this.arUrl.emit(url)
        })
    }

    bindWebcomponentApi() {
        const nativeElement = this.elementRef.nativeElement as CMConfiguratorElement
        nativeElement.getParameterList = this.getParameterList.bind(this)
        nativeElement.setParameter = this.setParameter.bind(this)
        nativeElement.saveSnapshot = this.saveSnapshot.bind(this)
        nativeElement.captureSnapshotInMemory = this.captureSnapshotInMemory.bind(this)
        nativeElement.viewInAr = this.viewInAr.bind(this)
        nativeElement.zoomIn = this.zoomIn.bind(this)
        nativeElement.zoomOut = this.zoomOut.bind(this)
        nativeElement.resetCamera = this.resetCamera.bind(this)
        nativeElement.loadConfigurator = (templateUuid: string) => this.load(templateUuid, undefined)
        nativeElement.getPricesAsList = this.pricingService.getPricesAsList.bind(this.pricingService)
        nativeElement.generateQrCode = this.generateQrCode.bind(this)
    }

    ngAfterViewInit(): void {
        this.bindWebcomponentApi()
    }

    onInititalizedThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService) {
        threeSceneManagerService.$ambientLight.set(false)
        threeSceneManagerService.$showGrid.set(false)
        threeSceneManagerService.$displayMode.set("configurator")
        this.threeSceneManagerService = threeSceneManagerService

        this.threeSceneManagerService.requestedResize$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.setViewportSize()
            this.localSceneManagerService.compileTemplate()
        })
    }

    getThreeSceneManagerService(): ThreeSceneManagerService {
        if (!this.threeSceneManagerService) throw new Error("ThreeSceneManagerService not initialized")
        return this.threeSceneManagerService
    }

    setViewportSize() {
        const parameters = this.localSceneManagerService.$instanceParameters()
        parameters.parameters["$viewportSize"] = this.viewer().getViewportSize()
        this.localSceneManagerService.$instanceParameters.set(parameters)
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.templateUuid) {
            const updatedTemplateUuid = changes.templateUuid.currentValue as string
            const parameters = this.parameters ? (await decodeConfiguratorUrlParameters(this.parameters, this.sdk)).parameters : undefined
            if (updatedTemplateUuid !== changes.templateUuid.previousValue) this.load(updatedTemplateUuid, parameters)
        }
        if (changes.sceneLegacyId) {
            const updatedSceneLegacyId = changes.sceneLegacyId.currentValue as number
            if (updatedSceneLegacyId !== changes.sceneLegacyId.previousValue) this.loadWithSceneLegacyId(updatedSceneLegacyId)
        }
        if (changes.urlParameters) {
            const updatedUrlParameters = changes.urlParameters.currentValue as string
            if (updatedUrlParameters !== changes.urlParameters.previousValue) this.loadWithUrlParameters(updatedUrlParameters)
        }
    }

    async loadWithSceneLegacyId(sceneLegacyId: number) {
        const templateId = (await this.sdk.throwable.getTemplateUuidFromSceneLegacyIdForConfigurator({sceneLegacyId})).scene.picture.template?.id
        if (!templateId) throw new Error("Failed to get template id from scene id")
        this.load(templateId, undefined)
    }

    async loadWithUrlParameters(urlParameters: string) {
        const decodedUrlParameters = await decodeConfiguratorUrlParameters(urlParameters, this.sdk)
        if (decodedUrlParameters.templateId) this.load(decodedUrlParameters.templateId, decodedUrlParameters.parameters)
        else if (decodedUrlParameters.sceneId) {
            const templateId = (await this.sdk.throwable.getTemplateUuidFromSceneLegacyIdForConfigurator({sceneLegacyId: decodedUrlParameters.sceneId})).scene
                .picture.template?.id
            if (!templateId) throw new Error("Failed to get template id from scene id")
            this.load(templateId, decodedUrlParameters.parameters)
        }
    }

    async load(templateId: string, initialParameters: Parameters | undefined) {
        console.log("Loading template", templateId)
        this.$runningOperation.set("loading")

        const template = (await this.sdk.throwable.getTemplateDetailsForConfigurator({id: templateId})).template
        const graph = loadGraphForNewTemplateSystem(template.latestRevision?.graph)
        if (!graph) throw new Error("Failed to load template graph")

        this.localSceneManagerService.$templateGraph.set(graph)
        this.localSceneManagerService.$defaultCustomerId.set(template.organizationLegacyId)
        this.localSceneManagerService.$templateRevisionId.set(template.latestRevision?.id)

        if (initialParameters) this.localSceneManagerService.$instanceParameters.set(initialParameters)
        this.setViewportSize()
        this.localSceneManagerService.compileTemplate()
        await this.getThreeSceneManagerService().sync(true)

        this.$runningOperation.set("none")
        await this.pricingService.load(templateId)
        this.loadingCompleted.emit()
    }

    toggleFullscreen() {
        const fullscreen = fullscreenActive()
        if (fullscreen) {
            exitFullscreen()
        } else {
            const fullscreenElement = inIframe() ? document.documentElement : this.elementRef.nativeElement
            openFullscreen(fullscreenElement)
        }

        this.$inFullscreen.set(!fullscreen)
    }

    creatingStl(isCreating: boolean) {
        this.$runningOperation.set(isCreating ? "stl" : "none")
    }

    creatingAr(isCreating: boolean) {
        this.$runningOperation.set(isCreating ? "ar" : "none")
    }

    getParameterList() {
        const result: ConfiguratorParameter[] = []
        for (const descriptor of this.localSceneManagerService.getDescriptors())
            if (descriptor.props.type === "input") result.push(descriptor.toConfiguratorParameter())
        return result
    }

    async setParameter(id: string, type: ConfiguratorParameterType, value: string) {
        const parameterValue = await initializeTemplateParameterValue(type, value, this.sdk)
        this.localSceneManagerService.setTemplateParameter(id, parameterValue)
        await this.getThreeSceneManagerService().sync(true)
        this.changeCompleted.emit({id, type, value})
    }

    setMaterialGraph(inputId: string, graph: IMaterialGraph) {
        this.localSceneManagerService.setTemplateParameter(inputId, new MaterialGraphReference({graph}))
    }

    setMaterialGraphToFirstInput(graph: IMaterialGraph) {
        const materialInput = this.localSceneManagerService.getDescriptors().find((descriptor) => isMaterialInput(descriptor))
        if (!materialInput) throw new Error("No material input found")
        this.localSceneManagerService.setTemplateParameter(materialInput.props.id, new MaterialGraphReference({graph}))
    }

    saveSnapshot() {
        const snapshot = captureSnapshot(this.getThreeSceneManagerService(), "image/jpeg", 95)
        FilesService.downloadFile("snapshot.jpg", snapshot)
    }

    captureSnapshotInMemory(): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                resolve(captureSnapshot(this.getThreeSceneManagerService(), "image/jpeg", 95))
            } catch (error) {
                reject(error)
            }
        })
    }

    viewInAr() {
        throw new Error("Backend implementation missing")
        // const templateRevisionId = this.sceneManagerService.$templateRevisionId()
        // if (!templateRevisionId) return
        // this.arService.viewArModel(templateRevisionId, this.sceneManagerService.$currentLocalConfiguration())
    }

    zoomIn(amount: number): void {
        this.viewer().zoom(amount)
    }

    zoomOut(amount: number): void {
        this.viewer().zoom(1.0 / amount)
    }

    resetCamera(): void {
        this.viewer().resetCamera()
    }

    private async syncConfigChangesAndNotify() {
        await this.getThreeSceneManagerService().sync(true)
        this.configurationLoaded.emit()
        this.configMenuService.setSynchronizing(false)
    }

    hideOnboardingIcons() {
        this.onboardingClicked.set(true)
        increaseOnboardingCounter()
    }

    generateQrCode(url: string, errorCorrectionLevel: "high" | "low", width: number, margin: number): Promise<string> {
        return toDataURL(url, {errorCorrectionLevel, width, margin})
    }
}
