import {Injectable, OnDestroy, signal} from "@angular/core"
import {CachedNodeGraphResult} from "@cm/lib/graph-system/evaluators/cached-node-graph-result"
import {LegacyMaterialConverter} from "@cm/lib/materials/legacy-material-converter"
import {Context} from "@cm/lib/materials/types"
import {IMaterialData, keyForMaterialData, keyForMeshMaterialData} from "@cm/lib/templates/interfaces/material-data"
import {MeshRenderSettings} from "@cm/lib/templates/interfaces/scene-object"
import {TextureResolution} from "@cm/lib/templates/nodes/scene-properties"
import {AsyncReentrancyGuard} from "@cm/lib/utils/async-reentrancy-guard"
import {applyMeshRenderSettings} from "@cm/lib/materials/nodes/apply-mesh-render-settings"
import {Observable, Subject, defer, from} from "rxjs"
import * as THREE from "three"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

const colorPalette: number[] = [
    0xa8e6ce, 0xdcedc2, 0xffd3b5, 0xff8c94, 0xe5fcc2, 0x9de0ad, 0x45ada8, 0x547980, 0x594f4f, 0xf67280, 0xc06c84, 0x6c5b7b, 0x355c7d, 0xe8175d, 0xf7db4f,
    0xf0e4e4, 0xfad9c1, 0x009688, 0x7bc043, 0x0392cf,
]

@Injectable()
export class ThreeMaterialManagerService implements OnDestroy {
    private asyncPromiseSerializerByMaterial = new Map<string, AsyncReentrancyGuard.PromiseSerializer>()
    private materialCache = new Map<
        string,
        {
            material: THREE.Material
            key: string
            refCount: number
            referencedTextureNodes: (THREE.Texture | THREENodes.TextureNode)[]
            defaultMaterial: boolean
            variations: THREE.Material[]
        }
    >()

    progressiveTextureLoading = signal(true)
    private requestedRedraw = new Subject<void>()
    private defaultTextureResolution: TextureResolution = "2000px"

    materialModifier: ((material: THREE.Material) => void) | undefined = undefined
    requestedRedraw$ = this.requestedRedraw.asObservable()

    private tryToAcquireFromCache = (key: string) => {
        const cachedMaterial = this.materialCache.get(key)
        if (cachedMaterial !== undefined) {
            cachedMaterial.refCount++
            return cachedMaterial.material
        }

        return null
    }

    acquireMaterial(
        materialData: IMaterialData,
        meshRenderSettings: MeshRenderSettings | undefined,
        materialIndex: number,
    ): [THREE.Material, Observable<THREE.Material> | null] {
        const key = meshRenderSettings ? keyForMeshMaterialData(materialData, meshRenderSettings) : keyForMaterialData(materialData)

        const cachedMaterial = this.tryToAcquireFromCache(key)
        if (cachedMaterial) return [cachedMaterial, null]

        const generateMaterial = async () => {
            let asyncPromiseSerializer = this.asyncPromiseSerializerByMaterial.get(key)
            if (asyncPromiseSerializer === undefined) {
                asyncPromiseSerializer = new AsyncReentrancyGuard.PromiseSerializer()
                this.asyncPromiseSerializerByMaterial.set(key, asyncPromiseSerializer)
            }

            return asyncPromiseSerializer.executeSequentially(async () => {
                const cachedMaterial = this.tryToAcquireFromCache(key)
                if (cachedMaterial) return cachedMaterial

                const materialConverter = new LegacyMaterialConverter()
                const materialGraph = materialConverter.convertMaterialGraph(materialData.materialGraph)
                const augmentedMaterialGraph = meshRenderSettings ? applyMeshRenderSettings(materialGraph, meshRenderSettings) : materialGraph

                const referencedTextureNodes: (THREE.Texture | THREENodes.TextureNode)[] = []
                const usedUvChannels = new Set<number>()
                const context: Context = {
                    type: "three",
                    textureResolution: materialData.realtimeSettings?.textureResolution ?? this.defaultTextureResolution,
                    onThreeCreatedTexture: (textureNode) => {
                        referencedTextureNodes.push(textureNode)
                    },
                    onThreeUsingUvChannel: (uvChannel) => {
                        usedUvChannels.add(uvChannel)
                    },
                    onRequestRedraw: () => {
                        this.requestedRedraw.next()
                    },
                    progressiveTextureLoading: this.progressiveTextureLoading(),
                    forceFiltering: materialData.realtimeSettings?.textureFiltering,
                }

                const result = new CachedNodeGraphResult(augmentedMaterialGraph, context)
                const {surface: material} = await result.run()
                if (!(material instanceof THREE.Material)) throw new Error("Material is not a THREE.Material")

                usedUvChannels.delete(0)
                if (usedUvChannels.size > 0) {
                    const originalOnBeforeCompile = material.onBeforeCompile
                    material.onBeforeCompile = (shader, renderer) => {
                        originalOnBeforeCompile(shader, renderer)
                        shader.vertexUv1s = shader.vertexUv1s || usedUvChannels.has(1)
                        shader.vertexUv2s = shader.vertexUv2s || usedUvChannels.has(2)
                        shader.vertexUv3s = shader.vertexUv3s || usedUvChannels.has(3)
                    }
                }

                this.setupMaterial(material, materialData, key, referencedTextureNodes, false)

                return material
            })
        }

        return [this.acquireDefaultMaterial(materialData, materialIndex), materialData.realtimeSettings?.disable ? null : defer(() => from(generateMaterial()))]
    }

    acquireDefaultMaterial(materialData: IMaterialData | undefined, materialIndex: number): THREE.Material {
        const usedMaterialData = materialData ?? {side: "front", alphaMaskThreshold: 0}
        const key = `default-${materialIndex},${usedMaterialData.side},${usedMaterialData.alphaMaskThreshold}`

        const cachedMaterial = this.tryToAcquireFromCache(key)
        if (cachedMaterial) return cachedMaterial

        const material = getDefaultMaterial(materialIndex)

        this.setupMaterial(material, usedMaterialData, key, [], true)
        return material
    }

    private setupMaterial(
        material: THREE.Material,
        materialData: Pick<IMaterialData, "side" | "alphaMaskThreshold">,
        key: string,
        referencedTextureNodes: (THREE.Texture | THREENodes.TextureNode)[],
        defaultMaterial: boolean,
    ) {
        switch (materialData.side) {
            case "front":
                material.side = THREE.FrontSide
                break
            case "back":
                material.side = THREE.BackSide
                break
            case "double":
                material.side = THREE.DoubleSide
                break
            default:
                material.side = THREE.FrontSide
                break
        }
        material.alphaTest = materialData.alphaMaskThreshold ?? 0
        this.materialModifier?.(material)

        this.materialCache.set(key, {material, key, refCount: 1, referencedTextureNodes, defaultMaterial, variations: []})
    }

    releaseMaterial(material: THREE.Material | THREE.Material[]) {
        if (Array.isArray(material)) for (const m of material) this.releaseMaterial(m)
        else {
            const cachedMaterial = [...this.materialCache.values()].find((x) => x.material === material || x.variations.includes(material))
            if (cachedMaterial !== undefined) {
                cachedMaterial.refCount--
                if (cachedMaterial.refCount <= 0) {
                    this.materialCache.delete(cachedMaterial.key)
                    for (const textureNode of cachedMaterial.referencedTextureNodes) {
                        if (textureNode instanceof THREE.Texture) textureNode.dispose()
                        else textureNode.value.dispose()
                    }
                    material.dispose()
                    for (const variation of cachedMaterial.variations) variation.dispose()
                    console.info("Material disposed", cachedMaterial.key)
                }
            } else material.dispose()
        }
    }

    ngOnDestroy() {
        this.clearTextures()
    }

    private clearTextures() {
        for (const cachedMaterial of this.materialCache.values()) {
            const {material, referencedTextureNodes} = cachedMaterial
            for (const textureNode of referencedTextureNodes) {
                if (textureNode instanceof THREE.Texture) textureNode.dispose()
                else textureNode.value.dispose()
            }
            material.dispose()
            for (const variation of cachedMaterial.variations) variation.dispose()
            console.info("Material disposed", material)
        }

        this.materialCache.clear()
    }

    updateMaterials(updateFunction: (material: THREE.Material) => void) {
        for (const cachedMaterial of this.materialCache.values()) {
            updateFunction(cachedMaterial.material)
            for (const variation of cachedMaterial.variations) updateFunction(variation)
        }
    }

    acquireVariation(material: THREE.Material, matcher: (material: THREE.Material) => void, creator: (material: THREE.Material) => void): THREE.Material {
        const cachedMaterial = [...this.materialCache.values()].find((x) => x.material === material || x.variations.includes(material))
        if (cachedMaterial === undefined) throw new Error("Material not found")

        const {material: originalMaterial, variations} = cachedMaterial

        const variation = [originalMaterial, ...variations].find(matcher)
        if (variation) {
            cachedMaterial.refCount++
            return variation
        } else {
            const variation = material.clone()
            variation.onBeforeCompile = material.onBeforeCompile
            this.materialModifier?.(material)
            creator(variation)
            cachedMaterial.variations.push(variation)

            cachedMaterial.refCount++
            return variation
        }
    }
}

export const getDefaultMaterial = (materialIndex: number) => {
    const usedMaterialIndex = materialIndex % colorPalette.length

    const material = new THREE.MeshStandardMaterial({
        color: colorPalette[usedMaterialIndex],
        roughness: 0.8,
        metalness: 0.0,
    })

    return material
}
