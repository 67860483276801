import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core"
import {WebGl2CanvasComponent} from "@common/components/canvas/webgl2-canvas/webgl2-canvas.component"
import {CanvasNavigation} from "@common/helpers/canvas/canvas-navigation"
import {HalContext} from "@common/models/hal/hal-context"
import {HalImage} from "@common/models/hal/hal-image"
import {createHalImage} from "@common/models/hal/hal-image/create"
import {HalPainterImageStretch} from "@common/models/hal/common/hal-painter-image-stretch"
import * as paper from "paper"
import {fromEvent, map, merge, Observable, Subject, Subscription, switchMap, take, takeUntil} from "rxjs"
import {Box2, Box2Like} from "@cm/lib/math/box2"
import {Vector2} from "@cm/lib/math/vector2"
import {cancelDeferredTask, queueDeferredTask} from "@cm/lib/utils/utils"
import {CanvasBaseToolboxRootItem} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-root-item"

export const USE_MIPMAPS_FOR_CANVAS = true

@Component({
    selector: "cm-canvas-base",
    templateUrl: "./canvas-base.component.html",
    styleUrls: ["./canvas-base.component.scss"],
    standalone: true,
    imports: [WebGl2CanvasComponent],
})
export class CanvasBaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("canvasElement", {static: true}) canvasElementRef!: ElementRef
    @ViewChild("webGlCanvas", {static: true}) readonly webGlCanvasComponent!: WebGl2CanvasComponent
    @ViewChild("canvasContainer", {static: true}) canvasContainerRef!: ElementRef

    @Input() set imageUrl(url: string) {
        if (this.imageLoadSubscription) {
            this.imageLoadSubscription.unsubscribe()
        }
        if (url) this.imageLoadSubscription = this.loadImage(url, true).pipe(takeUntil(this.destroySubject)).subscribe() // assume sRGB
    }

    @Input() set imageData(value: ImageData) {
        if (!value) return
        const canvas: HTMLCanvasElement = document.createElement("canvas")
        canvas.width = value.width
        canvas.height = value.height
        canvas.getContext("2d")?.putImageData(value, 0, 0)
        if (this.imageLoadSubscription) {
            this.imageLoadSubscription.unsubscribe()
        }
        this.imageLoadSubscription = this.loadImage(canvas.toDataURL(), true).pipe(takeUntil(this.destroySubject)).subscribe() // assume sRGB
    }

    @Input() zoomToFitOnLoadingComplete = false

    private _physicalInfo: CanvasPhysicalInfo | undefined
    @Input()
    set physicalInfo(value: CanvasPhysicalInfo) {
        this._physicalInfo = value
        if (this._navigation) {
            this._navigation.physicalInfo = value
        }
        this.physicalInfoChange.emit(value)
    }

    get physicalInfo(): CanvasPhysicalInfo {
        if (!this._physicalInfo) {
            throw Error("Attempting to retrieve physical-info which has never been set.")
        }
        return this._physicalInfo
    }

    @Output() readonly loadingComplete = new EventEmitter<void>()
    @Output() readonly loadingError = new EventEmitter<void>()
    @Output() readonly canvasBoundsChange = new EventEmitter<Box2>()
    @Output() readonly physicalInfoChange = new EventEmitter<CanvasPhysicalInfo>()

    ngOnInit() {
        this._canvas = this.canvasElementRef.nativeElement
        // this.scope = new paper.PaperScope()
        paper.setup(this._canvas)
        this.project = paper.project
        this._paperLayer = this.project.activeLayer
        this.paperLayer.name = "PaperLayer"

        fromEvent<ErrorEvent>(this.image, "error")
            .pipe(takeUntil(this.destroySubject))
            .subscribe((error: ErrorEvent) => {
                console.error("Cannot load image.", error)
                this.loadingError.emit()
            })
    }

    ngAfterViewInit(): void {
        this.webGlCanvasComponent.resized.subscribe(() => this.drawWebGlCanvas())
        this._halImage = createHalImage(this.halContext)
        this._halPainterImageStretch = HalPainterImageStretch.create(this.halContext)

        this._navigation = new CanvasNavigation(this, this._canvas, this.project, this.image)
        this._navigation.viewChange.pipe(takeUntil(this.destroySubject)).subscribe(() => this.onViewChanged())
        this._navigation.physicalInfo = this._physicalInfo
        this._navigation.zoomTo(1)
    }

    ngOnDestroy(): void {
        this._destroyed = true

        if (this._redrawRequestId != null) {
            cancelDeferredTask(this._redrawRequestId)
            this._redrawRequestId = null
        }

        this._halPainterImageStretch.dispose()
        this.halImage.dispose()

        this.project.clear()
        this.project.remove()
        this.destroySubject.next()
        this.destroySubject.complete()
        this._navigation.destroy()
    }

    get destroyed(): boolean {
        return this._destroyed
    }

    get halContext(): HalContext {
        return this.webGlCanvasComponent.halContext
    }

    get navigation(): CanvasNavigation {
        return this._navigation
    }

    get paperLayer(): paper.Layer {
        return this._paperLayer
    }

    get image(): HTMLImageElement {
        return this._image
    }

    get halImage(): HalImage {
        return this._halImage
    }

    get toolbox(): CanvasBaseToolboxRootItem | null {
        return this._navigation.toolboxRootItem
    }

    set toolbox(canvasToolbox: CanvasBaseToolboxRootItem | null) {
        this._navigation.toolboxRootItem = canvasToolbox
    }

    get canvasBounds(): Box2 {
        return this._canvasBounds
    }

    get canvasCursorPosition(): Vector2 {
        return this._navigation.canvasCursorPosition
    }

    get customDrawFn(): CustomDrawFn | null {
        return this._customDrawFn
    }

    set customDrawFn(value: CustomDrawFn | null) {
        this._customDrawFn = value
        this.requestRedraw()
    }

    get isSRGB(): boolean {
        return this._imageIsSrgb
    }

    set isSRGB(value: boolean) {
        this._imageIsSrgb = value
    }

    get displayGamma(): number {
        return this._displayGamma
    }

    set displayGamma(value: number) {
        this._displayGamma = value
    }

    private onViewChanged() {
        this.requestRedraw()
    }

    loadImage(url: string, isSRGB: boolean): Observable<void> {
        this.isSRGB = isSRGB
        this._image.crossOrigin = "anonymous"
        const loadObservable = fromEvent(this._image, "load").pipe(
            take(1),
            switchMap(async () => {
                await this.showOriginalImage()
                if (this.zoomToFitOnLoadingComplete) {
                    this._navigation.zoomToFitImage()
                }
                this.loadingComplete.emit()
            }),
        )
        this._image.src = url
        return merge(
            loadObservable,
            this.loadingError.pipe(
                take(1),
                map(() => {
                    throw new Error("Failed to load image")
                }),
            ),
        )
    }

    async showOriginalImage() {
        await this.showImage(this._image, this._imageIsSrgb)
    }

    async showImage(image: HTMLImageElement, isSRGB: boolean): Promise<void> {
        await this.halImage.create({htmlImageElement: image, options: {useSRgbFormat: isSRGB, useMipMaps: USE_MIPMAPS_FOR_CANVAS}})
        await this.drawWebGlCanvas()
    }

    async showCanvas(canvas: HTMLCanvasElement, isSRGB: boolean): Promise<void> {
        await this.halImage.create({htmlCanvasElement: canvas, options: {useSRgbFormat: isSRGB, useMipMaps: USE_MIPMAPS_FOR_CANVAS}})
        await this.drawWebGlCanvas()
    }

    requestRedraw() {
        if (this._redrawRequestId == null && !this._destroyed) {
            this._redrawRequestId = queueDeferredTask(() => this.drawWebGlCanvas())
        }
    }

    get viewTransform(): paper.Matrix {
        const dpiCorrectedTransform = new paper.Matrix().scale(window.devicePixelRatio, window.devicePixelRatio)
        if (this.project.view) dpiCorrectedTransform.append(this.project.view.matrix)
        return dpiCorrectedTransform
    }

    private async drawWebGlCanvas(): Promise<void> {
        this._redrawRequestId = null
        await this.webGlCanvasComponent.clearBackBuffer()
        const drawFn = this._customDrawFn ?? this.defaultDrawFn.bind(this)
        const imageBounds = await drawFn()
        this.setCanvasBounds(imageBounds)
        await this.webGlCanvasComponent.presentBackBuffer(this._imageIsSrgb, this._displayGamma)
    }

    private async defaultDrawFn(): Promise<Box2Like> {
        await this._halPainterImageStretch.paint(this.webGlCanvasComponent.backBufferImage, this.halImage, {transform: this.viewTransform})
        return {x: 0, y: 0, width: this.halImage.descriptor.width, height: this.halImage.descriptor.height}
    }

    private setCanvasBounds(boundingBox: Box2Like) {
        if (this._canvasBounds.equals(boundingBox)) {
            return
        }
        this._canvasBounds = Box2.fromBox2Like(boundingBox)
        this.canvasBoundsChange.emit(this._canvasBounds)
    }

    private _redrawRequestId: number | null = null
    private _customDrawFn: CustomDrawFn | null = null
    private _halPainterImageStretch!: HalPainterImageStretch
    private _navigation!: CanvasNavigation
    private imageLoadSubscription!: Subscription
    private destroySubject = new Subject<void>()
    private _canvas!: HTMLCanvasElement
    private _image: HTMLImageElement = new Image()
    private _imageIsSrgb = false
    private _displayGamma = 1
    private _halImage!: HalImage
    private _canvasBounds: Box2 = new Box2(0, 0, 0, 0)

    private project!: paper.Project
    private _paperLayer!: paper.Layer

    private _destroyed = false
}

type CustomDrawFn = () => Promise<Box2Like> // returns the box of the drawn image

export type CanvasPhysicalInfo = {
    pixelsPerCm: number
    originXCm: number
    originYCm: number
}
