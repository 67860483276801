import {Component, effect, inject, input, output} from "@angular/core"
import {MatButtonToggleModule} from "@angular/material/button-toggle"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatFormField, MatLabel} from "@angular/material/form-field"
import {MatSelect, MatOption} from "@angular/material/select"
import {FormsModule} from "@angular/forms"
import {ToggleComponent} from "@app/common/components/buttons/toggle/toggle.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {LocalPreviewRenderingService} from "@app/common/services/rendering/local-preview-rendering.service"
import {ObjectId} from "@cm/lib/templates/interfaces/scene-object"
import {getLodTypeDescription} from "@cm/lib/templates/types"

@Component({
    selector: "cm-template-scene-viewer-options",
    standalone: true,
    imports: [MatMenuModule, ToggleComponent, MatButtonToggleModule, MatTooltipModule, MatFormField, MatLabel, MatSelect, MatOption, FormsModule],
    templateUrl: "./template-scene-viewer-options.component.html",
    styleUrl: "./template-scene-viewer-options.component.scss",
})
export class TemplateSceneViewerOptionsComponent {
    sceneManagerService = inject(SceneManagerService)
    threeSceneManagerService = inject(ThreeSceneManagerService)
    localPreviewRenderingService = inject(LocalPreviewRenderingService)

    selectedCameraId = input.required<ObjectId | undefined>()

    selectedCameraIdChanged = output<ObjectId | undefined>()

    getLodTypeDescription = getLodTypeDescription

    constructor() {
        effect(() => {
            const selectedCameraId = this.selectedCameraId()
            if (selectedCameraId) {
                const cameras = this.sceneManagerService.$cameras()
                if (!cameras.find((camera) => camera.id === selectedCameraId)) {
                    if (cameras.length > 0) this.selectedCameraIdChanged.emit(cameras[0].id)
                    else this.selectedCameraIdChanged.emit(undefined)
                }
            }
        })
    }
}
