import {CommonModule} from "@angular/common"
import {AfterViewInit, Component, computed, DestroyRef, ElementRef, EventEmitter, inject, Input, OnDestroy, Output, signal} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ImageColorSpace, TextureThumbnailViewDataObjectDetailsFragment, TextureThumbnailViewDataObjectFragment} from "@api"
import {AuthService} from "@app/common/services/auth/auth.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {UploadProcessingService} from "@app/common/services/upload/upload-processing.service"
import {Settings} from "@common/models/settings/settings"
import {FilesService} from "@common/services/files/files.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {interval, Subject, takeUntil} from "rxjs"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {ThumbnailLayout} from "@common/models/enums/thumbnail-layout"
import {MimeType} from "@legacy/helpers/utils"
import {DropFilesComponent} from "@common/components/files"
import {PermissionsService} from "@common/services/permissions/permissions.service"

const POLL_THUMBNAIL_TIMEOUT = 1000

@Component({
    selector: "cm-texture-thumbnail-view",
    templateUrl: "./texture-thumbnail-view.component.html",
    styleUrl: "./texture-thumbnail-view.component.scss",
    standalone: true,
    imports: [CommonModule, MatTooltipModule, MatMenuModule, ThumbnailComponent, DataObjectThumbnailComponent, DropFilesComponent],
})
export class TextureThumbnailViewComponent implements AfterViewInit, OnDestroy {
    @Input() label = ""

    @Input() set dataObjectId(value: string | undefined) {
        void this.loadDataObject(value)
    }

    @Input() isUploading = false
    @Input() canDrop = false
    @Output() init = new EventEmitter<EventTarget>()
    @Output() load = new EventEmitter<Event>()
    @Output() thumbnailAvailable = new EventEmitter<TextureThumbnailViewDataObjectDetailsFragment>()
    @Output() fileDropped = new EventEmitter<File>()

    permission = inject(PermissionsService)
    organization = inject(OrganizationsService)
    $can = this.permission.$to

    protected $mouseHoovering = signal<boolean>(false)
    protected $showDropzone = computed(() => this.$mouseHoovering() && this.canDrop)

    constructor(
        private destroyRef: DestroyRef,
        private elementRef: ElementRef,
        private sdk: SdkService,
        protected authService: AuthService,
        private uploadProcessingService: UploadProcessingService,
    ) {}

    ngAfterViewInit(): void {
        this.init.emit(this.elementRef.nativeElement)
    }

    ngOnDestroy(): void {
        this._loadingNewTexture.complete()
        this._dataObjectUpdated.complete()
    }

    private async loadDataObject(dataObjectId: string | undefined) {
        if (this._dataObjectId === dataObjectId) {
            return
        }
        this._loadingNewTexture.next()
        this._dataObjectId = dataObjectId
        if (this._dataObjectId) {
            const result = await this.sdk.gql.textureThumbnailViewQueryDataObject({dataObjectId: this._dataObjectId})
            this.dataObject = result.dataObject
            if (this.dataObject.thumbnailAvailable) {
                this.thumbnailAvailable.emit(this.dataObject)
            } else {
                interval(POLL_THUMBNAIL_TIMEOUT)
                    .pipe(takeUntilDestroyed(this.destroyRef), takeUntil(this._loadingNewTexture), takeUntil(this._dataObjectUpdated))
                    .subscribe(() => this.pollUpdatedDataObject(this.dataObject!))
            }
        } else {
            this.dataObject = undefined
        }
    }

    private async pollUpdatedDataObject(dataObject: TextureThumbnailViewDataObjectFragment) {
        const updatedDataObject = await this.sdk.gql.textureThumbnailViewQueryDataObject({dataObjectId: dataObject.id})
        if (updatedDataObject.dataObject.thumbnailAvailable) {
            this.dataObject = updatedDataObject.dataObject
            this._dataObjectUpdated.next()
            this.thumbnailAvailable.emit(this.dataObject)
        }
    }

    protected colorSpaceIsUnknown(dataObject: TextureThumbnailViewDataObjectFragment): boolean {
        return (dataObject.imageColorSpace ?? ImageColorSpace.Unknown) === ImageColorSpace.Unknown
    }

    protected downloadDataObject(dataObject: TextureThumbnailViewDataObjectDetailsFragment): void {
        FilesService.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    protected describeDataObject(dataObject: TextureThumbnailViewDataObjectDetailsFragment): string {
        let typeName: string
        switch (dataObject.mediaType) {
            case "image/x-exr":
                typeName = "EXR"
                break
            case "image/tiff":
                typeName = "TIFF"
                break
            case "image/jpeg":
                typeName = "JPEG"
                break
            default:
                typeName = `${dataObject.mediaType}`
                break
        }
        const colorSpaceName = dataObject.imageColorSpace?.toString() ?? ImageColorSpace.Unknown.toString()
        return `${dataObject.width ?? "?"} x ${dataObject.height ?? "?"} ${typeName} (${colorSpaceName})`
    }

    protected async setOriginalColorspace(dataObject: TextureThumbnailViewDataObjectDetailsFragment, colorSpace: ImageColorSpace) {
        dataObject.imageColorSpace = colorSpace
        await this.sdk.gql.textureThumbnailViewUpdateDataObject({input: {id: dataObject.id, imageColorSpace: colorSpace}})
        await this.uploadProcessingService.createUploadProcessingJob(dataObject.legacyId, dataObject.organization.id)
    }

    protected onFilesSelected(event: Event) {
        const inputElement = event.target as HTMLInputElement
        if (inputElement.files && inputElement.files.length > 0) {
            this.fileDropped.emit(inputElement.files[0])
        }
    }

    protected onFilesDropped(event: File[]) {
        if (event.length > 0) {
            this.fileDropped.emit(event[0])
        }
    }

    protected imageColorSpace = ImageColorSpace
    protected dataObject: TextureThumbnailViewDataObjectFragment | undefined = undefined

    private _loadingNewTexture = new Subject<void>()
    private _dataObjectUpdated = new Subject<void>()
    private _dataObjectId: string | undefined = undefined
    protected readonly Settings = Settings
    protected readonly ThumbnailLayout = ThumbnailLayout
    protected readonly MimeType = MimeType
}
