import {Component, computed, inject, output, signal} from "@angular/core"
import {Matrix4, Vector3} from "@cm/lib/math"
import {getNodeIconClass} from "@app/template-editor/helpers/template-icons"
import {Node, isObject, Object, NodeOwner, isNode} from "@cm/lib/templates/node-types"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {TemplateNodeDragService} from "@app/template-editor/services/template-node-drag.service"
import {MeshDecal} from "@cm/lib/templates/nodes/mesh-decal"
import {Annotation} from "@cm/lib/templates/nodes/annotation"
import {v4 as uuid4} from "uuid"
import {BooleanInput, ImageInput, JSONInput, MaterialInput, NumberInput, ObjectInput, StringInput, TemplateInput} from "@cm/lib/templates/nodes/input"
import {ObjectExport, MaterialExport, TemplateExport, ImageExport, StringExport, NumberExport, BooleanExport, JSONExport} from "@cm/lib/templates/nodes/export"
import {Translation, FixedRotation} from "@cm/lib/templates/declare-transform-node"
import {RigidRelation} from "@cm/lib/templates/nodes/rigid-relation"
import {quaternionToAngleDegrees} from "@app/common/helpers/utils/math-utils"
import {getNodeOwner} from "@cm/lib/templates/utils"
import {PointGuide} from "@cm/lib/templates/nodes/point-guide"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {CentroidAccumulator} from "@cm/lib/templates/utils/scene-geometry-utils"
import {PlaneGuide} from "@cm/lib/templates/nodes/plane-guide"
import {StoredMesh} from "@cm/lib/templates/nodes/stored-mesh"
import {ProceduralMesh} from "@cm/lib/templates/nodes/procedural-mesh"
import {MaterialAssignments} from "@cm/lib/templates/nodes/material-assignment"
import {BooleanValue, JSONValue, NumberValue, StringValue} from "@cm/lib/templates/nodes/value"
import {OverlayMaterialColor} from "@cm/lib/templates/nodes/overlay-material-color"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {Nodes} from "@cm/lib/templates/nodes/nodes"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {ConfigGroup} from "@cm/lib/templates/nodes/config-group"
import {Group} from "@cm/lib/templates/nodes/group"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {uploadMeshToGroup, uploadImage, selectFile} from "@app/template-editor/helpers/upload"
import {WebAssemblyWorkerService} from "@app/editor/services/webassembly-worker.service"
import {MatDialog} from "@angular/material/dialog"
import {RenameDialogComponent} from "@app/common/components/dialogs/rename-dialog/rename-dialog.component"
import {tap} from "rxjs"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"
import {LodType} from "@cm/lib/templates/nodes/lod-type"
import {DataObjectReference} from "@cm/lib/templates/nodes/data-object-reference"
import {MeshCurve} from "@cm/lib/templates/nodes/mesh-curve"
import {Seam} from "@cm/lib/templates/nodes/seam"

type SceneItem = {
    name: string
    node?: Node
    action?: () => Promise<false | void> | false | void
    iconClass: string
    disabled?: boolean
}

@Component({
    selector: "cm-template-add-other",
    standalone: true,
    templateUrl: "./template-add-other.component.html",
    styleUrl: "./template-add-other.component.scss",
    imports: [TemplateAddCardComponent],
})
export class TemplateAddOtherComponent {
    drag = inject(TemplateNodeDragService)
    private dialog = inject(MatDialog)
    private sceneManagerService = inject(SceneManagerService)
    private sdk = inject(SdkService)
    private uploadService = inject(UploadGqlService)
    private workerService = inject(WebAssemblyWorkerService)
    onItemClicked = output()

    currentSection = signal<"upload" | "configuration" | "primitive" | "decal" | "input" | "export" | "value" | "connection" | "other">("upload")

    private selectedNodes = computed(() => this.sceneManagerService.$selectedNodeParts().map((node) => node.templateNode))
    private selectedObjects = computed(() => this.selectedNodes().filter((node): node is Object => isObject(node)))
    private selectedPointGuideNodes = computed(() =>
        this.sceneManagerService
            .$selectedNodeParts()
            .map((templateNodePart) => this.sceneManagerService.getSceneNodeParts(templateNodePart))
            .flat()
            .map((node) => node.sceneNode)
            .filter((x): x is SceneNodes.Mesh => SceneNodes.Mesh.is(x)),
    )

    uploadSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Upload Mesh",
                action: () => this.uploadMesh(),
                iconClass: getNodeIconClass(StoredMesh.getNodeClass()),
            },
            {
                name: "Upload Image",
                action: () => this.uploadImage(),
                iconClass: getNodeIconClass(DataObjectReference.getNodeClass()),
            },
        ]
    })

    configurationSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Config Group",
                node: new ConfigGroup({name: "Config Group", nodes: new Nodes({list: []}), id: uuid4(), displayWithLabels: false}),
                iconClass: getNodeIconClass(ConfigGroup.getNodeClass()),
            },
            {
                name: "Group",
                node: new Group({name: "Group", nodes: new Nodes({list: []}), active: true}),
                iconClass: getNodeIconClass(Group.getNodeClass()),
            },
        ]
    })

    primitiveSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Plane",
                node: new ProceduralMesh({
                    name: "Plane",
                    geometryGraph: "plane",
                    parameters: {width: 1000, height: 1000},
                    materialAssignments: new MaterialAssignments({"0": null}),
                    materialSlotNames: {},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
            {
                name: "Box",
                node: new ProceduralMesh({
                    name: "Box",
                    geometryGraph: "box",
                    parameters: {width: 100, height: 100, depth: 100, inside: false, faceMaterials: false},
                    materialAssignments: new MaterialAssignments({"0": null, "1": null, "2": null}),
                    materialSlotNames: {"0": "Bottom", "1": "Outside", "2": "Top"},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
            {
                name: "Sphere",
                node: new ProceduralMesh({
                    name: "Sphere",
                    geometryGraph: "sphere",
                    parameters: {radius: 10, numU: 64, numV: 32},
                    materialAssignments: new MaterialAssignments({"0": null}),
                    materialSlotNames: {},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
            {
                name: "Studio",
                node: new ProceduralMesh({
                    name: "Studio",
                    geometryGraph: "simpleStudioRoom",
                    parameters: {height: 5, length: 10, width: 10, showCeiling: true, showFloor: true, showWalls: true},
                    materialAssignments: new MaterialAssignments({"0": null, "1": null, "2": null}),
                    materialSlotNames: {"0": "Floor", "1": "Walls", "2": "Ceiling"},
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    castRealtimeShadows: true,
                    receiveRealtimeShadows: true,
                }),
                iconClass: getNodeIconClass(ProceduralMesh.getNodeClass()),
            },
        ]
    })

    decalSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Mesh Decal",
                node: new MeshDecal({
                    name: "Mesh Decal",
                    mesh: null,
                    offset: [0, 0],
                    rotation: 0,
                    size: [10, 10],
                    distance: 0.01,
                    mask: undefined,
                    invertMask: false,
                    maskType: "binary",
                    materialAssignment: null,
                    visible: true,
                }),
                iconClass: getNodeIconClass(MeshDecal.getNodeClass()),
            },
            {
                name: "Mesh Curve",
                node: new MeshCurve({
                    name: "Mesh Curve",
                    mesh: null,
                    closed: false,
                    controlPoints: [],
                    visible: true,
                }),
                iconClass: getNodeIconClass(MeshCurve.getNodeClass()),
            },
            {
                name: "Seam",
                node: new Seam({
                    name: "Seam",
                    item: null,
                    curve: null,
                    allowScaling: false,
                    visible: true,
                }),
                iconClass: getNodeIconClass(Seam.getNodeClass()),
            },
            {
                name: "Annotation",
                node: new Annotation({
                    name: "Annotation",
                    id: uuid4(),
                    label: "Label",
                    description: "",
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                }),
                iconClass: getNodeIconClass(Annotation.getNodeClass()),
            },
        ]
    })

    inputSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Object Input",
                node: new ObjectInput({
                    name: "Object Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(ObjectInput.getNodeClass()),
            },
            {
                name: "Material Input",
                node: new MaterialInput({
                    name: "Material Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(MaterialInput.getNodeClass()),
            },
            {
                name: "Template Input",
                node: new TemplateInput({
                    name: "Template Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(TemplateInput.getNodeClass()),
            },
            {
                name: "Image Input",
                node: new ImageInput({
                    name: "Image Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(ImageInput.getNodeClass()),
            },
            {
                name: "String Input",
                node: new StringInput({
                    name: "String Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(StringInput.getNodeClass()),
            },
            {
                name: "Number Input",
                node: new NumberInput({
                    name: "Number Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(NumberInput.getNodeClass()),
            },
            {
                name: "Boolean Input",
                node: new BooleanInput({
                    name: "Boolean Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(BooleanInput.getNodeClass()),
            },
            {
                name: "JSON Input",
                node: new JSONInput({
                    name: "JSON Input",
                    id: uuid4(),
                }),
                iconClass: getNodeIconClass(JSONInput.getNodeClass()),
            },
        ]
    })

    exportSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Object Output",
                node: new ObjectExport({
                    name: "Object Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(ObjectExport.getNodeClass()),
            },
            {
                name: "Material Output",
                node: new MaterialExport({
                    name: "Material Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(MaterialExport.getNodeClass()),
            },
            {
                name: "Template Output",
                node: new TemplateExport({
                    name: "Template Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(TemplateExport.getNodeClass()),
            },
            {
                name: "Image Output",
                node: new ImageExport({
                    name: "Image Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(ImageExport.getNodeClass()),
            },
            {
                name: "String Output",
                node: new StringExport({
                    name: "String Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(StringExport.getNodeClass()),
            },
            {
                name: "Number Output",
                node: new NumberExport({
                    name: "Number Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(NumberExport.getNodeClass()),
            },
            {
                name: "Boolean Output",
                node: new BooleanExport({
                    name: "Boolean Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(BooleanExport.getNodeClass()),
            },
            {
                name: "JSON Output",
                node: new JSONExport({
                    name: "JSON Output",
                    id: uuid4(),
                    node: null,
                }),
                iconClass: getNodeIconClass(JSONExport.getNodeClass()),
            },
        ]
    })

    valueSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "String Value",
                node: new StringValue({
                    value: "",
                }),
                iconClass: getNodeIconClass(StringValue.getNodeClass()),
            },
            {
                name: "Number Value",
                node: new NumberValue({
                    value: 0,
                }),
                iconClass: getNodeIconClass(NumberValue.getNodeClass()),
            },
            {
                name: "Boolean Value",
                node: new BooleanValue({
                    value: false,
                }),
                iconClass: getNodeIconClass(BooleanValue.getNodeClass()),
            },
            {
                name: "JSON Value",
                node: new JSONValue({
                    value: {},
                }),
                iconClass: getNodeIconClass(JSONValue.getNodeClass()),
            },
        ]
    })

    connectionSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Rigid Relation",
                node: new RigidRelation({
                    translation: new Translation({x: 0, y: 0, z: 0}),
                    rotation: new FixedRotation({x: 0, y: 0, z: 0}),
                    targetA: null,
                    targetB: null,
                }),
                iconClass: getNodeIconClass(RigidRelation.getNodeClass()),
            },
            {
                name: "Rigidly Connect Selection",
                action: () => this.rigidlyConnectSelection(),
                iconClass: "far fa-arrow-right-arrow-left",
                disabled: this.selectedObjects().length <= 1,
            },
            {
                name: "Point Guide from Selection",
                action: () => this.addPointGuideForSelection(),
                iconClass: getNodeIconClass(PointGuide.getNodeClass()),
                disabled: this.selectedPointGuideNodes().length === 0,
            },
            {
                name: "Floor Guide",
                node: new PlaneGuide({
                    name: "Floor Guide",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.identity().toArray(),
                    visible: true,
                }),
                iconClass: getNodeIconClass(PlaneGuide.getNodeClass()),
            },
            {
                name: "Wall Guide",
                node: new PlaneGuide({
                    name: "Wall Guide",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.rotationX(90).toArray(),
                    visible: true,
                }),
                iconClass: getNodeIconClass(PlaneGuide.getNodeClass()),
            },
        ]
    })

    otherSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Create Template From Selection",
                action: () => this.createTemplateFromSelection(),
                iconClass: getNodeIconClass(TemplateInstance.getNodeClass()),
                disabled: this.selectedNodes().length == 0,
            },
            {
                name: "Overlay Material Color",
                node: new OverlayMaterialColor({
                    material: null,
                    overlay: null,
                    size: [10, 10],
                }),
                iconClass: getNodeIconClass(OverlayMaterialColor.getNodeClass()),
            },
            {
                name: "LOD Type",
                node: new LodType({
                    lodType: "web",
                }),
                iconClass: getNodeIconClass(LodType.getNodeClass()),
            },
        ]
    })

    sceneItems = computed<SceneItem[]>(() => {
        const currentSection = this.currentSection()
        switch (currentSection) {
            case "upload":
                return this.uploadSceneItems()
            case "primitive":
                return this.primitiveSceneItems()
            case "configuration":
                return this.configurationSceneItems()
            case "decal":
                return this.decalSceneItems()
            case "input":
                return this.inputSceneItems()
            case "export":
                return this.exportSceneItems()
            case "value":
                return this.valueSceneItems()
            case "connection":
                return this.connectionSceneItems()
            case "other":
                return this.otherSceneItems()
        }
    })

    addItem(node: Node) {
        this.sceneManagerService.modifyTemplateGraph((graph) => {
            graph.parameters.nodes.addEntry(node)
        })
    }

    dragStart(event: DragEvent, node: Node) {
        this.drag.dragStart(event, node)
    }

    private async uploadMesh(): Promise<false | void> {
        const file = await selectFile("Select a mesh file for upload", ".cmm,.obj,.ply")
        if (!file) return false

        const action = async () => {
            const group = await uploadMeshToGroup(this.sdk, this.uploadService, this.workerService, this.sceneManagerService, file)
            if (!group) return

            this.addItem(group)
        }

        action()
    }

    private async uploadImage(): Promise<false | void> {
        const file = await selectFile("Select an image file for upload", "image/*")
        if (!file) return false

        const action = async () => {
            const image = await uploadImage(this.sdk, this.uploadService, this.sceneManagerService, this.dialog, file)
            if (!image) return

            this.addItem(image)
        }

        action()
    }

    private rigidlyConnectSelection() {
        const selectedObjects = this.selectedObjects()
        if (selectedObjects.length < 2) return

        const primaryObj = selectedObjects[0]

        const nodeOwner = getNodeOwner(primaryObj)
        if (!nodeOwner) throw new Error("Cannot find node owner for selected object")

        this.sceneManagerService.modifyTemplateGraph(() => {
            const matrixA = this.sceneManagerService.getTransformAccessor(primaryObj)?.getTransform()

            if (!matrixA) return

            for (let i = 1; i < selectedObjects.length; i++) {
                const secondaryObject = selectedObjects[i]
                const matrixB = this.sceneManagerService.getTransformAccessor(secondaryObject)?.getTransform()

                if (!matrixB) continue

                const {position, quaternion} = matrixA.inverse().multiply(matrixB).decompose()
                const angles = quaternionToAngleDegrees(quaternion)

                nodeOwner.parameters.nodes.addEntry(
                    new RigidRelation({
                        translation: new Translation({x: position.x, y: position.y, z: position.z}),
                        rotation: new FixedRotation({x: angles[0], y: angles[1], z: angles[2]}),
                        targetA: primaryObj,
                        targetB: secondaryObject,
                    }),
                )
                secondaryObject.updateParameters({lockedTransform: undefined})
            }
        })
    }

    private addPointGuideForSelection() {
        const selectedPointGuideNodes = this.selectedPointGuideNodes()

        if (selectedPointGuideNodes.length === 0) return

        const centroidAccumulator = new CentroidAccumulator()
        selectedPointGuideNodes.forEach((sceneNode) => centroidAccumulator.accumulate(sceneNode.meshData, sceneNode.transform))
        const centroid = Vector3.fromArray(centroidAccumulator.finalize().centroid)
        const transform = Matrix4.translation(centroid.x, centroid.y, centroid.z).toArray()

        this.addItem(
            new PointGuide({
                name: "Point Guide",
                lockedTransform: transform,
                $defaultTransform: transform,
                visible: true,
            }),
        )
    }

    private moveSelectionTo(targetNode: NodeOwner) {
        const otherSelectedNodes = this.selectedNodes()
            .filter(isNode)
            .filter((node) => node !== targetNode)

        otherSelectedNodes.forEach((node) =>
            getNodeOwner(node, (nodeOwner) => {
                if (nodeOwner !== targetNode) {
                    nodeOwner.parameters.nodes.removeEntry(node)
                    targetNode.parameters.nodes.addEntry(node)
                }
            }),
        )
    }

    private createTemplateFromSelection() {
        const selectedNodes = this.selectedNodes()
        if (selectedNodes.length === 0) return

        const dialogRef = this.dialog.open(RenameDialogComponent, {
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                currentName: "Template Graph",
            },
        })

        dialogRef
            .afterClosed()
            .pipe(
                tap((newValue) => {
                    if (newValue) {
                        this.sceneManagerService.modifyTemplateGraph((templateGraph) => {
                            const templateSubGraph = new TemplateGraph({
                                name: newValue,
                                nodes: new Nodes({list: []}),
                            })
                            templateGraph.parameters.nodes.addEntry(templateSubGraph)
                            this.moveSelectionTo(templateSubGraph)
                        })
                    }
                }),
            )
            .subscribe()
    }
}
