import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {assertNever, deepCopy} from "@cm/lib/utils/utils"
import {
    Operator,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorProcessingHints,
} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {HighpassPanelComponent} from "app/textures/texture-editor/operator-stack/operators/highpass/panel/highpass-panel.component"
import {HighpassToolbox} from "app/textures/texture-editor/operator-stack/operators/highpass/toolbox/highpass-toolbox"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {Vector2} from "@cm/lib/math/vector2"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {toGrayscale} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-to-grayscale"
import {affineTransform} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-affine-transform"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {blur} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/blur"
import {math} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-math"
import {blend} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-blend"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-drawable-image"
import {mean} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/composite/mean"

export class OperatorHighpass extends OperatorBase<TextureEditNodes.OperatorHighpass> {
    readonly panelComponentType: OperatorPanelComponentType = HighpassPanelComponent
    readonly canvasToolbox: HighpassToolbox

    readonly type = "operator-highpass" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorHighpass | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-highpass",
                enabled: true,
                blurSettingsH: {
                    enabled: true,
                    smoothingDistance: 150,
                },
                blurSettingsV: {
                    enabled: true,
                    smoothingDistance: 150,
                },
                intensity: 1,
                wrapAround: false,
                angleOffset: 0,
                maskReference: undefined,
                correctionMode: "modulation",
            },
        )

        // convert legacy smoothingDistance to blurSettings
        if (this.node.smoothingDistance) {
            this.node.blurSettingsH = {
                enabled: true,
                smoothingDistance: this.node.smoothingDistance.x,
            }
            this.node.blurSettingsV = {
                enabled: true,
                smoothingDistance: this.node.smoothingDistance.y,
            }
            this.node.angleOffset = 0
            delete this.node.smoothingDistance
        }

        this.canvasToolbox = new HighpassToolbox(this)

        this.updateDrawableImageRef()
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this.canvasToolbox.remove()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorHighpass(this.callback, deepCopy(this.node))
        if (this._maskDrawableImageRef) {
            clonedOperator.addMask()
            await this.callback.drawableImageCache.cloneImage(this._maskDrawableImageRef, clonedOperator._maskDrawableImageRef!)
        }
        return clonedOperator
    }

    // OperatorBase
    async queueImageOps(cmdQueue: ImageOpCommandQueue, input: OperatorInput, hints: OperatorProcessingHints): Promise<OperatorOutput> {
        cmdQueue.beginScope(this.type)
        const sourceImage = input
        const blurSettingsH = {
            enabled: this.node.blurSettingsH.enabled,
            smoothingDistance: Math.round(this.node.blurSettingsH.smoothingDistance),
        }
        const blurSettingsV = {
            enabled: this.node.blurSettingsV.enabled,
            smoothingDistance: Math.round(this.node.blurSettingsV.smoothingDistance),
        }
        const wrapAround = this.node.wrapAround
        const intensity = this.node.intensity ?? 1
        const angleOffset = this.node.angleOffset

        const applySourceImageConversion = (sourceImage: OperatorInput) => {
            const correctLuminanceOnly = false
            if (correctLuminanceOnly) {
                const grayscaleConversionMode = descriptorByTextureType(hints.textureType).isColorData ? "luminance" : "average"
                return toGrayscale(cmdQueue, {
                    sourceImage: sourceImage,
                    mode: grayscaleConversionMode,
                })
            } else {
                return sourceImage
            }
        }
        const filterSourceImage = applySourceImageConversion(sourceImage)

        // blur to compute low-pass
        let blurSource: OperatorInput
        const useAngleOffset =
            angleOffset !== 0 && (blurSettingsH.enabled || blurSettingsV.enabled) && blurSettingsH.smoothingDistance !== blurSettingsV.smoothingDistance
        const rotationTransform = new Matrix3x2().rotate(angleOffset)
        const expandedSize = (() => {
            const p00 = rotationTransform.multiplyVector(new Vector2(-sourceImage.descriptor.width, -sourceImage.descriptor.height).mulInPlace(0.5))
            const p10 = rotationTransform.multiplyVector(new Vector2(sourceImage.descriptor.width, -sourceImage.descriptor.height).mulInPlace(0.5))
            const p01 = rotationTransform.multiplyVector(new Vector2(-sourceImage.descriptor.width, sourceImage.descriptor.height).mulInPlace(0.5))
            const p11 = rotationTransform.multiplyVector(new Vector2(sourceImage.descriptor.width, sourceImage.descriptor.height).mulInPlace(0.5))
            const min = Vector2.min(p00, Vector2.min(p10, Vector2.min(p01, p11)))
            const max = Vector2.max(p00, Vector2.max(p10, Vector2.max(p01, p11)))
            return max.sub(min).add(new Vector2(blurSettingsH.smoothingDistance, blurSettingsV.smoothingDistance)).ceil()
        })()
        if (useAngleOffset) {
            blurSource = affineTransform(cmdQueue, {
                sourceImage: filterSourceImage,
                transform: new Matrix3x2()
                    .translate(expandedSize.mul(0.5))
                    .rotate(-(angleOffset ?? 0))
                    .translate(new Vector2(sourceImage.descriptor.width, sourceImage.descriptor.height).mul(-0.5)),
                addressMode: wrapAround ? "wrap" : "clamp",
                resultImageOrDataType: createImage(cmdQueue, {
                    imageOrDescriptor: {
                        ...sourceImage.descriptor,
                        width: expandedSize.x,
                        height: expandedSize.y,
                    },
                }),
            })
        } else {
            blurSource = filterSourceImage
        }
        let lowpassImage: OperatorInput = blur(cmdQueue, {
            sourceImage: blurSource,
            blurKernelSize: {
                x: blurSettingsH.enabled ? blurSettingsH.smoothingDistance : sourceImage.descriptor.width,
                y: blurSettingsV.enabled ? blurSettingsV.smoothingDistance : sourceImage.descriptor.height,
            },
            borderMode: wrapAround ? "wrap" : "wrap-mirrored",
        })
        if (useAngleOffset) {
            lowpassImage = affineTransform(cmdQueue, {
                sourceImage: lowpassImage,
                transform: new Matrix3x2()
                    .translate(new Vector2(sourceImage.descriptor.width, sourceImage.descriptor.height).mul(0.5))
                    .rotate(angleOffset ?? 0)
                    .translate(expandedSize.mul(-0.5)),
                resultImageOrDataType: createImage(cmdQueue, {
                    imageOrDescriptor: sourceImage.descriptor,
                }),
            })
        }

        // get optional mask
        const mask = this._maskDrawableImageRef
            ? drawableImage(cmdQueue, {
                  drawableImageRef: this._maskDrawableImageRef,
                  descriptor: {
                      width: sourceImage.descriptor.width,
                      height: sourceImage.descriptor.height,
                      channelLayout: "R" as const,
                      dataType: TextureEditorSettings.PreviewProcessingImageFormat,
                  },
              })
            : undefined
        if (mask && cmdQueue.mode === "preview" && this.selected && this.showMask) {
            cmdQueue.endScope(this.type)
            return {
                resultImage: mask,
                options: {
                    stopEvaluation: true,
                },
            }
        } else if (cmdQueue.mode === "preview" && this.selected && this.showLowpass) {
            cmdQueue.endScope(this.type)
            return {
                resultImage: lowpassImage,
                options: {
                    stopEvaluation: true,
                },
            }
        } else {
            // compute mean
            const sourceImageMean = mean(cmdQueue, {
                sourceImage: filterSourceImage,
                resultDataType: "float16",
            }).mean

            // compute highpass
            let highpassImage: OperatorInput
            if (this.correctionMode === "offset") {
                const meanMinusLowpass = math(cmdQueue, {
                    operator: "-",
                    operandA: sourceImageMean,
                    operandB: lowpassImage,
                })
                highpassImage = math(cmdQueue, {
                    operator: "+",
                    operandA: sourceImage,
                    operandB: meanMinusLowpass,
                })
            } else if (this.correctionMode === "modulation") {
                const meanDivLowpass = math(cmdQueue, {
                    operator: "/safe",
                    operandA: sourceImageMean,
                    operandB: lowpassImage,
                })
                highpassImage = math(cmdQueue, {
                    operator: "*",
                    operandA: sourceImage,
                    operandB: meanDivLowpass,
                })
            } else {
                assertNever(this.correctionMode)
            }

            // optionally apply mask
            let resultImage: OperatorInput
            if (!mask) {
                resultImage = highpassImage
            } else {
                resultImage = blend(cmdQueue, {
                    backgroundImage: sourceImage,
                    foregroundImage: highpassImage,
                    alpha: mask,
                    blendMode: "normal",
                    premultipliedAlpha: false,
                })
            }

            // optionally blend by intensity
            if (intensity !== 1) {
                resultImage = blend(cmdQueue, {
                    backgroundImage: sourceImage,
                    foregroundImage: resultImage,
                    alpha: intensity,
                    blendMode: "normal",
                    premultipliedAlpha: false,
                })
            }

            cmdQueue.endScope(this.type)
            return {
                resultImage,
            }
        }
    }

    set showGuides(value: boolean) {
        this.canvasToolbox.showGuides = value
    }

    get showGuides(): boolean {
        return this.canvasToolbox.showGuides
    }

    set showLowpass(value: boolean) {
        if (this._showLowpass === value) {
            return
        }
        this._showLowpass = value
        if (this.showLowpass) {
            this.showMask = false
        }
        this.requestEval()
    }

    get showLowpass(): boolean {
        return this._showLowpass
    }

    set showMask(value: boolean) {
        if (this._showMask === value) {
            return
        }
        this._showMask = value
        if (this.showMask) {
            this.showLowpass = false
        }
        this.requestEval()
    }

    get showMask(): boolean {
        return this._showMask
    }

    set intensity(value: number) {
        if (this.node.intensity === value) {
            return
        }
        this.node.intensity = value
        this.markEdited()
        this.requestEval()
    }

    get intensity(): number {
        return this.node.intensity ?? 1
    }

    set useWrapAround(value: boolean) {
        if (this.node.wrapAround === value) {
            return
        }
        this.node.wrapAround = value
        this.markEdited()
        this.requestEval()
    }

    get useWrapAround(): boolean {
        return this.node.wrapAround
    }

    get isIsotropic(): boolean {
        return this.useH && this.useV && this.smoothingDistanceH === this.smoothingDistanceV
    }

    set useH(value: boolean) {
        if (this.useH === value) {
            return
        }
        this.node.blurSettingsH.enabled = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get useH(): boolean {
        return this.node.blurSettingsH.enabled
    }

    set smoothingDistanceH(value: number) {
        if (this.smoothingDistanceH === value) {
            return
        }
        this.node.blurSettingsH.smoothingDistance = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get smoothingDistanceH(): number {
        return this.node.blurSettingsH.smoothingDistance
    }

    set useV(value: boolean) {
        if (this.useV === value) {
            return
        }
        this.node.blurSettingsV.enabled = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get useV(): boolean {
        return this.node.blurSettingsV.enabled
    }

    set smoothingDistanceV(value: number) {
        if (this.smoothingDistanceV === value) {
            return
        }
        this.node.blurSettingsV.smoothingDistance = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get smoothingDistanceV(): number {
        return this.node.blurSettingsV.smoothingDistance
    }

    set angleOffset(value: number) {
        if (this.angleOffset === value) {
            return
        }
        this.node.angleOffset = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get angleOffset(): number {
        return this.node.angleOffset
    }

    get hasMask(): boolean {
        return this.node.maskReference !== undefined
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    set correctionMode(value: TextureEditNodes.OperatorHighpassCorrectionMode) {
        if (this.node.correctionMode === value) {
            return
        }
        this.node.correctionMode = value
        this.markEdited()
        this.requestEval()
    }

    get correctionMode(): TextureEditNodes.OperatorHighpassCorrectionMode {
        return this.node.correctionMode ?? "offset"
    }

    addMask(): void {
        if (this.hasMask) {
            return
        }
        this.node.maskReference = {
            type: "data-object-reference",
            dataObjectId: "",
        }
        this.updateDrawableImageRef()
        this.markEdited()
        this.requestEval()
    }

    removeMask(): void {
        if (!this.hasMask) {
            return
        }
        this.node.maskReference = undefined
        this.showMask = false
        this.updateDrawableImageRef()
        this.markEdited()
        this.requestEval()
    }

    private updateDrawableImageRef(): void {
        this._maskDrawableImageRef = this.node.maskReference ? new DrawableImageRef(this.node.maskReference, this.callback.customerLegacyId) : undefined
        this.canvasToolbox.setMaskReference(this._maskDrawableImageRef)
    }

    private _showLowpass = false
    private _showMask = false
    private _brushSettings = new BrushSettings()
    private _maskDrawableImageRef: DrawableImageRef | undefined
}
