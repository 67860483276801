<cm-inspector-section>
    <ng-container cm-title> Visibility</ng-container>
    <ng-container cm-content>
        <cm-toggle [(toggle)]="showGuides" [disabled]="disabled">
            <ng-container cm-text><span [class.cm-disabled]="disabled">Show guides</span></ng-container>
        </cm-toggle>
        <cm-toggle [(toggle)]="showLowpass" [disabled]="disabled">
            <ng-container cm-text><span [class.cm-disabled]="disabled">Show gradients (that will be removed)</span></ng-container>
        </cm-toggle>
        <cm-toggle [disabled]="!hasMask" [(toggle)]="showMask" [disabled]="disabled">
            <ng-container cm-text><span [class.cm-disabled]="disabled">Show mask</span></ng-container>
        </cm-toggle>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title> Settings</ng-container>
    <ng-container cm-content>
        <span class="cm-label">Mode</span>
        <div class="cm-mode-selections">
            <cm-button class="cm-mode-selection" [class.cm-active]="correctionMode === 'offset'" (click)="correctionMode = 'offset'" [disabled]="disabled">
                <ng-container cm-left-icon><i class="far fa-plus"></i></ng-container>
                <ng-container cm-text>Offset</ng-container>
            </cm-button>
            <cm-button
                class="cm-mode-selection"
                [class.cm-active]="correctionMode === 'modulation'"
                (click)="correctionMode = 'modulation'"
                [disabled]="disabled"
            >
                <ng-container cm-left-icon><i class="far fa-multiply"></i></ng-container>
                <ng-container cm-text>Modulation</ng-container>
            </cm-button>
        </div>
        <cm-toggle [(toggle)]="useWrapAround" [disabled]="disabled">
            <ng-container cm-text><span [class.cm-disabled]="disabled">Wrap around</span></ng-container>
        </cm-toggle>
        <cm-toggle [(toggle)]="useIndividualHV" [disabled]="disabled">
            <ng-container cm-text><span [class.cm-disabled]="disabled">Directional</span></ng-container>
        </cm-toggle>
        <div>
            <span class="cm-label">Intensity</span>
            <cm-slider [min]="0" [max]="1" [(value)]="intensity" [disabled]="disabled"></cm-slider>
        </div>
        @if (useIndividualHV) {
            <cm-inspector-section>
                <ng-container cm-title> Width</ng-container>
                <ng-container cm-content>
                    <cm-toggle [(toggle)]="infiniteH" [disabled]="disabled">
                        <ng-container cm-text><span [class.cm-disabled]="disabled">Infinite</span></ng-container>
                    </cm-toggle>
                    <div>
                        <span class="cm-label">Smoothing distance ({{ measureUnit }})</span>
                        <cm-slider
                            [disabled]="infiniteH || disabled"
                            [min]="px2measure(1)"
                            [max]="px2measure(5000)"
                            [exponent]="4"
                            [displayValuePrecision]="2"
                            [value]="px2measure(smoothingDistanceH)"
                            (valueChange)="smoothingDistanceH = measure2px($event)"
                        ></cm-slider>
                    </div>
                </ng-container>
            </cm-inspector-section>
            <cm-inspector-section>
                <ng-container cm-title> Height</ng-container>
                <ng-container cm-content>
                    <cm-toggle [(toggle)]="infiniteV" [disabled]="disabled">
                        <ng-container cm-text><span [class.cm-disabled]="disabled">Infinite</span></ng-container>
                    </cm-toggle>
                    <div>
                        <span class="cm-label">Smoothing distance ({{ measureUnit }})</span>
                        <cm-slider
                            [disabled]="infiniteV || disabled"
                            [min]="px2measure(1)"
                            [max]="px2measure(5000)"
                            [displayValuePrecision]="2"
                            [exponent]="4"
                            [value]="px2measure(smoothingDistanceV)"
                            (valueChange)="smoothingDistanceV = measure2px($event)"
                        ></cm-slider>
                    </div>
                </ng-container>
            </cm-inspector-section>
            <cm-inspector-section>
                <ng-container cm-title> Rotation</ng-container>
                <ng-container cm-content>
                    <div>
                        <span class="cm-label" [class.cm-disabled]="disabled">Angle (deg)</span>
                        <cm-slider [min]="-90" [max]="90" [(value)]="angleOffset" [disabled]="disabled"></cm-slider>
                    </div>
                </ng-container>
            </cm-inspector-section>
        } @else {
            <div>
                <span class="cm-label" [class.cm-disabled]="disabled">Smoothing radius ({{ measureUnit }})</span>
                <cm-slider
                    [min]="px2measure(1)"
                    [max]="px2measure(5000)"
                    [displayValuePrecision]="2"
                    [exponent]="4"
                    [value]="px2measure(smoothingDistanceH)"
                    (valueChange)="smoothingDistanceH = measure2px($event)"
                    [disabled]="disabled"
                ></cm-slider>
            </div>
        }
    </ng-container>
</cm-inspector-section>
@if (hasMask) {
    <cm-button (click)="removeMask()" class="cm-mask-remove" [disabled]="disabled">
        <ng-container cm-left-icon><i class="far fa-trash"></i></ng-container>
        <ng-container cm-text>Remove mask</ng-container>
    </cm-button>
    <cm-action-panel-row>
        <cm-brush-settings-panel [brushSettings]="brushSettings" [disabled]="disabled"></cm-brush-settings-panel>
    </cm-action-panel-row>
} @else {
    <cm-button (click)="addMask()" class="cm-mask-add" [disabled]="disabled">
        <ng-container cm-left-icon><i class="far fa-plus"></i></ng-container>
        <ng-container cm-text>Add mask</ng-container>
    </cm-button>
}
