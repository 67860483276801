import {Size2Like} from "ts-lib/dist/browser/math/size2"
import {Box2Like} from "ts-lib/dist/browser/math/box2"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-copy-region"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {getHalAddressMode, getVec4Color} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"
import {ColorLike} from "ts-lib/dist/browser/math"
import {DataType, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {ImageOpCommandQueueWebGL2} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue-webgl2"
import {createImage} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-create-image"
import {convert} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convert"

const SCOPE_NAME = "CreateImagePyramid"

export type ParameterType = {
    sourceImage: ImageRef
    sourceRegion?: Box2Like // default: {x: 0, y: 0, width: sourceImage.width, height: sourceImage.height}
    maxLevels?: number // defaults to full pyramid down to 1x1
    minResolution?: Size2Like // defaults to 1x1
    smooth?: boolean // default: true
    addressMode?: AddressMode // default: "clamp"
    borderColor?: ColorLike // default: {r: 0, g: 0, b: 0, a: 1}
    resultDataType?: DataType // default: sourceImage.dataType
}

export type ReturnType = ImageRef[]

export const createImagePyramid = (
    cmdQueue: ImageOpCommandQueueWebGL2,
    {sourceImage, sourceRegion, maxLevels, minResolution, smooth, addressMode, borderColor, resultDataType}: ParameterType,
): ReturnType => {
    cmdQueue.beginScope(SCOPE_NAME)
    smooth ??= false
    addressMode ??= "clamp"
    borderColor ??= {r: 0, g: 0, b: 0, a: 1}
    const painterDownSample2x2 = cmdQueue.createPainter(
        "compositor",
        "downSample2x2",
        `
        vec4 computeColor(ivec2 targetPixel) {
            return (texelFetch0(targetPixel * 2 + ivec2(0, 0), ${getHalAddressMode(addressMode)}, ${getVec4Color(borderColor)}) +
                    texelFetch0(targetPixel * 2 + ivec2(1, 0), ${getHalAddressMode(addressMode)}, ${getVec4Color(borderColor)}) +
                    texelFetch0(targetPixel * 2 + ivec2(0, 1), ${getHalAddressMode(addressMode)}, ${getVec4Color(borderColor)}) +
                    texelFetch0(targetPixel * 2 + ivec2(1, 1), ${getHalAddressMode(addressMode)}, ${getVec4Color(borderColor)})) * 0.25;
        }
    `,
    )
    const smoothingCode = (addressMode: AddressMode, borderColor: ColorLike, indexFn: (i: number) => string) => `
        vec4 fetch(ivec2 texelIndex) {
            return texelFetch0(texelIndex, ${getHalAddressMode(addressMode)}, ${getVec4Color(borderColor)});
        }

        vec4 computeColor(ivec2 targetPixel) {
            vec4 color = vec4(0);
            bool use3Tap = true;
            if (use3Tap) {
                // 3-tap
                color += fetch(targetPixel + ${indexFn(-1)}) * 1.0;
                color += fetch(targetPixel + ${indexFn(0)}) * 2.0;
                color += fetch(targetPixel + ${indexFn(+1)}) * 1.0;
                color /= 4.0;
            } else {
                // 5-tap
                color += fetch(targetPixel + ${indexFn(-2)}) * 1.0;
                color += fetch(targetPixel + ${indexFn(-1)}) * 4.0;
                color += fetch(targetPixel + ${indexFn(0)}) * 6.0;
                color += fetch(targetPixel + ${indexFn(+1)}) * 4.0;
                color += fetch(targetPixel + ${indexFn(+2)}) * 1.0;
                color /= 16.0;
            }
            return color;
        }
    `
    const painterSmoothH = smooth
        ? cmdQueue.createPainter(
              "compositor",
              "smoothH",
              smoothingCode(addressMode, borderColor, (i) => `ivec2(${i}, 0)`),
          )
        : undefined
    const painterSmoothV = smooth
        ? cmdQueue.createPainter(
              "compositor",
              "smoothV",
              smoothingCode(addressMode, borderColor, (i) => `ivec2(0, ${i})`),
          )
        : undefined
    if (sourceRegion) {
        sourceImage = copyRegion(cmdQueue, {sourceImage, sourceRegion, addressMode, borderColor, resultImageOrDataType: resultDataType})
    } else {
        const sourceDescriptor = sourceImage.descriptor
        sourceRegion ??= {x: 0, y: 0, width: sourceDescriptor.width, height: sourceDescriptor.height}
        sourceImage = convert(cmdQueue, {sourceImage, dataType: resultDataType})
    }
    if (maxLevels !== undefined) {
        if (maxLevels < 1) {
            throw new Error("maxLevels must be at least 1")
        }
        if (minResolution !== undefined) {
            throw new Error("minResolution is not allowed when maxLevels is set")
        }
        const maxLevelScaling = 2 ** (maxLevels - 1)
        minResolution = {
            width: Math.ceil(sourceRegion.width / maxLevelScaling),
            height: Math.ceil(sourceRegion.height / maxLevelScaling),
        }
    }
    if (!minResolution) {
        minResolution = {width: 1, height: 1}
    }
    const resultImages = [sourceImage]
    while (sourceImage.descriptor.width > minResolution.width || sourceImage.descriptor.height > minResolution.height) {
        const targetImage = createImage(cmdQueue, {
            imageOrDescriptor: {
                ...sourceImage.descriptor,
                width: Math.max(minResolution.width, Math.ceil(sourceRegion.width / 2)),
                height: Math.max(minResolution.height, Math.ceil(sourceRegion.height / 2)),
            },
        })
        cmdQueue.paint(painterDownSample2x2, {
            sourceImages: sourceImage,
            resultImage: targetImage,
        })
        if (painterSmoothH && painterSmoothV) {
            const targetSmoothedH = createImage(cmdQueue, {imageOrDescriptor: targetImage})
            cmdQueue.paint(painterSmoothH, {
                sourceImages: targetImage,
                resultImage: targetSmoothedH,
            })
            cmdQueue.paint(painterSmoothV, {
                sourceImages: targetSmoothedH,
                resultImage: targetImage,
            })
        }
        resultImages.push(targetImage)
        sourceImage = targetImage
        sourceRegion = {x: 0, y: 0, width: sourceImage.descriptor.width, height: sourceImage.descriptor.height}
    }
    cmdQueue.endScope(SCOPE_NAME)
    return resultImages
}
