import {DeclareMaterialNode, materialSlots} from "@src/materials/declare-material-node"
import {z} from "zod"
import {threeConvert, threeValueNode} from "@src/materials/three-utils"
import {getAll} from "@src/graph-system/utils"
import * as THREENodes from "three/examples/jsm/nodes/Nodes"

export class Math extends DeclareMaterialNode(
    {
        returns: z.object({value: materialSlots}),
        inputs: z.object({
            value: materialSlots.optional(),
            value_001: materialSlots.optional(),
        }),
        parameters: z.object({
            operation: z
                .enum([
                    "ADD",
                    "SUBTRACT",
                    "MULTIPLY",
                    "DIVIDE",
                    "MULTIPLY_ADD",
                    "POWER",
                    "LOGARITHM",
                    "SQRT",
                    "INVERSE_SQRT",
                    "ABSOLUTE",
                    "EXPONENT",
                    "MINIMUM",
                    "MAXIMUM",
                    "LESS_THAN",
                    "GREATER_THAN",
                    "SIGN",
                    "COMPARE",
                    "SMOOTH_MIN",
                    "SMOOTH_MAX",
                    "ROUND",
                    "FLOOR",
                    "CEIL",
                    "TRUNC",
                    "FRACT",
                    "MODULO",
                    "FLOORED_MODULO",
                    "WRAP",
                    "SNAP",
                    "PINGPONG",
                    "SINE",
                    "COSINE",
                    "TANGENT",
                    "ARCSINE",
                    "ARCCOSINE",
                    "ARCTANGENT",
                    "ARCTAN2",
                    "SINH",
                    "COSH",
                    "TANH",
                    "RADIANS",
                    "DEGREES",
                ])
                .optional(),
            value: z.number().optional(),
            value_001: z.number().optional(),
            value_002: z.number().optional(), //used by "wrap"
            useClamp: z.boolean().optional(),
        }),
    },
    {
        toThree: async ({get, inputs, parameters}) => {
            const {value: valueA, value_001: valueB} = await getAll(inputs, get)
            const inputA = valueA ?? threeConvert(parameters.value, threeValueNode) ?? threeValueNode(0)
            const inputB = valueB ?? threeConvert(parameters.value_001, threeValueNode) ?? threeValueNode(0)
            const {useClamp} = parameters
            const operation = parameters.operation ?? "ADD"

            const getValue = () => {
                switch (operation) {
                    case "ADD":
                        return THREENodes.add(inputA, inputB)
                    case "SUBTRACT":
                        return THREENodes.sub(inputA, inputB)
                    case "MULTIPLY":
                        return THREENodes.mul(inputA, inputB)
                    case "DIVIDE":
                        return THREENodes.div(inputA, inputB)
                    case "POWER":
                        return THREENodes.pow(inputA, inputB)
                    case "LOGARITHM":
                        return THREENodes.log(inputA)
                    case "SQRT":
                        return THREENodes.sqrt(inputA)
                    case "INVERSE_SQRT":
                        return THREENodes.inverseSqrt(inputA)
                    case "ABSOLUTE":
                        return THREENodes.abs(inputA)
                    case "EXPONENT":
                        return THREENodes.exp(inputA)
                    case "MINIMUM":
                        return THREENodes.min(inputA, inputB)
                    case "MAXIMUM":
                        return THREENodes.max(inputA, inputB)
                    case "LESS_THAN":
                        return THREENodes.lessThan(inputA, inputB)
                    case "GREATER_THAN":
                        return THREENodes.greaterThan(inputA, inputB)
                    case "SIGN":
                        return THREENodes.sign(inputA)
                    case "ROUND":
                        return THREENodes.round(inputA)
                    case "FLOOR":
                        return THREENodes.floor(inputA)
                    case "CEIL":
                        return THREENodes.ceil(inputA)
                    case "TRUNC":
                        return THREENodes.trunc(inputA)
                    case "FRACT":
                        return THREENodes.fract(inputA)
                    case "MODULO":
                        return THREENodes.mod(inputA, inputB)
                    case "SINE":
                        return THREENodes.sin(inputA)
                    case "COSINE":
                        return THREENodes.cos(inputA)
                    case "TANGENT":
                        return THREENodes.tan(inputA)
                    case "ARCSINE":
                        return THREENodes.asin(inputA)
                    case "ARCCOSINE":
                        return THREENodes.acos(inputA)
                    case "ARCTANGENT":
                        return THREENodes.atan(inputA)
                    case "ARCTAN2":
                        return THREENodes.atan2(inputA, inputB)
                    case "RADIANS":
                        return THREENodes.radians(inputA)
                    case "DEGREES":
                        return THREENodes.degrees(inputA)
                    default:
                        throw new Error(`Invalid operation: ${operation}`)
                }
            }

            const value = getValue()
            return {value: useClamp ? THREENodes.clamp(value, threeValueNode(0), threeValueNode(1)) : value}
        },
    },
) {}
