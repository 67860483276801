import {Component, inject, Input} from "@angular/core"
import {Params, RouterLink} from "@angular/router"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-navigation-item",
    templateUrl: "./navigation-item.component.html",
    styleUrls: ["./navigation-item.component.scss"],
    standalone: true,
    imports: [MemoizePipe, RouterLink],
})
export class NavigationItemComponent {
    @Input() active?: boolean = false
    @Input() link?: string[] | null
    @Input() params: Params | null = null
    @Input() filterForCustomer?: boolean = false

    permission = inject(PermissionsService)
    organizations = inject(OrganizationsService)
    $can = this.permission.$to

    constructor(public auth: AuthService) {}
}
