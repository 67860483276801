<div class="cm-container">
    <div class="cm-left-panel">
        <div class="cm-left-panel-option" [class.cm-selected]="currentSection() === 'camera'" (click)="currentSection.set('camera')">
            <i class="far fa-camera"></i>
            Camera and Properties
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="currentSection() === 'light'" (click)="currentSection.set('light')">
            <i class="far fa-lightbulb"></i>
            Lights
        </div>
        <div class="cm-left-panel-option" [class.cm-selected]="currentSection() === 'hdri'" (click)="currentSection.set('hdri')">
            <i class="far fa-sun"></i>
            HDRI Lights
        </div>
        @if (currentSection() === "hdri") {
            <div class="cm-filters-container">
                <cm-search (valueChange)="searchText$.next($event)" placeholder="Name, description, ..."></cm-search>
                <cm-own-organizations-filter (selectionChanged)="organizationsIds$.next($event)"></cm-own-organizations-filter>
            </div>
        }
    </div>
    @if (currentSection() === "hdri") {
        <cm-infinite-list
            [count]="data().length"
            [intersectionStatusChange]="pageFilledStatusChange$"
            containerSelector=".cdk-overlay-container"
            class="cm-items-list"
            [listType]="'grid'"
            [gridSize]="gridSize"
            class="cm-item-list"
        >
            @for (item of data(); track item.data?.id) {
                @if (item.error) {
                    <cm-card-error @fadeInPlaceholder></cm-card-error>
                } @else if (item.data) {
                    <cm-template-add-card [data]="item.data" [node]="item.node" (onItemClicked)="onItemClicked.emit()"> </cm-template-add-card>
                } @else {
                    <cm-card-placeholder @fadeInPlaceholder></cm-card-placeholder>
                }
            }
        </cm-infinite-list>
    } @else {
        <div>
            <div class="cm-list-container">
                @for (sceneItem of sceneItems(); track sceneItem.name) {
                    <cm-template-add-card [data]="sceneItem" [node]="sceneItem.node" (onItemClicked)="onItemClicked.emit()"> </cm-template-add-card>
                }
            </div>
        </div>
    }
</div>
