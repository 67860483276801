<div class="cm-grid-tile-container">
    <div class="cm-grid-tile">
        <input #fileInput class="cm-select-file" (change)="onFilesSelected($event)" type="file" [accept]="MimeType.Images" />
        <cm-drop-files
            [mimeTypeFilter]="MimeType.Images"
            [showDropzone]="$showDropzone()"
            [enableDrop]="canDrop"
            [maxFiles]="1"
            (filesDropped)="onFilesDropped($event)"
            (click)="canDrop ? fileInput.click() : false"
            subtitle="to upload texture or click to browse"
        >
            <div class="cm-grid-tile-content">
                <div class="cm-grid-tile-image">
                    @if (isUploading) {
                        <cm-thumbnail class="cm-grid-tile-image" [url]="Settings.UPLOADING_URL"></cm-thumbnail>
                    } @else {
                        <cm-data-object-thumbnail
                            class="cm-grid-tile-image"
                            [dataObjectId]="dataObject?.id"
                            [layout]="ThumbnailLayout.Cover"
                            (load)="load.emit($event)"
                        ></cm-data-object-thumbnail>
                    }
                </div>
                @if (dataObject) {
                    <span
                        [ngClass]="{
                            'cm-texture-details': true,
                            'cm-texture-details-warning': colorSpaceIsUnknown(dataObject)
                        }"
                        [matTooltip]="'DataObject ' + dataObject.legacyId"
                    >
                        @if (colorSpaceIsUnknown(dataObject)) {
                            <i class="far fa-exclamation-triangle"></i>
                        }
                        <!--                    <div-->
                        <!--                        #clipboardTooltip="matTooltip"-->
                        <!--                        matTooltip="Copy to clipboard"-->
                        <!--                        (mouseenter)="clipboardTooltip.message = 'Copy to clipboard'"-->
                        <!--                        (click)="-->
                        <!--                            utils.copyToClipboardWithTooltip(textureRevisionData.legacyId, clipboardTooltip); $event.stopPropagation(); $event.preventDefault()-->
                        <!--                        "-->
                        <!--                    >-->
                        <!--                        {{ textureRevisionData.legacyId }} (v{{ textureRevisionData.number }})-->
                        <!--                        <br />-->
                        <!--                        {{ textureRevisionData.width | number: "1.2-2" }}-->
                        <!--                        cm × {{ textureRevisionData.height | number: "1.2-2" }} cm-->
                        <!--                    </div>-->
                    </span>
                    <a
                        class="cm-texture-download"
                        [matMenuTriggerFor]="textureDownloadMenu"
                        download
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                    >
                        <i class="far fa-arrow-to-bottom"></i>
                    </a>
                    <mat-menu class="cm-texture-download-menu" #textureDownloadMenu="matMenu">
                        <button mat-menu-item class="cm-original-download" (click)="downloadDataObject(dataObject)">
                            {{ describeDataObject(dataObject) }}
                        </button>
                        @if ($can().update.dataObject(dataObject)) {
                            <button mat-menu-item class="cm-set-colorspace" (click)="setOriginalColorspace(dataObject, imageColorSpace.Srgb)">
                                Set original colorspace: {{ imageColorSpace.Srgb.toString() }}
                            </button>
                            <button mat-menu-item class="cm-set-colorspace" (click)="setOriginalColorspace(dataObject, imageColorSpace.Linear)">
                                Set original colorspace: {{ imageColorSpace.Linear.toString() }}
                            </button>
                            <button mat-menu-item class="cm-set-colorspace" (click)="setOriginalColorspace(dataObject, imageColorSpace.Unknown)">
                                Set original colorspace: {{ imageColorSpace.Unknown.toString() }}
                            </button>
                        }
                        @for (related of dataObject.related; track related) {
                            <button mat-menu-item (click)="downloadDataObject(related)">
                                {{ describeDataObject(related) }}
                            </button>
                        }
                    </mat-menu>
                }
            </div>
        </cm-drop-files>
        <div class="cm-grid-tile-title">
            {{ label }}
        </div>
    </div>
</div>
