import {Settings} from "@app/common/models/settings/settings"

export function isOnboardingRequired(): boolean {
    try {
        const onboardingCompleted: string | null = localStorage.getItem(Settings.getOnboardingKey())
        let times = 0
        if (onboardingCompleted) {
            times = parseInt(onboardingCompleted, 10)
            if (times >= Settings.ONBOARDING_COUNT) return false
        }
        return true
    } catch (error) {
        console.error("Failed to get onboarding status, defaulting to 'not required': ", error)
        return false
    }
}

export function increaseOnboardingCounter(): void {
    try {
        const onboardingCompleted: string | null = localStorage.getItem(Settings.getOnboardingKey())
        let times = 0
        if (onboardingCompleted) {
            times = parseInt(onboardingCompleted, 10)
            if (times < Settings.ONBOARDING_COUNT) {
                localStorage.setItem(Settings.getOnboardingKey(), (++times).toString(10))
            }
        } else {
            localStorage.setItem(Settings.getOnboardingKey(), "1")
        }
    } catch (error) {
        console.error("Failed to increase onboarding counter: ", error)
    }
}
