import {ADDRESS_MODE_BORDER, ADDRESS_MODE_CLAMP_TO_EDGE, ADDRESS_MODE_MIRRORED_REPEAT, ADDRESS_MODE_REPEAT} from "@common/helpers/webgl2/constants"
import {TextureAddressMode} from "@common/helpers/webgl2/types"

export function resolveTextureAddressMode(textureAddressMode: TextureAddressMode): number {
    switch (textureAddressMode) {
        case "CLAMP_TO_EDGE":
            return ADDRESS_MODE_CLAMP_TO_EDGE
        case "REPEAT":
            return ADDRESS_MODE_REPEAT
        case "MIRRORED_REPEAT":
            return ADDRESS_MODE_MIRRORED_REPEAT
        case "BORDER":
            return ADDRESS_MODE_BORDER
    }
}

export function getUniformLocation(gl: WebGL2RenderingContext, program: WebGLProgram, name: string): WebGLUniformLocation {
    const loc = gl.getUniformLocation(program, name)
    if (!loc) {
        throw new Error(`Uniform ${name} not found`)
    }
    return loc
}

export function createBuffer(gl: WebGL2RenderingContext, data: ArrayBufferView): WebGLBuffer {
    const buffer = gl.createBuffer()
    if (!buffer) {
        throw new Error("Failed to create buffer")
    }
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
    gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW)
    return buffer
}

export function createDynamicBuffer(gl: WebGL2RenderingContext, target: number, sizeInBytes: number): WebGLBuffer {
    const buffer = gl.createBuffer()
    if (!buffer) {
        throw new Error("Failed to create buffer")
    }
    gl.bindBuffer(target, buffer)
    gl.bufferData(target, sizeInBytes, gl.DYNAMIC_DRAW)
    return buffer
}

export function compileAndLinkProgram(gl: WebGL2RenderingContext, vertexSrc: string, fragmentSrc: string): WebGLProgram {
    const vertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexSrc)
    const fragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentSrc)
    const program = gl.createProgram()
    if (!program) {
        throw Error("Could not create WebGL program")
    }
    gl.attachShader(program, vertexShader)
    gl.attachShader(program, fragmentShader)
    gl.linkProgram(program)
    gl.deleteShader(vertexShader)
    gl.deleteShader(fragmentShader)
    const status = gl.getProgramParameter(program, gl.LINK_STATUS)
    if (!status) {
        const errStr = gl.getProgramInfoLog(program)
        throw Error("Shader link error: " + errStr)
    }
    return program
}

export function compileShader(gl: WebGL2RenderingContext, shaderType: number, shaderSrc: string): WebGLShader {
    const shader = gl.createShader(shaderType)
    if (!shader) {
        throw Error("Could not create WebGL shader")
    }
    gl.shaderSource(shader, shaderSrc)
    gl.compileShader(shader)
    const status = gl.getShaderParameter(shader, gl.COMPILE_STATUS)
    if (!status) {
        const errStr = gl.getShaderInfoLog(shader)
        gl.deleteShader(shader)
        throw Error("Shader compile error: " + errStr)
    }
    return shader
}
