import {Component} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {Labels} from "@labels"
import {HdrisGridComponent} from "@platform/components/hdris/hdris-grid/hdris-grid.component"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"
import {UsersGridComponent} from "@platform/components/users/users-grid/users-grid.component"
import {BasePageComponent} from "@pages/base/base-page.component"

@Component({
    selector: "cm-users-page",
    standalone: true,
    imports: [
        CheckboxesFilterComponent,
        HdrisGridComponent,
        ItemFiltersComponent,
        RouterOutlet,
        SidebarLayoutComponent,
        TagSearchFilterComponent,
        UsersGridComponent,
    ],
    templateUrl: "./users-page.component.html",
    styleUrl: "./users-page.component.scss",
})
export class UsersPageComponent extends BasePageComponent {
    readonly Labels = Labels
}
