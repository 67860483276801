import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Matrix4} from "@src/math"

const TD = TypeDescriptors

export class TransformState {
    matrix: Matrix4 | undefined = undefined
    controlledBySolver = false
    solverNeedsUpdate = true
    isFirstUpdate = true
    isInteracting = true
}

export interface TransformAccessor {
    getTransform(): Matrix4 | undefined
    setTransform(matrix: Matrix4, interactive?: boolean): void
    endTransform(): void
    readonly controlledBySolver: boolean
    checkSolverNeedsUpdate(): boolean
}

const transformDescriptor = {
    state: TD.inlet(TD.Identity<TransformState>()),
    parentMatrix: TD.inlet(TD.Matrix4),
    lockedTransform: TD.inlet(TD.JSON<number[] | undefined>()),
    defaultTransform: TD.inlet(TD.JSON<number[] | undefined>()),
    matrix: TD.outlet(TD.Matrix4),
    accessor: TD.outlet(TD.Identity<TransformAccessor>()),
}

export class Transform implements TransformAccessor, NodeClassImpl<typeof transformDescriptor, typeof Transform> {
    static descriptor = transformDescriptor
    static uniqueName = "Transform"
    state!: Inlet<TransformState>
    parentMatrix!: Inlet<Matrix4>
    lockedTransform: Inlet<number[] | undefined>
    defaultTransform: Inlet<number[] | undefined>
    matrix!: Outlet<Matrix4>
    accessor!: Outlet<TransformAccessor>

    run() {
        if (this.state === NotReady || this.parentMatrix === NotReady || this.lockedTransform === NotReady || this.defaultTransform === NotReady) {
            this.accessor.emitIfChanged(NotReady)
            this.matrix.emitIfChanged(NotReady)
            return
        }
        if (this.lockedTransform && this.parentMatrix) {
            this.state.controlledBySolver = false
            this.state.solverNeedsUpdate = true
            this.state.matrix = this.parentMatrix.multiply(Matrix4.fromArray(this.lockedTransform))
        } else {
            this.state.controlledBySolver = true
            if (this.state.isFirstUpdate) {
                if (this.defaultTransform) {
                    this.state.matrix = Matrix4.fromArray(this.defaultTransform)
                } else {
                    this.state.matrix = Matrix4.fromArray([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])
                }

                this.state.isFirstUpdate = false
                this.state.solverNeedsUpdate = true
            }
        }
        this.accessor.emitIfChanged(this)
        if (this.state.matrix) this.matrix.emitIfChanged(this.state.matrix)
    }

    // TransformAccessor interface:

    getTransform() {
        if (this.state === NotReady) return undefined
        return this.state.matrix
    }

    setTransform(matrix: Matrix4, interactive?: boolean) {
        if (this.state === NotReady) return
        this.state.isInteracting = !!interactive
        if (interactive) {
            this.state.solverNeedsUpdate = true
        }
        if (!this.state.matrix || !this.state.matrix.equals(matrix)) {
            this.state.matrix = matrix
            this.matrix.emitIfChanged(matrix)
        }
    }

    endTransform() {
        if (this.state === NotReady) return
        this.state.isInteracting = false
        //TODO: end interaction in solver
    }

    get controlledBySolver(): boolean {
        if (this.state === NotReady) return false
        return this.state.controlledBySolver
    }

    checkSolverNeedsUpdate(): boolean {
        if (this.state === NotReady) return false
        if (this.state.solverNeedsUpdate) {
            this.state.solverNeedsUpdate = false
            return true
        } else {
            return false
        }
    }
}
