import {Quaternion, Vector3} from "@cm/lib/math"
import * as ndarray from "ndarray"
import {NdArray} from "ndarray"
import {SVD} from "svd-js" //TODO: move these into this module?
import {Matrix, Vector} from "@cm/lib/templates/interfaces/connection-solver"

export function argSort<T>(arr: Array<T>) {
    const indices = Array(arr.length)
    for (let i = 0; i < arr.length; i++) {
        indices[i] = i
    }
    indices.sort((indexA, indexB) => {
        if (arr[indexA] < arr[indexB]) {
            return -1
        } else if (arr[indexA] > arr[indexB]) {
            return 1
        }
        return 0
    })
    return indices
}

export function transpose<T>(arr: Array<Array<T>>) {
    return arr[0].map((col, i) => arr.map((row) => row[i]))
}

export function fitPlaneToPoints(points: number[]): {origin: number[]; axes: number[][]; scale: number[]} {
    let cx = 0,
        cy = 0,
        cz = 0
    for (let i = 0; i < points.length; i += 3) {
        cx += points[i + 0]
        cy += points[i + 1]
        cz += points[i + 2]
    }
    cx /= points.length / 3
    cy /= points.length / 3
    cz /= points.length / 3
    const arr: number[][] = []
    for (let i = 0; i < points.length; i += 3) {
        arr.push([points[i] - cx, points[i + 1] - cy, points[i + 2] - cz])
    }
    let {q, v} = SVD(arr)
    v = transpose(v)
    const sortOrd = argSort(q).reverse()
    v = sortOrd.map((i) => v[i])
    q = sortOrd.map((i) => q[i])

    return {origin: [cx, cy, cz], axes: v, scale: q}
}

export function fitAxisAlignedPlaneToPoints(points: number[], rotationAngle: number): {origin: number[]; axes: number[][]; scale: number[]} {
    const plane = fitPlaneToPoints(points)
    let [cx, cy, cz] = plane.origin
    const v = plane.axes
    const q = plane.scale

    // project the normal of surface down onto xy plane of object space
    // and use that to calculate a new basis vector that is laying on the xy plane for the surface
    const n = new Vector3(0.0, 1.0, 0.0) // up vector in object space
    const a3 = new Vector3(v[2][0], v[2][1], v[2][2]).normalized()
    const proj_a3 = a3.sub(n.mul(a3.dot(n))).normalized()
    let a1 = n.cross(proj_a3)
    // 0.92 == cos(22.5 degree)
    if (Math.abs(a3.dot(n)) > 0.92) {
        a1.x = Math.sign(a3.dot(n))
        a1.y = 0.0
        a1.z = 0.0
    }

    // rotate a1 around a3 by rotation angle
    // straight forward Rodrigues' axis angle rotation formula
    rotationAngle = (Math.PI * rotationAngle) / 180.0 // convert to radians
    a1 = a1
        .mul(Math.cos(rotationAngle))
        .add(a3.cross(a1).mul(Math.sin(rotationAngle)))
        .add(a3.mul(a3.dot(a1) * (1.0 - Math.cos(rotationAngle))))

    // recreate third basis vector
    const a2 = a3.cross(a1)

    v[0] = [a1.x, a1.y, a1.z]
    v[1] = [a2.x, a2.y, a2.z]

    // instead of using q as scale calculate a tight bounding box by projecting the points onto the new axes going out from the center point
    // also adjust the center point to balance out the bounding box and as such make the fit tighter
    const vecCenter = new Vector3(cx, cy, cz)
    for (let axis = 0; axis < 3; axis++) {
        const vec = new Vector3(v[axis][0], v[axis][1], v[axis][2]).normalized()
        q[axis] = 0
        let mind = 0
        let maxd = 0
        for (let i = 0; i < points.length; i += 3) {
            const dist = vec.dot(new Vector3(points[i], points[i + 1], points[i + 2]).sub(vecCenter))
            if (dist > maxd) maxd = dist
            if (dist < mind) mind = dist
        }
        const fac = (maxd + mind) / 2.0
        cx += v[axis][0] * fac
        cy += v[axis][1] * fac
        cz += v[axis][2] * fac
        q[axis] = Math.abs(maxd - mind)
    }

    return {origin: [cx, cy, cz], axes: v, scale: q}
}

export function zeros(...dims: number[]): NdArray {
    let totalSz = 1
    for (let i = 0; i < dims.length; i++) {
        totalSz *= dims[i]
    }
    return ndarray(new Float32Array(totalSz).fill(0), dims)
}

export function eye(sz: number): NdArray {
    const m = ndarray(new Float32Array(sz * sz).fill(0), [sz, sz])
    for (let i = 0; i < sz; i++) {
        m.set(i, i, 1)
    }
    return m
}

export function ones(...dims: number[]): NdArray {
    let totalSz = 1
    for (let i = 0; i < dims.length; i++) {
        totalSz *= dims[i]
    }
    return ndarray(new Float32Array(totalSz).fill(1), dims)
}

export function toVector(arr: ArrayLike<number>) {
    return ndarray(new Float32Array(arr), [arr.length])
}

export function toMatrix(arr: ArrayLike<number>, m: number, n: number) {
    return ndarray(new Float32Array(arr), [m, n])
}

export function slice1d(arr: Vector, lowAxis0: number, hiAxis0: number): Vector {
    return arr.hi(hiAxis0).lo(lowAxis0)
}

export function slice2d(arr: Matrix, lowAxis0: number, hiAxis0: number, lowAxis1: number, hiAxis1: number): Matrix {
    return arr.hi(hiAxis0, hiAxis1).lo(lowAxis0, lowAxis1)
}

export function matVec4Mul(M: Matrix, x: number, y: number, z: number, w: number, out: number[]): void {
    out[0] = M.get(0, 0) * x + M.get(0, 1) * y + M.get(0, 2) * z + M.get(0, 3) * w
    out[1] = M.get(1, 0) * x + M.get(1, 1) * y + M.get(1, 2) * z + M.get(1, 3) * w
    out[2] = M.get(2, 0) * x + M.get(2, 1) * y + M.get(2, 2) * z + M.get(2, 3) * w
    out[3] = M.get(3, 0) * x + M.get(3, 1) * y + M.get(3, 2) * z + M.get(3, 3) * w
}

export function matMul(A: Matrix, B: Matrix, out: Matrix) {
    const ni = A.shape[0]
    const nj = A.shape[1]
    const nk = B.shape[1]
    for (let i = 0; i < ni; i++) {
        for (let k = 0; k < nk; k++) {
            let tmp = 0
            for (let j = 0; j < nj; j++) {
                tmp += A.get(i, j) * B.get(j, k)
            }
            out.set(i, k, tmp)
        }
    }
}

export function matInverse4(M: Matrix, out: Matrix) {
    const e = M.data as number[]
    const re = out.data as number[]
    const n11 = e[0],
        n21 = e[1],
        n31 = e[2],
        n41 = e[3]
    const n12 = e[4],
        n22 = e[5],
        n32 = e[6],
        n42 = e[7]
    const n13 = e[8],
        n23 = e[9],
        n33 = e[10],
        n43 = e[11]
    const n14 = e[12],
        n24 = e[13],
        n34 = e[14],
        n44 = e[15]
    const t11 = n23 * n34 * n42 - n24 * n33 * n42 + n24 * n32 * n43 - n22 * n34 * n43 - n23 * n32 * n44 + n22 * n33 * n44
    const t12 = n14 * n33 * n42 - n13 * n34 * n42 - n14 * n32 * n43 + n12 * n34 * n43 + n13 * n32 * n44 - n12 * n33 * n44
    const t13 = n13 * n24 * n42 - n14 * n23 * n42 + n14 * n22 * n43 - n12 * n24 * n43 - n13 * n22 * n44 + n12 * n23 * n44
    const t14 = n14 * n23 * n32 - n13 * n24 * n32 - n14 * n22 * n33 + n12 * n24 * n33 + n13 * n22 * n34 - n12 * n23 * n34
    const det = n11 * t11 + n21 * t12 + n31 * t13 + n41 * t14
    if (det === 0) {
        console.warn("Could not compute matrix inverse!")
        return
    }
    const detInv = 1 / det
    re[0] = t11 * detInv
    re[1] = (n24 * n33 * n41 - n23 * n34 * n41 - n24 * n31 * n43 + n21 * n34 * n43 + n23 * n31 * n44 - n21 * n33 * n44) * detInv
    re[2] = (n22 * n34 * n41 - n24 * n32 * n41 + n24 * n31 * n42 - n21 * n34 * n42 - n22 * n31 * n44 + n21 * n32 * n44) * detInv
    re[3] = (n23 * n32 * n41 - n22 * n33 * n41 - n23 * n31 * n42 + n21 * n33 * n42 + n22 * n31 * n43 - n21 * n32 * n43) * detInv
    re[4] = t12 * detInv
    re[5] = (n13 * n34 * n41 - n14 * n33 * n41 + n14 * n31 * n43 - n11 * n34 * n43 - n13 * n31 * n44 + n11 * n33 * n44) * detInv
    re[6] = (n14 * n32 * n41 - n12 * n34 * n41 - n14 * n31 * n42 + n11 * n34 * n42 + n12 * n31 * n44 - n11 * n32 * n44) * detInv
    re[7] = (n12 * n33 * n41 - n13 * n32 * n41 + n13 * n31 * n42 - n11 * n33 * n42 - n12 * n31 * n43 + n11 * n32 * n43) * detInv
    re[8] = t13 * detInv
    re[9] = (n14 * n23 * n41 - n13 * n24 * n41 - n14 * n21 * n43 + n11 * n24 * n43 + n13 * n21 * n44 - n11 * n23 * n44) * detInv
    re[10] = (n12 * n24 * n41 - n14 * n22 * n41 + n14 * n21 * n42 - n11 * n24 * n42 - n12 * n21 * n44 + n11 * n22 * n44) * detInv
    re[11] = (n13 * n22 * n41 - n12 * n23 * n41 - n13 * n21 * n42 + n11 * n23 * n42 + n12 * n21 * n43 - n11 * n22 * n43) * detInv
    re[12] = t14 * detInv
    re[13] = (n13 * n24 * n31 - n14 * n23 * n31 + n14 * n21 * n33 - n11 * n24 * n33 - n13 * n21 * n34 + n11 * n23 * n34) * detInv
    re[14] = (n14 * n22 * n31 - n12 * n24 * n31 - n14 * n21 * n32 + n11 * n24 * n32 + n12 * n21 * n34 - n11 * n22 * n34) * detInv
    re[15] = (n12 * n23 * n31 - n13 * n22 * n31 + n13 * n21 * n32 - n11 * n23 * n32 - n12 * n21 * n33 + n11 * n22 * n33) * detInv
}

function xfnv1a(k: string) {
    let h = 0
    for (let i = 0, h = 2166136261 >>> 0; i < k.length; i++) h = Math.imul(h ^ k.charCodeAt(i), 16777619)
    return function () {
        h += h << 13
        h ^= h >>> 7
        h += h << 3
        h ^= h >>> 17
        return (h += h << 5) >>> 0
    }
}

export function createRNG(seedString: string) {
    // based on sfc32 PRNG
    const seedFn = xfnv1a(seedString)
    let a = seedFn()
    let b = seedFn()
    let c = seedFn()
    let d = seedFn()
    return () => {
        a >>>= 0
        b >>>= 0
        c >>>= 0
        d >>>= 0
        let t = (a + b) | 0
        a = b ^ (b >>> 9)
        b = (c + (c << 3)) | 0
        c = (c << 21) | (c >>> 11)
        d = (d + 1) | 0
        t = (t + d) | 0
        c = (c + t) | 0
        return (t >>> 0) / 4294967296
    }
}

// export function anglesToQuaternion(rx: number, ry: number, rz: number) {
//     let cos_a = Math.cos(rz);
//     let sin_a = Math.sin(rz);
//     let cos_b = Math.cos(ry);
//     let sin_b = Math.sin(ry);
//     let cos_c = Math.cos(rx);
//     let sin_c = Math.sin(rx);
//     let matrix: Matrix4 = new Matrix4;
//     matrix.elements[0] = cos_a*cos_b;
//     matrix.elements[1] = sin_a*cos_b;
//     matrix.elements[2] = -sin_b;
//     matrix.elements[4] = cos_a*sin_b*sin_c - sin_a*cos_c;
//     matrix.elements[5] = sin_a*sin_b*sin_c + cos_a*cos_c;
//     matrix.elements[6] = cos_b*sin_c;
//     matrix.elements[8] = cos_a*sin_b*cos_c + sin_a*sin_c;
//     matrix.elements[9] = sin_a*sin_b*cos_c - cos_a*sin_c;
//     matrix.elements[10] = cos_b*cos_c;
//     matrix.elements[15] = 1;
//     const {position, quaternion, scale} = matrix.decompose();
//     return quaternion;
// }

export function angleDegreesToQuaternion(rx: number, ry: number, rz: number) {
    rx *= Math.PI / 180
    ry *= Math.PI / 180
    rz *= Math.PI / 180
    const rotMag = Math.sqrt(rx * rx + ry * ry + rz * rz)
    if (rotMag > 0.0) {
        rx = rx / rotMag
        ry = ry / rotMag
        rz = rz / rotMag
        const qx = rx * Math.sin(rotMag / 2.0)
        const qy = ry * Math.sin(rotMag / 2.0)
        const qz = rz * Math.sin(rotMag / 2.0)
        const qw = Math.cos(rotMag / 2.0)
        return new Quaternion(qx, qy, qz, qw)
    } else {
        return new Quaternion(0, 0, 0, 1)
    }
}

export function quaternionToAngleDegrees(q: Quaternion) {
    let rx = 0,
        ry = 0,
        rz = 0
    if (q.w !== 1.0) {
        const angle = 2 * Math.acos(q.w)
        rx = (q.x / Math.sqrt(1 - q.w * q.w)) * angle * (180 / Math.PI)
        ry = (q.y / Math.sqrt(1 - q.w * q.w)) * angle * (180 / Math.PI)
        rz = (q.z / Math.sqrt(1 - q.w * q.w)) * angle * (180 / Math.PI)
    }
    return [rx, ry, rz] as const
}
