import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {TypeDescriptors} from "../type-descriptors"

const TD = TypeDescriptors

const gateDescriptor = {
    input: TD.inlet(TD.Identity<any>()),
    gate: TD.inlet(TD.Primitive<boolean>()),
    output: TD.outlet(TD.Identity<any>()),
}

export class Gate<T> implements NodeClassImpl<typeof gateDescriptor, typeof Gate> {
    static descriptor = gateDescriptor
    static uniqueName = "Gate"
    input!: Inlet<T>
    gate!: Inlet<boolean>
    output!: Outlet<T>

    run() {
        if (this.input === NotReady || this.gate === NotReady) {
            // this.output.emitIfChanged(NotReady);
            return
        }
        if (!this.gate) return
        this.output.emitIfChanged(this.input)
    }
}
