<h2 mat-dialog-title>Save new material revision</h2>
<mat-dialog-content>
    <form id="matRevisionForm" (submit)="confirmAction()">
        <mat-form-field>
            <input
                [disabled]="!$can().update.materialRevision(null, 'material')"
                [ngModel]="materialInfo?.legacyId"
                (ngModelChange)="materialChanged(undefined, $event)"
                matInput
                type="number"
                placeholder="Material ID"
                name="materialId"
            />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <input [ngModel]="materialInfo?.name" matInput type="string" placeholder="Material name" name="materialName" [disabled]="true" />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <input [ngModel]="materialInfo?.range" matInput type="string" placeholder="Range" name="rangeName" [disabled]="true" />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <input [ngModel]="materialInfo?.customer" matInput type="string" placeholder="Customer" name="customerName" [disabled]="true" />
        </mat-form-field>
        <mat-form-field appearance="fill">
            <textarea [(ngModel)]="result.comment" matInput [cdkTextareaAutosize]="true" cdkAutosizeMinRows="2" placeholder="Comment" name="revisionComment">
            </textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancelAction()">Cancel</button>
    <button mat-button form="matRevisionForm" [disabled]="false">Save</button>
</mat-dialog-actions>
