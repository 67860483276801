import {Vector2Like} from "ts-lib/dist/browser/math/vector2"
import {ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {resize} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-resize"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"
import {convolve} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/primitive/image-op-convolve"

const SCOPE_NAME = "Blur"

export type ParameterType = {
    sourceImage: ImageRef
    blurKernelSize: Vector2Like
    borderMode: "wrap" | "clamp" | "wrap-mirrored" | "zero"
}

export type ReturnType = ImageRef

export function blur(cmdQueue: ImageOpCommandQueue, {sourceImage, blurKernelSize, borderMode}: ParameterType): ReturnType {
    cmdQueue.beginScope(SCOPE_NAME)
    const downSampledSourceImage = resize(cmdQueue, {
        sourceImage,
        resultSize: {
            width: blurKernelSize.x > 0 ? Math.ceil(sourceImage.descriptor.width * Math.min(1, MAX_KERNEL_SIZE / blurKernelSize.x)) : 1,
            height: blurKernelSize.y > 0 ? Math.ceil(sourceImage.descriptor.height * Math.min(1, MAX_KERNEL_SIZE / blurKernelSize.y)) : 1,
        },
    })
    const downSampledBlurredImageH = convolve(cmdQueue, {
        sourceImage: downSampledSourceImage,
        kernel: (() => {
            const smoothingDistanceH = blurKernelSize.x ? blurKernelSize.x : 1
            const kernelSizeH = Math.min(MAX_KERNEL_SIZE, smoothingDistanceH)
            const kernelH = computeKernel(kernelSizeH)
            return {
                width: kernelH.length,
                height: 1,
                values: kernelH,
            }
        })(),
        borderMode,
    })
    const downSampledBlurredImage = convolve(cmdQueue, {
        sourceImage: downSampledBlurredImageH,
        kernel: (() => {
            const smoothingDistanceV = blurKernelSize.y ? blurKernelSize.y : 1
            const kernelSizeV = Math.min(MAX_KERNEL_SIZE, smoothingDistanceV)
            const kernelV = computeKernel(kernelSizeV)
            return {
                width: 1,
                height: kernelV.length,
                values: kernelV,
            }
        })(),
        borderMode,
    })
    const result = resize(cmdQueue, {
        sourceImage: downSampledBlurredImage,
        resultSize: sourceImage.descriptor,
    })
    cmdQueue.endScope(SCOPE_NAME)
    return result
}

function computeKernel(spread: number): number[] {
    // compute Gaussian kernel
    const halfKernelSize = Math.ceil(spread)
    const sigma = spread / 3
    const kernel = []
    let sum = 0
    for (let i = -halfKernelSize; i <= halfKernelSize; i++) {
        const v = Math.exp((-i * i) / (2 * sigma * sigma))
        kernel.push(v)
        sum += v
    }
    // make area under curve equal to 1
    for (let i = 0; i < kernel.length; i++) {
        kernel[i] /= sum
    }
    return kernel
}

const MAX_KERNEL_SIZE = 32
