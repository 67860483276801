import {Component, computed, input, output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatTooltipModule} from "@angular/material/tooltip"
import {PricingNode} from "@cm/lib/pricing/declare-pricing-node"
import {ConditionalAmountNode} from "@cm/lib/pricing/nodes/conditional-amount-node"
import {VariantConditionNodeComponent} from "@pricing/components/node-visualizers/variant-condition-node/variant-condition-node.component"

@Component({
    standalone: true,
    selector: "cm-amount",
    templateUrl: "./conditional-amount-node.component.html",
    styleUrls: ["./conditional-amount-node.component.scss", "../node-styles.scss"],
    imports: [VariantConditionNodeComponent, FormsModule, MatTooltipModule],
})
export class ConditionalAmountNodeComponent {
    $node = input.required<PricingNode>()
    $conditionalAmountNode = computed<ConditionalAmountNode | undefined>(() => {
        const node = this.$node()
        if (node instanceof ConditionalAmountNode) return node
        else return undefined
    })

    $description = computed<string>(() => {
        const node = this.$conditionalAmountNode()
        if (node === undefined) return ""
        return node.parameters.description.length > 0 ? node.parameters.description : "Amount group node"
    })

    $conditionRemoved = output<void>()
}
