import {ImageOpType, runImageOp} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op"
import {ImageDescriptorWithOptionals, ImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-ref"
import {DrawableImageRef} from "@app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {ImageOpCommandQueue} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/image-op-command-queue"

export type ParameterType = {
    drawableImageRef: DrawableImageRef
    descriptor: ImageDescriptorWithOptionals
}

export type ReturnType = ImageRef

export const imageOpDrawableImage: ImageOpType<ParameterType, ReturnType> = {
    name: "DrawableImage",

    WebGL2: ({cmdQueue, parameters: {drawableImageRef: ref, descriptor}}) => {
        return cmdQueue.createDrawableImage(ref, descriptor)
    },

    ImgProc: ({cmdQueue, parameters: {drawableImageRef: ref, descriptor}}) => {
        return cmdQueue.createDrawableImage(ref, descriptor)
    },
}

export function drawableImage(cmdQueue: ImageOpCommandQueue, parameters: ParameterType) {
    return runImageOp(cmdQueue, imageOpDrawableImage, parameters)
}
