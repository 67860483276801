import {NodeGraph, NodeParameters} from "@src/graph-system/node-graph"

export function ensureValidContext<ReturnType, Context>(graph: NodeGraph<ReturnType, Context>, context: Context) {
    const schema = graph.getContextSchema()
    const result = schema.safeParse(context)
    if (!result.success) {
        console.error(`Invalid context for node ${graph.constructor.name}`, context, result.error.issues, schema)
        throw new Error(`Invalid context for node ${graph.constructor.name}`)
    } else return result.data as Context
}

export function ensureValidParameters<ReturnType, Context, ParamTypes extends NodeParameters>(
    graph: NodeGraph<ReturnType, Context, ParamTypes>,
    parameters: ParamTypes,
) {
    const schema = graph.getParamsSchema()
    const result = schema.safeParse(parameters)
    if (!result.success) {
        console.error(`Invalid parameters for node ${graph.constructor.name}`, parameters, result.error.issues, schema)
        throw new Error(`Invalid parameters for node ${graph.constructor.name}`)
    } else return result.data as ParamTypes
}

export function ensureValidResult<ReturnType, Context>(graph: NodeGraph<ReturnType, Context>, value: ReturnType) {
    const schema = graph.getReturnSchema()
    const result = schema.safeParse(value)
    if (!result.success) {
        console.error(`Invalid returned value for node ${graph.constructor.name}`, value, result.error.issues, schema)
        throw new Error(`Invalid returned value for node ${graph.constructor.name}`)
    } else return result.data as ReturnType
}
