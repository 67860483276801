import {Injectable, Injector, inject} from "@angular/core"
import {TemplateThumbnailRenderingFragment} from "@api"
import {templateThumbnailJobGraph} from "@app/common/helpers/rendering/template/template-thumbnail-job-graph"
import {MaterialGraphService} from "@app/common/services/material-graph/material-graph.service"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {RenderingService} from "@app/common/services/rendering/rendering.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {UploadGqlService} from "@app/common/services/upload/upload.gql.service"
import {WebAssemblyWorkerService} from "@app/editor/services/webassembly-worker.service"
import {loadGraphForNewTemplateSystem} from "@app/templates/helpers/editor-type"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {Parameters} from "@cm/lib/templates/nodes/parameters"
import {graphToJson} from "@cm/lib/utils/graph-json"

const STUDIO_SCENE_TEMPLATE_ID = "b4670fe9-0d78-4762-8d96-94dae867dfa8"

@Injectable({
    providedIn: "root",
})
export class TemplateThumbnailRenderingService {
    webAssemblyWorkerService = inject(WebAssemblyWorkerService)
    sdkService = inject(SdkService)
    refreshService = inject(RefreshService)
    materialGraphService = inject(MaterialGraphService)
    uploadGqlService = inject(UploadGqlService)
    renderingService = inject(RenderingService)

    sceneManagerService: SceneManagerService
    #injector = inject(Injector)

    constructor() {
        const injector = Injector.create({providers: [SceneManagerService], parent: this.#injector})
        this.sceneManagerService = injector.get(SceneManagerService)
    }

    // Questions: error handling, reflect job progess in UI?
    async renderTemplateThumbnail(template: TemplateThumbnailRenderingFragment): Promise<void> {
        if (template.id === STUDIO_SCENE_TEMPLATE_ID) {
            throw new Error("Cannot render thumbnail for studio scene template itself")
        }

        // prepare studio scene template
        const {template: studioSceneTemplate} = await this.sdkService.gql.getTemplateForThumbnailRendering({templateId: STUDIO_SCENE_TEMPLATE_ID})
        const studioSceneTemplateGraph = this.getTemplateGraph(studioSceneTemplate)
        if (!studioSceneTemplateGraph) {
            throw new Error("Studio scene template is missing")
        }

        // prepare template graph
        const templateGraph = this.getTemplateGraph(template)
        if (!templateGraph) {
            throw new Error("Template graph is missing")
        }

        // prepare scene manager
        this.sceneManagerService.$templateGraph.set(studioSceneTemplateGraph)
        this.sceneManagerService.$defaultCustomerId.set(studioSceneTemplate.organization.legacyId)
        this.sceneManagerService.$instanceParameters.set(
            new Parameters({
                "studio-scene-template-input": templateGraph,
            }),
        )

        this.sceneManagerService.compileTemplate()
        await this.sceneManagerService.sync(true)

        const sceneNodes = this.sceneManagerService.$scene()
        await this.submitRenderJob(template, sceneNodes)
    }

    private getTemplateGraph(studioSceneTemplate: TemplateThumbnailRenderingFragment) {
        const templateRevision = studioSceneTemplate.latestRevision

        if (!templateRevision) return null

        const {graph} = templateRevision
        if (!graph) return null

        return loadGraphForNewTemplateSystem(graph)
    }

    private async submitRenderJob(template: TemplateThumbnailRenderingFragment, sceneNodes: SceneNodes.SceneNode[]) {
        const jobName = `Thumbnail generation for template ${template.id}`

        const {mainRenderTask, shadowMaskRenderTask, combinedRenderTask} = await this.renderingService.createRenderTask({
            nodes: sceneNodes,
            final: true,
            name: jobName,
            organizationLegacyId: template.organization.legacyId,
        })

        // post processing settings are ignored during rendering, we'll need to add them to the job graph
        const postProcessingSettings = sceneNodes.find(SceneNodes.RenderPostProcessingSettings.is)

        const jobGraph = templateThumbnailJobGraph({
            template,
            combinedRenderData: combinedRenderTask,
            mainRenderTask,
            shadowMaskRenderTask,
            postProcessingSettings,
        })

        return this.sdkService.gql
            .renderingServiceCreateJob({
                input: {
                    name: jobName,
                    organizationLegacyId: template.organization.legacyId,
                    graph: graphToJson(jobGraph),
                },
            })
            .then(({createJob}) => createJob)
    }
}
