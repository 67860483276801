import {MaterialSocket} from "@material-editor/models/material-socket"

export const ImageTextureSetOutputs: Record<string, MaterialSocket> = {
    baseColor: {paramType: "socket", id: "BaseColor", type: "output", valueType: "output", label: "Base Color"},
    metallic: {paramType: "socket", id: "Metallic", type: "output", valueType: "output", label: "Metallic"},
    specular: {paramType: "socket", id: "Specular", type: "output", valueType: "output", label: "Specular"},
    roughness: {paramType: "socket", id: "Roughness", type: "output", valueType: "output", label: "Roughness"},
    anisotropic: {paramType: "socket", id: "Anisotropic", type: "output", valueType: "output", label: "Anisotropic"},
    anisotropicRotation: {paramType: "socket", id: "AnisotropicRotation", type: "output", valueType: "output", label: "Anisotropic Rotation"},
    alpha: {paramType: "socket", id: "Alpha", type: "output", valueType: "output", label: "Alpha"},
    normal: {paramType: "socket", id: "Normal", type: "output", valueType: "output", label: "Normal"},
    displacement: {paramType: "socket", id: "Displacement", type: "output", valueType: "output", label: "Displacement"},
    transmission: {paramType: "socket", id: "Transmission", type: "output", valueType: "output", label: "Transmission"},
}

export const ImageTextureSetInputs: Record<string, MaterialSocket> = {
    uv: {paramType: "socket", id: "UV", type: "input", valueType: "input", label: "UV"},
    normalStrength: {paramType: "socket", id: "NormalStrength", type: "input", valueType: "scalar", label: "Normal Strength", defaultValue: 1},
}
