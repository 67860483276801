import {DestroyRef, Injectable, computed, inject, signal} from "@angular/core"
import {DeclareListNode} from "@cm/lib/graph-system/utils"
import {isNodeOwner, isSwitch, isTemplateOwningContainer, isTemplateReferenceContainer} from "@cm/lib/templates/node-types"
import {TemplateNode, context, isTemplateNode, templateNode} from "@cm/lib/templates/types"
import {v4 as uuid4} from "uuid"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {Parameters} from "@cm/lib/templates/nodes/parameters"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {SceneManagerService} from "@template-editor/services/scene-manager.service"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {isNamedNode} from "@cm/lib/templates/nodes/named-node"
import {isIdNode} from "@cm/lib/templates/nodes/id-node"
import {MaterialAssignment, MaterialAssignments} from "@cm/lib/templates/nodes/material-assignment"
import {TemplateInput} from "@cm/lib/templates/nodes/input"
import {TemplateOutput} from "@cm/lib/templates/nodes/output"
import {TemplateSwitch} from "@cm/lib/templates/nodes/switch"
import {TemplateExport} from "@cm/lib/templates/nodes/export"
import {removeUndefinedEntriesFromObject} from "@cm/lib/utils/utils"

class GatherTemplateNode extends DeclareListNode(
    {
        context,
        item: templateNode,
    },
    {},
) {}

@Injectable()
export class TemplateNodeClipboardService {
    private _nodes = signal<TemplateNode[]>([])
    nodes = this._nodes.asReadonly()
    valid = computed(() => this._nodes().length > 0)
    private sceneManagerService = inject(SceneManagerService)
    private destroyRef = inject(DestroyRef)

    constructor() {
        this.sceneManagerService.templateSwapped$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.clear()
        })
    }

    clear(): void {
        this._nodes.set([])
    }

    copy(nodes: TemplateNode | TemplateNode[]) {
        if (Array.isArray(nodes)) this._nodes.set([...nodes])
        else this._nodes.set([nodes])
    }

    pasteReferences() {
        return [...this._nodes()]
    }

    pasteDuplicates() {
        const gatherTemplateNode = new GatherTemplateNode({list: this.pasteReferences()})
        const gatherTemplateNodeCopy = gatherTemplateNode.clone({
            cloneSubNode: (parent, subNode, path) => {
                const cloneSubNode = (() => {
                    if (parent instanceof GatherTemplateNode) return true
                    if (isTemplateNode(parent) && isTemplateNode(subNode)) {
                        //Clone all node owning nodes
                        if (isNodeOwner(subNode)) {
                            //Unless not used as a direct template graph reference
                            if (subNode instanceof TemplateGraph) {
                                if (
                                    parent instanceof TemplateExport ||
                                    parent instanceof TemplateInput ||
                                    parent instanceof TemplateOutput ||
                                    parent instanceof TemplateSwitch ||
                                    parent instanceof TemplateInstance
                                ) {
                                    return false
                                }
                            }
                            return true
                        }
                        //Clone node container nodes
                        if (isNodeOwner(parent) && isTemplateOwningContainer(subNode)) return true
                        //Also clone referenced nodes of the containers
                        if (isTemplateOwningContainer(parent)) return true

                        //Clone material assignments (in meshes)
                        if (subNode instanceof MaterialAssignments) return true
                        //Clone all material assignment items within material assignments, but do not clone the referenced nodes of the containers
                        if (parent instanceof MaterialAssignments && subNode instanceof MaterialAssignment) return true

                        //Clone switch nodes
                        if (isSwitch(subNode)) return true
                        //Clone switch container nodes, but do not clone the referenced nodes
                        if (isSwitch(parent) && isTemplateReferenceContainer(subNode)) return true

                        //Clone parameters (in template instance)
                        if (subNode instanceof Parameters) return true
                    }

                    return false
                })()

                return cloneSubNode
            },
            parameterOverrides: (node) => {
                if (isTemplateNode(node))
                    return removeUndefinedEntriesFromObject({
                        name: isNamedNode(node) ? `${node.parameters.name} copy` : undefined,
                        id: isIdNode(node) ? uuid4() : undefined,
                    })
                else return undefined
            },
        })

        gatherTemplateNode.clear()

        const returnNodes = [...gatherTemplateNodeCopy.parameters.list]

        gatherTemplateNodeCopy.clear()

        return returnNodes
    }
}
