<div
    (mousedown)="onboardingHintVisible ? hideOnboardingIcons() : null"
    (touchstart)="onboardingHintVisible ? hideOnboardingIcons() : null"
    class="cm-viewer-container"
>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <link href="https://storage.googleapis.com/cm-platform-prod-static/fonts/fontawesome-pro-6.0.0-alpha3/css/all.css" rel="stylesheet" />
    @if (loading || deferredArViewAction || isCreatingStl) {
        <cm-loading-spinner [showSpinner]="loading || arLoading || isCreatingStl">
            @if (arLoading) {
                <ng-container cm-text-bottom>
                    <span class="cm-ar-loading-text"> Generating AR model... </span>
                </ng-container>
            }
            @if (deferredArViewAction) {
                <ng-container cm-text-bottom>
                    <span class="cm-ar-loading-text"> AR model is ready to view! </span>
                    <button mat-raised-button color="primary" (click)="deferredArViewAction()"><i class="far fa-cube"></i> Click here</button>
                </ng-container>
            }
            @if (isCreatingStl) {
                <ng-container cm-text-bottom>
                    <span class="cm-ar-loading-text"> Generating STL files... </span>
                </ng-container>
            }
        </cm-loading-spinner>
    }
    <div [style.display]="onboardingHintVisible && !loading ? 'grid' : 'none'" class="cm-starting-icons-container">
        <div class="cm-hand">
            <i class="fas fa-arrow-left cm-arrows"></i>
            <i class="far fa-hand-back-point-up cm-hand-icon"></i>
            <i class="fas fa-arrow-right cm-arrows"></i>
        </div>
        <div class="cm-title">Drag to Rotate</div>
    </div>
    <div class="cm-scene-view-container">
        <cm-interactive-scene-view
            #sceneView
            [viewManager]="viewManager"
            [defaultCameraPosition]="defaultCameraPosition"
            [defaultCameraTarget]="defaultCameraTarget"
            (selectionEvent)="onSceneViewSelectionEvent($event)"
            (sizeChanged)="viewSizeChanged($event[0], $event[1])"
        >
        </cm-interactive-scene-view>
    </div>
    @if (showUi) {
        <div class="cm-ui-container">
            @if (sceneProperties?.uiStyle !== "hidden" && _showMenu) {
                <cm-config-menu-legacy
                    [useCaptions]="useCaptionsInMenu"
                    exportparts="config-group-title, cm-icon-container, cm-configurator-menu, cm-icon-with-caption, cm-config-menu-icons"
                >
                </cm-config-menu-legacy>
            }
            @if (showActionMenu) {
                <cm-action-menu-legacy
                    [style.display]="!loading ? 'grid' : 'none'"
                    [customerId]="customerId"
                    [desktopArUrl]="desktopArUrl"
                    [pdfGenerationEnabled]="pdfGenerationEnabled"
                    [gltfDownloadEnabled]="gltfDownloadEnabled"
                    [stlDownloadEnabled]="stlDownloadEnabled"
                    [salesEnquiryEnabled]="salesEnquiryEnabled"
                    [snapshotEnabled]="snapshotEnabled"
                    [fullscreenEnabled]="fullscreenEnabled"
                    [arEnabled]="arEnabled"
                    [qrCodeDialogOpen]="qrCodeDialogOpen"
                    [getSceneManager]="getSceneManager.bind(this)"
                    (saveSnapshot)="saveSnapshot()"
                    (downloadGltf)="downloadGltf()"
                    (viewInAr)="viewInAr()"
                    (downloadPdf)="downloadPdf()"
                    (toggleFullscreen)="toggleFullscreen()"
                    (closeArDialog)="closeArDialog()"
                    (creatingStl)="creatingStl($event)"
                >
                    <ng-container cm-right-menu>
                        <ng-content select="[cm-right-menu]"></ng-content>
                    </ng-container>
                </cm-action-menu-legacy>
            }
        </div>
    }
    <ng-content select="[cm-extra-content]"></ng-content>
</div>
