import {DecimalPipe, NgStyle} from "@angular/common"
import {Component} from "@angular/core"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {SetImageTextureInputs, SetImageTextureOutputs} from "@app/material-editor/models/nodes/set-image-texture"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NumericInputComponent} from "@common/components/inputs/numeric-input/numeric-input.component"
import {TextureType} from "@api"
import {MaterialNodeType} from "@material-editor/models/material-node-type"
import {NodeBaseComponent} from "@node-editor/components/node-base/node-base.component"
import {ThumbnailComponent} from "@common/components/thumbnail/thumbnail.component"
import {z} from "zod"
import {BaseImageTextureSetComponent} from "@app/material-editor/components/nodes/base-image-texture-set/base-image-texture-set.component"
import {DataObjectThumbnailComponent} from "@common/components/data-object-thumbnail/data-object-thumbnail.component"
import {SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY} from "@cm/lib/materials/types"

@Component({
    selector: "cm-set-image-texture-node",
    templateUrl: "./set-image-texture-node.component.html",
    styleUrls: ["./set-image-texture-node.component.scss"],
    standalone: true,
    imports: [
        NumericInputComponent,
        InputContainerComponent,
        MatMenuModule,
        DecimalPipe,
        NodeBaseComponent,
        MatFormFieldModule,
        MatSelectModule,
        ThumbnailComponent,
        NgStyle,
        DataObjectThumbnailComponent,
    ],
})
export class SetImageTextureNodeComponent extends BaseImageTextureSetComponent<typeof SetImageTextureNodeComponent> {
    outputs = SetImageTextureOutputs
    inputs = SetImageTextureInputs
    typeInfo = SetImageTextureNodeType

    textureTypeOptions?: TextureType[]
    textureType?: TextureType

    async changeTextureSetRevision(revisionId: string | undefined) {
        await this.loadTextureSetRevision(revisionId)
        this.textureTypeOptions = this.textureSetRevision?.mapAssignments.map((mapAssignment) => mapAssignment.textureType) ?? []
        this.changeTextureType(undefined, false)
        this.setupTextureInfo()
        this.setTextureSetRevisionVersion(revisionId)
        this.updateNodeParameters(true)
    }

    async changeTextureSet(textureSetLegacyId: number) {
        await this.loadTextureSet(textureSetLegacyId)
        await this.changeTextureSetRevision(this.textureSetRevisionInfos?.[0].id)
    }

    changeTextureType(textureType?: TextureType, updateNode: boolean = true) {
        this.textureType = textureType ?? this.textureTypeOptions?.[0]
        this.setThumbnail()
        if (updateNode) this.updateNodeParameters(true)
    }

    async fromNodeParameters() {
        const textureSetRevisionId = this.nodeBase.getParameter("TextureSetRevisionId")
        if (!textureSetRevisionId) {
            this.resetParameters()
            this.updateNodeParameters(true)
            return
        }

        await this.loadTextureSetRevision(textureSetRevisionId)
        await this.loadTextureSet(this.textureSetRevision?.textureSet?.legacyId)
        this.setTextureSetRevisionVersion(textureSetRevisionId)

        this.textureTypeOptions = this.textureSetRevision?.mapAssignments.map((mapAssignment) => mapAssignment.textureType) ?? []
        const textureTypeString = this.nodeBase.getParameter(SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY)
        const parsedTextureType = z.nativeEnum(TextureType).safeParse(textureTypeString)
        if (!parsedTextureType.success)
            throw new Error(
                `${SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY} parameter on ShaderNodeSetTexture not a TextureType enum: ${textureTypeString}`,
            )
        const textureType = parsedTextureType.data
        if (!textureType) throw new Error(`Failed to parse texture type from ${SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY}`)
        this.textureType = textureType

        this.setThumbnail()
    }

    updateNodeParameters(applyDefaults: boolean) {
        this.nodeBase.setParameterNoUpdate("TextureSetRevisionId", this.textureSetRevision?.id, "string")
        this.nodeBase.node.textureSetRevision = this.textureSetRevision
            ? {
                  id: this.textureSetRevision.id,
                  width: this.textureSetRevision.width,
                  height: this.textureSetRevision.height,
                  displacement: this.textureSetRevision.displacement ?? undefined,
                  mapAssignments: this.textureSetRevision.mapAssignments.map((assignment) => ({
                      textureType: assignment.textureType,
                      dataObjectLegacyId: assignment.dataObject.legacyId,
                  })),
              }
            : undefined

        this.nodeBase.setParameterNoUpdate(SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY, this.textureType?.toString(), "string")
        this.triggerGraphUpdate()
    }

    resetNodeParameters() {
        this.nodeBase.setParameterNoUpdate("TextureSetRevisionId", undefined, "string")
        this.nodeBase.setParameterNoUpdate(SET_TEXTURE_MAP_ASSIGNMENT_TEXTURE_TYPE_PARAMETER_KEY, undefined, "string")
    }

    resetParameters() {
        this.textureSetRevision = undefined
        this.textureSetLegacyId = undefined
        this.textureSetRevisionInfos = undefined
        this.textureSetRevisionVersion = undefined
        this.thumbnailId = undefined
        this.textureType = undefined
        this.textureTypeOptions = undefined
    }

    setThumbnail() {
        this.thumbnailId = this.textureType
            ? this.textureSetRevision?.mapAssignments.filter((mapAssignment) => mapAssignment.textureType === this.textureType)?.[0].dataObject.id
            : undefined
    }
}

export const SetImageTextureNodeType: MaterialNodeType<typeof SetImageTextureNodeComponent> = {
    id: "setImageTexture",
    label: "Set Image Texture",
    color: "#9e6034",
    name: "ShaderNodeSetTexture",
    inputs: [SetImageTextureInputs.uv],
    outputs: [SetImageTextureOutputs.texture],
    component: SetImageTextureNodeComponent,
}
