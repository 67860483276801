import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, NotReady, Outlet} from "@src/templates/runtime-graph/slots"
import {TemplateImageDataNew, TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {dataObjectNewToImageRessource, isIDataObjectNew} from "@src/templates/interfaces/data-object"
import {ResolvedResource} from "@src/materials/material-node-graph"
import {transformColorOverlay} from "@src/materials/material-node-graph-transformations"

const TD = TypeDescriptors

const transformColorOverlayNewDescriptor = {
    material: TD.inlet(TD.MaterialGraph),
    size: TD.inlet(TD.Tuple<[number, number]>()),
    image: TD.inlet(TD.TemplateImageDataNew),
    useAlpha: TD.inlet(TD.Boolean),
    outputMaterial: TD.outlet(TD.MaterialGraph),
}

export class TransformColorOverlayNew implements NodeClassImpl<typeof transformColorOverlayNewDescriptor, typeof TransformColorOverlayNew> {
    static descriptor = transformColorOverlayNewDescriptor
    static uniqueName = "TransformColorOverlayNew"
    material!: Inlet<IMaterialGraph>
    size!: Inlet<[number, number]>
    image!: Inlet<TemplateImageDataNew>
    useAlpha!: Inlet<boolean>
    outputMaterial!: Outlet<IMaterialGraph>

    run() {
        const material = this.material
        const image = this.image
        const size = this.size
        const useAlpha = this.useAlpha
        if (material === NotReady || image === NotReady || size === NotReady || useAlpha === NotReady) {
            this.outputMaterial.emitIfChanged(NotReady)
            return
        }
        const resolvedResource: ResolvedResource = isIDataObjectNew(image.dataObject)
            ? dataObjectNewToImageRessource(image.dataObject)
            : {transientDataObject: image.dataObject}
        this.outputMaterial.emitIfChanged(image ? transformColorOverlay(material, resolvedResource, size, useAlpha) : material)
    }
}
