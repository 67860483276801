import {OperatorType} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"

type OperatorDescBase = {
    readonly label: string
    readonly icon: string
    readonly description: string
}

export type OperatorDesc = OperatorDescBase & {
    readonly type: OperatorType
}

export type TextureEditNodeDesc = OperatorDescBase & {
    readonly type: TextureEditNodes.OperatorType
}

export const byTextureEditNodeType: Record<TextureEditNodes.OperatorType, TextureEditNodeDesc> = {
    "operator-auto-tiling": {
        type: "operator-auto-tiling",
        label: "Tiling (Legacy)",
        icon: "far fa-location-dot",
        description: "Automatically tiles the texture.",
    },
    "operator-shift": {
        type: "operator-shift",
        label: "Shift",
        icon: "far fa-arrows",
        description: "Shifts the texture.",
    },
    "operator-rotate": {
        type: "operator-rotate",
        label: "Rotate",
        icon: "far fa-undo",
        description: "Rotates the texture.",
    },
    "operator-layer-and-mask": {
        type: "operator-layer-and-mask",
        label: "Layer and Mask",
        icon: "far fa-draw-polygon",
        description: "A maskable layered copy of the texture.",
    },
    "operator-highpass": {
        type: "operator-highpass",
        label: "Gradient Removal",
        icon: "far fa-wave-sine",
        description: "Removes unwanted gradients from the texture.",
    },
    "operator-clone-stamp": {
        type: "operator-clone-stamp",
        label: "Clone stamp",
        icon: "far fa-stamp",
        description: "Fill in a region by cloning some other region.",
    },
    "operator-tiling": {
        type: "operator-tiling",
        label: "Tiling",
        icon: "far fa-location-dot",
        description: "Make texture tilable.",
    },
}

export const byOperatorType: Record<OperatorType, OperatorDesc> = {
    "operator-initial": {
        type: "operator-initial",
        label: "Initial",
        icon: "far fa-asterisk",
        description: "Original image.",
    },
    ...byTextureEditNodeType,
}
