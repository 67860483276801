// @ts-strict-ignore
import {CdkTreeModule, NestedTreeControl} from "@angular/cdk/tree"
import {AsyncPipe, NgTemplateOutlet} from "@angular/common"
import {Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from "@angular/core"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {MatListModule} from "@angular/material/list"
import {MatMenuModule} from "@angular/material/menu"
import {MatSnackBar} from "@angular/material/snack-bar"
import {MatTooltipModule} from "@angular/material/tooltip"
import {MatTreeNestedDataSource} from "@angular/material/tree"
import {Params, Router} from "@angular/router"
import {Material} from "@app/legacy/api-model/material"
import {SelectTemplateComponent} from "@app/templates/select-template/select-template.component"
import {AddMenuSectionComponent} from "@app/templates/template-publisher/template-tree/add-menu-section/add-menu-section.component"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {insertAfter, removeFromArray} from "@cm/lib/utils/utils"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {RenameDialogComponent} from "@common/components/dialogs/rename-dialog/rename-dialog.component"
import {ListItemComponent} from "@common/components/item"
import {getSelectionModifier} from "@legacy/helpers/utils"
import {Matrix4, Vector3} from "@cm/lib/math"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Template, TemplateTypes} from "@legacy/api-model/template"
import {TemplateRevision} from "@legacy/api-model/template-revision"
import {FilesService} from "@common/services/files/files.service"
import {SelectMaterialComponent} from "@platform/components/materials/select-material/select-material.component"
import {NodeClipboardService} from "app/templates/template-publisher/node-clipboard.service"
import {SelectionService} from "app/templates/template-publisher/selection.service"
import type {TemplatePublisherComponent} from "app/templates/template-publisher/template-publisher.component"
import {DragAndDropService} from "app/templates/template-publisher/template-tree/drag-and-drop.service"
import {TemplateService} from "app/templates/template.service"
import {BehaviorSubject, from, map, Observable, Subject, switchMap, takeUntil} from "rxjs"
import {v4 as uuid4} from "uuid"
import TemplateGraph = Nodes.TemplateGraph
import {TriggeredDialogComponent} from "@common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {DialogSize} from "@common/models/dialogs"
import {MaterialFilterInput, TemplateFilterInput} from "@api"
import {DialogService} from "@common/services/dialog/dialog.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"

class NodeTreeItem {
    readonly trackByKey: string
    readonly localNodeIdMap: WeakMap<Nodes.Node, string>

    constructor(
        readonly parent: NodeTreeItem | null,
        readonly node: Nodes.Node,
    ) {
        if (!parent) {
            this.localNodeIdMap = new WeakMap()
        } else {
            this.localNodeIdMap = parent.localNodeIdMap
        }
        let id = this.localNodeIdMap.get(node)
        if (id == undefined) {
            id = uuid4()
            this.localNodeIdMap.set(node, id)
        }
        this.trackByKey = parent ? parent.trackByKey + "/" + id : id
    }

    get context(): Nodes.Context | null {
        return this.parent?.node as Nodes.Context
    }

    private getNodeChildren(): Nodes.Node[] | null {
        switch (this.node?.type) {
            case "templateGraph":
            case "configVariant":
            case "switch":
            case "group":
            case "configGroup":
                return this.node.nodes
            // case "mesh":
            // case "proceduralMesh":
            //     return this.node.surfaces;
            default:
                return null
        }
    }

    hasChildren(): boolean {
        return this.getNodeChildren() != null
    }

    private _children: NodeTreeItem[] | null | undefined

    getChildren(): NodeTreeItem[] | null {
        if (this._children !== undefined) return this._children
        const childNodes = this.getNodeChildren()
        if (!childNodes) return (this._children = null)
        const childItems: NodeTreeItem[] = []
        for (const childNode of childNodes) {
            childItems.push(new NodeTreeItem(this, childNode))
        }
        return (this._children = childItems)
    }

    invalidate() {
        this._children = undefined
    }

    findByKeys(keys: string[], list?: NodeTreeItem[]): NodeTreeItem[] {
        if (!list) list = []
        if (keys.includes(this.trackByKey)) {
            list.push(this)
        }
        return list
    }
}

@Component({
    selector: "cm-template-tree",
    templateUrl: "./template-tree.component.html",
    styleUrls: ["./template-tree.component.scss"],
    standalone: true,
    imports: [
        CdkTreeModule,
        MatListModule,
        ListItemComponent,
        MatMenuModule,
        MemoizePipe,
        MatTooltipModule,
        AsyncPipe,
        AddMenuSectionComponent,
        ToggleComponent,
        SelectTemplateComponent,
        SelectMaterialComponent,
        NgTemplateOutlet,
        TriggeredDialogComponent,
    ],
})
export class TemplateTreeComponent implements OnInit {
    @ViewChild("selectMaterialDialog") selectMaterialDialog: SelectMaterialComponent
    @ViewChild("selectTemplateDialog") selectTemplateDialog: SelectTemplateComponent
    @Input() entity: Template | {customer: number; id: number}
    @Input() editor: TemplatePublisherComponent
    @Output() activateConfigVariant = new EventEmitter<{config: Nodes.ConfigGroup; variant: Nodes.ConfigVariant}>()
    @Output() viewSubTemplate: EventEmitter<Nodes.TemplateGraph | null> = new EventEmitter<Nodes.TemplateGraph | null>()
    @Output() highlightNode: EventEmitter<{
        node: Nodes.Node
        highlight: boolean
        transient?: boolean
        modifier?: boolean
    }> = new EventEmitter<{
        node: Nodes.Node
        highlight: boolean
        transient?: boolean
        modifier?: boolean
    }>()
    @Output() highlightSurface: EventEmitter<{object: Nodes.Object; surfaceId: string | null}> = new EventEmitter<{
        object: Nodes.Object
        surfaceId: string | null
    }>()
    @Output() showTransientObject: EventEmitter<{node: Nodes.Node; show: boolean}> = new EventEmitter<{
        node: Nodes.Node
        show: boolean
    }>()

    NodeUtils = NodeUtils
    treeControl = new NestedTreeControl<NodeTreeItem, NodeTreeItem["trackByKey"]>((item) => item.getChildren(), {trackBy: (item) => item.trackByKey})
    dataSource?: MatTreeNestedDataSource<NodeTreeItem>
    context: Nodes.Context
    Material = Material
    Template = Template
    hasChild = (index: number, item: NodeTreeItem) => item.hasChildren()
    trackBy = (index: number, item: NodeTreeItem) => item.trackByKey
    FilesService = FilesService
    private readonly unsubscribe = new Subject<void>()
    materialFilters: MaterialFilterInput
    templateFilters: TemplateFilterInput

    @Input() hdris: {id: number; name: string}[] = []
    defaultHdriId = 15

    private _templateGraph: TemplateGraph
    private rootItem: NodeTreeItem

    @Input() set templateGraph(graph: TemplateGraph) {
        this._templateGraph = graph
        this.rootItem = new NodeTreeItem(null, graph)
    }

    get templateGraph(): TemplateGraph {
        return this._templateGraph
    }

    dialogService = inject(DialogService)
    files = inject(FilesService)
    organizations = inject(OrganizationsService)
    permission = inject(PermissionsService)
    sdk = inject(SdkService)
    $can = this.permission.$to

    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public dragAndDrop: DragAndDropService,
        public templateService: TemplateService,
        private selectionService: SelectionService,
        public clipboardService: NodeClipboardService,
        hotkeys: Hotkeys,
    ) {
        // register hotkeys
        hotkeys
            .addShortcut("delete")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => this.deleteSelection())
        //hotkeys.addShortcut('c').pipe(takeUntil(this.unsubscribe)).subscribe(() => this.centerNodesAtOrigin(this.selectionService.selectedNodes, false));
    }

    ngOnInit() {
        this.materialFilters = {hasCyclesMaterial: true}
        this.templateFilters = {}

        this.initTree(this.templateGraph)
        this.templateService.contextsModified.pipe(takeUntil(this.unsubscribe)).subscribe((modifiedContexts: Nodes.Context[]) => {
            modifiedContexts.forEach((context) => this.updateNode(context))
        })
    }

    initTree(_templateGraph: TemplateGraph): void {
        this.dataSource = new MatTreeNestedDataSource()
        this.dataSource.data = this.rootItem.getChildren()
        this.treeControl.dataNodes = this.dataSource.data
    }

    // There is a bug in the CDK Tree's implementation, so the update has to be done manually.
    updateTree(): void {
        this.rootItem.invalidate()
        this.dataSource.data = null
        this.dataSource.data = this.rootItem.getChildren()
    }

    accessibleObjects(relation: Nodes.Relation): Nodes.Object[] {
        return this.editor.getAccessibleNodes(relation).filter(NodeUtils.isObject)
    }

    accessibleSurfaceSwitches(relation: Nodes.Relation): Nodes.Switch<Nodes.SurfaceReference>[] {
        return this.accessibleSwitches(relation) as Nodes.Switch<Nodes.SurfaceReference>[] //TODO: better types
    }

    accessibleObjectSwitches(relation: Nodes.Relation): Nodes.Switch<Nodes.ObjectReference>[] {
        return this.accessibleSwitches(relation) as Nodes.Switch<Nodes.ObjectReference>[] //TODO: better types
    }

    private accessibleSwitches(_node: Nodes.Node): Nodes.Switch<Nodes.Node>[] {
        const switches: Nodes.Switch<Nodes.Node>[] = []
        this.templateGraph.nodes.forEach((node) => {
            if (NodeUtils.isConfigGroup(node)) {
                node.nodes.forEach((node) => {
                    if (NodeUtils.isSwitch(node)) {
                        switches.push(node)
                    }
                })
            }
        })
        return switches
    }

    newSurfaceReference(object: Nodes.Object, surfaceId: string) {
        return Nodes.create<Nodes.DirectSurfaceReference>({
            type: "surfaceReference",
            object,
            surfaceId,
        })
    }

    addConfigVariant(group: Nodes.ConfigGroup) {
        this.promptForName("New Config", (name: string) => {
            const variant = Nodes.create<Nodes.ConfigVariant>({
                type: "configVariant",
                id: uuid4(),
                name,
                nodes: [],
            })
            group.nodes.push(variant)
            this.updateNode(group)
            this.activateConfigVariant.emit({config: group, variant})
        })
    }

    addConfigGroup(context: Nodes.Context) {
        this.promptForName("New Config Group", (name: string) => {
            const group = Nodes.create<Nodes.ConfigGroup>({
                type: "configGroup",
                id: uuid4(),
                name,
                nodes: [],
            })
            context.nodes.push(group)
            this.updateNode(context)
        })
    }

    addTemplateExport(context: Nodes.Context) {
        this.promptForName("New Output", (name: string) => {
            const templateExport = Nodes.create<Nodes.TemplateExport>({
                type: "templateExport",
                id: uuid4(),
                name,
                node: null,
            })
            context.nodes.push(templateExport)
            this.updateNode(context)
        })
    }

    addSwitch(group: Nodes.ConfigGroup) {
        this.promptForName("New Switch", (name: string) => {
            const sw = Nodes.create<Nodes.Switch<any>>({
                type: "switch",
                name,
                nodes: [],
            })
            group.nodes.push(sw)
            this.updateNode(group)
        })
    }

    addSelectedMaterialToSwitch(sw: Nodes.Switch<any>) {
        for (const node of this.selectionService.selectedNodes) {
            if (node && NodeUtils.resolvesToMaterial(node)) {
                sw.nodes.push(node)
            }
        }
        this.updateNode(sw)
    }

    addSelectedObjectToSwitch(sw: Nodes.Switch<any>) {
        for (const node of this.selectionService.selectedNodes) {
            if (node && NodeUtils.resolvesToObject(node)) {
                sw.nodes.push(node)
            }
        }
        this.updateNode(sw)
    }

    addRelation(context: Nodes.Context, type: "attach" | "align" | "lock" | "rigid"): Nodes.Relation {
        let newRelation: Nodes.Relation = null
        if (type === "attach") {
            context.nodes.push(
                Nodes.create<Nodes.AttachSurfaces>({
                    type: "attachSurfaces",
                    surfaceOffset: {x: 0, y: 0, angle: 0},
                    translation: {x: 0, y: 0, z: 0},
                    rotation: {type: "fixed", x: 0, y: 0, z: 0},
                    targetA: null,
                    targetB: null,
                }),
            )
            newRelation = context.nodes[context.nodes.length - 1] as Nodes.Relation
        } else if (type === "rigid") {
            context.nodes.push(
                Nodes.create<Nodes.RigidRelation>({
                    type: "rigidRelation",
                    translation: {x: 0, y: 0, z: 0},
                    rotation: {type: "fixed", x: 0, y: 0, z: 0},
                    targetA: null,
                    targetB: null,
                }),
            )
            newRelation = context.nodes[context.nodes.length - 1] as Nodes.Relation
        }
        this.updateNode(context)
        return newRelation
    }

    addAlignmentGuide(context: Nodes.TemplateGraph, guideType: "floor" | "wall") {
        const guide = Nodes.create<Nodes.PlaneGuide>({
            type: "planeGuide",
            name: guideType === "floor" ? "Floor" : "Wall",
            width: 100,
            height: 100,
            lockedTransform: (guideType === "wall" ? Matrix4.rotationX(90) : Matrix4.identity()).toArray(),
        })
        context.nodes.push(guide)
        this.updateNode(context)
        this.selectionService.selectNode(guide, false)
    }

    addAreaLight(context: Nodes.Context) {
        const light = Nodes.create<Nodes.AreaLight>({
            type: "areaLight",
            name: "Light",
            width: 100,
            height: 100,
            lockedTransform: Matrix4.identity().toArray(),
            target: [0, 0, -100],
            intensity: 1,
            color: [1, 1, 1],
            on: true,
            directionality: 0,
        })
        context.nodes.push(light)
        this.updateNode(context)
        this.selectionService.selectNode(light, false)
    }

    addLightPortal(context: Nodes.Context) {
        const light = Nodes.create<Nodes.LightPortal>({
            type: "lightPortal",
            name: "Light Portal",
            width: 100,
            height: 100,
            lockedTransform: Matrix4.identity().toArray(),
        })
        context.nodes.push(light)
        this.updateNode(context)
        this.selectionService.selectNode(light, false)
    }

    addHdriLight(context: Nodes.Context) {
        const hdri = this.hdris.find((x) => x.id === this.defaultHdriId) ?? this.hdris[0]
        const hdriLight = Nodes.create<Nodes.HDRILight>({
            type: "hdriLight",
            name: "Environment HDRI",
            hdri:
                hdri &&
                Nodes.create<Nodes.HDRIReference>({
                    type: "hdriReference",
                    hdriId: hdri.id,
                    name: hdri.name,
                }),
            intensity: 1,
            rotation: [0, 0, 0],
        })
        context.nodes.push(hdriLight)
        this.updateNode(context)
        this.selectionService.selectNode(hdriLight, false)
    }

    addCamera(context: Nodes.Context) {
        const camera = Nodes.create<Nodes.Camera>({
            type: "camera",
            name: "Camera",
            target: [0, 0, 0],
            lockedTransform: Matrix4.translation(0, 0, 100).toArray(),
            resolutionX: 1920,
            resolutionY: 1080,
            filmGauge: 36,
            fStop: 22,
            focalLength: 50,
            focalDistance: 100,
            exposure: 1.0,
            shiftX: 0,
            shiftY: 0,
            automaticVerticalTilt: false,
        })
        context.nodes.push(camera)
        this.updateNode(context)
        this.selectionService.selectNode(camera, false)
    }

    addSceneProperties(context: Nodes.Context) {
        const node = Nodes.create<Nodes.SceneProperties>({
            type: "sceneProperties",
            maxSubdivisionLevel: 2,
            backgroundColor: [1, 1, 1],
            uiColor: null,
        })
        context.nodes.push(node)
        this.updateNode(context)
        this.selectionService.selectNode(node, false)
    }

    addRender(context: Nodes.Context) {
        const node = Nodes.create<Nodes.Render>({
            type: "render",
            width: 1920,
            height: 1080,
            samples: 100,
        })
        context.nodes.push(node)
        this.updateNode(context)
        this.selectionService.selectNode(node, false)
    }

    addPostProcessRender(context: Nodes.Context) {
        const node = Nodes.create<Nodes.PostProcessRender>({
            type: "postProcessRender",
            mode: "whiteBackground",
            render: undefined,
            processShadows: false,
        })
        context.nodes.push(node)
        this.updateNode(context)
        this.selectionService.selectNode(node, false)
    }

    addProceduralMesh(context: Nodes.Context) {
        const mesh = Nodes.create<Nodes.ProceduralMesh>({
            type: "proceduralMesh",
            name: "Procedural",
            lockedTransform: Matrix4.identity().toArray(),
            geometryGraph: "",
            surfaces: [],
            parameters: {},
            materialAssignments: {
                "0": null,
                "1": null,
                "2": null,
            },
            materialSlotNames: {},
        })
        context.nodes.push(mesh)
        this.updateNode(context)
        this.selectionService.selectNode(mesh, false)
    }

    addPlane(context: Nodes.Context) {
        const mesh = Nodes.create<Nodes.ProceduralMesh>({
            type: "proceduralMesh",
            name: "Plane",
            lockedTransform: Matrix4.identity().toArray(),
            geometryGraph: "plane",
            surfaces: [],
            parameters: {
                width: 1000,
                height: 1000,
            },
            materialAssignments: {
                "0": null,
            },
            materialSlotNames: {},
        })
        context.nodes.push(mesh)
        this.updateNode(context)
        this.selectionService.selectNode(mesh, false)
    }

    addBox(context: Nodes.Context) {
        const mesh = Nodes.create<Nodes.ProceduralMesh>({
            type: "proceduralMesh",
            name: "Box",
            lockedTransform: Matrix4.identity().toArray(),
            geometryGraph: "box",
            surfaces: [],
            parameters: {
                width: 100,
                height: 100,
                depth: 100,
                inside: false,
                faceMaterials: false,
            },
            materialAssignments: {
                "0": null,
                "1": null,
                "2": null,
                // "3": null,
                // "4": null,
                // "5": null,
            },
            materialSlotNames: {},
        })
        context.nodes.push(mesh)
        this.updateNode(context)
        this.selectionService.selectNode(mesh, false)
    }

    addSphere(context: Nodes.Context) {
        const mesh = Nodes.create<Nodes.ProceduralMesh>({
            type: "proceduralMesh",
            name: "Sphere",
            lockedTransform: Matrix4.identity().toArray(),
            geometryGraph: "sphere",
            surfaces: [],
            parameters: {
                radius: 10,
                numU: 64,
                numV: 32,
            },
            materialAssignments: {
                "0": null,
            },
            materialSlotNames: {},
        })
        context.nodes.push(mesh)
        this.updateNode(context)
        this.selectionService.selectNode(mesh, false)
    }

    addStudio(context: Nodes.Context) {
        const mesh = Nodes.create<Nodes.ProceduralMesh>({
            type: "proceduralMesh",
            name: "Studio",
            lockedTransform: Matrix4.identity().toArray(),
            geometryGraph: "simpleStudioRoom",
            surfaces: [],
            parameters: {
                height: 5,
                length: 10,
                width: 10,
                showCeiling: true,
                showFloor: true,
                showWalls: true,
            },
            materialAssignments: {
                "0": null,
                "1": null,
                "2": null,
            },
            materialSlotNames: {
                "0": "Floor",
                "1": "Walls",
                "2": "Ceiling",
            },
        })
        context.nodes.push(mesh)
        this.updateNode(context)
        this.selectionService.selectNode(mesh, false)
    }

    addDecal(context: Nodes.Context, mesh?: Nodes.MeshOrInstance) {
        this.promptForName("New Decal", (name) => {
            const decal = Nodes.create<Nodes.MeshDecal>({
                type: "meshDecal",
                name,
                mesh,
                offset: [0, 0],
                rotation: 0,
                size: [10, 10],
                distance: 0.01,
                mask: undefined,
                material: undefined,
            })
            context.nodes.push(decal)
            this.updateNode(context)
            this.selectionService.selectNode(decal, false)
        })
    }

    addPointGuideForSelection(context: Nodes.Context) {
        this.promptForName("New Point Guide", (name) => {
            const nodes = this.selectionService.selectedNodes
            if (nodes.length === 0) return
            const centroid = this.editor.centroidForNodes(nodes)
            const transform = Matrix4.translation(centroid.x, centroid.y, centroid.z).toArray()
            const guide = Nodes.create<Nodes.PointGuide>({
                type: "pointGuide",
                name,
                lockedTransform: transform,
                $defaultTransform: transform,
            })
            context.nodes.push(guide)
            this.updateNode(context)
            this.selectionService.selectNode(guide, false)
        })
    }

    addValue(context: Nodes.Context) {
        const value = Nodes.create<Nodes.Value>({
            type: "value",
            value: true,
        })
        context.nodes.push(value)
        this.updateNode(context)
        this.selectionService.selectNode(value, false)
    }

    addOverlayMaterialColor(context: Nodes.Context) {
        const value = Nodes.create<Nodes.OverlayMaterialColor>({
            type: "overlayMaterialColor",
            material: null,
            overlay: null,
            size: [10, 10],
        })
        context.nodes.push(value)
        this.updateNode(context)
        this.selectionService.selectNode(value, false)
    }

    addAnnotation(context: Nodes.Context): void {
        const annotation = Nodes.create<Nodes.Annotation>({
            type: "annotation",
            id: uuid4(),
            name: "Annotation",
            label: "Label",
            description: "",
            lockedTransform: Matrix4.identity()
                .multiply(Matrix4.translation(0, 0, 0))
                .toArray(),
        })
        context.nodes.push(annotation)
        this.updateNode(context)
        this.selectionService.selectNode(annotation, false)
    }

    _setTransformLocked(node: Nodes.Object, locked: boolean) {
        const objId = this.editor.sceneManager.getObjectIdForNode(node)
        if (objId) {
            const worldTransform = this.editor.sceneManager.getWorldTransformForObject(objId)
            if (locked) {
                node.lockedTransform = worldTransform.toArray()
            } else {
                delete node.lockedTransform
            }
            this.updateNode(node)
        }
    }

    setAllTransformLocked(node: Nodes.Object, locked: boolean) {
        if (this.isNodeSelected(node)) {
            // part of current selection, apply lock to all
            for (const sel of this.selectionService.selectedNodes) {
                if (NodeUtils.isTransformable(sel)) {
                    this._setTransformLocked(sel, locked)
                }
            }
        } else {
            // only apply to this node
            this._setTransformLocked(node, locked)
        }
    }

    unlockTransformIfLocked(node: Nodes.Node) {
        if (NodeUtils.isTransformable(node) && node.lockedTransform) {
            delete node.lockedTransform
            this.updateNode(node)
        }
    }

    rigidlyConnectSelection(context: Nodes.Context) {
        const selectedObjects: Nodes.ObjectReference[] = []
        for (const node of this.selectionService.selectedNodes) {
            if (NodeUtils.resolvesToObject(node)) {
                selectedObjects.push(node)
            }
        }

        if (selectedObjects.length < 2) {
            return
        }

        const primObj = selectedObjects[0]
        for (let i = 1; i < selectedObjects.length; i++) {
            const secObj = selectedObjects[i]
            const relation = this.addRelation(context, "rigid")
            if (relation.type === "rigidRelation") {
                relation.targetA = primObj
                relation.targetB = secObj
                const params = this.editor.captureRelationParams(relation)
                relation.translation.x = params[0]
                relation.translation.y = params[1]
                relation.translation.z = params[2]
                relation.rotation = {
                    type: "fixed",
                    x: params[3],
                    y: params[4],
                    z: params[5],
                }
                this.unlockTransformIfLocked(secObj)
                this.updateNode(relation)
            }
        }
    }

    openSelectMaterialDialog(context: Nodes.Context) {
        this.context = context
        this.dialogService.selectInDialog(SelectMaterialComponent, {
            filters: this.materialFilters,
            onSelect: (material) => this.addTask(from(this.addMaterialFromLibrary(this.context, material.id))),
        })
    }

    openSelectTemplateDialog(context: Nodes.Context) {
        this.context = context
        this.dialogService.selectInDialog(SelectTemplateComponent, {
            filters: this.templateFilters,
            onSelect: (template) => this.addTask(from(this.addTemplateFromLibrary(this.context, template.id))),
        })
    }

    async addTemplateFromLibrary(context: Nodes.Context, templateId: string) {
        const {template} = await this.sdk.gql.templateTreeTemplate({id: templateId})
        const templateRef = Nodes.create<Nodes.TemplateInstance>({
            type: "templateInstance",
            id: uuid4(),
            name: template.name,
            lockedTransform: Matrix4.identity().toArray(),
            template: {
                type: "templateReference",
                templateRevisionId: template.latestRevision.legacyId,
            },
        })
        context.nodes.push(templateRef)
        this.updateNode(context)
        this.selectionService.selectNode(templateRef, false)
    }

    async addMaterialFromLibrary(context: Nodes.Context, materialId: string) {
        const {material} = await this.sdk.gql.templateTreeMaterial({id: materialId})
        const materialRevision = material.latestCyclesRevision
        if (!materialRevision) {
            throw new Error("Material has no latest revision for cycles!")
        }
        const materialRef = Nodes.create<Nodes.MaterialReference>({
            type: "materialReference",
            name: material.name,
            materialRevisionId: materialRevision.legacyId,
        })
        context.nodes.push(materialRef)
        this.updateNode(context)
        this.selectionService.selectNode(materialRef, false)
    }

    addMaterialInput(context: Nodes.Context) {
        this.promptForName("New Material Input", (name) => {
            const input = Nodes.create<Nodes.TemplateMaterialInput>({
                type: "templateInput",
                id: uuid4(),
                inputType: "material",
                name,
            })
            context.nodes.push(input)
            this.updateNode(context)
            this.selectionService.selectNode(input, false)
        })
    }

    addTemplateInput(context: Nodes.Context) {
        this.promptForName("New Template Input", (name) => {
            const input = Nodes.create<Nodes.TemplateTemplateInput>({
                type: "templateInput",
                id: uuid4(),
                inputType: "template",
                name,
            })
            context.nodes.push(input)
            this.updateNode(context)
            this.selectionService.selectNode(input, false)
        })
    }

    addImageInput(context: Nodes.Context) {
        this.promptForName("New Image Input", (name) => {
            const input = Nodes.create<Nodes.TemplateImageInput>({
                type: "templateInput",
                id: uuid4(),
                inputType: "image",
                name,
            })
            context.nodes.push(input)
            this.updateNode(context)
            this.selectionService.selectNode(input, false)
        })
    }

    addStringInput(context: Nodes.Context) {
        this.promptForName("New String Input", (name) => {
            const input = Nodes.create<Nodes.TemplateStringInput>({
                type: "templateInput",
                id: uuid4(),
                inputType: "string",
                name,
            })
            context.nodes.push(input)
            this.updateNode(context)
            this.selectionService.selectNode(input, false)
        })
    }

    addNumberInput(context: Nodes.Context) {
        this.promptForName("New Number Input", (name) => {
            const input = Nodes.create<Nodes.TemplateNumberInput>({
                type: "templateInput",
                id: uuid4(),
                inputType: "number",
                name,
            })
            context.nodes.push(input)
            this.updateNode(context)
            this.selectionService.selectNode(input, false)
        })
    }

    addBooleanInput(context: Nodes.Context) {
        this.promptForName("New Boolean Input", (name) => {
            const input = Nodes.create<Nodes.TemplateBooleanInput>({
                type: "templateInput",
                id: uuid4(),
                inputType: "boolean",
                name,
            })
            context.nodes.push(input)
            this.updateNode(context)
            this.selectionService.selectNode(input, false)
        })
    }

    addEmptyTemplateInstance(context: Nodes.Context) {
        const instance = Nodes.create<Nodes.TemplateInstance>({
            type: "templateInstance",
            id: uuid4(),
            name: "Template Instance",
            template: null,
            lockedTransform: Matrix4.identity().toArray(),
        })
        context.nodes.push(instance)
        this.updateNode(context)
        this.selectionService.selectNode(instance, false)
    }

    moveSelectionToNewGroup(context: Nodes.Context) {
        this.promptForName("New Group", (name) => {
            const nodes = this.selectionService.selectedNodes
            const modifiedContexts = NodeUtils.removeNodesFromContexts(this.templateGraph, nodes)
            const group = Nodes.create<Nodes.Group>({
                type: "group",
                name,
                active: true,
                nodes: [...nodes],
            })
            context.nodes.push(group)
            modifiedContexts.push(context)
            modifiedContexts.forEach((c) => this.updateNode(c))
        })
    }

    moveSelectionToContext(context: Nodes.Context) {
        const nodes = this.selectionService.selectedNodes
        const modifiedContexts = NodeUtils.removeNodesFromContexts(this.templateGraph, nodes)
        context.nodes.push(...nodes)
        modifiedContexts.push(context)
        modifiedContexts.forEach((c) => this.updateNode(c))
    }

    replaceSelectionWithTemplate(context: Nodes.Context) {
        const nodes = this.selectionService.selectedNodes
        if (nodes.length === 0) return

        const dialogRef: MatDialogRef<SelectTemplateComponent> = this.dialog.open(SelectTemplateComponent, {
            disableClose: false,
            width: "90%",
            height: "90%",
            data: {},
        })

        this.addTask(
            dialogRef.afterClosed().pipe(
                map(async (template: {id: string}) => {
                    if (template) {
                        // const commonContext = NodeUtils.getCommonContextForNodes(this.templateGraph, nodes) || this.templateGraph;
                        const modifiedContexts = NodeUtils.removeNodesFromContexts(this.templateGraph, nodes)
                        modifiedContexts.forEach((c) => this.updateNode(c))
                        return this.addTemplateFromLibrary(context, template.id)
                    }
                }),
            ),
        )
    }

    createTemplateFromSelection(context: Nodes.Context) {
        const nodes = this.selectionService.selectedNodes
        this.promptForName("New Template", (name) => {
            // const newPosition = this.centerNodesAtOrigin(nodes, true);
            const graph = Nodes.create<Nodes.TemplateGraph>({
                type: "templateGraph",
                schema: Nodes.currentTemplateGraphSchema,
                name,
                nodes: [...nodes],
            })
            const modifiedContexts = NodeUtils.removeNodesFromContexts(this.templateGraph, nodes)
            const instance = Nodes.create<Nodes.TemplateInstance>({
                type: "templateInstance",
                id: uuid4(),
                name: name + " instance",
                template: graph,
                // $defaultTransform: Matrix4.translation(newPosition.x, newPosition.y, newPosition.z).toArray()
            })
            context.nodes.push(graph)
            context.nodes.push(instance)
            modifiedContexts.push(context)
            modifiedContexts.forEach((c) => this.updateNode(c))
            this.selectionService.selectNode(instance, false)
        })
    }

    promoteToLibraryTemplate(graph: Nodes.TemplateGraph) {
        //TODO: look for dangling references inside of template graph
        this.promptForName("New Template", (name) => {
            const template: Template = new Template()
            template.name = name
            template.customer = this.entity.customer
            template.type = TemplateTypes.Part
            template.comment = `(Created from editor selection within ${this.entity instanceof Template ? this.entity.name : this.entity.id})`
            const templateRevision: TemplateRevision = new TemplateRevision()
            const pending = template.save().pipe(
                switchMap(() => {
                    const newGraph: Nodes.TemplateGraph = {
                        ...graph,
                        name,
                    }
                    templateRevision.template = template.id
                    templateRevision.templateGraph = this.editor.sceneManager.exportGraph(newGraph)
                    return templateRevision.save()
                }),
                map(() => {
                    console.warn("TODO: replace all references to the promoted template")
                }),
            )
            this.addTask(pending)
        })
    }

    centerNodesAtOrigin(nodes: Nodes.Node[], lockTransform: boolean, reference?: Nodes.Node): Vector3 {
        const position = reference ? this.editor.worldTransformForNode(reference).getTranslation() : this.editor.centroidForNodes(nodes)
        const wDelta = Matrix4.translation(-position.x, -position.y, -position.z)
        this.editor.applyWorldTransformToNodes(nodes, wDelta, lockTransform)
        return position
    }

    moveSelectionToOrigin(reference: Nodes.Node) {
        this.centerNodesAtOrigin(this.selectionService.selectedNodes, true, reference)
    }

    dissolveGroup(group: Nodes.Group) {
        const context = NodeUtils.findContextForNode(this.templateGraph, group)
        if (context) {
            removeFromArray(context.nodes, group)
            for (const node of group.nodes) {
                //TODO: splice at original index
                context.nodes.push(node)
            }
            this.updateNode(context)
        }
    }

    deleteSelection() {
        this.editor.deleteNodes(this.selectionService.selectedNodes)
    }

    isNodeSelected(node: Nodes.Node): boolean {
        return this.selectionService.selectedNodes.includes(node)
    }

    isNodeExportedFromTemplate(node: Nodes.Node): boolean {
        switch (node.type) {
            case "templateExport":
            case "configGroup":
            case "configVariant":
                return true
            default:
                return false
        }
    }

    isNodeLocked(node: Nodes.Node): boolean {
        return NodeUtils.isTransformable(node) && !!node.lockedTransform
    }

    addSelectedSurfaceToSwitch(sw: Nodes.Switch<any>) {
        const objNode = this.selectionService.filterOne(NodeUtils.isObject)
        const surface = this.selectionService.selectedSurface
        if (objNode && surface) {
            sw.nodes.push(
                Nodes.create<Nodes.DirectSurfaceReference>({
                    type: "surfaceReference",
                    object: objNode,
                    surfaceId: Nodes.getExternalId(surface),
                }),
            )
            this.updateNode(sw)
        }
    }

    resetNodeTransform(node: Nodes.Object) {
        return this.editor.resetNodeTransform(node)
    }

    selectNode(node: Nodes.Node | null, event?: MouseEvent | PointerEvent): void {
        const extendType = event && getSelectionModifier(event)
        if (extendType === "range") {
            const nodeContext: Nodes.Context = NodeUtils.findContextForNode(this.templateGraph, node)
            const startIdx = nodeContext.nodes.indexOf(this.selectionService.selectedNodes[0])
            const endIdx = nodeContext.nodes.indexOf(node)
            if (startIdx >= 0) {
                const increment = endIdx > startIdx ? 1 : -1
                for (let i = 1; i <= (endIdx - startIdx) * increment; i++) {
                    this.selectionService.selectNode(nodeContext.nodes[startIdx + i * increment], true)
                }
                return
            }
        }
        this.selectionService.selectNode(node, extendType === "single")
    }

    deleteNode(context: Nodes.Context | undefined, node: Nodes.Node): void {
        const count = NodeUtils.countReferences(node, this.templateGraph)
        const allowDeleteSingleRef = context && NodeUtils.isReferenceOnlyContext(context)

        if (count > 1) {
            const dialogRef: MatDialogRef<DialogComponent> = this.dialog.open(DialogComponent, {
                width: "400px",
                data: {
                    title: "Delete node",
                    message:
                        "There are " +
                        count.toString() +
                        " references to the node '" +
                        NodeUtils.describeNode(node) +
                        "'. Are you sure you want to delete all of them?",
                    confirmLabel: "Delete all",
                    otherActions:
                        context && allowDeleteSingleRef
                            ? [
                                  {
                                      state: "deleteReference",
                                      label: "Delete this reference",
                                  },
                              ]
                            : undefined,
                    cancelLabel: "Cancel",
                },
            })
            this.addTask(
                dialogRef.afterClosed().pipe(
                    map((action) => {
                        if (action) {
                            if (action === "deleteReference") {
                                removeFromArray(context.nodes, node)
                                this.updateNode(context)
                                this.selectionService.removeFromSelection(node)
                            } else {
                                this.editor.deleteNodes([node])
                            }
                        }
                    }),
                ),
            )
        } else {
            this.editor.deleteNodes([node])
        }
    }

    resolveNodeLink(node: Nodes.TemplateInstance | Nodes.TemplateReference): Observable<string> | null {
        let revisionID: number
        const resolvedNode = NodeUtils.resolveTemplateInstance(node)
        if (resolvedNode.type === "templateReference") {
            return from(this.sdk.gql.templateTreeTemplateRevision({legacyId: resolvedNode.templateRevisionId})).pipe(
                map(({templateRevision}) => {
                    return "/templates/" + templateRevision.template.id + "/revisions/" + templateRevision.id + "/edit"
                }),
            )
        } else {
            return null
        }
    }

    _viewSubTemplate(node: Nodes.TemplateGraph): void {
        this.viewSubTemplate.emit(node)
    }

    renameNode(node: Nodes.Node): void {
        const isNamedNode = (
            node: Nodes.Node,
        ): node is Nodes.Node & {
            name: string
        } => "name" in node && typeof node.name === "string"
        if (isNamedNode(node)) {
            this.openRenameDialog(node.name, (newName) => {
                node.name = newName
                this.updateNode(node)
            })
        }
    }

    copySelection(primaryNode?: Nodes.Node): void {
        this.clipboardService.copy(this.selectionService.selectedNodes)
    }

    pasteSelection(context: Nodes.Context): void {
        if (!context) {
            if (this.selectionService.selectedNodes.length !== 1) {
                this.snackBar.open("Please select a single node as the destination of the copy/paste operation.", "", {duration: 3000})
                return
            }
            context = this.selectionService.selectedNodes[0] as Nodes.Context
        }
        if (!("nodes" in context)) {
            this.snackBar.open("Cannot paste into the selected node.", "", {duration: 3000})
            return
        }

        const nodes = NodeUtils.isReferenceOnlyContext(context) ? this.clipboardService.pasteReferences() : this.clipboardService.pasteDuplicates()
        for (const node of nodes) {
            this.updateNode(node)
            context.nodes.push(node)
        }
        this.updateTree()
    }

    instanceSelection(context: Nodes.Context, node: Nodes.TemplateDefinition) {
        const instance = Nodes.create<Nodes.TemplateInstance>({
            type: "templateInstance",
            id: uuid4(),
            template: node,
            name: ((node as unknown as {name: string}).name ?? "Template") + " instance",
        })
        insertAfter(context.nodes, node, instance)
        this.updateNode(context)
    }

    duplicateNode(node: Nodes.Node) {
        const context = NodeUtils.findContextForNode(this.templateGraph, node)
        insertAfter(context.nodes, node, ...NodeUtils.copySubgraphStructure([node]))
        this.updateNode(context)
    }

    private promptForName(name: string, onConfirm: (name: string) => void): void {
        return this.openRenameDialog(name, onConfirm)
    }

    private openRenameDialog(name: string, onConfirm: (name: string) => void): void {
        const dialogRef: MatDialogRef<RenameDialogComponent> = this.dialog.open(RenameDialogComponent, {
            width: "400px",
            data: {
                currentName: name,
            },
        })
        this.addTask(
            dialogRef.afterClosed().pipe(
                map((newName) => {
                    if (newName) {
                        onConfirm(newName)
                    }
                }),
            ),
        )
    }

    addTask(task: Observable<void> | Observable<Promise<void>>): void {
        this.editor.addTask(task)
    }

    getNodeLabel(node: Nodes.Node): string {
        if ("name" in node) return node.name
        else return NodeUtils.describeNode(node)
    }

    getErrorString(node: Nodes.Node): string | undefined {
        return this.editor.getNodeErrors(node)?.join("\n")
    }

    updateNode(node: Nodes.Node) {
        this.updateTree()
        this.editor.sceneManager.markNodeChanged(node)
    }

    getNodeIconClass(node: Nodes.Node): string | null {
        if (NodeUtils.isCamera(node)) return "far fa-camera"
        if (NodeUtils.isTemplateInput(node)) return "far fa-sign-in-alt"
        if (NodeUtils.isTemplateExport(node)) return "far fa-sign-out-alt"

        if (NodeUtils.isProceduralMesh(node)) return "far fa-hexagon"
        if (NodeUtils.isSwitch(node)) return "far fa-random"
        if (NodeUtils.isValue(node)) return "far fa-code"
        if (NodeUtils.isResolveNode(node)) return "fas fa-binoculars"

        if (NodeUtils.isMesh(node)) return "far fa-cube"
        if (NodeUtils.isMeshDecal(node)) return "far fa-image"
        if (NodeUtils.isMaterialReference(node)) return "far fa-game-board-alt"
        if (NodeUtils.isLight(node)) return "far fa-lightbulb"
        if (NodeUtils.isInstance(node)) return this.getNodeIconClass(node.node)
        if (NodeUtils.isTemplateGraph(node)) return "far fa-drafting-compass"
        if (NodeUtils.isTemplateOrInstance(node)) return "fad fa-cube"

        if (NodeUtils.isGroup(node)) return "far fa-folder"
        if (NodeUtils.isConfigGroup(node)) return "far fa-list"
        if (NodeUtils.isConfigVariant(node)) return "far fa-cog"

        if (NodeUtils.isRelation(node)) return "far fa-arrow-to-right"
        if (NodeUtils.isGuide(node)) return "far fa-map-marker-alt"
        if (NodeUtils.isAnnotation(node)) return "far fa-tag"

        if (NodeUtils.isSceneProperties(node)) return "far fa-browser"
        if (NodeUtils.isRender(node)) return "far fa-image"
        if (NodeUtils.isPostProcessRender(node)) return "far fa-palette"
        return null
    }

    protected readonly DialogSize = DialogSize
}
