import {NgTemplateOutlet} from "@angular/common"
import {Component, effect, HostListener, inject, OnDestroy, OnInit} from "@angular/core"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {ActivatedRoute, NavigationEnd, Router, RouterLink, UrlSerializer} from "@angular/router"
import {NavigationBarOrganizationFragment, OrganizationType, SystemRole} from "@api"
import {ToggleComponent} from "@common/components/buttons/toggle/toggle.component"
import {Breakpoints} from "@common/models/constants"
import {NavigationItemComponent} from "@common/components/navigation/navigation-item/navigation-item.component"
import {SidenavComponent} from "@common/components/navigation/sidenav/sidenav.component"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {OrganizationsService} from "@common/services/organizations/organizations.service"
import {NavigationBarService} from "@common/services/navigation-bar/navigation-bar-service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UserThumbnailComponent} from "@platform/components/users/user-thumbnail/user-thumbnail.component"
import {filter, Subject, takeUntil} from "rxjs"
import {environment} from "@environment"
import {MatListModule} from "@angular/material/list"
import {Labels} from "@labels"
import {IsDefined} from "@cm/lib/utils/filter"
import {CdkMenu, CdkMenuItem, CdkMenuTrigger} from "@angular/cdk/menu"
import {LineComponent} from "@common/components/line/line.component"

@Component({
    selector: "cm-navigation-bar",
    templateUrl: "navigation-bar-component.html",
    styleUrls: ["navigation-bar-component.scss"],
    standalone: true,
    imports: [
        RouterLink,
        NgTemplateOutlet,
        ToggleComponent,
        MemoizePipe,
        NavigationItemComponent,
        SidenavComponent,
        MatButtonModule,
        MatListModule,
        CdkMenu,
        CdkMenuTrigger,
        CdkMenuItem,
        LineComponent,
        UserThumbnailComponent,
    ],
})
export class NavigationBarComponent implements OnInit, OnDestroy {
    @HostListener("window:resize", ["$event"])
    onResize(_event: Event) {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
    }

    progressBarValue = 100
    selectedLink: string | undefined
    unsubscribe = new Subject<void>()
    sidenavOpen = false
    isBigScreen: boolean = false
    navigationIsToggled = false
    allOrganizations: NavigationBarOrganizationFragment[] = []

    environment = environment
    backgroundColor = environment.style.navigationBar.background ?? "#0000000"

    permission = inject(PermissionsService)
    auth = inject(AuthService)
    organizations = inject(OrganizationsService)
    refresh = inject(RefreshService)
    serializer = inject(UrlSerializer)
    sdk = inject(SdkService)
    $can = this.permission.$to

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        public navBarService: NavigationBarService,
    ) {
        effect(() => {
            const can = this.$can()
            void this.loadOrganizations(can)
        })
    }

    ngOnInit() {
        this.isBigScreen = window.innerWidth >= Breakpoints.smallScreenLimit
        this.navBarService.progress.pipe(takeUntil(this.unsubscribe)).subscribe((progress: number) => {
            this.progressBarValue = progress
        })
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe),
            )
            .subscribe(() => {
                this.selectedLink = this.route.snapshot.children[0].routeConfig?.path
            })
    }

    async loadOrganizations(can: ReturnType<PermissionsService["$to"]>) {
        if (can.read.menu("authenticateForAnyOrganization")) {
            const batchSize = 100
            this.allOrganizations = []
            for (let i = 0; i < 100; i++) {
                const {organizations} = await this.sdk.gqlWithoutSilo.navigationBarAllOrganizations({
                    filter: {visibleInFilters: true},
                    skip: batchSize * i,
                    take: batchSize,
                })
                this.allOrganizations = [...this.allOrganizations, ...organizations.filter(IsDefined)]
                if (organizations.length === 0) {
                    break
                }
            }
        } else {
            const userId = this.auth.$user()?.id
            if (userId) {
                const {user} = await this.sdk.gqlWithoutSilo.navigationBarUserMemberships({id: userId})
                this.allOrganizations = user.memberships.map((membership) => membership.organization).filter(IsDefined)
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next()
        this.unsubscribe.complete()
    }

    async activateSystemRole(systemRole: SystemRole) {
        await this.sdk.activateSystemRole(systemRole)
        const colormassOrganizations = await this.sdk.gql.navigationBarColormassOrganization()
        const colormassId = colormassOrganizations?.organizations?.[0]?.id
        if (colormassId && this.route.snapshot.queryParamMap.get("organizationId")) {
            await this.router.navigate(this.route.snapshot.url, {
                queryParams: {organizationId: colormassId},
                queryParamsHandling: "merge",
            })
        }
        await this.router.navigate(["pictures"], {queryParams: {tab: "projects", organizationId: colormassId}})
    }

    async activateOrganization(organization: {__typename: "Organization"; id: string; name?: string | null; type?: OrganizationType | null}) {
        this.sdk.selectOrganization(organization)
        this.refresh.all()
        const organizationId = organization?.id ?? null
        await this.router.navigate(["pictures"], {queryParams: {tab: "projects", organizationId}})
    }

    get showOrganizationSwitcher() {
        return (
            this.$can().read.menu("authenticateForAnyOrganization") ||
            (this.$can().read.menu("authenticateForOrganization") && this.allOrganizations.length > 1)
        )
    }

    protected readonly Labels = Labels
    protected readonly SystemRole = SystemRole
}
