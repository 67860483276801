import {Component, inject, OnInit} from "@angular/core"
import {RouterOutlet} from "@angular/router"
import {CheckboxesFilterComponent, PinnedFilterComponent, PinnedFilterOptionComponent, TagSearchFilterComponent} from "@common/components/filters"
import {ItemFiltersComponent} from "@common/components/item"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {Enums} from "@enums"
import {Labels, StateLabel} from "@labels"
import {SidebarLayoutComponent} from "@platform/components/layouts/sidebar-layout/sidebar-layout.component"
import {MaterialsGridComponent} from "@platform/components/materials/materials-grid/materials-grid.component"
import {ModelsGridComponent} from "@platform/components/models/models-grid/models-grid.component"
import {SdkService} from "@common/services/sdk/sdk.service"
import {IsDefined} from "@cm/lib/utils/filter"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"

@Component({
    selector: "cm-models-page",
    standalone: true,
    imports: [
        CheckboxesFilterComponent,
        ItemFiltersComponent,
        MaterialsGridComponent,
        PinnedFilterComponent,
        PinnedFilterOptionComponent,
        RouterOutlet,
        SidebarLayoutComponent,
        TagSearchFilterComponent,
        ModelsGridComponent,
        PlaceholderComponent,
    ],
    templateUrl: "./models-page.component.html",
    styleUrl: "./models-page.component.scss",
})
export class ModelsPageComponent implements OnInit {
    sdk = inject(SdkService)
    permission = inject(PermissionsService)
    $can = this.permission.$to

    userLabels: StateLabel<string>[] | null = null

    ngOnInit() {
        this.sdk.gql.modelsPageVisibleUsers().then(({users}) => {
            this.userLabels = users.filter(IsDefined).map((user) => ({
                label: user.name,
                state: user.id,
            }))
        })
    }

    protected readonly Enums = Enums
    protected readonly Labels = Labels
}
