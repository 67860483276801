import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import {wrap} from "@cm/lib/utils/utils"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {HalContext, HalEntity} from "@common/models/hal/hal-context"
import {HalPainterPrimitive} from "@common/models/hal/hal-painter-primitive"
import {HalBrushShapeGenerator} from "app/textures/texture-editor/operator-stack/operators/shared/hal/hal-brush-shape-generator"
import {Box2} from "@cm/lib/math/box2"
import {HalPaintableImage} from "@app/common/models/hal/hal-paintable-image"
import {createHalPainterPrimitive} from "@common/models/hal/hal-painter-primitive/create"

const BRUSH_SPLAT_SPACING = 0.02 // portion of brush width

const SHADING_FUNCTION = `
    vec4 computeColor(vec2 worldPosition, vec2 uv, vec4 color) {
        return textureNormalized0(uv);
    }
`

export class HalStrokeGeometry implements HalEntity {
    constructor(readonly context: HalContext) {
        this.halPainterPrimitive = createHalPainterPrimitive(this.context, SHADING_FUNCTION)
        this.brushShapeGenerator = HalBrushShapeGenerator.create(this.context)
    }

    // HalEntity
    dispose(): void {
        this.halPainterPrimitive.dispose()
    }

    get strokePoints(): readonly Vector2Like[] {
        return this._strokePoints
    }

    set brushSettings(value: BrushSettings) {
        this._brushSettings = value
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    tilingEnabled = false

    startNewStroke() {
        this._strokePoints = []
        this.firstPointDrawn = false
        this.splatDistanceRemainder = 0
        this.needUpdateStrokeGeometry = true
    }

    pushStrokePoints(...points: Vector2Like[]) {
        this._strokePoints.push(...points.map((p) => new Vector2(p.x, p.y)))
        this.needUpdateStrokeGeometry = true
    }

    async paint(target: HalPaintableImage, removeDrawnPoints: boolean): Promise<Box2> {
        this.updateStrokeGeometry(target.width, target.height)
        const brushShapeImage = await this.brushShapeGenerator.getBrushShape(this.brushSettings, target.descriptor.dataType)
        this.halPainterPrimitive.setSourceImage(0, brushShapeImage)
        await this.halPainterPrimitive.paint(target, {blendMode: "screen"})
        if (removeDrawnPoints) {
            const lastStrokePoint = this._strokePoints[this.strokePoints.length - 1]
            this._strokePoints = [lastStrokePoint]
        }
        return this.boundingBox
    }

    private updateStrokeGeometry(targetWidth: number, targetHeight: number) {
        if (!this.needUpdateStrokeGeometry) {
            return
        }
        this.needUpdateStrokeGeometry = false
        this.halPainterPrimitive.clearGeometry()
        this.boundingBox = this.addStrokeGeometrySplats(this._strokePoints, this.brushSettings.brushWidth, targetWidth, targetHeight)
    }

    private addStrokeGeometrySplats(path: Vector2[], width: number, targetWidth: number, targetHeight: number): Box2 {
        if (width <= 0) {
            throw Error("Thickness must be positive")
        }
        const boundingBox = new Box2()
        const splatDistance = width * BRUSH_SPLAT_SPACING
        if (path.length >= 1 && !this.firstPointDrawn) {
            this.firstPointDrawn = true
            boundingBox.expandByBox(this.addTileableQuad(path[0], width, targetWidth, targetHeight))
            this.splatDistanceRemainder = splatDistance
        }
        if (path.length >= 2) {
            for (let i = 1; i < path.length; i++) {
                const p0 = path[i - 1]
                const p1 = path[i]
                const delta = p1.sub(p0)
                const distance = delta.norm()
                const dir = delta.mul(1 / distance)
                while (this.splatDistanceRemainder < distance) {
                    const pos = p0.add(dir.mul(this.splatDistanceRemainder))
                    const quadBoundingBox = this.addTileableQuad(pos, width, targetWidth, targetHeight)
                    boundingBox.expandByBox(quadBoundingBox)
                    this.splatDistanceRemainder += splatDistance
                }
                this.splatDistanceRemainder -= distance
            }
        }
        return boundingBox
    }

    private addTileableQuad(pos: Vector2, width: number, targetWidth: number, targetHeight: number): Box2 {
        if (this.tilingEnabled) {
            pos = new Vector2(wrap(pos.x, targetWidth), wrap(pos.y, targetHeight))
        }
        const boundingBox = new Box2()
        boundingBox.expandByBox(this.addQuad(pos, width))
        if (this.tilingEnabled) {
            const repeatX = pos.x > targetWidth - width / 2 || pos.x < width / 2
            const reoeatY = pos.y > targetHeight - width / 2 || pos.y < width / 2
            const signX = pos.x > targetWidth / 2 ? -1 : 1
            const signY = pos.y > targetHeight / 2 ? -1 : 1
            if (repeatX && reoeatY) {
                boundingBox.expandByBox(this.addQuad(pos.add({x: targetWidth * signX, y: targetHeight * signY}), width))
            }
            if (repeatX) {
                boundingBox.expandByBox(this.addQuad(pos.add({x: targetWidth * signX, y: 0}), width))
            }
            if (reoeatY) {
                boundingBox.expandByBox(this.addQuad(pos.add({x: 0, y: targetHeight * signY}), width))
            }
        }
        boundingBox.expandToIntegers()
        boundingBox.intersect(new Box2(0, 0, targetWidth, targetHeight))
        return boundingBox
    }

    private addQuad(pos: Vector2, width: number): Box2 {
        const p00 = new Vector2(pos.x - width / 2, pos.y - width / 2)
        const p10 = new Vector2(pos.x + width / 2, pos.y - width / 2)
        const p01 = new Vector2(pos.x - width / 2, pos.y + width / 2)
        const p11 = new Vector2(pos.x + width / 2, pos.y + width / 2)
        const baseIndex = this.halPainterPrimitive.addVertices(
            [p00, p10, p01, p11],
            [new Vector2(0, 0), new Vector2(1, 0), new Vector2(0, 1), new Vector2(1, 1)],
        )
        this.halPainterPrimitive.addIndices([baseIndex + 0, baseIndex + 1, baseIndex + 2, baseIndex + 1, baseIndex + 3, baseIndex + 2])
        return Box2.fromMinMax(p00, p11)
    }

    private halPainterPrimitive: HalPainterPrimitive
    private _strokePoints: Vector2[] = []
    private firstPointDrawn = false
    private _brushSettings = new BrushSettings()
    private brushShapeGenerator: HalBrushShapeGenerator // TODO replace by image-op
    private needUpdateStrokeGeometry = true
    private splatDistanceRemainder = 0
    private boundingBox = new Box2()
}
