import {NodeClassImpl} from "@src/templates/runtime-graph/types"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {IDataObjectNew} from "@src/templates/interfaces/data-object"
import {Subscription, from, map} from "rxjs"
import {ISceneManagerNew} from "@src/templates/interfaces/scene-manager"

const TD = TypeDescriptors

const loadDataDescriptor = {
    sceneManager: TD.inlet(TD.Identity<ISceneManagerNew>()),
    dataObject: TD.inlet(TD.DataObjectNew),
    data: TD.outlet(TD.Identity<Uint8Array>()),
}

export class LoadData implements NodeClassImpl<typeof loadDataDescriptor, typeof LoadData> {
    static descriptor = loadDataDescriptor
    static uniqueName = "LoadData"
    sceneManager!: Inlet<ISceneManagerNew>
    dataObject!: Inlet<IDataObjectNew>
    data!: Outlet<Uint8Array>

    private pending: Subscription | null = null

    run() {
        this.data.emitIfChanged(NotReady)
        this.pending?.unsubscribe()

        if (this.sceneManager === NotReady || this.dataObject === NotReady) {
            return
        }

        const {dataObject, sceneManager} = this

        this.pending = sceneManager.addTaskNew(
            `loadData(${dataObject.legacyId})`,
            from(
                (async () => {
                    const response = await fetch(dataObject.downloadUrl)
                    if (!response.ok) {
                        throw new Error(`Failed to download data: ${response.statusText}`)
                    }
                    const buffer = await response.arrayBuffer()
                    return new Uint8Array(buffer)
                })(),
            ).pipe(
                map((data) => {
                    this.pending = null
                    this.data.emitIfChanged(data)
                }),
            ),
            true,
        )
    }

    cleanup() {
        this.pending?.unsubscribe()
    }
}
